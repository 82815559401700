import React, {createRef} from "react";
import {Avatar, Card, CardHeader, Grid, IconButton, Tooltip} from "@material-ui/core";
import {Theme, withStyles} from "@material-ui/core/styles";
import {blue, blueGrey, green, red, yellow} from "@material-ui/core/colors";
import {Styles} from "@material-ui/styles";
import {ReactComponent as PenSVG} from "src/img/pen.svg";
import {ReactComponent as ActiveSVG} from "src/img/on_btn.svg";
import {ReactComponent as DisableSVG} from "src/img/off_btn.svg";
import {ReactComponent as TrashSVG} from "src/img/trash.svg";

import {HostAPI} from "src/APIs/HostAPI";
import {allowLogs, createAlertFromMessage, devLog} from "../../../../Helpers";
import {EditHost} from "./add-edit-host/edit-host";
import AlertDialog, {AlertDialogData} from "src/components/organisms/alert-dialog/AlertDialog";

type HostUserProps = {
    user: any,
    parentRef: any,
    history: any,
    match: any,
    createAlert?:(type:string,title:string,message:string) => void,
    classes?:any,

}
export class HostUser extends React.Component<HostUserProps> {
    dialog = createRef<AlertDialog>();
    getStatusComponent = (status: string) => {
        let {user, classes} = this.props;
        let text = (status === 'admin') ? "Main Admin" : status.replace(/(^\w)/, status.charAt(0).toUpperCase());
        let color: string
        switch (status) {
            case 'admin':
            case 'pending':
            case 'active':
            case 'disabled':
                break;
            default:
                return <></>;
        }
        return <span className={`${classes.status} ${classes[status] ?? ''} `}>  {text} </span>;
    }
    toggleHostStatus = async () => {
        let {user, parentRef} = this.props;
        let message = await HostAPI.toggleHostStatus(user.id, user.status)
        await parentRef.refresh()
        createAlertFromMessage(parentRef, message)


    }
    deleteHost = () => {
        let {user, parentRef} = this.props;

        let dialogData: AlertDialogData = {
            title: "Are You Sure",
            message: "",
            agreeText: "Ok",
            cancelText: "Cancel",
            onAgreeClicked: (dialog: AlertDialog) => {

                HostAPI.deleteHost(user.id).then(async (message) => {
                    await parentRef.refresh()
                    createAlertFromMessage(parentRef, message)
                })

            },
        }


        this.dialog.current?.setDialogData(dialogData)
        this.dialog.current?.open()
    }
    editHost = ()=>{
        let {user,history} = this.props;
        devLog(user)
        history.push(EditHost.route.replace(':id?', user.id))
    }
    render() {
        let {user, classes} = this.props;
        let {status} = user
        return (
            <Card variant="outlined" className={classes.card}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                            {user.first_name?.charAt(0).toUpperCase() ?? ''}
                            {user.last_name?.charAt(0).toUpperCase() ?? ''}
                        </Avatar>
                    }
                    action={
                        <Grid container spacing={1} /*className={`${classes.buttonBar}`}*/>
                            {(status === 'admin' && !allowLogs()) ||
                            <Grid container item sm={12} md={4}>
                                <Tooltip title={"Edit"} arrow>
                                    <IconButton aria-label="edit"
                                                onClickCapture={this.editHost}/*className={`${classes.iconButton} row-12 col-sm-4 mx-auto px-auto `}*/>
                                        <PenSVG className={classes.icon}/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            }

                            {(status === 'active' || status === 'disabled') &&
                            <Grid container item sm={12} md={4}>
                                <Tooltip title={(status === 'active') ? "Disable" : "Activate"} arrow>
                                    <IconButton aria-label="edit" onClickCapture={this.toggleHostStatus}>
                                        {(status === 'active') && <DisableSVG className={classes.icon}/>}
                                        {(status === 'disabled') && <ActiveSVG className={classes.icon}/>}
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            }

                            { (status === 'admin') ||
                            <Grid container item sm={12} md={4}>
                                <Tooltip title={"Delete"} arrow>
                                    <IconButton aria-label="edit"
                                                onClickCapture={this.deleteHost}/*className={`${classes.iconButton} row-12 col-sm-4 mx-auto px-auto `}*/>
                                        <TrashSVG className={classes.icon}/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            }

                        </Grid>
                    }
                    title={`${user.first_name ?? ''} ${user.last_name ?? ''}`}
                    subheader={
                        <>
                            {user.email && <><span> {user.email} </span><br/></>}
                            {this.getStatusComponent(user.status)}
                        </>
                    }
                />
                {/*{JSON.stringify(user, null, 4)}<br/>*/}
                <AlertDialog ref={this.dialog}/>
            </Card>
        );
    }
}

const styles = (theme: Theme) =>
    ({
        card: {
            marginTop:theme.spacing(1)
        },
        icon: {
            margin:'auto',
            width:24,
            height:24,
        },
        iconButton: {
            marginRight:'auto',
            marginLeft:'auto',
            width:'auto',
            [theme.breakpoints.down('md')]:{
                width:'100%',
            }
        },
        buttonBar: {
            marginRight:'auto',
            marginLeft:'auto',
            width:'auto',
            [theme.breakpoints.down('md')]:{

            }
        },
        avatar: {
            backgroundColor: blueGrey[300],
        },
        status:{
            borderRadius:'0.2rem',
            padding:'.2rem 0.5rem',
            fontSize:'.8rem'

        },
        'admin':
            {backgroundColor: blue[300]},
        'pending':
            {backgroundColor: yellow[300]},
        'active':
            {backgroundColor: green[300]},
        'disabled':
            {backgroundColor: red[300]},


    } as Styles<Theme, any, string>)

//@ts-ignore
export const HostUserEnhanced = withStyles(styles)(HostUser);
