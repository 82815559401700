import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from 'clsx';

const useStyles = makeStyles(
    (theme) => (
        {
            root: {
                maxWidth: '100%',
            },
            sectionTitle: {
                fontSize: 22,
                fontWeight: 600,
                marginBottom: '20px'
            }
        }
    )
)


function ImportBookingForm({fileData})
{
    const classes = useStyles();
    
    const handleFileUpload = (e) => {
        fileData.append('file', e.target.files[0]);
    };

    return (
        <div className={clsx(classes.root)}>
            <div className="row">
                <div className="col-md-12">
                    <input type="file" className="form-control" accept=".csv" onChange={(e) => handleFileUpload(e)} />
                </div>
            </div>
        </div>
    );
}

export default ImportBookingForm;