import React, {Component} from 'react';
import {createAlert, formValidation, getApiLinkV2} from "../Helpers";
import axios from "axios";
import {getAxios} from "src/Helpers";
import FloatingAlert from "./organisms/FloatingAlert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import Bugsnag from "@bugsnag/js";
import {FMPhoneInput} from "src/components/host/forms/fields";
import Helmet from "react-helmet";
class Contact extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      fNameError: null,
      lNameError: null,
      emailError: null,
      phoneError: null,
      companyError: null,
      messageError: null,
    }

    this.handleContact = this.handleContact.bind(this);
  }

  componentDidMount() {
    //ReactGA.pageview(window.location.pathname + window.location.search);
  }

  handleContact(e) {
    e.preventDefault();

    const data = new FormData(e.target);
    const fNameError = formValidation('fname', data.get("fname"));
    const lNameError = formValidation('lname', data.get("lname"));
    const emailError = formValidation('email', data.get("email"));
    const subjectError = formValidation('subject', data.get("subject"));
    const phoneError = formValidation('phone', data.get("phone"));
    const companyError = formValidation('company', data.get("company"));
    const messageError = formValidation('message', data.get("message"));

    this.setState({
      reqLoading: true,
      reqAlert: null,
      fNameError: fNameError,
      lNameError: lNameError,
      emailError: emailError,
      subjectError: subjectError,
      phoneError: phoneError,
      companyError: companyError,
      messageError: messageError
    });

    if (fNameError || lNameError || emailError || subjectError || phoneError || companyError || messageError) return this.setState({ reqLoading: false });

    const url = getApiLinkV2('user/contact');

    getAxios().post(url, {
      first_name: data.get("fname"),
      last_name: data.get("lname"),
      email: data.get("email"),
      subject: data.get("subject"),
      phone: data.get("phone"),
      company: data.get("company"),
      message: data.get("message"),
    }, {
      headers: { 'Content-Type': 'application/json' },
    }).then(response => {
        createAlert(this, 'success', '', 'Thank you for contacting us, A fluidmeet team member will respond back asap.');
        document.getElementById('fname').value = '';
        document.getElementById('lname').value = '';
        document.getElementById('email').value = '';
        document.getElementById('subject').value = '';
        document.getElementById('phone').value = '';
        document.getElementById('company').value = '';
        document.getElementById('message').value = '';
        setTimeout(() => {
          window.location.href = "/contact-us";
          }, 2000);
      })
      .catch(error => {
        Bugsnag.notify(error);
        createAlert(this, 'danger',(error.response && error.response.data.error) ? 'Error: ' : '', (error.response && error.response.data.error) ? error.response.data.error : error.message);
      }).then(() => {
      this.setState({ reqLoading: false });
    });
  }

  render() {

    const { reqLoading, reqAlert } = this.state;

    return (
      <>
          <Helmet>
            <meta charSet="utf-8"/>
            <meta name={'description'}
                  content={'Find a feasible and practical space at Fluidmeet.'}/>
            <meta name="keywords"
                  content="contact us, deatails, whatsapp, email, sumit a request, free consultation"/>
            <link rel={"canonical"} href="https://www.fluidmeet.com/contact-us" />
            <title>Fluidmeet | Contact Us</title>
          </Helmet>
      <div className="container-fluid contain bg-white mt-80 pr-0 pl-0">
        <div className="container form-contain">
          <section className="page">

            <h1 className="page-title mt-4 mb-4">Contact Us</h1>

            <p className="page-desc mt-2">
            To get in touch with us, please fill in the form below with your contact details and queries and our team will get back to you as soon as possible
            </p>

            <div className="form-general">
              <form className="row mt-5" autoComplete="on" onSubmit={this.handleContact}>

                <div className="col-6 pr-mobile-1 pl-0">
                  <input type="name" id="fname" name="fname" className="form-control" placeholder="First name" required />
                  {(this.state.fNameError) ? (<div className="form-error">{this.state.fNameError}</div>) : (<></>)}
                </div>
                <div className="col-6 pl-mobile-1 pr-0">
                  <input type="name" id="lname" name="lname" className="form-control" placeholder="Last name" required />
                  {(this.state.lNameError) ? (<div className="form-error">{this.state.lNameError}</div>) : (<></>)}
                </div>
                <div className="col-6 pr-mobile-1 pl-0">
                  <input type="email" id="email" name="email" className="inputEmail form-control" placeholder="Email address" required />
                  {(this.state.emailError) ? (<div className="form-error">{this.state.emailError}</div>) : (<></>)}
                </div>
                <div className="col-6 pl-mobile-1 pr-0">
                  <div className="selector">
                    <select name="subject" id="subject" className="select">
                      <option value="">Subject</option>
                      <option value="Booking Enquiry">Booking Enquiry</option>
                      <option value="Listing Venue on Fluidmeet">Listing Venue on Fluidmeet</option>
                      <option value="Interested in Partnership">Interested in Partnership</option>
                      <option value="Fluidmeet HR">Fluidmeet HR</option>
                      <option value="Complaint">Complaint</option>
                      <option value="Premium Plans">Premium Plans</option>
                    </select>
                  </div>
                  {(this.state.subjectError) ? (<div className="form-error">{this.state.subjectError}</div>) : (<></>)}
                </div>
                <div className="col-6 pr-mobile-1 pl-0">
                  <FMPhoneInput type={'tel'} id="phone" placeholder="Phone number"
                                    className="form-control"  required />
                  {(this.state.phoneError) ? (<div className="form-error">{this.state.phoneError}</div>) : (<></>)}
                </div>
                <div className="col-6 pl-mobile-1 pr-0">
                  <input type="text" id="company" name="company" className="form-control" placeholder="Company" required />
                  {(this.state.companyError) ? (<div className="form-error">{this.state.companyError}</div>) : (<></>)}
                </div>
                <div className="col-12 pr-0 pl-0">
                  <textarea id="message" name="message" className="form-control" placeholder="Message" multiline={true} required />
                  {(this.state.messageError) ? (<div className="form-error">{this.state.messageError}</div>) : (<></>)}
                </div>

                <div className="col-12 mt-3 mb-4 pr-0 pl-0">
                  <button className={"btn btn-lg btn-primary btn-block fl_btn submit-btn "+((reqLoading) ? 'ld-btn' : '')}>
                    {(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch} spin={true} /> loading</>) : 'Submit'}
                  </button>
                </div>
              </form>
            </div>

            {(reqAlert && <FloatingAlert error={reqAlert} onClick={() => this.setState({reqAlert: null})} />)}

          </section>
        </div>

      </div>
      </>
    );
  }
}

export default Contact;
