import React, {useEffect, useState} from 'react';
import {AccordionDetails, AccordionSummary, CircularProgress, Divider, makeStyles} from "@material-ui/core";
import {blue, blueGrey} from "@material-ui/core/colors";
import clsx from 'clsx';
import {
	devLog,
	getApiLinkV2,
	getAxios,
	getCurrency,
	getToken,
	getUser,
	printAmenity,
	printDateFromDateTime,
	printHostBookingStatus,
	printPrice,
	printSpaceData,
	printTimeRange,
	getCurrencies,
} from "src/Helpers";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useHistory, useParams} from "react-router-dom";
import Bugsnag from "@bugsnag/js";
import axios from 'axios';
import multiProposal from 'src/img/gray_logo.png';
import Accordion from "@material-ui/core/Accordion";
import {infoSection} from "src/components/host/host-dashboard/manage-reservations/reservations-details/info-section";
import {CATERED_AMENITIES} from "src/components/host/host-dashboard/manage-listings/add-edit-workspaces/consts";
import {VenueAPI} from "src/APIs/venue/VenueAPI";


const useStyles = makeStyles((theme) => (
		{
			accordionRoot: {
				backgroundColor: '#edf5fa'
			},
			tooltip: {
				fontSize: theme.typography.pxToRem(18),
			},
			hoverListItem: {
				alignItems: 'center',
				'&:hover': {
					backgroundColor: blueGrey[50],
					borderRadius: '0.5rem',
				}

			}
		}
	)
)

export type ShortTermDetailsProps = {}
ShortTermDetails.key = 'short-term-details'
ShortTermDetails.route = `/${ShortTermDetails.key}/:id/`

ShortTermDetails.view = (history, reservation_id) => {
	history.push(ShortTermDetails.generateViewLink(reservation_id))
}

ShortTermDetails.generateViewLink = (reservation_id) => {
	return (`/${ShortTermDetails.key}/${reservation_id}/`)
}


export type ShortTermDetailsRouteParams = {
	id: string,
	type?: string,
	action?: 'view' | 'price',
}
export default function ShortTermDetails({}: ShortTermDetailsProps) {

	const {id} = useParams<ShortTermDetailsRouteParams>()
	const currency = getCurrency();
	const user = getUser();
	const [reservation, setReservation] = useState<any>({})
	const [checked_amenities, set_checked_amenities] = useState<any>({})
	const [space, setSpace] = useState<any>();
	const [loading, setLoading] = useState<boolean>(true);
	const [venueCurrency, setVenueCurrency] = useState<any>({
		name: 'USD',
		icon: '$',
		exchange_rate: 1,
		country: 'United States'
	});
	const history = useHistory();
	const classes = useStyles();

	useEffect(() => {
		fetchReservation();
	}, []);

	useEffect(() => {
		if(reservation) {
			const countries = getCurrencies().map(obj => obj.country);
			const currencies = getCurrencies();
			if(countries.includes(reservation.venue_country)) {
				let currency = currencies.find(c => c.country == reservation.venue_country);
				setVenueCurrency(currency);
			}
			else {
				setVenueCurrency({
					name: 'USD',
					icon: '$',
					exchange_rate: 1,
					country: 'United States'
				});
			}
		}
	}, [reservation]);

	const fetchReservation = () => {

		let token = getToken()

		if (!token) return;
		setLoading(true)
		const url = getApiLinkV2("host/reservations/" + id);

		getAxios().get(url, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token,
			},
			params: {
				type: 'booking'
			},
		}).then(response => {
			devLog(`Response`, {response})
			if (response.data.reservation) {
				setReservation(response.data.reservation)
				setSpace(response.data.space)
				set_checked_amenities(response.data.checked_amenities)
				devLog(response.data)
			}
		}).catch(error => {
			devLog(error);
			setReservation(null)
			setTimeout(() => {
				Bugsnag.notify(error);
			}, 500)
		}).finally(() =>
			setLoading(false)
		);

	}

	const spaceTapped = space => {
		// navigate('SpaceDetails'+space.workspace_id, 'SpaceDetails', {id: space.workspace_id})
	}

	const createHeader = () => {

		return (
			<>
				<div className={clsx('d-flex flex-row align-items-start justify-content-between flex-wrap')}>

					<div className={clsx('d-flex flex-row align-items-start')} style={styles.space}
						// onPress={() => this.spaceTapped(space)}
					>
						{(space.image) ? (
							<img style={styles.spaceimg}
							     src={"https://fluidmeet.s3.amazonaws.com/venue_logos/" + space.image}/>
						) : (
							<div style={styles.spaceimg}>
								<img style={{...styles.generalimg, height: 42, width: 43,}}
								     src={multiProposal}/>
							</div>
						)}

						<div style={styles.spacedetails}>
							<div style={styles.spaceText}><b>Space:</b> {space.name}</div>
							<div style={styles.spaceText}><b>Venue:</b> {space.venue_name}</div>
							<div style={styles.spaceText}><b>Address:</b> {space.front_address}</div>
							<div style={styles.spaceText}>
								<b>Type:</b> {printSpaceData(space.workspace_type_id).name}</div>
						</div>
					</div>


					<div className={'mt-2 ml-3'}>
						<div style={styles.idTitleContent}>
							<div style={styles.idTitle}>{'#' + reservation.booking_number}</div>
						</div>
						<div style={styles.statusContent}>
							<b>Status:</b> {printHostBookingStatus(reservation)}
						</div>
					</div>

				</div>
			</>
		)
	}

	const grid = 'col-12 d-flex flex-row flex-wrap align-items-center py-2';
	const col5 = 'col-5';
	const col8 = 'col-7';

	const createBookingDetails = () => {
		return (
			<div>
				<Accordion
					key={'booking-details'}
					classes={{
						root: classes.accordionRoot
					}}
					variant={'elevation'}
					defaultExpanded={true}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon/>}
						aria-controls={`booking-details-content`}
						id={`booking-details-header`}
					>
						<div style={styles.sectionhead}>Booking Details</div>
					</AccordionSummary>

					<AccordionDetails className={grid}>


						<div className={clsx(grid, classes.hoverListItem)}>
							<div className={col5}>
								<div style={{...styles.reservSubtitle}}>Client Name</div>
							</div>
							<div className={col8}>
								<div style={styles.reservText}>{reservation.name}</div>
							</div>
						</div>
						<div className={clsx(grid, classes.hoverListItem)}>
							<div className={col5}>
								<div style={{...styles.reservSubtitle}}>Booking date</div>
							</div>
							<div className={col8}>
								<div
									style={styles.reservText}>{printDateFromDateTime(reservation.start_date_time)}</div>
							</div>
						</div>
						<div className={clsx(grid, classes.hoverListItem)}>
							<div className={col5}>
								<div style={{...styles.reservSubtitle}}>Booking time</div>
							</div>
							<div className={col8}>
								<div
									style={styles.reservText}>{printTimeRange(reservation.start_date_time, reservation.end_date_time)}</div>
							</div>
						</div>
						<div className={clsx(grid, classes.hoverListItem)}>
							<div className={col5}>
								<div style={{...styles.reservSubtitle}}>Created on</div>
							</div>
							<div className={col8}>
								<div
									style={styles.reservText}>{printDateFromDateTime(reservation.created_datetime)}</div>
							</div>
						</div>

						<div className={clsx(grid, classes.hoverListItem)}>
							<div className={col5}>
								<div style={{...styles.reservSubtitle}}>Price</div>
							</div>
							<div className={col8}>
								<div
									style={styles.reservText}>{printPrice((reservation.subtotal * (reservation.venue_percent / 100)), reservation.payment_currency, venueCurrency.name, false)}</div>
							</div>
						</div>
						<div className={clsx(grid, classes.hoverListItem)}>
							<div className={col5}>
								<div style={{...styles.reservSubtitle}}>Sales Tax</div>
							</div>
							<div className={col8}>
								<div
									style={styles.reservText}>{printPrice((reservation.sale_tax_amt * (reservation.venue_percent / 100)),  reservation.payment_currency, venueCurrency.name)}
								</div>
							</div>
						</div>
						{/*<div className={clsx(grid, classes.hoverListItem)}>
							<div className={col5}>
								<div style={{...styles.reservSubtitle}}>Price (Inc taxes)</div>
							</div>
							<div className={col8}>
								<div
									style={styles.reservText}>{printPrice(((reservation.sale_tax_amt * (reservation.venue_percent / 100)) + reservation.subtotal), reservation.payment_currency, currency.name, true)}</div>
							</div>
						</div>*/}
						{/*<div className={clsx(grid, classes.hoverListItem)}>
							<div className={col5}>
								<div style={{...styles.reservSubtitle}}>Discount</div>
							</div>
							<div className={col8}>
								<div
									style={styles.reservText}>{printPrice(reservation.discount, reservation.payment_currency, currency.name, true)}</div>
							</div>
						</div>*/}
						{/*<div className={clsx(grid, classes.hoverListItem)}>
							<div className={col5}>
								<div style={{...styles.reservSubtitle}}>Total Price</div>
							</div>
							<div className={col8}>
								<div
									style={styles.reservText}>{printPrice(reservation.amount_due, reservation.payment_currency, currency.name, true)}</div>
							</div>
						</div>*/}
						<div className={clsx(grid, classes.hoverListItem)}>
							<div className={col5}>
								<div style={{...styles.reservSubtitle}}>Amount Owed to you</div>
							</div>
							<div className={col8}>
								<div
									style={styles.reservText}>{printPrice((reservation.subtotal * (reservation.venue_percent / 100)) + (reservation.sale_tax_amt * (reservation.venue_percent / 100)), reservation.payment_currency, venueCurrency.name, false)}</div>
							</div>
						</div>

					</AccordionDetails>
				</Accordion>
			</div>


		)
	}
	const createSpaceDetails = () => {
		let amenities = '-'
		if (checked_amenities && Object.keys(checked_amenities).length > 0) {
			amenities = CATERED_AMENITIES.map(
				({label, key}) => {
					if (checked_amenities[`chk_${key}`] === "yes") {
						return label;
					} else return null;
				}
			).filter(Boolean).join(', ');
		}
		let elements: any[] = [
			{title: 'Venue Name', value: space.venue_name,},
			{title: 'Venue  Location', value: `${space.front_address}, ${reservation.suite_floor}`,},
			{
				title: 'Space Details',
				value: <div className={''}>
					Name: {" " + space.name}<br/>Type: {" " + printSpaceData(reservation.workspace_type_id).name}
				</div>,
			},
			{
				title: 'Requested Capacity',
				value: `Space for ${reservation.capacity} ${(reservation.capacity > 1) ? 'people' : 'person'}`,
			},
			{
				title: 'Amenities Requested', value: amenities
			},
			{title: 'Parking', value: reservation.parking ? reservation.parking : '-'}
		]

		if (space.amenities && Object.keys(space.amenities).length > 0) elements.push(
			{
				title: 'Amenities Requested',
				value: space.amenities.map(
					(key, i) => {
						const amenityCount = space.amenities[key];
						if (!amenityCount) return '';
						const amenity = printAmenity(key);
						if (!amenity) return '';
						return amenityCount + ' ' + amenity.amenity + ' required, '
					}
				)
			})
		return (
			<div>
				<Accordion
					key={'space-details'}
					classes={{
						root: classes.accordionRoot
					}}
					variant={'elevation'}
					defaultExpanded={true}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon/>}
						aria-controls={`space-details-content`}
						id={`space-details-header`}
					>
						<div style={styles.sectionhead}>Venue & Space Details</div>
					</AccordionSummary>

					<AccordionDetails>


						<div className={grid}>
							{
								elements.map(
									({title, value}) => (infoSection(null, title, value))
								)

							}


						</div>

					</AccordionDetails>

				</Accordion>
			</div>
		)
	}


	if (!loading && !reservation) history.goBack();

	if (!loading && Object.keys(reservation).length === 0) {
		return (

			<div>
				Short Term Booking
			</div>

		);
	}

	return (
		<div className="">
			<div className="container pr-0 pl-0" style={{marginTop: 120}}>
				<div className="page">

					<div className={`row page-lg-title mt-4 mb-5 `}>
						Short Term Booking
					</div>
					{
						(loading) ? (
							<div className={clsx('d-flex col-12 justify-content-center')}>
								<CircularProgress className={clsx('mx-auto my-5')}/>
							</div>
						) : (


							<div style={styles.content}>
								{createHeader()}

								<Divider className={'my-3'}/>

								{createBookingDetails()}

								<Divider className={'my-3'}/>

								{createSpaceDetails()}

								<div className={'my-4'}/>


							</div>

						)
					}
				</div>
			</div>
		</div>

	);


}
const styles = {
	baseView: {
		display: 'none',
		zIndex: -1,
		backgroundColor: 'rgba(0, 0, 0, 0.3)',
	},
	baseViewAct: {
		flex: 1,
		position: 'absolute' as const,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 2,
		backgroundColor: 'rgba(0, 0, 0, 0.3)',
	},
	container: {
		backgroundColor: 'white' as const,
		flex: 1,
	},
	content: {
		flex: 1,
		paddingHorizontal: 20,
	},
	space: {
		flex: 1,
		marginTop: 6,
		paddingBottom: 16,
		flexDirection: 'row' as const,
		borderBottomColor: '#CFD8E7',
		borderBottomWidth: 0.5,
	},
	spaceimg: {
		height: 200,
		resizeMode: 'cover',
		borderRadius: 10,
		backgroundColor: '#E5E7E9',
	},
	generalimg: {
		resizeMode: 'contain' as const,
		marginTop: 'auto' as const,
		marginBottom: 'auto' as const,
		marginLeft: 'auto' as const,
		marginRight: 'auto' as const,
	},
	spacedetails: {
		flex: 1,
		flexShrink: 1,
		flexDirection: 'column' as const,
		paddingLeft: 14,
		overflow: 'hidden' as const,
	},
	spaceTitle: {
		color: '#212529',
		paddingBottom: 3,
		paddingRight: 50,
		overflow: 'hidden' as const,
		fontWeight: 600,
		fontSize: 18,
	},
	spaceText: {
		color: '#212529',
		paddingBottom: 3,
		overflow: 'hidden' as const,
		fontSize: 17,
		paddingLeft: '0.5rem',
	},
	map: {
		width: '100%',
		height: '100%',
		borderRadius: 12,
	},
	mapContainer: {
		marginTop: 12,
		marginBottom: 20,
		backgroundColor: '#cfd8e7',
		borderRadius: 12,
		borderColor: '#CFD8E7',
		borderWidth: 1,
		width: '100%',
		// height: (Dimensions.get('window').width - 50),
	},
	ratingTitle: {
		color: '#CFD8E7',
		paddingBottom: 10,
		overflow: 'hidden' as const,
		fontWeight: 700,
		textAlign: 'center' as const,
		fontSize: 16,
	},
	reservTitle: {
		color: '#212529',
		paddingBottom: 16,
		overflow: 'hidden' as const,
		fontWeight: 600,
		fontSize: 16,
	},
	reservSubtitle: {
		color: '#394148',
		overflow: 'hidden' as const,
		fontWeight: 500,
		fontSize: 18,
	},
	reservText: {
		color: '#212529',
		paddingBottom: 3,
		overflow: 'hidden' as const,
		fontSize: 17,
	},
	reservprice: {
		color: '#212529',
		paddingBottom: 1,
		overflow: 'hidden' as const,
		fontSize: 18,
	},
	quoteTitle: {
		color: '#212529',
		paddingBottom: 3,
		overflow: 'hidden' as const,
		fontSize: 17,
		fontWeight: 500,
		paddingRight: 46,
	},
	noteText: {
		textAlign: 'left' as const,
		color: '#212529',
		fontSize: 16,
		lineHeight: 24,
		marginTop: 8,
		marginBottom: 16,
	},
	noteLink: {
		textAlign: 'left' as const,
		color: '#212529',
		textDecorationLine: 'underline' as const,
		fontSize: 16,
	},
	head: {
		flex: 0,
		flexDirection: 'row' as const,
		height: 30,
		marginTop: 14,
		marginBottom: 18,
		justifyContent: 'center' as const,
	},
	quoteHead: {
		flex: 0,
		flexDirection: 'row' as const,
		height: 30,
		marginTop: 4,
		marginBottom: 6,
		justifyContent: 'center' as const,
	},
	idTitleContent: {
		flexGrow: 1,
		flexShrink: 1,
		paddingRight: 6,
		justifyContent: 'center' as const,
		marginTop: 'auto' as const,
		marginBottom: 'auto' as const,
	},
	idTitle: {
		justifyContent: 'center' as const,
		marginTop: 'auto' as const,
		marginBottom: 'auto' as const,
		fontSize: 17,
	},
	statusContent: {
		flexGrow: 0,
		paddingTop: 4,
		flexShrink: 0,
		justifyContent: 'center' as const,
	},
	priceContent: {
		flexGrow: 0,
		flexShrink: 0,
	},
	sectionhead: {
		width: '100%',
		fontSize: 19,
		fontWeight: 600,
		paddingBottom: 20,
	},
	sectionSubhead: {
		width: '100%',
		fontSize: 18,
		color: '#212529',
		fontWeight: 500,
		paddingBottom: 8,
	},
	ratingContainer: {
		flexDirection: 'row' as const,
		alignItems: "center",
		justifyContent: "flex-start"
	},
	reviewText: {
		fontWeight: 700,
		alignItems: "center",
		fontSize: 20,
		color: "rgba(230, 196, 46, 1)",
	},
	starContainer: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-start"
	},
	btnGrid: {
		flexDirection: 'row' as const,
		marginHorizontal: -4,
	},
	col: {
		flex: 1,
		flexDirection: 'column',
		paddingHorizontal: 8,
	},

	quoteDecline: {
		color: '#212529',
		paddingTop: 20,
		paddingBottom: 4,
		fontSize: 16,
	},
	quoteDeclineNote: {
		color: '#212529',
		fontSize: 16,
	},
	pt2: {
		paddingTop: 8,
	},
	pb2: {
		paddingBottom: 10,
	},
	pb4: {
		paddingBottom: 20,
	},
	btnView: {
		flex: 1,
		height: 48,
		backgroundColor: blue[400],
		marginBottom: 10,
		borderRadius: 10,
		marginHorizontal: 8,
		paddingHorizontal: 4,
		justifyContent: 'center' as const,
	},
	btnText: {
		flex: 0,
		flexGrow: 0,
		textAlign: 'center' as const,
		color: 'white' as const,
		fontSize: 17,
		fontWeight: 500,
	},
	onoffBtn: {
		flex: 0,
		height: 30,
		backgroundColor: blueGrey[100],
		borderRadius: 6,
		paddingHorizontal: 8,
		justifyContent: 'center' as const,
	},
	onoffText: {
		fontSize: 16,
		fontWeight: 400,
	},
	onActive: {
		fontSize: 16,
		fontWeight: 500,
	},
	btn: {
		flex: 0,
		flexGrow: 0,
		textAlign: 'right',
		color: 'black',
		fontSize: 17,
		fontWeight: 400,
		textDecorationLine: 'underline' as const,
	},
	divider: {
		flex: 1,
		height: 1,
		marginBottom: 24,
		backgroundColor: '#CFD8E7',
	},
	emptyTitle: {
		fontSize: 17,
		fontWeight: 400,
		color: '#697486',
	},
	proposal: {
		paddingTop: 20,
		paddingHorizontal: 16,
		paddingBottom: 16,
		borderWidth: 1,
		backgroundColor: '#F8F9Fa',
		borderColor: '#CFD8E7',
		borderRadius: 10,
		marginBottom: 24,
	},
	mapIconView: {
		position: "absolute",
		right: 0,
		top: 46,
		width: 36,
		height: 36,
		zIndex: 2,
	},
	mapIcon: {
		width: 36,
		height: 36,
	},
	backView: {
		position: 'absolute' as const,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	row: {
		flex: 1,
		flexDirection: 'column',
	},
	showMore: {
		flex: 0,
		flexDirection: 'row' as const,
		marginTop: 'auto' as const,
		marginBottom: 'auto' as const,
		paddingVertical: 16,
		marginHorizontal: 32,
		justifyContent: "center",
		alignItems: "center",
		// width: (Dimensions.get('window').width - 64),
		// maxHeight: (Dimensions.get('window').height - 300),
		backgroundColor: "white",
		borderRadius: 12,
		shadowColor: "#000000",
		shadowOffset: {
			width: 0,
			height: 1
		},
		shadowOpacity: 0.125,
		shadowRadius: 2,
		elevation: 2,
	},
	moreHeader: {
		position: 'relative' as const,
		paddingHorizontal: 12,
		marginBottom: 16,
		borderBottomColor: '#CFD8E7',
		borderBottomWidth: 0.5,
	},
	moreHeaderText: {
		color: 'black',
		textAlign: 'center' as const,
		fontSize: 19,
		fontWeight: 500,
		marginBottom: 16,
	},
	closeView: {
		padding: 2,
		position: 'absolute' as const,
		top: 0,
		right: 10,
	},
}
