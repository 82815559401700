export function isNull(x) {
	return x == null
}

export function isNotNull(x) {
	return !isNull(x)
}

export function isUndefined(x) {
	return x === undefined
}

export function isNotUndefined(x) {
	return !isUndefined(x)
}

export function isNullOrUndefined(x) {
	return isNull(x) || isUndefined(x)
}

export function isNotNullOrUndefined(x) {
	return !isNullOrUndefined(x)
}

export function pick<T, K extends keyof T>(obj: T, ...keys: Array<K>): Pick<T, K> {
	const copy = {} as Pick<T, K>;
	keys.forEach(key => copy[key] = obj[key]);
	return copy;
}


export function firstNonZero(...x) {
	return x.find(x => x != 0)
}
