import React, { Component } from 'react';
import { Link, Redirect, withRouter } from "react-router-dom";
import queryString from 'query-string';
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FMPhoneInput } from "src/components/host/forms/fields";
import { faAngleDoubleRight, faCircleNotch, faMap, faSort, faTimes } from '@fortawesome/free-solid-svg-icons';
import SearchApi from "./SearchApi";
import printLocal, {
  createAlert,
  formValidation,
  getApiLinkV2,
  getCurrency,
  getImage,
  getToken,
  getCountryCode,
  getUser,
  printLink,
  printLinkRemoved,
  printLinks,
  printPriceOnly,
  getSource
} from "../Helpers";
import 'src/styles/office-spaces-popup-form.scss';
import 'src/styles/meeting-room-popup-form.scss';
import 'src/styles/banquet-popup-form.scss';
import 'src/styles/co-working-popup-form.scss';
import Space from './Space';
import Slider from '@material-ui/core/Slider';
import styles from "../GoogleMapStyles.json";
import markerIcon from "../img/blue-marker2.png";
import markerActIcon from "../img/marker.png";
import Footer from "./organisms/Footer";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import axios from "axios";
import { getAxios } from "src/Helpers";
import Bugsnag from "@bugsnag/js";
import { connect } from "react-redux";
import clsx from 'clsx';
import offices_popup_image_small_size_mobile from '../img/popups/offices-popup.jpg';
import offices_popup_image_mobile from '../img/popups/offices-popup.png';
// import meeting_rooms_popup_image from '../img/meeting-rooms-popup-img.jpg';
// import banquet_popup_image from '../img/banquet-popup-img-01.png';
import meeting_rooms_popup_image from '../img/popups/meeting-popup.png';
import banquet_popup_image from '../img/popups/banquet-popup.png';
import co_working_popup_image from '../img/co-working-popup.png';
import offices_popup_image_phone_logo from '../img/elements-02.png';
import ReactGA from "react-ga-neo";
import PhoneInput from 'react-phone-input-2'
import libphonenumber from "google-libphonenumber";
let phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()
let PNF = libphonenumber.PhoneNumberFormat
export class SearchPage extends Component {
  static route = '/search/:city/:type?';

  constructor(props) {
    super(props)
    // const city = (props.match.params && props.match.params.city) ? props.match.params.city : null;
    // const country = printCountry(city);
    // devLog(city);

    this.state = {
      city: { id: "", location: { lat: 25.1210779, lng: 55.2212647 } },
      activeSort: '',
      activeFilters: {},
      types: [],
      filters: [],
      moreFilters: [],
      sort: [],
      spaces: [],
      activeSpace: null,
      activeMarker: "",
      showMore: null,
      spacesCount: 0,
      minPrice: 0,
      maxPrice: 0,
      filterBox: '',
      term: 'monthly',
      isLoading: true,
      showBtmNav: false,
      isMapOpen: false,
      isMobileView: false,
      showingMapSpace: false,
      redirect: false,
      request: {},
      errors: {},
      reqLoading: false,
      reqAlert: null,
      selectedCountry: this.props.history?.location?.state?.country ? this.props.history.location.state.country : "United Arab Emirates",
      selectedCity: this.props.history?.location?.state?.city ? this.props.history.location.state.city : null,
      width: 0,
      height: 0,
      show_popUp_office_spaces: true,
    }

    this.mapRef = null;
    this.typesFilterRef = React.createRef();
    this.typesFilterBoxRef = React.createRef();
    this.moreFilterRef = React.createRef();
    this.showMoreRef = React.createRef();
    this.sortFilterRef = React.createRef();
    this.sortFilterBoxRef = React.createRef();
    this.toggleShowMenu = this.toggleShowMenu.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleSize = this.handleSize.bind(this);
    this.handleTapFilter = this.handleTapFilter.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.handleClickOut = this.handleClickOut.bind(this);
    this.handleFilterCheck = this.handleFilterCheck.bind(this);
    this.toggleMoreFilter = this.toggleMoreFilter.bind(this);
    this.handleChangePrice = this.handleChangePrice.bind(this);
    this.updateResults = this.updateResults.bind(this);
    this.handleMapTapped = this.handleMapTapped.bind(this);
    this.handleSelectMarker = this.handleSelectMarker.bind(this);
    this.handleUnSelectSpace = this.handleUnSelectSpace.bind(this);
    this.handleSpaceMouseOver = this.handleSpaceMouseOver.bind(this);
    this.handleSpaceMouseOut = this.handleSpaceMouseOut.bind(this);
    this.handleUpdateValue = this.handleUpdateValue.bind(this);
    this.handlePriceType = this.handlePriceType.bind(this);
    this.redirectScreen = this.redirectScreen.bind(this);
    this.toggleShowMore = this.toggleShowMore.bind(this);
    this.handleGeneralReq = this.handleGeneralReq.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleNextBtn = this.handleNextBtn.bind(this);
    this.show_popUp_office_spaces = this.show_popUp_office_spaces.bind(this);
  }
  show_popUp_office_spaces() {
    this.setState({ show_popUp_office_spaces: false });
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleSize);
    document.addEventListener('mousedown', this.handleClickOut);

    this.handleScroll(null);
    this.handleSize(null);

    //ReactGA.pageview(window.location.pathname + window.location.search);

    this.interval = setInterval(() => document.body.classList.remove("no-scroll"), 1000);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleSize);
    document.removeEventListener('mousedown', this.handleClickOut);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  handleScroll(event) {

    const { isMapOpen, isMobileView } = this.state;

    let map = document.getElementById("map-container")
    let floatingBtn = document.getElementById("float-map-btn")

    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 169) {
      if (!map.classList.contains("map-bottom")) {
        map.classList.add("map-bottom");
      }
      if (floatingBtn) {
        floatingBtn.classList.add('hide');
      }
    } else {
      map.classList.remove("map-bottom");
      if (floatingBtn && !isMapOpen && isMobileView) {
        floatingBtn.classList.remove('hide');
      }
    }
  }

  handleSize(event) {

    let map = document.getElementById("map-container")

    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 169) {
      if (!map.classList.contains("map-bottom")) {
        map.classList.add("map-bottom");
      }
    } else {
      map.classList.remove("map-bottom");
    }

    if (window.innerWidth <= 1040 && !this.state.isMobileView) {
      this.setState({ isMobileView: true });
    } else if (window.innerWidth > 1040 && this.state.isMobileView) {
      this.setState({ isMobileView: false });
    }

  }

  handleClickOut(event) {

    if (this.typesFilterRef.current != null && this.typesFilterBoxRef.current != null && !this.typesFilterRef.current.contains(event.target) && !this.typesFilterBoxRef.current.contains(event.target)) {
      document.getElementById("type-filter-btn").classList.remove("focused");
      document.getElementById("type-filter").classList.remove("show");
      if (this.state.filterBox == 'type') {
        this.setState({ filterBox: '' });
      }
    }

    this.state.filters.forEach(item => {
      if (item.ref.current != null && item.boxRef.current != null && !item.ref.current.contains(event.target) && !item.boxRef.current.contains(event.target)) {
        document.getElementById(item.id + "-filter-btn").classList.remove("focused");
        document.getElementById(item.id + "-filter").classList.remove("show");
        if (this.state.filterBox == item.id) {
          if (item.id == 'price') {
            let search = queryString.parse(this.props.location.search);
            if (search && search.pr_t) {
              this.handlePriceType(search.pr_t, true);
            }
          }
          this.setState({ filterBox: '' });
        }
      }
    });

    if (this.sortFilterRef.current != null && this.sortFilterBoxRef.current != null && !this.sortFilterRef.current.contains(event.target) && !this.sortFilterBoxRef.current.contains(event.target)) {
      document.getElementById("s-filter-btn").classList.remove("focused");
      document.getElementById("s-filter").classList.remove("show");
      if (this.state.filterBox == 's') {
        this.setState({ filterBox: '' });
      }
    }
    if (this.moreFilterRef.current != null && !this.moreFilterRef.current.contains(event.target)) {
      this.toggleMoreFilter(false);
    }
    if (this.showMoreRef.current != null && !this.showMoreRef.current.contains(event.target)) {
      this.toggleShowMore();
    }
  }

  updateResults(spaces, filters, count) {

    this.initFilters(filters);
    this.setState({ isLoading: false, spaces: spaces, spacesCount: count });

    if (spaces && spaces.length > 0) {

      if (window.google) {
        const bounds = new window.google.maps.LatLngBounds();
        spaces.map(space => {
          bounds.extend({ lat: parseFloat(space.latitude), lng: parseFloat(space.longitude) });
          return space.id
        });
        if (this.mapRef) {
          this.mapRef.setCenter(bounds.getCenter());
        }
      }
    }
  }

  handleChangePrice(event, newValue, hasMin) {

    if (!newValue) return;

    let ratio = this.state.maxPrice / 100;
    let minPrice = (hasMin) ? newValue[0] : 0;
    let maxPrice = (hasMin) ? newValue[1] : newValue;
    if (hasMin) {
      document.getElementById("min-price").innerHTML = parseInt(minPrice * ratio);
    }
    document.getElementById("max-price").innerHTML = parseInt((maxPrice * ratio));
  }

  handlePriceType(type, def = false) {

    var filters = this.state.filters;
    var priceFilter = filters.find(x => x.id == 'price');
    var prices = priceFilter.values;
    var minPrice = 0;
    var maxPrice = (prices['max_' + type + '_amt']) ? Math.ceil(parseInt(prices['max_' + type + '_amt'])) : 1000;

    if (def) {
      priceFilter.defMinPrice = priceFilter.minPrice;
      priceFilter.defMaxPrice = priceFilter.maxPrice;
    } else {
      priceFilter.defMinPrice = minPrice;
      priceFilter.defMaxPrice = maxPrice;
    }
    priceFilter.ratio = (maxPrice / 100);
    priceFilter.activeTab = type;

    if (priceFilter.hasMin) {
      document.getElementById("min-price").innerHTML = minPrice;
    }
    document.getElementById("max-price").innerHTML = maxPrice;

    this.setState({ filters: filters, minPrice: minPrice, maxPrice: maxPrice });
  }

  handleUpdateValue(filter, action) {

    let value = document.getElementById(filter.divName + "-count").innerHTML;
    let oldCount = parseInt(value.replace(/\+/g, ''));
    if (oldCount == 0) {
      if (action == 'add') {
        document.getElementById(filter.divName + "-count").innerHTML = (filter.values[0]) + "";
      }
      return;
    }

    let index = filter.values.findIndex(x => x == oldCount);
    let i = (index > -1) ? index : 0;
    if (action == 'add') {
      document.getElementById(filter.divName + "-count").innerHTML = (filter.values[(i < filter.values.length - 1) ? i + 1 : filter.values.length - 1]) + "";
    } else if (action == 'sub') {
      document.getElementById(filter.divName + "-count").innerHTML = (((i == 0) ? 0 : filter.values[i - 1])) + "";
    }
  }

  handleMapTapped() {

    if (this.state.showingMapSpace) {
      this.setState({ activeSpace: null, showingMapSpace: false });
    }
  }

  handleSelectMarker(space) {

    this.handleUnSelectSpace();

    this.setState({ activeSpace: space, showingMapSpace: true, activeMarker: space.workspace_id });
  }

  handleUnSelectSpace() {

    this.setState({ activeSpace: null, showingMapSpace: false, activeMarker: "" });
  }

  handleSpaceMouseOver(space) {
    this.setState({ activeMarker: space.workspace_id });
  }

  handleSpaceMouseOut(space) {
    this.setState({ activeMarker: "" });
  }

  initFilters(avFilters) {

    const { match } = this.props;

    var filters = [];
    var moreFilters = [];
    var term = 'monthly';
    var minPrice = 0;
    var maxPrice = 100;
    let search = queryString.parse(this.props.location.search);
    let type = this.props.match.params.type;
    var food = '';
    var def = 'monthly';
    var service = 'space';

    const types = [
      { id: 'office-spaces', name: 'Office spaces', type: 'space', food: '', def: 'monthly' },
      { id: 'banquets-and-event-spaces', name: 'Banquet Halls & Event Spaces', type: 'space', food: 'catered', def: 'catered' },
      { id: 'co-working-spaces', name: 'Co-working Spaces', type: 'space', food: '', def: 'monthly' },
      { id: 'meeting-rooms', name: 'Meeting Rooms', type: 'space', food: 'catered', def: 'hourly' },
      { id: 'business-setup', name: 'Business Setup', type: 'service', food: '', def: '' },
    ]

    let typeFilter = types.find(x => x.id == type);
    if (typeFilter) {
      food = typeFilter.food;
      def = typeFilter.def;
      service = typeFilter.type;
    }

    const sort = [
      { id: 'def', name: 'Most Relevant' },
      { id: 'rating', name: 'Highest Rating' },
      { id: 'new', name: 'Newest Added' },
    ];
    const terms = [
      { id: 'hourly', name: 'Hourly', isActive: false },
      { id: 'monthly', name: 'Monthly', isActive: false },
    ];
    const foodTerms = [
      { id: 'hourly', name: 'Room Rental Only', isActive: false },
      { id: 'catered', name: 'Room + Food & Beverages', isActive: false },
    ];

    if (service == "space") {
      if (food == 'catered') {
        filters.push({ id: 't', name: "Food Options", activeNum: 0, options: foodTerms, divName: "term_", singleSelect: true, ref: React.createRef(), boxRef: React.createRef() });
      } else {
        filters.push({ id: 't', name: "Term", activeNum: 0, options: terms, divName: "term_", singleSelect: true, ref: React.createRef(), boxRef: React.createRef() });
      }
    }

    if (service === 'service') {
      term = "service";
    } else {
      let filter = filters.find(x => x.id === 't');
      var option = null;
      if (search.t && filter.options.find(x => x.id === search.t)) {
        term = search.t;
        option = filter.options.find(x => x.id === term);
      } else {
        option = filter.options.find(x => x.id === def);
        if (option) {
          term = def;
        }
      }
      if (option) {
        option.isActive = true;
        filter.activeNum = filter.activeNum + 1;
      }
    }

    if (avFilters) {

      if (avFilters.prices && Object.keys(avFilters.prices).length > 0) {
        var prices = avFilters.prices;
        var tabs = [];
        var hasMin = false;
        if (term === 'monthly') {
          tabs = ['annually'];
          hasMin = false;
          minPrice = 0;
          maxPrice = (prices.max_month_price) ? Math.ceil(parseInt(prices.max_month_price)) : 300000;
        } else if (term === 'hourly') {
          if (type === 'co-working-spaces') {
            prices.max_half_day_amt = 0
          }
          if (prices.max_hour_amt && parseInt(prices.max_hour_amt) > 0) { tabs.push('hour'); }
          if (prices.max_half_day_amt && parseInt(prices.max_half_day_amt) > 0) { tabs.push('half_day'); }
          if (prices.max_day_amt && parseInt(prices.max_day_amt) > 0) { tabs.push('day'); }
          hasMin = true;
          minPrice = 0;
          maxPrice = (prices['max_' + tabs[0] + '_amt']) ? Math.ceil(parseInt(prices['max_' + tabs[0] + '_amt'])) : 1000;
        } else if (term === 'catered') {
          tabs = ['person'];
          hasMin = true;
          minPrice = 0;
          maxPrice = (prices.max_catered_price) ? Math.ceil(parseInt(prices.max_catered_price)) : 500;
        } else if (term === 'service') {
          tabs = ['cap'];
          minPrice = 0;
          maxPrice = (prices.max_total_price) ? Math.ceil(parseInt(prices.max_total_price)) : 100000;
          hasMin = false;
        }
        filters.push({ id: 'price', name: "Price", tabs: tabs, values: avFilters.prices, activeTab: tabs[0], activeNum: 0, minPrice: minPrice, maxPrice: maxPrice, defMinPrice: minPrice, defMaxPrice: maxPrice, ratio: (maxPrice / 100), hasMin: hasMin, divName: "price_", ref: React.createRef(), boxRef: React.createRef() });
      }

      if (avFilters.neighborhoods && avFilters.neighborhoods.length > 0) {
        var neighbors = [];
        avFilters.neighborhoods.forEach(item => {
          if (item.neighborhood && item.name) {
            neighbors.push({ id: item.neighborhood, name: item.name, isActive: false });
          }
        });
        filters.push({ id: 'n', name: "Neighborhood", activeNum: 0, options: neighbors, divName: "neighborhood_", ref: React.createRef(), boxRef: React.createRef() });
      }

      if (avFilters.license_zones && avFilters.license_zones.length > 0) {
        var zones = [];
        avFilters.license_zones.forEach(item => {
          if (item.id && item.name) {
            zones.push({ id: item.id, name: item.name, isActive: false });
          }
        });
        filters.push({ id: 'z', name: "Zone", activeNum: 0, options: zones, divName: "zone_", singleSelect: true, ref: React.createRef(), boxRef: React.createRef() });
      }
    }

    if (avFilters) {

      if (avFilters.venue_types && avFilters.venue_types.length > 0) {
        var venueTypes = [];
        avFilters.venue_types.forEach(item => {
          if (item.venue_type_id && item.name) {
            venueTypes.push({ id: item.venue_type_id, name: item.name, isActive: false });
          }
        });
        moreFilters.push({ id: 'v_t', name: "Venue type", activeNum: 0, options: venueTypes, divName: "venue_" });
      }
      if (avFilters.office_types && avFilters.office_types.length > 0) {
        var officeTypes = [];
        avFilters.office_types.forEach(item => {
          if (item.office_type_id && item.name) {
            officeTypes.push({ id: item.office_type_id, name: item.name, isActive: false });
          }
        });
        moreFilters.push({ id: 'o_t', name: "Office type", activeNum: 0, options: officeTypes, divName: "office_" });
      }
      if (avFilters.layouts && avFilters.layouts != false && Object.keys(avFilters.layouts).length > 0) {
        var layouts = [];
        Object.keys(avFilters.layouts).forEach(key => {
          layouts.push({ id: key, name: avFilters.layouts[key], url: getImage(key), isActive: false });
        });
        moreFilters.push({ id: 'l', name: "Layout", activeNum: 0, urls: layouts, divName: "layout_" });
      }
      if (avFilters.amenities && avFilters.amenities.length > 0) {
        var amenities = [];
        avFilters.amenities.forEach(item => {
          if (item.name) {
            amenities.push({ id: item.name.replace(/&/g, '%26'), name: item.name, isActive: false });
          }
        });
        moreFilters.push({ id: 'a', name: "Amenities", activeNum: 0, options: amenities, divName: "amenity_" });
      }
      if (avFilters.capacities && avFilters.capacities.length > 1) {
        var capacities = [];
        avFilters.capacities.forEach(item => {
          if (item.value && item.value != "") {
            capacities.push(item.value);
          }
        });
        moreFilters.push({ id: 'c', name: "Capacity", activeNum: 0, value: 0, values: capacities, divName: "capacity_" });
      }
      if (avFilters.license_types && avFilters.license_types.length > 0) {
        var licenseTypes = [];
        avFilters.license_types.forEach(item => {
          if (item.license_type && item.name) {
            licenseTypes.push({ id: item.license_type, name: item.name, isActive: false });
          }
        });
        moreFilters.push({ id: 'ls_t', name: "License Type", activeNum: 0, options: licenseTypes, divName: "license_" });
      }
      if (avFilters.license_authorities && avFilters.license_authorities.length > 0) {
        // Check for zone first
        var zone = "";
        var authorities = [];
        if (search.z) {
          let zFilter = filters.find(x => x.id == 'z');
          if (zFilter && zFilter.options && zFilter.options.find(x => x.id == search.z)) {
            zone = search.z;
          }
        }
        avFilters.license_authorities.forEach(item => {
          if (item.id && item.name && (zone == '' || zone == 'all' || item.zone == zone)) {
            authorities.push({ id: item.id, name: item.name, isActive: false });
          }
        });
        moreFilters.push({ id: 'ls_a', name: "License Authority", activeNum: 0, options: authorities, divName: "authority_" });
      }
      // if (avFilters.suitable_for && avFilters.suitable_for.length > 0) {
      //   moreFilters.push({ id: 's_f', name: "Suitable for", activeNum: 0, options: suitableFor, divName: "suitable_" });
      // }
      if (avFilters.days && avFilters.days.length > 0) {
        var availability = [];
        avFilters.days.forEach(item => {
          if (item.value && item.name) {
            availability.push({ id: item.value, name: item.name, isActive: false });
          }
        });
        moreFilters.push({ id: 'av', name: "Availability", activeNum: 0, options: availability, divName: "availabile_" });
      }
    }

    Object.keys(search).forEach(key => {
      var value = search[key];
      let filter = (key == "min_pr" || key == "max_pr" || key == "m_pr" || key == "pr_t") ? filters.find(x => x.id == 'price') : filters.find(x => x.id == key);
      if (filter) {
        if (!(value instanceof Array)) {
          value = [value];
        }
        if (filter.options) {
          if (key == "n") {
            value.forEach(item => {
              const fItem = (key == "n") ? item.replace(/-/g, ' ') : item
              const option = (key == "n") ? filter.options.find(x => x.name == fItem) : filter.options.find(x => x.id == fItem);
              if (option) {
                option.isActive = true;
                filter.activeNum += 1;
              }
            });
          }
        } else if (key == "min_pr") {
          filter.defMinPrice = parseInt(search.min_pr);
          filter.minPrice = parseInt(search.min_pr);
        } else if (key == "max_pr") {
          filter.maxPrice = parseInt(search.max_pr);
          filter.defMaxPrice = parseInt(search.max_pr);
        } else if (key == "m_pr") {
          filter.maxPrice = parseInt(search.m_pr);
          filter.defMaxPrice = parseInt(search.m_pr);
        } else if (key == "pr_t") {
          var prices = filter.values;
          maxPrice = (prices['max_' + search.pr_t + '_amt']) ? Math.ceil(parseInt(prices['max_' + search.pr_t + '_amt'])) : 1000;
          filter.activeTab = search.pr_t;
          filter.ratio = (maxPrice / 100);
        }
      } else {
        let moreFilter = moreFilters.find(x => x.id == key);
        if (moreFilter) {
          if (!(value instanceof Array)) {
            value = [value];
          }
          if (moreFilter.options || moreFilter.urls) {
            var options = (moreFilter.options) ? moreFilter.options : ((moreFilter.urls) ? moreFilter.urls : []);
            value.forEach(item => {
              let option = options.find(x => x.id == item);
              if (option) {
                option.isActive = true;
                moreFilter.activeNum += 1;
              }
            });
          } else if (moreFilter.values) {
            value.forEach(item => {
              let countVal = moreFilter.values.find(x => x == item);
              if (countVal) {
                moreFilter.value = countVal;
                moreFilter.activeNum += 1;
              }
            });
          }
        }
      }
    });

    if (match.params && match.params.city && match.params.type && match.params.city !== 'nearby') {

      if (search.show && (search.show === 'lt-popup' || search.show === 'ddr-popup')) {
        this.toggleShowMore((search.show === 'lt-popup') ? 'generic-lt' : 'generic-ddr');
      }
      if (term === 'monthly') {
        setTimeout(() => {
          this.toggleShowMore('generic-lt');
          // history.push(printLink(this.props.location.pathname, this.props.search, 'show', 'lt-popup'));
        }, 20000);
      } else if (term === 'service') {

      } else if (term === 'catered' || (term === 'hourly' && food === 'catered')) {
        setTimeout(() => {
          this.toggleShowMore('generic-ddr');
          // history.push(printLink(this.props.location.pathname, this.props.search, 'show', 'lt-popup'));
        }, 20000);
      }
    }

    this.setState({
      filters: filters,
      moreFilters: moreFilters,
      types: types,
      food: food,
      service: service,
      minPrice: minPrice,
      maxPrice: maxPrice,
      term: term,
      sort: sort,
    });
  }

  toggleShowMenu(open) {
    window.scrollTo(0, 0);
    this.setState({ isMapOpen: !this.state.isMapOpen });
  }

  toggleMoreFilter(open) {
    if (open) {
      document.body.classList.add("no-scroll");
      document.getElementById("more-filters").classList.add("show-flex");
    } else {
      document.body.classList.remove("no-scroll");
      document.getElementById("more-filters").classList.remove("show-flex");
    }
  }

  toggleShowMore(type = null) {

    if (type) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    this.setState({ showMore: type });
  }

  handleTapFilter(id) {

    if (id !== 'more') {
      document.getElementById(id + "-filter-btn").classList.add("focused");
      this.setState({ filterBox: id }, () => document.getElementById(id + "-filter").classList.add("show"));
      return;
    }

  }

  moreFiltersActive() {

    var activeCount = 0;
    this.state.moreFilters.forEach(moreFilter => {
      if (moreFilter.activeNum > 0) {
        activeCount += 1
      }
    });
    return activeCount;
  }

  clearFilter(filterId) {

    if (filterId == "more") {

      var moreOptionsChecked = {};
      var moreFilters = this.state.moreFilters;
      moreFilters.forEach(moreFilter => {
        if (moreFilter.options) {
          moreFilter.options.forEach(option => {
            option.isActive = false;
            if (moreFilter.activeNum > 0) {
              moreFilter.activeNum -= 1;
            }
          });
        } else if (moreFilter.values) {
          document.getElementById(moreFilter.divName + "-count").innerHTML = 0;
          moreFilter.value = 0;
        }
        moreOptionsChecked[moreFilter.id] = {};
      });
      this.setState({ filterBox: '' });
      this.props.history.push(printLinks(this.props.location.pathname, this.props.location.search, moreOptionsChecked, true));
      return;
    }

    var filter = this.state.filters.find(x => x.id == filterId);
    if (filter) {
      if (filter.options) {
        filter.options.forEach(x => {
          x.isActive = false
          if (filter.activeNum > 0) {
            filter.activeNum -= 1;
          }
        });
      } else if (filterId == "price") {
        filter.defMinPrice = this.state.minPrice;
        filter.defMaxPrice = this.state.maxPrice;
        filter.minPrice = this.state.minPrice;
        filter.maxPrice = this.state.maxPrice;
        if (filter.hasMin) {
          document.getElementById("min-price").innerHTML = this.state.minPrice;
        }
        document.getElementById("max-price").innerHTML = this.state.maxPrice;
      }
    }
    document.getElementById(filterId + "-filter-btn").classList.remove("focused");
    document.getElementById(filterId + "-filter").classList.remove("show");
    this.setState({ filterBox: '' });
    this.props.history.push(printLinkRemoved(this.props.location.pathname, this.props.location.search, filterId));
  }

  applyFilter(filterId, id = null) {

    let pathname = this.props.location.pathname;
    let search = this.props.location.search;

    switch (filterId) {

      case 'type':
        document.getElementById("type-filter-btn").classList.remove("focused");
        document.getElementById("type-filter").classList.remove("show");
        this.props.history.push("/search/" + this.props.match.params.city + "/" + id);
        break;

      case 's':
      case 't':
      case 'z':
        var filter = this.state.filters.find(x => x.id == filterId);
        if (filter && filter.options) filter.options.forEach(x => { if (x.id != id) x.isActive = false; });
        document.getElementById(filterId + "-filter-btn").classList.remove("focused");
        document.getElementById(filterId + "-filter").classList.remove("show");
        if (filterId == 't') {
          var tOptions = { t: id, m_pr: 0, min_pr: 0, max_pr: 0, pr_t: "" };
          this.props.history.push(printLinks(pathname, search, tOptions, true));
        } else {
          this.props.history.push(printLink(pathname, search, filterId, id, true));
        }
        break;

      case 'price':
        var priceOptions = {};
        var originMinPrice = this.state.minPrice;
        var originMaxPrice = this.state.maxPrice;
        var priceFilter = this.state.filters.find(x => x.id == filterId);
        let minPrice = (priceFilter.hasMin) ? parseInt(document.getElementById("min-price").innerHTML) : 0;
        let maxPrice = parseInt(document.getElementById("max-price").innerHTML);
        if (priceFilter) {

          if (priceFilter.hasMin) {
            priceFilter.defMinPrice = minPrice;
            priceFilter.minPrice = minPrice;
          }
          priceFilter.defMaxPrice = maxPrice;
          priceFilter.maxPrice = maxPrice;

          if (priceFilter.activeTab == 'annually' || priceFilter.activeTab == 'cap') {
            priceOptions.m_pr = (maxPrice < originMaxPrice) ? maxPrice : 0;
            priceOptions.min_pr = 0;
            priceOptions.max_pr = 0;
            priceOptions.pr_t = "";
          } else {
            if ((minPrice > originMinPrice) || (maxPrice < originMaxPrice)) {
              priceOptions.min_pr = minPrice;
              priceOptions.max_pr = maxPrice;
              priceOptions.pr_t = priceFilter.activeTab;
            } else {
              priceOptions.min_pr = 0;
              priceOptions.max_pr = 0;
              priceOptions.pr_t = "";
            }
            priceOptions.m_pr = 0;
          }

          document.getElementById(filterId + "-filter-btn").classList.remove("focused");
          document.getElementById(filterId + "-filter").classList.remove("show");
        }
        this.props.history.push(printLinks(pathname, search, priceOptions, true));
        break;

      case 'more':
        var moreOptionsChecked = {};
        let moreFilters = this.state.moreFilters;
        moreFilters.forEach(moreFilter => {
          if (moreFilter.options || moreFilter.urls) {
            var optionChecked = {};
            var options = (moreFilter.options) ? moreFilter.options : ((moreFilter.urls) ? moreFilter.urls : []);
            options.forEach(option => {
              let checked = document.getElementById(moreFilter.divName + option.id + "-checkbox").checked;
              if (checked) {
                optionChecked[option.id] = true;
              }
              option.isActive = checked;
            });
            moreOptionsChecked[moreFilter.id] = optionChecked;
          } else if (moreFilter.values) {
            let count = parseInt(document.getElementById(moreFilter.divName + "-count").innerHTML);
            moreFilter.value = count;
            moreOptionsChecked[moreFilter.id] = count;
          }
        });
        this.props.history.push(printLinks(pathname, search, moreOptionsChecked, true));
        this.toggleMoreFilter(false);
        break;

      default:
        var optionsChecked = {};
        var otherFilter = this.state.filters.find(x => x.id == filterId);
        if (otherFilter && otherFilter.options) {
          otherFilter.options.forEach(x => {
            let checked = document.getElementById(otherFilter.divName + x.id + "-checkbox").checked;
            if (checked) {
              if (filterId == "n") {
                const fName = x.name.replace(/\s+/g, '-');
                optionsChecked[fName] = true;
              } else {
                optionsChecked[x.id] = true;
              }
            }
            x.isActive = checked;
          });
        }
        document.getElementById(filterId + "-filter-btn").classList.remove("focused");
        document.getElementById(filterId + "-filter").classList.remove("show");
        this.props.history.push(printLink(pathname, search, filterId, Object.keys(optionsChecked), true));
        break;
    }
  }

  handleFilterCheck(filter, id) {

    if (filter.id == 'type' || filter.id == 's') {
      return;
    }

    if (filter.options) {
      if (filter.singleSelect) {
        //   filter.options.forEach(x => {
        //     if (x.id === id) {
        //       document.getElementById(filter.divName+x.id+"-checkbox").checked = true;
        //     } else {
        //       document.getElementById(filter.divName+x.id+"-checkbox").checked = false;
        //     }
        //   });
        this.applyFilter(filter.id, id);
      } else {
        let checked = document.getElementById(filter.divName + id + "-checkbox").checked;
        document.getElementById(filter.divName + id + "-checkbox").checked = !checked;
      }
    }
  }

  handleMoreFilterCheck(filter, id) {

    // if (filter.options) {
    // if (filter.id == 'term') {
    //   options.forEach(x => {
    //     if (x.id !== id) document.getElementById(filter.divName+x.id+"-checkbox").checked = false
    //   });
    // }
    // }
    let checked = document.getElementById(filter.divName + id + "-checkbox").checked;
    document.getElementById(filter.divName + id + "-checkbox").checked = !checked;
  }

  createFilterBox(filter, currency) {

    var elements = [];

    if (filter.id == 'price') {

      elements.push(
        <>
          <div className="show-flex justify-content-center mt-2 minw-350">
            <div className="btn-group fl-btn-group" role="group">
              {
                (filter.tabs.length > 1) ? (
                  filter.tabs.map(item => {
                    return (<button type="button" className={"btn btn-outline-primary fl-btn " + ((filter.activeTab == item) ? "active" : "")} onClick={() => this.handlePriceType(item)}>{printLocal(item)}</button>)
                  })
                ) : (
                  <div className="box-head">{printLocal(filter.tabs[0])}</div>
                )
              }
            </div>
          </div>

          {(filter.hasMin) ? (
            <>
              <TwoThumbsSlider key={filter.activeTab + "-" + filter.defMinPrice + "-" + filter.defMaxPrice}
                onChange={(e, newVal) => this.handleChangePrice(e, newVal, true)}
                defaultValue={[filter.defMinPrice / filter.ratio, filter.defMaxPrice / filter.ratio]} />
              <div className="slider-div">
                <div className="price mr-4">
                  <span className="input-group-text border-0">{currency.icon} <span id="min-price">{printPriceOnly(filter.minPrice, 'USD', currency.name)}</span></span>
                </div>
                <span>-</span>
                <div className="price ml-4">
                  <span className="input-group-text border-0">{currency.icon} <span id="max-price">{printPriceOnly(filter.maxPrice, 'USD', currency.name)}</span></span>
                </div>
              </div>
            </>
          ) : (
            <>
              <OneThumbSlider
                step={filter.defMaxPrice / (filter.ratio * 20)}
                onChange={(e, newVal) => this.handleChangePrice(e, newVal, false)}
                defaultValue={filter.maxPrice / filter.ratio} />
              <div className="slider-div">
                <div className="price mr-4 ml-4">
                  <span className="input-group-text border-0">{currency.icon} <span id="max-price">{printPriceOnly(filter.maxPrice, 'USD', currency.name)}</span></span>
                </div>
              </div>
            </>
          )}

        </>);
      return elements;
    }
    if (filter.options) {
      filter.options.map((item, i) => {
        elements.push(
          <div key={i} className="dropdown-item" onClick={() => this.handleFilterCheck(filter, item.id)}>
            <input id={filter.divName + item.id + "-checkbox"} name={filter.divName + item.id + "-checkbox"} className="form-check-input" type="checkbox" value="" defaultChecked={(item.isActive)} onChange={() => this.handleFilterCheck(filter, item.id)} />
            <span className="title">{item.name}</span>
          </div>);
      });
      return elements;
    }
  }

  createMoreFilterOptions(filter) {
    console.log({ filter })
    var elements = [];
    if (filter.urls) {
      filter.urls.map((item, i) => {
        elements.push(
          <div id={"test"} key={i} className="col-12 col-sm-6 p-0" onClick={() => this.handleMoreFilterCheck(filter, item.id)}>
            <div className="dropdown-item">
              <input id={filter.divName + item.id + "-checkbox"} name={filter.divName + item.id + "-checkbox"} className="form-check-input" type="checkbox" value="" defaultChecked={(item.isActive)} onChange={() => this.handleMoreFilterCheck(filter, item.id)} />
              <img className="title" src={item.url} />
            </div>
          </div>);
      });
      return elements;
    }
    if (filter.values) {
      elements.push(
        <div className="more-counter">
          <p>Capacity</p>
          <span className="page-link page-btn text-center d-inline-block disabled" onClick={() => this.handleUpdateValue(filter, 'sub')}>
            <span aria-hidden="true">-</span>
          </span>
          <span id={filter.divName + "-count"} className="counter">{filter.value}</span>
          <span className="page-link page-btn text-center d-inline-block" onClick={() => this.handleUpdateValue(filter, 'add')}>
            <span aria-hidden="true">+</span>
          </span>
        </div>);
      return elements;
    }
    if (filter.options) {
      filter.options.map((item, i) => {

        elements.push(
          <div key={i} className={clsx("col-12 col-sm-6 p-0", { "d-none": parseFloat(item.id) === 8 })} onClick={() => {
            this.handleMoreFilterCheck(filter, item.id)
            if (['1', '2', '3'].includes(item.id) && filter.options.some(({ id }) => id === '8')) this.handleMoreFilterCheck(filter, '8')
          }}>
            <div className="dropdown-item">
              <input id={filter.divName + item.id + "-checkbox"} name={filter.divName + item.id + "-checkbox"} className="form-check-input" type="checkbox" value="" defaultChecked={(item.isActive)} onChange={() => this.handleMoreFilterCheck(filter, item.id)} />
              <span className="title">{item.name}</span>
            </div>
          </div>);
      });
      return elements;
    }
    if (filter.buttons) {
      filter.buttons.map((item, i) => {
        elements.push(
          <div key={i} className="" onClick={() => this.handleMoreFilterCheck(filter, item.id)}>
            <div className="dropdown-item">{item.name}</div>
          </div>);
      });
      return elements;
    }
  }

  handleGeneralReq(e) {
    e.preventDefault();

    this.setState({ reqLoading: true });

    const { showMore, request, errors } = this.state;
    const data = new FormData(e.target);

    request.fName = data.get("fname");
    request.lName = data.get("lname");
    request.email = data.get("email");
    request.phone = data.get("phone");
    request.company = data.get("company");

    errors.fName = formValidation('fname', request.fName);
    errors.lName = formValidation('lname', request.lName);
    errors.email = formValidation('email', request.email);
    errors.phone = formValidation('phone', request.phone);
    errors.company = formValidation('company', request.company, false);

    Object.keys(errors).forEach(key => { if (!errors[key]) delete errors[key]; });

    this.setState({ errors: errors });
    if (Object.keys(errors).length > 0) {
      this.setState({ reqLoading: false });
      return;
    }

    const url = getApiLinkV2('workspace/lt-request');
    const token = getToken();
    const headers = { 'Content-Type': 'application/json' };
    if (token) {
      headers['Authorization'] = 'Bearer ' + token;
    }

    getAxios().post(url, {
      rq_wid: 0,
      ri_first_name: request.fName,
      ri_last_name: request.lName,
      ri_email: request.email,
      ri_phone: request.phone,
      ri_company: request.company,
      source: 'search',
    }, {
      headers: headers,
    }).then(response => {
      document.getElementById('fname').value = '';
      document.getElementById('lname').value = '';
      document.getElementById('email').value = '';
      document.getElementById('phone').value = '';
      document.getElementById('company').value = '';
      createAlert(this, 'success', '', 'Your request has been sent successfully .');
      this.setState({ requestSent: true, reqLoading: false });
      this.toggleShowMore();
    }).catch(error => {
      Bugsnag.notify(error);
      createAlert(this, 'danger', (error.response && error.response.data.message) ? 'Error: ' : '', (error.response && error.response.data.message) ? error.response.data.message : error.message);
      this.setState({ reqLoading: false });
    });
  }

  redirectScreen() {
    this.setState({ redirect: true });
  }
  handleNextBtn(e) {
    e.preventDefault();

    this.setState({ reqLoading: true });

    const { match, location, history, generic } = this.props;
    const { space, countryCode, request, errors } = this.state;
    const data = new FormData(e.target);

    request.fName = data.get("fname");
    request.lName = data.get("lname");
    request.email = data.get("email");
    request.phone = data.get("phone");
    request.company = data.get("company");

    const user = getUser();
    // if (request.phone && request.phone.length > 0) {
    //   if (request.phone[0] === '0') {
    //     request.phone = request.phone.substring(1);
    //   }
    //   const code = countryCode ? '+'+countryCode : (user && user.country) ? ('+'+getCountryCode(user.country)[3]) : '+971';
    //   request.phone = code+request.phone;
    // }


    errors.fName = formValidation('fname', request.fName);
    errors.lName = formValidation('lname', request.lName);
    errors.email = formValidation('email', request.email);
    errors.phone = formValidation('phone', request.phone);
    errors.company = formValidation('company', request.company, false);

    Object.keys(errors).forEach(key => {
      if (!errors[key]) delete errors[key];
    });

    this.setState({ errors: errors });
    if (Object.keys(errors).length > 0) {
      this.setState({ reqLoading: false });
      return;
    }
    let phone = phoneUtil.parse(request.phone)
    request.phone = phoneUtil.format(phone, PNF.E164)

    const url = getApiLinkV2('workspace/lt-request');
    const token = getToken();
    const headers = { 'Content-Type': 'application/json' };
    if (token) {
      headers['Authorization'] = 'Bearer ' + token;
    }

    if(window.hasOwnProperty('gtag')) {
      window?.gtag('event', 'conversion', {'send_to': 'AW-935885959/_XkwCMLm9vUCEIf5ob4D'});
      window?.gtag('event', 'conversion', {'send_to': 'AW-935885959/6952CO6k9fUCEIf5ob4D'});
    }

    const source = getSource();

    getAxios().post(url, {
      rq_wid: (generic) ? 0 : match.params.id,
      ri_first_name: request.fName,
      ri_last_name: request.lName,
      ri_email: request.email,
      ri_phone: request.phone,
      ri_company: request.company,
      source: source,
    }, {
      headers: headers,
    }).then(response => {
      this.setState({ reqLoading: false });

      const spaceType = location.pathname.indexOf('lt-office') !== -1 ? 'Office Space' : 'Co-Working Space';
      var eventLabel = spaceType + ' LT Success from ' + source;
      // if (!generic) eventLabel = eventLabel+' for space '+space.workspace_id;
      ReactGA.event({ category: 'LT', action: 'LT_Success' + (generic ? '_Generic' : '_Unique'), label: eventLabel });

      var baseUrl = '/request/lt-office/generic/success';
      const user = getUser();
      let FirstNameUser;
      let LastNameUser;
      let PhoneNumberUser;
      let emailAddressUser;
      if (!user) {
        FirstNameUser = request.fName;
        LastNameUser = request.lName;
        PhoneNumberUser = request.phone;
        emailAddressUser = request.email;
      } else {
        FirstNameUser = user?.first_name;
        LastNameUser = user?.last_name;
        PhoneNumberUser = user?.phone;
        emailAddressUser = user?.email;
      }
      if (window.location.href.includes('lt-office') || window.location.href.includes('office-spaces')) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "Offices_LT_Submission",
          'First Name': FirstNameUser,
          'Last Name': LastNameUser,
          'Email': emailAddressUser,
          'Phone': PhoneNumberUser
        });
      }

      if (window.location.href.includes('lt-coworking') || window.location.href.includes('co-working-spaces')) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "Coworking_LT_Submission",
          'First Name': FirstNameUser,
          'Last Name': LastNameUser,
          'Email': emailAddressUser,
          'Phone': PhoneNumberUser
        });
      }
      if (window.location.href.includes('lt-coworking') && !window.location.href.includes('generic')) {
        baseUrl = '/request/lt-coworking/' + match.params.id + '/success';
      }
      if (window.location.href.includes('lt-office') && !window.location.href.includes('generic')) {
        baseUrl = '/request/lt-office/' + match.params.id + '/success';
      }
      if (window.location.href.includes('lt-coworking') && window.location.href.includes('generic')) {
        baseUrl = '/request/lt-coworking/generic/success';
      }
      if ((window.location.href.includes('lt-coworking') || window.location.href.includes('co-working-spaces')) && !window.location.href.includes('generic')) {
        baseUrl = '/request/lt-coworking/generic/success';
      }
      if (window.location.href.includes('lt-office') && window.location.href.includes('generic')) {
        baseUrl = '/request/lt-office/generic/success';
      }
      history.push(baseUrl);
      // window.location.href = (baseUrl);
    }).catch(error => {
      Bugsnag.notify(error);
      createAlert(this, 'danger', (error.response && error.response.data.message) ? 'Error: ' : '', (error.response && error.response.data.message) ? error.response.data.message : error.message);
      this.setState({ reqLoading: false });
    });
  }
  render() {

    var moreFiltersCount = this.moreFiltersActive();
    const { match, location, defCurrency } = this.props;
    const { redirect, showMore, isMapOpen, isMobileView, errors, reqAlert, reqLoading } = this.state;
    const user = getUser();
    const currency = getCurrency(defCurrency);

    if (redirect) {
      return (<Redirect to={{ pathname: '/login', state: { prevLocation: location } }} />);
    }

    return (
      <>

        <div id="container" className={(this.state.isMapOpen) ? "container-fluid mt-80 pr-0 pl-0" : "container-fluid contain mt-80"}>

          <section className="search-results">

            <div className="row m-0 p-0">

              <div id="results-container" className={(this.state.isMapOpen) ? "results-container col-6 mt-50 pr-4 pl-4" : "results-container col-12 mt-50 "}>

                <div className="display-flex">

                  {(match.params && match.params.city && match.params.city != 'nearby') ? (
                    <h1 id="section-title" className="section-title search-title">
                      {(match.params.type != null) ? printLocal(match.params.type) : "Spaces"} in {printLocal(match.params.city)}
                    </h1>
                  ) : (
                    <h1 id="section-title" className="section-title">
                      {(match.params.type != null) ? printLocal(match.params.type) + " Nearby" : "Spaces in Dubai"}
                    </h1>
                  )}

                  {(isMobileView && match.params && match.params.type != null && match.params.city && match.params.city != 'nearby' && (
                    <>
                      <div ref={this.sortFilterRef} id="s-filter-btn" className="sort-btn mt-0 mb-auto mr-0 ml-0"
                        onClick={() => this.handleTapFilter('s')}>
                        <FontAwesomeIcon icon={faSort} />
                        <span>Sort by</span>
                      </div>
                      <div ref={this.sortFilterBoxRef} id="s-filter" className="filter-box sort-box">
                        {this.state.sort.map((item, i) => (
                          <div key={i} className="dropdown-item" onClick={() => this.applyFilter('s', item.id)}>
                            <input id={item.id + "-checkbox"} name={item.id + "-checkbox"} className="form-check-input" type="checkbox" value="" defaultChecked={(queryString.parse(location.search).s == item.id) || (item.id == "def" && queryString.parse(location.search).s == null)} onChange={() => this.applyFilter("s", item.id)} />
                            <span className="title">{item.name}</span>
                          </div>
                        ))}
                      </div>
                    </>
                  ))}

                </div>


                {(this.state.isLoading) ? (

                  <h6 className="section-subtitle">Loading...</h6>

                ) : (
                  <>
                    {/*<h6 className="section-subtitle">{(this.state.spacesCount > 500) ? "+500" : this.state.spacesCount} {(match.params.type == 'business-setup') ? 'services' : 'spaces'}</h6>*/}

                    {(!match.params.city || match.params.city && match.params.city != 'nearby') ? (

                      <div className="filters-cont">
                        <div id="filters" className={(this.state.isMapOpen) ? "filters all grow" : "filters all"}>

                          <div className="btn-div">
                            <button ref={this.typesFilterRef} id={"type-filter-btn"} className={(match.params.type) ? "filter active" : "filter"} type="button" onClick={() => this.handleTapFilter("type")}>
                              {(match.params.type) ? printLocal(match.params.type) : "Space type"}
                            </button>
                            <div ref={this.typesFilterBoxRef} id={"type-filter"} className="filter-box">
                              <div className="cont">
                                {/*<div className="dropdown-head">*/}
                                {/*  <span className="dropdown-title">Spaces</span>*/}
                                {/*</div>*/}
                                {this.state.types.map((item, i) => {
                                  if (item.type == "service") return (<></>)
                                  return (
                                    <div key={i} className="dropdown-item" onClick={() => this.applyFilter("type", item.id)}>
                                      <input id={item.id + "-checkbox"} name={item.id + "-checkbox"} className="form-check-input" type="checkbox" value="" defaultChecked={(item.id == match.params.type)} onChange={() => this.applyFilter("type", item.id)} />
                                      <span className="title">{item.name}</span>
                                    </div>)
                                })}
                                {/*<div className="dropdown-head">*/}
                                {/*  <span className="dropdown-title">Services</span>*/}
                                {/*</div>*/}
                                {/*{this.state.types.map((item, i) => {*/}
                                {/*  if (item.type == "space") return (<></>)*/}
                                {/*  return (<div key={i} className="dropdown-item" onClick={() => this.applyFilter("type", item.id)}>*/}
                                {/*    <input id={item.id+"-checkbox"} name={item.id+"-checkbox"} className="form-check-input" type="checkbox" value="" defaultChecked={(item.id == match.params.type)} onChange={() => this.applyFilter("type", item.id)} />*/}
                                {/*    <span className="title">{item.name}</span>*/}
                                {/*  </div>)*/}
                                {/*})}*/}
                              </div>
                            </div>
                          </div>

                          {(match.params && match.params.type != null && match.params.city && match.params.city != 'nearby') ? (
                            <>
                              {this.state.filters.map((item, i) => (
                                <div key={i} className="btn-div">
                                  <button ref={item.ref} id={item.id + "-filter-btn"} className={((item.activeNum > 0 && item.options.find(x => x.isActive) && item.options.find(x => x.isActive).id != 'all') || (item.id ==
                                    'price' && (item.minPrice != this.state.minPrice || item.maxPrice != this.state.maxPrice))) ? "filter active" : "filter"} type="button" onClick={() => this.handleTapFilter(item.id)}>

                                    {(item.activeNum == 0) ?
                                      item.name :
                                      ((item.options && item.activeNum == 1) ?
                                        ((item.options.find(x => x.isActive) && item.options.find(x => x.isActive).id != 'all') ? item.options.find(x => x.isActive).name : item.name) :
                                        item.name + " (" + item.activeNum + ")"
                                      )}

                                  </button>
                                  <div ref={item.boxRef} id={item.id + "-filter"} className="filter-box">
                                    <div className="cont">
                                      {(this.state.filterBox == item.id) ? this.createFilterBox(item, currency) : (<></>)}
                                      {/* {(this.state.filterBox == 'price') ? document.getElementsByClassName('MuiSlider-thumb[data-index=0]')[0].style.display = "none" : ""} */}
                                    </div>
                                    {(!item.singleSelect && (
                                      <>
                                        <div className="dropdown-divider"></div>
                                        <div className="filter-bottom">
                                          <button className="custom-btn clear-btn ml-4 mt-2 mb-0" type="button" onClick={() => this.clearFilter(item.id)}>Clear</button>
                                          <button className="custom-btn save" type="button" onClick={() => this.applyFilter(item.id)}>Save</button>
                                        </div>
                                      </>
                                    ))}
                                  </div>
                                </div>
                              ))}
                              {(this.state.moreFilters.length > 0 && (
                                <div className="btn-div">
                                  <button id={"more-filter-btn"} className={(moreFiltersCount > 0) ? "filter active" : "filter"} type="button" onClick={() => this.toggleMoreFilter(true)}>
                                    {(moreFiltersCount > 0) ? "More filters (" + moreFiltersCount + ")" : "More filters"}
                                  </button>
                                </div>))}
                            </>
                          ) : (
                            <></>
                          )}

                        </div>

                        {(!isMobileView && match.params && match.params.type != null && match.params.city && match.params.city != 'nearby' && (
                          <div id="filters-map" className={(isMapOpen) ? "map" : "map shrink"}>
                            <div className={"map-btn " + ((isMapOpen || isMobileView) ? "hide" : " ")} onClick={() => this.toggleShowMenu(true)}>
                              <FontAwesomeIcon icon={faMap} />
                              <span>Show map</span>
                            </div>
                            <div ref={this.sortFilterRef} id="s-filter-btn" className="sort-btn float-right-mobile"
                              onClick={() => this.handleTapFilter('s')}>
                              <FontAwesomeIcon icon={faSort} />
                              {/* {(queryString.parse(location.search).sort) ? (
                            <span>{printLocal(queryString.parse(location.search).sort)}</span>
                          ) : ( */}
                              <span>Sort by</span>
                              {/* )} */}
                            </div>

                            <div ref={this.sortFilterBoxRef} id="s-filter" className="filter-box sort-box">
                              {
                                this.state.sort.map((item, i) => (
                                  <div key={i} className="dropdown-item" onClick={() => this.applyFilter('s', item.id)}>
                                    <input id={item.id + "-checkbox"} name={item.id + "-checkbox"} className="form-check-input" type="checkbox" value="" defaultChecked={(queryString.parse(location.search).s == item.id) || (item.id == "def" && queryString.parse(location.search).s == null)} onChange={() => this.applyFilter("s", item.id)} />
                                    <span className="title">{item.name}</span>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        ))}

                      </div>

                    ) : (
                      <div className="col-12 mt-5"></div>
                    )}

                  </>)}

                <div className="results row mb-5">

                  <SearchApi key={location.pathname + location.search} isMapOpen={this.state.isMapOpen}
                    location={location} pathname={location.pathname} search={location.search}
                    params={match.params} service={this.state.service} term={this.state.term}
                    food={this.state.food} currency={currency} isMobileView={window.innerWidth < 768}
                    updateResults={this.updateResults} onMouseOver={this.handleSpaceMouseOver}
                    onMouseOut={this.handleSpaceMouseOut} allowSpaceLink={true} />

                </div>

                {(match.params && match.params.type != null && match.params.city && match.params.city != 'nearby') ? (
                  <div id="float-map-btn" className={"float-map-btn " + ((!this.state.isMapOpen && this.state.isMobileView) ? " " : "hide")} onClick={() => this.toggleShowMenu(true)}>
                    <FontAwesomeIcon icon={faMap} />
                    <span>Show map</span>
                  </div>
                ) : (
                  <></>
                )}

              </div>

            </div>

          </section>

          <div id="map-container" className={"col-6 m-0 p-0 map-fixed " + ((this.state.isMapOpen) ? " " : "hide")}>
            <div className="map-div">
              <div className="close-btn" onClick={() => this.toggleShowMenu(false)}>
                <FontAwesomeIcon icon={faAngleDoubleRight} />
              </div>
              {/*<LoadScript googleMapsApiKey='AIzaSyBDM449V7qE4OkyoV2zP3xm_VQIYDvrHVw'>*/}
              <GoogleMap
                onLoad={(map) => { this.mapRef = map; }}
                options={{
                  styles: styles,
                  keyboardShortcuts: false,
                  scaleControl: true,
                  scrollwheel: true,
                  draggable: true,
                  typeControl: true,
                  gestureHandling: "greedy",
                  mapTypeControl: true,
                  mapTypeControlOptions: { position: 1 },
                  zoomControl: true,
                  zoomControlOptions: { position: ((window.innerWidth < 441) ? 7 : 8) },
                  maxZoom: 11,
                }}
                mapContainerStyle={{ height: "100%", width: "100%" }}
                zoom={11}
                center={this.state.city.location}
                onClick={this.handleMapTapped}>
                {
                  this.state.spaces.map(space => {
                    return (
                      <Marker key={space.workspace_id} name={space.workspace_id} space={space} position={{ lat: parseFloat(space.latitude), lng: parseFloat(space.longitude) }} icon={(this.state.activeMarker == space.workspace_id) ? markerActIcon : markerIcon} onClick={() => this.handleSelectMarker(space)} onMouseOver={() => this.handleSpaceMouseOver(space)} onMouseOut={() => this.handleSpaceMouseOut(space)} />
                    )
                  })
                }

                {
                  this.state.activeSpace && (
                    <InfoWindow
                      anchor={null}
                      position={{ lat: parseFloat(this.state.activeSpace.latitude - 0.05), lng: parseFloat(this.state.activeSpace.longitude) }}
                      clickable={true}
                      onCloseClick={this.handleUnSelectSpace}
                      visible={this.state.showingMapSpace}>
                      <Space id={this.state.activeSpace.id} isMapView={true} isMapOpen={false}
                        space={this.state.activeSpace} term={this.state.term} service={this.state.service}
                        food={this.state.food} redirectScreen={this.redirectScreen} currency={currency}
                        allowLink={true} />
                    </InfoWindow>
                  )
                }
              </GoogleMap>
              {/*</LoadScript>*/}
            </div>

            <div id="floating-spaces" className={"floating-spaces " + ((this.state.isMobileView) ? " " : "hide")}>
              {
                this.state.spaces.map(space => <Space id={space.id} term={this.state.term} service={this.state.service} food={this.state.food} isMapView={true} isMapOpen={false} space={space} onMouseOver={() => this.handleSpaceMouseOver(space)} onMouseOut={() => this.handleSpaceMouseOut(space)} redirectScreen={this.redirectScreen} currency={currency} />)
              }
            </div>

          </div>

        </div>

        <div id="more-filters" className="more-filter fz-17 justify-content-center">
          <div ref={this.moreFilterRef} className="more-box align-self-center">

            <div className="more-header">
              <h5 className="center">More filters</h5>
              <div className="dropdown-divider mt-4 mb-0"></div>
            </div>

            <div className="more-contain">

              {
                this.state.moreFilters.map((item, i) => (
                  <div key={i} id={item.id} className="row">
                    <div className="col-12 p-0">
                      <div className="sec-title">{item.name}</div>
                    </div>
                    {this.createMoreFilterOptions(item)}
                  </div>
                ))
              }

            </div>

            <div className="more-bottom">
              <div className="dropdown-divider mb-3 mt-0"></div>
              <button className="custom-btn clear-btn ml-4 mt-2 mb-0" type="button" onClick={() => this.clearFilter('more')}>Clear</button>
              <button className="custom-btn save" type="button" onClick={() => this.applyFilter('more')}>Save</button>
            </div>
          </div>
        </div>


        {this.state.width <= 750 && window.location.href.includes('meeting-rooms') ?
              this.state.show_popUp_office_spaces &&
              <div className={"more-filter fz-17 justify-content-center " + ((showMore) ? "show-flex" : "")}>
                {window.location.href.includes('meeting-rooms') ?
                  <div ref={this.showMoreRef} className="popup-send-requests-mobile align-self-center">
                    <img className="meeting-room-circular-div-img-mobile-sizes" src={meeting_rooms_popup_image} alt="find your meeting room" />
                    <div className='modal-right-block'>
                    <a href="tel:+971 50 3263932">
                      <img className="meeting-room-popup-phone-call-div-img-mobile" src={offices_popup_image_phone_logo} alt="Call US" />
                    </a>
                    {window.location.href.includes('meeting-rooms') ? <></> : <div className="dropdown-divider mt-4 mb-0" />}
                    {/* </div> */}
                    <div className="more-contain">
                      {(showMore === 'generic-ddr') && window.location.href.includes('meeting-rooms') ?
                        <div class="">
                          <button class="close" aria-label="Dismiss alert" type="button" data-close>
                            <span onClick={() => {
                              this.show_popUp_office_spaces();
                            }} aria-hidden="true">&times;</span>
                          </button>
                          {this.state.width <= 455 && this.state.width >= 340 ? <h2 className="meeting-room-popup-send-requests-title-mobile-less-455">CAN'T FIND THE RIGHT <br></br> MEETING ROOM TO <br></br> HOST YOUR BUSINESS<br></br> MEETING?</h2> : this.state.width <= 340 ? <h2 className="meeting-room-popup-send-requests-title-mobile-less-455">CAN'T FIND THE<br></br> RIGHT  MEETING<br></br> ROOM TO HOST<br></br> YOUR BUSINESS<br></br> MEETING?</h2> : <h2 className="meeting-room-popup-send-requests-title-mobile">CAN'T FIND THE RIGHT <br></br> MEETING ROOM TO <br></br> HOST YOUR BUSINESS<br></br> MEETING?</h2>}
                          {this.state.width <= 630 && this.state.width > 495 && this.state.width > 400 ? <><h6 className='meeting-room-popUpform-body-inner-txt-mobile'>Submit your request and let us help<br></br> you find the meeting room that matches<br></br> your preference and business requirements</h6><br></br></> : this.state.width <= 495 && this.state.width > 400 ? <h6 className='meeting-room-popUpform-body-inner-txt-mobile-less-495'>Submit your request and let us help<br></br> you find the meeting room that matches<br></br> your preference and business requirements</h6> : this.state.width <= 400 ? <h6 className='meeting-room-popUpform-body-inner-txt-mobile-less-400'>Submit your request and<br></br> let us help you find<br></br> the meeting room that<br></br> matches your preference<br></br> and business requirements</h6> : <h6 className='meeting-room-popUpform-body-inner-txt-mobile'>Submit your request and let us help<br></br> you find the meeting room that matches<br></br> your preference and business requirements</h6>}
                          <Link to="/request/catered-meeting/generic?source=search">
                            <center>
                              <button type="submit" className='meeting-room-popup-form-btn-style-mobile btn btn-lg btn-primary fl_btn submit-btn'>
                                Send Request
                              </button>
                            </center>
                          </Link>
                        </div>
                        : (showMore === 'generic-lt') ?
                          (
                            <div className="form-general p-0">
                              <div className="row mt-3 mb-4">
                                <div className="col-12 pt-2 pb-4 note-text">
                                  Submit your request through the link below and Fluidmeet team will help you with your search.
                                </div>
                              </div>
                            </div>
                            // <div className="form-general p-0">
                            //   <div className="row mt-3 mb-4 mrl-5">
                            //     <div className="col-12 pt-2 pb-4 note-text">
                            //       Fill in your contact details and one of our consultants will contact you asap to discuss the spaces available to you.
                            //     </div>
                            //     <div className="col-lg-6 col-12 pr-2 pr-mobile-2 pl-mobile-2">
                            //       <input type="name" id="fname" name="fname" className="form-control mb-2" placeholder="First name"
                            //              defaultValue={(user) ? user.firstName : ''} required={true} />
                            //       {(errors.fName) ? (<div className="form-error">{errors.fName}</div>) : (<></>)}
                            //     </div>
                            //     <div className="col-lg-6 col-12 pl-2 pr-mobile-2 pl-mobile-2">
                            //       <input type="name" id="lname" name="lname" className="form-control mb-2" placeholder="Last name"
                            //              defaultValue={(user) ? user.lastName : ''} required={true} />
                            //       {(errors.lName) ? (<div className="form-error">{errors.lName}</div>) : (<></>)}
                            //     </div>
                            //     <div className="col-lg-6 col-12 pr-2 pr-mobile-2 pl-mobile-2">
                            //       <input type="email" id="email" name="email" className="form-control mb-2" placeholder="Email address"
                            //              defaultValue={(user) ? user.email : ''} required={true} />
                            //       {(errors.email) ? (<div className="form-error">{errors.email}</div>) : (<></>)}
                            //     </div>
                            //     <div className="col-lg-6 col-12 pl-2 pr-mobile-2 pl-mobile-2">
                            //       <input type="tel" id="phone" name="phone" className="form-control mb-2" placeholder="Phone number"
                            //              defaultValue={(user) ? user.phone : ''} required={true} />
                            //       {(errors.phone) ? (<div className="form-error">{errors.phone}</div>) : (<></>)}
                            //     </div>
                            //     <div className="col-lg-6 col-12 pr-2 pr-mobile-2 pl-mobile-2">
                            //       <input type="tel" id="company" name="company" className="form-control mb-2" placeholder="Company"
                            //              defaultValue={(user) ? user.company : ''} />
                            //       {(errors.company) ? (<div className="form-error">{errors.company}</div>) : (<></>)}
                            //     </div>
                            //   </div>
                            // </div>
                          ) : (showMore === 'generic-ddr') ? (
                            <div className="form-general p-0">
                              <div className="row mt-3 mb-4">
                                <div className="col-12 pt-2 pb-4 note-text">
                                  Submit your request through the link below and Fluidmeet team will help you with your search.
                                </div>
                              </div>
                            </div>
                          ) : (showMore === 'generic-bs') ? (
                            <div className="form-general p-0">
                              <div className="row mt-3 mb-4">
                                <div className="col-12 pt-2 pb-4 note-text">
                                  Lots of licensing options available. Fill in your contact details and one of our consultants will contact you asap to discuss options available to you.
                                </div>
                                <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                                  <input type="name" id="fname" name="fname" className="form-control mb-2" placeholder="First name"
                                    defaultValue={(user) ? user.first_name : ''} required={true} />
                                  {(errors.fName) ? (<div className="form-error">{errors.fName}</div>) : (<></>)}
                                </div>
                                <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                                  <input type="name" id="lname" name="lname" className="form-control mb-2" placeholder="Last name"
                                    defaultValue={(user) ? user.last_name : ''} required={true} />
                                  {(errors.lName) ? (<div className="form-error">{errors.lName}</div>) : (<></>)}
                                </div>
                                <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                                  <input type="email" id="email" name="email" className="form-control mb-2" placeholder="Email address"
                                    defaultValue={(user) ? user.email : ''} required={true} />
                                  {(errors.email) ? (<div className="form-error">{errors.email}</div>) : (<></>)}
                                </div>
                                <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                                  <input type="tel" id="phone" name="phone" className="form-control mb-2" placeholder="Phone number"
                                    defaultValue={(user) ? user.phone : ''} required={true} />
                                  {(errors.phone) ? (<div className="form-error">{errors.phone}</div>) : (<></>)}
                                </div>
                                <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                                  <div className="selector">
                                    <CountryDropdown classes="select" defaultOptionLabel='Licensing Country' value={this.state.selectedCountry} onChange={(val) => this.setState({ selectedCountry: val })} />
                                  </div>
                                  {(this.state.countryError) ? (<div className="form-error">{this.state.countryError}</div>) : (<></>)}
                                </div>
                                <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                                  <div className="selector">
                                    <RegionDropdown classes="select" defaultOptionLabel='Licensing City' disableWhenEmpty={true} country={this.state.selectedCountry} value={this.state.selectedCity} onChange={(val) => this.setState({ selectedCity: val })} />
                                  </div>
                                  {(errors.company) ? (<div className="form-error">{errors.company}</div>) : (<></>)}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                    </div>
                    <div className="more-bottom">
                      {window.location.href.includes('meeting-rooms') ? <></> : <div className="dropdown-divider mb-3 mt-0" />}
                      {(showMore === 'generic-bs') ? (
                        window.location.href.includes('meeting-rooms') ? <></> : <button className="sendBtn mr-3 mr-2-mob" type="submit">
                          {(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch} spin={true} /> loading</>) : 'Send Request'}
                        </button>
                      ) : (showMore === 'generic-lt') ? (
                        window.location.href.includes('meeting-rooms') ? <></> : <>
                          <button className="cancelBtn mr-3" type="button" onClick={() => this.toggleShowMore()}>Not Now</button>
                          <Link to={{ pathname: '/request/' + (match.params.type === 'co-working-spaces' ? 'lt-coworking' : 'lt-office') + '/generic?source=search', state: { term: 'monthly', source: 'search' } }}>
                            <button className="sendBtn mr-3 mr-2-mob">Send Request</button>
                          </Link>
                        </>
                      ) : (showMore === 'generic-ddr') ? (
                        window.location.href.includes('meeting-rooms') ? <></> : <>
                          <button className="cancelBtn mr-3" type="button" onClick={() => this.toggleShowMore()}>Not Now</button>
                          <Link to={{ pathname: '/request/' + (match.params.type === 'meeting-rooms' ? 'catered-meeting' : 'catered-banquet') + '/generic?source=search', state: { term: 'catered', source: 'search' } }}>
                            <button className="sendBtn mr-3 mr-2-mob">Send Request</button>
                          </Link>
                        </>
                      ) : (
                        window.location.href.includes('meeting-rooms') ? <></> : <button className="cancelBtn mr-3" type="button" onClick={() => this.toggleShowMore()}>Close</button>
                      )}
                    </div>
                    </div>
                    {/* </form> */}
                  </div> :

                  <div ref={this.showMoreRef} className="more-box align-self-center space-page-box mini-box">
                    <form autoComplete="on" onSubmit={(e) => this.handleGeneralReq(e)}>
                      <div className="more-header">
                        {(showMore === 'generic-lt') ? (
                          <h5 className="center pr-2 pl-2">
                            {(match.params.type === 'co-working-spaces') ? 'Looking for a Co-working Space?' : ''}
                          </h5>
                        ) : (showMore === 'generic-ddr') ? (
                          <h5 className="center pr-2 pl-2">Can’t Find What You’re Looking For?</h5>
                        ) : (showMore === 'generic-bs') ? (
                          <h5 className="center pr-2 pl-2">Looking for Similar Services?</h5>
                        ) : (
                          ''
                        )}
                        {window.location.href.includes('meeting-rooms') ? <></> : <div className="dropdown-divider mt-4 mb-0" />}
                      </div>
                      <div className="more-contain space-page-box-contain">
                        {(showMore === 'generic-lt') && window.location.href.includes('meeting-rooms') ?
                          // <div className="col-12 pt-2 pb-4 note-text">
                          //   Looking For A SERVICED OFFICES? <br></br>
                          //   Submit your request and let us help you find the most suitable option.
                          // </div>



                          <div class="box-style-size">
                            <a href="#" title="Close" class="">Close</a>
                            <img className="offices-popup-circular-div-img" src={offices_popup_image_mobile} alt="find your serviced office" />
                            <div className="popup-send-requests-title-mobile"><h2>Looking For A SERVICED OFFICES?</h2></div>
                            <form autoComplete="on" onSubmit={(e) => this.handleGeneralReq(e)}>
                              <div className="form-general p-0">
                                <input type="text" id="fname" name="fname" className="form-control" placeholder="First Name" required />
                                {(this.state.fNameError) ? (<div className="form-error">{this.state.fNameError}</div>) : (<></>)}

                                <input type="text" id="lname" name="lname" className="form-control" placeholder="Last Name" required />
                                {(this.state.lNameError) ? (<div className="form-error">{this.state.lNameError}</div>) : (<></>)}

                                <input type="email" id="email" name="email" className="form-control" placeholder="Email address" required />
                                {(this.state.emailError) ? (<div className="form-error">{this.state.emailError}</div>) : (<></>)}

                                <div className="selector">
                                  <FMPhoneInput type={'tel'} id="phone" placeholder="Phone number"
                                    extraClassNames={"bs-white-bs-pg"} /></div>
                                {(this.state.phoneError) ? (<div className="form-error">{this.state.phoneError}</div>) : (<></>)}

                                <input type="email" id="email" name="email" className="form-control" placeholder="Email address" required />
                                {(this.state.emailError) ? (<div className="form-error">{this.state.emailError}</div>) : (<></>)}
                              </div>
                              <button type="submit" className={"btn btn-lg btn-primary btn-block fl_btn mt-3 " + ((reqLoading) ? 'ld-btn' : '')}>
                                {(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch} spin={true} /> loading</>) : 'Send Request'}
                              </button>
                            </form>
                          </div>
                          : (showMore === 'generic-lt') ?
                            (
                              <div className="form-general p-0">
                                <div className="row mt-3 mb-4">
                                  <div className="col-12 pt-2 pb-4 note-text">
                                    Submit your request through the link below and Fluidmeet team will help you with your search.
                                  </div>
                                </div>
                              </div>
                              // <div className="form-general p-0">
                              //   <div className="row mt-3 mb-4 mrl-5">
                              //     <div className="col-12 pt-2 pb-4 note-text">
                              //       Fill in your contact details and one of our consultants will contact you asap to discuss the spaces available to you.
                              //     </div>
                              //     <div className="col-lg-6 col-12 pr-2 pr-mobile-2 pl-mobile-2">
                              //       <input type="name" id="fname" name="fname" className="form-control mb-2" placeholder="First name"
                              //              defaultValue={(user) ? user.firstName : ''} required={true} />
                              //       {(errors.fName) ? (<div className="form-error">{errors.fName}</div>) : (<></>)}
                              //     </div>
                              //     <div className="col-lg-6 col-12 pl-2 pr-mobile-2 pl-mobile-2">
                              //       <input type="name" id="lname" name="lname" className="form-control mb-2" placeholder="Last name"
                              //              defaultValue={(user) ? user.lastName : ''} required={true} />
                              //       {(errors.lName) ? (<div className="form-error">{errors.lName}</div>) : (<></>)}
                              //     </div>
                              //     <div className="col-lg-6 col-12 pr-2 pr-mobile-2 pl-mobile-2">
                              //       <input type="email" id="email" name="email" className="form-control mb-2" placeholder="Email address"
                              //              defaultValue={(user) ? user.email : ''} required={true} />
                              //       {(errors.email) ? (<div className="form-error">{errors.email}</div>) : (<></>)}
                              //     </div>
                              //     <div className="col-lg-6 col-12 pl-2 pr-mobile-2 pl-mobile-2">
                              //       <input type="tel" id="phone" name="phone" className="form-control mb-2" placeholder="Phone number"
                              //              defaultValue={(user) ? user.phone : ''} required={true} />
                              //       {(errors.phone) ? (<div className="form-error">{errors.phone}</div>) : (<></>)}
                              //     </div>
                              //     <div className="col-lg-6 col-12 pr-2 pr-mobile-2 pl-mobile-2">
                              //       <input type="tel" id="company" name="company" className="form-control mb-2" placeholder="Company"
                              //              defaultValue={(user) ? user.company : ''} />
                              //       {(errors.company) ? (<div className="form-error">{errors.company}</div>) : (<></>)}
                              //     </div>
                              //   </div>
                              // </div>
                            ) : (showMore === 'generic-ddr') ? (
                              <div className="form-general p-0">
                                <div className="row mt-3 mb-4">
                                  <div className="col-12 pt-2 pb-4 note-text">
                                    Submit your request through the link below and Fluidmeet team will help you with your search.
                                  </div>
                                </div>
                              </div>
                            ) : (showMore === 'generic-bs') ? (
                              <div className="form-general p-0">
                                <div className="row mt-3 mb-4">
                                  <div className="col-12 pt-2 pb-4 note-text">
                                    Lots of licensing options available. Fill in your contact details and one of our consultants will contact you asap to discuss options available to you.
                                  </div>
                                  <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                                    <input type="name" id="fname" name="fname" className="form-control mb-2" placeholder="First name"
                                      defaultValue={(user) ? user.first_name : ''} required={true} />
                                    {(errors.fName) ? (<div className="form-error">{errors.fName}</div>) : (<></>)}
                                  </div>
                                  <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                                    <input type="name" id="lname" name="lname" className="form-control mb-2" placeholder="Last name"
                                      defaultValue={(user) ? user.last_name : ''} required={true} />
                                    {(errors.lName) ? (<div className="form-error">{errors.lName}</div>) : (<></>)}
                                  </div>
                                  <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                                    <input type="email" id="email" name="email" className="form-control mb-2" placeholder="Email address"
                                      defaultValue={(user) ? user.email : ''} required={true} />
                                    {(errors.email) ? (<div className="form-error">{errors.email}</div>) : (<></>)}
                                  </div>
                                  <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                                    <input type="tel" id="phone" name="phone" className="form-control mb-2" placeholder="Phone number"
                                      defaultValue={(user) ? user.phone : ''} required={true} />
                                    {(errors.phone) ? (<div className="form-error">{errors.phone}</div>) : (<></>)}
                                  </div>
                                  <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                                    <div className="selector">
                                      <CountryDropdown classes="select" defaultOptionLabel='Licensing Country' value={this.state.selectedCountry} onChange={(val) => this.setState({ selectedCountry: val })} />
                                    </div>
                                    {(this.state.countryError) ? (<div className="form-error">{this.state.countryError}</div>) : (<></>)}
                                  </div>
                                  <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                                    <div className="selector">
                                      <RegionDropdown classes="select" defaultOptionLabel='Licensing City' disableWhenEmpty={true} country={this.state.selectedCountry} value={this.state.selectedCity} onChange={(val) => this.setState({ selectedCity: val })} />
                                    </div>
                                    {(errors.company) ? (<div className="form-error">{errors.company}</div>) : (<></>)}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                      </div>
                      <div className="more-bottom">
                        {window.location.href.includes('meeting-rooms') ? <></> : <div className="dropdown-divider mb-3 mt-0" />}
                        {(showMore === 'generic-bs') ? (
                          window.location.href.includes('meeting-rooms') ? <></> : <button className="sendBtn mr-3 mr-2-mob" type="submit">
                            {(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch} spin={true} /> loading</>) : 'Send Request'}
                          </button>
                        ) : (showMore === 'generic-lt') ? (
                          window.location.href.includes('meeting-rooms') ? <></> : <>
                            <button className="cancelBtn mr-3" type="button" onClick={() => this.toggleShowMore()}>Not Now</button>
                            <Link to={{ pathname: '/request/' + (match.params.type === 'co-working-spaces' ? 'lt-coworking' : 'lt-office') + '/generic?source=search', state: { term: 'monthly', source: 'search' } }}>
                              <button className="sendBtn mr-3 mr-2-mob">Send Request</button>
                            </Link>
                          </>
                        ) : (showMore === 'generic-ddr') ? (
                          window.location.href.includes('meeting-rooms') ? <></> : <>
                            <button className="cancelBtn mr-3" type="button" onClick={() => this.toggleShowMore()}>Not Now</button>
                            <Link to={{ pathname: '/request/' + (match.params.type === 'meeting-rooms' ? 'catered-meeting' : 'catered-banquet') + '/generic?source=search', state: { term: 'catered', source: 'search' } }}>
                              <button className="sendBtn mr-3 mr-2-mob">Send Request</button>
                            </Link>
                          </>
                        ) : (
                          window.location.href.includes('meeting-rooms') ? <></> : <button className="cancelBtn mr-3" type="button" onClick={() => this.toggleShowMore()}>Close</button>
                        )}
                      </div>
                    </form>
                  </div>}
              </div>

              
                :
                this.state.show_popUp_office_spaces &&
                <div className={"more-filter fz-17 justify-content-center " + ((showMore) ? "show-flex" : "")}>
                  {window.location.href.includes('office-spaces') ?
                    <div ref={this.showMoreRef} className="popup-send-requests align-self-center">
                      <div className='modal-left-block'>
                      <img className="offices-popup-circular-div-img" src={offices_popup_image_mobile} alt="find your serviced office" />
                      <div className='modal-left-inner'>
                      <a href="tel:+971 50 3263932">
                        <img className="offices-popup-phone-call-div-img-desktop" src={offices_popup_image_phone_logo} alt="Call US" />
                      </a>
                      <h3 className='float-text-popupOffices-desktop'>PLANNING TO SETUP<br></br> OR RENEW YOUR<br></br> BUSINESS LICENSE?</h3>
                      <h6 className='float-inner-text-popupOffices-desktop'>fluidmeet will help you find the ideal<br></br>office space that is ready for your<br></br> immediate occupancy</h6>
                      
                      </div>
                      </div>
                      <div className='modal-right-block'>
                      
                      {/* <form autoComplete="on" onSubmit={this.handleNextBtn}> */}
                      {/* <div className="more-header"> */}
                      {(showMore === 'generic-lt') ? (
                        <h5 className="center pr-2 pl-2">
                          {(match.params.type === 'co-working-spaces') ? 'Looking for a Co-working Space?' : ''}
                        </h5>
                      ) : (showMore === 'generic-ddr') ? (
                        <h5 className="center pr-2 pl-2">Can’t Find What You’re Looking For?</h5>
                      ) : (showMore === 'generic-bs') ? (
                        <h5 className="center pr-2 pl-2">Looking for Similar Services?</h5>
                      ) : (
                        ''
                      )}
                      {window.location.href.includes('office-spaces') ? <></> : <div className="dropdown-divider mt-4 mb-0" />}
                      {/* </div> */}
                      <div className="more-contain">
                        {(showMore === 'generic-lt') && window.location.href.includes('office-spaces') ?
                          // <div className="col-12 pt-2 pb-4 note-text">
                          //   Looking For A SERVICED OFFICES? <br></br>
                          //   Submit your request and let us help you find the most suitable option.
                          // </div>



                          <div class="">
                            <button class="close" aria-label="Dismiss alert" type="button" data-close>
                              <span onClick={() => {
                                this.show_popUp_office_spaces();
                              }} aria-hidden="true">&times;</span>
                            </button>

                            <form autoComplete="on" onSubmit={this.handleNextBtn}>
                              <div className="form-general p-0">
                                <h2 className="popup-send-requests-title">LOOKING FOR A <br></br> SERVICED OFFICE?</h2>
                                {this.state.width <= 1300 ? <h6 className='offices-popUpform-body-inner-txt'>Submit your request and let us<br></br> help you find the most suitable option</h6> : <h6 className='offices-popUpform-body-inner-txt'>Submit your request and let us help you find<br></br> the most suitable option</h6>}
                                {this.state.width <= 1300 ? <><br></br></> : <><br></br><br></br><br></br><br></br><br></br><br></br></>}
                                <div className="popoUp-form-offices-desktop-size">
                                  <input type="name" id="fname" name="fname" className="form-control" placeholder="First name"
                                    defaultValue={(user) ? user.first_name : ''} required={true} />
                                  {(errors.fName) ? (<div className="form-error">{errors.fName}</div>) : (<></>)}

                                  <input type="name" id="lname" name="lname" className="form-control" placeholder="Last name"
                                    defaultValue={(user) ? user.last_name : ''} required={true} />
                                  {(errors.lName) ? (<div className="form-error">{errors.lName}</div>) : (<></>)}

                                  <input type="email" id="email" name="email" className="form-control" placeholder="Email address"
                                    defaultValue={(user) ? user.email : ''} required={true} />
                                  {(errors.email) ? (<div className="form-error">{errors.email}</div>) : (<></>)}


                                  <PhoneInput
                                    preferredCountries={['ae']}
                                    country={(user && user.country) ? getCountryCode(user.country)[2] : 'ae'}
                                    enableAreaCodes={true}
                                    buttonStyle={{
                                      borderRadius: '16px 0 0 16px',
                                      backgroundColor: '#f9f9f9',
                                      zIndex: 10,
                                      border: '1px solid #cfd8e7',
                                    }}
                                    dropdownStyle={{
                                      borderRadius: '16px',
                                      zIndex: 10,
                                      border: '1px solid #cfd8e7',
                                    }}
                                    searchClass={"form-control-parent"}
                                    inputProps={{ id: 'phone', name: 'phone', required: true }}
                                    inputStyle={{
                                      width: '100%',
                                      borderRadius: '16px',
                                    }}
                                    inputClass={'bg-grey-focus-white'}
                                    autoFormat={true}
                                    value={(user) ? user.phone : ''}
                                    enableSearch={true}
                                    onChange={(phone, data) => this.setState({ countryCode: data.dialCode })}
                                  />

                                  {(errors.phone) ? (<div className="form-error">{errors.phone}</div>) : (<></>)}


                                  <input type="text" id="company" name="company" className="form-control" placeholder="Company"
                                    defaultValue={(user) ? user.company : ''} />
                                  {(errors.company) ? (<div className="form-error">{errors.company}</div>) : (<></>)}

                                  <center><button type="submit" className={"offices-popup-form-btn-style-border btn btn-lg btn-primary btn-block fl_btn submit-btn mb-5 " + ((reqLoading) ? 'ld-btn' : '')}>
                                    {(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch} spin={true} /> loading</>) : 'Send Request'}
                                  </button></center>
                                </div>
                              </div>
                            </form>

                          </div>
                          : (showMore === 'generic-lt') ?
                            (
                              <div className="form-general p-0">
                                <div className="row mt-3 mb-4">
                                  <div className="col-12 pt-2 pb-4 note-text">
                                    Submit your request through the link below and Fluidmeet team will help you with your search.
                                  </div>
                                </div>
                              </div>
                              // <div className="form-general p-0">
                              //   <div className="row mt-3 mb-4 mrl-5">
                              //     <div className="col-12 pt-2 pb-4 note-text">
                              //       Fill in your contact details and one of our consultants will contact you asap to discuss the spaces available to you.
                              //     </div>
                              //     <div className="col-lg-6 col-12 pr-2 pr-mobile-2 pl-mobile-2">
                              //       <input type="name" id="fname" name="fname" className="form-control mb-2" placeholder="First name"
                              //              defaultValue={(user) ? user.firstName : ''} required={true} />
                              //       {(errors.fName) ? (<div className="form-error">{errors.fName}</div>) : (<></>)}
                              //     </div>
                              //     <div className="col-lg-6 col-12 pl-2 pr-mobile-2 pl-mobile-2">
                              //       <input type="name" id="lname" name="lname" className="form-control mb-2" placeholder="Last name"
                              //              defaultValue={(user) ? user.lastName : ''} required={true} />
                              //       {(errors.lName) ? (<div className="form-error">{errors.lName}</div>) : (<></>)}
                              //     </div>
                              //     <div className="col-lg-6 col-12 pr-2 pr-mobile-2 pl-mobile-2">
                              //       <input type="email" id="email" name="email" className="form-control mb-2" placeholder="Email address"
                              //              defaultValue={(user) ? user.email : ''} required={true} />
                              //       {(errors.email) ? (<div className="form-error">{errors.email}</div>) : (<></>)}
                              //     </div>
                              //     <div className="col-lg-6 col-12 pl-2 pr-mobile-2 pl-mobile-2">
                              //       <input type="tel" id="phone" name="phone" className="form-control mb-2" placeholder="Phone number"
                              //              defaultValue={(user) ? user.phone : ''} required={true} />
                              //       {(errors.phone) ? (<div className="form-error">{errors.phone}</div>) : (<></>)}
                              //     </div>
                              //     <div className="col-lg-6 col-12 pr-2 pr-mobile-2 pl-mobile-2">
                              //       <input type="tel" id="company" name="company" className="form-control mb-2" placeholder="Company"
                              //              defaultValue={(user) ? user.company : ''} />
                              //       {(errors.company) ? (<div className="form-error">{errors.company}</div>) : (<></>)}
                              //     </div>
                              //   </div>
                              // </div>
                            ) : (showMore === 'generic-ddr') ? (
                              <div className="form-general p-0">
                                <div className="row mt-3 mb-4">
                                  <div className="col-12 pt-2 pb-4 note-text">
                                    Submit your request through the link below and Fluidmeet team will help you with your search.
                                  </div>
                                </div>
                              </div>
                            ) : (showMore === 'generic-bs') ? (
                              <div className="form-general p-0">
                                <div className="row mt-3 mb-4">
                                  <div className="col-12 pt-2 pb-4 note-text">
                                    Lots of licensing options available. Fill in your contact details and one of our consultants will contact you asap to discuss options available to you.
                                  </div>
                                  <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                                    <input type="name" id="fname" name="fname" className="form-control mb-2" placeholder="First name"
                                      defaultValue={(user) ? user.first_name : ''} required={true} />
                                    {(errors.fName) ? (<div className="form-error">{errors.fName}</div>) : (<></>)}
                                  </div>
                                  <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                                    <input type="name" id="lname" name="lname" className="form-control mb-2" placeholder="Last name"
                                      defaultValue={(user) ? user.last_name : ''} required={true} />
                                    {(errors.lName) ? (<div className="form-error">{errors.lName}</div>) : (<></>)}
                                  </div>
                                  <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                                    <input type="email" id="email" name="email" className="form-control mb-2" placeholder="Email address"
                                      defaultValue={(user) ? user.email : ''} required={true} />
                                    {(errors.email) ? (<div className="form-error">{errors.email}</div>) : (<></>)}
                                  </div>
                                  <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                                    <input type="tel" id="phone" name="phone" className="form-control mb-2" placeholder="Phone number"
                                      defaultValue={(user) ? user.phone : ''} required={true} />
                                    {(errors.phone) ? (<div className="form-error">{errors.phone}</div>) : (<></>)}
                                  </div>
                                  <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                                    <div className="selector">
                                      <CountryDropdown classes="select" defaultOptionLabel='Licensing Country' value={this.state.selectedCountry} onChange={(val) => this.setState({ selectedCountry: val })} />
                                    </div>
                                    {(this.state.countryError) ? (<div className="form-error">{this.state.countryError}</div>) : (<></>)}
                                  </div>
                                  <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                                    <div className="selector">
                                      <RegionDropdown classes="select" defaultOptionLabel='Licensing City' disableWhenEmpty={true} country={this.state.selectedCountry} value={this.state.selectedCity} onChange={(val) => this.setState({ selectedCity: val })} />
                                    </div>
                                    {(errors.company) ? (<div className="form-error">{errors.company}</div>) : (<></>)}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                      </div>
                      <div className="more-bottom">
                        {window.location.href.includes('office-spaces') ? <></> : <div className="dropdown-divider mb-3 mt-0" />}
                        {(showMore === 'generic-bs') ? (
                          window.location.href.includes('office-spaces') ? <></> : <button className="sendBtn mr-3 mr-2-mob" type="submit">
                            {(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch} spin={true} /> loading</>) : 'Send Request'}
                          </button>
                        ) : (showMore === 'generic-lt') ? (
                          window.location.href.includes('office-spaces') ? <></> : <>
                            <button className="cancelBtn mr-3" type="button" onClick={() => this.toggleShowMore()}>Not Now</button>
                            <Link to={{ pathname: '/request/' + (match.params.type === 'co-working-spaces' ? 'lt-coworking' : 'lt-office') + '/generic?source=search', state: { term: 'monthly', source: 'search' } }}>
                              <button className="sendBtn mr-3 mr-2-mob">Send Request</button>
                            </Link>
                          </>
                        ) : (showMore === 'generic-ddr') ? (
                          window.location.href.includes('office-spaces') ? <></> : <>
                            <button className="cancelBtn mr-3" type="button" onClick={() => this.toggleShowMore()}>Not Now</button>
                            <Link to={{ pathname: '/request/' + (match.params.type === 'meeting-rooms' ? 'catered-meeting' : 'catered-banquet') + '/generic?source=search', state: { term: 'catered', source: 'search' } }}>
                              <button className="sendBtn mr-3 mr-2-mob">Send Request</button>
                            </Link>
                          </>
                        ) : (
                          window.location.href.includes('office-spaces') ? <></> : <button className="cancelBtn mr-3" type="button" onClick={() => this.toggleShowMore()}>Close</button>
                        )}
                      </div>
                      </div>
                      {/* </form> */}
                    </div>
                    : window.location.href.includes('co-working-spaces') ?
                      <div ref={this.showMoreRef} className="popup-send-requests-co-working align-self-center">
                        <div className='modal-left-block'>
                        <img className="co-working-popup-circular-div-img" src={co_working_popup_image} alt="find your Co-working" />
                        <div className='modal-left-inner'>
                        <a href="tel:+971 50 3263932">
                          <img className="co-working-popup-phone-call-div-img-desktop" src={offices_popup_image_phone_logo} alt="Call US" />
                        </a>
                        <h3 className='float-text-popupco-working-desktop'>SEARCHING FOR THE<br></br> IDEAL CO-WORKING SPACE,<br></br> SHARED OFFICE, OR HOT DESK?</h3>
                        <h6 className='float-inner-text-popupco-working-desktop'>fluidmeet team can help you find a<br></br> workspace that is ready for your<br></br> immediate occupancy</h6>
                        </div>
                        </div>

                        <div className='modal-right-block'>
                        {/* <form autoComplete="on" onSubmit={this.handleNextBtn}> */}
                        {/* <div className="more-header"> */}
                        {(showMore === 'generic-lt') ? (
                          <h5 className="center pr-2 pl-2">
                            {(match.params.type === 'co-working-spaces') ? '' : ''}
                          </h5>
                        ) : (showMore === 'generic-ddr') ? (
                          <h5 className="center pr-2 pl-2">Can’t Find What You’re Looking For?</h5>
                        ) : (showMore === 'generic-bs') ? (
                          <h5 className="center pr-2 pl-2">Looking for Similar Services?</h5>
                        ) : (
                          ''
                        )}
                        {window.location.href.includes('co-working-spaces') ? <></> : <div className="dropdown-divider mt-4 mb-0" />}
                        {/* </div> */}
                        <div className="more-contain">
                          {(showMore === 'generic-lt') && window.location.href.includes('co-working-spaces') ?
                            // <div className="col-12 pt-2 pb-4 note-text">
                            //   Looking For A SERVICED OFFICES? <br></br>
                            //   Submit your request and let us help you find the most suitable option.
                            // </div>



                            <div class="">
                              <button class="close" aria-label="Dismiss alert" type="button" data-close>
                                <span onClick={() => {
                                  this.show_popUp_office_spaces();
                                }} aria-hidden="true">&times;</span>
                              </button>

                              <form autoComplete="on" onSubmit={this.handleNextBtn}>
                                <div className="form-general p-0">
                                  <h2 className="co-working-popup-send-requests-title">LOOKING FOR A <br></br>CO-WORKING SPACE?</h2>
                                  {this.state.width <= 1300 ? <h6 className='co-working-popUpform-body-inner-txt'>Submit your request and let us<br></br> help you find the most suitable option</h6> : <h6 className='co-working-popUpform-body-inner-txt'>Submit your request and let us help you find<br></br> the most suitable option</h6>}
                                  {this.state.width <= 1300 ? <><br></br></> : <><br></br><br></br><br></br><br></br><br></br><br></br></>}
                                  <div className="popoUp-form-co-working-desktop-size">
                                    <input type="name" id="fname" name="fname" className="form-control" placeholder="First name"
                                      defaultValue={(user) ? user.first_name : ''} required={true} />
                                    {(errors.fName) ? (<div className="form-error">{errors.fName}</div>) : (<></>)}

                                    <input type="name" id="lname" name="lname" className="form-control" placeholder="Last name"
                                      defaultValue={(user) ? user.last_name : ''} required={true} />
                                    {(errors.lName) ? (<div className="form-error">{errors.lName}</div>) : (<></>)}

                                    <input type="email" id="email" name="email" className="form-control" placeholder="Email address"
                                      defaultValue={(user) ? user.email : ''} required={true} />
                                    {(errors.email) ? (<div className="form-error">{errors.email}</div>) : (<></>)}


                                    <PhoneInput
                                      preferredCountries={['ae']}
                                      country={(user && user.country) ? getCountryCode(user.country)[2] : 'ae'}
                                      enableAreaCodes={true}
                                      buttonStyle={{
                                        borderRadius: '16px 0 0 16px',
                                        backgroundColor: '#f9f9f9',
                                        zIndex: 10,
                                        border: '1px solid #cfd8e7',
                                      }}
                                      dropdownStyle={{
                                        borderRadius: '16px',
                                        zIndex: 10,
                                        border: '1px solid #cfd8e7',
                                      }}
                                      searchClass={"form-control-parent"}
                                      inputProps={{ id: 'phone', name: 'phone', required: true }}
                                      inputStyle={{
                                        width: '100%',
                                        borderRadius: '16px',
                                      }}
                                      inputClass={'bg-grey-focus-white'}
                                      autoFormat={true}
                                      value={(user) ? user.phone : ''}
                                      enableSearch={true}
                                      onChange={(phone, data) => this.setState({ countryCode: data.dialCode })}
                                    />

                                    {(errors.phone) ? (<div className="form-error">{errors.phone}</div>) : (<></>)}


                                    <input type="text" id="company" name="company" className="form-control" placeholder="Company"
                                      defaultValue={(user) ? user.company : ''} />
                                    {(errors.company) ? (<div className="form-error">{errors.company}</div>) : (<></>)}

                                    <center><button type="submit" className={"co-working-popup-form-btn-style-border btn btn-lg btn-primary btn-block fl_btn submit-btn mb-5 " + ((reqLoading) ? 'ld-btn' : '')}>
                                      {(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch} spin={true} /> loading</>) : 'Send Request'}
                                    </button></center>
                                  </div>
                                </div>
                              </form>

                            </div>
                            : (showMore === 'generic-lt') ?
                              (
                                <div className="form-general p-0">
                                  <div className="row mt-3 mb-4">
                                    <div className="col-12 pt-2 pb-4 note-text">
                                      Submit your request through the link below and Fluidmeet team will help you with your search.
                                    </div>
                                  </div>
                                </div>
                                // <div className="form-general p-0">
                                //   <div className="row mt-3 mb-4 mrl-5">
                                //     <div className="col-12 pt-2 pb-4 note-text">
                                //       Fill in your contact details and one of our consultants will contact you asap to discuss the spaces available to you.
                                //     </div>
                                //     <div className="col-lg-6 col-12 pr-2 pr-mobile-2 pl-mobile-2">
                                //       <input type="name" id="fname" name="fname" className="form-control mb-2" placeholder="First name"
                                //              defaultValue={(user) ? user.firstName : ''} required={true} />
                                //       {(errors.fName) ? (<div className="form-error">{errors.fName}</div>) : (<></>)}
                                //     </div>
                                //     <div className="col-lg-6 col-12 pl-2 pr-mobile-2 pl-mobile-2">
                                //       <input type="name" id="lname" name="lname" className="form-control mb-2" placeholder="Last name"
                                //              defaultValue={(user) ? user.lastName : ''} required={true} />
                                //       {(errors.lName) ? (<div className="form-error">{errors.lName}</div>) : (<></>)}
                                //     </div>
                                //     <div className="col-lg-6 col-12 pr-2 pr-mobile-2 pl-mobile-2">
                                //       <input type="email" id="email" name="email" className="form-control mb-2" placeholder="Email address"
                                //              defaultValue={(user) ? user.email : ''} required={true} />
                                //       {(errors.email) ? (<div className="form-error">{errors.email}</div>) : (<></>)}
                                //     </div>
                                //     <div className="col-lg-6 col-12 pl-2 pr-mobile-2 pl-mobile-2">
                                //       <input type="tel" id="phone" name="phone" className="form-control mb-2" placeholder="Phone number"
                                //              defaultValue={(user) ? user.phone : ''} required={true} />
                                //       {(errors.phone) ? (<div className="form-error">{errors.phone}</div>) : (<></>)}
                                //     </div>
                                //     <div className="col-lg-6 col-12 pr-2 pr-mobile-2 pl-mobile-2">
                                //       <input type="tel" id="company" name="company" className="form-control mb-2" placeholder="Company"
                                //              defaultValue={(user) ? user.company : ''} />
                                //       {(errors.company) ? (<div className="form-error">{errors.company}</div>) : (<></>)}
                                //     </div>
                                //   </div>
                                // </div>
                              ) : (showMore === 'generic-ddr') ? (
                                <div className="form-general p-0">
                                  <div className="row mt-3 mb-4">
                                    <div className="col-12 pt-2 pb-4 note-text">
                                      Submit your request through the link below and Fluidmeet team will help you with your search.
                                    </div>
                                  </div>
                                </div>
                              ) : (showMore === 'generic-bs') ? (
                                <div className="form-general p-0">
                                  <div className="row mt-3 mb-4">
                                    <div className="col-12 pt-2 pb-4 note-text">
                                      Lots of licensing options available. Fill in your contact details and one of our consultants will contact you asap to discuss options available to you.
                                    </div>
                                    <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                                      <input type="name" id="fname" name="fname" className="form-control mb-2" placeholder="First name"
                                        defaultValue={(user) ? user.first_name : ''} required={true} />
                                      {(errors.fName) ? (<div className="form-error">{errors.fName}</div>) : (<></>)}
                                    </div>
                                    <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                                      <input type="name" id="lname" name="lname" className="form-control mb-2" placeholder="Last name"
                                        defaultValue={(user) ? user.last_name : ''} required={true} />
                                      {(errors.lName) ? (<div className="form-error">{errors.lName}</div>) : (<></>)}
                                    </div>
                                    <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                                      <input type="email" id="email" name="email" className="form-control mb-2" placeholder="Email address"
                                        defaultValue={(user) ? user.email : ''} required={true} />
                                      {(errors.email) ? (<div className="form-error">{errors.email}</div>) : (<></>)}
                                    </div>
                                    <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                                      <input type="tel" id="phone" name="phone" className="form-control mb-2" placeholder="Phone number"
                                        defaultValue={(user) ? user.phone : ''} required={true} />
                                      {(errors.phone) ? (<div className="form-error">{errors.phone}</div>) : (<></>)}
                                    </div>
                                    <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                                      <div className="selector">
                                        <CountryDropdown classes="select" defaultOptionLabel='Licensing Country' value={this.state.selectedCountry} onChange={(val) => this.setState({ selectedCountry: val })} />
                                      </div>
                                      {(this.state.countryError) ? (<div className="form-error">{this.state.countryError}</div>) : (<></>)}
                                    </div>
                                    <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                                      <div className="selector">
                                        <RegionDropdown classes="select" defaultOptionLabel='Licensing City' disableWhenEmpty={true} country={this.state.selectedCountry} value={this.state.selectedCity} onChange={(val) => this.setState({ selectedCity: val })} />
                                      </div>
                                      {(errors.company) ? (<div className="form-error">{errors.company}</div>) : (<></>)}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                        </div>
                        <div className="more-bottom">
                          {window.location.href.includes('co-working-spaces') ? <></> : <div className="dropdown-divider mb-3 mt-0" />}
                          {(showMore === 'generic-bs') ? (
                            window.location.href.includes('co-working-spaces') ? <></> : <button className="sendBtn mr-3 mr-2-mob" type="submit">
                              {(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch} spin={true} /> loading</>) : 'Send Request'}
                            </button>
                          ) : (showMore === 'generic-lt') ? (
                            window.location.href.includes('co-working-spaces') ? <></> : <>
                              <button className="cancelBtn mr-3" type="button" onClick={() => this.toggleShowMore()}>Not Now</button>
                              <Link to={{ pathname: '/request/' + (match.params.type === 'co-working-spaces' ? 'lt-coworking' : 'lt-office') + '/generic?source=search', state: { term: 'monthly', source: 'search' } }}>
                                <button className="sendBtn mr-3 mr-2-mob">Send Request</button>
                              </Link>
                            </>
                          ) : (showMore === 'generic-ddr') ? (
                            window.location.href.includes('co-working-spaces') ? <></> : <>
                              <button className="cancelBtn mr-3" type="button" onClick={() => this.toggleShowMore()}>Not Now</button>
                              <Link to={{ pathname: '/request/' + (match.params.type === 'meeting-rooms' ? 'catered-meeting' : 'catered-banquet') + '/generic?source=search', state: { term: 'catered', source: 'search' } }}>
                                <button className="sendBtn mr-3 mr-2-mob">Send Request</button>
                              </Link>
                            </>
                          ) : (
                            window.location.href.includes('co-working-spaces') ? <></> : <button className="cancelBtn mr-3" type="button" onClick={() => this.toggleShowMore()}>Close</button>
                          )}
                        </div>
                        </div>
                        {/* </form> */}
                      </div>

                      : window.location.href.includes('meeting-rooms') ?
                        <div ref={this.showMoreRef} className="popup-send-requests-meeting-room-popup align-self-center">
                          <img className="meeting-room-popup-circular-div-img" src={meeting_rooms_popup_image} alt="find your serviced office" />
                          <div className='modal-right-block'>
                            <a href="tel:+971 50 3263932">
                              <img className="meeting-room-popup-phone-call-div-img-desktop" src={offices_popup_image_phone_logo} alt="Call US" />
                            </a>
                            {window.location.href.includes('meeting-rooms') ? <></> : <div className="dropdown-divider mt-4 mb-0" />}
                            {/* </div> */}
                            <div className="more-contain">
                              {window.location.href.includes('meeting-rooms') ?
                                // <div className="col-12 pt-2 pb-4 note-text">
                                //   Looking For A SERVICED OFFICES? <br></br>
                                //   Submit your request and let us help you find the most suitable option.
                                // </div>



                                <div class="">
                                  <button class="close" aria-label="Dismiss alert" type="button" data-close>
                                    <span onClick={() => {
                                      this.show_popUp_office_spaces();
                                    }} aria-hidden="true">&times;</span>
                                  </button>


                                  <h2 className="popup-send-requests-meeting-room-popup-title">CAN'T FIND THE RIGHT <br></br> MEETING ROOM TO <br></br> HOST YOUR BUSINESS<br></br> MEETING?</h2>
                                  {this.state.width <= 1250 && this.state.width >= 750 ? <h6 className='meeting-room-popupform-body-inner-txt'>Submit your request and let us help<br></br> you find the meeting room that matches<br></br> your preference and business requirements</h6> : <h6 className='meeting-room-popupform-body-inner-txt'>Submit your request and let us help you find the meeting room that matches your preference and business requirements</h6>}
                                  <Link className='meeting-room-popup-form-btn-style btn btn-lg btn-primary fl_btn submit-btn' to="/request/catered-meeting/generic?source=search">
                                    Send Request
                                  </Link>

                                </div>
                                : (showMore === 'generic-lt') ?
                                  (
                                    <div className="form-general p-0">
                                      <div className="row mt-3 mb-4">
                                        <div className="col-12 pt-2 pb-4 note-text">
                                          Submit your request through the link below and Fluidmeet team will help you with your search.
                                        </div>
                                      </div>
                                    </div>
                                    // <div className="form-general p-0">
                                    //   <div className="row mt-3 mb-4 mrl-5">
                                    //     <div className="col-12 pt-2 pb-4 note-text">
                                    //       Fill in your contact details and one of our consultants will contact you asap to discuss the spaces available to you.
                                    //     </div>
                                    //     <div className="col-lg-6 col-12 pr-2 pr-mobile-2 pl-mobile-2">
                                    //       <input type="name" id="fname" name="fname" className="form-control mb-2" placeholder="First name"
                                    //              defaultValue={(user) ? user.firstName : ''} required={true} />
                                    //       {(errors.fName) ? (<div className="form-error">{errors.fName}</div>) : (<></>)}
                                    //     </div>
                                    //     <div className="col-lg-6 col-12 pl-2 pr-mobile-2 pl-mobile-2">
                                    //       <input type="name" id="lname" name="lname" className="form-control mb-2" placeholder="Last name"
                                    //              defaultValue={(user) ? user.lastName : ''} required={true} />
                                    //       {(errors.lName) ? (<div className="form-error">{errors.lName}</div>) : (<></>)}
                                    //     </div>
                                    //     <div className="col-lg-6 col-12 pr-2 pr-mobile-2 pl-mobile-2">
                                    //       <input type="email" id="email" name="email" className="form-control mb-2" placeholder="Email address"
                                    //              defaultValue={(user) ? user.email : ''} required={true} />
                                    //       {(errors.email) ? (<div className="form-error">{errors.email}</div>) : (<></>)}
                                    //     </div>
                                    //     <div className="col-lg-6 col-12 pl-2 pr-mobile-2 pl-mobile-2">
                                    //       <input type="tel" id="phone" name="phone" className="form-control mb-2" placeholder="Phone number"
                                    //              defaultValue={(user) ? user.phone : ''} required={true} />
                                    //       {(errors.phone) ? (<div className="form-error">{errors.phone}</div>) : (<></>)}
                                    //     </div>
                                    //     <div className="col-lg-6 col-12 pr-2 pr-mobile-2 pl-mobile-2">
                                    //       <input type="tel" id="company" name="company" className="form-control mb-2" placeholder="Company"
                                    //              defaultValue={(user) ? user.company : ''} />
                                    //       {(errors.company) ? (<div className="form-error">{errors.company}</div>) : (<></>)}
                                    //     </div>
                                    //   </div>
                                    // </div>
                                  ) : (showMore === 'generic-ddr') && (!window.location.href.includes('meeting-rooms')) ? (
                                    <div className="form-general p-0">
                                      <div className="row mt-3 mb-4">
                                        <div className="col-12 pt-2 pb-4 note-text">
                                          Submit your request through the link below and Fluidmeet team will help you with your search.
                                        </div>
                                      </div>
                                    </div>
                                  ) : (showMore === 'generic-bs') ? (
                                    <div className="form-general p-0">
                                      <div className="row mt-3 mb-4">
                                        <div className="col-12 pt-2 pb-4 note-text">
                                          Lots of licensing options available. Fill in your contact details and one of our consultants will contact you asap to discuss options available to you.
                                        </div>
                                        <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                                          <input type="name" id="fname" name="fname" className="form-control mb-2" placeholder="First name"
                                            defaultValue={(user) ? user.first_name : ''} required={true} />
                                          {(errors.fName) ? (<div className="form-error">{errors.fName}</div>) : (<></>)}
                                        </div>
                                        <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                                          <input type="name" id="lname" name="lname" className="form-control mb-2" placeholder="Last name"
                                            defaultValue={(user) ? user.last_name : ''} required={true} />
                                          {(errors.lName) ? (<div className="form-error">{errors.lName}</div>) : (<></>)}
                                        </div>
                                        <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                                          <input type="email" id="email" name="email" className="form-control mb-2" placeholder="Email address"
                                            defaultValue={(user) ? user.email : ''} required={true} />
                                          {(errors.email) ? (<div className="form-error">{errors.email}</div>) : (<></>)}
                                        </div>
                                        <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                                          <input type="tel" id="phone" name="phone" className="form-control mb-2" placeholder="Phone number"
                                            defaultValue={(user) ? user.phone : ''} required={true} />
                                          {(errors.phone) ? (<div className="form-error">{errors.phone}</div>) : (<></>)}
                                        </div>
                                        <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                                          <div className="selector">
                                            <CountryDropdown classes="select" defaultOptionLabel='Licensing Country' value={this.state.selectedCountry} onChange={(val) => this.setState({ selectedCountry: val })} />
                                          </div>
                                          {(this.state.countryError) ? (<div className="form-error">{this.state.countryError}</div>) : (<></>)}
                                        </div>
                                        <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                                          <div className="selector">
                                            <RegionDropdown classes="select" defaultOptionLabel='Licensing City' disableWhenEmpty={true} country={this.state.selectedCountry} value={this.state.selectedCity} onChange={(val) => this.setState({ selectedCity: val })} />
                                          </div>
                                          {(errors.company) ? (<div className="form-error">{errors.company}</div>) : (<></>)}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                            </div>
                            <div className="more-bottom">
                              {window.location.href.includes('meeting-rooms') ? <></> : <div className="dropdown-divider mb-3 mt-0" />}
                              {(showMore === 'generic-bs') ? (
                                window.location.href.includes('meeting-rooms') ? <></> : <button className="sendBtn mr-3 mr-2-mob" type="submit">
                                  {(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch} spin={true} /> loading</>) : 'Send Request'}
                                </button>
                              ) : (showMore === 'generic-lt') ? (
                                window.location.href.includes('meeting-rooms') ? <></> : <>
                                  <button className="cancelBtn mr-3" type="button" onClick={() => this.toggleShowMore()}>Not Now</button>
                                  <Link to={{ pathname: '/request/' + (match.params.type === 'co-working-spaces' ? 'lt-coworking' : 'lt-office') + '/generic?source=search', state: { term: 'monthly', source: 'search' } }}>
                                    <button className="sendBtn mr-3 mr-2-mob">Send Request</button>
                                  </Link>
                                </>
                              ) : (showMore === 'generic-ddr') ? (
                                window.location.href.includes('meeting-rooms') ? <></> : <>
                                  <button className="cancelBtn mr-3" type="button" onClick={() => this.toggleShowMore()}>Not Now</button>
                                  <Link to={{ pathname: '/request/' + (match.params.type === 'meeting-rooms' ? 'catered-meeting' : 'catered-banquet') + '/generic?source=search', state: { term: 'catered', source: 'search' } }}>
                                    <button className="sendBtn mr-3 mr-2-mob">Send Request</button>
                                  </Link>
                                </>
                              ) : (
                                window.location.href.includes('meeting-rooms') ? <></> : <button className="cancelBtn mr-3" type="button" onClick={() => this.toggleShowMore()}>Close</button>
                              )}
                            </div>
                          </div>
                          {/* </form> */}
                        </div>
                        : window.location.href.includes('banquets-and-event-spaces') ?
                          <div ref={this.showMoreRef} className="popup-send-requests-banquet-popup align-self-center">
                            <img className="banquet-popup-circular-div-img" src={banquet_popup_image} alt="find your Banquet" />
                            <div className='modal-right-block'>
                            <a href="tel:+971 50 3263932">
                              <img className="banquet-popup-phone-call-div-img-desktop" src={offices_popup_image_phone_logo} alt="Call US" />
                            </a>
                            {window.location.href.includes('banquets-and-event-spaces') ? <></> : <div className="dropdown-divider mt-4 mb-0" />}
                            {/* </div> */}
                            <div className="more-contain">
                              {window.location.href.includes('banquets-and-event-spaces') ?
                                // <div className="col-12 pt-2 pb-4 note-text">
                                //   Looking For A SERVICED OFFICES? <br></br>
                                //   Submit your request and let us help you find the most suitable option.
                                // </div>



                                <div class="">
                                  <button class="close" aria-label="Dismiss alert" type="button" data-close>
                                    <span onClick={() => {
                                      this.show_popUp_office_spaces();
                                    }} aria-hidden="true">&times;</span>
                                  </button>


                                  <h2 className="popup-send-requests-banquet-popup-title">CAN'T FIND THE RIGHT <br></br> EVENT VENUE FOR <br></br> YOUR EVENT<br></br> THEME?</h2>
                                  {this.state.width <= 1250 && this.state.width >= 750 ? <h6 className='banquet-popupform-body-inner-txt'>Submit your request and let us help<br></br> you find the most suitable venue<br></br> for your upcoming event.</h6> : <h6 className='banquet-popupform-body-inner-txt'>Submit your request and let us help you find the most suitable venue for your upcoming event.</h6>}
                                  <Link className='banquet-popup-form-btn-style btn btn-lg btn-primary fl_btn submit-btn' to={{ pathname: "/request/catered-banquet/generic?source=search", country: this.state.selectedCountry, city: this.state.selectedCity }}>
                                    Send Request
                                  </Link>

                                </div>
                                : (showMore === 'generic-lt') ?
                                  (
                                    <div className="form-general p-0">
                                      <div className="row mt-3 mb-4">
                                        <div className="col-12 pt-2 pb-4 note-text">
                                          Submit your request through the link below and Fluidmeet team will help you with your search.
                                        </div>
                                      </div>
                                    </div>
                                    // <div className="form-general p-0">
                                    //   <div className="row mt-3 mb-4 mrl-5">
                                    //     <div className="col-12 pt-2 pb-4 note-text">
                                    //       Fill in your contact details and one of our consultants will contact you asap to discuss the spaces available to you.
                                    //     </div>
                                    //     <div className="col-lg-6 col-12 pr-2 pr-mobile-2 pl-mobile-2">
                                    //       <input type="name" id="fname" name="fname" className="form-control mb-2" placeholder="First name"
                                    //              defaultValue={(user) ? user.firstName : ''} required={true} />
                                    //       {(errors.fName) ? (<div className="form-error">{errors.fName}</div>) : (<></>)}
                                    //     </div>
                                    //     <div className="col-lg-6 col-12 pl-2 pr-mobile-2 pl-mobile-2">
                                    //       <input type="name" id="lname" name="lname" className="form-control mb-2" placeholder="Last name"
                                    //              defaultValue={(user) ? user.lastName : ''} required={true} />
                                    //       {(errors.lName) ? (<div className="form-error">{errors.lName}</div>) : (<></>)}
                                    //     </div>
                                    //     <div className="col-lg-6 col-12 pr-2 pr-mobile-2 pl-mobile-2">
                                    //       <input type="email" id="email" name="email" className="form-control mb-2" placeholder="Email address"
                                    //              defaultValue={(user) ? user.email : ''} required={true} />
                                    //       {(errors.email) ? (<div className="form-error">{errors.email}</div>) : (<></>)}
                                    //     </div>
                                    //     <div className="col-lg-6 col-12 pl-2 pr-mobile-2 pl-mobile-2">
                                    //       <input type="tel" id="phone" name="phone" className="form-control mb-2" placeholder="Phone number"
                                    //              defaultValue={(user) ? user.phone : ''} required={true} />
                                    //       {(errors.phone) ? (<div className="form-error">{errors.phone}</div>) : (<></>)}
                                    //     </div>
                                    //     <div className="col-lg-6 col-12 pr-2 pr-mobile-2 pl-mobile-2">
                                    //       <input type="tel" id="company" name="company" className="form-control mb-2" placeholder="Company"
                                    //              defaultValue={(user) ? user.company : ''} />
                                    //       {(errors.company) ? (<div className="form-error">{errors.company}</div>) : (<></>)}
                                    //     </div>
                                    //   </div>
                                    // </div>
                                  ) : (showMore === 'generic-ddr') && (!window.location.href.includes('banquets-and-event-spaces')) ? (
                                    <div className="form-general p-0">
                                      <div className="row mt-3 mb-4">
                                        <div className="col-12 pt-2 pb-4 note-text">
                                          Submit your request through the link below and Fluidmeet team will help you with your search.
                                        </div>
                                      </div>
                                    </div>
                                  ) : (showMore === 'generic-bs') ? (
                                    <div className="form-general p-0">
                                      <div className="row mt-3 mb-4">
                                        <div className="col-12 pt-2 pb-4 note-text">
                                          Lots of licensing options available. Fill in your contact details and one of our consultants will contact you asap to discuss options available to you.
                                        </div>
                                        <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                                          <input type="name" id="fname" name="fname" className="form-control mb-2" placeholder="First name"
                                            defaultValue={(user) ? user.first_name : ''} required={true} />
                                          {(errors.fName) ? (<div className="form-error">{errors.fName}</div>) : (<></>)}
                                        </div>
                                        <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                                          <input type="name" id="lname" name="lname" className="form-control mb-2" placeholder="Last name"
                                            defaultValue={(user) ? user.last_name : ''} required={true} />
                                          {(errors.lName) ? (<div className="form-error">{errors.lName}</div>) : (<></>)}
                                        </div>
                                        <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                                          <input type="email" id="email" name="email" className="form-control mb-2" placeholder="Email address"
                                            defaultValue={(user) ? user.email : ''} required={true} />
                                          {(errors.email) ? (<div className="form-error">{errors.email}</div>) : (<></>)}
                                        </div>
                                        <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                                          <input type="tel" id="phone" name="phone" className="form-control mb-2" placeholder="Phone number"
                                            defaultValue={(user) ? user.phone : ''} required={true} />
                                          {(errors.phone) ? (<div className="form-error">{errors.phone}</div>) : (<></>)}
                                        </div>
                                        <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                                          <div className="selector">
                                            <CountryDropdown classes="select" defaultOptionLabel='Licensing Country' value={this.state.selectedCountry} onChange={(val) => this.setState({ selectedCountry: val })} />
                                          </div>
                                          {(this.state.countryError) ? (<div className="form-error">{this.state.countryError}</div>) : (<></>)}
                                        </div>
                                        <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                                          <div className="selector">
                                            <RegionDropdown classes="select" defaultOptionLabel='Licensing City' disableWhenEmpty={true} country={this.state.selectedCountry} value={this.state.selectedCity} onChange={(val) => this.setState({ selectedCity: val })} />
                                          </div>
                                          {(errors.company) ? (<div className="form-error">{errors.company}</div>) : (<></>)}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                            </div>
                            <div className="more-bottom">
                              {window.location.href.includes('banquets-and-event-spaces') ? <></> : <div className="dropdown-divider mb-3 mt-0" />}
                              {(showMore === 'generic-bs') ? (
                                window.location.href.includes('banquets-and-event-spaces') ? <></> : <button className="sendBtn mr-3 mr-2-mob" type="submit">
                                  {(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch} spin={true} /> loading</>) : 'Send Request'}
                                </button>
                              ) : (showMore === 'generic-lt') ? (
                                window.location.href.includes('banquets-and-event-spaces') ? <></> : <>
                                  <button className="cancelBtn mr-3" type="button" onClick={() => this.toggleShowMore()}>Not Now</button>
                                  <Link className='sendBtn mr-3 mr-2-mob' to={{ pathname: '/request/' + (match.params.type === 'co-working-spaces' ? 'lt-coworking' : 'lt-office') + '/generic?source=search', state: { term: 'monthly', source: 'search' } }}>
                                    Send Request
                                  </Link>
                                </>
                              ) : (showMore === 'generic-ddr') ? (
                                window.location.href.includes('banquets-and-event-spaces') ? <></> : <>
                                  <button className="cancelBtn mr-3" type="button" onClick={() => this.toggleShowMore()}>Not Now</button>
                                  <Link className='sendBtn mr-3 mr-2-mob' to={{ pathname: '/request/' + (match.params.type === 'banquets-and-event-spaces' ? 'catered-meeting' : 'catered-banquet') + '/generic?source=search', state: { term: 'catered', source: 'search' } }}>
                                    Send Request
                                  </Link>
                                </>
                              ) : (
                                window.location.href.includes('banquets-and-event-spaces') ? <></> : <button className="cancelBtn mr-3" type="button" onClick={() => this.toggleShowMore()}>Close</button>
                              )}
                            </div>
                            </div>
                            {/* </form> */}
                          </div>

                          :

                          <div ref={this.showMoreRef} className="more-box align-self-center space-page-box mini-box">
                            <form autoComplete="on" onSubmit={(e) => this.handleGeneralReq(e)}>
                              <div className="more-header">
                                {(showMore === 'generic-lt') ? (
                                  <h5 className="center pr-2 pl-2">
                                    {(match.params.type === 'co-working-spaces') ? 'Looking for a Co-working Space?' : ''}
                                  </h5>
                                ) : (showMore === 'generic-ddr') ? (
                                  <h5 className="center pr-2 pl-2">Can’t Find What You’re Looking For?</h5>
                                ) : (showMore === 'generic-bs') ? (
                                  <h5 className="center pr-2 pl-2">Looking for Similar Services?</h5>
                                ) : (
                                  ''
                                )}
                                {window.location.href.includes('office-spaces') ? <></> : <div className="dropdown-divider mt-4 mb-0" />}
                              </div>
                              <div className="more-contain space-page-box-contain">
                                {(showMore === 'generic-lt') && window.location.href.includes('office-spaces') ?
                                  // <div className="col-12 pt-2 pb-4 note-text">
                                  //   Looking For A SERVICED OFFICES? <br></br>
                                  //   Submit your request and let us help you find the most suitable option.
                                  // </div>



                                  <div class="box-style-size">
                                    <a href="#" title="Close" class="">Close</a>
                                    <img className="offices-popup-circular-div-img" src={offices_popup_image_mobile} alt="find your serviced office" />
                                    <div className="popup-send-requests-title"><h2>Looking For A SERVICED OFFICES?</h2></div>
                                    <form autoComplete="on" onSubmit={(e) => this.handleGeneralReq(e)}>
                                      <div className="form-general p-0">
                                        <input type="text" id="fname" name="fname" className="form-control" placeholder="First Name" required />
                                        {(this.state.fNameError) ? (<div className="form-error">{this.state.fNameError}</div>) : (<></>)}

                                        <input type="text" id="lname" name="lname" className="form-control" placeholder="Last Name" required />
                                        {(this.state.lNameError) ? (<div className="form-error">{this.state.lNameError}</div>) : (<></>)}

                                        <input type="email" id="email" name="email" className="form-control" placeholder="Email address" required />
                                        {(this.state.emailError) ? (<div className="form-error">{this.state.emailError}</div>) : (<></>)}

                                        <div className="selector">
                                          <FMPhoneInput type={'tel'} id="phone" placeholder="Phone number"
                                            extraClassNames={"bs-white-bs-pg"} /></div>
                                        {(this.state.phoneError) ? (<div className="form-error">{this.state.phoneError}</div>) : (<></>)}

                                        <input type="email" id="email" name="email" className="form-control" placeholder="Email address" required />
                                        {(this.state.emailError) ? (<div className="form-error">{this.state.emailError}</div>) : (<></>)}
                                      </div>
                                      <button type="submit" className={"btn btn-lg btn-primary btn-block fl_btn mt-3 " + ((reqLoading) ? 'ld-btn' : '')}>
                                        {(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch} spin={true} /> loading</>) : 'Send Request'}
                                      </button>
                                    </form>
                                  </div>
                                  : (showMore === 'generic-lt') ?
                                    (
                                      <div className="form-general p-0">
                                        <div className="row mt-3 mb-4">
                                          <div className="col-12 pt-2 pb-4 note-text">
                                            Submit your request through the link below and Fluidmeet team will help you with your search.
                                          </div>
                                        </div>
                                      </div>
                                      // <div className="form-general p-0">
                                      //   <div className="row mt-3 mb-4 mrl-5">
                                      //     <div className="col-12 pt-2 pb-4 note-text">
                                      //       Fill in your contact details and one of our consultants will contact you asap to discuss the spaces available to you.
                                      //     </div>
                                      //     <div className="col-lg-6 col-12 pr-2 pr-mobile-2 pl-mobile-2">
                                      //       <input type="name" id="fname" name="fname" className="form-control mb-2" placeholder="First name"
                                      //              defaultValue={(user) ? user.firstName : ''} required={true} />
                                      //       {(errors.fName) ? (<div className="form-error">{errors.fName}</div>) : (<></>)}
                                      //     </div>
                                      //     <div className="col-lg-6 col-12 pl-2 pr-mobile-2 pl-mobile-2">
                                      //       <input type="name" id="lname" name="lname" className="form-control mb-2" placeholder="Last name"
                                      //              defaultValue={(user) ? user.lastName : ''} required={true} />
                                      //       {(errors.lName) ? (<div className="form-error">{errors.lName}</div>) : (<></>)}
                                      //     </div>
                                      //     <div className="col-lg-6 col-12 pr-2 pr-mobile-2 pl-mobile-2">
                                      //       <input type="email" id="email" name="email" className="form-control mb-2" placeholder="Email address"
                                      //              defaultValue={(user) ? user.email : ''} required={true} />
                                      //       {(errors.email) ? (<div className="form-error">{errors.email}</div>) : (<></>)}
                                      //     </div>
                                      //     <div className="col-lg-6 col-12 pl-2 pr-mobile-2 pl-mobile-2">
                                      //       <input type="tel" id="phone" name="phone" className="form-control mb-2" placeholder="Phone number"
                                      //              defaultValue={(user) ? user.phone : ''} required={true} />
                                      //       {(errors.phone) ? (<div className="form-error">{errors.phone}</div>) : (<></>)}
                                      //     </div>
                                      //     <div className="col-lg-6 col-12 pr-2 pr-mobile-2 pl-mobile-2">
                                      //       <input type="tel" id="company" name="company" className="form-control mb-2" placeholder="Company"
                                      //              defaultValue={(user) ? user.company : ''} />
                                      //       {(errors.company) ? (<div className="form-error">{errors.company}</div>) : (<></>)}
                                      //     </div>
                                      //   </div>
                                      // </div>
                                    ) : (showMore === 'generic-ddr') ? (
                                      <div className="form-general p-0">
                                        <div className="row mt-3 mb-4">
                                          <div className="col-12 pt-2 pb-4 note-text">
                                            Submit your request through the link below and Fluidmeet team will help you with your search.
                                          </div>
                                        </div>
                                      </div>
                                    ) : (showMore === 'generic-bs') ? (
                                      <div className="form-general p-0">
                                        <div className="row mt-3 mb-4">
                                          <div className="col-12 pt-2 pb-4 note-text">
                                            Lots of licensing options available. Fill in your contact details and one of our consultants will contact you asap to discuss options available to you.
                                          </div>
                                          <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                                            <input type="name" id="fname" name="fname" className="form-control mb-2" placeholder="First name"
                                              defaultValue={(user) ? user.first_name : ''} required={true} />
                                            {(errors.fName) ? (<div className="form-error">{errors.fName}</div>) : (<></>)}
                                          </div>
                                          <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                                            <input type="name" id="lname" name="lname" className="form-control mb-2" placeholder="Last name"
                                              defaultValue={(user) ? user.last_name : ''} required={true} />
                                            {(errors.lName) ? (<div className="form-error">{errors.lName}</div>) : (<></>)}
                                          </div>
                                          <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                                            <input type="email" id="email" name="email" className="form-control mb-2" placeholder="Email address"
                                              defaultValue={(user) ? user.email : ''} required={true} />
                                            {(errors.email) ? (<div className="form-error">{errors.email}</div>) : (<></>)}
                                          </div>
                                          <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                                            <input type="tel" id="phone" name="phone" className="form-control mb-2" placeholder="Phone number"
                                              defaultValue={(user) ? user.phone : ''} required={true} />
                                            {(errors.phone) ? (<div className="form-error">{errors.phone}</div>) : (<></>)}
                                          </div>
                                          <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                                            <div className="selector">
                                              <CountryDropdown classes="select" defaultOptionLabel='Licensing Country' value={this.state.selectedCountry} onChange={(val) => this.setState({ selectedCountry: val })} />
                                            </div>
                                            {(this.state.countryError) ? (<div className="form-error">{this.state.countryError}</div>) : (<></>)}
                                          </div>
                                          <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                                            <div className="selector">
                                              <RegionDropdown classes="select" defaultOptionLabel='Licensing City' disableWhenEmpty={true} country={this.state.selectedCountry} value={this.state.selectedCity} onChange={(val) => this.setState({ selectedCity: val })} />
                                            </div>
                                            {(errors.company) ? (<div className="form-error">{errors.company}</div>) : (<></>)}
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                              </div>
                              <div className="more-bottom">
                                {window.location.href.includes('office-spaces') ? <></> : <div className="dropdown-divider mb-3 mt-0" />}
                                {(showMore === 'generic-bs') ? (
                                  window.location.href.includes('office-spaces') ? <></> : <button className="sendBtn mr-3 mr-2-mob" type="submit">
                                    {(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch} spin={true} /> loading</>) : 'Send Request'}
                                  </button>
                                ) : (showMore === 'generic-lt') ? (
                                  window.location.href.includes('office-spaces') ? <></> : <>
                                    <button className="cancelBtn mr-3" type="button" onClick={() => this.toggleShowMore()}>Not Now</button>
                                    <Link className='sendBtn mr-3 mr-2-mob' to={{ pathname: '/request/' + (match.params.type === 'co-working-spaces' ? 'lt-coworking' : 'lt-office') + '/generic?source=search', state: { term: 'monthly', source: 'search' } }}>
                                      Send Request
                                    </Link>
                                  </>
                                ) : (showMore === 'generic-ddr') ? (
                                  window.location.href.includes('office-spaces') ? <></> : <>
                                    <button className="cancelBtn mr-3" type="button" onClick={() => this.toggleShowMore()}>Not Now</button>
                                    <Link className="sendBtn mr-3 mr-2-mob" to={{ pathname: '/request/' + (match.params.type === 'meeting-rooms' ? 'catered-meeting' : 'catered-banquet') + '/generic?source=search', state: { term: 'catered', source: 'search' } }}>
                                      Send Request
                                    </Link>
                                  </>
                                ) : (
                                  window.location.href.includes('office-spaces') ? <></> : <button className="cancelBtn mr-3" type="button" onClick={() => this.toggleShowMore()}>Close</button>
                                )}
                              </div>
                            </form>
                          </div>}
                </div>
        }


        {(reqAlert && (
          <div id="alerts">
            <div className={"alert alert-" + reqAlert.type + " alert-dismissible floating-alert fade show"}>
              <strong>{reqAlert.title}</strong> {reqAlert.message}
              <button type="button" className="btn-close" onClick={() => this.setState({ reqAlert: null })}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </div>
        ))}

        <Footer />
      </>
    );
  }
}

const TwoThumbsSlider = withStyles({
  root: {
    color: '#000',
    height: 3,
    padding: '13px 0',
    maxWidth: 'calc(100% - 70px)',
    marginTop: '20px',
    marginRight: '35px',
    marginLeft: '35px',
    display: 'inline-block',
    borderRadius: 4,
  },
  thumb: {
    height: 27,
    width: 27,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    marginTop: -12,
    marginLeft: -13,
    boxShadow: '#ebebeb 0 2px 2px',
    '&:focus, &:hover, &$active': {
      boxShadow: '#ccc 0 2px 3px 1px',
    },
    '& .bar': {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  track: {
    color: '#00a9e0',
    height: 3,
    borderRadius: 4,
  },
  rail: {
    color: '#d8d8d8',
    opacity: 1,
    height: 3,
    borderRadius: 4,
  },
})(Slider);

const OneThumbSlider = withStyles({
  root: {
    color: '#000',
    padding: '13px 0',
    maxWidth: 'calc(100% - 70px)',
    marginTop: '20px',
    marginRight: '35px',
    marginLeft: '35px',
    display: 'inline-block',
    height: 8,
  },
  thumb: {
    height: 27,
    width: 27,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    marginTop: -12,
    marginLeft: -13,
    boxShadow: '#ebebeb 0 2px 2px',
    '&:focus, &:hover, &$active': {
      boxShadow: '#ccc 0 2px 3px 1px',
    },
    '& .bar': {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    color: '#00a9e0',
    height: 3,
    borderRadius: 4,
  },
  rail: {
    color: '#d8d8d8',
    opacity: 1,
    height: 3,
    borderRadius: 4,
  },
})(Slider);

export default connect((state, props) => ({
  defCurrency: state.settings.defCurrency,
}), null)(withRouter(SearchPage));

