import React, {Component} from 'react';
import {Link, Redirect, withRouter} from "react-router-dom";
import queryString from 'query-string';
import {GoogleMap, Marker} from "@react-google-maps/api";
import {
  createAlert,
  devLog,
  formValidation,
  getApiLinkV2,
  getCurrency,
  getLayoutImg,
  getSpaceTerms,
  getToken,
  getUser,
  hasTerm,
  printAmenity,
  printLink,
  printLinkRemoved,
  printMonth,
  printPrice,
  printRule,
  printSpaceData
} from "src/Helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import officeSpacesIcon from "src/img/icons/ico-reception.svg";
import parkingIcon from "src/img/icons/ico-free-parking.svg";
import {Pannellum} from "pannellum-react";
import {
  faArrowsAlt,
  faCircleNotch,
  faEllipsisV,
  faHeart as faSolidHeart,
  faPhoneAlt,
  faStar,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {faCheckCircle, faCircle, faHeart, faShareSquare,} from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import {getAxios} from "src/Helpers";
import home from 'src/img/home.png';
import styles from "../GoogleMapStyles.json";
import markerIcon from "src/img/blue-marker2.png";
import profileImg from "src/img/profile.jpg";
import NavBar from "./atoms/NavBar";
import BottomBar from "./atoms/BottomBar";
import {Carousel} from "react-responsive-carousel";
import Space from "./Space";
import facebook from "src/img/facebook.svg";
import twitter from "src/img/twitter.svg";
import linkedIn from "src/img/linkedin.svg";
import Footer from "./organisms/Footer";
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";
import Bugsnag from "@bugsnag/js";
import {connect} from "react-redux";
import {ShortTermBooking} from "./seeker/booking/short-term-booking";
import {RequestForProposal} from "./seeker/booking/request-for-proposal";

class Workspace extends Component {
  
  oldScroll = 0;
  stickDiv = null;
  slideDiv = null;
  bottomDiv = null;
  
  constructor(props) {
    super(props);

    this.state = {
      location: { lat: 25.1210779, lng: 55.2212647 },
      space: null,
      similarSpaces: [],
      isScrolled: false,
      isBottomReached: false,
      redirect: '',
      term: 'monthly',
      service: 'space',
      food: 'monthly',
      vrView: false,
      fullGallery: -1,
      request: {},
      errors: {},
      reqLoading: false,
      reqAlert: null,
      mapZoom: false
    }

    this.showMoreRef = React.createRef();
    this.handleClickOut = this.handleClickOut.bind(this);
    this.fetchSpace = this.fetchSpace.bind(this);
    this.fetchSimilarSpaces = this.fetchSimilarSpaces.bind(this);
    this.handleFavourite = this.handleFavourite.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.showFullGallery = this.showFullGallery.bind(this);
    this.toggle360View = this.toggle360View.bind(this);
    this.toggleShowMore = this.toggleShowMore.bind(this);
    this.printAmenities = this.printAmenities.bind(this);
    this.printReviews = this.printReviews.bind(this);
    this.handleTermChange = this.handleTermChange.bind(this);
    this.handleShare = this.handleShare.bind(this);
    this.handleGeneralReq = this.handleGeneralReq.bind(this);
  }

  componentDidMount() {

    window.scrollTo(0, 0);
    this.fetchSpace();

    window.addEventListener('scroll', this.handleScroll);
    document.addEventListener('mousedown', this.handleClickOut);
  
    //ReactGA.pageview(window.location.pathname + window.location.search);

    const url = getApiLinkV2('cron/ratings');
    const token = getToken();
    const headers = {'Content-Type': 'application/json'};
    if (token) {
      headers['Authorization'] = 'Bearer '+token;
    }

    getAxios().post(url, {
      headers: headers,
    });

    this.interval = setInterval(() => {

      const chatFrame = document.querySelector("#fc_frame");
      if (chatFrame) {
        if (window.innerWidth < 992) {
          devLog(window.innerWidth);
          chatFrame.style.bottom = (window.innerWidth < 441) ? '90px' : '110px';
        }
        clearInterval(this.interval);
      }

      const bookRowObj = document.querySelector('#book-row');
      const spaceImageObj = document.querySelector('#space-page-img');
      const spaceGalleryObj = document.querySelector('#space-gallery');
      if (bookRowObj)
        bookRowObj.style.maxHeight = (document.querySelector('#space-stick').offsetWidth - 100)+'px';
      if (spaceImageObj)
        spaceImageObj.style.width = (document.querySelector('#space-gallery').offsetWidth)+'px';
      if (spaceGalleryObj)
        spaceGalleryObj.style.opacity = 1;

    }, 1000);
    setTimeout(() => {
      clearInterval(this.interval);
    }, 10000);
  }

  componentWillUnmount() {

    clearInterval(this.interval);
    const chatFrame = document.querySelector("#fc_frame");
    if (chatFrame) {
      chatFrame.style.bottom = '20px';
    }

    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('mousedown', this.handleClickOut);
  }

  handleClickOut(event) {

    if (this.showMoreRef.current != null && !this.showMoreRef.current.contains(event.target)) {
      this.toggleShowMore();
    }
  }

  handleScroll(event) {

    if (window.innerWidth < 768) return;

    if (!this.slideDiv) {
      this.slideDiv = document.querySelector("#slide-cont");
      if (!this.slideDiv) return;
    }
    if (!this.stickDiv) {
      this.stickDiv = document.querySelector("#space-stick");
      if (!this.stickDiv) return;
    }

    const isScrollingDown = window.scrollY > this.oldScroll;
    const ref = isScrollingDown ? 615 : 630;
    const dist = isScrollingDown ? 180 : 150;

    if (window.scrollY > ref) {

      if (!this.slideDiv.classList.contains("sticky")) {
        this.slideDiv.classList.add("sticky");
        this.slideDiv.style = 'top: 80px';
      }

      if (!this.bottomDiv) {
        this.bottomDiv = document.querySelector("#bottom-cont");
        if (!this.bottomDiv) return;
      }

      if (window.scrollY > (this.bottomDiv.offsetTop-this.stickDiv.offsetHeight-dist)) {
        if (this.slideDiv.classList.contains("sticky")) {
          this.slideDiv.classList.remove("sticky");
          this.slideDiv.style = 'top: '+(this.bottomDiv.offsetTop-this.stickDiv.offsetHeight-80)+'px';
        }
      } else {
        if (!this.slideDiv.classList.contains("sticky")) {
          this.slideDiv.classList.add("sticky");
          this.slideDiv.style = 'top: 80px';
        }
      }

    } else {

      if (this.slideDiv.classList.contains("sticky")) {
        this.slideDiv.classList.remove("sticky");
        this.slideDiv.style = 'top: 710px';
      }
    }

    this.oldScroll = window.scrollY;
  }

  showFullGallery(index) {

    if (index > -1) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    this.setState({fullGallery: index});
  }

  toggle360View(show, space) {

    if (show) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    this.setState({vrView: show});
    // window.showPano(space.image_360);
  }

  toggleShowMore(type = null) {

    if (type) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    this.setState({showMore: type});
  }

  handleFavourite() {

    const token = getToken();
    if (!token) {
      this.setState({redirect: 'login'});
      return;
    }

    const { space } = this.state;
    const { service } = this.props;

    space.favourite = (space.favourite == 'no') ? 'yes' : 'no';
    this.setState({ space: space });

    const url = (service == 'service') ? getApiLinkV2('user/favourite-services') : getApiLinkV2('user/favourite-spaces');

    getAxios().post(url, {
      workspace_id: space.workspace_id,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token,
      },
    }).then(response => {
      const { space } = this.state;
      space.favourite = (response.data.favourite) ? 'yes' : 'no';
      this.setState({ space: space });
    })
    .catch(error => {
      Bugsnag.notify(error);
      console.error((error.response && error.response.data.error) ? error.response.data.error : error.message);
    });
  }

  fetchSpace() {

    const { match, location, history } = this.props;
    const search = queryString.parse(location.search);

    const url = getApiLinkV2('workspace/'+match.params.id);
    const token = getToken();
    const headers = {'Content-Type': 'application/json'};
    if (token) {
      headers['Authorization'] = 'Bearer '+token;
    }

    getAxios().get(url, {
      headers: headers,
    }).then(response => {
        if (response.data.workspace) {
          const space = response.data.workspace;
          const defSpaceData = printSpaceData(space.workspace_type_id);
          const spaceTerms = getSpaceTerms(space);
          const service = defSpaceData.service;
          var food = (search.f) ? search.f : defSpaceData.food;
          var term = spaceTerms[0];
          
          console.log("spaceeee", space);

          if (space.seeker_share_options != '') {
            const options = JSON.parse(space.seeker_share_options);
            if (options) {
              let map = options.find(x => x == 'map') != null;
              if(map) this.setState({mapZoom: true});
            }
          }

          if (search.t) {
            if (hasTerm(space, search.t)) {
              term = search.t;
            } else {
              history.push(printLinkRemoved(location.pathname, location.search, 't'));
            }
          }
          this.setState({
            space,
            similarSpaces: response.data.similar_workspace,
            term,
            food,
            service,
            location: {lat: parseFloat(space.latitude), lng: parseFloat(space.longitude)}
          });
          document.title = "Fluidmeet | " + space.name;
          devLog(response.data.workspace);
  
          let query = new URLSearchParams(this.props.location.search)
          let action = query.get('action')
          if (action === 'view-360' && space?.image_360) this.toggle360View(true, space)
        }
      })
      .catch(error => {
        Bugsnag.notify(error);
        this.setState({space: null, similarSpaces: [], location: {}});
        console.error((error.response && error.response.data.error) ? error.response.data.error : error.message);
        this.props.history.push('/error');
      });

      let viewedUrl = getApiLinkV2('workspace-viewed');
      let body = {
        'space_id': match.params.id
      };
      getAxios().post(viewedUrl, body, {headers}).then(response => console.log(response)).catch(error => console.log(error));
  }

  fetchSimilarSpaces() {

    const { match } = this.props;

    const url = getApiLinkV2('similar-workspaces/'+match.params.id);
    const token = getToken();
    const headers = {'Content-Type': 'application/json'};
    if (token) {
      headers['Authorization'] = 'Bearer '+token;
    }

    getAxios().get(url, {
      headers: headers,
    }).then(response => {
        this.setState({similarSpaces: response.data.similar_workspace});
      })
      .catch(error => {
        Bugsnag.notify(error);
        this.setState({similarSpaces: []});
      });
  }

  printAmenities(array) {

    var elements = [];
    Object.keys(array).forEach(key => {
      const item = array[key];
      const amenity = printAmenity(item.amenity);
      if (amenity) {
        elements.push(
          <div key={item.amenity_id} className="col-md-6 col-12">
            <div className="space-option">
              <img src={amenity.image}/>
              <span className="ml-2">{amenity.name}</span>
            </div>
          </div>
        );
      }
    });
    return elements;
  }

  printHostedBy = (space) => {

    var elements = [];
    var venueName = false;
    var hostName = false;
    var callVenue = false;

    if (space.seeker_share_options != '') {
      const options = JSON.parse(space.seeker_share_options);
      devLog(options)
      if (options) {
        hostName = options.find(x => x == 'host-name') != null
        venueName = options.find(x => x == 'venue-name') != null
        callVenue = options.find(x => x == 'call-now') != null
      }
    }

    if (hostName || venueName) {
      elements.push(
        <div className='hostCont'>
          <div className='hostImg'>
            <img src={home} style={{width: 30, height: 30}} />
          </div>
          <div className='hostDesc'>
            <div className='textTinySubtitle'>hosted by</div>
            <div className='textTitle'>{space.host_name}</div>
            <div className='host-text' style={{marginTop: 6}}>{space.venue_name}</div>
          </div>
          {callVenue && (
            <a href={"tel:"+space.host_phone}>
              <div className='callBtn'>
                <FontAwesomeIcon icon={faPhoneAlt} style={{fontSize: 17, color: '#FFFFFF'}} />
              </div>
            </a>
          )}
        </div>
      )
    } else {
      elements.push(
        <div className='hostCont' style={{backgroundColor: '#ecf1f7', padding: 25,}}>
          <div className='nohostImg' style={{marginTop: 'auto', marginBottom: 'auto'}}>
            <img src={home} style={{width: 30, height: 30, marginBottom: 3,}} />
          </div>
          <div className='hostDesc'>
            <div className='textTinySubtitle'>hosted by</div>
            <div className='host-text' style={{fontSize: 17}}>{space.venue_type}</div>
          </div>
          <div>
            <a href={"tel:+971 50 3263932"}>
              <div className='callBtn'>
                <FontAwesomeIcon icon={faPhoneAlt} style={{fontSize: 17, color: '#FFFFFF'}} />
              </div>
            </a>
          </div>
        </div>
      )
    }
    return elements;
  }

  handleGeneralReq(e) {
    e.preventDefault();

    this.setState({reqLoading: true});

    const { match, showMore, request, errors } = this.state;
    const data = new FormData(e.target);

    request.fName = data.get("fname");
    request.lName = data.get("lname");
    request.email = data.get("email");
    request.phone = data.get("phone");
    request.company = data.get("company");

    errors.fName = formValidation('fname', request.fName);
    errors.lName = formValidation('lname', request.lName);
    errors.email = formValidation('email', request.email);
    errors.phone = formValidation('phone', request.phone);
    errors.company = formValidation('company', request.company, false);

    Object.keys(errors).forEach(key => { if (!errors[key]) delete errors[key]; });

    this.setState({ errors: errors });
    if (Object.keys(errors).length > 0) {
      this.setState({reqLoading: false});
      return;
    }

    const url = getApiLinkV2('workspace/lt-request');
    const token = getToken();
    const headers = {'Content-Type': 'application/json'};
    if (token) {
      headers['Authorization'] = 'Bearer '+token;
    }

    getAxios().post(url, {
      rq_wid: 0,
      ri_first_name: request.fName,
      ri_last_name: request.lName,
      ri_email: request.email,
      ri_phone: request.phone,
      ri_company: request.company,
      source: 'space-details',
    }, {
      headers: headers,
    }).then(response => {
      document.getElementById('fname').value = '';
      document.getElementById('lname').value = '';
      document.getElementById('email').value = '';
      document.getElementById('phone').value = '';
      document.getElementById('company').value = '';
      createAlert(this, 'success', '', 'Your request has been sent successfully .');
      this.setState({ requestSent: true, reqLoading: false});
      this.toggleShowMore();
    }).catch(error => {
      Bugsnag.notify(error);
      createAlert(this, 'danger',(error.response && error.response.data.message) ? 'Error: ' : '', (error.response && error.response.data.message) ? error.response.data.message : error.message);
      this.setState({reqLoading: false});
    });
  }

  printReviews(reviews, showStars) {

    var elements = [];
    reviews.forEach(review => {
      elements.push(
        <div key={review.review_id} className="space-option">
          <div className="space-header border-0">
            <img className="space-review-img" src={(review.photo_url) ? review.photo_url : profileImg}/>
            <div className="space-review">
              <div className="ml-2">{review.first_name}</div>
              {(showStars && (
                <div className="ml-2">
                  <FontAwesomeIcon icon={faStar} style={{color: '#00a9e0'}}/>{" "}
                  {review.rating}
                </div>
              ))}
              <div className="ml-2">{review.comment}</div>
            </div>
          </div>
        </div>
      )
    });
    return elements;
  }

  handleTermChange(term) {

    const { location, history } = this.props;
    history.push(printLink(location.pathname, location.search, 't', term));
    this.setState({term: term});
  }

  handleShare(url) {
    window.open(url, "_blank", "width=500,height=400");
  }

  render() {  
    const {space, similarSpaces, location, fullGallery, vrView, redirect, term, service, food, showMore, errors, reqAlert, reqLoading} = this.state;
    const {match, defCurrency} = this.props;
    const user = getUser();
    const currency = getCurrency(defCurrency);

    if (space?.images && space?.images?.length > 0) window.images = space.images.map((item) => ("https://fluidmeet.s3.amazonaws.com/venue_logos/" + item.image))
  
    if (redirect !== '') {
      return (<Redirect to={{pathname: '/' + redirect, state: {prevLocation: this.props.location}}}/>);
    }
  
    if (!space) {
      return (
          <>
            <NavBar smallNav={true} smallSearch={true} space={{}}/>
            <div className="container">
              <section className="space-page">
            </section>
          </div>
        </>
      );
    }

    return (
      <>

        <NavBar smallNav={true} smallSearch={true} space={space} handleFavourite={this.handleFavourite} handleShare={this.toggleShowMore}/>

        <div id="slide-cont" className="container-fluid slide-cont">
          <div className="container-lg">
            <div className="space-container">
              <div className="space-content"/>
              <div id="space-stick" className="space-stick">

                <div className="book-toprow">

                  <h4 className="side-title w100 text-center">Book space</h4>

                  {(food === 'catered') ? (
                    <div className="booking-select">
                      <select name="duration" id="duration" className="select" defaultValue={term} onChange={(e) => this.handleTermChange(e.target.value)}>
                        {(hasTerm(space, 'catered') && (
                          <option value="catered">Room + Food & Beverages</option>
                        ))}
                        {(hasTerm(space, 'hourly') && (
                          <option value="hourly">Room Only</option>
                        ))}
                      </select>
                    </div>
                  ) : (

                    // (((term === 'hourly' && ((space.month_pass && space.month_price > 0) || (space.quarter_pass && space.quarter_price > 0) || (space.half_year_pass && space.half_year_price > 0) || (space.year_pass && space.year_price > 0))) || (term === 'monthly' && ((space.hour_pass && space.hour_price > 0) || (space.half_day_pass && space.half_day_price > 0) || (space.day_pass && space.day_price > 0)))) && (

                      <div className="booking-select">
                        <select name="duration" id="duration" className="select" defaultValue={term} onChange={(e) => this.handleTermChange(e.target.value)}>
                          {(hasTerm(space, 'monthly') && (
                            <option value="monthly">Long Term (monthly)</option>
                          ))}
                          {(hasTerm(space, 'hourly') && (
                            <option value="hourly">Short Term (hourly)</option>
                          ))}
                        </select>
                      </div>
                    // ))
                  )}

                </div>

                {(term === 'hourly') ? (
                  <div className="stick-cont w100">
                    <div className="min-term text-center w100">{(space.min_booking_hrs) ? space.min_booking_hrs : '1'}{(space.min_booking_hrs > 1) ? ' hours ' : ' hour '}min</div>
                    <div id="book-row" className="book-row w100 d-flex flex-row flex-wrap mb-3">
                      
                        {(space.hour_pass && space.hour_price > 0 && (
                          <div className="col-12">
                            <div className="space-options">
                              <div className="row">
                                <div className="col-5">
                                  <div className="bold">Hour</div>
                                </div>
                                <div className="col-7">
                                  <div className="text-right">
                                    {printPrice(space.hour_price, space.currency, currency.name, true)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        {(space.half_day_pass && space.half_day_price > 0 && (
                          <div className="col-12">
                            <div className="space-options">
                              <div className="row">
                                <div className="col-5">
                                  <div className="bold">Half day</div>
                                </div>
                                <div className="col-7">
                                  <div className="text-right">
                                    {printPrice(space.half_day_price, space.currency, currency.name, true)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        {(space.day_pass && space.day_price > 0 && (
                          <div className="col-12">
                            <div className="space-options">
                              <div className="row">
                                <div className="col-5">
                                  <div className="bold">Day</div>
                                </div>
                                <div className="col-7">
                                  <div className="text-right">
                                    {printPrice(space.day_price, space.currency, currency.name, true)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                    </div>
  
                    {user?.type != 'host' &&
                    <Link to={{
                      pathname: ShortTermBooking.getRoute(match.params.id, space.workspace_type_id),
                      // state: {space: space, term: 'hourly', food: food, service: service}
                    }} className="w100f">
                      <button className="bar-btn w100  mx-3">Book now</button>
                    </Link>
                    }
                    {space.rti_id &&
                      <div style={{padding: '18px'}}>
                        <i>
                          <span>This venue offers real time bookings. Space availability and bookings are instant.</span>
                        </i>
                      </div>
                    }
                  </div>
                ) : (food === 'catered') ? (
                  <div className="stick-cont">
                    {/*<div className="min-term text-center w100">Room + Food & Beverages</div>*/}
                    <div id="book-row" className="book-row w100 d-flex flex-row flex-wrap mb-3">
                        {(((space.winter_one_coffee_break && space.winter_one_coffee_break > 0) || (space.winter_two_coffee_break && space.winter_two_coffee_break > 0) && space.venue_pricing_automation == 1) ? (
                          <div className="col-12">
                            <div className="space-options">
                              <div className="row">
                                <div className="col-7">
                                  <div className="bold">Coffee</div>
                                </div>
                                <div className="col-5">
                                  {(space.winter_one_coffee_break && space.winter_one_coffee_break > 0 && (
                                    <div className="text-right">
                                      {printPrice(space.winter_one_coffee_break, space.currency, currency.name, true)} / Person</div>
                                  ))}
                                  {(space.winter_two_coffee_break && space.winter_two_coffee_break > 0 && (
                                    <div className="text-right">
                                      {printPrice(space.winter_two_coffee_break, space.currency, currency.name, true)} / Two</div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : ((space.one_coffee_break_pass && space.one_coffee_break_price > 0) || (space.two_coffee_break_pass && space.two_coffee_break_price > 0)) ? (
                          <div className="col-12">
                            <div className="space-options">
                              <div className="row">
                                <div className="col-5">
                                  <div className="bold">Coffee</div>
                                </div>
                                <div className="col-7">
                                  {(space.one_coffee_break_pass && space.one_coffee_break_price > 0 && (
                                    <div className="text-right">
                                      {printPrice(space.one_coffee_break_price, space.currency, currency.name, true)} / Person</div>
                                  ))}
                                  {(space.two_coffee_break_pass && space.two_coffee_break_price > 0 && (
                                    <div className="text-right">
                                      {printPrice(space.two_coffee_break_price, space.currency, currency.name, true)} / Two</div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null)}

                        {(space.winter_one_coffee_break_lunch_dinner && space.winter_one_coffee_break_lunch_dinner > 0 && space.venue_pricing_automation == 1 && (
                          <div className="col-12">
                            <div className="space-options">
                              <div className="row">
                                <div className="col-7">
                                  <div className="bold">One Coffee / Lunch & dinner</div>
                                </div>
                                <div className="col-5">
                                  <div className="text-right">
                                    {printPrice(space.winter_one_coffee_break_lunch_dinner, space.currency, currency.name, true)} / Person</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        {(space.winter_two_coffee_break_lunch_dinner && space.winter_two_coffee_break_lunch_dinner > 0 && space.venue_pricing_automation == 1 ? (
                          <div className="col-12">
                            <div className="space-options">
                              <div className="row">
                                <div className="col-7">
                                  <div className="bold">Two Coffee / Lunch & dinner</div>
                                </div>
                                <div className="col-5">
                                  <div className="text-right">
                                    {printPrice(space.winter_two_coffee_break_lunch_dinner, space.currency, currency.name, true)} / Person</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) :
                        ((space.one_coffee_break_lunch_dinner_pass && space.one_coffee_break_lunch_dinner_price > 0) || (space.two_coffee_break_lunch_dinner_pass && space.two_coffee_break_lunch_dinner_price > 0)) ?
                        (
                          <div className="col-12">
                            <div className="space-options">
                              <div className="row">
                                <div className="col-5">
                                  <div className="bold">Lunch + Coffee</div>
                                </div>
                                <div className="col-7">
                                  {(space.one_coffee_break_lunch_dinner_pass && space.one_coffee_break_lunch_dinner_price > 0 && (
                                    <div className="text-right">
                                      {printPrice(space.one_coffee_break_lunch_dinner_price, space.currency, currency.name, true)} / Person</div>
                                  ))}
                                  {(space.two_coffee_break_lunch_dinner_pass && space.two_coffee_break_lunch_dinner_price > 0 && (
                                      <div className="text-right">
                                        {printPrice(space.two_coffee_break_lunch_dinner_price, space.currency, currency.name, true)} /
                                        Two</div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null
                        )}
                        {(space.winter_lunch_dinner_non_alcoholic_drinks && space.winter_lunch_dinner_non_alcoholic_drinks > 0 && space.venue_pricing_automation == 1 ? (
                          <div className="col-12">
                            <div className="space-options">
                              <div className="row">
                                <div className="col-7">
                                  <div className="bold">Lunch & dinner / Non-Alcoholic Drinks</div>
                                </div>
                                <div className="col-5">
                                  <div className="text-right">
                                    {printPrice(space.winter_lunch_dinner_non_alcoholic_drinks, space.currency, currency.name, true)} / Person</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : space.lunch_dinner_pass && space.lunch_dinner_price > 0 ? (
                          <div className="col-12">
                            <div className="space-options">
                              <div className="row">
                                <div className="col-5">
                                  <div className="bold">Lunch</div>
                                </div>
                                <div className="col-7">
                                  <div className="text-right">
                                    {printPrice(space.lunch_dinner_price, space.currency, currency.name, true)} / Person</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null
                        )}

                        {(space.winter_lunch_dinner_alcoholic_drinks && space.winter_lunch_dinner_alcoholic_drinks > 0 && space.venue_pricing_automation == 1 && (
                          <div className="col-12">
                            <div className="space-options">
                              <div className="row">
                                <div className="col-7">
                                  <div className="bold">Lunch & dinner / Alcoholic Drinks</div>
                                </div>
                                <div className="col-5">
                                  <div className="text-right">
                                    {printPrice(space.winter_lunch_dinner_alcoholic_drinks, space.currency, currency.name, true)} / Person</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                        {(space.winter_minimum_price && space.winter_minimum_price > 0 && space.venue_pricing_automation == 1 && (
                          <div className="col-12" style={{margin: "10px 0px"}}>
                            <div className="row">
                              <div className="col-5">
                                <div className="text-right">Minimum price</div>
                              </div>
                              <div className="col-7">
                                <div className="text-right">
                                  {printPrice(space.winter_minimum_price, space.currency, currency.name, true)}</div>
                              </div>
                            </div>
                          </div>
                        ))}

                        {(((space.one_coffee_break_pass && space.one_coffee_break_price > 0 && space.venue_pricing_automation == 0) || (space.two_coffee_break_pass && space.two_coffee_break_price > 0 && space.venue_pricing_automation == 0)) && (
                          <div className="col-12">
                            <div className="space-options">
                              <div className="row">
                                <div className="col-5">
                                  <div className="bold">Coffee</div>
                                </div>
                                <div className="col-7">
                                  {(space.one_coffee_break_pass && space.one_coffee_break_price > 0 && (
                                    <div className="text-right">
                                      {printPrice(space.one_coffee_break_price, space.currency, currency.name, true)} / Person</div>
                                  ))}
                                  {(space.two_coffee_break_pass && space.two_coffee_break_price > 0 && (
                                    <div className="text-right">
                                      {printPrice(space.two_coffee_break_price, space.currency, currency.name, true)} / Two</div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                        {(space.lunch_dinner_pass && space.lunch_dinner_price > 0 && space.venue_pricing_automation == 0 && (
                          <div className="col-12">
                            <div className="space-options">
                              <div className="row">
                                <div className="col-5">
                                  <div className="bold">Lunch</div>
                                </div>
                                <div className="col-7">
                                  <div className="text-right">
                                    {printPrice(space.lunch_dinner_price, space.currency, currency.name, true)} / Person</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                        {(((space.one_coffee_break_lunch_dinner_pass && space.one_coffee_break_lunch_dinner_price > 0 && space.venue_pricing_automation == 0) || (space.two_coffee_break_lunch_dinner_pass && space.two_coffee_break_lunch_dinner_price > 0 && space.venue_pricing_automation == 0)) && (
                          <div className="col-12">
                            <div className="space-options">
                              <div className="row">
                                <div className="col-5">
                                  <div className="bold">Lunch + Coffee</div>
                                </div>
                                <div className="col-7">
                                  {(space.one_coffee_break_lunch_dinner_pass && space.one_coffee_break_lunch_dinner_price > 0 && (
                                    <div className="text-right">
                                      {printPrice(space.one_coffee_break_lunch_dinner_price, space.currency, currency.name, true)} / Person</div>
                                  ))}
                                  {(space.two_coffee_break_lunch_dinner_pass && space.two_coffee_break_lunch_dinner_price > 0 && (
                                      <div className="text-right">
                                        {printPrice(space.two_coffee_break_lunch_dinner_price, space.currency, currency.name, true)} /
                                        Two</div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                    {user?.type != 'host' && <>
                      <Link to={{
                        pathname: RequestForProposal.getRoute(match.params.id, space.workspace_type_id),
                        state: {space: space, term: 'catered', food: food, service: service}
                      }} className="w100f">
                        <button className="bar-btn book-btn w100 mx-3">Request a Quote</button>
                      </Link>
                      <button className="custom-btn clear-btn similar-spaces w100 "
                              onClick={() => this.toggleShowMore('generic-ddr')}>Looking for similar spaces?
                      </button>
                    </>
                    }
                  </div>
                ) : (
                  <div className="stick-cont">
                    <div className="min-term text-center w100">
                      {(space.term && space.term > 0) ? printMonth(space.term)+' min' : '1 month min'}
                    </div>
                    <div id="book-row" className="book-row w100 d-flex flex-row flex-wrap mb-3">
                      
                        {(space.month_pass && space.month_price > 0 && (
                          <div className="col-6 pr-2">
                            <div className="space-options">
                              <div className="bold">Month</div>
                              <div className="pt-1">
                                {printPrice(space.month_price, space.currency, currency.name, true)}
                              </div>
                            </div>
                          </div>
                        ))}
                        {(space.quarter_pass && space.quarter_price > 0 && (
                          <div className="col-6 pl-2">
                            <div className="space-options">
                              <div className="bold">Quarter</div>
                              <div className="pt-1">
                                {printPrice(space.quarter_price, space.currency, currency.name, true)}
                              </div>
                            </div>
                          </div>
                        ))}
                        {(space.half_year_pass && space.half_year_price > 0 && (
                          <div className="col-6 pr-2">
                            <div className="space-options">
                              <div className="bold">Half year</div>
                              <div className="pt-1">
                                {printPrice(space.half_year_price, space.currency, currency.name, true)}
                              </div>
                            </div>
                          </div>
                        ))}
                        {(space.year_pass && space.year_price > 0 && (
                            <div className="col-6 pl-2">
                              <div className="space-options">
                                <div className="bold">Year</div>
                                <div className="pt-1">
                                  {printPrice(space.year_price, space.currency, currency.name, true)}
                                </div>
                              </div>
                            </div>
                        ))}
                    </div>
                    {user?.type != 'host' && <>
                      <Link to={{
                        pathname: '/request/' + (space.workspace_type_id === '4' ? 'lt-coworking' : 'lt-office') + '/' + match.params.id,
                        state: {space: space, term: 'monthly', food: food, service: service, source: 'details'}
                      }} className="w100f">
                        <button className="bar-btn book-btn w100 mx-3">Send Request</button>
                      </Link>
                      <button className="custom-btn clear-btn similar-spaces w100"
                              onClick={() => this.toggleShowMore('generic-lt')}>Looking for similar spaces?
                      </button>
                    </>
                    }
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid bg-white h-z">
          <div className="container-lg h-z">
            <div className="space-page h-z">

              <h1 className="full-title h-z">{space.name} { space.rti_id && <span className="btn btn-sm btn-outline-success">Instant</span>}</h1>

              <span className="space-fulllocation h-z">{space.front_address}</span>

              <div className="space-subdiv h-z">
                <div className="space-subtitle mt-2">
                  <span className="space-rate">
                    <FontAwesomeIcon icon={faStar} style={{color: (space.workspace_rating && space.workspace_rating > 0) ? '#00a9e0' : '#E0E0E0'}}/>{" "}
                    {(space.workspace_rating && space.workspace_rating > 0) ? (
                      space.workspace_rating
                    ) : (
                      // (space.date_added) ? (
                      //   isSpaceOld(space.date_added)
                      // ) : (
                      ""
                      // )
                    )}
                    {(space.reviews > 0) ? (
                      <span className="full">({space.reviews})</span>
                    ) : (
                      <></>
                    )}
                  </span>
                  <span className="footer-divider">|</span>
                  {(space.featured === 'yes') ? (
                    <>
                    <span className="space-f">
                      <div className="featured"><div className="trending-img"/></div>
                      Featured
                    </span>
                      <span className="footer-divider">|</span>
                    </>
                  ) : (
                    <></>
                  )}
                  <span className="space-location">{space.front_address}</span>
                </div>

                <div className="space-actions mt-2">
                  <button className="space-btn align-end mr-2 ml-0 pl-0" onClick={this.handleFavourite}>
                  {(space.favourite === 'yes') ? (
                    <>
                    <FontAwesomeIcon icon={faSolidHeart} style={{width: 18, height: 18, marginTop: 3, marginRight: 5,}} color='#FF0032'/>{" "}
                    Favourited
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faHeart} style={{width: 18, height: 18, marginTop: 3, marginRight: 5,}}/>{" "}
                      Favourite
                    </>
                  )}
                  </button>
                  <button className="space-btn align-end ml-0 pl-0" onClick={() => this.toggleShowMore('share')}>
                    <FontAwesomeIcon icon={faShareSquare} style={{width: 18, height: 18, marginTop: 3, marginRight: 5,}}/>{" "}
                    Share
                  </button>
                </div>
              </div>

              <div id="space-gallery" className="space-gallery h-z">

                <div className="row">

                  {(space.image && (
                    <>

                      <div className="slider-cont">
                        <Carousel
                          selectedItem={0}
                          showArrows={true}
                          infiniteLoop={true}
                          showThumbs={false}
                          showStatus={true}
                          autoPlay={false}>
                          {(space.images.map((item, i) => (
                            <div key={i} className="myCarousel"
                                 onClick={() => this.showFullGallery(i)}>
                              <img src={"https://fluidmeet.s3.amazonaws.com/venue_logos/" + item.image}/>
                            </div>
                          )))}
                        </Carousel>
                      </div>

                      <div className="space-details-gallery">
                        <img id="space-page-img" className={"space-page-img pr-1 " + ((space.images.length > 1) ? " " : " br20 ") +
                        ((space.images.length > 2) ? "w70" : ((space.images.length > 1) ? "w50" : "w100Img"))}
                             src={"https://fluidmeet.s3.amazonaws.com/venue_logos/" + space.image} />
                        <div className={"pr-1 " + ((space.images.length > 1) ? " " : " br20 ") +
                        ((space.images.length > 2) ? "w70" : ((space.images.length > 1) ? "w50" : "w100Img"))} style={{position:'absolute',cursor: 'pointer',top: 0,left: 0,height: '100%'}} onClick={() => this.showFullGallery(0)} />

                        {(space.images.length > 1 && (
                          <div className={"space-img-side pl-1 " + ((space.images.length > 2) ? "w30" : "w50")}>

                          <img className={"space-page-img2 " + ((space.images.length > 2) ? "pb-1" : "brhalf20 h100")}
                          src={"https://fluidmeet.s3.amazonaws.com/venue_logos/" + space.images[1].image} />
                          <div className={((space.images.length > 2) ? "pb-1 h50" : "brhalf20 h100")}
                          style={{position:'absolute',cursor: 'pointer',top: 0,left: 0,right:0}}
                          onClick={() => this.showFullGallery(1)} />

                          {(space.images.length > 2 && (
                            <>
                            <img className="space-page-img2 space-img3 pt-1"
                            src={"https://fluidmeet.s3.amazonaws.com/venue_logos/" + space.images[2].image} />
                            <div className="pt-1"
                            style={{position:'absolute',cursor: 'pointer',bottom: 0,left: 0,right:0,height: '50%'}}
                            onClick={() => this.showFullGallery(2)} />
                            </>
                          ))}
                          </div>
                        ))}
                      </div>

                    </>
                  ))}

                </div>

                <div className="space-btns small-btns">
                  {(space.image_360 && (
                    <button className=" fl_btn space-gallery-btn shadow-sm" onClick={() => this.toggle360View(true, space)}>
                      <FontAwesomeIcon icon={faArrowsAlt}/>
                      <span className="ml-2">360 View</span>
                    </button>
                  ))}

                  {(space.images.length > 3 && (
                    <button className=" fl_btn space-gallery-btn shadow-sm show-all" onClick={() => this.showFullGallery(0)}>
                      <FontAwesomeIcon icon={faEllipsisV}/>
                      <FontAwesomeIcon icon={faEllipsisV}/>
                      <FontAwesomeIcon icon={faEllipsisV}/>
                      <span className="ml-2">Show all photos</span>
                    </button>
                  ))}
                </div>
              </div>

              <div className="space-container">

                <div className="space-content h-z">

                  <div className="space-header">
                    <div className="space-headersubtitle">

                      {this.printHostedBy(space)}

                      <div className="row pb-3 option-featured">
                        <div className="col-6">
                      {/*{(space.featured === 'yes' && (*/}
                      {/*<div className="space-option">*/}
                      {/*  <div className="featured"><div className="trending-img" /></div>*/}
                      {/*  <span>Featured</span>*/}
                      {/*</div>*/}
                      {/*))}*/}
                      <div className="space-option">
                        <FontAwesomeIcon icon={faCheckCircle} className="icon fz17" />
                        <span>Verified</span>
                      </div>
                      {/*<div className="space-option">*/}
                      {/*  <FontAwesomeIcon icon={faStar} className="icon" />*/}
                      {/*  <span>{space.reviews} Reviews</span>*/}
                      </div>
                      {/*</div>*/}
                      {/*<div className="col-6">*/}
                      {/*<div className="space-option">*/}
                      {/*  <span>Response rate: 100%</span>*/}
                      {/*</div>*/}
                      {/*<div className="space-option">*/}
                      {/*  <span>Response time: within an hour</span>*/}
                      {/*</div>*/}
                      {/*  </div>*/}
                      </div>


                    </div>
                  </div>

                  <div className="space-about pt-3">

                    {(space.suitable.length > 0 && (
                      <div className="space-suitable">
                        {space.suitable.map(item => (
                          <div className="suitablefor">{item.name}</div>
                        ))}
                      </div>
                    ))}

                    <div className="row">

                      {(space.licencing && (
                        <Link className="space-feature-link" to='/business-setup'>
                          <div className="col-12">
                            <div className="space-feature">
                              <div className="imgdiv">
                                <img src={officeSpacesIcon} style={{width: 36, height: 36 }} />
                              </div>
                              <div className="space-feature-cont underline_hover">
                                <div className="space-feature-title fz-17">Licensing Available</div>
                                <div className="space-feature-subtitle fz-17">Suitable for company establishment</div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))}

                      {(space.parking_instruction && (
                        <div className="col-12">
                          <div className="space-feature">
                            <div className="imgdiv">
                              <img src={parkingIcon} style={{width: 39, height: 45 }} />
                            </div>
                            <div className="space-feature-cont">
                              <div className="space-feature-title">Parking Available</div>
                              <div className="space-feature-subtitle">{space.parking_instruction}</div>
                            </div>
                          </div>
                        </div>
                      ))}

                    </div>
                  </div>

                  {(Object.keys(space.layouts).length > 0 && (
                    <div className="space-about">
                      <h1>Layouts and Capacity</h1>
                      <div className="row">
                        {Object.keys(space.layouts).map(key => (
                          <div key={key} className="col-md-3 col-6">
                            <div className="space-layout">
                              <img src={getLayoutImg(key)}/>
                              <div className="layout-count">{space.layouts[key]}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}

                  <div className="space-about">
                    <h1>Details</h1>
                    {(space.description.length > 500) ? (
                      <>
                      <div className="space-about-desc">{space.description.slice(0, 500)}...</div>
                      <button className=" fl_btn space-gallery-btn opt-btn" onClick={() => this.toggleShowMore('details')}>
                        <span>Read more</span>
                      </button>
                      </>
                    ) : (
                      <div className="space-about-desc">{space.description}</div>
                    )}

                    <div className="space-subdiv h-z">
                      <div className="space-subtitle mt-3">
                        <span>{printSpaceData(space.workspace_type_id).name}</span>
                          {(space.size && space.size > 0 && (
                            <>
                              <span className="text-divider">|</span>
                              <span>{space.size} sqft</span>
                            </>
                          ))}
                          {(space.capacity && space.capacity > 0 && (
                            <>
                              <span className="text-divider">|</span>
                              <span>{space.capacity} people max</span>
                            </>
                          ))}
                      </div>
                    </div>

                  </div>

                  {(Object.keys(space.amenity).length > 0 && (
                    <div className={"space-about "+((space.venue_facilities && Object.keys(space.venue_facilities).length > 0) ? "border-0" : "")}>
                      <h1>Amenities</h1>
                      <div className="row">
                        {this.printAmenities(space.amenity)}
                      </div>
                      {(Object.keys(space.amenity).length > 10 && (
                        <button className=" fl_btn space-gallery-btn opt-btn" onClick={() => this.toggleShowMore('amenity')}>
                          <span>Show more</span>
                        </button>
                      ))}
                    </div>
                  ))}

                  {(Object.keys(space.venue_facilities).length > 0 && (
                    <div className="space-about pt-2">
                      <h1>Facilities</h1>
                      <div className="row">
                        {this.printAmenities(space.venue_facilities)}
                      </div>
                      {(Object.keys(space.venue_facilities).length > 10 && (
                        <button className=" fl_btn space-gallery-btn opt-btn" onClick={() => this.toggleShowMore('facilities')}>
                          <span>Show more</span>
                        </button>
                      ))}
                    </div>
                  ))}

                  {((( (space.term || (space.security_deposit && space.security_deposit !== '0.00' && space.security_deposit > 0) || space.outside_catering !== 'yes') ) || ( space.operationHours && space.operationHours.length > 0 ) ) && (

                    <div className="space-about">

                      <h1>Need to know</h1>

                      {(space.operationHours && space.operationHours.length > 0 && (
                        <>
                        <h3 className="pt18">Working hours</h3>
                          {space.operationHours.map(item => (
                            <div className="space-option">
                              <FontAwesomeIcon icon={faCircle} color="#617d98" style={{marginTop: 4}}/>
                              <span className="ml-2">
                                {/*{(*/}
                                {/*  item.days.length > 2) ? (*/}
                                {/*  item.days[0]+' - '+item.days[item.days.length-1]*/}
                                {/*) : (*/}
                                {item.days.map((day, i) => (i === item.days.length-1) ? day : day+', ')}
                                {/*)}*/}
                                : {item.time}
                              </span>
                            </div>
                          ))}
                        </>
                      ))}

                      {((space.term || (space.security_deposit && space.security_deposit !== '0.00' && space.security_deposit > 0) || space.outside_catering !== 'yes') && (
                        <>
                          <h3 className="mt-4">Space rules</h3>
                          {(space.term && (
                            <div className="space-option">
                              <FontAwesomeIcon icon={faCircle} color="#617d98" style={{marginTop: 4}}/>
                              <span className="ml-2">Minimum Term: {space.term}</span>
                            </div>
                          ))}
                          {(space.rule && (
                            Object.keys(space.rule).map(key => (
                              <div className="space-option">
                                <FontAwesomeIcon icon={faCircle} color="#617d98" style={{marginTop: 4}}/>
                                <span className="ml-2">{printRule(space.rule[key].rule)}</span>
                              </div>
                            ))
                          ))}
                          {(space.security_deposit && space.security_deposit !== '0.00' && space.security_deposit > 0 && (
                            <div className="space-option">
                              <FontAwesomeIcon icon={faCircle} color="#617d98" style={{marginTop: 4}}/>
                              <span className="ml-2">Security Deposit: ${space.security_deposit}</span>
                            </div>
                          ))}
                          <div className="space-option">
                            <FontAwesomeIcon icon={faCircle} color="#617d98" style={{marginTop: 4}}/>
                            {(space.outside_catering === 'yes') ? (
                              <span className="ml-2">Out of venue catering is allowed</span>
                            ) : (
                              <span className="ml-2">Out of venue catering is not allowed</span>
                            )}
                          </div>
                        </>
                      ))}

                    </div>
                  ))}

                  <div className="space-about border-0">
                    <h1>Location</h1>

                    <div className="map-cont">
                      {/*<LoadScript googleMapsApiKey='AIzaSyBDM449V7qE4OkyoV2zP3xm_VQIYDvrHVw'>*/}
                        <GoogleMap
                          options={{
                            styles: styles,
                            keyboardShortcuts: false,
                            scaleControl: true,
                            draggable: true,
                            gestureHandling: "greedy",
                            typeControl: true,
                            // mapTypeControl: true,
                            // mapTypeControlOptions: {position: 1},
                            // zoomControl: true,
                            // zoomControlOptions: {position: 8},
                            maxZoom: this.state.mapZoom ? 50 : 11
                          }}
                          mapContainerStyle={{height: "100%", width: "100%"}}
                          zoom={11}
                          center={location}>
                          <Marker key={space.workspace_id} name={space.workspace_id} space={space} position={location} icon={markerIcon}/>
                        </GoogleMap>
                      {/*</LoadScript>*/}
                    </div>

                  </div>

                  {(space.reviews > 0 && (
                    <div className="space-about border-0">
                      <h1>
                        <FontAwesomeIcon icon={faStar} style={{color: '#00a9e0', fontSize: 18, marginBottom: 1}}/>{" "}
                        <span>{(space.workspace_rating) ? space.workspace_rating : '0'} ({space.reviews} reviews)</span>
                      </h1>
                      {this.printReviews(space.workspace_reviews, false)}
                      <button className=" fl_btn space-gallery-btn opt-btn" onClick={() => this.toggleShowMore('reviews')}>
                        <span>Show all {space.reviews} review</span>
                      </button>
                    </div>
                  ))}

                </div>

              </div>
            </div>
          </div>
        </div>

        {(fullGallery > -1 && (
          <div className="fullgallery">
            <div className="space-gallery" style={{opacity: 1}}>
              <Carousel
                selectedItem={fullGallery}
                showArrows={true}
                infiniteLoop={true}
                showThumbs={true}
                showStatus={true}
                autoPlay={false}>
                {(space.images.map((item, i) => (
                  <div key={i} className="myCarousel">
                    <img src={"https://fluidmeet.s3.amazonaws.com/venue_logos/" + item.image}/>
                  </div>
                )))}
              </Carousel>
            </div>
            <button className="btn-close-page" type="button" onClick={() => this.showFullGallery(-1)}>
              <FontAwesomeIcon icon={faTimes}/>
            </button>
          </div>
        ))}

        <div className={"more-filter fz-17 justify-content-center " + ((showMore) ? "show-flex" : "")}>
          <div ref={this.showMoreRef} className="more-box align-self-center space-page-box">
            <form autoComplete="on" onSubmit={(e) => this.handleGeneralReq(e)}>
              <div className="more-header">
                {(showMore === 'share') ? (
                  <h5 className="center pr-2 pl-2">Share space</h5>
                ) : (showMore === 'details') ? (
                    <h5 className="center pr-2 pl-2">Description</h5>
                ) : (showMore === 'amenity') ? (
                    <h5 className="center pr-2 pl-2">Amenities</h5>
                ) : (showMore === 'facilities') ? (
                    <h5 className="center pr-2 pl-2">Facilities</h5>
                ) : (showMore === 'generic-lt') ? (
                  <h5 className="center pr-2 pl-2">Looking for Long Term Space?</h5>
                ) : (showMore === 'generic-ddr') ? (
                  <h5 className="center pr-2 pl-2">Looking for Similar Spaces?</h5>
                ) : (showMore === 'generic-bs') ? (
                  <h5 className="center pr-2 pl-2">Looking for Similar Services?</h5>
                ) : (showMore === 'reviews') ? (
                    <h5 className="center pr-2 pl-2">Reviews</h5>
                ) : (showMore === 'allprices') ? (
                  (food === "catered") ? (
                    (term === 'catered') ? (
                      <h5 className="center pr-2 pl-2">All Room + Food & Beverages Prices</h5>
                    ) : (
                      <h5 className="center pr-2 pl-2">All Space Only Prices</h5>
                    )
                  ) : (
                    (term === 'monthly') ? (
                      <h5 className="center pr-2 pl-2">All Long Term Prices</h5>
                    ) : (
                      <h5 className="center pr-2 pl-2">All Short Term Prices</h5>
                    )
                  )
                ) : (
                  <h5 className="center pr-2 pl-2">More</h5>
                )}
                <div className="dropdown-divider mt-4 mb-0" />
              </div>
              <div className="more-contain space-page-box-contain">

                {(showMore === 'details') ? (
                  <div className="space-about border-0">{space.description}</div>
                ) : (showMore === 'amenity') ? (
                  this.printAmenities(space.amenity)
                ) : (showMore === 'facilities') ? (
                  this.printAmenities(space.venue_facilities)
                ) : (showMore === 'reviews') ? (
                  this.printReviews(space.all_reviews, true)
                ) : (showMore === 'generic-ddr') ? (
                  <div className="form-general p-0">
                    <div className="row mt-3 mb-4">
                      <div className="col-12 pt-2 pb-4 note-text">
                        Submit your request through the link below and Fluidmeet team will help you with your search.
                      </div>
                    </div>
                  </div>
                ) : (showMore === 'generic-lt') ? (
                    <div className="form-general p-0">
                      <div className="row mt-3 mb-4">
                        <div className="col-12 pt-2 pb-4 note-text">
                          Submit your request through the link below and Fluidmeet team will help you with your search.
                        </div>
                      </div>
                    </div>
                  // <div className="form-general p-0">
                  //   <div className="row mt-3 mb-4">
                  //     <div className="col-12 pt-2 pb-4 note-text">
                  //       Fill in your contact details and one of our consultants will contact you asap to discuss the available options for you.
                  //     </div>
                  //     <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                  //       <input type="name" id="fname" name="fname" className="form-control mb-2" placeholder="First name"
                  //              defaultValue={(user) ? user.firstName : ''} required={true} />
                  //       {(errors.fName) ? (<div className="form-error">{errors.fName}</div>) : (<></>)}
                  //     </div>
                  //     <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                  //       <input type="name" id="lname" name="lname" className="form-control mb-2" placeholder="Last name"
                  //              defaultValue={(user) ? user.lastName : ''} required={true} />
                  //       {(errors.lName) ? (<div className="form-error">{errors.lName}</div>) : (<></>)}
                  //     </div>
                  //     <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                  //       <input type="email" id="email" name="email" className="form-control mb-2" placeholder="Email address"
                  //              defaultValue={(user) ? user.email : ''} required={true} />
                  //       {(errors.email) ? (<div className="form-error">{errors.email}</div>) : (<></>)}
                  //     </div>
                  //     <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                  //       <input type="tel" id="phone" name="phone" className="form-control mb-2" placeholder="Phone number"
                  //              defaultValue={(user) ? user.phone : ''} required={true} />
                  //       {(errors.phone) ? (<div className="form-error">{errors.phone}</div>) : (<></>)}
                  //     </div>
                  //     <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                  //       <input type="tel" id="company" name="company" className="form-control mb-2" placeholder="Company"
                  //              defaultValue={(user) ? user.company : ''} required={true} />
                  //       {(errors.company) ? (<div className="form-error">{errors.company}</div>) : (<></>)}
                  //     </div>
                  //   </div>
                  // </div>
                ) : (showMore === 'generic-bs') ? (
                  <div className="form-general p-0">
                    <div className="row mt-3 mb-4">
                      <div className="col-12 pt-2 pb-4 note-text">
                        Lots of licensing options available. Fill in your contact details and one of our consultants will contact you asap to discuss options available to you.
                      </div>
                      <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                        <input type="name" id="fname" name="fname" className="form-control mb-2" placeholder="First name"
                               defaultValue={(user) ? user.first_name : ''} required={true} />
                        {(errors.fName) ? (<div className="form-error">{errors.fName}</div>) : (<></>)}
                      </div>
                      <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                        <input type="name" id="lname" name="lname" className="form-control mb-2" placeholder="Last name"
                               defaultValue={(user) ? user.last_name : ''} required={true} />
                        {(errors.lName) ? (<div className="form-error">{errors.lName}</div>) : (<></>)}
                      </div>
                      <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                        <input type="email" id="email" name="email" className="form-control mb-2" placeholder="Email address"
                               defaultValue={(user) ? user.email : ''} required={true} />
                        {(errors.email) ? (<div className="form-error">{errors.email}</div>) : (<></>)}
                      </div>
                      <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                        <input type="tel" id="phone" name="phone" className="form-control mb-2" placeholder="Phone number"
                               defaultValue={(user) ? user.phone : ''} required={true} />
                        {(errors.phone) ? (<div className="form-error">{errors.phone}</div>) : (<></>)}
                      </div>
                      <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                        <input type="tel" id="company" name="company" className="form-control mb-2" placeholder="Company"
                               defaultValue={(user) ? user.company : ''} required={true} />
                        {(errors.company) ? (<div className="form-error">{errors.company}</div>) : (<></>)}
                      </div>
                      <div className="col-lg-6 col-12 pl-2 pl-mobile-0">
                        <div className="selector">
                          <CountryDropdown classes="select" defaultOptionLabel='Licensing Country' value={this.state.selectedCountry} onChange={(val) => this.setState({ selectedCountry: val })} />
                        </div>
                        {(this.state.countryError) ? (<div className="form-error">{this.state.countryError}</div>) : (<></>)}
                      </div>
                      <div className="col-lg-6 col-12 pr-2 pr-mobile-0">
                        <div className="selector">
                          <RegionDropdown classes="select" defaultOptionLabel='Licensing City' disableWhenEmpty={true} country={this.state.selectedCountry} value={this.state.selectedCity} onChange={(val) => this.setState({ selectedCity: val })} />
                        </div>
                        {(errors.company) ? (<div className="form-error">{errors.company}</div>) : (<></>)}
                      </div>
                    </div>
                  </div>
                ) : (showMore === 'allprices') ? (

                  (term == 'catered') ? (
                    <div className="row mb-3">
                      {(((space.one_coffee_break_pass && space.one_coffee_break_price > 0) || (space.two_coffee_break_pass && space.two_coffee_break_price > 0)) && (
                        <div className="col-12">
                          <div className="space-options">
                            <div className="w100 bold text-center">Coffee</div>
                            {(space.one_coffee_break_pass && space.one_coffee_break_price > 0 && (
                              <div className="w100 text-center">{printPrice(space.one_coffee_break_price, space.currency, currency.name)} / One person
                              </div>
                            ))}
                            {(space.two_coffee_break_pass && space.two_coffee_break_price > 0 && (
                              <div className="w100 text-center">{printPrice(space.two_coffee_break_price, space.currency, currency.name)} / Two person
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                      {(space.lunch_dinner_pass && space.lunch_dinner_price > 0 && (
                        <div className="col-12">
                          <div className="space-options">
                            <div className="w100 bold text-center">Lunch / Dinner</div>
                            <div className="w100 text-center">
                              {printPrice(space.lunch_dinner_price, space.currency, currency.name)} / One person
                            </div>
                          </div>
                        </div>
                      ))}
                      {(((space.one_coffee_break_lunch_dinner_pass && space.one_coffee_break_lunch_dinner_price) || (space.two_coffee_break_lunch_dinner_pass && space.two_coffee_break_lunch_dinner_price > 0)) > 0 && (
                        <div className="col-12">
                          <div className="space-options">
                            <div className="w100 bold text-center">Lunch / Dinner + Coffee</div>
                            {(space.one_coffee_break_lunch_dinner_pass && space.one_coffee_break_lunch_dinner_price > 0 && (
                              <div className="w100 text-center">
                                {printPrice(space.one_coffee_break_lunch_dinner_price, space.currency, currency.name)} / One person
                              </div>
                            ))}
                            {(space.two_coffee_break_lunch_dinner_pass && space.two_coffee_break_lunch_dinner_price > 0 && (
                              <div className="w100 text-center">
                                {printPrice(space.two_coffee_break_lunch_dinner_price, space.currency, currency.name)} / Two person
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (term == 'monthly') ? (
                    <div className="row mb-3">
                      {(space.month_pass && space.month_price > 0 && (
                        <div className="col-12">
                          <div className="space-options">
                            <div className="text-center bold">Month</div>
                            <div className="text-center pt-1">
                              {printPrice(space.month_price, space.currency, currency.name)}
                            </div>
                          </div>
                        </div>
                      ))}
                      {(space.quarter_pass && space.quarter_price > 0 && (
                        <div className="col-12">
                          <div className="space-options">
                            <div className="text-center bold">Quarter</div>
                            <div className="text-center pt-1">
                              {printPrice(space.quarter_price, space.currency, currency.name)}
                            </div>
                          </div>
                        </div>
                      ))}
                      {(space.half_year_pass && space.half_year_price > 0 && (
                        <div className="col-12">
                          <div className="space-options">
                            <div className="text-center bold">Half year</div>
                            <div className="text-center pt-1">
                              {printPrice(space.half_year_price, space.currency, currency.name)}
                            </div>
                          </div>
                        </div>
                      ))}
                      {(space.year_pass && space.year_price > 0 && (
                        <div className="col-12">
                          <div className="space-options">
                            <div className="text-center bold">Year</div>
                            <div className="text-center pt-1">
                              {printPrice(space.year_price, space.currency, currency.name)}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="row mb-3">
                      {(space.hour_pass && space.hour_price > 0 && (
                        <div className="col-12">
                          <div className="space-options">
                            <div className="row">
                              <div className="col-5">
                                <div className="bold">Hour</div>
                              </div>
                              <div className="col-7">
                                <div className="text-right">
                                  {printPrice(space.hour_price, space.currency, currency.name)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      {(space.half_day_pass && space.half_day_price > 0 && (
                        <div className="col-12">
                          <div className="space-options">
                            <div className="row">
                              <div className="col-6">
                                <div className="bold">Half day</div>
                              </div>
                              <div className="col-6">
                                <div className="text-right">
                                  {printPrice(space.half_day_price, space.currency, currency.name)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      {(space.day_pass && space.day_price > 0 && (
                        <div className="col-12">
                          <div className="space-options">
                            <div className="row">
                              <div className="col-5">
                                <div className="bold">Day</div>
                              </div>
                              <div className="col-7">
                                <div className="text-right">
                                  {printPrice(space.day_price, space.currency, currency.name)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )

                ) : (showMore === 'share') ? (
                  <ul className="pt-2">
                    <li className="firebaseui-list-item pb-2" onClick={() => this.handleShare("https://www.facebook.com/sharer/sharer.php?sdk=joey&u=" + encodeURI('https://www.fluidmeet.com/space/'+space.workspace_id) + "&display=popup&ref=plugin&src=share_button")}>
                      <div id="fbBtn" className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-id-idp-button">
                        <span className="firebaseui-idp-icon-wrapper">
                          <img className="firebaseui-idp-icon fb-icon" alt="" src={facebook} />
                        </span>
                        <span className="firebaseui-idp-text firebaseui-idp-text-long text-dark">Share using Facebook</span>
                      </div>
                    </li>
                    <li className="firebaseui-list-item pb-2" onClick={() => this.handleShare("https://twitter.com/intent/tweet?url=" + encodeURI('https://www.fluidmeet.com/space/'+space.workspace_id))}>
                      <div id="fbBtn" className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-id-idp-button">
                        <span className="firebaseui-idp-icon-wrapper">
                          <img className="firebaseui-idp-icon fb-icon" alt="" src={twitter} />
                        </span>
                        <span className="firebaseui-idp-text firebaseui-idp-text-long text-dark">Share using Twitter</span>
                      </div>
                    </li>
                    <li className="firebaseui-list-item" onClick={() => this.handleShare("https://www.linkedin.com/shareArticle?mini=true&url=" + encodeURI('https://www.fluidmeet.com/space/'+space.workspace_id) + "&source=" + encodeURI('https://www.fluidmeet.com/'))}>
                      <div id="fbBtn" className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-id-idp-button">
                        <span className="firebaseui-idp-icon-wrapper">
                          <img className="firebaseui-idp-icon fb-icon" alt="" src={linkedIn} />
                        </span>
                        <span className="firebaseui-idp-text firebaseui-idp-text-long text-dark">Share using LinkedIn</span>
                      </div>
                    </li>
                  </ul>
                ) : (
                  <></>
                )}
              </div>
              <div className="more-bottom">
                <div className="dropdown-divider mb-3 mt-0" />
                {(showMore === 'generic-bs') ? (
                  <button className="sendBtn mr-3 mr-2-mob" type="submit">
                    {(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch} spin={true} /> loading</>) : 'Send Request'}
                  </button>
                ) : (showMore === 'generic-lt') ? (
                  <>
                    <button className="cancelBtn mr-3" type="button" onClick={() => this.toggleShowMore()}>Not Now</button>
                    <Link to={{pathname: '/request/'+(space.workspace_type_id === '4' ? 'lt-coworking' : 'lt-office')+'/generic', state: {term: 'monthly', source: 'details'}}}>
                      <button className="sendBtn mr-3 mr-2-mob">Send Request</button>
                    </Link>
                  </>
                ) : (showMore === 'generic-ddr') ? (
                    <>
                      <button className="cancelBtn mr-3" type="button" onClick={() => this.toggleShowMore()}>Not Now
                      </button>
                      <Link to={{
                        pathname: RequestForProposal.getRoute('generic', space.workspace_type_id),
                        state: {term: 'catered', source: 'details'}
                      }}>
                        <button className="sendBtn mr-3 mr-2-mob">Send Request</button>
                      </Link>
                    </>
                ) : (
                  <button className="custom-btn save" type="button" onClick={() => this.toggleShowMore()}>Close</button>
                )}
              </div>
            </form>
          </div>
        </div>

        {(vrView && (
          <div className="fullgallery bg-black">

            <Pannellum
              width="100%"
              height="100%"
              image={"https://fluidmeet.s3.amazonaws.com/venue_logos/360/"+space.image_360+"/preview.jpg"} // image="https://pannellum.org/images/alma.jpg"
              pitch={0}
              yaw={0}
              hfov={50}
              autoLoad
              showZoomCtrl={true} />

            <button className="btn-close-page" type="button" onTouchStart={() => this.toggle360View(false, null)} onClick={() => this.toggle360View(false, null)}>
              <FontAwesomeIcon icon={faTimes}/>
            </button>
          </div>
        ))}

        {(similarSpaces.length > 0 && (
          <>
          <div id="bottom-cont" className="container-fluid bg-whiteblue">
            <div className="container-lg pt-4 pb-4">
              <h1 className="more-title">Other Spaces to Consider</h1>
              <section className="more-spaces">
                <div className="row">
                  {similarSpaces.map((space, i) => <Space id={i} space={space} term={term} service={service} food={food}
                                                          currency={currency} allowLink={true}/>)}
                </div>
              </section>
            </div>
          </div>
          <div className="long-divider"/>
          </>
        ))}

        <BottomBar id={match.params.id} space={space} term={term} service={service} food={food} currency={currency} handleTermChange={this.handleTermChange} toggleShowMore={this.toggleShowMore} />

        {(reqAlert && (
            <div id="alerts">
              <div className={"alert alert-" + reqAlert?.type + " alert-dismissible floating-alert fade show"}>
                <strong>{reqAlert?.title}</strong> {reqAlert?.message}
                <button type="button" className="btn-close" onClick={() => this.setState({reqAlert: null})}>
                  <FontAwesomeIcon icon={faTimes}/>
                </button>
              </div>
            </div>
        ))}

        <Footer smallNav={true} bottomPadding={true} />
      </>
    );
  }
}

export default connect((state, props) => ({
  defCurrency: state.settings.defCurrency,
}), null)(withRouter(Workspace));

