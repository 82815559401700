import React, {createRef, RefObject} from "react";
import AlertDialog from "src/components/organisms/alert-dialog/AlertDialog";


const AlertDialogContext = React.createContext<AlertDialogContextType>({});
export type AlertDialogContextType = {
	alertDialog?: RefObject<AlertDialog>,
}

const AlertDialogProvider = ({children}) => {
	const alertDialog = createRef<AlertDialog>();

	return (

		<AlertDialogContext.Provider value={{alertDialog}}>
			{children}
			<AlertDialog ref={alertDialog}/>
		</AlertDialogContext.Provider>
	);

}

function useAlertDialog() {
	const context = React.useContext(AlertDialogContext)
	if (!context) {
		throw new Error(
			`AlertDialog cannot be used outside the AlertDialogContext`,
		)
	}
	return context
}

const withAlertDialog = WrappedComponent => props => { // curry
	const context = useAlertDialog();
	return (
		<WrappedComponent
			{...props}
			{...context}
		/>
	);
};
export default AlertDialogProvider;
export {useAlertDialog, AlertDialogProvider, withAlertDialog}
export const AlertDialogConsumer = AlertDialogContext.Consumer
