import {Elements, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import React, {FormEvent, useEffect, useState} from "react";
import {loadStripe, PaymentIntent} from "@stripe/stripe-js";
import {StripeAPI} from "src/APIs/stripe-api";
import {FMButton} from "src/components/host/forms/fields";
import 'src/styles/payment-element.scss'
import {blue} from "@material-ui/core/colors";
import PhoneInput from "react-phone-input-2";
import {devLog, formValidation, getApiLinkV2, getAxios, getCountryCode, getStripePublishableKey, getToken, getUser} from "src/Helpers";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "./snackbar";


const stripePromise = loadStripe(getStripePublishableKey());
const appearance = {
	theme: 'flat',
	variables: {
		colorPrimary: blue[500],
		fontSizeBase: 18,
		colorText: '#30313d',
		colorDanger: '#df1b41',
		fontFamily: 'Ideal Sans, system-ui, sans-serif',
		spacingUnit: '.35rem',
		borderRadius: '1rem',
		// See all possible variables below
	}
};


export function FMPayment({paymentIntent: _paymentIntent, redirectUrl, allowBillingDetails = false}) {
	const [paymentIntent, setPaymentIntent] = useState<PaymentIntent>(_paymentIntent);
	const history = useHistory()
	useEffect(() => {
		if (!_paymentIntent)
			history.push('/404')
	}, [])

	useEffect(() => {
		console.log({_paymentIntent})
	}, [_paymentIntent])


	if (paymentIntent && paymentIntent?.client_secret)
		return (
			<Elements stripe={stripePromise} options={{clientSecret: paymentIntent.client_secret, appearance}}>
				<CheckoutForm redirectUrl={redirectUrl} allowBillingDetails={allowBillingDetails}/>
			</Elements>
		)
	else
		return (<>
			{/*<Elements stripe={stripePromise}>*/}
			{/*	<CheckoutForm/>*/}
			{/*</Elements>*/}
		</>)
}

export function CheckoutForm({redirectUrl, allowBillingDetails = false}) {
	const stripe = useStripe();
	const elements = useElements();
	const [error, setError] = useState<string>();
	const [errors, setErrors] = useState<any>({});
	const [ready, setReady] = useState(false);
	const [reqLoading, setReqLoading] = useState(false);
	let user = getUser();
	const {showSuccessMessage,showErrorMessage,showWarningMessage} = useSnackbar()


	const handleSubmit = async (event: FormEvent) => {
		if (reqLoading) return;
		event.preventDefault();
		console.log({event,redirectUrl})
		let orderId = redirectUrl.split('/', ).pop();
		setReqLoading(true)
		if (allowBillingDetails) {
			let data = new FormData(event.target as HTMLFormElement)
			let request: any = {}
			request.fName = data.get("fname");
			request.lName = data.get("lname");
			request.email = data.get("email");
			request.phone = data.get("phone");
			
			errors.fName = formValidation('fname', request.fName);
			errors.lName = formValidation('lname', request.lName);
			errors.email = formValidation('email', request.email);
			errors.phone = formValidation('phone', request.phone);
			// errors.country = formValidation('country', selectedCountry);
			// errors.city = formValidation('city', selectedCity);
			
			Object.keys(errors).forEach(key => {
				if (!errors[key]) delete errors[key];
			});
			showWarningMessage("Please Re-Check the inputted data")
			setErrors(errors)
			console.log(orderId, request.phone);
			let token = getToken();
			if(token) {
				const url = getApiLinkV2('workspace/add-booking-phone');

				getAxios().post(url, {
					"order_id": orderId,
					"phone": request.phone
				},
				{
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + token,
					},
				}).then(response => {
					devLog(response);
				})
			}
			if (Object.keys(errors).length > 0) {
				setReqLoading(false)
				return;
			}
		}
		if (!stripe || !elements) {
			showErrorMessage("Can't Process the payment","Error")
			return;
		}

		const result = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: redirectUrl,
			},
		});


		if (result.error) {
			// Show error to your customer (e.g., payment details incomplete)
			setError(result.error.message);
			showErrorMessage(result.error.message ?? "Can't Process the payment card","Payment Error")
		}
		// else {
			// Your customer will be redirected to your `return_url`. For some payment
			// methods like iDEAL, your customer will be redirected to an intermediate
			// site first to authorize the payment, then redirected to the `return_url`.
		// }
	};


	return (
		<div
			className={'d-flex flex-row justify-content-start col-12 flex-wrap  payment-form form-general'}
			style={{visibility: ready ? "visible" : "hidden"}}
		>

			<PaymentElement className={'col-12 px-0'} onReady={() => setReady(true)}
			                options={{fields: {billingDetails: 'auto'}}}/>
			{error && <div className={'form-error'}>{error}</div>}
			<form onSubmit={handleSubmit} className={'col-12 px-0 mt-3'} autoComplete="on">
				{
					allowBillingDetails && (
						<div className="col-12 pr-0 pl-0 pt-4 top-b bottom-b pb-5">
							<h3>Billing address</h3>
							<div className="row pt-3">
								<div className="col-6 pr-2 py-2">
									{/*<label className="w100f">First name</label>*/}
									<input type="name" id="fname" name="fname"
									       className="form-control"
									       placeholder="First name"
									       defaultValue={(user) ? user.first_name : ''}
									       required={true}/>
									{(errors.fName) ? (<div
										className="form-error">{errors.fName}</div>) : (<></>)}
								</div>
								<div className="col-6 pl-2 py-2">
									{/*<label className="w100f">Last name</label>*/}
									<input type="name" id="lname" name="lname"
									       className="form-control"
									       placeholder="Last name"
									       defaultValue={(user) ? user.last_name : ''}
									       required={true}/>
									{(errors.lName) ? (<div
										className="form-error">{errors.lName}</div>) : (<></>)}
								</div>
								<div className="col-12 py-2">
									{/*<label className="w100f">Phone number</label>*/}
									<div className="w-100">
										<PhoneInput
											preferredCountries={['ae']}
											country={(user && user.country) ? getCountryCode(user.country)[2] : 'ae'}
											enableAreaCodes={true}
											buttonStyle={{
												borderRadius: '16px 0 0 16px',
												backgroundColor: '#f9f9f9',
												zIndex: 10,
												border: '1px solid #cfd8e7',
											}}
											dropdownStyle={{
												borderRadius: '16px',
												zIndex: 10,
												border: '1px solid #cfd8e7',
											}}
											searchClass={"form-control-parent"}
											inputProps={{
												id: 'phone',
												name: 'phone',
												required: true
											}}
											inputStyle={{
												width: '100%',
												borderRadius: '16px',
											}}
											inputClass={'bg-grey-focus-white'}
											autoFormat={true}
											value={(user) ? user.phone : ''}
											enableSearch={true}
											// onChange={(phone, data) => this.setState({countryCode: data.dialCode})}
										/>
									</div>
									{(errors.phone) ? (<div
										className="form-error">{errors.phone}</div>) : (<></>)}
								</div>
								<div className="col-12 py-2">
									{/*<label className="w100f">Email address</label>*/}
									<input type="email" id="email" name="email"
									       className="form-control"
									       placeholder="Email address"
									       defaultValue={(user) ? user.email : ''}
									       required={true}/>
									{(errors.email) ? (<div
										className="form-error">{errors.email}</div>) : (<></>)}
								</div>
								{/*<div className="col-6 pr-2">*/}
								{/*	/!*<label className="w100f">Country</label>*!/*/}
								{/*	<div className="selector">*/}
								{/*		<CountryDropdown classes="select"*/}
								{/*		                 defaultOptionLabel='Country'*/}
								{/*		                 value={selectedCountry}*/}
								{/*		                 onChange={(val) => this.setState({*/}
								{/*			                 selectedCountry: val,*/}
								{/*			                 selectedCity: ''*/}
								{/*		                 })}/>*/}
								{/*	</div>*/}
								{/*	{(errors.country) ? (<div*/}
								{/*		className="form-error">{errors.country}</div>) : (<></>)}*/}
								{/*</div>*/}
								{/*<div className="col-6 pl-2">*/}
								{/*	/!*<label className="w100f">City</label>*!/*/}
								{/*	<div className="selector">*/}
								{/*		<RegionDropdown classes="select"*/}
								{/*		                disableWhenEmpty={true}*/}
								{/*		                defaultOptionLabel='City'*/}
								{/*		                country={selectedCountry}*/}
								{/*		                value={selectedCity}*/}
								{/*		                onChange={(val) => this.setState({selectedCity: val})}/>*/}
								{/*	</div>*/}
								{/*	{(errors.city) ? (<div*/}
								{/*		className="form-error">{errors.city}</div>) : (<></>)}*/}
								{/*</div>*/}
							</div>
						</div>
					)
				}
				<div className={'d-flex flex-row justify-content-start pb-5 col-12 px-0 '}>
					<FMButton
						title={"Submit"}
						extraClassNames={'col-12 col-md-4 px-0'}
						isLoading={reqLoading}
					/>

				</div>

			</form>

		</div>
	)

}
