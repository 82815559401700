import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {CircularProgress} from "@material-ui/core";
import {blue, red} from "@material-ui/core/colors";
import clsx from 'clsx';
import {Link, Redirect, useHistory} from "react-router-dom";
import {FMButton} from "src/components/host/forms/fields";
import {HostAPI, Message} from "src/APIs/HostAPI";
import {HostDashboard} from "src/components/host/host-dashboard/host-dashboard";
import {devLog, doubleToPercentage} from "src/Helpers";
import {useSnackbar} from "src/components/organisms/snackbar";

export type ContractDetailsProps = {}
ContractDetails.key = 'contract-details'
ContractDetails.route = `/${ContractDetails.key}`

ContractDetails.view = (history) => {
	history.push(`/${ContractDetails.key}`)
}


export type ContractDetailsRouteParams = {}
export default function ContractDetails({}: ContractDetailsProps) {
	// const {subscription_id} = useParams<ContractDetailsRouteParams>();
	const [subscription, setSubscription] = useState<any>();
	const [message, setMessage] = useState<Message>();
	const [data, setData] = useState<any>();
	const [loading, setLoading] = useState<boolean>(true);
	const [reqLoading, setReqLoading] = useState<boolean>(false);
	let history = useHistory();
	let contract = useRef<HTMLDivElement>(null);
	const {snackbar, showSuccessMessage, showErrorMessage} = useSnackbar();
	useEffect(() => {

		(
			async () => {

				let message = await HostAPI.getSubscriptionContract()
				// if (message?.type === 'danger') history.push(HostDashboard.route)

				setData(message?.attachment?.data)
				setMessage(message)
				devLog("message", {res: message?.attachment})
				setLoading(false)
			}
		)()

	}, [])

	useLayoutEffect(() => {
		if (contract.current && data)
			contract.current.innerHTML = data.template?.description
	}, [data, loading])

	const handleContractAccept = () => {

		if (!reqLoading) {
			setReqLoading(true)
			HostAPI.acceptSubscriptionContract(data.subscription.subscription_id).then(
				(message) => {
					if (message?.type === 'success') {
						showSuccessMessage(message?.message, message?.title)
						setTimeout(() => {
							history.push(HostDashboard.route)
						}, 5000)

					} else if (message?.type === 'danger') {
						showErrorMessage(message?.message, message?.title)
					} else showErrorMessage("Error")
				}
			)
		}

	}
	return (
		<div className="container pr-0 pl-0" style={{marginTop: 120}}>
			<div className="page pb-5">

				{
					(loading) ? (
						<div className={clsx('d-flex col-12 justify-content-center')}>
							<CircularProgress className={clsx('mx-auto my-5')}/>
						</div>
					) : (<>

							{(message?.type === "danger") ? (

									<div className={" rounded d-flex flex-column align-items-center p-4"}
									     style={{backgroundColor: red[200]}}>
										{/*<div className={'h4'}>*/}
										{/*	{message?.title}*/}
										{/*</div>*/}
										<div className={'h5'}>
											{message?.message}
										</div>
										<div className={'h6'}>
											Please <Link style={{color: 'black'}} to='/contact-us'><u>contact
											us</u></Link> for support.
										</div>

									</div>

							) : (Boolean(data && message && data?.subscription && message?.type === "success")) ?

								(<div>

									<div className={`row page-lg-title mt-40 mb-5 `}>

										<div className={"col-11"} style={{color: blue[500], textAlign: 'center'}}>
											{(data?.template?.title) || "Terms & Conditions"}
										</div>
									</div>

									<div
										className={'bg-white p-5 '}
										style={{
											borderRadius: '1rem',
											borderColor: blue[100],
											borderWidth: '1px',
											borderStyle: 'solid',
											position: 'relative', overflow: 'hidden'
										}}>
										{
											data.subscription?.contract_status === 'accepted' &&
											<div className="corner-ribbon top-right green shadow">Accepted</div>
										}

										<div ref={contract}
										     id={'contract'}
										/>
										<h4 className={'pb-3'}><u>Your Subscription</u></h4>
										<table className="mw-100" cellPadding={' 5px'}>
											<tbody>
											<tr>
												<th>Subscription:</th>
												<td>
												{data.subscription?.plan_name}
											</td>
										</tr>
										<tr>
											<th>Company type:</th>
											<td>{data.subscription?.venue_type}</td>
										</tr>
										<tr>
											<th>Total No. of listings:</th>
											<td>{data.subscription?.listings}</td>
										</tr>
										<tr>
											<th>No. of featured listings (out of total):</th>
											<td>{data.subscription?.featured}</td>
										</tr>
										<tr>
											<th>Space Only Rental referral (%):</th>
											<td>{data.subscription?.st_price}%</td>
										</tr>
										<tr>
											<th>Long-term (1-12 months) Rental referral (%):</th>
											<td>{data.subscription?.lt_price}%</td>
										</tr>
										<tr>
											<th>Space + Food &amp; Beverage Rental referral (%):
											</th>
											<td>{data.subscription?.ddr_price}%</td>
										</tr>
											<tr>
												<th>Accommodation referral (%):</th>
												<td>{data.subscription?.accom_price}%</td>
											</tr>


											<tr>
												<th>Registration fee</th>
												<td>{(data.subscription?.reg_fee) ? (data.subscription?.currency ?? data.subscription?.plan_currency + ' ' + parseFloat(data.subscription.reg_fee)) : '-'}</td>
											</tr>


											<tr>
												<th>Plan Price</th>
												<td>{data.subscription?.plan_currency} {Math.round(parseFloat(data.subscription?.plan_price))}</td>
											</tr>
											{
												data.subscription?.discount_percent && (
													<tr>
														<th>Discount({doubleToPercentage(data.subscription?.discount_percent / 100)})</th>
														<td>{data.subscription?.currency} {Math.round(parseFloat(data.subscription?.discount))}</td>
														{/*<td>{data.subscription?.currency} {Math.round(parseFloat(data.subscription?.plan_price) * parseFloat(data.subscription?.discount_percent )/ 100)}</td>*/}
													</tr>
												)
											}


											<tr>
												<th>Price {(data.subscription?.discount_percent) && "After Discount"}</th>
												<td>{data.subscription?.currency} {Math.round(parseFloat(data.subscription?.price))}</td>
												{/*<td>{data.subscription?.currency} {Math.round(parseFloat(data.subscription?.price_due))}</td>*/}
											</tr>
											{/*<tr>*/}
											{/*	<th>Price</th>*/}
											{/*	<td>{data.currency} {Math.round(parseFloat(data.subscription?.price_due) * parseFloat(data.exchange_rate))}</td>*/}
											{/*</tr>*/}
											<tr>
												<th>VAT(5%)</th>
												<td>{data.subscription?.currency} {Math.round(parseFloat(data.subscription?.price_due) * .05)}</td>
											</tr>
											<tr>
												<th>Total Price (Inc VAT)</th>
												<td>{data.subscription?.currency} {Math.round(parseFloat(data.subscription?.price_due) * 1.05)}</td>
											</tr>

											</tbody>
									</table>
										{(data.subscription?.contract_status !== 'accepted' || data.subscription?.isrenewed == 1) &&
										<div className={'d-flex justify-content-center pt-4'}>
											<FMButton extraClassNames={'col-md-4'} isLoading={reqLoading}
											          onClick={handleContractAccept} title={'Accept'}/>
										</div>
										}
									</div>

								</div>) : (
									/*{!Boolean(data && message && data?.subscription) && <Redirect to={'/error'}/>}*/
									<Redirect to={'/error'}/>
								)
							}
						</>
					)
				}

			</div>
		</div>

	)
}
// Plan price
// discount
// price after discount
// tax
// total price
