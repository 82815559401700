import Alert, {Color} from "@material-ui/lab/Alert";
import {Snackbar} from "@material-ui/core";
import React from "react";
import {Message} from "src/APIs/HostAPI";

export type AlertSnackbarCallback = (snackbar:AlertSnackbar)=>void;

export type AlertSnackbarProps = {
	onClose: AlertSnackbarCallback,
	title:string,  message: string,
	severity:Color,
	timeout:number
}
export type AlertSnackbarData = {
	onClose?: AlertSnackbarCallback,
	title?:string,  message?: string,
	severity?:Color,
	timeout?:number
}

export type AlertSnackbarState =
	{
		opened:boolean
	} & AlertSnackbarProps

export default class AlertSnackbar extends React.Component<AlertSnackbarProps,AlertSnackbarState> {
	static defaultProps = {
		title: "",message:'',
		severity:'success' as const,
		timeout:3000,
		onClose:(snackbar:AlertSnackbar)=>{snackbar.close()},

	}

	constructor(props: Readonly<AlertSnackbarProps> | AlertSnackbarProps) {
		super(props);
		this.state={
			opened:false,
			...props
		}
	}
	open = ()=>{
		this.setState({opened:true})
		setTimeout(()=>{
			this.close()
		},this.state.timeout)
	}
	close = ()=>{
		this.setState({opened:false})
	}
	setOnCloseCallback = (callback:AlertSnackbarCallback)=>{
		this.setState({onClose:callback})
	}
	setSeverity = (severity:Color)=>{
		this.setState({severity})
	}
	setMessage = (message:string)=>{
		this.setState({message})
	}
	setTitle = (title:string)=>{
		this.setState({title})
	}
	setAlertData = (data:AlertSnackbarData)=>{
		this.setState({opened:false,...AlertSnackbar.defaultProps,...this.props,...data})
	}
	setDataFromMessageObject(message:Message){
		this.setState({
			severity:(!message.type || message.type === 'danger')? 'error' : message.type,
			title: message.title ?? '',
			message:message.message ?? '',
		})
	}

	render() {

		let {opened,onClose,severity,timeout, message,title} = this.state;

		return (
			<Snackbar
				anchorOrigin={{ vertical:"top", horizontal:"center" }}
				open={opened}
				autoHideDuration={timeout}

			>
				<Alert severity={severity} onClose={()=>onClose(this)}><span style={{fontWeight:'bold'}}>{Boolean(title) && `${title}:`}</span> {message} </Alert>
			</Snackbar>
	);
	}
}


