import React, {useEffect, useRef, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core/styles";
import {blue, blueGrey} from "@material-ui/core/colors";
import {RouteComponentProps, useHistory, useParams} from "react-router-dom";
import {HostDashboardRoutePrams} from "src/components/host/host-dashboard/host-dashboard";
import {SubscriptionData} from "src/components/host/host-dashboard/manage-listings/SubscriptionData";
import {AlertComponent} from "src/components/host/host-dashboard/Alert";
import {HostAPI} from "src/APIs/HostAPI";
import clsx from 'clsx';
import {refreshVenues} from "src/redux/VenuesSlice";
import {VenueItem} from "src/components/host/host-dashboard/manage-listings/VenueItem";
import {EditVenue} from './add-edit-venues/edit-venue';
import {EditWorkspace} from "src/components/host/host-dashboard/manage-listings/add-edit-workspaces/edit-workspace";
import {Venue} from "src/APIs/venue/venue";
import AlertSnackbar from "src/components/organisms/snackbar/AlertSnackBar";
import {useDispatch, useSelector} from "react-redux";
import {VenueControl} from "src/APIs/venue/venue-control";
import ContractDetails from "src/components/host/host-dashboard/subscription/contract-details";
import {devLog} from "src/Helpers";
import moment from "moment";

const useStyles = makeStyles(
    (theme: Theme) => (
        {
            root: {
                maxWidth: '1280px',
            },
            media: {
                height: 0,
                paddingTop: '56.25%', // 16:9
            },
            expand: {
                transform: 'rotate(0deg)',
                marginLeft: 'auto',
                transition: theme.transitions.create('transform', {
                    duration: theme.transitions.duration.shortest,
                }),
            },
            expandOpen: {
                transform: 'rotate(180deg)',
            },
            avatar: {
                backgroundColor: blueGrey[300],
            },
            count:{
                borderRadius:'40%',
                padding:'.2rem 0.5rem',
                fontSize:'.8rem',
                backgroundColor: blue[300],
                verticalAlign:'middle',
            },
            title: {
                verticalAlign:'middle',
            },
            iconButton:{
                marginLeft:'auto',
                alignSelf:'middle',

            },
            sectionTitle: {
                fontSize: 22,
                fontWeight: 600,
            },
            btn: {
                flex: 0,
                flexGrow: 0,
                textAlign: 'right' as const,
                color: 'black',
                fontSize: 17,
                fontWeight: 400,
                textDecorationLine: 'underline',
            },
        }
    )
)

export type ManageListingsProps = {} & RouteComponentProps<HostDashboardRoutePrams>

ManageListings.key = 'manage-listings'
ManageListings.route = `/${ManageListings.key}`

export function ManageListings({}) {

    const classes = useStyles();
    const history = useHistory()
    const params = useParams();
    const venues = useSelector((state: any) => state.venues.data)
    const dispatch = useDispatch()
    // const Store = useStore()
    const [reqAlert, setReqAlert] = useState<any>(null);
    const [subscription, setSubscription] = useState<any>();
    const [hostStatus, setHostStatus] = useState<any>();
    const [venueAdd, setVenueAdd] = useState<any>();
    const snackbar = useRef<AlertSnackbar>(null);

    useEffect(() => {
        HostAPI.fetchSubscription().then((response) => {
            devLog("Manage Listings :: fetchSubscription", {response})

            if (
                (!response?.subscription?.details)
                || (
                    response?.subscription?.details &&
                    (
                        response?.subscription?.details?.contract_status !== "accepted" ||
                        (
                            response?.subscription?.details?.end_date &&
                            moment(response?.subscription?.details?.end_date).isValid() &&
                            moment(response?.subscription?.details?.end_date).toDate() <= new Date()
                        )
                    )
                )
            ) {
                ContractDetails.view(history)
            } else {
                setSubscription(response?.subscription)
                setHostStatus(response?.hostStatus)
                setVenueAdd(response?.venueAdd)
            }

        })

        dispatch(refreshVenues())
    }, [])

    // useEffect(() => {
    //     devLog("Manage-Listings::", venues)
    //
    //
    // })

    const isExpired = hostStatus !== 'active';

    const handleAddVenue = () => {
        history.push(EditVenue.route.replace(':id?', ''))
    }

    const handleAddSpace = (venue: Venue) => {

        let spacesCount = 0;
        venues.forEach(venue => spacesCount += (venue.workspaces ? venue.workspaces.length : 0));
        if (subscription.details.listings <= spacesCount) {
            snackbar.current?.setAlertData(
                {
                    severity: 'error' as const,
                    title: 'Upgrade your subscription',
                    message: 'Maximum number of spaces added for your subscription.',
                    timeout: 7000,
                }
            )
            snackbar.current?.open()
            return;
        }

        history.push(EditWorkspace.route.replace(':venueID/:id?', venue.venue_details_id))
    }


    return (
        <div className="Manage-Listings">
            <div className={clsx("container-fluid bg-white mt-40 ", classes.root)}>
                <div className="page px-md-3">

                    <div className={`row page-lg-title mt-4 mb-5 ${classes.title}`}>
                        <div className={"col-11"}>
                            Manage Listings
                        </div>
                    </div>

                    <SubscriptionData/>

                    <div className={clsx('mt-20')} style={{marginBottom: 32}}>

                        <div className={clsx('d-flex flex-row flex-nowrap justify-content-center align-items-center mt-12')}>
                            <div style={{flexGrow: 1, flexShrink: 1}}>
                                <div className={clsx(classes.sectionTitle)}>
                                    My Venues
                                </div>
                            </div>
                            {venueAdd === true && (
                                <div style={{flexGrow: 0, flexShrink: 0, marginTop: 5}} onClickCapture={handleAddVenue}>
                                        <span className={clsx(classes.btn,'cursor-pointer')} >Add Venue</span>
                                </div>
                            )}
                        </div>

                        <div>
                            {venues && venues.map((item: VenueControl) => {
                                return <VenueItem key={item.venue_details.venue_details_id} venueControl={item}
                                                  onAddSpace={handleAddSpace}/>
                            })}
                        </div>

                    </div>

                </div>
                <AlertComponent reqAlert={reqAlert} onDismissClicked={() => setReqAlert(null)}/>
                <AlertSnackbar ref={snackbar}/>
            </div>
        </div>
    );
}


//@ts-ignore
export const ManageListingsEnhanced = (ManageListings);
