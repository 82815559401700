
class User {

  constructor(data) {
    this.id = data.user_id;
    this.email = data.email;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.country = data.country;
    this.city = data.city;
    this.phone = data.phone;
    this.company = data.company;
    this.position = data.position;
    this.image = data.image;
    this.type = data.user_type;
    this.is_broker = data.is_broker;
  }
}

export default User;
