import React, {Component} from 'react';
import {Link, Redirect} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import NavBar from "../atoms/NavBar";
import {devLog, getApiLinkV2, getProposalBooking, getSpaceBooking, getToken} from "src/Helpers";
import axios from "axios";
import {getAxios} from "src/Helpers";
import Bugsnag from "@bugsnag/js";
import ReactGA from "react-ga-neo";

class WorkspaceBookingResponse extends Component {

  constructor(props) {
    super(props);

    this.state = {
      page: (props.page) ? props.page : 'verify',
      redirect: null
    }
  }

  componentDidMount() {

    const { location } = this.props;
    const { page } = this.state;

    const space = getSpaceBooking();
    const proposal = getProposalBooking();

    if ((!space || !space.order_id) && (!proposal || !proposal.order_id)) {
      devLog("redirect-error")
      this.setState({redirect: '/error'});
      return;
    }

    const order_id = (space) ? space.order_id : proposal.order_id;

    // const spaceType = location.pathname.indexOf('catered-meeting') !== -1 ? 'catered-meeting' : 'catered-banquet';
    // const baseUrl = (generic) ? '/request/'+spaceType+'/generic' : '/request/'+spaceType+'/' + match.params.id;
    // '/booking-verification/:booking_type/:workspace_type/:workspace_id/:order_id/:event?quote_id='
    const successUrl = (space) ? '/booking/st/'+space.workspace_id+'/success' : '/booking/catered-banquet/'+proposal.quote_id+'/success';
    const errorUrl = (space) ? '/booking/st/'+space.workspace_id+'/error' : '/booking/catered-banquet/'+proposal.quote_id+'/error';

    if (page !== 'verify') return;

    const token = getToken();
    if (!token) return;

    const url = getApiLinkV2('workspace/booking/'+order_id);

      getAxios().get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token,
      },
    }).then(response => {
        devLog(response);
        if(window.hasOwnProperty('gtag')) {
          if(window.hasOwnProperty('gtag')) {
            window?.gtag('event', 'conversion', {'send_to': 'AW-935885959/IQ6LCI7Z0fUCEIf5ob4D'});
          }
        }
        if(window.hasOwnProperty('gtag')) {
          if(window.hasOwnProperty('gtag')) {
            window?.gtag('event', 'conversion', {'send_to': 'AW-935885959/KtWNCLyQz_UCEIf5ob4D'});
          }
        }
        

      if (space) {
        const spaceType = location.pathname.indexOf('st-office') !== -1 ? 'Office Space' : location.pathname.indexOf('st-banquet') !== -1 ? 'Event' +
          ' Space' : location.pathname.indexOf('st-coworking') !== -1 ? 'Co-Working Space' : 'Meeting Room';
        ReactGA.event({
          category: 'ST',
          action: 'ST_Success',
          label: spaceType + ' ST Booking Success for space ' + space.workspace_id
        });
  
      } else {
        ReactGA.event({
          category: 'DDR',
          action: 'DDR_Booking_Success_Unique',
          label: 'Catered Booking Success for quote ' + proposal.quote_id
        });
  
      }

      this.setState({redirect: successUrl});
    }).catch(error => {
        Bugsnag.notify(error);
        devLog(error);
        this.setState({redirect: errorUrl});
      });

    //ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {

    const { match, generic } = this.props;
    const { page, redirect } = this.state;

    if (redirect) return <Redirect to={redirect} />

    return (
      <>

        <NavBar backLink={(generic) ? '/reservation/'+match.params.id : '/space/'+match.params.id+'?t=hourly'} smallLogo={true} smallNav={true} hideSearch={true} hideHostBtn={true} pageTitle="Request to book space"  />

        <div className="container-fluid bg-white h-z">
          <div className="container-lg h-z">
            <div className="booking-page h-z">

              <h1 className="full-title h-z">
                <Link to={(generic) ? '/reservation/'+match.params.id : '/space/'+match.params.id+'?t=hourly'}>
                  <FontAwesomeIcon icon={faArrowLeft} onClick={this.handleExitBooking}
                                 style={{color: '#000000', fontSize: 20, cursor: 'pointer', marginRight: 24, marginBottom: 4}} />
                </Link>
                Request to book space
              </h1>

              <div className="space-container">
                <div className="space-content h-z">
                  <div className="form-general p-0">
                    <div className="mt-4 form-flex">
                      <div className="form-content">
                        {(page === 'verify') ? (
                          <>
                            <div className="space-about pt-4 pb-2 border-0">
                              <h3 className="pb-1">Verifying</h3>
                              <h6>We are verifying your payment. It may take a while.</h6>
                            </div>
                            <button className="btn btn-lg mb-5">
                              <FontAwesomeIcon icon={faCircleNotch} spin={true} /> loading
                            </button>
                          </>
                        ) : (page === 'success') ? (
                          <>
                            <div className="space-about pt-4 border-0">
                              <h3 className="pb-1">Payment received</h3>
                              <h6>Thank you. We have received your payment successfully.</h6>
                            </div>
                            {(match.params.type === 'st') ? (
                              <Link to={(generic) ? null : '/space/'+match.params.id+'?t=hourly'}>
                                <button className="btn btn-lg btn-primary submit-btn mb-5">Back to space</button>
                              </Link>
                            ) : (match.params.type === 'catered') ? (
                              <Link to={(generic) ? null : '/reservation/'+match.params.id}>
                                <button className="btn btn-lg btn-primary submit-btn mb-5">Back to reservation</button>
                              </Link>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (page === 'error') ? (
                          <>
                            <div className="space-about pt-4 border-0">
                              <h3 className="pb-1">Error</h3>
                              <h6>Error while processing your payment.</h6>
                            </div>
                            {(match.params.type === 'st') ? (
                              <Link to={(generic) ? null : '/space/'+match.params.id+'?t=hourly'}>
                                <button className="btn btn-lg btn-primary submit-btn mb-5">Back to space</button>
                              </Link>
                            ) : (match.params.type === 'catered') ? (
                              <Link to={(generic) ? null : '/reservation/'+match.params.id}>
                                <button className="btn btn-lg btn-primary submit-btn mb-5">Back to reservation</button>
                              </Link>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </>
    );
  }
}

export default WorkspaceBookingResponse;
