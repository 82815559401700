import React, {useEffect, useState} from 'react';
import {Theme} from "@material-ui/core/styles";
import {blue, blueGrey, green, red} from "@material-ui/core/colors";
import {makeStyles} from "@material-ui/styles";
import {FetchSubscriptionResponse, HostAPI} from "src/APIs/HostAPI";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from 'moment';
import clsx from 'clsx';

const styles = {
	rootView: {
		flex: 1,
	},
	content: {
		flex: 1,
		paddingHorizontal: 6,
	},
	hostCont: {
		borderRadius: 6,
		backgroundColor: '#eef2f5',
		borderColor: '#d4e1ee',
		borderWidth: 0.5,
		marginTop: 20,
		marginBottom: 12,
		marginHorizontal: 10,
		paddingHorizontal: 16,
		paddingVertical: 10,
		alignItems: 'center',
	},
	hostDesc: {
		flexGrow: 1,
		flexShrink: 1,
	},
	hostImg: {
		borderRadius: 40,
		backgroundColor: '#FFFFFF',
		width: 54,
		height: 54,
		marginRight: 16,
		justifyContent: 'center',
		alignItems: 'center',
	},
	textTitle: {
		fontSize: 19,
		marginTop: 3,
		color: blueGrey[700],
		fontWeight: 600,
	},
	textSubtitle: {
		fontSize: 16,
		fontWeight: 500,
		color: blueGrey[500],
		marginBottom: 6,
	},
	textTinySubtitle: {
		fontSize: 15,
		fontWeight: 500,
		color: blueGrey[500],
		marginTop: 8,
		marginBottom: 4,
	},
	textValue: {
		fontSize: 15,
		fontWeight: 500,
		color: blueGrey[500],
		textAlign: 'right' as const,
		marginTop: 8,
		marginBottom: 4,
	},
	divider: {
		height: .5,
		marginTop: 10,
		marginBottom: 20,
		width:'100%',
		backgroundColor: blueGrey[200],
	},
	text: {
		fontSize: 16,
		lineHeight: 18,
	},
	statusView: {
		flexGrow: 0,
		flexShrink: 0,
		paddingVertical: 3,
		paddingHorizontal: 6,
		borderRadius: 4,
	},
	statusText: {
		fontSize: 16,
		fontWeight: 500,
		color: 'white',
	},
	statusGreen: {
		backgroundColor: green[500],
		// backgroundColor: '#097e09',
	},
	statusRed: {
		backgroundColor: red[500],
		// backgroundColor: '#bf0f0f',
	},
	header: {
		flexDirection: 'row',
		backgroundColor: '#FFFFFF',
	},
	headerCont: {
		flex: 1,
		marginBottom: 12,
		flexDirection: 'column',
		alignContent: 'flex-start',
	},
	largeTitle: {
		fontWeight: 700,
		marginTop: 36,
		lineHeight: 34,
		fontSize: 25,
	},
	largeSubtitle: {
		color: "black",
		fontSize: 20,
		marginTop: 14,
	},
	headerText: {
		textAlign: 'left',
		color: 'black',
		fontSize: 19,
		lineHeight: 25,
	},
	submitBtn: {
		height: 54,
		backgroundColor: blue[400],
		marginBottom: 28,
		borderRadius: 10,
		justifyContent: 'center',
	},
	submitBtnView: {
		flex: 0,
		marginRight: 'auto',
		marginLeft: 'auto',
		flexDirection: 'row',
	},
	submitBtnText: {
		flex: 0,
		textAlign: 'center',
		color: 'white',
		fontSize: 17,
		fontWeight: 500,
	},
	success: {
		flex: 1,
		flexDirection: 'row',
		paddingVertical: 10,
		color: '#155724',
		backgroundColor: '#d4edda',
		borderColor: '#c3e6cb',
		borderRadius: 10,
	},
};

export type SubscriptionDataProps = {

}


export function SubscriptionData({}: SubscriptionDataProps) {
	const classes = useStyles();
	const [subscription, setSubscription] = useState<any>();
	const [hostStatus, setHostStatus] = useState<any>();
	const [venueAdd, setVenueAdd] = useState<any>();

	useEffect(()=>{
		HostAPI.fetchSubscription().then((response)=>{
			setSubscription(response?.subscription)
			setHostStatus(response?.hostStatus)
			setVenueAdd(response?.venueAdd)
		})
	},[])
	const isExpired = hostStatus !== 'active';

	if (!subscription || subscription.length == 0 || !subscription.details)
		return (<div style={{flex: 1, backgroundColor: 'white'}}></div>)
	return (
		<div>
			<Accordion elevation={4} style={styles.hostCont}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon/>}
					aria-controls="subsciption-panel-content"
					id="subsciption-panel-header"
				>
					{/*{JSON.stringify(subscription, null,4)}*/}

					<div style={styles.hostDesc}>

							<div className={clsx('d-flex flex-nowrap flex-row')} style={{marginBottom: 2}}>

								<div style={{flexGrow: 1, flexShrink: 0}}>
									<div style={styles.textTitle}>My Subscription</div>
								</div>

								<div style={{marginTop: 2, marginBottom: 'auto'}}>
									<div style={{...styles.statusView, ...(isExpired ? styles.statusRed : styles.statusGreen)}}>
										<div className={clsx('p-1')} style={styles.statusView}>
											{isExpired ? 'Expired' : 'Active'}
										</div>
									</div>
								</div>

							</div>

						<div>
							<div style={{...styles.textSubtitle, marginTop: 6, marginBottom: 10}}>
								{moment(subscription.details.start_date).format('MMM D,' +
									' YYYY')}
								{subscription.details.end_date != '0000-00-00' && (
									' - '+moment(subscription.details.end_date).format('MMM D,' +
										' YYYY')
								)}
							</div>
							<div style={styles.textSubtitle}>{subscription.details.plan_name} Subscription</div>
						</div>

					</div>
				</AccordionSummary>
				<AccordionDetails>
					<div style={{width:'100%',}}>

					<div style={styles.divider} />

					<div className={clsx('d-flex flex-row flex-nowrap')} >
						<div style={{flexGrow: 0, flexShrink: 1}}>
							<div style={styles.textTinySubtitle}>Total No. of listings:</div>
							<div style={styles.textTinySubtitle}>Featured listings:</div>
							<div style={styles.textTinySubtitle}>Short Term / Space Only:</div>
							<div style={styles.textTinySubtitle}>Long Term:</div>
							<div style={styles.textTinySubtitle}>Catered space inquiry:</div>
						</div>
						<div style={{flexGrow: 0, flexShrink: 0, width: 50}}>
							<div style={styles.textValue}>{subscription.details.listings}</div>
							<div style={styles.textValue}>{subscription.details.featured}</div>
							<div style={styles.textValue}>{subscription.details.st_price}%</div>
							<div style={styles.textValue}>{subscription.details.lt_price}%</div>
							<div style={styles.textValue}>{subscription.details.ddr_price}%</div>
						</div>
					</div>
					</div>
				</AccordionDetails>
			</Accordion>


		</div>
	);

}


const useStyles = makeStyles(
	(theme: Theme)=> (
		{
			root: {
				maxWidth: '80%',
			},
			media: {
				height: 0,
				paddingTop: '56.25%', // 16:9
			},
			expand: {
				transform: 'rotate(0deg)',
				marginLeft: 'auto',
				transition: theme.transitions.create('transform', {
					duration: theme.transitions.duration.shortest,
				}),
			},
			expandOpen: {
				transform: 'rotate(180deg)',
			},
			avatar: {
				backgroundColor: blueGrey[300],
			},
			count:{
				borderRadius:'40%',
				padding:'.2rem 0.5rem',
				fontSize:'.8rem',
				backgroundColor: blue[300],
				verticalAlign:'middle',
			},
			title: {
				verticalAlign:'middle',
			},
			iconButton:{
				marginLeft:'auto',
				alignSelf:'middle',

			}
		}
	)
)
