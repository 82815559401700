import {createAlert, devLog, getApiLinkV2, getToken, getUser, resetUser, setUser} from "src/Helpers";
import axios from "axios";
import {getAxios} from "src/Helpers";
import Bugsnag from "@bugsnag/js";
import ReactGA from "react-ga-neo";
import {HostAPI} from "src/APIs/HostAPI";

export default class AuthAPI {

    static async signInByToken(login_token) {
        const url = getApiLinkV2('auth/signin');
        try {
            let response = await getAxios().post(url, {login_token}, {
                headers: {'Content-Type': 'application/json'},
            })
            devLog("Sign In", {response})
            const user = response.data.userData;
            setUser(user, response.data.token);
            Bugsnag.setUser(user.user_id, user.email, user.first_name + " " + user.last_name);
            ReactGA.set({userId: user.user_id});
            if (user.user_type === 'host') {
                await HostAPI.fetchSubscription()
            }
            return {user: getUser(), token: response.data.token}
        } catch (error: any) {
            if (error.response && error.response?.status === 406) {
                if (error.response?.data?.status === 'wrong_pass') {
                    createAlert(this, 'danger', 'The email address or password you entered is incorrect.', '');
                }
            } else {
                Bugsnag.notify(new Error('Error Catched in axios ' + JSON.stringify(error)));
            }
        }
    }

    static async signIn(email, password) {
        const url = getApiLinkV2('auth/signin');
        try {
            let response = await getAxios().post(url, {email, password}, {
                headers: {'Content-Type': 'application/json'},
            })
            devLog("Sign In", response)
            const user = response.data.userData;
            setUser(user, response.data.token);
            Bugsnag.setUser(user.user_id, user.email, user.first_name + " " + user.last_name);
            ReactGA.set({userId: user.user_id});
            if (user.user_type === 'host') {
                await HostAPI.fetchSubscription()
            }
            return {user: getUser(), token: response.data.token}
        } catch (error: any) {
            if (error.response && error.response?.status === 406) {
                if (error.response?.data?.status === 'wrong_pass') {
                    createAlert(this, 'danger', 'The email address or password you entered is incorrect.', '');
                }
            } else {
                Bugsnag.notify(new Error('Error Catched in axios ' + JSON.stringify(error)));
            }
        }
    }

    static async signOut() {

        const url = getApiLinkV2('auth/signout');
        const token = getToken();
        devLog(url);
        devLog(token);

        try {
            await getAxios().post(url, {}, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                },
            })
            resetUser();
        } catch (error: any) {
            Bugsnag.notify(error);
            createAlert(this, 'danger', (error.response && error.response.data.error) ? 'Error: ' : '', (error.response && error.response.data.error) ? error.response.data.error : error.message);
        }
    }


}
