import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {FetchHostDetailsResponse, HostAPI, HostUpdateRequest} from "src/APIs/HostAPI";
import {Theme, withStyles} from "@material-ui/core/styles";
import {blue, blueGrey} from "@material-ui/core/colors";
import {Styles} from "@material-ui/styles";
import {CircularProgress, IconButton} from "@material-ui/core";
import {HostDashboardRoute} from "src/components/host/host-dashboard/consts";
import {HostDashboardRoutePrams} from "src/components/host/host-dashboard/host-dashboard";
import {
	HostPermissions,
	PermissionChangedCallback
} from "src/components/host/host-dashboard/manage-hosts/add-edit-host/host-permissions";
import clsx from 'clsx';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {createAlertFromMessage, devLog} from "src/Helpers";
import {AlertComponent, AlertRequest} from "src/components/host/host-dashboard/Alert";

export interface EditHostProps  extends RouteComponentProps<HostDashboardRoutePrams> {
	classes?:any;
}

export type EditHostState = {
	fetched: boolean,
	loading: boolean,
	reqAlert?: AlertRequest,
}&FetchHostDetailsResponse

export class EditHost extends React.Component<EditHostProps, EditHostState> {
	static key = 'add-edit-host'
	static route = `${HostDashboardRoute}/${EditHost.key}/:id?`

	constructor(props: EditHostProps) {
		super(props)
		this.state = {
			fetched: false,
			loading: false,
		} as EditHostState
	}

	componentDidMount() {
		let {match: {params: {id}}} = this.props;
		if (id)
		HostAPI.fetchHostDetails(id).then(response=> {
				if (!response?.host) this.props.history.push('/error')
				this.setState({...response,fetched:true} as EditHostState,()=> {
					devLog('fetched')
					this.forceUpdate()
				})
			}
		)
	}

	save = ()=>{
		let {host, selectedPermissions, selectedVenues} = this.state
		let {match: {params: {id}}} = this.props;

		let updateRequest = {
			fName: host.first_name,
			lName: host.last_name,
			email: host.email,
			phone: host.phone,
			position: host.position,
			venues: host.venues,
		} as HostUpdateRequest
		this.setState({loading: true})
		if (id) {
			HostAPI.updateHostDetails(updateRequest, host, selectedPermissions, selectedVenues).then(
				(message) => {
					createAlertFromMessage(this, message)
					setTimeout(() => this.props.history.goBack(), 1500);
				}
			)
		} else {
			HostAPI.addNewHost(updateRequest, selectedPermissions, selectedVenues).then(
				(message) => {
					createAlertFromMessage(this, message)
					setTimeout(() => this.props.history.goBack(), 1500);
				}
			)
		}

	}

	permissionChanged:PermissionChangedCallback = (request:FetchHostDetailsResponse)=>{
		this.setState({
			selectedPermissions:request.selectedPermissions,
			selectedVenues:request.selectedVenues,
		})
	}


	render() {
		let {classes} = this.props;
		let response = this.state as FetchHostDetailsResponse
		let {fetched, host, reqAlert, loading} = this.state

		// devLog( "EditHost" , this.props);
		return (
			<div className="container mt-100 pr-0 pl-0" style={{position: 'relative'}}>
				<div className="page " style={{position: 'relative'}}>

					<div className={clsx('d-flex align-items-center')}>
						<IconButton>
							<ArrowBackIosIcon onClickCapture={() => this.props.history.goBack()}/>
						</IconButton>

						{fetched &&
						<div className={clsx('d-inline-block')}>
							<span
								className={clsx('h5')}>{`${host?.first_name ?? ''} ${host?.last_name ?? ''}`}</span><br/>
							<span className={clsx('h6')} style={{color: blueGrey[400]}}>{host?.email}</span>
						</div>
						}
						{/*{this.getStatusComponent(host.status)}*/}
					</div>

					{(!fetched || loading) ? (
						<div className={clsx('d-flex col-12 justify-content-center')}>
							<CircularProgress className={clsx('mx-auto my-5')}/>
						</div>
					) : (<>
						<HostPermissions {...response} permissionChangedCallback={this.permissionChanged}/>
						<div className="d-flex flex-nowrap flex-row-reverse mt-3 mb-4 pr-0 pl-0">
							<button
								className={"btn btn-lg btn-primary btn-block fl_btn save-btn align-start "}
								onClickCapture={this.save}
							>
								Save
							</button>
						</div>
					</>)
					}


				</div>
				<AlertComponent reqAlert={reqAlert} onDismissClicked={() => this.setState({reqAlert: undefined})}/>
			</div>
		);
	}
}
const styles = (theme: Theme)=>
	({
		root: {
			maxWidth: '80%',
		},
		media: {
			height: 0,
			paddingTop: '56.25%', // 16:9
		},
		expand: {
			transform: 'rotate(0deg)',
			marginLeft: 'auto',
			transition: theme.transitions.create('transform', {
				duration: theme.transitions.duration.shortest,
			}),
		},
		expandOpen: {
			transform: 'rotate(180deg)',
		},
		avatar: {
			backgroundColor: blueGrey[300],
		},
		count:{
			borderRadius:'40%',
			padding:'.2rem 0.5rem',
			fontSize:'.8rem',
			backgroundColor: blue[300],
			verticalAlign:'middle',
		},
		title: {
			verticalAlign:'middle',
		},
		iconButton:{
			marginLeft:'auto',
			alignSelf:'middle',

		}
	} as Styles<Theme,any, string> )

// @ts-ignore
export let EditHostEnhanced = withStyles(styles)(withRouter(EditHost));
