import React from "react";
import cityLogo1 from 'src/img/spaces_in_dubai_256.jpg';
import cityLogo2 from 'src/img/spaces_in_abudhabi_256.jpg';
import cityLogo3 from 'src/img/spaces_in_sharjah_256.jpg';
import cityLogo4 from 'src/img/spaces_in_riyadh_256.jpg';
import cityLogo5 from 'src/img/spaces_in_london_256.jpg';
import cityLogo6 from 'src/img/spaces_in_mumbai_256.jpg';
import cityLogo7 from 'src/img/spaces_in_bangalore_256.jpg';
import cityLogo8 from 'src/img/spaces_in_doha_256.jpg';

const cities = [
    {id: 'Dubai', name: 'Dubai', img: cityLogo1},
    {id: 'Abu-Dhabi', name: 'Abu Dhabi', img: cityLogo2},
    {id: 'Sharjah', name: 'Sharjah', img: cityLogo3},
    {id: 'Riyadh', name: 'Riyadh', img: cityLogo4},
    {id: 'London', name: 'London', img: cityLogo5},
    {id: 'Mumbai', name: 'Mumbai', img: cityLogo6},
    {id: 'Bangalore', name: 'Bangalore', img: cityLogo7},
    {id: 'Doha-and-the-rest-of-Qatar', name: 'Doha', img: cityLogo8},
]

const Cities = ({hideDubai, handleCity, allowLink}) => {

    return cities.map(
        (item, i) => {
            let city = (
                <div className="city">
                    <span className="city-img" style={{backgroundImage: `url(${item.img})`}} role={'img'}
                          aria-label={"Find & book your ideal space in " + item.name}/>
                    <span className="city-label">
                                        <span className="city-title">{item.name}</span>
                                        <span className="city-subtitle">View Spaces</span>
                                    </span>
                </div>
            )
            if ((item.id !== 'Dubai') || (item.id === 'Dubai' && !hideDubai))
                return (

                    <div key={i} className="col-lg-3 col-md-4 col-sm-6 col-6 p-2"
                         onClick={() => handleCity?.(item)}>
                        {
                            allowLink ? (
                                <a href={'/search/' + item.id}>
                                    {city}
                                </a>
                            ) : (city)
                        }
                    </div>

                )
        }
    )
}


export default Cities;
