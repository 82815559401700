import React, {useEffect, useRef} from 'react';
import {FetchHostDetailsResponse} from "src/APIs/HostAPI";
import {ReactComponent as CheckedSVG} from "src/img/checked.svg";
import {ReactComponent as DefCheckedSVG} from "src/img/def_check.svg";
import {ReactComponent as CheckSVG} from "src/img/check.svg";
import {blue, blueGrey, red} from "@material-ui/core/colors";
import {Accordion, AccordionDetails, AccordionSummary,} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import {useUpdateReducer} from "src/hooks/useUpdateReducer";
import {devLog} from 'src/Helpers';
import HostsUtils from "src/utils/host-utils";

export type PermissionChangedCallback = (request: FetchHostDetailsResponse) => any
export type HostPermissionsProps = {
	classes?: any,
	permissionChangedCallback?: PermissionChangedCallback,
} & FetchHostDetailsResponse

export type HostPermissionsState = {
	hiddenSections: any,
} & FetchHostDetailsResponse


export function HostPermissions(props: HostPermissionsProps) {
	const venuesRef = useRef<HTMLDivElement>(null);
	const [state, setState, updateState] = useUpdateReducer<any>({
		selectedVenues: [],
		selectedPermissions: [], ...props
	})
	const classes = useStyles();
	useEffect(() => {
		permissionChanged()
	}, [state])
	const permissionChanged = () => {
		let {selectedVenues, selectedPermissions, venues, host, hiddenSections} = state;
		devLog('host', host)
		devLog('selectedPermissions', selectedPermissions)
		props.permissionChangedCallback?.(state as FetchHostDetailsResponse)
	}

	const handleSelectVenue = (venue: any) => {

		const {selectedVenues, selectedPermissions} = state;
		const venueIdx = selectedVenues?.findIndex(x => x.venue_details_id === venue.venue_details_id);
		if (venueIdx !== null)
			if (venueIdx > -1) {
				selectedVenues?.splice(venueIdx, 1);
				delete selectedPermissions[venue.venue_details_id]
			} else {
				selectedVenues?.push(venue);
			}
		updateState({selectedVenues});
	}
	const selectAll = (venue) => {
		const allPermissions = Object.fromEntries(HostsUtils.VENUE_PERMISSIONS.map((vPerm) => {
			return [vPerm.value, vPerm.options.map(o => o.value)]
		}))

		updateState(
			{
				selectedPermissions: {
					...state.selectedPermissions,
					[venue.venue_details_id]: allPermissions
				}
			}
		);
	}
	const deselectAll = (venue) => {
		let {host} = state;
		let host_status = (host && host?.user_id === host?.host_id) ? 'admin' : host?.status ?? '';

		let permissions = {}

		if (host_status === 'admin')
			permissions = Object.fromEntries(HostsUtils.VENUE_PERMISSIONS.map((vPerm) => {
				return [
					vPerm.value,
					vPerm.options.filter(o => !Boolean(o.optional)).map(o => o.value)
				]
			}))

		updateState(
			{
				selectedPermissions: {
					...state.selectedPermissions,
					[venue.venue_details_id]: permissions
				}
			}
		);
	}
	const handleSelectPerm = (id: any, value: any, perm: any) => {

		const {selectedPermissions} = state;
		const permissions = selectedPermissions[id] != null && selectedPermissions[id][value] != null ? selectedPermissions[id][value] : [];

		const permIdx = permissions.findIndex((x: any) => x === perm);
		if (permIdx > -1) {
			permissions.splice(permIdx, 1);
		} else {
			permissions.push(perm);
		}

		if (permissions.length === 0) {
			delete selectedPermissions[id][value];
			if (selectedPermissions[id].length === 0) {
				delete selectedPermissions[id];
			}
		} else {
			const permDict = selectedPermissions[id] != null ? selectedPermissions[id] : {};
			permDict[value] = permissions;
			selectedPermissions[id] = permDict;
		}

		updateState({selectedPermissions});
	}


	let {selectedVenues, selectedPermissions, venues, host} = state;
	let host_status = (host && host?.user_id === host?.host_id) ? 'admin' : host?.status ?? '';

	return (
		<div>
			{
				(selectedVenues && selectedPermissions) ?
					<div className="edit-host">

						{/*region select venues*/}
						<div ref={venuesRef}>
							<div className={classes.inputContent}>
								<span className={classes.label}>Venues</span>

								<div className={clsx('overflow-auto mh-20 ')}
								     style={{width: '100%', marginTop: 14}}>

									{venues?.map((item, i) => (
										<div key={i} className={classes.gridCol}>
											<Checkbox
												checked={selectedVenues?.findIndex(x => x.venue_details_id === item.venue_details_id) > -1 ?? false}
												onClick={() => handleSelectVenue(item)}
												checkedIcon={<CheckedSVG/>}
												icon={<CheckSVG/>}
											/>
											<span className={classes.text}
											      onClickCapture={() => handleSelectVenue(item)}>
                                        {item.venue_name}
                                    </span>
											</div>
										))}

									</div>

								</div>
							</div>
							{/*endregion select venues*/}


                            {/*region selected venues permissions*/}

                            <Container className={classes.root} maxWidth={false}>

                                {selectedVenues.map((venue, i) => (
	                                // <Accordion elevation={4} defaultExpanded={Boolean(hiddenSections[`venue-${i}`])} expanded={Boolean(hiddenSections[`venue-${i}`])} onClickCapture={()=> handleShow(`venue-${i}`)}>
                                    <Accordion elevation={4} defaultExpanded={i===0}>

	                                    <AccordionSummary
		                                    expandIcon={<ExpandMoreIcon/>}
		                                    aria-controls="panel1a-content"
		                                    id="panel1a-header">
		                                    <div className={clsx(classes.sectionHead)} style={{marginTop: 10}}>
			                                    {venue.venue_name}
		                                    </div>
	                                    </AccordionSummary>
	                                    <AccordionDetails>
		                                    <div key={i} className={clsx('w-100')}>
			                                    <div className={clsx('d-flex flex-row-reverse ')}>
				                                    <div className={clsx('cursor-pointer mx-2')}
				                                         onClickCapture={() => selectAll(venue)}><u> Select
					                                    All </u></div>
				                                    <div className={clsx('cursor-pointer mx-2')}
				                                         onClickCapture={() => deselectAll(venue)}><u> Deselect
					                                    All</u></div>
			                                    </div>
			                                    <div style={{flexDirection: 'column', width: '100%', marginBottom: 12}}>
				                                    {HostsUtils.VENUE_PERMISSIONS.map((vPerm, index) => (
					                                    <div key={index}>
						                                    {((vPerm.value !== 'ST' && vPerm.value !== 'LT' && vPerm.value !== 'DDR') || venue[vPerm.value] > 0) && (
							                                    <>
								                                    <div
									                                    className={clsx(classes.headLabel)}>{vPerm.label}</div>

								                                    <div
									                                    className={clsx('d-flex flex-row flex-wrap mb-8')}>
									                                    {vPerm.options.map((item, i) => (
										                                    <div key={i}
										                                         className={clsx(classes.gridCol)}>
											                                    <Checkbox
												                                    checked={selectedPermissions[venue.venue_details_id] != null && selectedPermissions[venue.venue_details_id][vPerm.value] != null && selectedPermissions[venue.venue_details_id][vPerm.value].findIndex((x: any) => x === item.value) > -1}
												                                    onClickCapture={() => handleSelectPerm(venue.venue_details_id, vPerm.value, item.value)}
												                                    checkedIcon={host_status === 'admin' && !item.optional ?
													                                    <DefCheckedSVG/> :
													                                    <CheckedSVG/>}
												                                    disabled={host_status === 'admin' && !item.optional}
												                                    icon={<CheckSVG/>}/>
											                                    <div className={clsx(classes.text)}
											                                         onClick={() => handleSelectPerm(venue.venue_details_id, vPerm.value, item.value)}>
												                                    {item.label}
											                                    </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>

                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}

                            </Container>




                            {/*{errors.venues && (<Text style={styles.errorLabel}>{errors.venues}</Text>)}*/}
                            {/*endregion selected venues permissions*/}
                            <div className={clsx('mt-40 mb-40')}>
	                            <Divider />
                            </div>

							{/*region other*/}
							<div className={clsx('mt-40')} >
								<Accordion defaultExpanded>
									{/*<Accordion expanded={Boolean(hiddenSections[`other`])} onClickCapture={()=> handleShow(`other`)} >*/}
									<AccordionSummary
										expandIcon={<ExpandMoreIcon/>}
										aria-controls="panel1a-content"
										id="others-header"
									>
										<div className={classes.bigSectionHead} style={{marginTop: 24}}>
											Other Permissions
										</div>
									</AccordionSummary>
									<AccordionDetails>

										<div style={{width: '100%', marginBottom: 12}}>
											{HostsUtils.OTHER_PERMISSIONS.map((oPerm, index) => (
												<div key={`other-permissions-${index}`}
												     className={clsx('d-flex flex-wrap')}>
													{oPerm.options.map((item, i) => (
														<div key={i} className={clsx(classes.gridCol, 'px-2')}>
															<Checkbox
																checked={selectedPermissions['other'] != null && selectedPermissions['other'][oPerm.value] != null && selectedPermissions['other'][oPerm.value].findIndex((x: any) => x === item.value) > -1}
																onChange={() => handleSelectPerm('other', oPerm.value, item.value)}
																checkedIcon={host_status === 'admin' && !item.optional ?
																	<DefCheckedSVG/> : <CheckedSVG/>}
																disabled={host_status === 'admin' && !item.optional}
																	icon={<CheckSVG/>}

																/>
																<div className={classes.text}
																     onClick={() => handleSelectPerm('other', oPerm.value, item.value)}>
																	{item.label}
																</div>
															</div>
														))}
													</div>
												))}
											</div>

									</AccordionDetails>
								</Accordion>
							</div>
							{/*endregion other*/}


						</div>

						:
						<>
							<span className={classes.label}>Error</span>
							{/*<Typography>*/}
							{/*	{JSON.stringify(this.state, null, 4)}*/}
							{/*</Typography>*/}
						</>
				}
			</div>
		)
}

const useStyles = makeStyles((theme: Theme) => ({

	root: {
		width: '95%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},

	scrollView: {
		backgroundColor: 'white',
	},
	content: {
		flex: 1,
		paddingHorizontal: 20,
	},
	container: {
		backgroundColor: 'white',
		flex: 1,
	},
	header: {
		backgroundColor: 'white',
		paddingLeft: 24,
		paddingRight: 24,
	},
	sectionHead: {
		width: '100%',
		fontSize: 16,
	},
	bigSectionHead: {
		width: '100%',
		fontSize: 19,
	},
	divider: {
		flex: 1,
		height: 0.5,
		marginLeft: 20,
		marginRight: 0,
		backgroundColor: '#CFD8E7',
	},
	reservation: {
		flex: 1,
		marginHorizontal: 20,
		paddingVertical: 12,
		alignItems: 'center',
		flexDirection: 'row',
	},
	reservimg: {
		height: 36,
		width: 36,
		flex: 0,
		resizeMode: 'cover',
		borderRadius: 6,
		backgroundColor: '#f1f3f6',
	},
	generalimg: {
		resizeMode: 'contain',
		marginTop: 'auto',
		marginBottom: 'auto',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	reservdetails: {
		flex: 1,
		flexShrink: 1,
		flexDirection: 'column',
		paddingRight: 8,
		overflow: 'hidden',
	},
	reservTitle: {
		color: '#212529',
		paddingBottom: 3,
		paddingRight: 50,
		overflow: 'hidden',
		fontSize: 18,
	},
	reservText: {
		color: '#212529',
		paddingBottom: 3,
		overflow: 'hidden',
		fontSize: 16,
	},
	statusView: {
		flexGrow: 0,
		flexShrink: 0,
		paddingTop: 1,
		paddingBottom: 2,
		paddingHorizontal: 6,
		borderRadius: 4,
		marginTop: 3,
		backgroundColor: 'black',
	},
	statusText: {
		fontSize: 15,
		color: 'white',
	},
	statusGreen: {
		backgroundColor: '#097e09',
	},
	statusGray: {
		backgroundColor: '#7E7F80',
	},
	statusRed: {
		backgroundColor: '#bf0f0f',
	},
	headLabel: {
		paddingTop: 2,
		paddingBottom: 12,
		fontSize: 16,
		color: blueGrey[700],
	},
	label: {
		paddingTop: 4,
		fontSize: 15,
		color: blueGrey[400],
	},
	errorLabel: {
		fontSize: 16,
		paddingTop: 6,
		color: red[500],
	},
	inputContent: {
		marginTop: 20,
	},
	textInput: {
		paddingVertical: 0,
		paddingHorizontal: 0,
		flexGrow: 1,
		height: 40,
		borderColor: '#CFD8E7',
		borderRightWidth: 0,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		borderBottomWidth: 0.5,
		fontSize: 18,
	},
	errorInput: {
		paddingVertical: 0,
		paddingHorizontal: 0,
		flexGrow: 1,
		height: 40,
		borderColor: red[500],
		borderRightWidth: 0,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		borderBottomWidth: 1.5,
		fontSize: 18,
	},
	multiLineInput: {
		textAlignVertical: 'top',
		paddingVertical: 0,
		paddingHorizontal: 0,
		flexGrow: 1,
		height: 100,
		borderColor: '#CFD8E7',
		borderRightWidth: 0,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		borderBottomWidth: 0.5,
		fontSize: 18,
	},
	errorMultiLineInput: {
		textAlignVertical: 'top',
		paddingVertical: 0,
		paddingHorizontal: 0,
		flexGrow: 1,
		height: 100,
		borderColor: red[500],
		borderRightWidth: 0,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		borderBottomWidth: 1.5,
		fontSize: 18,
	},
	checkbox: {
		paddingRight: 0,
		paddingLeft: 0,
		paddingTop: 0,
		paddingBottom: 0,
		marginRight: 12,
		marginLeft: 0,
		marginTop: 0,
		marginBottom: 0,
	},
	col: {
		flexDirection: "row",
		flexWrap: 'nowrap',
		marginBottom: 12,
		paddingRight: 12,
	},
	gridCol: {
		flexDirection: "row",
		flexWrap: 'nowrap',
		flexGrow: 0,
		flexShrink: 0,
		marginBottom: 12,
		paddingRight: 12,
	},
	text: {
		fontSize: 17,
		marginTop: 'auto',
		marginBottom: 'auto',
		width: 'fit-content',
	},
	submitBtn: {
		height: 52,
		backgroundColor: blue[400],
		marginTop: 14,
		marginBottom: 14,
		borderRadius: 10,
		justifyContent: 'center',
	},
	submitBtnView: {
		flex: 0,
		marginRight: 'auto',
		marginLeft: 'auto',
		flexDirection: 'row',
	},
	submitBtnText: {
		flex: 0,
		textAlign: 'center',
		color: 'white',
		fontSize: 17,
	}
}))


