import React, {Component} from "react";
import {Link} from "react-router-dom";
import {createAlert, formValidation, getApiLinkV2, setUser} from "../../Helpers";
import User from "./User";
import axios from "axios";
import {getAxios} from "src/Helpers";
import FloatingAlert from "../organisms/FloatingAlert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import Bugsnag from "@bugsnag/js";
import ReactGA from "react-ga-neo";
import {capitalizeFirst} from "../host/host-helpers";
import Helmet from "react-helmet";
class SignUp extends Component {

  constructor(props) {
    super(props);

    this.state = {
      reqAlert: null,
      fNameError: null,
      lNameError: null,
      emailError: null,
      passError: null,
      confirmPassError: null,
    }

    this.handleSignUp = this.handleSignUp.bind(this);
  }

  handleSignUp(e) {
    e.preventDefault();

    const data = new FormData(e.target);
    const fNameError = formValidation('fname', data.get("fname"));
    const fLameError = formValidation('lname', data.get("lname"));
    const emailError = formValidation('email', data.get("email"));
    const passError = formValidation('password', data.get("password"));
    const confirmPassError = formValidation('confirmpass', data.get("confirmpass"));
    const passMatchError = data.get("password") === data.get("confirmpass") ? null : 'Passwords do not match!';

    this.setState({ reqLoading: true, reqAlert: null, fNameError: fNameError, fLameError: fLameError, emailError: emailError, passError: passError, confirmPassError: ((confirmPassError) ? confirmPassError : passMatchError) });

    if (fNameError || fLameError || emailError || passError || confirmPassError || passMatchError) return;

    const url = getApiLinkV2('auth/signup');

    getAxios().post(url, {
      user_type: this.props.user_type ?? 'seeker',
      first_name: data.get("fname"),
      last_name: data.get("lname"),
      email: data.get("email"),
      password: data.get("password"),
    }, {
      headers: { 'Content-Type': 'application/json' },
    }).then(response => {
        const user = response.data.userData;
        setUser(user, response.data.token);
        Bugsnag.setUser(user.user_id, user.email, user.first_name+" "+user.last_name);
        ReactGA.set({ userId: user.user_id });
        if (window.fcWidget) {
          window.fcWidget.setExternalId("user_"+user.user_id);
          window.fcWidget.user.setFirstName(user.first_name);
          window.fcWidget.user.setFirstName(user.last_name);
          window.fcWidget.user.setEmail(user.email);
        }
        this.props.signInCallback(new User(user));
      })
      .catch(error => {
        Bugsnag.notify(error);
        createAlert(this, 'danger',(error.response && error.response.data.error) ? 'Error: ' : '', (error.response && error.response.data.error) ? error.response.data.error : error.message);
      }).then(() => {
      this.setState({ reqLoading: false });
    });
  }
  
  
  render() {

    const { reqLoading, reqAlert } = this.state;

    return (
      <>
          <Helmet>
            <meta charSet="utf-8"/>
            <meta name={'description'}
                  content={'Find Your Ideal Space, Whether You Want to Rent Serviced Offices, Coworking Spaces, Banquet & Event Halls and Meeting Rooms, Commission-Free Booking'}/>
            <meta name="keywords"
                  content="Banquet & Event Halls, Serviced Offices, Co-working Spaces, Meeting Rooms, Rent, Book"/>
            <link rel={"canonical"} href="https://www.fluidmeet.com/signup" />
          </Helmet>
        <form autoComplete="on" onSubmit={this.handleSignUp}>
          {(this.props.user_type) ? (
              <input type="text" id="type" name="type" className="form-control" placeholder="Type"
                     value={capitalizeFirst(this.props.user_type)} required/>) : (<></>)}
    
          <input type="text" id="fname" name="fname" defaultValue={this.props.fname} className="form-control"
                 placeholder="First Name" required/>
          {(this.state.fNameError) ? (<div className="form-error">{this.state.fNameError}</div>) : (<></>)}
    
          <input type="text" id="lname" name="lname" defaultValue={this.props.lname} className="form-control"
                 placeholder="Last Name" required/>
          {(this.state.lNameError) ? (<div className="form-error">{this.state.lNameError}</div>) : (<></>)}
    
          <input type="email" id="email" name="email" defaultValue={this.props.email} className="form-control"
                 placeholder="Email address" required/>
          {(this.state.emailError) ? (<div className="form-error">{this.state.emailError}</div>) : (<></>)}
    
    
          <input type="password" id="password" name="password" className="form-control" placeholder="Password"
                 required/>
          {(this.state.passError) ? (<div className="form-error">{this.state.passError}</div>) : (<></>)}
    
          <input type="password" id="confirmpass" name="confirmpass" className="inputPassword form-control"
                 placeholder="Confirm Password" required/>
          {(this.state.confirmPassError) ? (<div className="form-error">{this.state.confirmPassError}</div>) : (<></>)}
    
          <button type="submit" className={"btn btn-lg btn-primary btn-block fl_btn " + ((reqLoading) ? 'ld-btn' : '')}>
            {(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch} spin={true} /> loading</>) : 'Create Account'}
          </button>

          <h2 className="fl_a mb-3 font-weight-normal">
            Already have an account? {" "}
            <Link to={"/login"} className="a2">Log In </Link>
          </h2>
        </form>
        {(reqAlert && <FloatingAlert error={reqAlert} onClick={() => this.setState({reqAlert: null})} />)}
      </>
    );
  }
}

export default SignUp;
