import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import React from "react";

export type AlertRequest = {
		id: string,
		type: string,
		title: string,
		message: string
}
export type AlertComponentProps = {
	reqAlert?: AlertRequest
	onDismissClicked: ((arg?: any) => any),
}

export function AlertComponent({reqAlert, onDismissClicked}: AlertComponentProps) {
	return (


		(reqAlert) ? (
			<div id="alerts">
				<div
					className={"alert alert-" + reqAlert?.type + " alert-dismissible floating-alert fade show"}>
					<strong>{reqAlert?.title}</strong> {reqAlert?.message}
					<button type="button" className="btn-close"
					        onClick={() => onDismissClicked()}>
						<FontAwesomeIcon icon={faTimes}/>
					</button>
				</div>
			</div>
		) : (<></>)


	);
}
