import {configureStore} from '@reduxjs/toolkit';
import settingsReducer from './SettingsSlice';
import venuesReducer from "./VenuesSlice";


export default configureStore({
  reducer: {
    settings: settingsReducer,
    venues: venuesReducer,
  },
});
