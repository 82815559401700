import React, {Component} from 'react';
import axios from 'axios';
import {
  createAlert,
  devLog,
  E164FormatNumber,
  formValidation,
  getApiLinkV2,
  getToken,
  getUser,
  setUserData,
  setUserImage
} from "src/Helpers";
import {Link, Redirect, withRouter} from "react-router-dom";
import profileImg from "../../img/profile.jpg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch, faTimes} from "@fortawesome/free-solid-svg-icons";
import {CountryDropdown, RegionDropdown} from 'react-country-region-selector';
import Bugsnag from "@bugsnag/js";
import {FMPhoneInput} from "../host/forms/fields";
import {getAxios} from "src/Helpers";
class Profile extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      selectedCountry: '',
      selectedCity: '',
      uploadingFile: false,
      selectedFile: null,
      reqAlert: null,
      reqLoading: false,
      fNameError: null,
      lNameError: null,
      phoneError: null,
      emailError: null,
      countryError: null,
      cityError: null,
      companyError: null,
      positionError: null,
      oldPassError: null,
      newPassError: null,
      confirmPassError: null,
    }

    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleUpdateProfile = this.handleUpdateProfile.bind(this);
    this.handleUpdatePass = this.handleUpdatePass.bind(this);
  }

  componentDidMount() {
    const user = getUser();
    this.setState({ selectedCountry: user.country, selectedCity: user.city });
    //ReactGA.pageview(window.location.pathname + window.location.search);
  }

  handleFileUpload(e) {

    this.setState({ uploadingFile: true });

    const token = getToken();
    const url = getApiLinkV2('user/image');
    const selectedFile = e.target.files[0];
    const formData = new FormData();
    formData.append("image", selectedFile);

    getAxios().post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer '+token,
      },
    }).then(response => {
          devLog(response);
          setUserImage(response.data.photo);
        this.setState({ uploadingFile: false });
        createAlert(this, 'success', '', 'Your profile picture has been updated.');
      })
      .catch(error => {
        Bugsnag.notify(error);
        devLog(error);
        this.setState({uploadingFile: false});
        createAlert(this, 'danger',(error.response && error.response.data.message) ? 'Error: ' : '', (error.response && error.response.data.message) ? error.response.data.message : error.message);
      });
  }

  handleUpdateProfile(e) {
    e.preventDefault();

    const data = new FormData(e.target);
    const fNameError = formValidation('fname', data.get("fname"));
    const lNameError = formValidation('lname', data.get("lname"));
    const phoneError = formValidation('phone', data.get("phone"), false);
    const emailError = formValidation('email', data.get("email"));
    const countryError = formValidation('country', this.state.selectedCountry, false);
    const cityError = formValidation('city', this.state.selectedCity, false);
    const companyError = formValidation('company', data.get("company"), false);
    const positionError = formValidation('position', data.get("position"), false);

    this.setState({
      reqLoading: true,
      fNameError: fNameError,
      lNameError: lNameError,
      phoneError: phoneError,
      emailError: emailError,
      countryError: countryError,
      cityError: cityError,
      companyError: companyError,
      positionError: positionError
    });

    if (fNameError || lNameError || phoneError || emailError || countryError || cityError || companyError || positionError) {
      this.setState({reqLoading: false, });
      return;
    }
  
    const user = getUser();
    const token = getToken();
    const url = getApiLinkV2('user/'+user.id);
    const userData = {
      first_name: data.get("fname"),
      last_name: data.get("lname"),
      phone: E164FormatNumber(data.get("phone")),
      email: data.get("email"),
      country: this.state.selectedCountry,
      city: this.state.selectedCity,
      company: data.get("company"),
      position: data.get("position"),
    }

    getAxios().post(url, userData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token,
      },
    }).then(response => {
        setUserData(userData);
        createAlert(this, 'success', '', 'Your profile details have been updated.');
      })
      .catch(error => {
        Bugsnag.notify(error);
        createAlert(this, 'danger',(error.response && error.response.data.error) ? 'Error: ' : '', (error.response && error.response.data.error) ? error.response.data.error : error.message);
      })
      .then(() => {
        this.setState({reqLoading: false, });
      });
  }

  handleUpdatePass(e) {
    e.preventDefault();

    const data = new FormData(e.target);
    const oldPassError = formValidation('password', data.get("password"));
    const newPassError = formValidation('password', data.get("newPass"));
    const confirmPassError = formValidation('confirmpass', data.get("confirmpass"));
    const passMatchError = data.get("newPass") === data.get("confirmpass") ? null : 'Passwords do not match!';

    this.setState({
      reqLoading: true,
      reqAlert: null,
      oldPassError: oldPassError,
      newPassError: newPassError,
      confirmPassError: ((confirmPassError) ? confirmPassError : passMatchError)
    });

    if (oldPassError || newPassError || confirmPassError || passMatchError) {
      this.setState({reqLoading: false, });
      return;
    }

    const url = getApiLinkV2('user/password');
    const token = getToken();

    getAxios().post(url, {
      oldPass: data.get("password"),
      password: data.get("newPass"),
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token,
      },
    }).then(response => {
        createAlert(this, 'success', '', 'Your password has been updated.');
    }).catch(error => {
      Bugsnag.notify(error);
        createAlert(this, 'danger',(error.response && error.response.data.error) ? 'Error: ' : '', (error.response && error.response.data.error) ? error.response.data.error : error.message);
    }).then(() => {
        this.setState({ reqLoading: false });
        let passDiv = document.getElementById('password');
        let newPassDiv = document.getElementById('newPass');
        let conPassDiv = document.getElementById('confirmpass');
        if (passDiv) passDiv.value = '';
        if (newPassDiv) newPassDiv.value = '';
        if (conPassDiv) conPassDiv.value = '';
    });
  }

  render() {

    const { reqLoading, reqAlert, selectedCountry, selectedCity, uploadingFile } = this.state;
    const { page } = this.props;
    const user = getUser();

    if (!user) return (<Redirect to='/error' />);

    return (
      <div className="container-fluid bg-white mt-80 pr-0 pl-0">
        <div className="container">
          <section className="page pt-4">

            <h1 className="page-lg-title mt-4 mb-5">My Account</h1>

            <div className="nav nav-tabs fz-17 page-nav mb-3">
              <div className="nav-item">
                <Link id="profile-tab" className={"nav-link " + ((page == 'profile') ? "active" : "")} to='/profile'>Profile</Link>
              </div>
              <div className="nav-item">
                <Link id="login-tab" className={"nav-link " + ((page == 'security') ? "active" : "")} to='/security'>Login & Security</Link>
              </div>
            </div>

            <div className="tab-content">
              <div id="profile" className={"tab-pane fade " + ((page == 'profile') ? "show active" : "")}>

                <div className="row m-0">

                  <div className="profile-img">
                    {/*{(uploadingFile) ? (*/}
                    {/*  <div className="loadingbtn">Uploading...</div>*/}
                    {/*) : (*/}
                    {/*  <>*/}
                    <div className="row m-0">
                      <img src={(user.image) ? (user.image.startsWith('http') ? user.image : "https://fluidmeet.s3.amazonaws.com/venue_logos/"+user.image) : profileImg} />
                    </div>
                      <div className="btn-div">
                        <input type="file" id="myFile" name="myFile" onChange={this.handleFileUpload} style={{display: 'none'}} />
                        <button className={"custom-btn clear-btn text-center"+((uploadingFile) ? 'ld-btn' : '')} type="button" onClick={() => document.getElementById('myFile').click()}>
                          {(uploadingFile) ? (<><FontAwesomeIcon icon={faCircleNotch} spin={true} /> loading</>) : 'Change Picture'}
                        </button>
                      </div>
                      {/*</>*/}
                    {/*)}*/}
                  </div>

                  <div className="profile-data">

                    <div className="form-general p-0">
                      <form className="row mr-0 ml-0 mt-5" autoComplete="on" onSubmit={this.handleUpdateProfile}>

                        <div className="col-6 pl-0 pl-mobile-0 pr-mobile-1">
                          <input type="name" id="fname" name="fname" className="form-control" placeholder="First name" defaultValue={user.first_name} required />
                          {(this.state.fNameError) ? (<div className="form-error">{this.state.fNameError}</div>) : (<></>)}
                        </div>
                        <div className="col-6 pr-0 pl-mobile-1 pr-mobile-0">
                          <input type="name" id="lname" name="lname" className="form-control" placeholder="Last name"
                                 defaultValue={user.last_name} required/>
                          {(this.state.lNameError) ? (
                              <div className="form-error">{this.state.lNameError}</div>) : (<></>)}
                        </div>
                        <FMPhoneInput type={'tel'} id="phone" placeholder="Phone number" value={user.phone}
                                      error={this.state.phoneError}
                                      extraClassNames={"col-sm-6 pl-0 pr-mobile-0 pl-mobile-0"}/>
                        <div className="col-sm-6 col-12 pr-0 pr-mobile-0 pl-mobile-0">
                          <input type="email" id="email" name="email" className="inputEmail form-control"
                                 placeholder="Email address" defaultValue={user.email} required/>
                          {(this.state.emailError) ? (
                              <div className="form-error">{this.state.emailError}</div>) : (<></>)}
                        </div>
                        <div className="col-sm-6 col-12 pl-0 pr-mobile-0 pl-mobile-0">
                          <div className="selector">
                            <CountryDropdown classes="select" value={selectedCountry} onChange={(val) => this.setState({ selectedCountry: val })} />
                          </div>
                        </div>
                        <div className="col-sm-6 col-12 pr-0 pr-mobile-0 pl-mobile-0">
                          <div className="selector">
                            <RegionDropdown classes="select" disableWhenEmpty={true} country={selectedCountry} value={selectedCity} onChange={(val) => this.setState({ selectedCity: val })} />
                          </div>
                        </div>
                        <div className="col-sm-6 col-12 pl-0 pr-mobile-0 pl-mobile-0">
                          <input type="text" id="company" name="company" className="form-control" placeholder="Company" defaultValue={user.company} />
                          {(this.state.companyError) ? (<div className="form-error">{this.state.companyError}</div>) : (<></>)}
                        </div>
                        <div className="col-sm-6 col-12 pr-0 pr-mobile-0 pl-mobile-0">
                          <input type="text" id="position" name="position" className="form-control" placeholder="Position" defaultValue={user.position} />
                          {(this.state.positionError) ? (<div className="form-error">{this.state.positionError}</div>) : (<></>)}
                        </div>

                        <div className="col-12 mt-3 mb-4 pr-0 pl-0">
                          <button className={"btn btn-lg btn-primary btn-block fl_btn save-btn align-start "+((reqLoading) ? 'ld-btn' : '')}>
                            {(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch} spin={true} /> loading</>) : 'Save'}
                          </button>
                        </div>

                      </form>
                    </div>
                  </div>

                </div>

              </div>

              <div id="login" className={"tab-pane fade " + ((page == 'security') ? "show active" : "" )}>

                <div className="row m-0">

                  <div className="login-data">
                    <div className="form-general p-0">
                      <form className="row mr-0 ml-0 mt-5" autoComplete="on" onSubmit={this.handleUpdatePass}>

                        <div className="col-sm-6 col-12 pl-0 pr-mobile-0 pl-mobile-0">
                          <input type="password" id="password" name="password" className="form-control" placeholder="Old Password" required />
                          {(this.state.oldPassError) ? (<div className="form-error">{this.state.oldPassError}</div>) : (<></>)}
                        </div>
                        <div className="col-6 pr-0 mobile-hide" />
                        <div className="col-sm-6 col-12 pl-0 pr-mobile-0 pl-mobile-0">
                          <input type="password" id="newPass" name="newPass" className="form-control" placeholder="New Password" required />
                          {(this.state.passError) ? (<div className="form-error">{this.state.passError}</div>) : (<></>)}
                        </div>
                        <div className="col-sm-6 col-12 pr-0 pr-mobile-0 pl-mobile-0">
                          <input type="password" id="confirmpass" name="confirmpass" className="inputPassword form-control" placeholder="Confirm New Password" required />
                          {(this.state.confirmPassError) ? (<div className="form-error">{this.state.confirmPassError}</div>) : (<></>)}
                        </div>
                        <div className="col-12 mt-3 mb-4 pr-0 pl-0">
                          <button className={"btn btn-lg btn-primary btn-block fl_btn save-btn "+((reqLoading) ? 'ld-btn' : '')}>
                            {(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch} spin={true} /> loading</>) : 'Update'}
                          </button>
                        </div>

                      </form>
                    </div>
                  </div>
                </div>

              </div>

            </div>

            {(reqAlert) ? (
              <div id="alerts">
                <div className={"alert alert-"+reqAlert.type+" alert-dismissible floating-alert fade show"}>
                  <strong>{reqAlert.title}</strong> {reqAlert.message}
                  <button type="button" className="btn-close" onClick={() => this.setState({reqAlert: null})}>
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              </div>
            ) : (<></>)}

          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(Profile);
