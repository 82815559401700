import React, {Component} from 'react';
import {Link} from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Cities from './organisms/Cities';
import coWorking from '../img/Coworking_spaces.jpg';
import office from '../img/Office_Spaces_and_serviced_offices.jpg';
import meeting from '../img/Meeting_rooms.jpg';
import events from '../img/Banquet_and_event_halls.jpg';
import Helmet from "react-helmet";
export default class BusinessCenters extends Component {
  
  componentDidMount() {
    
    //ReactGA.pageview(window.location.pathname + window.location.search);
  }
  
  render() {

    return (
      <>
          <Helmet>
            <meta charSet="utf-8"/>
            <title>Fluidmeet |Business Centers |Offices in Business Centers| Serviced Office |Office Space Rental</title>
            <meta name={'description'}
                  content={'Business centers are venues that offer their clients serviced offices and co-working spaces on monthly to annual terms, and meeting rooms on hourly/daily terms. The elegantly and spaciously designed business centers in different cities we cater to are well-furnished and equipped with the latest technology for your comfort.'}/>
            <meta name="keywords"
                  content="Business center, business hubs, serviced offices, flex spaces, coworking spaces, UAE, Dubai, Abu Dhabi, Sharjah, Ras Al Khaima, Ajman, Fujirah, London, Mumbai"/>
            <link rel={"canonical"} href="https://www.fluidmeet.com/business-centers" />
          </Helmet>
        <div className="bs-banner">

          <div className="bs-header">
            <h1>Business Centers</h1>
            <h5>Find a feasible and practical space at Fluidmeet.</h5>
          </div>
        </div>


        <div className="container-fluid container bs-page">


          <section className="how-works pt-5">
            <div className="row mt-5">

              <div className="col-md-6 col-12">
                <div className="how-div">
                  <span className="cardd-title">Flexible spaces at convenient locations</span>
                  <span className="cardd-subtitle">At Fluidmeet, we have partnered with leading business centers in different cities to offer clients the best possible space solutions, at the most reasonable prices in the market. Our easy to use platform enable businesses to book their spaces in business centers with a single click.</span>
                </div>
              </div>

              <div className="col-md-6 col-12">
                <div className="how-div">
                  <span className="cardd-title">All your space needs are fulfilled</span>
                  <span className="cardd-subtitle">Whether you run a start-up company that is in need of a pocket-friendly office solution, or a company representative interested in acquiring a temporary workspace, Fluidmeet is here to help you find just what you need, that too without compromising on your budget.
                  </span>
                </div>
              </div>

            </div>
          </section>


          <div className="section-divider2" />


          <section className="types mt-100">

            <div className="row pb-100">

              <div className="col-lg-5 col-12 order-lg-2 order-2">
                <div className="cardd-desc">
                  <div className="cardd-cont">
                    <div className="cardd-title mleft-4">Office spaces</div>
                    <div className="cardd-subtitle mleft-4">Private offices that come fully furnished and equipped with wifi, DEWA, phone line and other shared services like pantry, printer/scanner, call answering, mail management and more.</div>
                    <div className="cardd-subtitle mleft-4">Capacity: 1-8 persons</div>
                    <div className="cardd-subtitle mleft-4">Booking Term: Daily, Weekly & Monthly</div>
                    <Link to="/search/nearby/office-spaces">
                      <button className="btn section-btn btn-blue mt-2 mleft-4">Explore</button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-12 order-lg-1 order-1">
                <Link to="/search/nearby/office-spaces">
                  <div className="cardd-div">
                    <img className="cardd-img" src={office} alt="card image" />
                  </div>
                </Link>
              </div>

            </div>

            <div className="row pb-100">

              <div className="col-lg-5 col-12 order-lg-1 order-2">
                <div className="cardd-desc">
                  <div className="cardd-cont">
                    <div className="cardd-title mleft-4">Banquets & event halls</div>
                    <div className="cardd-subtitle mleft-4">Any workspace (Banquets, Event halls) that can be used to conduct large-sized events.</div>
                    <div className="cardd-subtitle mleft-4">Capacity: 20+ persons</div>
                    <div className="cardd-subtitle mleft-4">Booking Term: Hourly & Daily</div>
                    <Link to="/search/nearby/banquets-and-event-spaces">
                      <button className="btn section-btn btn-blue mt-2 mleft-4">Explore</button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-12 order-lg-2 order-1">
                <Link to="/search/nearby/banquets-and-event-spaces">
                  <div className="cardd-div">
                    <img className="cardd-img" src={events} alt="card image" />
                  </div>
                </Link>
              </div>

            </div>

            <div className="row pb-100">

              <div className="col-lg-7 col-12 order-lg-1 order-1">
                <Link to="/search/nearby/co-working-spaces">
                  <div className="cardd-div">
                    <img className="cardd-img" src={coWorking} alt="card image" />
                  </div>
                </Link>
              </div>
              <div className="col-lg-5 col-12 order-lg-2 order-2">
                <div className="cardd-desc">
                  <div className="cardd-cont">
                    <div className="cardd-title mleft-4">Co-working spaces</div>
                    <div className="cardd-subtitle mleft-4">Shared workspaces that come fully furnished and equipped with wifi, DEWA, phone line and other shared services like pantry, printer/scanner, call answering, mail management and more.</div>
                    <div className="cardd-subtitle mleft-4">Capacity: 1 person</div>
                    <div className="cardd-subtitle mleft-4">Booking Term: Daily, Weekly & Monthly</div>
                    <Link to="/search/nearby/co-working-spaces">
                      <button className="btn section-btn btn-blue mt-2 mleft-4">Explore</button>
                    </Link>
                  </div>
                </div>
              </div>

            </div>

            <div className="row pb-100">

              <div className="col-lg-7 col-12 order-lg-2 order-1">
                <Link to="/search/nearby/meeting-rooms">
                  <div className="cardd-div">
                    <img className="cardd-img" src={meeting} alt="card image" />
                  </div>
                </Link>
              </div>
              <div className="col-lg-5 col-12 order-lg-1 order-2">
                <div className="cardd-desc">
                  <div className="cardd-cont">
                    <div className="cardd-title mleft-4">Meeting rooms</div>
                    <div className="cardd-subtitle mleft-4">Any workspace (Meeting rooms, Training rooms, Boardrooms) that can be used to conduct small to mid-sized meetings.</div>
                    <div className="cardd-subtitle mleft-4">Capacity: 1-20 persons</div>
                    <div className="cardd-subtitle mleft-4">Booking Term: Hourly & Daily</div>
                    <Link to="/search/nearby/meeting-rooms">
                      <button className="btn section-btn btn-blue mt-2 mleft-4">Explore</button>
                    </Link>
                  </div>
                </div>
              </div>

            </div>

          </section>


          <section className="how-works">
            <div className="row mt-5">

              <div className="col-md-6 col-12">
                <div className="how-div">
                  <div className="cardd-title">Pick the best workspace as per your needs</div>
                  <div className="cardd-subtitle">Using our online platform, clients can readily view and compare prices and features of all listed Serviced Offices and Co-working spaces. All you need to do is request a tour or call from the business center that offers the workspaces that most suit your requirements. Yes, as soon as you come across a workspace that you like, go ahead and request a call from the business center or a tour right away!</div>
                </div>
              </div>

              <div className="col-md-6 col-12">
                <div className="how-div">
                  <div className="cardd-title">Request a tour or call from business centers</div>
                  <div className="cardd-subtitle">If wish to find a workspace in any of the cities that Fluidmeet caters to, we are here to ensure that all your needs are fulfilled as best as possible. With us, all you have to do is simply check our listings, compare all the available options, and then finally choose the one that you believe can fulfill your requirements best.</div>
                </div>
              </div>

            </div>
          </section>


          <div className="section-divider2" />


          <section className="how-works">
            <div className="row mt-5">

              <div className="col-md-8 col-12 center">
                <div className="about-div">
                  <div className="cardd-title text-center">Why opt for a business center to set up your workspace</div>
                  <div className="cardd-subtitle mt-4">
                    Business centers are venues that offer their clients serviced offices and co-working spaces on monthly to annual terms, and meeting rooms on hourly/daily terms. A major benefit associated with opting for serviced offices and co-working spaces is that they come equipped with the paperwork necessary for licensing, furniture, Wi-Fi, water and electricity, receptionist, telephone line, printing & scanning services and other shared facilities depending on the business center. Such features make these workspaces perfect for individuals or companies looking to set up a business or branch of an already existent company. Also, the workspaces are situated in prime locations in the city, giving clients an easily accessible base of operations, complete with spectacular urban views.
                  </div>
                </div>
              </div>

            </div>
          </section>


          <section className="cities">
            <h1 className="section-title pb-4">Top cities</h1>
            <div className="row">
              <Cities />
            </div>
          </section>

        </div>
      </>
    );
  }
}
