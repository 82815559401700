import {devLog, getApiLinkV2, getToken, getUser, getUserToken, setHostStatus} from "src/Helpers";
import Bugsnag from "@bugsnag/js";
import axios from "axios";
import {getAxios} from "src/Helpers";
import {capitalizeFirst} from "src/components/host/host-helpers";

export interface HostUser {
    status : ('active'| 'pending' | 'disable' | 'admin') & string;
}


export interface FetchHostsResponse {
    users?:Array<any>;
    venues?:Array<any>;
    edit?:boolean;
    delete?:boolean;
    status?:string;
}

type MessageType = "success" | "danger";
export class Message {
    constructor(type: MessageType, title?: string, message?: string, description?: string, attachment?: any) {
        this.type = type;
        this.title = title;
        this.message = message;
        this.description = description;
        this.attachment = attachment;
    }

    type: MessageType = 'danger';
    title?: string;
    message?: string;
    description?: string;
    attachment?: any;
    getText = () => {
        return this.title + '' + this.message;
    }
    static fromJSON = ({type, title, message, description, attachment}: { type: MessageType, title?: string, message?: string, description?: string, attachment?: any }) => {
        return new Message(type, title, message, description, attachment);
    }
}
export interface FetchReservationsResponse {
    reservation;
    spaces;
    space;
    request;
    isPricing: boolean;
}

export const FetchHostDetailsResponseKeys: Array<keyof FetchHostDetailsResponse> = [//:Array<keyof FetchHostDetailsResponse>
    'host',
    'venues',
    'selectedVenues',
    'selectedPermissions',
]
export interface FetchHostDetailsResponse {
    host?: any;
    venues?: Array<any>;
    selectedVenues?: Array<any>;
    selectedPermissions?: any;
}

export interface HostUpdateRequest{
    fName;
    lName;
    email;
    phone;
    position;
    venues;
}
export class FetchSubscriptionResponse{
    public subscription:any;
    public venueAdd:any;
    public hostStatus:any;

    constructor(subscription?: any, venue_add?: any, host_status?: any) {
        this.subscription = subscription ?? null;
        this.venueAdd = venue_add ?? null;
        this.hostStatus = host_status ?? null;
    }
    static fromMap({subscription, venue_add, host_status}:any){
        new this(subscription??null,venue_add??null,host_status??null);
    }
}
export class HostAPI {

    static TAG = "HostAPI :: ";

    static fetchHosts: ()=>Promise<FetchHostsResponse|null> = async () => {

        let token = getToken()
        if (!token) throw Error('invalid-token');
        else {
            const url = getApiLinkV2("host/users");

            try {
                let response = await getAxios().get(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    },
                });

                // const { users, venues, edit } = response.data;
                return response.data as FetchHostsResponse;


            } catch (error: any) {
                Bugsnag.notify(error);
                console.error(`${HostAPI.TAG} fetchHosts - ${error.message}`);
                return null;
            }


        }

    }

    static toggleHostStatus : (id:String,currentStatus:String)=> Promise<Message> =
        async(id, currentStatus) => {
            return HostAPI.updateHostStatus(id,currentStatus === 'active' ? "disabled" : "active",)
        }
    static updateHostStatus : (id:String,status:String)=> Promise<Message> =
        async(id, status) => {

        let token = getToken()

        const data = {
                option: 'status',
                status: status,
        }

        const url = getApiLinkV2("host/users/"+id);

            try {
                await getAxios().put(url, data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    },
                })
                return Message.fromJSON({
                    type: "success", message: 'host status has been updated' +
                        ' successfully', description: ''
                })
            } catch (error: any) {
                devLog(error);
                Bugsnag.notify(new Error('Error Catched in axios ' + JSON.stringify(error)));
                console.error(`${HostAPI.TAG} UpdateHostStatus - ${error.message}`);
                return Message.fromJSON({
                    type: "danger",
                    message: 'host status failed to update',
                    description: error.message
                })
            }


    }

    static deleteHost : (id:String) => Promise<Message> =
        async(id) => {
        let token = getToken()
        const url = getApiLinkV2("host/users/"+id);

        try{
            await getAxios().delete(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+token,
                },
            })
            return Message.fromJSON({type: "success", message: 'host has been deleted successfully', description: ''});
        } catch (error: any) {

            devLog(error);
            Bugsnag.notify(new Error('Error Catched in axios ' + JSON.stringify(error)));
            return Message.fromJSON({type: "danger", message: 'host failed to be deleted', description: error.message})
        }

    }

    static fetchHostDetails  = async(id:string) => {

        if (!id) return null;

        let token = getToken()
        if (!token) return null;

        const url = getApiLinkV2("host/users/"+id);

        try{

        let response = await getAxios().get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+token,
                },
            });


        if (response.data.user) {

                    const host = response.data.user;
                    const venues = response.data.venues ? response.data.venues : [];
                    const user_venues = response.data.user_venues ? response.data.user_venues : [];
                    const selectedPermissions = response.data.permissions ? response.data.permissions : {};

                    let selectedVenues = [];

                    //Finding Common Venues between this host and the managing host
                    if (user_venues) {
                        let user_venues_ids = user_venues.map((item:any) => item.venue_details_id)
                        selectedVenues = venues.filter((venue:any) => user_venues_ids.includes(venue.venue_details_id))
                        if (selectedVenues.length === 0 && host.status === 'admin') {
                            selectedVenues = venues
                        }
                    }

                    if (host.status === 'admin') {

                        venues.forEach(item => {
                            if (selectedPermissions[item.venue_details_id] == null) {
                                selectedPermissions[item.venue_details_id] = {
                                    "ST": ["view", "emails", "authorize"],
                                    "LT": ["view", "emails", "edit"],
                                    "DDR": ["view", "emails", "pricing"],
                                    "venue": ["view", "edit"],
                                    "workspace": ["view", "add", "edit", "delete"],
                                    "finance": ["view", "emails"],
                                };
                            }
                        });

                        if (!selectedPermissions.other || selectedPermissions.other.length === 0) {
                            selectedPermissions['other'] = {
                                "venue": ["add"], "user": ["view"],
                            };
                        }
                    }
            return {
                host,
                venues,
                selectedVenues,
                selectedPermissions
            } as FetchHostDetailsResponse;
        } else return {host: null, venues: [], selectedPermissions: [], selectedVenues: []} as FetchHostDetailsResponse;
        } catch (error: any) {
            Bugsnag.notify(error);
            devLog("hostApi-fetch-host-details", error);
            return {host: null, venues: [], selectedPermissions: [], selectedVenues: []} as FetchHostDetailsResponse;
        }

    }

    private static formHostDataMap = (user,request:HostUpdateRequest,selectedPermissions,selectedVenues)=>{
        user = user ?? getUser();
        if(!user) return;

        const otherPermissions = selectedPermissions['other'] != null ? selectedPermissions['other'] : {};
        let venuePermissions = {};

        if (selectedPermissions['other'] != null) delete selectedPermissions['other'];
        venuePermissions = selectedPermissions;

        return {
            option: 'update',
            host_id: user.id,
            first_name: request.fName,
            last_name: request.lName,
            venue_name: selectedVenues,
            phone: request.phone,
            email: request.email,
            position: request.position,
            other: otherPermissions,
            venue: venuePermissions,
        }
    }

    static updateHostDetails = async(request:HostUpdateRequest,host,selectedPermissions,selectedVenues) =>{

            let { token, user } = getUserToken()
            if (!user) return;
            if (!token) return;

            const data = HostAPI.formHostDataMap(user,request,selectedPermissions,selectedVenues);

                if (host) {
                    const url = getApiLinkV2("host/users/"+host.id);
                    try {
                        await getAxios().put(url, data, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + token,
                            },
                        })

                        return {
                            type: "success",
                            message: 'host has been updated successfully',
                            description: ''
                        } as Message;

                    } catch (error: any) {
                        devLog(`${HostAPI.TAG} updateHostDetails - ${error}`);
                        Bugsnag.notify(new Error('Error Catched in axios ' + JSON.stringify(error)));
                        return {
                            type: "danger",
                            message: "Failed to update host Details",
                            description: error.message
                        } as Message;
                    }


                }


}
    static addNewHost = async(request:HostUpdateRequest,selectedPermissions,selectedVenues) =>{

            let { token, user } = getUserToken()
            if (!user) return;
            if (!token) return;
            const data = HostAPI.formHostDataMap(user,request,selectedPermissions,selectedVenues);

                    const url = getApiLinkV2("host/users");
                    try {
                        let response = await getAxios().post(url, data, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + token,
                            },
                        })
                        devLog(response.data);
                        devLog(`${HostAPI.TAG} updateHostDetails - ${response.data}`);
                        return {
                            type: "success",
                            message: 'host has been updated successfully',
                            description: ''
                        } as Message;

                    } catch (error: any) {
                        devLog(`${HostAPI.TAG} updateHostDetails - ${error}`);
                        Bugsnag.notify(new Error('Error Catched in axios ' + JSON.stringify(error)));
                        return {type: "danger", message: 'host failed to be updated', description: ''} as Message;
                    }



}

    static fetchSubscription = async() => {

        let {user,token} = await getUserToken()

            if (!token) return;
            if (!user) return;

            try{
                devLog("Subscription", user)

            const url = getApiLinkV2("host/subscription/"+user.id);

            let response = await getAxios().get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+token,
                },
            })
            devLog("Subscription::response",{response})

                const subscription = response.data;
                const venue_add = Boolean(subscription.venue_add)
                console.log({subscription})
                let host_status = 'active'
                if (subscription?.details?.status === 'expired')
                    host_status = 'expired'
                else if (subscription?.deactivated_venues || subscription?.host_status !== 'yes')
                    host_status = 'deactivated'
                // else 'active'

                setHostStatus(host_status);


                return new FetchSubscriptionResponse(subscription, venue_add, host_status);
            } catch (error: any) {
                devLog("Subscription::error",{error})
                Bugsnag.notify(error);
                return new FetchSubscriptionResponse();
            }

    }

    static getSubscriptionContract = async () => {

        let {user, token} = await getUserToken()

        if (!token) return;
        if (!user) return;
        devLog("Subscription", user)

        try {
            devLog("Subscription Try")
            devLog("Subscription :: token", {token})
            // /api/v2/host/contract/view?id=839&type=host
            // const url = getApiLink(`host/contract/view?id=${subID}&type=host`);
            const url = getApiLinkV2(`subscription?id=${user.id}&type=host`);

            let response = await getAxios().get(url, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    // "Content-Type": "application/x-www-form-urlencoded",
                    "Content-Type": "application/json",
                }
            })
            // devLog(response)
            return new Message('success', "Success", "Fetched Contract successfully", undefined, response);

        } catch (error: any) {
            Bugsnag.notify(error);
            // console.error(error)
            return new Message('danger', "Error", error.response?.data?.message ?? error.message, undefined, error);
        }

    }

    static acceptSubscriptionContract = async (subID) => {

        let {user, token} = await getUserToken()

        if (!token) return;
        if (!user) return;


        try {

            const url = getApiLinkV2(`subscription`);

            let response = await getAxios().post(url, {
                status: 'accept',
                id: subID
            }, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    "Content-Type": "application/json",
                }
            })
            devLog(response)
            return new Message('success', "Success", "Accepted Contract successfully", undefined, response.data);

        } catch (error: any) {
            Bugsnag.notify(error);
            console.error(error)
            return new Message('danger', "Error", error.response?.data?.message ?? error.message, undefined, error);
        }

    }

    static respondToHostInvite = async (id, tkn, status: 'reject' | 'accept') => {
        // let token = getToken();
        // let user = getUserData()

        // if (!token) return;
        // if (!user) return;
        try {

            const url = getApiLinkV2(`host/change-status`);
            let response = await getAxios().post(url, {
                status,
                "id": id,
                "tkn": tkn,
            }, {
                headers: {
                    // 'Authorization': 'Bearer ' + token,
                    "Content-Type": "application/json",
                }
            })
            devLog(response)
            return new Message('success', "Success", (capitalizeFirst(status)) + "ed successfully", undefined, response.data);

        } catch (error: any) {
            Bugsnag.notify(error);
            console.error(error)
            return new Message('danger', "Error", error.response?.data?.message ?? error.message, undefined, error);
        }
    }

    static fetchProposalReservation = async (id) => {


        let token = getToken()
        if (!token) return null;

        const url = getApiLinkV2("host/reservations/" + id);
        try {
            let response = await getAxios().get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+token,
                },
                params: {
                    type: 'proposal',
                },
            })

            devLog(`${HostAPI.TAG} fetchReservation - ${response.data}`);

        const { reservation, spaces, space } = response.data;
            if (reservation) {
                let request: any = {};
                request.booking_status = reservation.booking_status ? parseInt(reservation.booking_status) : null;
                reservation.booking_status = request.booking_status;


                return {
                    reservation,
                    spaces,
                    space,
                    request,
                    isPricing: (reservation.amount_due <= 0)
                } as FetchReservationsResponse;
            } else {
                return {reservation: null, spaces: [], space: null} as FetchReservationsResponse;
            }
        } catch (error: any) {
            devLog(error);
            setTimeout(() => {
                Bugsnag.notify(error);
            }, 500)
            return {reservation: null, spaces: [], space: null} as FetchReservationsResponse;
        }
    }

    static proposalReservationUpdateStatus = async(request, reservation) => {

        if (reservation.booking_status === request.booking_status || request.booking_status == null) {
            return null;
        }

        let token = getToken()
            if (!token) return null ;

            const url = "https://host-dev.fluidmeet.com/api/v2/host/reservations/" +
                reservation.quote_id;
            try{
            let response = await getAxios().put(url, {
                reservation_type: 'proposal',
                option: 'status',
                status: request.booking_status,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            })
                devLog(`${HostAPI.TAG} - proposalReservationUpdateStatus -${response.data.message}`);
                return {
                    type: "success",
                    message: `Succeeded in Updating Status to ${request.booking_status}`,
                    description: response.data.message
                } as Message;
            } catch (error: any) {
                Bugsnag.notify(error);
                console.error(`${HostAPI.TAG} - proposalReservationUpdateStatus -${error}`)
                return {
                    type: "danger",
                    message: `Failed to Updating Status to ${request.booking_status}`,
                    description: error.message
                } as Message;

            }

    }

    static proposalReservationMoreSubmit = async (request,reservation, showMore ) => {

        let token = getToken()

            if (!token) return;

            const url = getApiLinkV2("host/reservations/" + reservation.quote_id);
        try{
            let response = await getAxios().put(url, {
                reservation_type: 'proposal',
                option: showMore,
                status: request.status,
                email: request.email,
                note: request.note,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            })

            return {type: "success" , message: `Succeeded proposalReservationMoreSubmit` ,description: response.data.message} as Message;
            } catch (error: any) {
            Bugsnag.notify(error);
            devLog(error);
            return {
                type: "danger",
                message: `Failed proposalReservationMoreSubmit`,
                description: error.message
            } as Message;
        }

    }

    static proposalReservationSubmit = async(data,reservation) => {

        let token = getToken()
        if (!token) return;

        const url = getApiLinkV2("host/reservations/" + reservation.quote_id);

        try {
            let response = await getAxios().put(url, data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    },
                }
            )
            return {
                type: "success",
                message: `Succeeded proposalReservationSubmit`,
                description: response.data.message
            } as Message;

        } catch (error: any) {
            Bugsnag.notify(error);
            devLog(error);
            return {type: "danger", message: `Failed proposalReservationSubmit`, description: error.message} as Message;
        }

    }

    fetchBookingReservation = async(route) =>
    {

        let token = getToken()
        if (!token) return;

            const url = getApiLinkV2("host/reservations/" + route.params.id);

            try{
                let response = await getAxios().get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+token,
                },
                    params: {
                        type: 'booking'
                    },
                })

                if (response.data.reservation) {
                    const reservation = response.data.reservation;
                    const space = response.data.space;
                    return {reservation, space};
                }
            } catch (error: any) {
                devLog(error);
                setTimeout(() => {
                    Bugsnag.notify(error);
                }, 500)
                return;
            }
    }

    static fetchEnableVenuesForManualBookings = async () => {
        let token = getToken()
        if (!token) return;

        const url = getApiLinkV2("host/manualBookings");

        try{
            let response = await getAxios().get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token,
            }
            });

            if(response.data.venues) {
                return response.data.venues;
            }

            return [];
        } catch (error: any) {
            devLog(error);
            setTimeout(() => {
                Bugsnag.notify(error);
            }, 500)
            return;
        }
    }

    static fetchReservationByWorkspace = async (wsId: any) => {
        let token = getToken()
        if (!token) return;

        const url = getApiLinkV2("host/bookings/" + wsId);

        try{
            let response = await getAxios().get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token,
            }
            });

            if(response.data.bookings) {
                return response.data.bookings;
            }

            return [];
        } catch (error: any) {
            devLog(error);
            setTimeout(() => {
                Bugsnag.notify(error);
            }, 500)
            return;
        }
    }

    static createEvent = async (data: any) => {
        let token = getToken()
        if (!token) return;

        const url = getApiLinkV2("host/bookings");

        try{
            let response = await getAxios().post(url, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token,
            }}
            );
            
            return response.data;
        } catch (error: any) {
            devLog(error);
            setTimeout(() => {
                Bugsnag.notify(error);
            }, 500)
            return;
        }
    }

    static updateEvent = async (id:any, data:any) => {
        let token = getToken()
        if (!token) return;

        const url = getApiLinkV2("host/bookings/" + id);

        try{
            let response = await getAxios().put(url, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token,
            }}
            );
            
            return response.data;
        } catch (error: any) {
            devLog(error);
            setTimeout(() => {
                Bugsnag.notify(error);
            }, 500)
            return;
        }
    }

    static uploadImport = async (data: any) => {
        let token = getToken()
        if (!token) return;

        const url = getApiLinkV2("host/bookings-import");

        try{
            let response = await getAxios().post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+token,
            }}
            );
            
            return response.data;
        } catch (error: any) {
            devLog(error);
            setTimeout(() => {
                Bugsnag.notify(error);
            }, 500)
            return;
        }
    }

}
