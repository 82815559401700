
	export type Discount = {
		type: 'absolute' | 'percentage',
		value: number,
		one_time: boolean,
	}

	export class ShortTermPricingModel {

		space_price: number
		duration: number
		discountData: Discount
		transaction_fee_percentage: number
		VAT_percentage: number
		fm_percentage: number
		recurring_count: number

		/*
		* @desc returns a short-term pricing model
		* */
		constructor(space_price, discountData: Discount, transaction_fee_percentage, vat_percentage, fm_percentage, duration = 1, recurring_count = 1) {
			this.space_price = space_price
			this.discountData = discountData
			this.transaction_fee_percentage = transaction_fee_percentage / 100
			this.VAT_percentage = vat_percentage / 100
			this.fm_percentage = fm_percentage / 100
			this.recurring_count = recurring_count || 1
			this.duration = duration || 1
		}

		get subtotal(): number {
			return this.space_price * this.recurring_count * this.duration;
		}

		get discount(): number {
			let {one_time, type, value} = this.discountData
			let discount_multiplier = (one_time) ? 1 : this.recurring_count;
			let discount_percentage_divider = (one_time) ? this.recurring_count : 1;
			switch (type) {
				case "absolute":
					return (value * discount_multiplier);
				case "percentage":
					return this.subtotal * value / (100 * discount_percentage_divider);
			}

		}

		get price_after_discount(): number {
			let price_after_discount = this.subtotal - this.discount;
			if (price_after_discount < 3) {
				price_after_discount = 3; // minimum charge
			}
			return price_after_discount;
		}

		get VAT(): number {
			return this.price_after_discount * this.VAT_percentage;
		}

		get price_inc_VAT(): number {
			return this.price_after_discount + this.VAT;
		}

		get transaction_fee(): number {
			return this.price_inc_VAT * this.transaction_fee_percentage;
		}

		get final_price(): number {
			return this.price_inc_VAT + this.transaction_fee;
		}

		get amount_charged(): number {
			return this.final_price;
		}

		get venue_VAT(): number {
			return this.VAT * (1 - this.fm_percentage);
		}

		get owed_to_venue_inc_VAT(): number {
			return this.price_inc_VAT * (1 - this.fm_percentage);
		}

		get fm_VAT(): number {
			return (this.VAT * this.fm_percentage) + (this.transaction_fee * (this.VAT_percentage / (1 + this.VAT_percentage)));
		}

		get owed_to_fm_inc_VAT(): number {
			return this.amount_charged - this.owed_to_venue_inc_VAT;
		}
	}


