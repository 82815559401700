import React, {useState} from "react";

export function updater<S>(state: S, setter: React.Dispatch<React.SetStateAction<S>>) {
	return (added: any) => {
		setter({...state, ...added})
	}
}

export function useUpdateReducer<T>(initialState: T | (()=>T)) {

	const [state, setState] = useState<T>(initialState);

	function updateState(updated?: any) {
		setState((prevState) => ({...prevState, ...updated}))
	}

	return ([state, setState, updateState]) as UpdateReducerTuple<T>

}

type UpdateReducerTuple<T> = [T, React.Dispatch<React.SetStateAction<T>>, (updated: T) => void]

// export function useUpdateArrayReducer<T extends []>(initialState:T){
//
// 	const [state, setState] = useState<T>(initialState);
//
// 	function addToArray(updated:T){
// 		setState([...state,...updated])
// 	}
// 	function updateState(updated:T){
// 		setState([...state,...updated])
// 	}
// 	return ( [state,setState,updateState] ) as UpdateReducerTuple<T>
//
// }
