import React, {ReactElement} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Stepper, {Orientation} from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
		},
		button: {
			marginTop: theme.spacing(1),
			marginRight: theme.spacing(1),
		},
		actionsContainer: {
			marginBottom: theme.spacing(2),
		},
		resetContainer: {
			padding: theme.spacing(3),
		},
	}),
);

export type StepperComponentGenerator = (activeStep: number, setActiveStep: React.Dispatch<React.SetStateAction<number>>) => ReactElement

export type StepPage = {
	key: string
	label: string
	page: StepperComponentGenerator;
}
export type LinearStepperActionCallback = (activeStep: number, setActiveStep: React.Dispatch<React.SetStateAction<number>>) => void;

export type LinearStepperProps = {
	steps: Array<StepPage>,
	footer?: StepperComponentGenerator | 'default',
	success?: StepperComponentGenerator | 'default',
	orientation?: Orientation,
	onDefaultFooterNext?: LinearStepperActionCallback,
	onDefaultFooterBack?: LinearStepperActionCallback,
}
export default function LinearStepper({steps, footer, success, orientation = 'vertical', onDefaultFooterNext, onDefaultFooterBack}: LinearStepperProps) {
	const classes = useStyles();
	const [activeStep, setActiveStep] = React.useState(0);

	const handleNext = () => {
		if (onDefaultFooterNext)
			onDefaultFooterNext(activeStep, setActiveStep)
		else
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		if (onDefaultFooterBack)
			onDefaultFooterBack(activeStep, setActiveStep)
		else
			setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	return (
		<div className={classes.root}>
			<Stepper activeStep={activeStep} orientation={orientation} className={clsx('bg-transparent')}>
				{steps.map(({label, key, page}, index) => (
					<Step key={key}>
						<StepLabel>{label}</StepLabel>
						<StepContent>
							{page(activeStep, setActiveStep)}
							<div className={classes.actionsContainer}>
								{
									Boolean(footer) &&
									(typeof footer !== "string") ?
										footer?.(activeStep, setActiveStep)
										:
										(footer === "default" &&
																					<div>
																						<Button
																							disabled={activeStep === 0}
																							onClick={handleBack}
																							className={classes.button}
																						>
																							Back
																						</Button>
																						<Button
																							variant="contained"
																							color="primary"
																							onClick={handleNext}
																							className={classes.button}
																						>
												{activeStep === steps.length - 1 ? 'Finish' : 'Next'}
																						</Button>
																					</div>
										)
								}
							</div>
						</StepContent>
					</Step>
				))}
			</Stepper>
			{
				Boolean(success) && activeStep === steps.length &&
				(typeof success !== "string") ?
					success?.(activeStep, setActiveStep)
					:
					(success === "default" &&
											<Paper square elevation={0} className={classes.resetContainer}>
												<Typography>All steps completed - you&apos;re finished</Typography>
												<Button onClick={handleReset} className={classes.button}>
													Reset
												</Button>
											</Paper>
					)
			}
		</div>
	);
}

// export function StepperDefaultFooter ({backText,nextText,backCallback,nextCallback,}){
// 	const classes = useStyles();
// 	return <div>
// 		<Button
// 			disabled={activeStep === 0}
// 			onClick={handleBack}
// 			className={classes.button}
// 		>
// 			Back
// 		</Button>
// 		<Button
// 			variant="contained"
// 			color="primary"
// 			onClick={handleNext}
// 			className={classes.button}
// 		>
// 			{activeStep === steps.length - 1 ? 'Finish' : 'Next'}
// 		</Button>
// 	</div>
// }
