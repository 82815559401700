import React, {ChangeEvent, createRef, MouseEventHandler, useCallback, useEffect, useRef, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core/styles";
import {blue, blueGrey} from "@material-ui/core/colors";
import {RouteComponentProps, useHistory, useParams} from "react-router-dom";
import {HostDashboardRoutePrams} from "src/components/host/host-dashboard/host-dashboard";
import clsx from 'clsx';
import {createStyles} from "@material-ui/styles";
import LinearStepper, {
	LinearStepperActionCallback,
	StepPage,
	StepperComponentGenerator
} from "src/components/host/host-dashboard/manage-listings/add-edit-venues/VerticalLinearStepper";
import {useUpdateReducer} from "src/hooks/useUpdateReducer";
import {useStateLogger} from "src/hooks/useStateLogger";

import {convert, devLog, formValidation, getApiLinkV2, getCurrencies, getToken, printPriceOnly} from "src/Helpers";
import AlertSnackbar from "src/components/organisms/snackbar/AlertSnackBar";
import {FMField, FMFieldGenerator, FMTextInput} from "src/components/host/forms/fields";
import {
	CATERED,
	SUMMER_CATERED,
	WINTER_CATERED,
	CATERED_AMENITIES,
	DAYS,
	HOURLY,
	LAYOUTS,
	LICENCING_TERRITORY,
	MONTHLY,
	OFFICE_AMENITIES,
	SUITABLE,
	TERMS,
	TYPES
} from "src/components/host/host-dashboard/manage-listings/add-edit-workspaces/consts";
import {FileImage} from "src/components/host/forms/file-image";
import {RemoveSVG} from "src/img/svg-exports";
import axios from "axios";
import {getAxios} from "src/Helpers";
import Bugsnag from "@bugsnag/js";
import FilesAPI from "src/APIs/FilesAPI";
import {CircularProgress} from "@material-ui/core";
import {VenueAPI} from "src/APIs/venue/VenueAPI";
import {Message} from "src/APIs/HostAPI";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AlertDialog from "src/components/organisms/alert-dialog/AlertDialog";


const useStyles = makeStyles(
	(theme: Theme) => createStyles(
		{
			root: {
				maxWidth: '80%',
			},
			media: {
				height: 0,
				paddingTop: '56.25%', // 16:9
			},
			expand: {
				transform: 'rotate(0deg)',
				marginLeft: 'auto',
				transition: theme.transitions.create('transform', {
					duration: theme.transitions.duration.shortest,
				}),
			},
			expandOpen: {
				transform: 'rotate(180deg)',
			},
			avatar: {
				backgroundColor: blueGrey[300],
			},
			count: {
				borderRadius: '40%',
				padding: '.2rem 0.5rem',
				fontSize: '.8rem',
				backgroundColor: blue[300],
				verticalAlign: 'middle',
			},
			title: {
				verticalAlign: 'middle',
			},
			note: {
				color: '#464646',
				paddingTop: 10,
				fontSize: 16,
			},
			iconButton: {
				marginLeft: 'auto',
				alignSelf: 'middle',

			},
			sectionTitle: {
				fontSize: 22,
				fontWeight: 600,
			},
			btn: {
				flex: 0,
				flexGrow: 0,
				textAlign: 'right' as const,
				color: 'black',
				fontSize: 17,
				fontWeight: 400,
				textDecorationLine: 'underline',
			},
			spaceLgImage: {
				height: "15rem",
				objectFit: 'cover' as const,
				borderRadius: 15,
				marginBottom: 10,
			},
			cardView: {
				position: 'relative',
			},
			spaceLgRemove: {
				position: 'absolute' as const,
				borderRadius: 18,
				width: 36,
				height: 36,
				right: 18,
				top: 9,
				zIndex: 10,
			},
		}
	)
)

export type EditWorkspaceProps = {} & RouteComponentProps<HostDashboardRoutePrams>

EditWorkspace.key = 'edit-workspace'
EditWorkspace.route = `/${EditWorkspace.key}/:venueID/:id?`


export function EditWorkspace({}: EditWorkspaceProps) {

	const classes = useStyles();

	const history = useHistory();

	const {id, venueID} = useParams<{ id: string, venueID: string }>();

	const [errors, setErrors, updateErrors] = useUpdateReducer<any>({});
	const [request, setRequest, updateRequest] = useUpdateReducer<any>({});

	const [formsSubmitted, setFormsSubmitted] = useState<any>(Array(4).fill(false));
	const [formsRefs, setFormsRefs] = React.useState(Array(4).fill(null).map(() => createRef<HTMLFormElement>()));
	const [currencies, setCurrencies] = useState<any>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [submissionState, setSubmissionState] = useState<any>({loading: true, state: null});
	const [venueCurrency, setVenueCurrency] = useState<any>();

	const [venue, setVenue] = useState<any>();
	const [amenitiesRef, setAmenitiesRef] = useState<any>();

	const snackbar = useRef<AlertSnackbar>(null);
	const dialog = useRef<AlertDialog>(null);

	const addImagesRef = useRef<HTMLInputElement>(null);
	const addPanoRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		const countries = getCurrencies().map(obj => obj.country);
		if(venue) {
			if(venue.country && countries.includes(venue.country)) {
				let currency = currencies.find(c => c.country == venue.country);
				setRequest(R => {return { ...R, currency: currency?.name }});
				setVenueCurrency(currency);
			}
			else {
				setRequest(R => {return { ...R, currency: "USD"}});
				setVenueCurrency({
					name: 'USD',
					icon: '$',
					exchange_rate: 1,
					country: 'United States'
				});
			}
		}
		else {
			setRequest(R => {return { ...R, currency: "USD"}});
			setVenueCurrency({
				name: 'USD',
				icon: '$',
				exchange_rate: 1,
				country: 'United States'
			});
		}
	}, [venue]);

	useStateLogger(errors, "Errors-State::")
	useStateLogger(request, "Request-State::")
	// useStateLogger(venue, "Request-State::")

	useEffect(() => {
		let currencies = getCurrencies();
		setCurrencies(currencies);
		if (request && request.currency) {
			// currency: currencies.find(x => x.name == space.currency) }
		}
		devLog("************", venueID, id)
		if (venueID) {
			VenueAPI.fetchVenue(venueID).then(
				response => {
					if (response) {
						setVenue(response.venue)
						if (id) {
							fetchSpace(id, response.venue)
						} else {
							setLoading(false)
						}
					} else history.goBack();
				}
			).catch(error => {
				history.goBack();
			})

		} else {
			history.goBack();
		}
	}, [])


	const fetchSpace = (id: string, venue: any) => {
		devLog("fetchSpace", id);

		let currencies = getCurrencies()
		/*setCurrencies(currencies)
		if (request && request.currency) {
			currency: currencies.find(x => x.name == space.currency) }
		}*/
		devLog("fetchSpace::currencies", currencies);

		let venueDefaultCurrency = venue.country ? currencies.find(c => c.country == venue.country) : {
			name: 'USD',
			icon: '$',
			exchange_rate: 1,
			country: 'United States'
		};

		let token = getToken()
		devLog("fetchSpace::token", token);
		if (!token) return;

		const url = getApiLinkV2("host/workspace/" + id);

		getAxios().get(url, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token,
			},
		}).then(response => {

			if (response.data.workspace) {

				let suitable: any[] = [];
				const amenities: any[] = [];
				const amenitiesBool: any[] = [];
				const days: any[] = [];
				const daysBool: any[] = [];
				let layouts: any[] = [];
				let capacity: any = null;
				const hourly: any[] = [];
				const monthly: any[] = [];
				const catered: any[] = [];
				const summerCatered: any[] = [];
				const winterCatered: any[] = [];
				const data = response.data;
				const space = response.data.workspace;

				if (data.amenities && data.amenities.length > 0)
					data.amenities.forEach((item: any) => {
						amenities.push(item.amenity)
						if (item.fee !== 'no') amenitiesBool.push(item.amenity)
					});

				if (data.suitable && data.suitable.length > 0)
					suitable = data.suitable

				LAYOUTS.forEach(item => {
					if (space[item.id + '_chk']) {
						layouts.push(item.id)
						request[`layouts-${item.id}-textInput`] = space[item.id + '_cap'];
					}

				});

				HOURLY.forEach(item => {
					if (space[item.value + '_pass']) {
						hourly.push(item.value)
						request[`hourly-${item.value}-textInput`] = printPriceOnly(space[item.value + '_price'], space.currency, venueDefaultCurrency.name, true);
					}

				});

				MONTHLY.forEach(item => {
					if (space[item.value + '_pass']) {
						monthly.push(item.value)
						request[`monthly-${item.value}-textInput`] = printPriceOnly(space[item.value + '_price'], space.currency, venueDefaultCurrency.name, true);
					}
				});

				CATERED.forEach(item => {
					if (space[item.value + '_pass']) {
						catered.push(item.value)
						request[`catered-${item.value}-textInput`] = printPriceOnly(space[item.value + '_price'], space.currency, venueDefaultCurrency.name, true);
					}
				});

				SUMMER_CATERED.forEach(item => {
					if (space[item.value] && space[item.value] > 0) {
						summerCatered.push(item.value)
						request[`summer_catered-${item.value}-textInput`] = printPriceOnly(space[item.value], space.currency, venueDefaultCurrency.name, true);
					}
				});

				WINTER_CATERED.forEach(item => {
					if (space[item.value] && space[item.value] > 0) {
						winterCatered.push(item.value)
						request[`winter_catered-${item.value}-textInput`] = printPriceOnly(space[item.value], space.currency, venueDefaultCurrency.name, true);
					}
				});

				if (space.exception_rules && space.exception_day1 !== '') {

					const exception_prices: any[] = [];
					HOURLY.forEach(item => {
						const price = space['exception_' + item.value + '_price1'];
						if (price && price != '0.00' && price != 0) {
							request[`exception_prices-${item.value}-textInput`] = printPriceOnly(price, space.currency, venueDefaultCurrency.name, true);
							exception_prices.push(item.value)
						}
					});
					request.exception_pass = true;
					request.exception_day = space.exception_day1;
					request.exception_prices = exception_prices;
				}

				DAYS.forEach(day => {
					const availability = data.availabilities[day.value];
					if (availability && availability != 'closed') {
						days.push(day.value)
						if (availability === 'open_24_hours') {
							daysBool.push(day.value)
						} else {
							request[`${day.value}-start`] = availability.substr(0, 5);
							request[`${day.value}-end`] = availability.substr(9, 5);
						}
					}
					// else {
					// 	availabilities.push({ day: day.label, value: day.value, startTime: null, endTime: null, active: false });
					// }
				});

				request.suitable = suitable;
				request.amenities = amenities;
				request['amenities-bool'] = amenitiesBool;
				request.days = days;
				request['days-bool'] = daysBool;
				request.layouts = layouts;
				request.hourly = hourly;
				request.monthly = monthly;
				request.catered = catered;
				request.summer_catered = summerCatered;
				request.winter_catered = winterCatered;
				request.automate_pricing = response.data.workspace.automate_pricing == "1";

				//todo
				if (space.image_360 && space.image_360 != '') {
					request.image_360 = {
						name: space.image_360,
						url: 'https://fluidmeet.s3.amazonaws.com/venue_logos/360/' + space.image_360 + '/preview.jpg'
					};
					request.pano = space.image_360
				}


				request.images = data.images ?? []

				request.name = space.name;
				request.type = space.workspace_type_id;
				request.size = space.size;
				request.term = space.term;
				request.security_deposit = printPriceOnly(space.security_deposit, space.currency, venueDefaultCurrency.name, true);
				request.description = space.description;
				//request.currency = space.currency;
				request.currency = venueDefaultCurrency.name;
				request.capacity = space.capacity + '';
				request.licencing = space.licencing;
				request.licencing_territory = space.licencing_territory;
				request.min_booking_hrs = space.min_booking_hrs;

				setAmenitiesRef(data.amenities)
				setRequest(request)
				devLog("fetchSpace::request", request);
				devLog("fetchSpace::data", response.data);

				// this.setState({ space, request});
			}

		}).catch(error => {
			devLog(error);
			Bugsnag.notify(error);
			history.goBack();

		}).finally(() => {
			setLoading(false);
		});

	}

	// useEffect(() =>{
	// 	if (request.images?.length <=0)
	// 		updateErrors({images: 'Please upload images'})
	// 	else{
	// 		updateErrors({images: null})
	// 	}
	// },[request]);


	const showMissingDataWarning = () => {
		window.scrollTo(0, 0)
		snackbar.current?.setMessage("Re-Check Input Data")
		snackbar.current?.setSeverity("warning")
		snackbar.current?.open()
	}
	const getFormFields = (formData:FormData)=>{
		const type = formData.get('type') ?? request.type;
		const isOffice = (type === '4' || type === '9') ;
		const hourly = formData.getAll('hourly');
		const monthly = formData.getAll('monthly');
		const catered = formData.getAll('catered');
		const summer_catered = formData.getAll('summer_catered');
		const winter_catered = formData.getAll('winter_catered');

		const hasHourly = (hourly && hourly.length > 0);

		const _basicInfoFields: any[] = [
			{name: 'type'},
			{name: 'name'},
			{name: 'size'},
			{name: 'description'},
			{name: 'amenities', array: true,},
			{name: 'amenities-bool', array: true, required: false},
			{name: 'suitable', array: true, required: !isOffice},

		]

		if (isOffice) _basicInfoFields.push(
			{name: 'licensing', required: false},
			{name: 'licencing_territory'},
			{name: 'capacity'},
		)
		else {
			_basicInfoFields.push(
				{name: 'layouts', array: true},
			)
		}


		const _pricesFields: any[] = [
			/*{
				name: 'currency',
			},*/
			{
				array: true,
				name: 'hourly',
				required: !Boolean(catered?.length || monthly?.length),
			},
			{
				array: true,
				name: 'summer_catered',
				required: summer_catered.length > 0 ? true : false,
			},
			{
				array: true,
				name: 'winter_catered',
				required: winter_catered.length > 0 ? true : false,
			}
		];


		if (hasHourly) {
			_pricesFields.push(
				{
					name: 'exception_pass',
					required: false,
				}
			);

		}


		if (isOffice) {
			_pricesFields.push(
				{
					array: true,
					name: 'monthly',
					required: !Boolean(hourly?.length || catered?.length),
				},
				{
					name: 'term',
				},
				{
					name: 'security_deposit',
				},
			)
		} else {
			_pricesFields.push(
				{
					array: true,
					name: 'catered',
					required: !Boolean(hourly?.length || monthly?.length),
				}
			)
		}


		const fields = [
			_basicInfoFields,
			[],
			[
				{name: 'min_booking_hrs'},
				{name: 'days', array: true},
				{name: 'days-bool', array: true, required: false},
			],
			_pricesFields,

		]
		return fields
	}
	const handleFormChanged = (activeStep: number) => {

		if (formsRefs[activeStep]?.current) {
			// devLog("formsRef", activeStep)
			const errorsIds: any[] = []
			const _request: any = {}
			const data = new FormData(formsRefs[activeStep].current!);
			const fields = getFormFields(data);
			// devLog("data", Array.from(data.entries()))

			fields[activeStep].forEach(({name, array, required = true}: any) => {
				if (array) {
					let arr = data.getAll(name);
					_request[name] = (arr.length > 0) ? arr : null;
				} else _request[name] = data.get(name);

				// devLog(name, _request[name])
				if (required) errorsIds.push(name)
			})
			if (activeStep === 0) {
				[
					...LAYOUTS.map(
						(item) => (
							{
								name: `layouts-${item.id}-textInput`,
								required: (_request['layouts']?.includes(item.id) ?? false),
							}
						)
					)
				].forEach(({name, array, required = true}: any) => {
					if (array) {
						let arr = data.getAll(name);
						_request[name] = (arr.length > 0) ? arr : null;
					} else {
						_request[name] = data.get(name);
					}
					if (required) errorsIds.push(name)
				})
			}

			if (activeStep === 2) {
				[
					...DAYS.map(
						(item) => (
							{
								name: item.value + '-start',
								required: ((_request['days']?.includes(item.value) ?? false) && (!_request['days-bool']?.includes(item.value) ?? false))
							}
						)
					),
					...DAYS.map(
						(item) => (
							{
								name: item.value + '-end',
								required: ((_request['days']?.includes(item.value) ?? false) && (!_request['days-bool']?.includes(item.value) ?? false))
							}
						)
					),
				].forEach(({name, array, required = true}: any) => {
					if (array) {
						let arr = data.getAll(name);
						_request[name] = (arr.length > 0) ? arr : null;
					} else {
						_request[name] = data.get(name);
					}
					if (required) errorsIds.push(name)
				})
			}

			if (activeStep === 3) {
				let hasAmountsFilled :boolean[]= [];
				let moneyFieldsErrorIDs :any[]= [];
				[
					...HOURLY.map(
						(item) => (
							{
								name: `hourly-${item.value}-textInput`,
								required: (_request['hourly']?.includes(item.value) ?? false),
								eitherOrMoneyField:true,
							}
						)
					),
					...HOURLY.map(
						(item) => (
							{
								name: `exception_prices-${item.value}-textInput`,
								required: (_request['exception_prices']?.includes(item.value) ?? false),
							}
						)
					),
					...MONTHLY.map(
						(item) => (
							{
								name: `monthly-${item.value}-textInput`,
								required: (_request['monthly']?.includes(item.value) ?? false),
								eitherOrMoneyField:true,
							}
						)
					),
					...CATERED.map(
						(item) => (
							{
								name: `catered-${item.value}-textInput`,
								required: (_request['catered']?.includes(item.value) ?? false),
								eitherOrMoneyField:true,
							}
						)
					),
					...SUMMER_CATERED.map(
						(item) => (
							{
								name: `summer_catered-${item.value}-textInput`,
								required: (_request['catered']?.includes(item.value) ?? false),
								eitherOrMoneyField:true,
							}
						)
					),
					...WINTER_CATERED.map(
						(item) => (
							{
								name: `winter_catered-${item.value}-textInput`,
								required: (_request['catered']?.includes(item.value) ?? false),
								eitherOrMoneyField:true,
							}
						)
					),
					{
						name: 'exception_day',
						required: _request.exception_pass
					},
					{
						array: true,
						name: 'exception_prices',
						required: _request.exception_pass && _request.exception_day
					}

				].forEach(({name, array, required = true,eitherOrMoneyField =false}: any) => {
					if (array) {
						let arr = data.getAll(name);
						_request[name] = (arr.length > 0) ? arr : null;
						if(eitherOrMoneyField){
							if (required) moneyFieldsErrorIDs.push(name)
						}else{
							if (required) errorsIds.push(name)
						}

					} else {
						_request[name] = data.get(name);
						if (required) errorsIds.push(name)
					}
					hasAmountsFilled.push(Boolean(_request[name]))
				})
				let hasAmountsFilledReduced = hasAmountsFilled.reduce(((previousValue, currentValue) => previousValue||currentValue));
				if(!hasAmountsFilledReduced) errorsIds.push(...moneyFieldsErrorIDs)
				/*if(request.currency !== _request.currency && hasAmountsFilledReduced){

					( (it)=>{
						if(!it) return;
						it.setDialogData({
							agreeText: 'Convert to the new amount',
							cancelText: 'Remain the same',
							message: `Do you want to convert the filled amounts below to be converted from their value in ${request.currency} to the corresponding value in ${_request.currency}, or let them remain the same. `,
							title: 'You have just changed the currency',
							onAgreeClicked:(dialog)=>{
								convertAmounts(request.currency,_request.currency,_request)
							}
						}
						)
						it.setExtraAction(
							{
								text:`Revert to ${request.currency}`,
								callback:(dialog)=>{
									updateRequest({currency:request.currency})
								}
							}
							)
						it.open()
					})(dialog.current);
				}*/
			}

			

			const _errors: any = {} //_ denotes local/private
			errorsIds.forEach((id) => {
				_errors[id] = formValidation(id, _request[id])
			})

			devLog('errors: ', _errors)
			Object.keys(_errors).forEach(key => {
				if (!_errors[key]) delete _errors[key];
			});
			setErrors(_errors)
			updateRequest(_request);
			return Object.keys(_errors).length <= 0;

		}

	}
	const convertAmounts = useCallback(
		( oldCurrency:string,newCurrency:string,_request:any) => {

			[
				...HOURLY.map((item) => (`hourly-${item.value}-textInput`)),
				...HOURLY.map((item) => (`exception_prices-${item.value}-textInput`)),
				...MONTHLY.map((item) => (`monthly-${item.value}-textInput`)),
				...CATERED.map((item) => (`catered-${item.value}-textInput`)),
				'security_deposit',
			].forEach(
				(item) => {
					_request[item] = convert(request[item], oldCurrency, newCurrency)
				}
			)
			devLog(request, _request)
			updateRequest(_request)

		}
	,	[request,updateRequest]
	)
	const handleNextPage: LinearStepperActionCallback = (activeStep, setActiveStep) => {

		devLog("ActiveStep", activeStep)
		devLog("forms", formsRefs)


		if (formsRefs[activeStep]?.current) {

			if (formsSubmitted[activeStep]) {
				if (Object.keys(errors).length > 0) {
					console.log(errors);
					showMissingDataWarning();
					return;
				}
			} else {
				const _formsSubmitted = formsSubmitted;
				_formsSubmitted[activeStep] = true;
				setFormsSubmitted(_formsSubmitted)

				if (!handleFormChanged(activeStep)) {
					showMissingDataWarning();
					return;
				}
			}

			if (activeStep === pages.length - 2) {
				handleSubmit()
			}
			setActiveStep(activeStep + 1)

		} else if (activeStep === 1) {
			const _formsSubmitted = formsSubmitted;
			_formsSubmitted[activeStep] = true;
			setFormsSubmitted(_formsSubmitted)
			if (request.images?.length > 0)
				setActiveStep(activeStep + 1)
			else updateErrors({images: 'Please upload images'})
		}


	}

	//region Files
	const handleInsertPanoFile: MouseEventHandler<HTMLDivElement> = (event) => {
		event.preventDefault();
		addPanoRef.current?.click()
	}

	const handleInsertImages: MouseEventHandler<HTMLDivElement> = (event) => {
		event.preventDefault();
		addImagesRef.current?.click()
	}

	const handlePanoFileLoaded = (e: ChangeEvent<HTMLInputElement>) => {
		const selectedFile = e.target.files?.[0]
		updateRequest({'pano': selectedFile})
		e.target.value = '';
	}

	const handleImageFileLoaded = (e: ChangeEvent<HTMLInputElement>) => {
		const selectedFiles = e.target.files ?? [];
		const images = request.images ?? [];
		images.push(...selectedFiles)
		updateRequest({images})
		e.target.value = '';
	}

	const removeImage = (idx: number) => {
		const images = request.images;
		images.splice(idx, 1)
		updateRequest({images})
	}
	//endregion Files

	const createBasicInfoPage: StepperComponentGenerator = (activeStep, setActiveStep) => {
		devLog("createBasicInfoPage", request)
		const isOffice = (request.type === '4' || request.type === '9');
		const _fields = [
			{id: 'type', type: 'select', placeholder: "Space Type", required: true, options: {data: TYPES,}} as FMField,
			{
				id: 'name',
				type: 'text',
				placeholder: "Space Name",
				extraClassNames: "col-md-6",
				required: true
			} as FMField,
			{
				id: 'size',
				type: 'text',
				placeholder: "Space size (sq ft)",
				extraClassNames: "col-md-6",
				required: true
			} as FMField,
		]
		if (isOffice) _fields.push({
			id: 'capacity',
			type: 'number',
			placeholder: "Capacity",
			extraClassNames: "col-md-6",
			required: true
		} as FMField,)
		_fields.push({
			id: 'description',
			type: 'text/multi-line',
			placeholder: "Space Description",
			required: true
		} as FMField,)
		if (isOffice) {
			_fields.push(
				{
					id: 'licensing',
					type: 'bool',
					placeholder: "Space suitable for company establishment (licensing)?",
					required: true, defaultValue: true
				} as FMField,
				{
					id: 'licencing_territory',
					type: 'select',
					placeholder: 'Territory',
					required: true,
					options: {data: LICENCING_TERRITORY}
				} as FMField
			)
		} else {
			_fields.push(
				{
					id: 'layouts',
					type: 'options',
					placeholder: 'Space layouts',
					required: true,
					options: {
						data: LAYOUTS,
						valueKey: 'id',
						iconKey: 'image',
						textInput: {
							label: "",
							placeholder: "Capacity",
							type: 'number',
							allowShowError: formsSubmitted[0],
							errors,
							defaultValues: request,
						}
					}
				} as FMField,
			)
		}
		_fields.push(
			{
				id: 'amenities',
				type: 'options',
				placeholder: 'Amenities',
				required: true,
				options: {
					data: (isOffice) ? OFFICE_AMENITIES : CATERED_AMENITIES,
					valueKey: 'id', iconKey: 'image', boolLabel: 'FREE?',
					single: false,
					boolSelected: request['amenities-bool'] ?? []
				}
			} as FMField)

		if (!isOffice) _fields.push({
			id: 'suitable',
			type: 'options',
			placeholder: 'Suitable for',
			required: true,
			options: {data: SUITABLE, valueKey: 'id', single: false}
		} as FMField);

		// devLog(OFFICE_AMENITIES.map(e => request.amenities?.includes(e.id)))

		return (
			<div className="">

				<div className="form-general p-0">
					<form className="row mr-0 ml-0 mt-5" autoComplete="on" onSubmit={() => {
					}} ref={formsRefs[0]} onChangeCapture={() => handleFormChanged(0)}>


						{
							_fields.map((item) => ({
								error: formsSubmitted[0] ? errors[item.id ?? ''] : null,
								value: request[item.id ?? ''],
								defaultValue: request[item.id ?? ''],
								...item
							})).map(FMFieldGenerator)

						}


						{/*<div className="col-12 mt-3 mb-4 pr-0 pl-0">*/}
						{/*	<button*/}
						{/*		className={"btn btn-lg btn-primary btn-block fl_btn save-btn align-start " + ((reqLoading) ? 'ld-btn' : '')}>*/}
						{/*		{(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch}*/}
						{/*		                                    spin={true}/> loading</>) : 'Save'}*/}
						{/*	</button>*/}
						{/*</div>*/}

					</form>
				</div>
			</div>

		)
	}

	const createGalleryInfoPage: StepperComponentGenerator = (activeStep, setActiveStep) => {

		return (
			<div>

				<div className="col-12 pr-mobile-0 pl-mobile-0">

					<div className="col-12 pr-mobile-0 pl-mobile-0 d-flex flex-row justify-content-between">

						<div className={`h5 mt-4 mb-2 ${classes.title}`}>
							360 View
						</div>

						<div className={`h6 mt-4 mb-2 cursor-pointer ${classes.title}`}
						     onClickCapture={handleInsertPanoFile}>
							<u>Upload</u>
						</div>

					</div>

					<div className={clsx('col-12', classes.note)}>
						{
							Boolean(request["pano"]) ? (
									(request["pano"] instanceof File) ? request["pano"].name : request.pano
								)
								:
								"Upload a zip file that contains pano.xml, preview.jpg and tiles folder"
						}
					</div>

					{(errors['pano']) ? (
						<div className="form-error">{errors['pano']}</div>) : (<></>)}

				</div>

				<div className="col-12 pr-mobile-0 pl-mobile-0 mt-4 mb-5">

					<div className="col-12 pr-mobile-0 pl-mobile-0 d-flex flex-row justify-content-between">

						<div className={`h5 mt-4 mb-2 ${classes.title}`}>
							Images
						</div>

						<div className={`h6 mt-4 mb-2 cursor-pointer ${classes.title}`}
						     onClickCapture={handleInsertImages}>
							<u>Add Images</u>
						</div>

					</div>

					<div className="col-12 pr-mobile-0 pl-mobile-0 d-flex flex-nowrap flex-row overflow-auto">
						{
							Boolean(request["images"]) && (
								request["images"].map(
									(item: any, index: number) => {
										return <div className={clsx(classes.cardView, 'mx-2')}>
											{
												(item instanceof File) ?
													<FileImage key={item.name} className={clsx(classes.spaceLgImage)}
													           file={item}/>
													:
													<img key={item.name} className={clsx(classes.spaceLgImage)}
													     src={'https://fluidmeet.s3.amazonaws.com/venue_logos/' + item}/>

											}
											<RemoveSVG className={clsx(classes.spaceLgRemove)}
											           onClickCapture={() => removeImage(index)}/>
										</div>
									}
								)
							)
						}

					</div>


					{((formsSubmitted[1]) ? (Boolean(request['images'] && request.images.length > 0) ? null : errors['images']) : null) ? (
						<div className="col-12 form-error">{errors['images']}</div>) : (<></>)}
				</div>


				<div className="">
					<input ref={addImagesRef} type="file" className=""
					       id="images" name={"images"} multiple accept="image/*"
					       style={{display: 'none'}}
					       onChangeCapture={handleImageFileLoaded}
					/>
					<input ref={addPanoRef} type="file" className=""
					       id="pano" name={"pano"} accept="application/zip"
					       style={{display: 'none'}}
					       onChangeCapture={handlePanoFileLoaded}
					/>
				</div>

				{request.customFile && <img src={request.customFile?.data}/>}

			</div>
		)
	}

	const createBusinessHoursPage: StepperComponentGenerator = (activeStep, setActiveStep) => {
		const _fields = [
			{
				id: 'days',
				type: 'options',
				required: true,
				options: {
					data: DAYS,
					boolLabel: '24Hrs',
					single: false,
					boolSelected: request['days-bool'] ?? [],
					fullwidth: true,
				}
			} as FMField,
		]
		return (
			<>
				<div className="">

					<div className="form-general p-0">
						<form className="row mr-0 ml-0 mt-5" autoComplete="on" onSubmit={() => {
						}} ref={formsRefs[2]} onChangeCapture={() => handleFormChanged(2)}>
							<div className="col-12 pl-4 mb-3">
								<FMTextInput type={"number"}
								             title={"Minimum Booking Hours"}
								             id={'min_booking_hrs'}
								             required={true}
								             min={1}
								             defaultValue={request['min_booking_hrs']}
								             extraClassNames={"col-md-8 col-lg-6 pl-4 pr-4 pr-md-5"}
								             error={errors['min_booking_hrs']}
								/>
							</div>
							<div className="col-md-6">
								{
									_fields.map((item) => ({
										error: formsSubmitted[2] ? errors[item.id!] : null,
										defaultValue: request[item.id!], ...item
									})).map(FMFieldGenerator)
								}
							</div>
							<div className="col-md-6 d-flex flex-column ">
								{

									DAYS.map((item) => (
										{
											id: item.value,
											type: 'timespan',
											required: true,
											defaultValue: [request[item.value + '-start'], request[item.value + '-end']],
										} as FMField
									))
										.map((item) => ({
											error: formsSubmitted[2] ? errors[item.id!] : null,
											defaultValue: request[item.id!],
											...item
										})).map((item, idx) => {
											const visible = (request['days']?.includes(DAYS[idx].value) && !request['days-bool']?.includes(DAYS[idx].value)) ? "visible" : "hidden";
											return (
												<div style={{visibility: visible}}>
													{FMFieldGenerator(item)}
												</div>
											)
										}
									)
								}
							</div>
							{/*<div className="col-12 mt-3 mb-4 pr-0 pl-0">*/}
							{/*	<button*/}
							{/*		className={"btn btn-lg btn-primary btn-block fl_btn save-btn align-start " + ((reqLoading) ? 'ld-btn' : '')}>*/}
							{/*		{(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch}*/}
							{/*		                                    spin={true}/> loading</>) : 'Save'}*/}
							{/*	</button>*/}
							{/*</div>*/}

						</form>
					</div>
				</div>
			</>
		)
	}


	const createPricesPage: StepperComponentGenerator = (activeStep, setActiveStep) => {
		const isOffice = (request.type === '4' || request.type === '9');
		const hasHourly = (request.hourly && request.hourly.length > 0);
		let currency = venueCurrency;

		const _fields: FMField[] = [
			/*{
				type: 'select',
				id: 'currency',
				placeholder: 'Currency',
				required: true,
				options: {
					data: currencies.map((item: any) => ({label: item.name, value: item.name})),
					selectNull: false,
				}
			},*/
			// {
			// 	type: 'custom',
			// 	customView: <div className={" col-12 form-error "} style={{fontSize: 12}}> *** Remember to change the
			// 		amounts below after changing the currency </div>,
			// },
			{
				type: 'options',
				id: 'hourly',
				placeholder: isOffice ? 'Hourly pricing' : 'Space Only pricing',
				//currency,
				// countLabel={'Price'},
				required: false,
				options: {
					data: HOURLY,
					textInput: {
						type: 'number',
						placeholder: 'Price',
						label: currency.icon,
						defaultValues: request,
						errors,
						allowShowError: formsSubmitted[3],
					}
				},

				// style={{marginBottom: hasHourly ? 0 : 14}}
			},

		];


		if (hasHourly) {
			_fields.push(
				{
					type: 'bool',
					id: 'exception_pass',
					placeholder: 'Exception pricing',
					defaultValue: request['exception_pass'],
					// style={{marginBottom: (hasHourly && request.exception_pass) ? 0 : 20}}
				}
			);
			if (request.exception_pass) {
				_fields.push(
					{
						type: 'select',
						id: 'exception_day',
						placeholder: 'Select Day',
						options: {
							data: DAYS,
						},
						// style={request.exception_day ? {} : null}
					}
				)
				if (request.exception_day) {
					_fields.push(
						{
							type: 'options',
							id: 'exception_prices',
							// countLabel:'Price',
							placeholder: 'Exception Day Hourly Pricing',
							options: {
								data: HOURLY,
								textInput: {
									type: 'number',
									placeholder: 'Price',
									label: currency.icon,
									defaultValues: request,
									errors,
									allowShowError: formsSubmitted[3],
								}
							},
						}
					)
				}
			}
		}


		if (isOffice) {
			_fields.push(
				{
					type: 'options',
					id: 'monthly',
					placeholder: 'Monthly pricing',
					// countLabel:'Price',
					options: {
						data: MONTHLY,
						textInput: {
							type: 'number',
							placeholder: 'Price',
							label: currency.icon,
							defaultValues: request,
							errors,
							allowShowError: formsSubmitted[3],
						}
					},
					required: true,

				},
				{
					type: 'select',
					id: 'term',
					placeholder: 'Minimum term',
					options: {data: TERMS},
					required: true,
				},
				{
					type: 'text',
					id: 'security_deposit',
					placeholder: 'Security deposit',
					title: 'Security deposit',
					required: true,
				},
			)
		} else {
			if(venue.city == "Dubai" && request.automate_pricing) {
				_fields.push(
					{
						type: 'options',
						id: 'summer_catered',
						placeholder: 'Summer Catering pricing',
						options: {
							data: SUMMER_CATERED,
							textInput: {
								type: 'number',
								placeholder: 'Price',
								label: currency.icon,
								defaultValues: request,
								errors,
								allowShowError: formsSubmitted[3],
							}
						},
						required: true,
					},
					{
						type: 'options',
						id: 'winter_catered',
						placeholder: 'Winter Catering pricing',
						options: {
							data: WINTER_CATERED,
							textInput: {
								type: 'number',
								placeholder: 'Price',
								label: currency.icon,
								defaultValues: request,
								errors,
								allowShowError: formsSubmitted[3],
							}
						},
						required: false,
					}
				)
			}
			else {
				_fields.push(
					{
						type: 'options',
						id: 'catered',
						placeholder: 'Catering pricing',
						// countLabel:'Price',
						options: {
							data: CATERED,
							textInput: {
								type: 'number',
								placeholder: 'Price',
								label: currency.icon,
								defaultValues: request,
								errors,
								allowShowError: formsSubmitted[3],
							}
						},
						required: false,
					}
				)
			}
		}

		return (
			<div>
				<form
					key='PricingForm'
					ref={formsRefs[3]}
					onChangeCapture={() => handleFormChanged(3)}>
					{
						_fields.map((item) => ({
							error: formsSubmitted[3] ? errors[item.id!] : null,
							defaultValue: request[item.id!],
							value: request[item.id!],
							...item
						})).map(FMFieldGenerator)
					}

				</form>

			</div>
		)
	}

	const createSubmissionPage: StepperComponentGenerator = (activeStep, setActiveStep) => {
		const {loading, state} = submissionState;
		if (!loading && state) setTimeout(() => {
			history.goBack()
		}, 5000)
		return (
			<div>

				<div key='ConfirmationForm'>
					{loading && <CircularProgress/>}
					{state === 'success' && <>
											<FontAwesomeIcon icon={'check-circle'}/>
											<div className={'page-lg-title'}>
						  {id ? 'Space Updated' : 'Request Sent'}
											</div>
											<div className={'note-title'}>
						  {id ? 'Your space details have been updated successfully'
							  : 'Your request has been sent successfully. Please check the email you provided for updates.'}
											</div>
										</>
					}
					{state === 'failed' && <>
											<FontAwesomeIcon icon={'ban'}/>
											<div className={'page-lg-title'}>
						  {id ? 'Failed to Updated' : 'Failed to Add'}
											</div>
						{/*<div className={'note-title'}>*/}
						{/*    {id ? 'Your space details have been updated successfully' */}
						{/*      : 'Your request has been sent successfully. Please check the email you provided for updates.'}*/}
						{/*</div>*/}

										</>
					}


				</div>

			</div>
		)
	}

	const handleSubmit = async () => {
		const isOffice = (request.type == '4' || request.type == '9');

		let token = await getToken()

		const url = getApiLinkV2("host/workspace");
		devLog('handleSubmit::token', token);

		try {
			devLog('handleSubmit::try');
			let pricing = (() => {
				if (request.hourly && request.hourly?.length > 0) {
					if (isOffice && request.monthly && request.monthly?.length > 0) return 'both'
					else return 'hourly'
				} else {
					return (isOffice ? 'monthly' : 'catered')
				}
			})();


			let images = await (async () => {
				const _images: any[] = []
				if (request.images) {
					for (const item of request.images) {

						if (item instanceof File) {
							let {attachment: key} = await FilesAPI.addImage(item) as Message
							_images.push(key)
						} else _images.push(item)
					}

				}
				return _images
			})();
			devLog('handleSubmit::images', images);

			let image_360 = await (
				async () => {

					if (request.pano) {
						if (request.pano instanceof File) {

							let message = await FilesAPI.uploadPano(request.pano);
							if (message.type === 'success') {
								devLog({message})
								return message.attachment?.name;
							} else {
								devLog(message.getText(), {response: message.attachment})
							}

						} else return request.pano
					}
				}

			)();
			devLog('handleSubmit::image_360', image_360);

			const data: any = {
				workspace_id: id ? id : '',
				venue_details_id: venue.venue_details_id,
				venue_name: venue.venue_name,
				min_booking_hrs: request.min_booking_hrs ?? 1,
				workspace_type_id: request.type,
				name: request.name,
				size: request.size,
				description: request.description,
				capacity: request.capacity,
				licencing: request.licencing === 'on' ? 'yes' : '',
				licencing_territory: request.licencing_territory,
				suitable: request.suitable ?? [],

				image_360,
				images,

				working_hours: DAYS.map(({value}: any, i: number) => {
					if (request.days?.includes(value)) {
						if (request['days-bool']?.includes(value)) return 'open_24_hours';
						return `${request[value + '-start']}:00-${request[value + '-end']}:00`
					} else {
						return 'closed'
					}
				}),

				currency: request.currency,
				term: request.term,
				security_deposit: request.security_deposit,
				pricing,
				room_only_pass: (request.hourly && request.hourly?.length > 0) ? 'yes' : '',
				catering: (
					request.catered && request.catered?.length > 0
					|| request.summer_catered && request.summer_catered?.length > 0
					|| request.winter_catered && request.winter_catered?.length > 0
				) ? 'yes' : '',

			};

			if (request.amenities) {

				let amenities: any[] = [];
				request.amenities.forEach((item: any) => {
					const amenityRef = amenitiesRef ? amenitiesRef.find((x: any) => x.amenity == item) : null;
					if (amenityRef) {
						amenities.push({
							id: amenityRef.id,
							amenity: item,
							fee: request['amenities-bool']?.includes(item) ? 'yes' : 'no'
						});
					} else {
						amenities.push(
							{amenity: item, fee: request.amenities[item] ? 'yes' : 'no'});
					}
				});
				data.amenities = amenities;
			}

			if (request.layouts) {
				request.layouts.forEach((item: any) => {
					data[item + '_chk'] = 'yes';
					data[item + '_cap'] = request[`layouts-${item}-textInput`];
				});

			}

			if (request.hourly) {
				request.hourly.forEach((item: any) => {
					data[item + '_pass'] = 'yes';
					data[item + '_price'] = request[`hourly-${item}-textInput`];
				});
			}

			if (request.monthly) {
				request.monthly.forEach((item: any) => {
					data[item + '_pass'] = 'yes';
					data[item + '_price'] = request[`monthly-${item}-textInput`];
				});
			}

			if (request.catered) {
				request.catered.forEach((item: any) => {
					data[item + '_pass'] = 'yes';
					data[item + '_price'] = request[`catered-${item}-textInput`];
				});
			}

			if(request.summer_catered) {
				request.summer_catered.forEach((item: any) => {
					data[item] = request[`summer_catered-${item}-textInput`];
				});
			}

			if(request.winter_catered) {
				request.winter_catered.forEach((item: any) => {
					data[item] = request[`winter_catered-${item}-textInput`];
				});
			}

			if (request.exception_pass && request.exception_day) {
				data.exception_rules = request.exception_pass ? 'yes' : '';
				data.exception_day1 = request.exception_day;
				request.exception_prices.forEach((item: any) => {
					data['exception_' + item + '_pass1'] = 'yes';
					data['exception_' + item + '_price1'] = request[`exception_prices-${item}-textInput`];
				});
			}

			console.log("request", data);

			let response = await getAxios().post(url, data, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token,
				},
			})
			setSubmissionState({loading: false, state: 'success'});
		} catch (error: any) {
			devLog(error.response);
			console.log({error});
			Bugsnag.notify(error);
			setSubmissionState({loading: false, state: 'failed'});
			// showErrorMessage(error);
		}
	}

	const pages = [
		{key: 'basic-info', label: 'Basic Info', page: createBasicInfoPage} as StepPage,
		{key: 'gallery', label: 'Gallery', page: createGalleryInfoPage} as StepPage,
		{key: 'business-hours', label: 'Business Hours', page: createBusinessHoursPage} as StepPage,
		{key: 'prices', label: 'Prices', page: createPricesPage} as StepPage,
		{key: 'submission', label: 'Submission', page: createSubmissionPage} as StepPage,
	]

	return (
		<>
			<div className="Edit Workspace">
				<div className="container pr-0 pl-0" style={{marginTop: 120}}>
					<div className="page">

						<div className={`row page-lg-title mt-4 mb-5 ${classes.title}`}>
							{(Boolean(id)) ? "Edit" : "Add"} Space
						</div>

						{(loading) ? (
							<div className={clsx('d-flex col-12 justify-content-center')}>
								<CircularProgress className={clsx('mx-auto my-5')}/>
							</div>
						) : (
							<LinearStepper
								steps={pages}
								footer={'default'}
								onDefaultFooterNext={handleNextPage}
							/>
						)
						}

					</div>
				</div>
			</div>
			<AlertSnackbar ref={snackbar}/>
			<AlertDialog ref={dialog}/>
		</>
	);
}