import {devLog, getApiLinkV2, getToken} from "src/Helpers";
import axios from "axios";
import {getAxios} from "src/Helpers";
import Bugsnag from "@bugsnag/js";
import {Message} from "src/APIs/HostAPI";

export default class FilesAPI {

	static async getImageLink(name) {
		return 'https://fluidmeet.s3.amazonaws.com/venue_logos/' + name
	}

	static async addImage(file) {
		const formData = new FormData();

		formData.append("file", file);

		try {
			let token = await getToken()
			const url = getApiLinkV2('files/upload-file');

			let response = await getAxios().post(url, formData, {
				headers: {
					'content-type': 'multipart/form-data',
					'authorization': 'Bearer ' + token,

				},
			})
			devLog(response);
			return new Message('success', '', 'Your profile picture has been updated.', '', response.data.name);
		} catch (error: any) {
			devLog(error);
			Bugsnag.notify(new Error('Error Catched in axios ' + JSON.stringify(error)));
		}

	}

	static async uploadPano(file) {
		try {
			const formData = new FormData();
			formData.append("file", file);

			let token = await getToken()
			const url = getApiLinkV2("files/upload-pano");

			let response = await getAxios().post(url, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					'Authorization': 'Bearer ' + token,
				},
			})

			if (response.data.status === 'success') {
				return new Message("success", "Success", "uploaded 360 view", '', {
					name: response?.data.name,
					url: 'https://fluidmeet.s3.amazonaws.com/venue_logos/360/' + response.data.name + '/preview.jpg'
				});

			} else {
				return new Message("danger", "Failure", "failed to upload 360 view", response.data.error, response);
			}

			// devLog(response.data);


		} catch (error: any) {

			devLog(error);
			Bugsnag.notify(new Error('Error Catched in axios ' + JSON.stringify(error)));
			return new Message("danger", "Failure", "failed to upload 360 view", '', error);
			// showErrorMessage(err);
		}

	}

	// static async uploadFile(file, type: 'contract'|'pano') {}

	static async uploadFile(file, type: 'contract' | 'pano') {

		try {
			const formData = new FormData();
			formData.append("file", file);

			let token = await getToken()
			const url = getApiLinkV2(type === 'contract' ? "files/upload-contract" : "files/upload-pano");

			let response = await getAxios().post(url, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					'Authorization': 'Bearer ' + token,
				},
			})

			if (response.data.status === 'success') {

				return {
					name: response?.data.name,
					url: type === 'contract' ? 'https://fluidmeet.s3.amazonaws.com/venue_logos/contracts/' + response.data.name
						: 'https://fluidmeet.s3.amazonaws.com/venue_logos/360/' + response.data.name + '/preview.jpg'
				};


			} else {
				// showError(response.data.error);
			}

			devLog(response.data);


		} catch (error: any) {

			devLog(error);
			Bugsnag.notify(new Error('Error Catched in axios ' + JSON.stringify(error)));
			// showErrorMessage(err);
		}

	}

	static async viewReceipt(id, userId) {

		try {


			let token = await getToken()
			const url = getApiLinkV2(`user/receipt/view?id=${id}&user_id=${userId}`);

			let response = await getAxios().get(url, {
				headers: {
					'Authorization': 'Bearer ' + token,
				},
			})

			devLog("FilesAPI :: ViewReceipt", response);

			return response.data


		} catch (error: any) {

			console.error("FilesAPI :: ViewReceipt", error);
			Bugsnag.notify(new Error('Error Caught in axios ' + JSON.stringify(error)));
			// showErrorMessage(err);
		}

	}
}
