export const FILTER_LIST = [
	{label: 'All Reservations', value: 'reservations'},
	{label: 'Event Inquiries', value: 'proposals'},
	{label: 'Workspace Inquiries', value: 'leads'},
	{label: 'Hourly/Daily Bookings', value: 'bookings'},
	
]

export const DATE_FILTER_LIST = [
	{label: '1 Month', value: 'one'},
	{label: '3 Months', value: 'three'},
	{label: '6 Months', value: 'six'},
	{label: 'custom dated', value: 'custom'},
]

export const OTHER_DATES = [
	{label: '1 week before', value: '1 week before'},
	{label: '1 week after', value: '1 week after'},
	{label: '1 day before', value: '1 day before'},
	{label: '1 day after', value: '1 day after'},
	{label: 'Other', value: 'Other'}
];
