import NavBar from "src/components/atoms/NavBar";
import React, {ReactElement, useEffect, useState} from "react";
import {Redirect, Route, useLocation} from "react-router-dom";
import {HostDashboard, RoutedHostDashboard} from "./host-dashboard";
import {decodeQuery, devLog, getUser, isHost} from "src/Helpers";
import Footer from "src/components/organisms/Footer";
import {EditHost, EditHostEnhanced} from "./manage-hosts/add-edit-host/edit-host";
import {AddNewHost, AddNewHostEnhanced} from "./manage-hosts/add-edit-host/add-new-host";
import {
	EditVenue,
	EditVenueEnhanced
} from "src/components/host/host-dashboard/manage-listings/add-edit-venues/edit-venue";
import {EditWorkspace,} from "src/components/host/host-dashboard/manage-listings/add-edit-workspaces/edit-workspace";
import ProposalDetails
	from "src/components/host/host-dashboard/manage-reservations/reservations-details/ProposalDetails";
import ShortTermDetails
	from "src/components/host/host-dashboard/manage-reservations/reservations-details/ST-booking-details";
import LongTermDetails
	from "src/components/host/host-dashboard/manage-reservations/reservations-details/LT-booking-details";
import ContractDetails from "src/components/host/host-dashboard/subscription/contract-details";
import RespondToAdminHostInvite from "src/components/host/host-dashboard/subscription/respond-to-admin-host-invite";
import {useQuery} from "src/hooks/useQuery";
import {LoadingComponent} from "src/components/atoms/loading-component";
import AuthAPI from "src/APIs/AuthAPI";


export const AccessWrapper = (
	{children, notHostRedirect = '/', notSeekerRedirect = '/', notUserRedirect = '/login', access = 'both'}:
		({ children: any, notHostRedirect?: string, notSeekerRedirect?: string, notUserRedirect?: string, access?: 'host-only' | 'seeker-only' | 'both' })
) => {
	let query = useQuery();
	let location = useLocation();
	let [user, setUser] = useState(getUser());
	let [fetched, setFetched] = useState(false);
	let isHost = () => (user?.type === 'host');
	let login_token = query.get('tkn');
	let email = decodeQuery(query, 'email');
	let password = decodeQuery(query, 'password');
	console.log("Wrapper :: ", {user, isHost: isHost(), login_token});
	useEffect(() => {
		if (!user && login_token) {
			if (login_token) {
				AuthAPI.signInByToken(login_token).then(() => {
					setUser(getUser())
				}).finally(() => {
					setFetched(true)
				});
			}
			if (email && password) {
				AuthAPI.signIn(email, password).then(() => {
					setUser(getUser())
				}).finally(() => {
					setFetched(true)
				});
			}

		}
	}, []);

	if (!user && login_token && !fetched)
		return <LoadingComponent/>
	else if (user) {

		if (access === 'both' || (access === 'host-only' && isHost()) || (access === 'seeker-only' && !isHost())) {
			return (
				<>
					{children}
				</>
			);
		} else if ((access === 'host-only' && !isHost())) {
			return (
				<Redirect to={notHostRedirect}/>
			);
		} else if ((access === 'seeker-only' && isHost())) {
			return (
				<Redirect to={notSeekerRedirect}/>
			);
		} else {
			return (
				<Redirect to={'/'}/>
			);
		}
	} else {
		return (
			<Redirect to={notUserRedirect + '?redirect=' + encodeURIComponent(location.pathname + location.search)}/>
		);
	}
}

export const HostDashboardRoutes: (args?: any) => Array<ReactElement> = (args?: any) => {

	return (
		[
			<Route
				key={RespondToAdminHostInvite.key}
				path={RespondToAdminHostInvite.route}
				render={(props: any) => {
					document.title = "Fluidmeet | Host Invite";
					devLog("***Here***", props);

					return (
						<>
							<NavBar hideSearch hideHostBtn/>

							<RespondToAdminHostInvite {...props}/>

							<Footer/>
						</>
					);

				}} exact/>,
			<Route
				key={ContractDetails.key}
				path={ContractDetails.route}
				render={
					(props: any) => {
						document.title = "Fluidmeet | Contract Details";
						devLog("***Here***", props);

						return (
							<AccessWrapper access={"host-only"}>
								<NavBar hideSearch hideHostBtn/>

								<ContractDetails {...props}/>

								<Footer/>
							</AccessWrapper>
						)

					}
				} exact/>,
			<Route
				key={LongTermDetails.key}
				path={LongTermDetails.route}
				render={(props: any) => {
					document.title = "Fluidmeet | Lead Details";
					devLog("***Here***", props);

					return (
						<AccessWrapper access={"host-only"}>
							<NavBar hideSearch hideHostBtn/>

							<LongTermDetails {...props}/>

							<Footer/>
						</AccessWrapper>
					);

				}} exact/>,
			<Route
				key={ShortTermDetails.key}
				path={ShortTermDetails.route}
				render={(props: any) => {
					document.title = "Fluidmeet | Booking Details";
					devLog("***Here***", props);
					return (
						<AccessWrapper access={"host-only"}>
							<NavBar hideSearch hideHostBtn/>

							<ShortTermDetails {...props}/>

							<Footer/>
						</AccessWrapper>
					);
				}} exact/>
			,
			<Route
				key={ProposalDetails.key}
				path={ProposalDetails.route}
				render={(props: any) => {
					document.title = "Fluidmeet | Proposal Details";
					devLog("***Here***", props);

					return (
						<AccessWrapper access={"host-only"}>
							<NavBar hideSearch hideHostBtn/>

							<ProposalDetails {...props}/>

							<Footer/>
						</AccessWrapper>
					);

				}} exact/>
			,
			<Route
				key={EditWorkspace.key}
				path={EditWorkspace.route}
				render={(props: any) => {
					document.title = "Fluidmeet | Edit Workspace";
					devLog(props);

					return (
						<AccessWrapper access={"host-only"}>
							<NavBar hideSearch hideHostBtn/>

							<EditWorkspace {...props}/>

							<Footer/>
						</AccessWrapper>
					);

				}} exact/>
			,
			<Route
				key={EditVenue.key}
				path={EditVenue.route}
				render={(props: any) => {
					document.title = "Fluidmeet | Edit Venue";
					devLog(props);

					return (
						<AccessWrapper access={"host-only"}>
							<NavBar hideSearch hideHostBtn/>

							<EditVenueEnhanced {...props}/>

							<Footer/>
						</AccessWrapper>
					);

				}} exact/>
            ,
            <Route
                key={EditHost.key}
                path={EditHost.route}
                render={(props: any) => {
	                document.title = "Fluidmeet | Edit host";
	                devLog(props);
	                if (props.match.params.id) {
		                return (
			                <AccessWrapper access={"host-only"}>
				                <NavBar hideSearch hideHostBtn/>

				                <EditHostEnhanced {...props}/>

				                <Footer/>
			                </AccessWrapper>
		                );
	                } else {
		                return (
			                <Redirect to={'/error'}/>
                        );
                    }
                }} exact/>
            ,
            <Route
                key={AddNewHost.key}
                path={AddNewHost.route}
                render={(props: any) => {
	                document.title = "Fluidmeet | Add New host";
	                devLog(props);

	                return (
		                <AccessWrapper access={"host-only"}>
			                <NavBar hideSearch hideHostBtn/>

			                <AddNewHostEnhanced {...props}/>

			                <Footer/>
		                </AccessWrapper>
	                );


                }} exact/>
            ,
            <Route
                key={HostDashboard.routeName}
                path={HostDashboard.subRoute}
                render={(props: any) => {
	                document.title = "Fluidmeet | Host Dashboard";
	                devLog(HostDashboard.routeName, {user: getUser(), isHost: isHost()})
	                return (
		                <AccessWrapper access={"host-only"}>
			                <NavBar hideSearch hideHostBtn/>

			                <RoutedHostDashboard {...props}/>

			                <Footer/>
		                </AccessWrapper>
	                );

                }} />


        ]
    );

};
