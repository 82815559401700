import React, {Component} from 'react';

export default class PageNotFound extends Component {

  componentDidMount() {
    //ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {

    return (
      <>

        <div className="container-fluid contain mt-80">

          <section className="not-found">

            <div className="row h100">

              <div className="not-found-msg">Error 404</div>

            </div>

          </section>

        </div>
      </>
    );
  }
}
