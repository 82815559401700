import React, {Component, createRef} from 'react';
import {Redirect, withRouter} from "react-router-dom";
import {
	addDays,
	checkAvailableEndTimeFromBooking,
	checkAvailableTimeFromBooking,
	createAlert,
	daysInMonth,
	devLog,
	getApiLinkV2,
	getCurrencies,
	getCurrency,
	getDayName,
	getLayoutImg,
	getToken,
	getUser,
	getVatPercentages,
	hasTerm,
	nth,
	printAmenity,
	printDateForServer,
	printPrice,
	printSpaceData,
	setSource,
	updateEndTime,
	updateFullDayTime,
	updateHalfDayTime,
	updateStartTime,
} from "src/Helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faCircleNotch, faStar} from "@fortawesome/free-solid-svg-icons";
import {faCalendar} from "@fortawesome/free-regular-svg-icons";
import NavBar from "src/components/atoms/NavBar";
import FloatingAlert from "src/components/organisms/FloatingAlert";
import DatePicker from "react-datepicker";
import queryString from "query-string";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import {getAxios} from "src/Helpers";
import Footer from "src/components/organisms/Footer";
import Bugsnag from "@bugsnag/js";
import {connect} from "react-redux";
import 'react-phone-input-2/lib/bootstrap.css'
import {
	CATERED_AMENITIES,
	getTypeTextKey
} from "src/components/host/host-dashboard/manage-listings/add-edit-workspaces/consts";
import {PaymentVerification} from "src/components/payment-verfication";
import libphonenumber from "google-libphonenumber";
import {FMPayment} from "src/components/organisms/fm-payment";
import {FMButton} from "src/components/host/forms/fields";
import {LoadingComponent} from "src/components/atoms/loading-component";
import {Discount, ShortTermPricingModel} from "src/utils/accounting-utils";
import {firstNonZero} from "src/global-functions";
import {Helmet} from 'react-helmet';


let phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()
let PNF = libphonenumber.PhoneNumberFormat

const excludedDates = {};
//   'Mar 10, 2021': true,
//   'Mar 11, 2021': true,
//   'May 12, 2021': true,
//   'May 13, 2021': true,
//   'May 14, 2021': true,
//   'May 15, 2021': true,
//   'Jul 18, 2021': true,
//   'Jul 19, 2021': true,
//   'Jul 20, 2021': true,
//   'Jul 21, 2021': true,
//   'Jul 22, 2021': true,
//   'Aug 9, 2021': true,
//   'Aug 10, 2021': true,
//   'Oct 18, 2021': true,
//   'Oct 19, 2021': true,
//   'Nov 30, 2021': true,
//   'Dec 1, 2021': true,
//   'Dec 2, 2021': true,
// };

const week = [
	'Sun',
	'Mon',
	'Tue',
	'Wed',
	'Thu',
	'Fri',
	'Sat',
];

const fullWeek = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];


const dateOptions = {year: 'numeric', month: 'short', day: 'numeric'};

export class ShortTermBooking extends Component<any, any> {
	static route = "/booking/short-term/:space_type/:id/:page?";
	oldScroll = 0;
	stickDiv = createRef<HTMLDivElement>();
	slideDiv = createRef<HTMLDivElement>();
	bottomDiv = createRef<HTMLDivElement>();
	start_time = createRef<HTMLSelectElement>();
	end_time = createRef<HTMLSelectElement>();
	time = createRef<HTMLSelectElement>();
	coupon = createRef<HTMLInputElement>();

	constructor(props) {
		super(props);

		var initState = {
			space: {},
			page: (props.match.params.page) ? props.match.params.page : 'details',
			term: 'hourly',
			food: null,
			service: null,
			selectedTerm: 'hourly',
			selectedLayout: '',
			selectedCountry: '',
			selectedCity: '',
			selectedLayoutMax: 0,
			selectedAmenities: {},
			flexibleDates: true,
			accomReq: false,
			sendToOthers: true,
			directContact: true,
			isRecurring: false,
			capacity: 1,
			startTime: "",
			endTime: "",
			time: "",
			recurringType: 'weekly',
			dayIndex: 0,
			copyAlert: 0,
			recurringNum: 0,
			days: [],
			rooms: [],
			request: {},
			recurring: {},
			isScrolled: false,
			isBottomReached: false,
			reqLoading: false,
			coupon: '',
			couponType: '',
			couponPrice: 0,
			showCoupon: false,
			couponLoading: false,
			startDate: null,
			endDate: null,
			accomStartDate: null,
			accomEndDate: null,
			countryCode: null,
			excludedDays: {},
			errors: {},
			loading: true,
			errorMessagevalidation: '',
			isCalendarEnabled: false,
			bookings: [],
			additionalExcludeDates: []
		}

		if (props.location.state) {

			const {space, term, food, service, source} = props.location.state;

			initState.space = space;
			initState.term = term;
			initState.food = food;
			initState.service = service;

			if (source) setSource(source);

		}

		let search = queryString.parse(props.location.search);
		if (search && search.source) {
			setSource(search.source);
		}


		this.state = initState;

	}

	static getRoute = (id, space_type_id: string | number, page = "details") => ShortTermBooking.route.replace(':space_type', getTypeTextKey(space_type_id) ?? 'error').replace(':id', id).replace(':page?', page);

	static moveRoute = ({space_type, id}, page = "details") => ShortTermBooking.route.replace(':space_type', space_type).replace(':id', id).replace(':page?', page);

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			window.scrollTo(0, 0);
			this.setState({page: this.props.match.params.page ?? 'details'});
			this.updateProgress()
			//ReactGA.pageview(window.location.pathname + window.location.search);
		}

		this.state.additionalExcludeDates.splice(0, this.state.additionalExcludeDates.length);

		if (!this.state.isCalendarEnabled) {
			let date = new Date();
			this.state.additionalExcludeDates.push(date);
			if (date.getHours() > 18 || date.getDay() === 0) {
				let addDate = new Date();
				addDate.setDate(date.getDate() + 1)
				this.state.additionalExcludeDates.push(addDate);
			}
		}
		console.log(this.state.additionalExcludeDates, this.state.isCalendarEnabled);
	}

	componentDidMount() {

		document.body.classList.remove("no-scroll");

		const datePickers = document.getElementsByClassName("react-datepicker__input-container");
		Array.from(datePickers).forEach((el => el.childNodes[0]?.setAttribute("readOnly", true)));

		const {location} = this.props;
		const {space, term} = this.state;

		this.fetchSpace();

		const user = getUser();
		if (user) {
			this.setState({selectedCountry: user.country, selectedCity: user.city});
		}


		window.addEventListener('beforeunload', this.handleUnLoad);


		// const spaceType = location.pathname.indexOf('st-office') !== -1 ? 'Office Space' : location.pathname.indexOf('st-banquet') !== -1 ? 'Event' +
		// 	' Space' : location.pathname.indexOf('st-coworking') !== -1 ? 'Co-Working Space' : 'Meeting Room';
		// ReactGA.event({
		// 	category: 'ST',
		// 	action: 'ST_Attempt',
		// 	label: spaceType + ' ST Booking Attempt for space ' + space.workspace_id
		// });


	}

	componentWillUnmount() {

		const {term} = this.state;
		window.removeEventListener('beforeunload', this.handleUnLoad);
	}

	fetchSpace = async () => {

		const {match} = this.props;

		const url = getApiLinkV2('workspace/' + match.params.id);
		const token = getToken();
		const headers = {'Content-Type': 'application/json'};
		if (token) {
			headers['Authorization'] = 'Bearer ' + token;
		}
		try {
			let response = await getAxios().get(url, {
				headers: headers,
			})
			if (response.data.workspace) {

				const space = response.data.workspace;
				const defSpaceData = printSpaceData(space.workspace_type_id);

				if (hasTerm(space, 'hourly')) {

					this.setState({
						space,
						similarSpaces: response.data.similar_workspace,
						food: defSpaceData.food,
						service: defSpaceData.service,
					}, () => {
						console.log({space});
						this.initDate();
						this.initPlans();
						this.setState({loading: false})
						if (space.layouts && Object.keys(space.layouts).length === 1) {
							const key = Object.keys(space.layouts)[0];
							this.setState({selectedLayout: key, selectedLayoutMax: space.layouts[key]})
						}
					});


					if (space && space.layouts && Object.keys(space.layouts).length === 1) {
						const key = Object.keys(space.layouts)[0];
						this.setState({selectedLayout: key, selectedLayoutMax: space.layouts[key]})
					}


				} else {
					this.setState({space: null, loading: false});
				}
			}

			if (response.data.isCalendarEnabled) {
				this.setState({
					isCalendarEnabled: true,
					bookings: response.data.bookings_data
				})
			}
		} catch (error: any) {
			Bugsnag.notify(error);
			console.error((error.response && error.response.data.error) ? error.response.data.error : error.message);
			this.setState({space: null, loading: false});
		}
	};

	handleExitBooking = () => {

		const {match, history, generic} = this.props;
		const {term} = this.state;

		if (window.confirm('Exit booking? Changes you made will not be saved.')) {
			history.push((generic) ? '/' : '/space/' + match.params.id + '?t=' + term);
		}
	};

	handleUnLoad = event => {
		const message = 'Exit booking? Changes you made will not be saved.';
		event.preventDefault();
		event.returnValue = message;
		return message;
	}

	updateProgress = () => {

		let page = this.props.match.params.page ?? 'details';
		let progress = 10

		switch (page) {

			case 'details':
				progress = 10;
				break;
			case 'checkout':
				progress = 90;
				break;
		}

		this.setState({progress})
	}

	handleUpdateCapacity = e => {

		const {space, selectedLayoutMax} = this.state;
		const finalMax = selectedLayoutMax > 0 ? selectedLayoutMax : ((space.capacity > 0) ? space.capacity : 1);
		const newCapacity = parseInt(e.target.value);

		if (newCapacity > finalMax) {
			this.setState({capacity: finalMax});
			return;
		}
		if (newCapacity < 1) {
			this.setState({capacity: 1});
			return;
		}
		this.setState({capacity: newCapacity});
	};

	handleChooseAmenity = amenity => {
		console.log('st', amenity);
		var amenities = this.state.selectedAmenities;
		if (amenities[amenity]) {
			delete amenities[amenity];
		} else {
			amenities[amenity] = true;
		}
		this.setState({selectedAmenities: amenities});
	};

	printTimeOptions = (space, selectedTerm, startDate, type: any = null, startTime: any = null) => {

		if (!startDate) return (<></>);

		const dayIndex = startDate.getDay();
		var day = '';
		if (dayIndex === 0) {
			day = 'sunday';
		} else if (dayIndex === 1) {
			day = 'monday';
		} else if (dayIndex === 2) {
			day = 'tuesday';
		} else if (dayIndex === 3) {
			day = 'wednesday';
		} else if (dayIndex === 4) {
			day = 'thursday';
		} else if (dayIndex === 5) {
			day = 'friday';
		} else if (dayIndex === 6) {
			day = 'saturday';
		}

		if (selectedTerm === 'hourly') {
			const minHoursDiff = (this.state.term === 'hourly' && space.min_booking_hrs) ? parseInt(space.min_booking_hrs) : 1;
			if (space) {
				if (type === "end") {
					return updateEndTime(space.ws_avail, day, startTime, minHoursDiff, this.state.isCalendarEnabled, this.state.bookings, startDate);
				} else {
					return updateStartTime(space.ws_avail, day, this.state.isCalendarEnabled, this.state.bookings, startDate, minHoursDiff);
				}
			} else {
				if (type === "end") {
					return updateEndTime(null, day, startTime, 1, this.state.isCalendarEnabled, this.state.bookings, startDate);
				} else {
					return updateStartTime(null, day, this.state.isCalendarEnabled, this.state.bookings, startDate, minHoursDiff);
				}
			}

		} else if (selectedTerm === 'half_day') {

			return updateHalfDayTime(space.ws_avail, day);

		} else if (selectedTerm === 'day') {

			return updateFullDayTime(space.ws_avail, day);
		}
	};

	getPricingModel = () => {

		const {countryCode, errors, space, selectedTerm, startTime, endTime, time, capacity, startDate, coupon, couponType, couponPrice, couponOneTime, selectedCountry, selectedCity, selectedLayout, isRecurring, recurringType, recurringNum, recurring, selectedAmenities} = this.state;


		const day = getDayName(startDate);
		const vat = space.tax_percent_exception === 'No' ? getVatPercentages(space.country) : parseFloat(space.tax_percent);
		const transaction_fee = (space.user_transaction_fee) ? space.user_transaction_fee : 5;

		const start_hr = (startTime) ? parseInt(startTime.substr(0, 2)) : (time) ? parseInt(time.substr(0, 2)) : 0;
		const end_hr = (endTime) ? parseInt(endTime.substr(0, 2)) : (time) ? parseInt(time.substr(9, 2)) : 0;
		const finalRecurringNum = (recurringNum > 0) ? recurringNum : 1;
		const bookingCount = (isRecurring) ? ((recurringType === 'monthly') ? finalRecurringNum : (Object.keys(recurring).length) * finalRecurringNum) : 1;
		const timeDiff = (selectedTerm === 'day' || selectedTerm === 'half_day') ? 1 : Math.ceil(end_hr - start_hr);

		let price = (selectedTerm === 'day') ? parseFloat(space.day_price) : (selectedTerm === 'half_day') ? parseFloat(space.half_day_price) : (selectedTerm === 'hourly') ? parseFloat(space.hour_price) : parseFloat(space.hour_price);

		if (space.exception_rules === 'yes') {
			if (space.exception_day1 === day) {
				price = (selectedTerm === 'day') ? parseFloat(firstNonZero(space.exception_day_price1, space.day_price)) : (selectedTerm === 'half_day') ? parseFloat(firstNonZero(space.exception_half_day_price1, space.half_day_price)) : (selectedTerm === 'hourly') ? parseFloat(firstNonZero(space.exception_hour_price1, space.hour_price)) : space.hour_price;
			} else if (space.exception_day2 === day) {
				price = (selectedTerm === 'day') ? parseFloat(firstNonZero(space.exception_day_price2, space.day_price)) : (selectedTerm === 'half_day') ? parseFloat(firstNonZero(space.exception_half_day_price2, space.half_day_price)) : (selectedTerm === 'hourly') ? parseFloat(firstNonZero(space.exception_hour_price2, space.hour_price)) : space.hour_price;
			}
		}
		let discountData: Discount = {
			value: couponPrice,
			one_time: couponOneTime === 'yes',
			type: couponType === 'percent' ? "percentage" : "absolute",
		}
		console.log(space.exception_hour_price1 || space.hour_price, space.exception_hour_price1, space.hour_price, price)
		let pricing_model =  new ShortTermPricingModel(price, discountData, transaction_fee, vat, (space.subscription_pricing?.st_price ?? 0), timeDiff, bookingCount)
		console.log({pricing_model})
		return pricing_model
	}
	printPriceDetails = (space, coupon, couponType, couponPrice, selectedTerm, startDate, startTime, endTime, isRecurring, recurring, recurringType, recurringNum, currency) => {

		if (!startDate) return (<></>);


		const day = getDayName(startDate);
		const start_hr = (startTime) ? parseInt(startTime.substr(0, 2)) : 0;
		const end_hr = (endTime) ? parseInt(endTime.substr(0, 2)) : 0;
		const finalRecurringNum = (recurringNum > 0) ? recurringNum : 1;
		const bookingCount = (isRecurring) ? ((recurringType === 'monthly') ? finalRecurringNum : (Object.keys(recurring).length) * finalRecurringNum) : 1;

		const timeDiff = Math.ceil(end_hr - start_hr);

		let pricing_model = this.getPricingModel()
		var elements: any[] = [];

		elements.push(
			<div className="col-12 pr-0 pl-0">
				<div className="booking-price">
					<div className="b-title">
						{(selectedTerm === 'day') ? '1 day' : (selectedTerm === 'half_day') ? 'half day' : (selectedTerm === 'hourly' && timeDiff > 0) ? timeDiff + ' hour' : '-'}
						{(space.exception_rules === 'yes' && (space.exception_day1 === day || space.exception_day2 === day) && ('(' + day + ' Exception price)'))}
						{(bookingCount > 1) ? ' x ' + (bookingCount) + ' times' : ''}
					</div>
					<div className="b-price">
						{printPrice(pricing_model.subtotal, space.currency, currency.name)}
					</div>
				</div>
			</div>
		);

		if (coupon !== '' && couponType !== '' && couponPrice !== 0) {

			elements.push(
				<div className="col-12 pr-0 pl-0">
					<div className="booking-price">
						<div className="b-title total-price">Discount</div>
						<div className="b-price total-price">
							{printPrice(pricing_model.discount, space.currency, currency.name)}
						</div>
					</div>
				</div>,
				<div className="col-12 pr-0 pl-0">
					<div className="booking-price">
						<div className="b-title total-price">Price After Discount</div>
						<div className="b-price total-price">
							{printPrice(pricing_model.price_after_discount, space.currency, currency.name)}
						</div>
					</div>
				</div>
			);

		}

		elements.push(
			<div className="col-12 pr-0 pl-0">
				<div className="booking-price">
					<div className="b-title">VAT {(pricing_model.VAT_percentage * 100).toFixed(2)}%</div>
					<div className="b-price">
						{printPrice(pricing_model.VAT, space.currency, currency.name)}
					</div>
				</div>
			</div>
		);


		elements.push(
			<div className="col-12 pr-0 pl-0">
				<div className="booking-price">
					<div className="b-title total-price">Price Inc. VAT</div>
					<div className="b-price total-price">
						{printPrice(pricing_model.price_inc_VAT, space.currency, currency.name)}
					</div>
				</div>
			</div>
		);


		elements.push(
			<div className="col-12 pr-0 pl-0">
				<div className="booking-price">
					<div className="b-title">User transaction Fee {pricing_model.transaction_fee_percentage * 100}%
					</div>
					<div className="b-price">
						{printPrice(pricing_model.transaction_fee, space.currency, currency.name)}
					</div>
				</div>
			</div>
		);
		elements.push(
			<div className="col-12 pr-0 pl-0">
				<div className="booking-price">
					<div className="b-title">Total Price</div>
					<div className="b-price">
						{printPrice(pricing_model.amount_charged, space.currency, currency.name)}
					</div>
				</div>
			</div>
		);


		return elements;
	};

	handleDateChangeRaw = e => {
		e.preventDefault();
	};

	printRecurring = (startDate, recurring, recurringType, recurringNum) => {

		const finalType = (recurringType === 'monthly') ? 'month' : 'week';
		const finalCount = (recurringNum > 0) ? recurringNum : 1;
		const finalTime = (finalCount > 1) ? 'times' : 'time';
		var selectedDays = '';
		if (recurringType === 'weekly') {
			week.forEach((day, i) => {
				if (recurring[day]) selectedDays += fullWeek[i] + ', ';
			});
		}
		selectedDays = selectedDays.substr(0, selectedDays.length - 2);

		const finalRecurring = (recurringType === 'monthly') ? ((recurring.monthly === 1) ? daysInMonth(startDate) : startDate.getDate() + nth(startDate.getDate())) : selectedDays;

		return 'Every ' + finalType + ' on ' + finalRecurring + ' for ' + finalCount + ' ' + finalTime;
	};

	toggleRecurring = isRecurring => {

		if (isRecurring) {

			const {startDate} = this.state;
			var recurring = [];
			const day = week[startDate.getDay()];
			recurring[day] = true;

			this.setState({isRecurring: true, recurring: recurring, recurringNum: 10, recurringType: 'weekly'});

		} else {

			this.setState({isRecurring: false, recurring: {}, recurringNum: 0, recurringType: 'weekly'})
		}
	};

	handleChooseRecurringType = e => {

		const {startDate} = this.state;
		var recurring = this.state.recurring;

		const type = e.target.value;
		if (type === 'monthly') {
			recurring = {monthly: 0};
		} else {
			delete recurring.monthly;
			const day = week[startDate.getDay()];
			recurring[day] = true;
		}
		this.setState({recurringType: type, recurring: recurring});
	};

	handleChooseRecurring = day => {

		const {startDate, recurringType} = this.state;
		var recurring = this.state.recurring;

		if (recurringType === 'monthly') {
			recurring = {monthly: day};
		} else {
			delete recurring.monthly;
			if (recurring[day]) {
				delete recurring[day];
				if (Object.keys(recurring).length < 1) {
					const day = week[startDate.getDay()];
					recurring[day] = true;
				}
			} else {
				recurring[day] = true;
			}
		}
		this.setState({recurring: recurring});
	};

	bookSpace = e => {
		e.preventDefault();
		if (!this.state.reqLoading) {

			this.setState({reqLoading: true});

			const {match, defCurrency} = this.props;
			const {countryCode, errors, space, selectedTerm, startTime, endTime, time, capacity, startDate, coupon, couponType, couponPrice, couponOneTime, selectedCountry, selectedCity, selectedLayout, isRecurring, recurringType, recurringNum, recurring, selectedAmenities} = this.state;

			const user = getUser();


			Object.keys(errors).forEach(key => {
				if (!errors[key]) delete errors[key];
			});

			this.setState({errors: errors});
			if (Object.keys(errors).length > 0) {
				this.setState({reqLoading: false});
				return;
			}
			if (startDate === null) {
				this.setState({reqLoading: false});
				this.setState({errorMessagevalidation: 'Enter a valid date'})
				window.scrollTo({top: 350, left: 0, behavior: 'smooth'});
				return;
			}else{
				this.setState({errorMessagevalidation: ''})
			}

			const currency = getCurrency(defCurrency);
			const day = getDayName(startDate);
			const vat = 5;


			const start_hr = (startTime) ? parseInt(startTime.substr(0, 2)) : (time) ? parseInt(time.substr(0, 2)) : 0;
			const end_hr = (endTime) ? parseInt(endTime.substr(0, 2)) : (time) ? parseInt(time.substr(9, 2)) : 0;
			const finalRecurringNum = (recurringNum > 0) ? recurringNum : 1;
			const bookingCount = (isRecurring) ? ((recurringType === 'monthly') ? finalRecurringNum : (Object.keys(recurring).length) * finalRecurringNum) : 1;
			const timeDiff = Math.ceil(end_hr - start_hr);

			let pricing_model = this.getPricingModel()
			if (!pricing_model.space_price) {
				alert("Error in Pricing, if this persists please contact the support team")
				return;
			}


			const dayTime = space.ws_avail[day.toLowerCase()];
			const gstart_hr = (dayTime === "open_24_hours") ? 0 : (parseInt(dayTime.substr(0, 2)));
			const gstart_min = (dayTime === "open_24_hours") ? 0 : (parseInt(dayTime.substr(3, 2)));
			const gend_hr = (dayTime === "open_24_hours") ? 24 : (parseInt(dayTime.substr(9, 2)));
			const gend_min = (dayTime === "open_24_hours") ? 0 : (parseInt(dayTime.substr(12, 2)));
			const halfDayTime = ("0" + gstart_hr).slice(-2) + ':' + ("0" + gstart_min).slice(-2) + ":00-" + ("0" + gend_hr).slice(-2) + ':' + ("0" + gend_min).slice(-2) + ":00";
			const fullDayTime = ("0" + gstart_hr).slice(-2) + ':' + ("0" + gstart_min).slice(-2) + ":00-" + ("0" + gend_hr).slice(-2) + ':' + ("0" + gend_min).slice(-2) + ":00";

			let amenities = {}

			CATERED_AMENITIES.forEach(
				(amenity) => {
					amenities[`chk_${amenity.key}`] = Boolean(selectedAmenities?.[amenity.id])
				}
			)

			const url = getApiLinkV2('workspace/booking');
			const token = getToken();


			const reqData = {
				submit_new: true,
				amenities,
				workspace_id: match.params.id,
				workspace_type_id: space.workspace_type_id,
				user_id: user?.id ?? "",
				reservation_type: selectedTerm,
				tax_name: 'VAT',
				min_booking_hrs: ((this.state.term === 'hourly' && space.min_booking_hrs) ? parseInt(space.min_booking_hrs) : 1),
				recurring: (isRecurring ? 'on' : 'off'),
				day: Object.keys(recurring),
				monthly_type: (recurring.monthly && recurring.monthly === 1) ? "day" : "date",
				monthly_date: "",

				currency: 'USD',
				final_currency: 'USD',
				final_payment: pricing_model.final_price,
				recurring_count: bookingCount,
				duration: timeDiff,
				recurringType: recurringType,
				subtotal: pricing_model.subtotal,
				sales_tax: vat,
				sales_tax_amt: pricing_model.VAT,
				amt_due_after_tax: pricing_model.price_inc_VAT,
				user_transaction_fee_amt: pricing_model.transaction_fee,
				amt_due_after_fee: pricing_model.amount_charged,
				discount_type: couponType,
				discount: couponPrice,
				discount_amt: pricing_model.discount,
				amt_due_after_disc: pricing_model.final_price,
				sales_price: pricing_model.final_price,
				total_price: pricing_model.final_price,
				total_recurring_amt: pricing_model.final_price,
				amount_due_rec: pricing_model.final_price,
				total_price_rec: pricing_model.final_price,
				fluidmeet_amount: pricing_model.owed_to_fm_inc_VAT,
				venue_amount: pricing_model.owed_to_venue_inc_VAT,

				hour_price: space.hour_price,
				half_day_price: space.half_day_price,
				day_price: space.day_price,
				exception_rules: space.exception_rules,
				immediate_capture: false,

				booking_date: printDateForServer(startDate),
				start_time: startTime,
				end_time: endTime,
				capacity: capacity,
				coupon_code: coupon,
				half_day_time: ((selectedTerm == 'half_day') ? this.time.current?.value : halfDayTime),
				day_time: ((selectedTerm == 'day') ? this.time.current?.value : fullDayTime),
				layout: selectedLayout,
				duration_note_txt: "",
				duration_note_txt_usd: "",
				title: "",

				redirect_url: this.getVerificationUrl(),
				space_type: space.workspace_type_id,
			}


			devLog(reqData.redirect_url);
			devLog(reqData, {recurring, keys: Object.keys(recurring)});
			// return;
			getAxios().post(url, reqData, {
				headers: {
					'Cache-Control': 'no-cache',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token,
				},
			}).then(response => {
				if (response.data.intent) {
					// space.order_id = response.data.booking_reference;
					// space.payment_id = response.data.p_id;
					// resetBookings();
					// setSpaceBooking(space);
					this.setState({reqLoading: false, paymentIntent: response.data.intent});
					window.removeEventListener('beforeunload', this.handleUnLoad);
					window.open(response.data.payment_url, '_top');
					this.props.history.push(ShortTermBooking.moveRoute(this.props.match.params, 'checkout'), {space})
					return;
				}
				console.log(response);
				this.setState({reqLoading: false});
			}).catch(error => {
				devLog({error});
				Bugsnag.notify(error);
				if (error.response?.data) {
					devLog(error.response?.data);
				}
				createAlert(this, 'danger', (error.response && error.response?.data?.message) ? 'Error: ' : '', (error.response && error.response?.data?.message) ? error.response?.data?.message : error.message);
				this.setState({reqLoading: false});
			});
		}
	};

	initPlans = () => {

		const {space} = this.state;
		var selectedTerm = 'hourly';
		selectedTerm = (space.day_pass && space.day_price > 0) ? 'day' : '';
		selectedTerm = (space.half_day_pass && space.half_day_price > 0) ? 'half_day' : selectedTerm;
		selectedTerm = (space.hour_pass && space.hour_price > 0) ? 'hourly' : selectedTerm;
		this.setState({selectedTerm: selectedTerm});
	};

	initDate = () => {

		const {generic} = this.props;
		const {space} = this.state;

		if (generic) {
			const today = new Date();
			const tomorrow = addDays(today, 1);
			this.setState({startDate: today, endDate: today, accomStartDate: today, accomEndDate: tomorrow,});
			// this.updateSelectedTimes(today, null, null);
			return;
		}

		var excluded = {}
		Object.keys(space.ws_avail).forEach(day => {
			const val = space.ws_avail[day];
			if (val === "closed") {
				if (day === 'sunday') {
					excluded[0] = true;
				} else if (day === 'monday') {
					excluded[1] = true;
				} else if (day === 'tuesday') {
					excluded[2] = true;
				} else if (day === 'wednesday') {
					excluded[3] = true;
				} else if (day === 'thursday') {
					excluded[4] = true;
				} else if (day === 'friday') {
					excluded[5] = true;
				} else if (day === 'saturday') {
					excluded[6] = true;
				}
			}
		});

		const nextAvlDate = new Date();
		const date = this.getNextAvlDay(excluded, nextAvlDate, 0);
		const nextDate = addDays(date, 1);

		this.setState({
			startDate: null,
			endDate: null,
			accomStartDate: date,
			accomEndDate: nextDate,
			excludedDays: excluded,
		});
		this.updateSelectedTimes(date, null, null);
	};

	getNextAvlDay = (excluded, date, counter) => {

		var dayIndex = date.getDay();
		if (dayIndex > 6) {
			dayIndex = dayIndex - 6;
		}
		if (counter > 6) {
			counter = counter - 6;
		}

		const formattedDate = date.toLocaleDateString("en-US", {year: 'numeric', month: 'short', day: 'numeric'});

		if (excluded[dayIndex] || excludedDates[formattedDate]) {
			const nextDate = addDays(date, 1);
			return this.getNextAvlDay(excluded, nextDate, counter + 1);
		} else {
			var newDate = new Date();
			newDate.setDate(new Date().getDate() + counter);
			return newDate;
		}
	};

	updateDate = date => {

		let fStartTime = this.state.startTime, fEndTime = this.state.endTime;

		let start_hr = parseInt(fStartTime.substr(0, 2));
		let end_hr = parseInt(fEndTime.substr(0, 2));
		let start_min = parseInt(fStartTime.substr(3, 2));
		let end_min = parseInt(fEndTime.substr(3, 2));
		let time_diff = (end_hr - start_hr);

		start_hr = 9;

		for (let index = 0; index < this.state.bookings.length; index++) {
			const booking = this.state.bookings[index];
			let idate = new Date(date);
			
			let bsdate = new Date(booking.start);
			let bsd = bsdate.getDate();
			let bshr = bsdate.getHours();
			
			let bedate = new Date(booking.end);
			let behr = bedate.getHours();
			
			if (bsd === idate.getDate() && start_hr >= bshr && start_hr < behr) {
				start_hr = behr;
			}
		}

		fStartTime = ( start_hr< 10 ? '0' + start_hr: start_hr) +':'+ ( start_min< 10 ? '0' + start_min: start_min) +':00';
		fEndTime = (start_hr + time_diff) + ':'+ ( end_min< 10 ? '0' + end_min: end_min) +':00';
		
		// if (this.state.isCalendarEnabled) {
		// 	if(!checkAvailableTimeFromBooking(date, this.state.startTime, this.state.bookings)) {
		// 		fStartTime = '';
		// 	}
		// 	if (fStartTime !== '') {
		// 		if(!checkAvailableEndTimeFromBooking(date, fStartTime, this.state.endTime, this.state.bookings)) {
		// 			fEndTime = '';
		// 		}
		// 	}
		// }

		this.setState({startDate: date, startTime: fStartTime, endTime: fEndTime});
		// this.updateSelectedTimes();
	};

	handleSelectPlan = term => {

		if (this.state.isCalendarEnabled) {
			for (let index = 0; index < this.state.bookings.length; index++) {
				const booking = this.state.bookings[index];
				let day = new Date(booking.start);
				const formattedDate = day.toLocaleDateString("en-US", {year: 'numeric', month: 'short', day: 'numeric'});
				if (term === 'day') {
				excludedDates[formattedDate] = true;
				} else {
					delete excludedDates[formattedDate];
				}
			}
		}
		this.updateSelectedTimes(null, term, null);

		this.setState({selectedTerm: term});
	};

	onStartTimeChange = () => {

		const startTime: any = this.start_time.current?.value;
		this.setState({startTime: startTime});
		this.updateSelectedTimes(null, null, startTime);
	};

	onEndTimeChange = () => {
		console.log(this.end_time.current?.value)
		this.setState({endTime: this.end_time.current?.value});
	};

	onTimeChange = () => {
		console.log(this.time.current?.value)
		this.setState({time: this.time.current?.value});
	};

	checkstartTime = () => {

	};

	updateSelectedTimes = (date = null, term = null, start = null) => {

		const {generic} = this.props;
		const {space, days, dayIndex, selectedTerm, startDate, startTime,} = this.state;
		const formattedDate = new Date(startDate).toLocaleDateString("en-US", {year: 'numeric', month: 'short', day: 'numeric'});

		if (Object.keys(excludedDates).includes(formattedDate)) {
			this.setState({
				startDate: ''
			})
		}

		var finalDate = (date) ? date : startDate;
		var finalTerm = (term) ? term : selectedTerm;
		var finalStartTime = (start) ? start : startTime;
		if (!finalDate) return;
		var dayIdx = finalDate.getDay();

		var day = '';
		if (dayIdx === 0) {
			day = 'sunday';
		} else if (dayIdx === 1) {
			day = 'monday';
		} else if (dayIdx === 2) {
			day = 'tuesday';
		} else if (dayIdx === 3) {
			day = 'wednesday';
		} else if (dayIdx === 4) {
			day = 'thursday';
		} else if (dayIdx === 5) {
			day = 'friday';
		} else if (dayIdx === 6) {
			day = 'saturday';
		}

		var fStartTime = "";
		var fEndTime = "";
		var fTime = "";

		if (generic) {
			const day = days[dayIndex];
			const start_hr = (finalStartTime) ? parseInt(finalStartTime.substr(0, 2)) : 7;
			const start_min = (finalStartTime) ? parseInt(finalStartTime.substr(3, 2)) : 0;
			day.startTime = ("0" + start_hr).slice(-2) + ':' + ("0" + start_min).slice(-2) + ":00"
			day.endTime = ("0" + (start_hr + 1)).slice(-2) + ':' + ("0" + start_min).slice(-2) + ":00"
			this.setState({days: days});
			return;
		}
		
		if (!space.ws_avail || (space.ws_avail[day] == null) || space.ws_avail[day] === "closed") {
			this.setState({startTime: "00:00:00", endTime: "00:00:00", time: "00:00:00"});
			return;
		}
		
		const dayTime = space.ws_avail[day];
		
		const minHoursDiff = (this.state.term === 'hourly' && space.min_booking_hrs) ? parseInt(space.min_booking_hrs) : 1;
		
		if (this.state.term === 'catered' || finalTerm === 'hourly') {
			const start_hr = (finalStartTime) ? parseInt(finalStartTime.substr(0, 2)) : ((dayTime === "open_24_hours") ? 0 : (parseInt(dayTime.substr(0, 2))));
			const start_min = (finalStartTime) ? parseInt(finalStartTime.substr(3, 2)) : ((dayTime === "open_24_hours") ? 0 : parseInt(dayTime.substr(3, 2)));
			fStartTime = ("0" + start_hr).slice(-2) + ':' + ("0" + start_min).slice(-2) + ":00"
			fEndTime = ("0" + (start_hr + minHoursDiff)).slice(-2) + ':' + ("0" + start_min).slice(-2) + ":00"
			
			if (this.state.term === 'catered' && days.length > 0) {
				const day = days[dayIndex];
				day.startTime = fStartTime;
				day.endTime = fEndTime;
				this.setState({days: days});
			} else {
				this.setState({startTime: fStartTime, endTime: fEndTime});
			}

			console.log(checkAvailableEndTimeFromBooking(finalDate, fStartTime, fEndTime, this.state.bookings));

			if (this.state.isCalendarEnabled) {
				if (fStartTime !== '') {
					if(!checkAvailableEndTimeFromBooking(finalDate, fStartTime, fEndTime, this.state.bookings)) {
						fEndTime = '';
					}
				}
	
				this.setState({startTime:fStartTime, endTime: fEndTime })
			}

		} else if (finalTerm === 'half_day') {

			const start_hr = (dayTime === "open_24_hours") ? 0 : (parseInt(dayTime.substr(0, 2)));
			const start_min = (dayTime === "open_24_hours") ? 0 : parseInt(dayTime.substr(3, 2));
			fTime = ("0" + start_hr).slice(-2) + ':' + ("0" + start_min).slice(-2) + ":00-" + ("0" + start_hr + 4).slice(-2) + ':' + ("0" + start_min).slice(-2) + ":00"

			this.setState({time: fTime});

		} else if (finalTerm === 'day') {

			const start_hr = (dayTime === "open_24_hours") ? 0 : (parseInt(dayTime.substr(0, 2)));
			const start_min = (dayTime === "open_24_hours") ? 0 : parseInt(dayTime.substr(3, 2));
			const end_hr = (dayTime === "open_24_hours") ? 24 : parseInt(dayTime.substr(9, 2));
			const end_min = (dayTime === "open_24_hours") ? 0 : parseInt(dayTime.substr(12, 2));
			fTime = ("0" + start_hr).slice(-2) + ':' + ("0" + start_min).slice(-2) + ":00-" + ("0" + end_hr).slice(-2) + ':' + ("0" + end_min).slice(-2) + ":00"

			this.setState({time: fTime});
		}
	};

	checkCouponCode = () => {

		this.setState({couponLoading: true});

		const code = this.coupon.current?.value;
		if (!code || code === '') {
			this.setState({coupon: '', couponType: '', couponPrice: 0, couponLoading: false});
			return
		}

		const url = getApiLinkV2('workspace/coupon/' + code);
		const token = getToken();
		const headers = {'Content-Type': 'application/json'};
		if (token) {
			headers['Authorization'] = 'Bearer ' + token;
		}
		getAxios().get(url, {
			headers: headers,
		}).then(response => {
			if (response.data.status && response.data.status === 'active') {
				createAlert(this, 'success', '', 'Coupon has been added successfully.');
				this.setState({
					coupon: code,
					couponType: response.data.coupon_type,
					couponPrice: response.data.coupon_price,
					couponLoading: false,
					couponOneTime: response.data.var_one_time,
				});
				return
			}
			createAlert(this, 'danger', '', (response.data.status && response.data.status === 'expired') ? 'Expired Coupon' : 'Invalid coupon code');
			this.setState({coupon: '', couponType: '', couponPrice: 0, couponLoading: false});
		}).catch(error => {
			Bugsnag.notify(error);
			createAlert(this, 'danger', (error.response && error.response.data.message) ? 'Error: ' : '', (error.response && error.response.data.message) ? error.response.data.message : error.message);
			this.setState({coupon: '', couponType: '', couponPrice: 0, couponLoading: false});
		});
	};

	getVerificationUrl = () => (
		new URL(
			PaymentVerification.generatePath(
				{
					booking_type: "st",
					workspace_id: this.props.match.params.id,
					workspace_type: getTypeTextKey(this.state.space.workspace_type_id),
					event: 'verify',
					payment_id: this.state.paymentIntent?.metadata['order_id']
				}
			),
			window.location.origin
		).toString()
	)

	render() {

		const {match, location, defCurrency} = this.props;
		const {
			page, space, countryCode, term, reqAlert, reqLoading, selectedCountry, selectedCity,
			couponLoading, showCoupon, coupon, couponType, couponPrice, selectedTerm, selectedLayout,
			selectedAmenities, progress, time, startDate, endDate, startTime, endTime, excludedDays, capacity,
			isRecurring, recurring, recurringType, recurringNum, request, errors, paymentIntent, loading, errorMessagevalidation, additionalExcludeDates
		} = this.state;

		const currency = getCurrency(defCurrency);
		const currencyList = getCurrencies();
		const user = getUser();
		console.log({user, request, space, startTime, endTime})
		if (loading) {
			return <LoadingComponent/>
		} else {

			if (!user) {
				return (<Redirect to={{pathname: '/login', state: {prevLocation: location}}}/>);
			}
			if (!space) {
				return (<Redirect to={'/space/' + match.params.id + '?t=' + term}/>);
			}
			if (space && Object.keys(space).length !== 0) {
				return (
					<>
						{page === 'details' ? (<>
							<Helmet>
					
								{/* Event snippet for Generic LT Success (2024) conversion page */}
								<script>
								{`
									window.gtag = window.gtag || function() { window.dataLayer.push(arguments); };
									gtag('js', new Date());
									gtag('config', 'AW-935885959');
									window.gtag('event', 'conversion', {"send_to": "'AW-935885959/lRvICPnX-fUCEIf5ob4D'"});
									window.gtag('event', 'conversion', {'send_to': 'AW-935885959/pIhxCN_a9_UCEIf5ob4D'});
									window.gtag('event', 'conversion', {'send_to': 'AW-935885959/vpqTCIiEyvUCEIf5ob4D'});
								`}
								</script>

							</Helmet>
						</>) : (<>
							<Helmet>
					
								{/* Event snippet for Generic LT Success (2024) conversion page */}
								<script>
								{`
									window.gtag = window.gtag || function() { window.dataLayer.push(arguments); };
									gtag('js', new Date());
									gtag('config', 'AW-935885959');
									window.gtag('event', 'conversion', {'send_to': 'AW-935885959/AXVTCPG1_PUCEIf5ob4D'});
									window.gtag('event', 'conversion', {'send_to': 'AW-935885959/QTz6CNjfz_UCEIf5ob4D'});
									window.gtag('event', 'conversion', {'send_to': 'AW-935885959/6aCNCI_S9fUCEIf5ob4D'});
									window.gtag('event', 'conversion', {'send_to': 'AW-935885959/210OCIOYy_UCEIf5ob4D'});
								`}
								</script>

							</Helmet>
						</>)}

						<NavBar backLink={'/space/' + match.params.id + '?t=' + term} smallLogo={true}
						        smallNav={true} hideSearch={true} hideHostBtn={true} progress={progress}
						        pageTitle={'Request to book space'}/>

						<div
							ref={this.slideDiv}
							id="slide-cont"
							className={"container-fluid slide-cont sticky "}
						>
							<div className="container-lg">
								<div className="booking-page   h-z pt-1 ">
									<div className="space-container">
										<div className="space-content"/>
										<div ref={this.stickDiv} id="space-stick" className="space-stick">

											{space && (
												<div className="book-toprow">
													<div style={{height: 80}}>
														<div className="space-div" style={{flexDirection: "row"}}>
															<img className="space-img"
															     src={"https://fluidmeet.s3.amazonaws.com/venue_logos/" + space.image}/>

															{/*<div style={{position:'absolute',top: 0,left: 10,right: 10,height: 'calc(100% - 140px)'}} />*/}

															<div className="space-details pt-2"
															     style={{height: 'auto'}}>
																<div className="space-rate">
																	<FontAwesomeIcon icon={faStar}
																	                 style={{color: (space.workspace_rating && space.workspace_rating > 0) ? '#00a9e0' : '#E0E0E0'}}/>{" "}
																	{(space.workspace_rating && space.workspace_rating > 0) ? (
																		space.workspace_rating
																	) : (
																		// (space.date_added) ? (
																		//   isSpaceOld(space.date_added)
																		// ) : (
																		""
																		// )
																	)}
																	{(space.reviews > 0) ? (
																		<span className="full">({space.reviews}) { space.rti_id && <span className="btn btn-sm btn-outline-success">Instant</span>}</span>
																	) : (
																		<>{ space.rti_id && <span className="btn btn-sm btn-outline-success">Instant</span>}</>
																	)}
																</div>
																<span className="space-location">{space.front_address}</span>
																<span className="space-title">{space.name}</span>
															</div>
														</div>
													</div>
												</div>
											)}


											{window.innerHeight <= 700 ? 
											<div className="space-about booking-p scroll-payment-summary-st-less-700">
												<h3 className="pb-1">Price details</h3>
												{this.printPriceDetails(space, coupon, couponType, couponPrice, selectedTerm, startDate, startTime, endTime, isRecurring, recurring, recurringType, recurringNum, currency)}
											</div>
											:
											<div className="space-about booking-p scroll-payment-summary-st">
												<h3 className="pb-1">Price details</h3>
												{this.printPriceDetails(space, coupon, couponType, couponPrice, selectedTerm, startDate, startTime, endTime, isRecurring, recurring, recurringType, recurringNum, currency)}
											</div>}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="container-fluid bg-white h-z">
							<div className="container-lg h-z pl-mobile-0 pr-mobile-0">
								<div className={"booking-page ps-187 h-z "}>

									<h1 className="full-title h-z">

										<FontAwesomeIcon icon={faArrowLeft}
										                 onClick={this.handleExitBooking}
										                 style={{
											                 color: '#000000',
											                 fontSize: 20,
											                 cursor: 'pointer',
											                 marginRight: 24,
											                 marginBottom: 4
										                 }}/>
										{
											page === 'details' ? "Request to book space" : "Checkout"
										}

									</h1>

									<div className="space-container">

										<div className="space-content h-z">

											<div className="form-general p-0">
												{
													(page === 'details') ? (
															<form className="mt-4 form-flex" autoComplete="on"
															      onSubmit={this.bookSpace}>


																<>
																	<div className="space-about pt-3 pb-0 border-0">
																		<h3>Plan</h3>
																		<div className="row pr-2 pl-2 mb-3">

																			{(space.hour_pass && space.hour_price > 0 && (
																				<div className="col-md-4 col-12 pr-2 pl-2">
																					<div
																						className={"space-options " + ((selectedTerm === 'hourly') ? 'options-selected' : '')}
																						onClick={() => this.handleSelectPlan('hourly')}>
																						<div className="bold">Hour</div>
																						<div className="pt-1">
																							{printPrice(space.hour_price, space.currency, currency.name, false)}
																						</div>
																					</div>
																				</div>
																			))}

																			{(space.half_day_pass && space.half_day_price > 0 && (
																				<div className="col-md-4 col-12 pr-2 pl-2">
																					<div
																						className={"space-options " + ((selectedTerm === 'half_day') ? 'options-selected' : '')}
																						onClick={() => this.handleSelectPlan('half_day')}>
																						<div className="bold">Half day</div>
																						<div className="pt-1">
																							{printPrice(space.half_day_price, space.currency, currency.name, false)}
																						</div>
																					</div>
																				</div>
																			))}

																			{(space.day_pass && space.day_price > 0 && (
																				<div className="col-md-4 col-12 pl-2 pr-2">
																					<div
																						className={"space-options " + ((selectedTerm === 'day') ? 'options-selected' : '')}
																						onClick={() => this.handleSelectPlan('day')}>
																						<div className="bold">Day</div>
																						<div className="pt-1">
																							{printPrice(space.day_price, space.currency, currency.name, false)}
																						</div>
																					</div>
																				</div>
																			))}

																		</div>
																	</div>

																	<div className="space-about pt-4 pb-2 border-0">
																		<h3>Booking info</h3>
																		<div className="row mb-2">

																			<div className="col-12 pr-3 pl-3 pt-4">
																				<h5 className="pb-2">Date</h5>
																				<div className="selector date-picker">
																					<FontAwesomeIcon icon={faCalendar}
																					                 style={{
																						                 width: 24,
																						                 height: 24,
																						                 color: '#989898',
																						                 position: 'absolute',
																						                 top: 15,
																						                 right: 15,
																					                 }}/>
																					<div
																						className="dates-picked">{(startDate) ? startDate.toLocaleDateString("en-US", dateOptions) : ''}</div>
																					<DatePicker
																						onFocus={(e) => e.target.readOnly = true}
																						onChangeRaw={this.handleDateChangeRaw}
																						excludeDates={Object.keys(excludedDates).map(date => new Date(date)).concat(additionalExcludeDates)}
																						filterDate={(date) => excludedDays[date.getDay()] == null}
																						selected={startDate}
																						minDate={new Date()}
																						onChange={this.updateDate}
																						disabledNavigation/>
																				</div>
																				<span style={{fontWeight: 'bold',color: 'red',}}>{errorMessagevalidation}</span>
																			</div>

																			<div
																				className={"col-6 pt-2 pr-mobile-1 " + ((selectedTerm === 'hourly') ? "" : "hide")}>
																				<h5 className="pb-2">Start time</h5>
																				<div className="selector mb-0">
																					<select ref={this.start_time}
																					        name="start_time"
																					        id="start_time"
																					        className="select"
																					        value={startTime}
																					        onChange={this.onStartTimeChange}>
																						{this.printTimeOptions(space, selectedTerm, startDate, 'start', startTime)}
																					</select>
																				</div>
																			</div>
																			<div
																				className={"col-6 pt-2 pl-mobile-1 " + ((selectedTerm === 'hourly') ? "" : "hide")}>
																				<h5 className="pb-2">End time</h5>
																				<div className="selector mb-0">
																					<select ref={this.end_time}
																					        name="end_time" id="end_time"
																					        className="select"
																					        value={endTime}
																					        onChange={this.onEndTimeChange}>
																						{this.printTimeOptions(space, selectedTerm, startDate, 'end', startTime)}
																					</select>
																				</div>
																			</div>

																			<div
																				className={"col-12 pt-2 " + ((selectedTerm === 'hourly') ? "hide" : "")}>
																				<h5 className="pb-2">Time</h5>
																				<div className="selector">
																					<select ref={this.time} name="time"
																					        id="time" className="select"
																					        value={time}
																					        onChange={this.onTimeChange}>
																						{this.printTimeOptions(space, selectedTerm, startDate)}
																					</select>
																				</div>
																			</div>

																			{(selectedTerm === 'hourly' && parseInt(space.min_booking_hrs) > 1 && (
																				<div
																					className="mt-2 ml-3 mr-3 mb-3 w100">Min
																					booking: {(space.min_booking_hrs) ? space.min_booking_hrs : '1'}{(space.min_booking_hrs > 1) ? ' hours ' : ' hour '}min</div>
																			))}

																			{(space.layouts && Object.keys(space.layouts).length > 0 && (
																				<div
																					className="col-12 pl-3 pr-3 pt-4 pb-3 border-0">
																					<h5>Select layout</h5>
																					<div className="row pl-2 pr-2">
																						{Object.keys(space.layouts).map(key => (
																							<div key={key}
																							     className="layout-col">
																								<div
																									className={"space-layout " + ((selectedLayout === key) ? 'options-selected' : '')}
																									onClick={() => this.setState({
																										selectedLayout: key,
																										selectedLayoutMax: space.layouts[key]
																									})}>
																									<img
																										src={getLayoutImg(key)}/>
																									<div
																										className="layout-count">{space.layouts[key]}</div>
																								</div>
																							</div>
																						))}
																					</div>
																				</div>
																			))}

																			<div
																				className="col-12 more-counter mb-2 border-0">
																				<h5 className="capacity-title">Capacity</h5>
																				<span id="count" className="counter">
	                                    <input type="number" className="form-control" placeholder="1" value={capacity}
	                                           onChange={this.handleUpdateCapacity}
											   onWheel={ event => event.currentTarget.blur() }
											   />
	                                  </span>
																			</div>

																			<div
																				className="col-12 mt-3 mb-2 display-flex more-dates border-0">
																				<div className="title-col">
																					<h5 className="mt-2">Recurring</h5>
																				</div>
																				<div className="on-off-col">
	                                    <span className="on-off">
	                                      <span className={(isRecurring) ? "on-active" : ""}
	                                            onClick={() => this.toggleRecurring(true)}>Yes</span>
	                                      <span className={(isRecurring) ? "" : "off-active"}
	                                            onClick={() => this.toggleRecurring(false)}>No</span>
	                                    </span>
																				</div>
																			</div>

																			{(isRecurring && (
																				<>
	                                  <span className="ml-3 mr-3 mb-3">
	                                    Note: Each booking will be recorded separately allowing you to cancel any future booking and receive a refund on that booking.
	                                  </span>
																					<div
																						className="row food-options w100f mr-3 ml-3">
																						<h6 className="text-center w100 mt-2">
																							{this.printRecurring(startDate, recurring, recurringType, recurringNum)}
																						</h6>
																						<div className="col-8 mt-3 pr-1">
																							<div className="selector">
																								<select className="select"
																								        value={recurringType}
																								        onChange={this.handleChooseRecurringType}>
																									<option
																										value="weekly">Weekly
																									</option>
																									<option
																										value="monthly">Monthly
																									</option>
																								</select>
																							</div>
																						</div>
																						<div className="col-4 mt-3 pl-1">
																							<input type="text"
																							       className="form-control"
																							       value={recurringNum}
																							       onChange={(e) => this.setState({recurringNum: e.target.value})}/>
																						</div>
																						<div className="row ml-2 mr-2">
																							{(recurringType === 'monthly') ? (
																								<>
																									<div
																										className="col-12 p-0">
																										<div
																											className="dropdown-item pr-0 pl-0">
																											<input
																												className="form-check-input"
																												type="checkbox"
																												checked={recurring.monthly !== 1}
																												onChange={() => this.handleChooseRecurring(0)}/>
																											<span
																												onClick={() => this.handleChooseRecurring(0)}>{startDate.getDate() + nth(startDate.getDate())} of the month</span>
																										</div>
																									</div>
																									<div
																										className="col-12 p-0">
																										<div
																											className="dropdown-item pr-0 pl-0">
																											<input
																												className="form-check-input"
																												type="checkbox"
																												checked={recurring.monthly === 1}
																												onChange={() => this.handleChooseRecurring(1)}/>
																											<span
																												onClick={() => this.handleChooseRecurring(1)}>{daysInMonth(startDate)} of the month</span>
																										</div>
																									</div>
																								</>
																							) : (
																								week.map((day, i) => {
																									if (excludedDays[i]) return (<></>)
																									return (
																										<div key={day}
																										     className="col-md-3 col-6 p-0">
																											<div
																												className="dropdown-item pr-0 pl-0">
																												<input
																													className="form-check-input"
																													type="checkbox"
																													checked={recurring[day] != null}
																													onChange={() => this.handleChooseRecurring(day)}/>
																												<span
																													onClick={() => this.handleChooseRecurring(day)}>{day}</span>
																											</div>
																										</div>
																									)
																								})
																							)}
																						</div>
																					</div>
																				</>
																			))}


																			{((space.workspace_type_id === "10" || space.workspace_type_id === "5") && Object.keys(space.amenity).length > 0 && (
																				<div
																					className="col-12 pl-3 pr-3 pt-3 border-0">
																					<h5>Select amenities</h5>
																					<div className="row pl-2 pr-2">
																						{(space && space.amenity && Object.keys(space.amenity).map(key => {
																							const item = space.amenity[key];
																							const amenity = printAmenity(item.amenity);
																							if (!amenity) return (<></>)
																							return (
																								<div key={item.amenity_id}
																								     className="col-md-6 col-12 p-0"
																								     onClick={() => this.handleChooseAmenity(item.amenity)}>
																									<div
																										className="dropdown-item pr-0 pl-0">
																										<input
																											id={item.amenity_id + "-checkbox"}
																											name={item.amenity_id + "-checkbox"}
																											className="form-check-input"
																											type="checkbox"
																											checked={selectedAmenities[item.amenity]}
																											onChange={() => this.handleChooseAmenity(item.amenity)}/>
																										<span>{amenity.name}</span>
																									</div>
																								</div>
																							)
																						}))}
																					</div>
																					<span>Notes: Chargeable amenities are payable at the venue on the day of your booking.</span>
																				</div>
																			))}

																		</div>
																	</div>

																	{(showCoupon) ? (
																		<>
																			<h5 className="pb-2">Coupon Code</h5>
																			<div className="row">
																				<div className="col-12 pl-3">
																					<input ref={this.coupon} id="coupon"
																					       name="coupon" type="text"
																					       className="form-control"
																					       placeholder="FM2021"/>
																					<div
																						className={"coupon-btn mr-0 apply-btn " + ((couponLoading) ? 'ld-btn' : '')}
																						onClick={this.checkCouponCode}>
																						{(couponLoading) ? (<>
																							<FontAwesomeIcon
																								icon={faCircleNotch}
																								spin={true}/> loading</>) : 'Apply'}
																					</div>
																				</div>
																			</div>
																		</>
																	) : (
																		<button className="custom-btn clear-btn couponbtn"
																		        type="button"
																		        onClick={() => this.setState({showCoupon: true})}>
																			Have a coupon?
																		</button>
																	)}

																	<div
																		className="space-about mt-3 pt-4 pb-3 booking-prices">
																		<h3 className="pb-1">Price details</h3>
																		{this.printPriceDetails(space, coupon, couponType, couponPrice, selectedTerm, startDate, startTime, endTime, isRecurring, recurring, recurringType, recurringNum, currency)}
																	</div>

																	<div
																		className="col-12 pr-0 pl-0 mt-3 pt-4 bottom-b pb-5">
																		<div className="row pt-3">
																			<div className="col-md-4 px-0 col-12">
																				<FMButton title={"Checkout"}
																				          isLoading={reqLoading}/>
																			</div>
																		</div>
																	</div>


																</>


															</form>
														)
														:
														(page === 'checkout' && paymentIntent) ? (
																<div className="mt-4">
																	<FMPayment paymentIntent={paymentIntent}
																	           redirectUrl={this.getVerificationUrl()}
																	           allowBillingDetails={true}/>
																</div>
															)
															:
															(<></>)
												}

											</div>
										</div>
									</div>

									{(reqAlert &&
										<FloatingAlert error={reqAlert}
										               onClick={() => this.setState({reqAlert: null})}/>)}

								</div>
							</div>
						</div>

						<div ref={this.bottomDiv} id="bottom-cont">
							<Footer smallNav={true} isPayment={true}/>
						</div>
					</>
				);
			} else {
				return (<></>);
			}


		}
	}


}

export const ShortTermBookingPage = connect((state: any, props) => ({
	defCurrency: state.settings.defCurrency,
}), null)(withRouter(ShortTermBooking));

export default ShortTermBookingPage;
