import React, {Component} from "react";
import {Link} from "react-router-dom";
import queryString from 'query-string';
import {createAlert, formValidation, getApiLinkV2} from "../../Helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import FloatingAlert from "../organisms/FloatingAlert";
import axios from "axios";
import {getAxios} from "src/Helpers";
import Bugsnag from "@bugsnag/js";

class Reset extends Component {

  constructor() {
    super();

    this.state = {
      emailError: null,
      passError: null,
      confirmPassError: null,
    };

    this.handleReset = this.handleReset.bind(this);
    this.handleUpdatePass = this.handleUpdatePass.bind(this);
  }

  handleReset(e) {
    e.preventDefault();

    const data = new FormData(e.target);
    const emailError = formValidation('email', data.get("email"));

    this.setState({ reqLoading: true, reqAlert: null, emailError: emailError });

    if (emailError) return;

    const url = getApiLinkV2('auth/forgot');

    getAxios().post(url, {
      email: data.get("email")
    }, {
      headers: { 'Content-Type': 'application/json' },
    }).then(response => {
      createAlert(this, 'success',' ', response.data.message);
    }).catch(error => {
      if (error.response && error.response.status !== 406) {
        Bugsnag.notify(new Error('Error Catched in axios ' + JSON.stringify(error)));
      }
      createAlert(this, 'danger',(error.response && error.response.data.error) ? 'Error: ' : '', (error.response && error.response.data.error) ? error.response.data.error : error.message);
      }).then(() => {
        this.setState({ reqLoading: false });
    });
  }

  handleUpdatePass(e) {
    e.preventDefault();

    const data = new FormData(e.target);
    const passError = formValidation('password', data.get("password"));
    const confirmPassError = formValidation('confirmpass', data.get("confirmpass"));
    const passMatchError = data.get("password") === data.get("confirmpass") ? null : 'Passwords do not match!';

    this.setState({ reqLoading: true, reqAlert: null, passError: passError, confirmPassError: ((confirmPassError) ? confirmPassError : passMatchError) });

    if (passError || confirmPassError || passMatchError) return;

    const queryStr = queryString.parse(this.props.location.search);
    const url = getApiLinkV2('auth/reset');

    getAxios().post(url, {
      password: data.get("password"),
      token: queryStr.token,
    }, {
      headers: { 'Content-Type': 'application/json' },
    }).then(response => {
        this.props.resetCallback();
      })
      .catch(error => {
        Bugsnag.notify(error);
        createAlert(this, 'danger',(error.response && error.response.data.error) ? 'Error: ' : '', (error.response && error.response.data.error) ? error.response.data.error : error.message);
      }).then(() => {
      document.getElementById('password').value = '';
      document.getElementById('confirmpass').value = '';
      this.setState({ reqLoading: false });
    });
  }

  render() {

    const { reqLoading, reqAlert } = this.state;
    const { location } = this.props;
    const queryStr = queryString.parse(location.search);

    if (queryStr.token) {
      return (
        <>
          <form autoComplete="on" onSubmit={this.handleUpdatePass}>

            <input type="password" id="password" name="password" className="form-control" placeholder="New Password" required />
            {(this.state.passError) ? (<div className="form-error">{this.state.passError}</div>) : (<></>)}
            <input type="password" id="confirmpass" name="confirmpass" className="inputPassword form-control" placeholder="Confirm New Password" required />
            {(this.state.confirmPassError) ? (<div className="form-error">{this.state.confirmPassError}</div>) : (<></>)}

            <button type="submit" className={"btn btn-lg btn-primary btn-block fl_btn "+((reqLoading) ? 'ld-btn' : '')}>
              {(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch} spin={true} /> loading</>) : 'Update Password'}
            </button>

          </form>

          {(reqAlert && <FloatingAlert error={reqAlert} onClick={() => this.setState({reqAlert: null})} />)}

        </>
      );
    }

    return (
      <>
        <form autoComplete="on" onSubmit={this.handleReset}>
          <input type="email" id="email" name="email" className="inputEmail form-control" placeholder="Email address" required />
          <button type="submit" className={"btn btn-lg btn-primary btn-block fl_btn "+((reqLoading) ? 'ld-btn' : '')}>
            {(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch} spin={true} /> loading</>) : 'Reset'}
          </button>
          <h2 className="fl_a mt-3 mb-0 font-weight-normal">
            Don’t have an account?{" "}
            <Link to={"/signup"} className="a2">Sign up</Link>
          </h2>
        </form>

        {(reqAlert && <FloatingAlert error={reqAlert} onClick={() => this.setState({reqAlert: null})} />)}

      </>
    );
  }
}

export default Reset;
