import React, {Component} from "react";
import {Link, Redirect} from "react-router-dom";
import queryString from 'query-string';
import Cities from './organisms/Cities'
import Space from './Space';
import printLocal, {
  devLog,
  getApiLinkV2,
  getToken,
  printFilterKey,
  printLink,
  printNeighbor,
  printTypeLink
} from "../Helpers";
import axios from "axios";
import {getAxios} from "src/Helpers";
import Bugsnag from "@bugsnag/js";
import {Helmet} from "react-helmet";
import { SpaceCard } from "./organisms/space-card";
import { Box } from "@material-ui/core";
import 'src/styles/search-page.scss'
export default class SearchApi extends Component {

  constructor(props) {
    super(props)

    this.state = {
      spaces: [],
      spacesDict: [],
      currentPage: 0,
      numOfPages: 0,
      redirect: false,
      isLoaded: false,
      error: null,
    }

    this.fetchSpaces = this.fetchSpaces.bind(this);
    this.fetchAllSpaces = this.fetchAllSpaces.bind(this);
    this.handleRemovedFav = this.handleRemovedFav.bind(this);
    this.redirectScreen = this.redirectScreen.bind(this);
  }
  
  // componentWillReceiveProps(nextProps) {
  
  //   let queryStr = queryString.parse(nextProps.search);
  //   let currentPage = (queryStr.p) ? queryStr.p : 1;
  
  //   if (currentPage !== this.state.currentPage) {
  //     devLog('props', this.props);
  //     devLog('nextProps', nextProps);
  //     this.props.updateResults([], null, 0);
  //     this.setState({spaces: [], spacesDict: []});
  //     // this.fetchSpaces();
  //   }
  // }
  
  componentDidMount() {
    
    const {pathname, isFav, params} = this.props;
    if (isFav || pathname == 'home' || (params.type && params.city != 'nearby')) {
      this.fetchSpaces();
      return;
    }
    this.fetchAllSpaces();

  }

  fetchAllSpaces() {

    const { params, updateResults } = this.props;

    const type = params.type;
    const city = (params.city !== 'nearby' || type) ? params.city : 'Dubai';

    var url = getApiLinkV2('workspace?city='+city);

    url += (window.innerWidth < 1200) ? "&limit=6" : "&limit=5";

    if (type) {
      switch (type) {
        case 'banquets-and-event-spaces':
          url += "&workspace=10";
          break;
        case 'co-working-spaces':
          url += "&workspace=4";
          break;
        case 'meeting-rooms':
          url += "&workspace=5";
          break;
        case 'business-setup':
          url += "&workspace=30";
          break;
        case 'office-spaces':
        default:
          url += "&workspace=9";
          break;
      }
    }
  
    devLog(url)

    var totalCount = 0;

    const token = getToken();
    const headers = {};
    if (token) {
      headers['Authorization'] = 'Bearer ' + token;
    }
    getAxios().get(url, {
      headers: headers
    }).then(response => {

      this.setState({
        isLoaded: true,
        spaces: [],
        spacesDict: response.data.spacesDict,
        currentPage: 0,
        numOfPages: 0,
        error: null,
      });

      response.data.spacesDict.forEach(spaceData => {
        totalCount += spaceData.count;
      });

      return totalCount;

    }).catch(error => {

      Bugsnag.notify(error);

      this.setState({
        isLoaded: true,
        spaces: [],
        spacesDict: [],
        currentPage: 0,
        numOfPages: 0,
        error
      });

      return 0;

    }).then(totalCount => {
      updateResults([], null, totalCount);
    });
  }

  fetchSpaces() {

    const { pathname, search, params, updateResults, updateFavResults } = this.props;

    let queryStr = queryString.parse(search);
    let currentPage = (queryStr.p) ? queryStr.p : 1;
    var url = getApiLinkV2('workspace?');

    if (pathname === 'home') {

      url += "city=dubai";
      url += "&workspace=9";
      url += (window.innerWidth < 1200) ? "&limit=6" : "&limit=5";

    } else if (pathname === 'fav-spaces') {

      url = getApiLinkV2('user/favourite-spaces?');

    } else if (pathname === 'fav-services') {

      url = getApiLinkV2('user/favourite-services?');

    } else {

      if (params.city) {
        url += "city="+params.city;
      }
      switch (params.type) {
        case 'banquets-and-event-spaces':
          url += "&workspace=10";
          if (queryStr.t && (queryStr.t === 'catered' || queryStr.t === 'hourly')) {
            url += "&food_type="+queryStr.t;
          } else {
            url += "&food_type=catered";
          }
          break;
        case 'co-working-spaces':
          url += "&workspace=4";
          if (queryStr.t && (queryStr.t === 'monthly' || queryStr.t === 'hourly')) {
            url += "&term_type="+queryStr.t;
          } else {
            url += "&term_type=monthly";
          }
          break;
        case 'meeting-rooms':
          url += "&workspace=5";
          if (queryStr.t && (queryStr.t === 'catered' || queryStr.t === 'hourly')) {
            url += "&food_type="+queryStr.t;
          } else {
            url += "&food_type=hourly";
          }
          break;
        case 'business-setup':
          url += "&workspace=30";
          break;
        case 'office-spaces':
        default:
          url += "&workspace=9";
          if (queryStr.t && (queryStr.t === 'monthly' || queryStr.t === 'hourly')) {
            url += "&term_type="+queryStr.t;
          } else {
            url += "&term_type=monthly";
          }
          break;
      }

      if (queryStr.s && queryStr.s != "def") {
        url += "&sort=%5B%22"+queryStr.s+"%22%5D";
      } else {
        url += "&sort=%5B%22%22%5D";
      }
      if (queryStr.max_pr) {
        url += "&price=%5B"+((queryStr.min_pr) ? queryStr.min_pr : 0)+"%2D"+queryStr.max_pr+"%5D";
      }
      if (queryStr.pr_t) {
        url += "&price-type="+queryStr.pr_t;
      }
      if (queryStr.m_pr) {
        url += "&max_price=%5B"+queryStr.m_pr+"%5D";
        url += "&price-range="+queryStr.m_pr;
      }
      if (queryStr.c) {
        url += "&capacity="+queryStr.c;
      }
      if (queryStr.z) {
        url += "&licence_zone="+queryStr.z;
      }
      Object.keys(queryStr).forEach(key => {

        if (key == "n" || key == "v_t" || key == "o_t" || key == "l" || key == "a" || key == "s_f" || key == "ls_t" || key == "av") {
          let value = queryStr[key];
          url += "&"+printFilterKey(key)+"=%5B";
          if (value instanceof Array) {
            for (let i = 0; i < value.length; i++) {
              url += (key == "n") ? printNeighbor(value[i]) : value[i];
              if (i < value.length-1) url += "%2C";
            }
          } else {
            url += (key == "n") ? printNeighbor(value) : value;
          }
          url += "%5D";
        }
      });
    }

    url += "&page="+(currentPage);
  
    devLog(url);

  //&venue=
  //&layout=
  //&licensing_zone=%5B%5D
  //&sort-search=rating
  //&isApp=no
  //&x=25.276987
  //&y=55.296249

    const token = getToken();
    const headers = {};
    if (token) {
      headers['Authorization'] = 'Bearer ' + token;
    }
    getAxios().get(url, {
      headers: headers
    }).then(response => {

      if (!response.data || !response.data.workspaces) {
        this.setState({
          isLoaded: true,
          spaces: [],
          spacesDict: [],
          currentPage: 0,
          numOfPages: 0,
          error: null,
        });
        if (updateResults) {
          updateResults([], null, 0);
        } else if (updateFavResults) {
          updateFavResults(0);
        }
        return;
      }
  
      devLog(response.data);

      const count = response.data.count ? parseInt(response.data.count) : 0;
      const numOfPages = (Math.ceil(count/30));

      this.setState({
        isLoaded: true,
        spaces: response.data.workspaces,
        spacesDict: [],
        currentPage: currentPage,
        numOfPages: numOfPages,
        error: null,
      });

      return response;

    }).catch(error => {

      Bugsnag.notify(new Error('Error in axios url: '+url+' - '+JSON.stringify(error)));

      this.setState({
        isLoaded: true,
        spaces: [],
        services: [],
        spacesDict: [],
        currentPage: 0,
        numOfPages: 0,
        numOfServicesPages: 0,
        error
      });

      return null;

    }).then(response => {

      if (updateResults) {
        if (response) {
          updateResults(response.data.workspaces, response.data.filters, response.data.count);
        } else {
          updateResults([], null, 0, 100);
        }
      } else if (updateFavResults) {
        if (response) {
          updateFavResults(response.data.count);
        } else {
          updateFavResults(0);
        }
      }
    });
  }

  handleRemovedFav(space) {

    var spaces = this.state.spaces;

    const index = spaces.findIndex(x => x.workspace_id == space.workspace_id);
    spaces.splice(index, 1);
    if (this.props.decreseFavResults) {
      this.props.decreseFavResults();
    }
    this.setState({ spaces: spaces});
  }

  createBottomNav(currentPage, numOfPages) {

    if (numOfPages < 1) {
      return <></>;
    }

    var elements = [];
    var pathname = this.props.pathname;
    var search = this.props.search;

    if (numOfPages > 0) {
      elements.push(<li className={(currentPage == 1) ? "page-item disabled" : "page-item"}>
      <Link to={printLink(pathname, search, 'p', currentPage-1)} className="page-link page-btn fz-16 text-center">
        <span aria-hidden="true">&laquo;</span>
      </Link>
    </li>);
    }
    for (let i = 1; i <= numOfPages; i++) {
      elements.push(<li key={i} className={(currentPage == i) ? "page-item active" : "page-item"}>
        {(currentPage == i) ? (
          <a className="page-link page-btn fz-16 text-center">{i}</a>
        ) : (
          <Link to={printLink(pathname, search, 'p', i)} className="page-link page-btn fz-16 text-center">{i}</Link>
        )}
      </li>);
    }
    if (numOfPages > 0) {
      elements.push(<li className={(currentPage == numOfPages) ? "page-item disabled" : "page-item"}>
        <Link to={printLink(pathname, search, 'p', ++currentPage)} className="page-link page-btn fz-16 text-center">
          <span aria-hidden="true">&raquo;</span>
        </Link>
      </li>);
    }
    return elements;
  }

  getShowAllLink(id) {

    const { params, updateResults } = this.props;

    const type = params.type;
    const city = (params.city !== 'nearby' || type) ? params.city : 'Dubai';
  
    if (type && city == 'nearby') {
      return "/search/" + id + "/" + type;
    }
    return "/search/" + city + "/" + printTypeLink(id);
  }
  
  redirectScreen() {
    this.setState({redirect: true});
  }
  
  getHelmet = () => {
    const {location, params: {city, type}} = this.props;
    let title = "Fluidmeet | The Premier Market Place For Serviced Offices, Meeting Rooms, Banquets."
    let description = "Book a space (serviced office, banquet or event hall, meeting room, coworking space) with the help of fluidmeet, the leading online workspace booking platform in the GCC"
    let keywords = "";
    let canonical = "https://www.fluidmeet.com/";
    if (city) {
  
      if (city === 'Manama-and-the-rest-of-Bahrain' && type) {
        title = `${(printLocal(type))} Rental | ${(printLocal(city))}`
        description = `Find Your Ideal Space in ${(printLocal(city))}, Rent Serviced Offices, Co-working Spaces, Banquet & Event Halls and Meeting Rooms, Commission-Free Booking`
        keywords = "Manama, Bahrain, Rent"
        switch (type) {
          case "office-spaces":
            description = "Find Your Ideal Office Space in Manama & The Rest Of Bahrain, Flexible Payment & Rental Terms, Send Your Request And Get a Free Quote Commission-Free";
            keywords += ", Serviced Offices, Office Space, Book";
            canonical = "https://www.fluidmeet.com/search/Manama-and-the-rest-of-Bahrain/office-spaces";
            break;
          
          case "banquets-and-event-spaces":
            description = "Find The Perfect Event Space For Your Upcoming Event, Request Your Free Quote. Filter by Price, Capacity, Layout & More. Commission-Free Reservation";
            keywords += ", Banquet Hall, Event Space";
            canonical = "https://www.fluidmeet.com/search/Manama-and-the-rest-of-Bahrain/banquets-and-event-spaces";
            break;
          case "meeting-rooms":
            description = "Find The Perfect Meeting Room in Manama Bahrain, Request Your Free Quote. Filter by Price, Capacity, Layout & More. Commission-Free Booking";
            keywords += ", Meeting Rooms, Book";
            canonical = "https://www.fluidmeet.com/search/Manama-and-the-rest-of-Bahrain/meeting-rooms";
            break;
          case "co-working-spaces":
            description = "Find Your Ideal Co-working Space in Manama & The Rest Of Bahrain, Flexible Payment & Rental Terms, Send Your Request And Get a Free Quote Commission-Free";
            keywords += ", Co-working Spaces, Book";
            canonical = "https://www.fluidmeet.com/search/Manama-and-the-rest-of-Bahrain/co-working-spaces";
            break;
        }
    
      } else if (city === 'nearby' && type) {
        keywords = "Rent"
        switch (type) {
          case "office-spaces":
            title = "Serviced Offices Rental Near You | Book Your Office Space"
            description = "Pick Out The City And Find Your Ideal Office Space, Flexible Payment & Rental Terms, Send Your Request And Get a Free Quote Commission-Free";
            keywords += ", Serviced Offices, Office Spaces, Book";
            canonical = "https://www.fluidmeet.com/search/nearby/office-spaces";
            break;
      
          case "banquets-and-event-spaces":
            title = "Banquet & Event Halls Rental Near You | Book Your Event Space"
            description = "Find The Perfect Event Space For Your Upcoming Event, Request Your Free Quote. Filter by Price, Capacity, Layout & More. Commission-Free Reservation";
            keywords += ", Banquet Hall, Event Space";
            canonical = "https://www.fluidmeet.com/search/nearby/banquets-and-event-spaces";
            break;
          case "co-working-spaces":
            title = "Coworking Spaces Rental | Book Your Workspace At 0% Commission"
            description = "Find The Ideal Coworking Space Near You, Flexible Payment & Rental Terms, Send Your Request And Get a Free Quote, Commission-Free Direct Booking";
            keywords += ", Co-working Spaces, Workspace, Book";
            canonical = "https://www.fluidmeet.com/search/nearby/co-working-spaces";
            break;
          case "meeting-rooms":
            title = "Meeting Rooms Rental | Book Your Conference Room At 0% Commission"
            description = "Find The Perfect Meeting Or Conference Room Near You, Request Your Free Quote. Filter by Price, Capacity, Layout & More. Commission-Free Booking";
            keywords += ", Meeting Room, Conference Room, Book";
            canonical = "https://www.fluidmeet.com/search/nearby/meeting-rooms";
            break;
      
        }
      } else if (city === 'jeddah' && type) {
        keywords = "Rent, Jeddah, KSA"
        switch (type) {
          case "office-spaces":
            title = "Serviced Offices Rental | Book Your Office in Jeddah"
            description = "Find Your Ideal Office Space in Jeddah KSA, Flexible Payment & Rental Terms, Send Your Request And Get a Free Quote Commission-Free";
            keywords += ", Serviced Offices, Office Spaces, Book";
            canonical = "https://www.fluidmeet.com/search/jeddah/office-spaces";
            break;
      
          case "banquets-and-event-spaces":
            title = "Banquet & Event Halls Rental in Jeddah | Book Your Event Space"
            description = "Find The Perfect Event Space For Your Upcoming Event, Request Your Free Quote. Filter by Price, Capacity, Layout & More. Commission-Free Reservation";
            keywords += ", Banquet Hall, Event Space";
            canonical = "https://www.fluidmeet.com/search/jeddah/banquets-and-event-spaces";
            break;
          case "co-working-spaces":
            title = "Coworking Spaces Rental | Book Your Workspace in Jeddah"
            description = "Find Your Ideal Coworking Space in Jeddah KSA, Flexible Payment & Rental Terms, Send Your Request And Get a Free Quote Commission-Free";
            keywords += ", Co-working Spaces, Workspace, Book";
            canonical = "https://www.fluidmeet.com/search/jeddah/co-working-spaces";
            break;
          case "meeting-rooms":
            title = "Meeting Rooms Rental | Book Your Conference Room in Jeddah"
            description = "Find The Perfect Meeting Room in Jeddah KSA, Request Your Free Quote. Filter by Price, Capacity, Layout & More. Commission-Free Booking";
            keywords += ", Meeting Rooms, Book";
            canonical = "https://www.fluidmeet.com/search/jeddah/meeting-rooms";
            break;
      
        }
      } else if (city === 'Dubai' && type) {
        keywords = "Rent, Dubai"
        switch (type) {

          case "office-spaces":
            title = "Fluidmeet |Rent your Serviced Office| Office Space Rental |Flex Spaces in Dubai"
            description = "Flex offices that come fully furnished and amenities equipped like wifi, DEWA, phone line and other shared services like pantry, printer/scanner, call answering, mail management and more. Located in Dubai and all over the UAE.";
            keywords = "Flex spaces, serviced office, office space, serviced hubs, rent offices, space rental, find and book your serviced office, Office Space Rental in Dubai , shared offices, business centers, business hubs, Dubai, UAE";
            canonical = "https://www.fluidmeet.com/search/Dubai/office-spaces";
            break;
          case "banquets-and-event-spaces":
            title = "Fluidmeet |Corporate Event Venues  | Wedding Venues | Banquet & Event Halls in Dubai "
            description = "Find The Perfect Event Space For Your Upcoming Event, Request Your Free Quote. Filter by Price, Capacity, Layout & More. Commission-Free Reservation. Any workspace (Banquets, Event halls) that can be used to conduct large-sized events.Location: Dubai, UAE.";
            keywords = "Banquet halls, Corporate Event spaces, Event spaces, wedding spaces, large events, meeting rooms, award ceremony, company meetings, book your banquet hall in Dubai, UAE";
            canonical = "https://www.fluidmeet.com/search/Dubai/banquets-and-event-spaces";
            break;
          case "co-working-spaces":
            title = "Fluidmeet |Rent your Coworking Space in Dubai| Flex Spaces | Shared workspaces | Dubai"
            description = "Discover productivity and collaboration in modern coworking spaces. Elevate your work environment with flexible shared offices, networking opportunities, and a vibrant community. Modern high-quality flex space in Dubai at an affordable price. Fully equipped flexible office across the Dubai, UAE.";
            keywords = "Flex spaces, coworking spaces, shared offices, office space, coworking space rental, find and book your coworking space, OfficeSpace rental, Dubai, UAE ";
            canonical = "https://www.fluidmeet.com/search/Dubai/co-working-spaces";
            break;
          case "meeting-rooms":
            title = "Fluidmeet |Meeting Rooms Rental | Meeting Rooms in Dubai | Boardrooms Rental|Conference Rooms"
            description = "Easy Booking. Use our platform to browse and compare hundreds of meeting rooms or conference room available   and impress at your next event. Available by the hour or day, with on site support.";
            keywords = "Meeting rooms, boardrooms, conference rooms, meeting room rental in Dubai, corporate meetings, Dubai, Uae";
            canonical = "https://www.fluidmeet.com/search/Dubai/meeting-rooms";
            break;
      
        }
      }else if (city === 'Abu-Dhabi' && type) {
      keywords = ""
      switch (type) {

        case "office-spaces":
          title = "Meeting Rooms Rental | Book Your Conference Room in Dubai"
          description = "Find The Perfect Meeting Room in Dubai UAE, Request Your Free Quote. Filter by Price, Capacity, Layout & More. Commission-Free Booking";
          keywords += "Meeting Rooms, Dubai, UAE, Rent, Book";
          canonical = "https://www.fluidmeet.com/search/Abu-Dhabi/office-spaces";
          break;

        case "banquets-and-event-spaces":
          title = "Serviced Offices Rental | Book Your Office Space in Abu Dhabi"
          description = "Find Your Ideal Office Space in Abu Dhabi UAE, Flexible Payment & Rental Terms, Send Your Request And Get a Free Quote Commission-Free";
          keywords += "Serviced Offices, Abu Dhabi, UAE, Office Space, Rent, Book";
          canonical = "https://www.fluidmeet.com/search/Abu-Dhabi/banquets-and-event-spaces";
          break;
        case "co-working-spaces":
          title = "Banquet & Event Halls Rental in Abu Dhabi | Book Your Event Space"
          description = "Find The Perfect Event Space For Your Upcoming Event, Request Your Free Quote. Filter by Price, Capacity, Layout & More. Commission-Free Reservation";
          keywords += "Banquet Hall, Event Space, Abu Dhabi, Rent";
          canonical = "https://www.fluidmeet.com/search/Abu-Dhabi/co-working-spaces";
          break;
        case "meeting-rooms":
          title = "Coworking Spaces Rental | Book Your Workspace in Abu Dhabi"
          description = "Find Your Ideal Coworking Space in Abu Dhabi UAE, Flexible Payment & Rental Terms, Send Your Request And Get a Free Quote Commission-Free";
          keywords += "Coworking Space, Workspace, Abu Dhabi, Rent, Book";
          canonical = "https://www.fluidmeet.com/search/Abu-Dhabi/meeting-rooms";
          break;
      }
    }else if (city === 'Sharjah' && type) {
      keywords = ""
      switch (type) {

        case "office-spaces":
          title = "Meeting Rooms Rental | Book Your Conference Room in Abu Dhabi"
          description = "Find The Perfect Meeting Room in Abu Dhabi UAE, Request Your Free Quote. Filter by Price, Capacity, Layout & More. Commission-Free Booking";
          keywords += "Meeting Rooms, Abu Dhabi, UAE, Rent, Book";
          canonical = "https://www.fluidmeet.com/search/Sharjah/office-spaces";
          break;

        case "banquets-and-event-spaces":
          title = "Serviced Offices Rental | Book Your Office Space in Sharjah"
          description = "Find Your Ideal Office Space in Sharjah UAE, Flexible Payment & Rental Terms, Send Your Request And Get a Free Quote Commission-Free";
          keywords += "Serviced Offices, Sharjah, UAE, Office Space, Rent, Book";
          canonical = "https://www.fluidmeet.com/search/Sharjah/banquets-and-event-spaces";
          break;
        case "co-working-spaces":
          title = "Banquet & Event Halls Rental in Sharjah | Book Your Event Space"
          description = "Find The Perfect Event Space For Your Upcoming Event, Request Your Free Quote. Filter by Price, Capacity, Layout & More. Commission-Free Reservation";
          keywords += "Banquet Hall, Event Space, Sharjah, Rent";
          canonical = "https://www.fluidmeet.com/search/Sharjah/co-working-spaces";
          break;
        case "meeting-rooms":
          title = "Coworking Spaces Rental | Book Your Workspace in Sharjah"
          description = "Find Your Ideal Coworking Space in Sharjah UAE, Flexible Payment & Rental Terms, Send Your Request And Get a Free Quote Commission-Free";
          keywords += "Coworking Space, Workspace, Sharjah, Rent, Book";
          canonical = "https://www.fluidmeet.com/search/Sharjah/meeting-rooms";
          break;
      }
    }else if (city === 'London' && type) {
      keywords = ""
      switch (type) {

        case "office-spaces":
          title = "Fluidmeet |Rent your Office Space | Ofice Space Rental London|Flex Spaces in London"
          description = "Flex offices that come fully furnished and amenities equipped like wifi, DEWA, phone line and other shared services like pantry, printer/scanner, call answering, mail management and more. Location: London, UK.";
          keywords = "Flex spaces, serviced office, office space, serviced hubs, rent offices, space rental, find and book your serviced office, Office Space Rental in London , shared offices, business centers, business hubs, London, UK, Europe";
          canonical = "https://www.fluidmeet.com/search/London/office-spaces";
          break;

        case "banquets-and-event-spaces":
          title = "Fluidmeet |Corporate Event Venues |Wedding Venues| Birthday Party Halls in London"
          description = "Find The Perfect Event Space For Your Upcoming Event, Request Your Free Quote. Filter by Price, Capacity, Layout & More. Commission-Free Reservation. Any workspace (Banquets, Event halls) that can be used to conduct large-sized events. Location: London,UK.";
          keywords = "Banquet halls, Corporate Event spaces, Event spaces, wedding spaces, large events, meeting rooms, award ceremony, company meetings, book your banquet hall in London, UK";
          canonical = "https://www.fluidmeet.com/search/London/banquets-and-event-spaces";
          break;
        case "co-working-spaces":
          title = "Fluidmeet |Rent your Coworking Space in London| Flex Spaces | Shared workspaces | London"
          description = "Discover productivity and collaboration in modern coworking spaces. Elevate your work environment with flexible shared offices, networking opportunities, and a vibrant community. Modern high-quality flex space in Dubai at an affordable price. Fully equipped flexible office spaces across London, UK.";
          keywords = "Flex spaces, coworking spaces, shared offices, office space, coworking space rental, find and book your coworking space, OfficeSpace rental, London, UK";
          canonical = "https://www.fluidmeet.com/search/London/co-working-spaces";
          break;
        case "meeting-rooms":
          title = "Fluidmeet |Meeting Rooms Rental | Meeting Rooms in London| Boardrooms Rental|Conference Rooms"
          description = "Easy Booking. Use our platform to browse and compare hundreds of meeting rooms or conference room available   and impress at your next event. Available by the hour or day, with on site support.";
          keywords = "Meeting rooms, boardrooms, conference rooms, meeting room rental in London, corporate meetings, London, UK, Europe";
          canonical = "https://www.fluidmeet.com/search/London/meeting-rooms";
          break;
      }
    }else if (city === 'Riyadh' && type) {
      keywords = ""
      switch (type) {

        case "office-spaces":
          title = "Serviced Offices Rental | Book Your Office Space in Riyadh"
          description = "Find Your Ideal Office Space in Riyadh KSA, Flexible Payment & Rental Terms, Send Your Request And Get a Free Quote Commission-Free";
          keywords += "Serviced Offices, Riyadh, KSA, Office Space, Rent, Book";
          canonical = "https://www.fluidmeet.com/search/Riyadh/office-spaces";
          break;

        case "banquets-and-event-spaces":
          title = "Banquet & Event Halls Rental in Riyadh | Book Your Event Space"
          description = "Find The Perfect Event Space For Your Upcoming Event, Request Your Free Quote. Filter by Price, Capacity, Layout & More. Commission-Free Reservation";
          keywords += "Banquet Hall, Event Space, Riyadh, Rent";
          canonical = "https://www.fluidmeet.com/search/Riyadh/banquets-and-event-spaces";
          break;
        case "co-working-spaces":
          title = "Coworking Spaces Rental | Book Your Workspace in Riyadh"
          description = "Find Your Ideal Coworking Space in Riyadh KSA, Flexible Payment & Rental Terms, Send Your Request And Get a Free Quote Commission-Free";
          keywords += "Coworking Space, Workspace, Riyadh, Rent, Book";
          canonical = "https://www.fluidmeet.com/search/Riyadh/co-working-spaces";
          break;
        case "meeting-rooms":
          title = "Meeting Rooms Rental | Book Your Conference Room in Riyadh"
          description = "Find The Perfect Meeting Room in Riyadh KSA, Request Your Free Quote. Filter by Price, Capacity, Layout & More. Commission-Free Booking";
          keywords += "Meeting Rooms, Riyadh, KSA, Rent, Book";
          canonical = "https://www.fluidmeet.com/search/Riyadh/meeting-rooms";
          break;
      }
    }else if (city === 'Mumbai' && type) {
      keywords = ""
      switch (type) {

        case "office-spaces":
          title = "Fluidmeet |Rent your Serviced Office| Office Space Rental Mumbai|Flex Spaces in Mumbai"
          description = "Flex offices that come fully furnished and amenities equipped like wifi, DEWA, phone line and other shared services like pantry, printer/scanner, call answering, mail management and more. Location: Mumbai, India";
          keywords = "Flex spaces, serviced office, office space, serviced hubs, rent offices, space rental, find and book your serviced office, Office Space Rental in Mumbai , shared offices, business centers, business hubs, Asia, India, Mumbai";
          canonical = "https://www.fluidmeet.com/search/Riyadh/office-spaces";
          break;

        case "banquets-and-event-spaces":
          title = "Fluidmeet |Corporate Event Venues |Wedding Venues| Birthday Party Halls in Mumbai"
          description = "Find The Perfect Event Space For Your Upcoming Event, Request Your Free Quote. Filter by Price, Capacity, Layout & More. Commission-Free Reservation. Any workspace (Banquets, Event halls) that can be used to conduct large-sized events. Location; Mumbai, India.";
          keywords = "Banquet halls, Corporate Event spaces, Event spaces, wedding spaces, large events, meeting rooms, award ceremony, company meetings, book your banquet hall in Mumbai, India, Asia";
          canonical = "https://www.fluidmeet.com/search/Riyadh/banquets-and-event-spaces";
          break;
        case "co-working-spaces":
          title = "Fluidmeet |Rent your Coworking Space in Mumbai| Flex Spaces | Shared workspaces | Mumbai"
          description = "Discover productivity and collaboration in modern coworking spaces. Elevate your work environment with flexible shared offices, networking opportunities, and a vibrant community. Modern high-quality flex space in Dubai at an affordable price. Fully equipped flexible office spaces across Mumbai, India.";
          keywords = "Flex spaces, coworking spaces, shared offices, office space, coworking space rental, find and book your coworking space, OfficeSpace rental, Mumbai, Asia, India";
          canonical = "https://www.fluidmeet.com/search/Riyadh/co-working-spaces";
          break;
        case "meeting-rooms":
          title = "Fluidmeet |Meeting Rooms Rental | Meeting Rooms in Mumbai | Boardrooms Rental|Conference Rooms"
          description = "Easy Booking. Use our platform to browse and compare hundreds of meeting rooms or conference room available   and impress at your next event. Available by the hour or day, with on site support.";
          keywords = "Meeting rooms, boardrooms, conference rooms, meeting room rental in Mumbai, corporate meetings, Mumbai, Asia, India";
          canonical = "https://www.fluidmeet.com/search/Riyadh/meeting-rooms";
          break;
      }
    }else {
        title = `Serviced Offices in ${(printLocal(city))} | Banquet & Event Halls Rental`
        description = `Find Your Ideal Space in ${(printLocal(city))}, Rent Serviced Offices, Co-working Spaces, Banquet & Event Halls and Meeting Rooms, Commission-Free Booking`
        keywords = `Banquet & Event Halls, Serviced Offices, Co-working Spaces, Meeting Rooms, Rent. Book, ${(printLocal(city))}`

      }
      return (
          <Helmet>
            <title>{title}</title>
            <meta name={"description"} content={description}/>
            <meta name={"keywords"} content={keywords}/>
            <link rel={"canonical"} href={canonical} />
          </Helmet>
      )
    }
    return (<></>)
  }
  
  render() {
    
    const {location, isFav, params, isMapOpen, search, term, service, food, isLarge, onMouseOver, onMouseOut, currency, handleSpace, allowSpaceLink} = this.props;
    const {redirect, spaces, spacesDict, isLoaded, numOfPages} = this.state;
    
    
    if (redirect) {
      return (<Redirect to={{pathname: '/login', state: {prevLocation: location}}}/>);
    }

    if (!isLoaded) {
      return (<></>);
    }


    if (spacesDict && spacesDict.length > 0) {
      return (
        <>
          {this.getHelmet()}
          {
            spacesDict.map((spaceData, i) => {
      
              const term = (spaceData.workspace_id == "10") ? "catered" : ((spaceData.workspace_id == "5") ? "hourly" : "monthly");
              const food = (spaceData.workspace_id == "5" || spaceData.workspace_id == 10) ? "catered" : "";
              const service = (spaceData.workspace_id == "30") ? "service" : "space";
      
              return (
                  <>
                    <div className="col-12">
                    <div className={(i == 0) ? "subsection-div mt-0" : "subsection-div"}>
                      <h1 class="subtitle">{(params.type && params.city == 'nearby') ? spaceData.id : printLocal(spaceData.workspace_id)}</h1>
                      <Link to={this.getShowAllLink((params.type && params.city == 'nearby') ? spaceData.id : spaceData.workspace_id)}>
                        <button class="custom-btn clear-btn m-0 p-0 pt-3" type="button">Show all</button> {/*({spaceData.count})*/}
                      </Link>
                    </div>
                  </div>
                    {spaceData.workspaces.map((space, i2) => (
                        <Space key={space.workspace_id} hideFav={true} id={i2} isMapView={false} isMapOpen={isMapOpen}
                               space={space} term={term} service={service} food={food} currency={currency}
                               redirectScreen={this.redirectScreen} allowLink={allowSpaceLink}/>))}
                </>
              )
            })
          }
          {
            (!params.type && params.city == 'nearby') ?
            (
              <>
                <div className="col-12">
                  <div className="subsection-div">
                    <h1 class="subtitle">Nearby Cities</h1>
                  </div>
                </div>
                <Cities />
              </>
            ) : (
              <></>
            )
          }
        </>
      );
    }

    if (!spaces || spaces.length === 0) return (<>{this.getHelmet()}</>);

    return (
        <div className="search-page">
          {this.getHelmet()}
          <Box className="space-box">
            {spaces.map((space, i) => <SpaceCard id={i} isMapView={false} isLarge={isLarge} isMapOpen={isMapOpen}
                                            space={space} isFav={isFav} term={term} service={service} food={food}
                                            handleRemovedFav={this.handleRemovedFav} handleSpace={handleSpace}
                                            redirectScreen={this.redirectScreen} onMouseOver={() => onMouseOver(space)}
                                            onMouseOut={() => onMouseOut(space)} currency={currency}
                                            allowLink={allowSpaceLink}/>)}
          </Box>
          
      
          <div className="col-12">
            {((isFav || (params.city && params.city != 'nearby')) && (numOfPages > 0)) ? (
                <nav className="results-nav" aria-label="Page navigation">
                  <ul className="pagination justify-content-center">
                    {this.createBottomNav((queryString.parse(search).p) ? queryString.parse(search).p : 1, numOfPages)}
                  </ul>
                </nav>
            ) : (
                <></>
          )}
        </div>
      </div>
    );
  }
}
