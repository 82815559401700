export const ReservStyles = {
	reservstatus: {
		height: 25,
		marginTop: 1,
		// marginRight: 6,
		// paddingHorizontal: 6,
		fontWeight:  500,
		borderRadius: 6,
		fontSize: 16,
		color: '#0e818d',
		// borderWidth: 1,
		// borderColor: '#0e818d',
	},
	statusblack: {
		fontSize: 16,
		color: 'black' as const,
		// borderColor: black,
	},
	statusgray: {
		fontSize: 16,
		color: '#7E7F80',
		// borderColor: '#7E7F80',
	},
	statusred: {
		fontSize: 16,
		color: '#bf0f0f',
		// borderColor: '#bf0f0f',
	},
	statusgreen: {
		fontSize: 16,
		color: '#097e09',
		// borderColor: '#097e09',
	},
};
