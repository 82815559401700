import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {TransitionProps} from '@material-ui/core/transitions';
import FilesAPI from "src/APIs/FilesAPI";
import clsx from "clsx";
import {CircularProgress, IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {devLog} from "src/Helpers";

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement<any, any> },
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});
export type ReceiptDialogCallback = (dialog: ReceiptDialog) => void;

export type ReceiptDialogProps = {
	receiptId,
	id,
	onClose?,
	onOpen?,
}


export type ReceiptDialogState =
	{
		opened: boolean,
		loading: boolean,
	} & ReceiptDialogProps

export default class ReceiptDialog extends React.Component<ReceiptDialogProps, ReceiptDialogState> {
	static defaultProps = {
		autoClose: true
	}
	contentRef;

	constructor(props: Readonly<ReceiptDialogProps> | ReceiptDialogProps) {
		super(props);
		this.contentRef = React.createRef<HTMLDivElement>();
		this.state = {
			opened: false,
			loading: true,
			...props
		}

	}

	open = () => {
		this.setState({opened: true, loading: true})
		FilesAPI.viewReceipt(this.props.receiptId, this.props.id).then((data) => {
			devLog(data)
			this.setState({loading: false})

			if (this.contentRef.current)
				this.contentRef.current.innerHTML = data.template;
		})
	}
	close = () => {
		this.setState({opened: false}, () => {
			this.props.onClose?.();
		})
	}


	render() {
		let {opened} = this.state;

		return (
			<Dialog
				open={opened}
				TransitionComponent={Transition}
				aria-labelledby="receipt-dialog-slide-title"
				aria-describedby="receipt-dialog-slide-description"
				maxWidth={"lg"}
				fullWidth={true}
			>
				<DialogTitle id="receipt-dialog-slide-title">

					<div className={'d-flex flex-row justifu-content-between align-items-center'}>
						View Receipt
						<IconButton className={'ml-auto'} aria-label="close" onClick={this.close}>
							<CloseIcon/>
						</IconButton>
					</div>
				</DialogTitle>
				<DialogContent>
					{
						(this.state.loading) && (
							<div className={clsx('d-flex col-12 justify-content-center')}>
								<CircularProgress className={clsx('mx-auto my-5')}/>
							</div>
						)
					}
					<div ref={this.contentRef}/>
				</DialogContent>
			</Dialog>

		);
	}
}
