export default class HostsUtils {
	static POSITIONS = [
		{label: 'GM', value: '1'},
		{label: 'Director of Sales', value: '2'},
		{label: 'Director of Marketing', value: '3'},
		{label: 'Assistant Director of Sales', value: '4'},
		{label: 'Assistant Director of Marketing', value: '5'},
		{label: 'F & B Manager', value: '6'},
		{label: 'Events Team Admin', value: '7'},
		{label: 'Business Team', value: '8'},
		{label: 'Finance Manager', value: '9'},
		{label: 'Director of Revenue', value: '10'},
		{label: 'Business Center Manager', value: '11'},
		{label: 'Business Center Admin', value: '12'},
		{label: 'Business Center Receptionist', value: '13'},
		{label: 'Business Center Assistant', value: '14'},
		{label: 'Business Center Assistant Manager', value: '15'},
		{label: 'Business Center Owner', value: '16'},
	];
	
	static VENUE_PERMISSIONS = [
		{
			label: 'Space Rental Only Bookings', value: 'ST', options:
				[
					{label: 'View', value: 'view'},
					{label: 'Emails', value: 'emails', optional: true},
					{label: 'Accept/Decline', value: 'authorize', optional: true},
				]
		},
		{
			label: 'Serviced Office Leads', value: 'LT', options:
				[
					{label: 'View', value: 'view'},
					{label: 'Emails', value: 'emails', optional: true},
					{label: 'Edit', value: 'edit', optional: true},
				]
		},
		{
			label: 'DDR Proposals', value: 'DDR', options:
				[
					{label: 'View', value: 'view'},
					{label: 'Emails', value: 'emails', optional: true},
					{label: 'Pricing', value: 'pricing', optional: true},
				]
		},
		{
			label: 'Venue Management', value: 'venue', options:
				[
					{label: 'View', value: 'view'},
					{label: 'Edit', value: 'edit'},
				]
		},
		{
			label: 'Space Management', value: 'workspace', options:
				[
					{label: 'View', value: 'view'},
					{label: 'Add New', value: 'add'},
					{label: 'Edit', value: 'edit'},
					{label: 'Delete', value: 'delete'},
				]
		},
		{
			label: 'Finance', value: 'finance', options:
				[
					{label: 'View', value: 'view'},
					{label: 'Emails', value: 'emails'},
				]
		},
	];
	
	static OTHER_PERMISSIONS = [
		{
			label: '', value: 'venue', options:
				[
					{label: 'Add New Venue', value: 'add'},
				]
		},
		{
			label: '', value: 'user', options:
				[
					{label: 'View Users', value: 'view'},
				]
		},
	];
}
