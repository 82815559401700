import clsx from "clsx";
import React, {ChangeEvent, FormEventHandler, ReactElement, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import PhoneInput from "react-phone-input-2";

export function assignIfNotNull(object, toBeAssigned) {
	Object.keys(toBeAssigned).forEach(
		(key) => {
			if (toBeAssigned[key] != null) {
				object[key] = toBeAssigned[key]
			}
		}
	)
	return object;
}

export function assignIfNotUndefined(object, toBeAssigned) {
	Object.keys(toBeAssigned).forEach(
		(key) => {
			if (toBeAssigned[key] != undefined) {
				object[key] = toBeAssigned[key]
			}
		}
	)
	return object;
}

const useStyles = makeStyles(
	{
		title: {
			fontSize: "22px !important",
			fontWeight: 500,
			lineHeight: "1.125em",
			color: "#212529",
		},
		file: {
			'&::after': {
				content: '"Browse"',
				height: "100%",
				textAlign:'center',
				display:'flex',
				alignItems:'center',
				backgroundColor:'#00a9e0',
				cursor:'pointer',
				color:'white'
			},
			'&:hover':{
				'&::after': {
					backgroundColor:'#0099cc',
				},
			},
		}
	}
)
export type FMFieldOptions = {
	data: any[],
	labelKey?: string,
	valueKey?: string,
	iconKey?: string,
	boolLabel?: string,
	single?: boolean,
	selected?: any,
	boolSelected?: string[],
	fullwidth?: boolean,
	selectNull?: boolean,
	textInput?: {
		label: string,
		placeholder: string,
		type?: string,
		defaultValues?: any;
		errors?: any;
		allowShowError?: boolean
	}
}
export interface FMSubClassesNames {
	input?: string,
	select?: string,
	selectDiv?: string,
}

export interface FMField {
	id?: string,
	type: string,
	error?: string,
	extraClassNames?: string,
	defaultValue?: string | boolean | string[],
	placeholder?: string,
	required?: boolean,
	options?: FMFieldOptions,
	customView?: any;
	title?: string | ReactElement,
	onChange?: FormEventHandler,
	classes?: FMSubClassesNames
}

export function FMOptionsInput({
	                               id = '', placeholder, required, error, extraClassNames = '', defaultValue = [],
	                               options,
                               }: Omit<FMField, 'type'>) {
	if (options) {

		const {
			data, labelKey = 'label', valueKey = 'value', iconKey = 'icon', boolLabel,
			single, selected = [], boolSelected = [], fullwidth,
			textInput
		} = options;

		const classes = useStyles();
		// devLog(defaultValue)
		// data.forEach(({[valueKey]: value, [labelKey]: label, [iconKey]: icon,}: any, index: number) => {
		// 	devLog('defaultChecked-' + value, Boolean((defaultValue as string[])?.includes(value)))
		// })

		return (
			<div className={clsx("col-12 pr-mobile-0 pl-mobile-0", extraClassNames)}>
				{Boolean(placeholder) &&
				<div className={clsx(`col-12 row mt-4 mb-2`, classes.title)}>
					{placeholder}
				</div>
				}
				<div className="col-12 pr-mobile-0 pl-mobile-0">
					<fieldset>
						{
							data.map(
								({[valueKey]: value, [labelKey]: label, [iconKey]: icon,}: any, index: number) => (
									<div
										className={clsx("col-12 form-control d-flex align-items-center justify-content-between", {'col-md-6': !fullwidth})}>
										<div className="d-flex flex-row align-items-center">
											<input type={single ? 'radio' : 'checkbox'} id={id + index} name={id}
											       value={value} className={clsx('mr-2  ml-2')}
												// style={{width: '24px', height: '24px', borderRadius: '50%'}}
												   style={{transform: 'scale(2)'}}
												   defaultChecked={false}
												   checked={Boolean((defaultValue as string[])?.includes(value))}
											/>
											<div className={clsx('col-10 d-flex flex-row')}>
												{Boolean(icon) &&
												<div className={'align-items-center my-auto'}
												     style={{width: 36, marginRight: 10, alignItems: 'center',}}>
													{icon}
												</div>
												}
												{Boolean(label) && <span style={{fontSize: '100%'}}>{label}</span>}
											</div>
										</div>
										{Boolean(boolLabel) && Boolean((defaultValue as string[])?.includes(value)) && (
											<div className="col-3 custom-control custom-switch ">
												<input className="custom-control-input " type="checkbox"
												       id={id + "-bool-" + index}
												       name={id + "-bool"}
												       value={value}
												       defaultChecked={boolSelected?.includes(value)}/>
												<label className="custom-control-label"
												       htmlFor={id + "-bool-" + index}>{boolLabel}</label>
											</div>
										)}
										{Boolean(textInput) && Boolean((defaultValue as string[])?.includes(value)) && (
											<div className={'col-5'}>
												<div className="  flex-row d-flex align-items-center">
													<input className="form-control px-2 my-0 py-2"
													       type={textInput?.type ?? 'text'}
													       id={`${id}-${value}-textInput`}
													       name={`${id}-${value}-textInput`}
													       value={textInput?.defaultValues?.[`${id}-${value}-textInput`] ?? ''}
													       placeholder={textInput?.placeholder}
														   onWheel={ event => event.currentTarget.blur() }
														   />
													<label className="ml-1 my-0"
													       htmlFor={`${id}-${value}-textInput`}>{textInput?.label}</label>
												</div>
												{(textInput?.errors?.[`${id}-${value}-textInput`] && textInput?.allowShowError) ? (
													<div className="form-error mt-2"
													     style={{fontSize: '1vw'}}>{textInput?.errors?.[`${id}-${value}-textInput`]}</div>) : (<></>)}
											</div>
										)}
									</div>

								)
							)
						}
					</fieldset>

					{(error) ? (
						<div className="form-error">{error}</div>) : (<></>)}
				</div>
			</div>
		)

	} else {
		throw new Error("options Prop is required for this type of FormField")
	}

}

export function FMSelectInput({
	                              id, placeholder, required, error, extraClassNames = '', defaultValue, onChange, title, value,
	                              options, disabled, classes
                              }: Omit<FMField, 'type'> & { value?: any, disabled?: boolean }) {
	if (options) {
		const {data, labelKey = 'label', valueKey = 'value', selectNull = true} = options;
		const MuiClasses = useStyles();
		return (
			<div className={clsx("col-12 pr-mobile-0 pl-mobile-0", extraClassNames)}>
				{title != 'none' && Boolean(title || placeholder) &&
				<div className={clsx(`col-12 row mt-4 mb-2`, MuiClasses.title)}>
					{title || placeholder}
				</div>
				}
				<div className={clsx("selector", classes?.selectDiv)}>
					<select className={clsx("select form-control mb-0", classes?.select)} name={id} id={id}
					        defaultValue={(defaultValue as string | number | undefined)} value={value}
					        onChangeCapture={onChange} disabled={disabled} required={required}>
						{selectNull && <option value=''>{placeholder}</option>}
						{
							data.map(
								({[valueKey]: value, [labelKey]: label}: any, index: number) => (
									<option key={index} value={value}
										// selected={defaultValue === value}
									>
										{label}
									</option>
								)
							)
						}
					</select>
				</div>
				{(error) ? (
					<div className="form-error">{error}</div>) : (<></>)}
			</div>
		)
	} else {
		throw new Error("options Prop is required for this type of FormField")
	}
}

export function FMTextInput({
	                            id, type, placeholder, required, error, extraClassNames = '', defaultValue = '',
	                            title, onChange, classes, value,
	                            min, max
                            }: FMField & { min?, max?, value? }) {
	const _classes = useStyles();
	if (type === 'number') {
		if (min === undefined) min = '0';
	}
	let extraProps = assignIfNotUndefined({}, {min, max,})
	return (
		<div className={clsx(extraClassNames, "col-12 pr-mobile-0 pl-mobile-0")}>
			{Boolean(title) &&
			<div className={clsx(`col-12 row mt-4 mb-2`, _classes.title)}>
				{title}
			</div>
			}
			<input type={type} id={id} name={id} className={clsx("form-control", classes?.input)}
				onWheel={ event => event.currentTarget.blur() }
				placeholder={placeholder} defaultValue={defaultValue?.toString()} value={value}
				required={required}
				onChangeCapture={onChange}
				onInputCapture={
					(event) => {
						let target = (event.target as HTMLInputElement)
						target.reportValidity()
					}
				}
				{...extraProps}
				min={min}
				max={max}

			/>
			{(error) ? (
				<div className="form-error">{error}</div>) : (<></>)}
		</div>
	);

}


export function FMPhoneInput({
	                             id, type, placeholder, required, error, extraClassNames = '', defaultValue = '',
	                             title, onChange, classes, value, country = 'ae', onPhoneChange
                             }: FMField & { country?: any, value?: any, onPhoneChange? }) {
	const _classes = useStyles();

	let extraProps = assignIfNotUndefined({}, {country, value})
	return (
		<div className={extraClassNames == 'bs-white-bs-pg' ? clsx(extraClassNames, "pr-mobile-0 pl-mobile-0") : extraClassNames}>
			{Boolean(title) &&
			<div className={clsx(`col-12 row mt-4 mb-2`, _classes.title)}>
				{title}
			</div>
			}
			{ extraClassNames == 'bs-white-bs-pg' ? 			<PhoneInput
				preferredCountries={['ae']}
				enableAreaCodes={true}
				buttonStyle={{
					borderRadius: '5px 0 0 5px',
					backgroundColor: '#f9f9f9',
					zIndex: 10,
					border: '1px solid #cfd8e7',
					height : '58px',
				}}
				dropdownStyle={{
					borderRadius: '5px',
					zIndex: 10,
					border: '5px solid #cfd8e7',
				}}
				searchClass={"form-control-parent"}
				inputProps={{
					id,
					name: id,
					required: required
				}}
				inputStyle={{
					width: '100%',
					height : '50%',
					borderRadius: '5px',
				}}
				inputClass={'form-control'}
				autoFormat={true}
				enableSearch={true}
				onChange={onPhoneChange}
				{...extraProps}
			/>: <PhoneInput
			preferredCountries={['ae']}
			enableAreaCodes={true}
			buttonStyle={{
				borderRadius: '16px 0 0 16px',
				backgroundColor: '#f9f9f9',
				zIndex: 10,
				border: '1px solid #cfd8e7',
			}}
			dropdownStyle={{
				borderRadius: '16px',
				zIndex: 10,
				border: '1px solid #cfd8e7',
			}}
			searchClass={"form-control-parent"}
			inputProps={{
				id,
				name: id,
				required: required
			}}
			inputStyle={{
				width: '100%',
				borderRadius: '16px',
			}}
			inputClass={'bg-grey-focus-white'}
			autoFormat={true}
			enableSearch={true}
			onChange={onPhoneChange}
			{...extraProps}
		/>}

			{(error) ? (
				<div className="form-error">{error}</div>) : (<></>)}
		</div>
	);

}

export function FMFileInput({
	                            id = "customFile", placeholder, required, error,
	                            extraClassNames = '', defaultValue = false, title, onChange,
	                            multiple, mimeType
                            }: Omit<FMField, 'type'> & { multiple?: boolean, mimeType?: string }) {
	const classes = useStyles();
	const [name,setName] = useState<string>();
	const handleFileLoaded = (e: ChangeEvent<HTMLInputElement>) => {

		const selectedFiles:FileList= e.target.files ?? new FileList();
		if(multiple){
			setName(`${selectedFiles} Files`)
		}else{
			setName(selectedFiles[0]?.name?.toString())
		}
		onChange?.(e)
		// e.target.value = '';
	}
	return (
		<div className={clsx("col-12 pr-mobile-0 pl-mobile-0 my-1", extraClassNames)}
		>
			{Boolean(title) &&
						<div className={clsx(`col-12 row mb-2`, classes.title)}>
				{title}
						</div>
			}
			<div className="custom-file">
				<input
					type="file"
					className="custom-file-input d-none"
					id={id}
					name={id}
					multiple={multiple}
					accept={mimeType}
					required={required}
					onChangeCapture={handleFileLoaded}

				/>
				<label className={clsx("custom-file-label form-control",classes.file)} htmlFor={id}>
					{placeholder ?? name ??'Choose file'}
				</label>
			</div>
			{/*{(error) ? (*/}
			{/*	<div className="form-error">{error}</div>) : (<></>)}*/}

		</div>

	);

}

export function FMTimeSpanInput({
	                         id, placeholder, required, error, extraClassNames = '', defaultValue = '',
                         }: Omit<FMField,'type'>) {

	return (
		<div className={clsx("col-12 pr-mobile-0 pl-mobile-0", extraClassNames)}>
			<div className={clsx("col-12 pr-mobile-0 pl-mobile-0 flex-row d-flex flex-nowrap")}>
				<div className={"mx-1"}>

					<input type={'time'} id={id + '-start'} name={id + '-start'} className="form-control"
					       placeholder={placeholder} defaultValue={defaultValue[0]?.toString()}
					       required={required}/>
				</div>
				<div className={"mx-1"}>
					<input type={'time'} id={id + '-end'} name={id + '-end'} className="form-control"
					       placeholder={placeholder} defaultValue={defaultValue[1]?.toString()}
					       required={required}/>
				</div>

			</div>
			{(error) ? (
				<div className="form-error">{error}</div>) : (<></>)}
		</div>
	);

}

export function FMTimeInput({
	                         id, placeholder, required, error, extraClassNames = '', defaultValue = '',title
                         }: Omit<FMField,'type'>) {
	const classes = useStyles();
	return (
		<div className={clsx("col-12 pr-mobile-0 pl-mobile-0", extraClassNames)}>
			{ title !== 'none' && Boolean(title) &&
						<div className={clsx(`col-12 row mb-2`, classes.title)}>
				{title}
						</div>
			}
			<input type={'time'} id={id} name={id} className="form-control"
			       placeholder={placeholder} defaultValue={defaultValue?.toString()}
			       required={required}/>

			{(error) ? (
				<div className="form-error">{error}</div>) : (<></>)}
		</div>
	);

}
export function FMDateInput({
	                         id, placeholder, required, error, extraClassNames = '', defaultValue = '',onChange,title
                         }: Omit<FMField,'type'>) {
	const classes = useStyles();

	return (
		<div className={clsx("col-12 pr-mobile-0 pl-mobile-0", extraClassNames)}>
			{ title !== 'none' && Boolean(title) &&
				<div className={clsx(`col-12 row mb-2`, classes.title)}>
					{title}
				</div>
			}
			<input type={'date'} id={id} name={id} className="form-control"
			       placeholder={placeholder} defaultValue={defaultValue?.toString()}
			       required={required} onChangeCapture={onChange}
			/>

			{(error) ? (
				<div className="form-error">{error}</div>) : (<></>)}
		</div>
	);

}

export function FMBooleanInput({
	                        id, placeholder, required, error, extraClassNames = '', defaultValue = false,title
                        }: Omit<FMField,'type'>) {

	return (
		<div className={clsx("col-12 pr-mobile-0 pl-mobile-0", extraClassNames)}>
			<div className="custom-control custom-switch">
				<input className="custom-control-input" type="checkbox"
				       defaultChecked={Boolean(defaultValue)} id={id} name={id}
				       placeholder={placeholder} required={required}
				/>
				<label className="custom-control-label"
				       htmlFor={id}><span>{title||placeholder}</span></label>
			</div>
			{/*{(error) ? (*/}
			{/*	<div className="form-error">{error}</div>) : (<></>)}*/}
		</div>

	);

}

export function FMButton({
	                              id, placeholder, required, error, extraClassNames = '', defaultValue = '',title,isLoading,onClick=()=>{},extraProps
                              }: Omit<FMField,'type'> & { isLoading?:boolean,onClick?:React.MouseEventHandler<HTMLButtonElement>,extraProps?:any } ) {
	return (
		<div className={clsx("col-12 pr-mobile-0 pl-mobile-0", extraClassNames)}>
			<button style={{backgroundColor:"#00a9e0",borderColor: "#00a9e0",color:'white' }} type="submit" onClickCapture={onClick} className={"btn btn-lg btn-block fl_btn "+((isLoading) ? 'ld-btn' : '')} {...extraProps}>
				{(isLoading) ? (<><FontAwesomeIcon icon={faCircleNotch} spin={true} /> loading</>) : title}
			</button>
		</div>
		)

}
export function FMMultilineTextInput({
	                              id, placeholder, required, error, extraClassNames = '', defaultValue = '',
	                                     title,onChange=()=>{},rows = '5',cols,
                              }: Omit<FMField,'type'> & { rows?:string,cols?:string}) {
	const classes = useStyles();
	let textAreaProps: any = {rows};
	if (cols) textAreaProps.cols = cols
	return (
		<div className={clsx("col-12 pr-mobile-0 pl-mobile-0", extraClassNames)}>
			{title != 'none' && Boolean(title) &&
						<div className={clsx(`col-12 row mt-4 mb-2`, classes.title)}>
				{title}
						</div>
			}
			<textarea id={id} name={id} className="form-control"
			          placeholder={placeholder} defaultValue={defaultValue?.toString()}
			          required={required} onChangeCapture={onChange} {...textAreaProps}
			/>
			{(error) ? (
				<div className="form-error">{error}</div>) : (<></>)}
		</div>
	);

}

export const FMFieldGenerator = (options: FMField) => {
	const {type} = options;
	switch (type) {
		case 'text/multi-line':
			return <FMMultilineTextInput {...options} />
		case 'options':
			return <FMOptionsInput {...options} />
		case 'select':
			return <FMSelectInput {...options} />
		case 'bool':
			return <FMBooleanInput {...options} />
		case 'timespan':
			return <FMTimeSpanInput {...options} />
		case 'empty':
			return <div className={clsx("col-12", options.extraClassNames)}/>

		case 'custom':
			return options.customView
		default:
			return <FMTextInput {...options} />
	}
}

