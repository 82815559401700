import React, { useEffect, useRef, useState } from "react";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import Button from "@material-ui/core/Button";
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import CreateBookingForm from "./create-booking-form";
import ImportBookingForm from "./import-booking-form";
import { HostAPI } from "src/APIs/HostAPI";
import AlertSnackbar from "src/components/organisms/snackbar/AlertSnackBar";

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

function EventCalendar({bookings, spaceId='', venueId='', spaceImg=''}) {

    const [events, setEvents] = useState([]);
    const [eventItem, setEventItem] = useState([]);
    const [buttonType, setButtonType] = useState('');
    const [open, setOpen] = React.useState(false);
    const [formData, setFormData] = useState({});
    const [fileData, setFileData] = useState(new FormData());
    const [start, setStart] = useState(new Date());
    const [bname, setBname] = useState('Booking');
    const [end, setEnd] = useState(new Date());
    const [selectedEvent, setSelectedEvent] = useState(0);
    const [isAwaiting, setIsAwaiting] = React.useState(false);
    const snackbar = useRef(null);

    const handleFormData = (name, value) => {
        formData[name] = value;
        console.log(name, value);
    };

    const handleOpen = () => {
        if (spaceId === '') {
            return;
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const formatDate = function (date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    const formatDisplayable = (date) =>
    {
        let d = new Date(date);
        let str = d.toString().substring(0, 21);
        return str;
    }

    let addHours = function(date, time) {
        let timeBreakdown = time.split(':');
        let hours = parseInt(timeBreakdown[0]);
        let minutes = parseInt(timeBreakdown[1]);
        date.setTime(date.getTime() + (hours * 60 * 60 * 1000) + (minutes * 60 * 1000));
      
        return date;
      }

    const handleSubmit = () => {
        if (buttonType === 'form-new-reservation') {
            console.log('new');
            let submitData = formData;
            submitData.start = formatDate(submitData.start);
            submitData.end = formatDate(submitData.end);
            
            let response = HostAPI.createEvent(formData).then((data) => {
                if(data.status === 'Success'){
                    let start = new Date(data.booking.start);
                    // start.setHours(data.booking.start_time);
                    let startHours = start.getHours();
                    let end = new Date(data.booking.end);
                    // end.setHours(data.booking.end_time);
                    let endHours = end.getHours();
                    let ev = {
                        id: data.id,
                        title: startHours + (startHours < 12 ? 'AM' : 'PM') + " - " + endHours + (endHours < 12 ? 'AM' : 'PM')+ " " + (data.booking.booking_name ?? 'Booking'),
                        start: start,
                        end: end,
                    };
                    setEvents([...events, ev]);
                    handleClose();
                    snackbar.current?.setDataFromMessageObject({
                        message: 'New Booking Created',
                        type: 'success'
                    })
                    snackbar.current?.open()
                }
                if (data.status === 'error') {
                    snackbar.current?.setDataFromMessageObject({
                        message: data.message
                    })
                    snackbar.current?.open()
                }
                console.log(data);
            }).catch((error) => {
                snackbar.current?.setDataFromMessageObject({
                    message: error.response
                })
                snackbar.current?.open()
            });
        }

        if (buttonType === 'form-booking-import') {
            console.log('import');
            fileData.append('workspaceId', spaceId);
            fileData.append('venueId', venueId);

            let submitData = fileData;

            HostAPI.uploadImport(submitData).then((data) => {
                console.log(data);
                if(data.status === 'success'){
                    let prevEvent = events;
                    for (let index = 0; index < data.uploaded.length; index++) {
                        setIsAwaiting(true);
                        const booking = data.uploaded[index];
                        
                        let start = new Date(booking.start);
                        // start.setHours(data.booking.start_time);
                        let startHours = start.getHours();
                        let end = new Date(booking.end);
                        // end.setHours(data.booking.end_time);
                        let endHours = end.getHours();
                        let ev = {
                            id: booking.id,
                            title: startHours + (startHours < 12 ? 'AM' : 'PM') + " - " + endHours + (endHours < 12 ? 'AM' : 'PM')+ " " + (booking.booking_name ?? 'Booking'),
                            start: start,
                            end: end,
                        };
                        prevEvent.push(ev);
                    }
                    setEvents(prevEvent);
                    setIsAwaiting(false);
                    handleClose();
                    snackbar.current?.setDataFromMessageObject({
                        message: 'Data Imported Successfully',
                        type: 'success'
                    })
                    snackbar.current?.open()
                }

                if (data.status === 'error') {
                    snackbar.current?.setDataFromMessageObject({
                        message: data.message
                    })
                    snackbar.current?.open()
                }
            }).catch((error) => {
                console.log(error);
                snackbar.current?.setDataFromMessageObject({
                    message: error.response
                })
                snackbar.current?.open()
            });
        }

        if (buttonType === 'form-edit-reservation') {
            console.log('edit booking');

            HostAPI.updateEvent(selectedEvent, formData).then((data) => {
                console.log(data);
                if(data.status === 'Success'){
                    let start = new Date(data.booking.start);
                    let startHours = start.getHours();
                    let end = new Date(data.booking.end);
                    let endHours = end.getHours();
                    let prevEvents = events;
                    prevEvents.forEach((event) => {
                        setIsAwaiting(true);
                        if(event.id == data.id) {
                            event.id = data.id;
                            event.title = startHours + (startHours < 12 ? 'AM' : 'PM') + " - " + endHours + (endHours < 12 ? 'AM' : 'PM')+ " " + (data.booking.booking_name ?? 'Booking');
                            event.start = start;
                            event.end = end;
                        }
                        setButtonType('form-new-reservation');
                    })

                    setEvents(prevEvents);
                    setIsAwaiting(false);

                    console.log(events);
                    
                    handleClose();
                    snackbar.current?.setDataFromMessageObject({
                        message: 'Booking Updated',
                        type: 'success'
                    })
                    snackbar.current?.open()
                } 
                if (data.status === 'error') {
                    snackbar.current?.setDataFromMessageObject({
                        message: data.message
                    })
                    snackbar.current?.open()
                }
                console.log(data);
            }).catch((error) => {
                console.log(error);
                snackbar.current?.setDataFromMessageObject({
                    message: error.response
                })
                snackbar.current?.open()
            });
        }
    };
    
    const handleDateClick = (arg) => { // bind with an arrow function
        let startd = arg.date,
        sd = startd.getFullYear() +'-'+ ((startd.getMonth()+1) < 10 ? '0'+(startd.getMonth()+1) : (startd.getMonth() + 1)) + '-' + (startd.getDate() < 10 ? '0'+startd.getDate() : startd.getDate())+' '+ '08:00:00',
        ed = startd.getFullYear() +'-'+ ((startd.getMonth()+1) < 10 ? '0'+(startd.getMonth()+1) : (startd.getMonth() + 1)) + '-' + (startd.getDate() < 10 ? '0'+startd.getDate() : startd.getDate())+' '+ '09:00:00';
        setStart(new Date(sd));
        setEnd(new Date(ed));
        setButtonType('form-new-reservation');
        handleOpen();
    };

    const handleSelect = (info) => {
        console.log(info);
        let is = Date.parse(info.start);
        let ie = Date.parse(info.end);
        let flag = true;
        for (let index = 0; index < bookings.length; index++) {
            const booking = bookings[index];

            let s = Date.parse(new Date(booking.start));
            let e = Date.parse(new Date(booking.end));
            if ((is >= s && is < e && e > ie) || (is < s && ie > s && ie <= e) || (is > s && ie < e) || (is < s && ie > e)) {
                flag = false;
                break;
            }
            
        }
        if (flag) {
            setStart(info.start);
            setEnd(info.end);
            setButtonType('form-new-reservation');
            handleOpen();
        } else {
            snackbar.current?.setDataFromMessageObject({
                message: 'Booking Not Available'
            })
            snackbar.current?.open()
        }
    };

    const handleEventClick = (info) => { // bind with an arrow function
        console.log(info);
        setEventItem(info.event);
        setButtonType('booking-details');
    }

    const handleResize = (info) => {
        console.log(info);
        setEventItem(info.event);
        setButtonType('booking-details');
        setButtonType('form-edit-reservation')
        setSelectedEvent(info.event.id);
        let start = new Date(info.event.start);
        let startHours = start.getHours();
        let end = new Date(info.event.end);
        let endHours = end.getHours();
        let title = info.event.title.replaceAll(startHours + (startHours < 12 ? 'AM' : 'PM') + " - " + endHours + (endHours < 12 ? 'AM' : 'PM')+ " ", '');
        setBname(title);
        setStart(info.event.start);
        setEnd(info.event.end);
        handleOpen()
    };

    const handleDrop = (info) => {
        console.log(info);
    };

    const handleEventRender = (info) => {
        return {html: '<span>' + info.event.title + '</span>'}
    };

    const btnPrimary = {
        marginBottom: 12,
        backgroundColor: '#1976d2',
        color: '#ffffff',
        width: '100%',
        cursor: 'pointer'
    };

    const btnSuccess = {
        marginBottom: 12,
        backgroundColor: '#2e7d32',
        color: '#ffffff',
        width: '100%',
        cursor: 'pointer'
    };

    const DialogContent = withStyles((theme) => ({
        root: {
          padding: theme.spacing(2),
          minHeight: '500px'
        },
    }))(MuiDialogContent);
      
    const DialogActions = withStyles((theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(1),
        },
    }))(MuiDialogActions);

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
          <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
              <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </MuiDialogTitle>
        );
      });

      const handleEditBtnClick = (e) => {
            handleOpen()
            setButtonType('form-edit-reservation')
            console.log(eventItem, events);
            setSelectedEvent(eventItem.id);
            let start = new Date(eventItem.start);
            let startHours = start.getHours();
            let end = new Date(eventItem.end);
            let endHours = end.getHours();
            let title = eventItem.title.replaceAll(startHours + (startHours < 12 ? 'AM' : 'PM') + " - " + endHours + (endHours < 12 ? 'AM' : 'PM')+ " ", '');
            setBname(title);
            setStart(eventItem.start);
            setEnd(eventItem.end);
      };
    useEffect(() => {
        let events = [];
        for (let index = 0; index < bookings.length; index++) {
            const booking = bookings[index];
            let start = new Date(booking.start).getHours();
            let end = new Date(booking.end).getHours();
            events.push({
                id: booking.id,
                title: start + (start < 12 ? 'AM' : 'PM') + " - " + end + (end < 12 ? 'AM' : 'PM')+ " " + booking.booking_name,
                start: booking.start,
                end: booking.end,
            });
        }
        setEvents(events);
        handleFormData('workspaceId', spaceId);
        handleFormData('venueId', venueId);
    }, [bookings, spaceId, venueId])
    return (
        <div>
            <div className="row">
                <div className="col-md-10 col-sm-12">
                    {!isAwaiting && 
                        <FullCalendar
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            eventColor= '#378006'
                            events={events}
                            dateClick={(arg) => handleDateClick(arg)}
                            select= {(info) => handleSelect(info)}
                            eventClick={(info) => handleEventClick(info)}
                            headerToolbar = {{
                                left: 'prev,next today',
                                center: 'title',
                                right: 'dayGridMonth,dayGridWeek,timeGridDay'
                            }}
                            dayMaxEvents={9}
                            editable={true}
                            selectable={true}
                            eventResize={(info) => handleResize(info)}
                            eventDragStop={(info) => handleDrop(info)}
                            eventContent={(event) => handleEventRender(event)}
                            // businessHours={{
                            //     startTime: '08:00'
                            // }}
                        />
                    }
                </div>
                <div className="col-md-2 col-sm-12">
                    <div>
                        <Button
                            variant="contained"
                            size="small"
                            className={clsx('px-5')}
                            style={btnPrimary}
                            onClickCapture={() => {
                                handleOpen()
                                setButtonType('form-new-reservation')
                                setBname('');
                                let startd = new Date(),
                                sd = startd.getFullYear() +'-'+ ((startd.getMonth()+1) < 10 ? '0'+(startd.getMonth()+1) : (startd.getMonth() + 1)) + '-' + (startd.getDate() < 10 ? '0'+startd.getDate() : startd.getDate())+' '+ '08:00:00',
                                ed = startd.getFullYear() +'-'+ ((startd.getMonth()+1) < 10 ? '0'+(startd.getMonth()+1) : (startd.getMonth() + 1)) + '-' + (startd.getDate() < 10 ? '0'+startd.getDate() : startd.getDate())+' '+ '09:00:00';
                                setStart(new Date(sd));
                                setEnd(new Date(ed));
                            }}
                        >
                            Create New Booking
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            className={clsx('px-5')}
                            style={btnSuccess}
                            onClickCapture={() => {
                                handleOpen()
                                setButtonType('form-booking-import')
                            }}
                        >
                            Import Bookings
                        </Button>
                    </div>
                    <Divider />
                    <div className="mt-2">
                        {spaceImg && <img style={{width: '100%'}} src={"https://fluidmeet.s3.amazonaws.com/venue_logos/"+spaceImg} /> }
                        
                    </div>
                    <div className="mt-2">
                        {(buttonType === 'booking-details' || buttonType === 'form-edit-reservation') && (
                            <div>
                                <Button
                                    variant="contained"
                                    size="small"
                                    className={clsx('px-5')}
                                    style={btnPrimary}
                                    onClickCapture={(e) => handleEditBtnClick(e)}
                                >
                                    Edit Booking
                                </Button>
                                <h3>{'Event Details'}</h3>
                                <p>Title: {eventItem['title']}</p>
                                <p>Start: {formatDisplayable(eventItem['startStr'])}</p>
                                <p>End: {formatDisplayable(eventItem['endStr'])}</p>
                            </div>
                        )}
                    </div>
                    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                            {buttonType === 'form-new-reservation' ? 'Create Booking':''}
                            {buttonType === 'form-edit-reservation' ? 'Edit Booking':''}
                            {buttonType === 'form-booking-import' ? 'Import Booking':''}
                        </DialogTitle>
                        <DialogContent dividers>
                            <>
                                {(buttonType === 'form-new-reservation' || buttonType === 'form-edit-reservation') && <CreateBookingForm handleFormData={handleFormData} startDate={start} endDate={end} bname={bname} />}
                                {buttonType === 'form-booking-import' && <ImportBookingForm fileData={fileData} />}
                                
                            </>
                            <Button autoFocus onClick={handleSubmit} color="primary">
                                {buttonType === 'form-new-reservation' ? 'Create Booking':''}
                                {buttonType === 'form-edit-reservation' ? 'Save Changes':''}
                                {buttonType === 'form-booking-import' ? 'Import Booking':''}
                            </Button>
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
            <AlertSnackbar ref={snackbar}/>
        </div>
    )
}

export default EventCalendar;