
export const CitiesRef = {
  "London": "United Kingdom",
  "Abu Dhabi": "United Arab Emirates",
  "Al Ain": "United Arab Emirates",
  "Al Khan": "United Arab Emirates",
  "Ar Ruways": "United Arab Emirates",
  "As Satwah": "United Arab Emirates",
  "Dayrah": "United Arab Emirates",
  "Dubai": "United Arab Emirates",
  "Fujairah": "United Arab Emirates",
  "Ras al-Khaimah": "United Arab Emirates",
  "Sharjah": "United Arab Emirates",
  "Al Jubail": "Saudi Arabia",
  "Dammam": "Saudi Arabia",
  "Jeddah": "Saudi Arabia",
  "Riyadh": "Saudi Arabia",
  "Mumbai": "India",
  "Bangalore": "India",
  "Abraq Khaytan": "Kuwait",
  "Ad Dasmah": "Kuwait",
  "Ad Dawhah": "Kuwait",
  "Al Ahmadi": "Kuwait",
  "Al Farwaniyah": "Kuwait",
  "Al Shamiya": "Kuwait",
  "Ar Rawdah": "Kuwait",
  "As Salimiyah": "Kuwait",
  "Ash Shu`aybah": "Kuwait",
  "Ash Shuwaykh": "Kuwait",
  "Bayan": "Kuwait",
  "Hawalli": "Kuwait",
  "Janub as Surrah": "Kuwait",
  "Kayfan": "Kuwait",
  "Kuwait City": "Kuwait",
  "Salwa": "Kuwait",
  "Doha": "Qatar",
  "Doha and the rest of Qatar": "Qatar",
  "Manama and the rest of Bahrain": "Bahrain",
  "Al Sohar": "Oman",
  "Masqat Matrah": "Oman",
  "Muscat": "Oman",
  "Nizwa": "Oman",
  "Ruwi": "Oman",
  "Saham": "Oman",
  "Salalah": "Oman",
  "Samad": "Oman",
  "Amman": "Jordan",
};
