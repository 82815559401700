import React, {Component} from 'react';
import {Link, Redirect, withRouter} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faArrowLeft,
  faHeart as faSolidHeart,
  faSearch,
  faUserCircle
} from '@fortawesome/free-solid-svg-icons';
import markerIcon from "../../img/pin.png";
import printLocal, {createAlert, devLog, getApiLinkV2, getToken, getUser, printLink, resetUser} from "../../Helpers";
import axios from "axios";
import {getAxios} from "src/Helpers";
import {faHeart, faShareSquare} from "@fortawesome/free-regular-svg-icons";
import Bugsnag from "@bugsnag/js";
import {HostDashboard} from "../host/host-dashboard/host-dashboard";
import ContractDetails from "../host/host-dashboard/subscription/contract-details";

class NavBar extends Component {
  
  constructor(props) {
    super(props)

    this.state = {
      user: null,
      oldPath: "",
      location: "",
      locationValue: "",
      location_ngbr_id: "",
      type: "",
      placeholder: 'Search for city',
      locations: [],
      isFirstTime: true,
      isMenuFull: false,
      isSearchFull: false,
      isScrolled: false,
      isMobileView: false,
      finalScrolled: false,
      isDropdownOpen: false,
      currentCountry: "",
      currentCity: ""
    }

    this.handleScroll = this.handleScroll.bind(this);

    this.locationInput = React.createRef();
    this.navRef = React.createRef();
    this.locationSearchRef = React.createRef();
    this.locationSearchBoxRef = React.createRef();
    this.typeSearchRef = React.createRef();
    this.typeSearchBoxRef = React.createRef();
    this.handleClickOut = this.handleClickOut.bind(this);
    this.searchLocationTapped = this.searchLocationTapped.bind(this);
    this.handleMenuTapped = this.handleMenuTapped.bind(this);
    this.handleSearchLocation = this.handleSearchLocation.bind(this);
    this.handleLocationChosen = this.handleLocationChosen.bind(this);
    this.handleTypeChosen = this.handleTypeChosen.bind(this);
    this.toggleNav = this.toggleNav.bind(this);
    this.initNavBar = this.initNavBar.bind(this);
    this.handleSize = this.handleSize.bind(this);
    this.searchTapped = this.searchTapped.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.searchCities = this.searchCities.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (this.props.location.pathname !== prevProps.location.pathname) {
  //     window.scrollTo(0, 0);
  //   }
  // }

  componentDidMount() {

    window.scrollTo(0, 0);

    const isMobileView = window.innerWidth < 768;

    this.setState({user: getUser(), isMobileView: isMobileView});

    let location = this.props.match.params.city;
    let type = this.props.match.params.type;

    if (location) {
      this.setState({location: location, locationValue: location});
    }
    if (type) {
      this.setState({type: type});
    }

    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleSize);
    document.addEventListener('mousedown', this.handleClickOut);

    this.initNavBar();
  }

  componentWillUnmount() {

    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleSize);
    document.removeEventListener('mousedown', this.handleClickOut);
  }

  handleClickOut(event) {

    if (!this.locationSearchRef.current.contains(event.target) && !this.locationSearchBoxRef.current.contains(event.target)) {
      document.getElementById("location-search")?.classList.remove("location-focus");
      document.getElementById("dropdown-search")?.classList.remove("show");
      this.setState({placeholder: 'Search for city'});
    }

    if (!this.typeSearchRef.current.contains(event.target) && !this.typeSearchBoxRef.current.contains(event.target)) {
      document.getElementById("type-search")?.classList.remove("type-focus");
      document.getElementById("dropdown-types")?.classList.remove("show");
    }

    if (!this.navRef.current.contains(event.target)) {
      if (this.state.isSearchFull) {
        this.handleFullSearch(false);
      }
      if (this.state.isMenuFull) {
        this.handleMenuTapped();
      }
    }
  }

  searchLocationTapped() {
    this.locationInput.focus();
    document.getElementById("location-search")?.classList.add("location-focus");
  }

  searchTypeTapped() {
    document.getElementById("type-search")?.classList.add("type-focus");
    document.getElementById("dropdown-types")?.classList.add("show");
  }

  searchTapped(type) {

    if (this.state.isScrolled) {
      this.handleFullSearch(true);
    }
    if (type) {
      if (type == "location") {
        this.searchLocationTapped();
        this.handleSearchLocation();
      } else if (type == "type") {
        this.searchTypeTapped();
      }
    }
  }

  handleSearchLocation() {
    this.setState({placeholder: 'Start typing...'});
    document.getElementById("dropdown-search")?.classList.add("show");
    this.searchCities(null);
    this.locationInput.focus();
  }

  handleLocationChosen(item) {

    if (item.id === 'others') {
      this.locationInput.focus();
      return;
    }

    this.setState({currentCountry: item.cn_name, currentCity: item.c_name});

    document.getElementById("location-search")?.classList.remove("location-focus");
    document.getElementById("dropdown-search")?.classList.remove("show");

    if (item.n_id !== '' && item.n_name !== '') {
      this.setState({location: item.id, location_ngbr_id: item.n_name, locationValue: item.n_name+" - "+item.c_name}, () => {
        if (this.state.type === "") {
          this.searchTypeTapped();
        } else {
          this.handleSearch(item.c_name);
        }
      });
    } else {
      this.setState({location: item.id, location_ngbr_id: '', locationValue: item.c_name}, () => {
        if (this.state.type === "") {
          this.searchTypeTapped();
        } else {
          this.handleSearch(item.c_name);
        }
      });
    }
  }

  handleTypeChosen(type) {

    document.getElementById("type-search")?.classList.remove("type-focus");
    document.getElementById("dropdown-types")?.classList.remove("show");

    this.setState({ type: type }, () => {
      if (this.state.location === "") {
        this.searchLocationTapped();
      } else {
        this.handleSearch();
      }
    });
  }

  handleSearch() {

    const { fullSearch } = this.props;

    if (this.state.location === "" && !fullSearch) {
      this.searchLocationTapped();
      return;
    }
    if (this.state.type === "") {
      this.searchTypeTapped();
      return;
    }

    // if (this.state.isSearchFull) {
    //   this.handleFullSearch(false);
    // }

    var newPath = "/search/"+this.state.location+"/"+this.state.type;
    if (this.state.location_ngbr_id !== '') {
      this.props.history.push({
        pathname: printLink(newPath, this.props.location.search, 'n', this.state.location_ngbr_id),
        state: {country: this.state.currentCountry, city: this.state.currentCity}
      });
    } else {
      this.props.history.push({
        pathname: newPath,
        state: {country: this.state.currentCountry, city: this.state.currentCity}
      });
    }
  }

  handleMenuTapped() {

    if (this.state.isSearchFull) {
      this.handleFullSearch(false);
    }
    if (this.state.isMenuFull) {
      document.getElementById("sm-menu")?.classList.remove("show");
      document.getElementById("nav")?.classList.remove("max-nav-menu");
      this.setState({ isMenuFull: false });
    } else {
      document.getElementById("nav")?.classList.add("max-nav-menu");
      document.getElementById("sm-menu")?.classList.add("show");
      this.setState({ isMenuFull: true });
    }
  }

  initNavBar() {

    if (this.props.hideSearch) {
      return;
    }

    if (!document.getElementById("search-nav")) return;

    const { largeSearch, fullSearch } = this.props;
    let scrolled = window.scrollY > 10;
    let navShow = (fullSearch && window.innerWidth > 440) ? "show-inline" : "show-flex";
    if (!largeSearch || scrolled || window.innerWidth < 768) {

      document.getElementById("search-header")?.classList.remove("show");
      // document.getElementById("search-opt-nav")?.classList.remove("show");
      document.getElementById("search-nav")?.classList.add(navShow);
      document.getElementById("search-nav")?.classList.add("opacity-1");
      document.getElementById("host-btn")?.classList.remove("white-text");
      document.getElementById("logo")?.classList.add("logo-dark");
      document.getElementById("nav")?.classList.add("bg-white");
      document.getElementById("nav")?.classList.add("shadow-nav");

      this.setState({ isScrolled: true, finalScrolled: true });
    }
    setTimeout(() => {
      if (document.getElementById("nav")) {
        document.getElementById("nav")?.classList.add("nav-trans");
      }
    });
  }

  handleSize(event) {

    const isNavWhite = document.getElementById("nav")?.classList.contains("bg-white");
    const isMobileView = window.innerWidth < 768;
    const oldMobileView = this.state.isMobileView;

    if (isMobileView !== oldMobileView) {
      this.setState({isMobileView: isMobileView});
    }

    if (isNavWhite && window.innerWidth > 767) {
      this.handleScroll(null);
      return;
    }
    if (!isNavWhite && window.innerWidth < 768) {
      this.toggleNav(true, true);
      return;
    }
  }

  handleScroll(event) {

    const { largeSearch, hideSearch } = this.props;

    let scrolled = window.scrollY > 10;
    let oldScrolled = this.state.isScrolled;
    let finalScrolled = this.state.finalScrolled;

    if (hideSearch) {
      return;
    }

    if (this.state.isFirstTime) {
      this.initNavBar();
      this.setState({ isFirstTime: false });
      return;
    }

    if (largeSearch && scrolled !== oldScrolled && oldScrolled === finalScrolled && window.innerWidth > 767) {
      this.toggleNav(scrolled, false);
    }
  }

  handleFullSearch(open) {

    if (this.state.isMenuFull) {
      document.getElementById("sm-menu")?.classList.remove("show");
      document.getElementById("nav")?.classList.remove("max-nav-menu");
      this.setState({ isMenuFull: false });
    }

    const { fullSearch, smallSearch } = this.props;
    let navShow = (fullSearch && window.innerWidth > 440) ? "show-inline" : "show-flex";

    if (open) {

      document.getElementById("host-btn")?.classList.remove("white-text");
      document.getElementById("logo")?.classList.add("logo-dark");
      document.getElementById("nav")?.classList.add("bg-white");
      document.getElementById("nav")?.classList.add("shadow-nav");
      document.getElementById("nav")?.classList.add("max-nav-h");
      // document.getElementById("search-opt-nav")?.classList.remove("white-text");
      // document.getElementById("search-opt-tab")?.classList.remove("bg-white");
      document.getElementById("search-holder").innerHTML = "";
      document.getElementById("search-bar")?.classList.add("search-bg");
      document.getElementById("location-btn")?.classList.add("opacity-0");
      document.getElementById("type-btn")?.classList.add("opacity-0");
      document.getElementById("srch-btn")?.classList.add("opacity-0");

      setTimeout(() => {

        document.getElementById("search-header")?.classList.remove("opacity-1");
        document.getElementById("search-header")?.classList.add("show");

        let searchHeader = document.getElementById("search-full").getBoundingClientRect();
        let searchNav = document.getElementById("search-nav").getBoundingClientRect();
        let newX = (window.innerWidth > 767) ? 0 : -20;

        document.getElementById("search-nav").style.transform = "scale("+(searchHeader.width/searchNav.width)+", "+(searchHeader.height/searchNav.height)+") translate("+newX+"px, "+(searchHeader.top-searchNav.top-11)+"px)";

        setTimeout(() => {

          if (!document.getElementById("search-nav")) return;

          document.getElementById("search-header")?.classList.add("opacity-1");
          document.getElementById("search-nav")?.classList.remove(navShow);
          // document.getElementById("search-opt-nav")?.classList.add("show");
          document.getElementById("search-nav").style.transform = "initial";

          this.setState({ isSearchFull: true });

        }, 100);

      }, 80);

    } else {

      document.getElementById("search-holder").innerHTML = "";
      document.getElementById("search-bar")?.classList.add("search-bg");
      document.getElementById("location-btn")?.classList.add("opacity-0");
      document.getElementById("type-btn")?.classList.add("opacity-0");
      document.getElementById("srch-btn")?.classList.add("opacity-0");
      document.getElementById("host-btn")?.classList.remove("white-text");

      setTimeout(() => {

        if (!document.getElementById("search-nav")) return;

        document.getElementById("search-header")?.classList.remove("opacity-1");
        document.getElementById("search-nav")?.classList.remove("opacity-1");
        // document.getElementById("search-opt-nav")?.classList.remove("show");
        document.getElementById("search-nav")?.classList.add(navShow);

        let searchHeader = document.getElementById("search-full").getBoundingClientRect();
        let searchNav = document.getElementById("search-nav").getBoundingClientRect();
        let newX = (window.innerWidth > 767) ? 0 : -30;
        // let newX = searchHeader.left - searchNav.left;
  
        devLog(searchHeader.left, searchHeader.top, searchHeader.width, searchHeader.height);

        document.getElementById("search-nav").style.transform = "scale("+(searchHeader.width/searchNav.width)+", "+(searchHeader.height/searchNav.height)+") translate("+newX+"px, "+(searchHeader.top-searchNav.top-11)+"px)";

        setTimeout(() => {

          if (!document.getElementById("search-nav")) return;

          document.getElementById("search-header")?.classList.remove("show");
          document.getElementById("search-header")?.classList.add("opacity-1");
          document.getElementById("search-nav")?.classList.add("opacity-1");
          document.getElementById("search-nav").style.transform = "initial";

          const location = this.props.match.params.city;
          const type = this.props.match.params.type;

          if (fullSearch && location) {
            document.getElementById("search-holder").innerHTML = (location !== 'nearby') ? '<span class="active">'+printLocal(location)+'</span>' : "Nearby";
          } else if (fullSearch && type) {
            document.getElementById("search-holder").innerHTML = '<span class="active">'+printLocal(type)+'</span>';
          } else {
            document.getElementById("search-holder").innerHTML = (smallSearch && window.innerWidth < 400) ? "Search..." : "What are you looking for?";
          }

          document.getElementById("search-bar")?.classList.remove("search-bg");
          document.getElementById("location-btn")?.classList.remove("opacity-0");
          document.getElementById("type-btn")?.classList.remove("opacity-0");
          document.getElementById("srch-btn")?.classList.remove("opacity-0");
          document.getElementById("nav")?.classList.remove("max-nav-h");

          this.setState({ isSearchFull: false, location: location, type: type});

        }, 100);

      }, 80);
    }
  }

  toggleNav(scrolled, force) {

    const { fullSearch, smallSearch, topSpace } = this.props;
    let oldScrolled = this.state.isScrolled;
    let hideSearch = this.props.hideSearch;
    let finalScrolled = this.state.finalScrolled;
    let navShow = (fullSearch && window.innerWidth > 440) ? "show-inline" : "show-flex";
    // let isoptNavWhite = document.getElementById("search-opt-nav")?.classList.contains("white-text");

    if (hideSearch) return;

    if (!document.getElementById("search-nav")) return;

    if (scrolled) {

        // document.getElementById("search-opt-nav")?.classList.remove("show");
        document.getElementById("search-nav")?.classList.remove("opacity-1");
        document.getElementById("search-nav")?.classList.add(navShow);

        let searchHeader = document.getElementById("search-full").getBoundingClientRect();
        let searchNav = document.getElementById("search-nav").getBoundingClientRect();
        let newX = (window.innerWidth > 767) ? 0 : -30;
        // let newX = searchHeader.left - searchNav.left;

        document.getElementById("search-header")?.classList.remove("show");
        document.getElementById("search-holder").innerHTML = "";
        document.getElementById("search-bar")?.classList.add("search-bg");
        document.getElementById("location-btn")?.classList.add("opacity-0");
        document.getElementById("type-btn")?.classList.add("opacity-0");
        document.getElementById("srch-btn")?.classList.add("opacity-0");
        document.getElementById("search-nav").style.transform = "scale("+(searchHeader.width/searchNav.width)+", "+(searchHeader.height/searchNav.height)+") translate("+newX+"px, "+(searchHeader.top-searchNav.top-11)+"px)";
        // document.getElementById("search-opt-nav").style.transform = "scale3d(0.3, 0.3, 1)";
        document.getElementById("nav")?.classList.remove("max-nav-h");

        setTimeout(() => {

          if (!force && oldScrolled !== finalScrolled) { return }
          if (!document.getElementById("search-nav")) return;

          document.getElementById("search-nav")?.classList.add("opacity-1");
          document.getElementById("host-btn")?.classList.remove("white-text");
          document.getElementById("logo")?.classList.add("logo-dark");
          document.getElementById("nav")?.classList.add("bg-white");
          document.getElementById("nav")?.classList.add("shadow-nav");

          setTimeout(() => {

            if (!force && oldScrolled !== finalScrolled) { return }

            document.getElementById("search-nav").style.transform = "initial";
            // document.getElementById("search-opt-nav").style.transform = "initial";

            const location = this.props.match.params.city;
            const type = this.props.match.params.type;

            if (fullSearch && location) {
              document.getElementById("search-holder").innerHTML = (location !== 'nearby') ? '<span class="active">'+printLocal(location)+'</span>' : "Nearby";
            } else if (fullSearch && type) {
              document.getElementById("search-holder").innerHTML = '<span class="active">'+printLocal(type)+'</span>';
            } else {
              document.getElementById("search-holder").innerHTML = (smallSearch && window.innerWidth < 400) ? "Search..." : "What are you looking" +
                " for?";
            }

            document.getElementById("search-bar")?.classList.remove("search-bg");
            document.getElementById("location-btn")?.classList.remove("opacity-0");
            document.getElementById("type-btn")?.classList.remove("opacity-0");
            document.getElementById("srch-btn")?.classList.remove("opacity-0");

            this.setState({ finalScrolled: scrolled });

          }, 30);

        }, 50);

      } else {

        document.getElementById("nav")?.classList.remove("bg-white");
        document.getElementById("nav")?.classList.remove("shadow-nav");
        document.getElementById("logo")?.classList.remove("logo-dark");
        document.getElementById("host-btn")?.classList.add("white-text");
        // document.getElementById("search-opt-nav")?.classList.add("white-text");
        // document.getElementById("search-opt-tab")?.classList.add("bg-white");

        setTimeout(() => {

          if (!force && oldScrolled != finalScrolled) { return }

          document.getElementById("search-header")?.classList.remove("opacity-1");
          document.getElementById("search-header")?.classList.add("show");

          let searchHeader = document.getElementById("search-full").getBoundingClientRect();
          let searchNav = document.getElementById("search-nav").getBoundingClientRect();
          let newX = (window.innerWidth > 767) ? 0 : -30;
          let margin = (topSpace) ? 21 : 11;
          // let newX = searchHeader.left - searchNav.left;

          document.getElementById("search-holder").innerHTML = "";
          document.getElementById("search-bar")?.classList.add("search-bg");
          document.getElementById("location-btn")?.classList.add("opacity-0");
          document.getElementById("type-btn")?.classList.add("opacity-0");
          document.getElementById("srch-btn")?.classList.add("opacity-0");
          document.getElementById("search-nav").style.transform = "scale("+(searchHeader.width/searchNav.width)+", "+(searchHeader.height/searchNav.height)+") translate("+newX+"px, "+(searchHeader.top-searchNav.top-margin)+"px)";
          document.getElementById("nav")?.classList.remove("max-nav-h");

          // if (isoptNavWhite) {
          //   document.getElementById("search-opt-nav").style.transform = "scale3d(0.3, 0.3, 1)";
          // }

          setTimeout(() => {

            if (!force && oldScrolled !== finalScrolled) { return }
            if (!document.getElementById("search-nav")) return;

            document.getElementById("search-header")?.classList.add("opacity-1");
            document.getElementById("search-nav")?.classList.remove(navShow);
            // document.getElementById("search-opt-nav")?.classList.add("show");
            // if (isoptNavWhite) {
            //   document.getElementById("search-opt-nav").style.transform = "initial";
            // }
            document.getElementById("search-nav").style.transform = "initial";

            this.setState({ finalScrolled: scrolled });

          }, 100);

        }, 80);
      }

      this.setState({ isScrolled: scrolled });
  }

  searchCities(event) {

    if (event && event.target && event.target.value && event.target.value != "") {

      this.setState({ locationValue: event.target.value });

      const url = getApiLinkV2('city?search='+event.target.value);
      getAxios().get(url).then(response => {
        var cities = [];
        var count = 0;
        if (response.data.cities) {
          Object.keys(response.data.cities).forEach(key => {
            if (count < 6) {
              let item = response.data.cities[key];
              count += 1;
              cities.push({id: item.id, c_name: item.c_name, cn_name: item.cn_name, n_id: item.n_id, n_name: item.n_name});
            }
          });
        }
        this.setState({locations: cities});
      }).catch(error => {
        Bugsnag.notify(error);
        this.setState({locations: []});
      });

    } else {

      const suggestions = [
        { id: 'Dubai', c_name: 'Dubai', cn_name: 'United Arab Emirates', n_id: '', n_name: '' },
        { id: 'London', c_name: 'London', cn_name: 'United Kingdom', n_id: '', n_name: '' },
        { id: 'Mumbai', c_name: 'Mumbai', cn_name: 'India', n_id: '', n_name: '' },
        { id: 'others', c_name: 'Other places', cn_name: '', n_id: '', n_name: '' },
      ]
      this.setState({locations: suggestions, locationValue: ''});
    }
  }

  handleSignOut() {
  
    const url = getApiLinkV2('auth/signout');
    const token = getToken();
    devLog(url);
    devLog(token);
  
    getAxios().post(url, {}, {
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    }).then(response => {
          resetUser();
          this.setState({user: null});
          this.props.history.push('/');
      })
      .catch(error => {
        Bugsnag.notify(error);
        createAlert(this, 'danger',(error.response && error.response.data.error) ? 'Error: ' : '', (error.response && error.response.data.error) ? error.response.data.error : error.message);
      });
  }

  render() {

    const { user, location, isMobileView, placeholder } = this.state;
    const { match, hideSearch, smallSearch, backLink, smallNav, hideHostBtn, hostBtn, smallLogo, pageTitle, fullSearch, authReq, topSpace, progress, space, handleFavourite, handleShare } = this.props;

    if (authReq && getUser() == null) {
      return (<Redirect to='/' />);
    }
    return (
      <nav ref={this.navRef} id="nav" className={"nav-xl " + ((smallLogo) ? "bg-white b-border ": " ") + ((hideSearch && !progress) ? "bg-white" +
        " shadow-nav" : "")}>

        <div className="loading-bar" />

        <div className={(smallNav) ? "container-lg pr-0 pl-0" : "container-fluid"}>

          <div className={(smallNav) ? "row m-0" : "row"}>

            {this.getLogo(this.state,this.props)}

            {(!pageTitle && (
              <div id="search-col" className={(hideSearch ? "col-md-4-min" : "col-md-4") + " justify-content-center pl-mobile-1 " + ((space) ? "col-10" : "col-11")}>

                {
                  (fullSearch && window.innerWidth > 440) ? (
                    <center>
                      <div id="search-nav" className="input-shadow search-done-nav show-inline">
                        <div id="search-bar" className="search-bar">
                          <div id="search-holder" className="hide" />
                          <div id="location-btn" className="location-btn" onClick={() => this.searchTapped('location')}>
                            {(location && location !== 'nearby') ? (
                              <span className="active">{printLocal(location)}</span>
                            ) : (
                              "Nearby"
                            )}
                          </div>
                          <div id="type-btn" className="type-btn" onClick={() => this.searchTapped('type')}>
                            {(match.params.type) ? (
                              <span className="active">{printLocal(match.params.type)}</span>
                            ) : (
                              "Choose type"
                            )}
                          </div>
                          <button id="srch-btn" className="btn btn-outline-primary home-search-btn" type="button" onClick={this.searchTapped}>
                            <FontAwesomeIcon icon={faSearch} style={{width: '.85em', height: '.85em', color: '#FFFFFF', marginBottom: '1px'}} />
                          </button>
                        </div>
                      </div>
                    </center>
                  ) : ((hideSearch) ? (
                    <div id="search-nav" className="input-group search-group search-nav fz-17 show-flex"></div>
                  ) : (
                    <div id="search-nav" className="input-group search-group search-nav fz-17 show-flex">
                      <div id="search-holder" className="form-control input-shadow home-search" onClick={this.searchTapped}>
                        {(fullSearch && location) ? (location !== 'nearby') ? (<span className="active">{printLocal(location)}</span>)
                            : "Nearby" : (fullSearch && match.params.type) ? <span className="active">{printLocal(match.params.type)}</span> : (smallSearch && window.innerWidth < 400) ? "Search..." : "What are you looking for?"}
                      </div>
                      <button id="srch-btn" className="btn btn-outline-primary home-search-btn" type="button" onClick={this.searchTapped}>
                        <FontAwesomeIcon icon={faSearch} style={{width: '.85em', height: '.85em', color: '#FFFFFF', marginBottom: '1px'}} />
                      </button>
                      <div id="location-btn" />
                      <div id="type-btn" />
                      <div id="search-bar" className="search-bar hide" />
                    </div>
                  ))
                }

                {/* <div id="search-opt-nav" className="home-search-opt white-text show">
                <span className="tapSpan">Book now</span>
                <span id="search-opt-tab" className="tabBB bg-white"></span>
              </div> */}

              </div>
            ))}

            {(isMobileView && space && (
              <div className="actions">
                {(space.favourite && (
                  <>
                  <button className="space-btn align-end mr-2 ml-0 pl-0" onClick={handleFavourite}>
                    {(space.favourite === 'yes') ? (
                      <FontAwesomeIcon icon={faSolidHeart} style={{width: 18, height: 18, marginTop: 3, marginRight: 5,}} color='#FF0032' />
                    ) : (
                      <FontAwesomeIcon icon={faHeart} style={{width: 18, height: 18, marginTop: 3, marginRight: 5,}} />
                    )}
                  </button>
                  <button className="space-btn align-end ml-0 pl-0" onClick={() => handleShare('share')}>
                    <FontAwesomeIcon icon={faShareSquare} style={{width: 18, height: 18, marginTop: 3, marginRight: 5,}} />{" "}
                  </button>
                  </>
                ))}
              </div>
            ))}

            {/*<div className="col-10 sm-title">*/}
            {/*  {(pageTitle && (<h1>{pageTitle}</h1>))}*/}
            {/*</div>*/}
            
            {this.getMenuSideButtons(this.state, this.props)}

            {/* <div className="col-12">
              <div id="search-opt-nav-full" className="home-search-opt">Spaces to reserve</div>
            </div> */}

            <div id="search-header" className={"col-12 search-col opacity-1 " + ((hideSearch) ? " " : "show ") + ((topSpace) ? "mt-5" : "")}>
              <div id="search-full" className="input-group search-group search-heading fz-17 search-cont mt-3">
                <div ref={this.locationSearchRef} id="location-search" className="search-location-div" onClick={this.searchLocationTapped}>
                  <p className="m-0 typet">Location</p>
                  <input
                    id="search-input" name="search-input" ref={(input) => { this.locationInput = input}}
                    type="text" className="form-control heading-search search-location"
                    placeholder={placeholder}
                    onFocus={this.handleSearchLocation}
                    onChange={this.searchCities}
                    value={this.state.locationValue} />
                </div>
                <div ref={this.typeSearchRef} id="type-search" className="search-type" onClick={this.searchTypeTapped}>
                  <p className="m-0 typet">Space</p>
                  <p id="type-input" className={"m-0 typep " + ((this.state.type) ? "active" : "")}>
                  {(this.state.type) ? printLocal(this.state.type) : "Choose type"}
                  </p>
                </div>
                <button className="btn btn-outline-primary home-search-btn large-search-btn" type="button" id="button-addon2" onClick={this.handleSearch}>
                  <FontAwesomeIcon icon={faSearch} style={{width: '16px', height: '16px', color: '#FFFFFF', marginBottom: '1px'}} />
                </button>

                <div ref={this.locationSearchBoxRef} id="dropdown-search" className="dropdown-menu dropdwn dropdown-search">
                  <div className="dropdown-cont">
                    {this.state.locationValue === '' && (<div className="city-suggestions">Suggestions</div>)}
                    {this.state.locations.map(item =>
                      <div className="dropdown-item" onClick={() => this.handleLocationChosen(item)}>
                        <img className="location-image" src={markerIcon} />
                        {(item.n_name != '') ? (
                          <span className="location-name">{item.n_name} {/*'-'*/} {/*item.c_name*/}</span>
                        ) : (
                          <span className="location-name">{item.c_name} - {item.cn_name}</span>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div ref={this.typeSearchBoxRef} id="dropdown-types" className="dropdown-menu dropdwn dropdown-types">
                  {/*<div className="dropdown-head">Spaces</div>*/}
                  <div className="dropdown-item" onClick={() => this.handleTypeChosen('office-spaces')}>Office Spaces</div>
                  <div className="dropdown-item" onClick={() => this.handleTypeChosen('banquets-and-event-spaces')}>Banquet Halls & Event Spaces</div>
                  <div className="dropdown-item" onClick={() => this.handleTypeChosen('co-working-spaces')}>Co-working Spaces</div>
                  <div className="dropdown-item" onClick={() => this.handleTypeChosen('meeting-rooms')}>Meeting Rooms</div>
                  {/*<div className="dropdown-head">Services</div>*/}
                  {/*<div className="dropdown-item" onClick={() => this.handleTypeChosen('business-setup')}>Business Setup</div>*/}
                </div>

              </div>
            </div>

            <div id="sm-menu" className="col-12">
              <ul className="sm-menu">
                <li><Link className="dropdown-item" to="/">Home</Link></li>
                {(user) ? (
                  <>
                    <li><Link className="dropdown-item" to="/reservations">Reservations</Link></li>
                    <li><Link className="dropdown-item" to="/favourite-spaces">Favourites</Link></li>
                    <li><Link className="dropdown-item" to="/profile">Account</Link></li>
                  </>
                ) : (
                  <>
                    <li><Link className="dropdown-item" to={{pathname: '/signup', state: { prevLocation: this.props.location }}}>Sign up</Link></li>
                    <li><Link className="dropdown-item" to={{pathname: '/login', state: { prevLocation: this.props.location }}}>Log in</Link></li>
                  </>
                )}
                  <li><Link className="dropdown-item" to={'/host-login'}>Host Login</Link></li>
                <li><Link className="dropdown-item" to={hostBtn ? '/host-application' : '/list-my-space'}>List My
                  Spaces</Link></li>
                <li><Link className="dropdown-item" to="/business-setup">Set up your business</Link></li>
                <li><Link className="dropdown-item" to="/serviced-offices">Serviced Offices</Link></li>
                <li><Link className="dropdown-item" to="/business-centers">Business centers</Link></li>
                <li><Link className="dropdown-item" to="/contact-us">Contact us</Link></li>
                {(user && (
                  <li><a className="dropdown-item link" onClick={this.handleSignOut}>Sign out</a></li>
                ))}
              </ul>
            </div>

          </div>

        </div>

        {/* {(progress && progress < 100 && (
          <div className="setup-loading-bar">
            <div className="setup-loading" style={{width: progress+'%'}} />
          </div>
        ))} */}

      </nav>
    );
  }
  getMenuSideButtons = (state, props)=> {
    const { user, isMobileView, placeholder } = this.state;
    const { match, hideSearch, smallSearch, backLink, smallNav, hideHostBtn, hostBtn, smallLogo, pageTitle, fullSearch, authReq, topSpace, progress, space, handleFavourite, handleShare } = this.props;
  
    return (
      <div id="menu" className={(pageTitle) ? "col-2 p-0" : (hideSearch ? "col-4-min" : "col-4") + " p-0"}>
      <div className="nav ml-auto mt-1">
        {hostBtn ? (
          <>
            {/*<div className="text-nav">Already a partner?</div>*/}
          
            <Link className="link-btn" to={{pathname: '/host-login', state: { prevLocation: this.props.location }}}>
              <button id="host-btn" className={"btn btn-outline host-btn-nav " + ((hideSearch) ? " " : "white-text")}>
                Log in
              </button>
            </Link>
          </>
        ) : (
          <>
            {!(hideHostBtn || user?.type === 'host') && (
              <>
                <Link to='/list-my-space' className="link-btn">
                  <button id="host-btn" className={"btn btn-outline btn-nav " + ((hideSearch) ? " " : "white-text")}>
                    List My Spaces
                  </button>
                </Link>
                <Link to='/host-login' className="link-btn">
                  <button id="host-login-btn" className={"btn btn-outline btn-nav btn-host-login"}>
                    Host Login
                  </button>
                </Link>
              </>
            )}
            {
              (user && user.type==='host' && !this.props.location.pathname.includes(HostDashboard.route)) &&
              <Link to={HostDashboard.route} className="link-btn">
                <button id="dashboard-btn" className={"btn btn-outline btn-nav " + ((hideSearch) ? " " : "white-text")}>
                  Dashboard
                </button>
              </Link>
            }
            <div className="dropdown">
              <button id="dropdownMenuButton" className="btn btn-secondary btn-drop" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <FontAwesomeIcon icon={faUserCircle} style={{width: '1.75em', height: '1.75em', color: (user) ? '#4b94ca' : '#646464'}} />
                <FontAwesomeIcon icon={faAngleDown} style={{width: '1.15em', height: '1.15em', color: '#646464', marginBottom: '.25rem'}} />
              </button>
              <div id="dropdown-menu" className="dropdown-menu dropdwn">
                {
                  (user) ? (
                    <>
                      {
                        (user?.type === 'host')?
                          <>
                            {(!this.props.location.pathname.includes(HostDashboard.route)) &&
                            <Link className="dropdown-item" to={HostDashboard.route}>Dashboard</Link>}
                            {(!this.props.location.pathname.includes(ContractDetails.route)) &&
                            <Link className="dropdown-item" to={ContractDetails.route}>Subscription Contract</Link>}
                          </>
                          :
                          <>
                            <Link className="dropdown-item" to="/reservations">Reservations</Link>
                            <Link className="dropdown-item" to="/favourite-spaces">Favourites</Link>
                            <Link className="dropdown-item" to="/profile">Account</Link>
                          </>
                      }
                  
                    </>
                  ) : (
                    <>
                      <Link className="dropdown-item" to={{pathname: '/signup', state: { prevLocation: this.props.location }}}>Sign up</Link>
                      <Link className="dropdown-item" to={{pathname: '/login', state: { prevLocation: this.props.location }}}>Log in</Link>
                    </>
                  )
                }
              
              
                { (user && user.type==='host') ||
                <>
                  <div className="dropdown-divider"></div>
                  {/* <Link className="dropdown-item" to={hostBtn ? '/host-application' : '/list-my-space'}>List My
                    Spaces</Link> */}
                  <Link className="dropdown-item" to="/business-setup">Set up your business</Link>
                  <Link className="dropdown-item" to="/serviced-offices">Serviced Offices</Link>
                  <Link className="dropdown-item" to="/banquet-event-halls">Banquet & Event Halls</Link>
                  <Link className="dropdown-item" to="/business-centers">Business centers</Link>
                </>
                }
                {
                  (this.props.location.pathname.includes('/contact-us')) ||
                  <Link className="dropdown-item" to="/contact-us">Contact us</Link>
                }
              
              
                {
                  (user) ? (
                    <>
                      <div className="dropdown-divider"></div>
                      <div className="dropdown-item link" onClick={this.handleSignOut}>Sign out</div>
                    </>
                  ) : (
                    <></>
                  )
                }
              </div>
            </div>
          </>
        )}
      </div>
    </div>
    )
  }
  getLogo = (state,props)=> {
    const { isMobileView} = state;
    const { hideSearch, backLink,  smallLogo, pageTitle } = props;
  
    return (
      <>
        <div id="full-logo" className={(pageTitle) ? "col-10 p-0" : "col-4 p-0"}>
      {(smallLogo) ? (
        <>
          <Link to="/">
            <div id="logo" className="tiny-logo-img" />
          </Link>
          {(pageTitle && (<h1>{pageTitle}</h1>))}
        </>
      ) : (
        <Link to="/">
          <div id="logo" className={"logo-img " + ((hideSearch) ? "logo-dark" : "")} />
        </Link>
      )}
    </div>
        <div id="sm-logo" className={(smallLogo) ? ((isMobileView) ? "w100f" : "row w100") : ""}>
      {(backLink) ? (
        <Link to={backLink}>
          <FontAwesomeIcon icon={faArrowLeft} style={{color: '#000000', fontSize: 20, marginLeft: 12, marginTop: 14}} />
        </Link>
      ) : (
        <div className="sm-logo-a" onClick={this.handleMenuTapped}>
          <div className="sm-logo-img" />
        </div>
      )}
      {(pageTitle && (
        <h1 className="sm-title">{pageTitle}</h1>
      ))}
    </div>
      </>
    )
  }
}

export default withRouter(NavBar);
