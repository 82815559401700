import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'src/styles/AboutUs-pg-style.scss';
import first_img_sec from '../img/images/coworkingb.png';
import fourth_sec_first_img from '../img/icons/about us page_value.png';
import fourth_sec_icons_1 from '../img/icons/about us page_Simplicity.png';
import fourth_sec_icons_2 from '../img/icons/about us page_Professional Integrity-.png';
import fourth_sec_icons_3 from '../img/icons/about us page_Innovation.png';
import fourth_sec_icons_4 from '../img/icons/about us page_Centricity.png';
import fourth_sec_icons_5 from '../img/icons/about us page_Efficiency.png';
import Helmet from "react-helmet";
class AboutUS extends Component {


  render() {
    return (
      <>
        <Helmet>
            <meta charSet="utf-8"/>
            <title>Online Platform For Work, Meeting, And Event Spaces</title>
            <meta name={'description'}
                  content={'fluidmeet Is Committed to Provide World-Class Serviced Offices, Meeting Rooms, And Event Spaces Located in Premium Business Centers And Event Venues'}/>
            <meta name="keywords"
                  content="Serviced offices, event spaces, event venues, business centers, meeting rooms, work spaces"/>
            <link rel={"canonical"} href="https://www.fluidmeet.com/about-us" />
        </Helmet>
      <section>
        <section>
            <center>
                <div className='AboutUS-pg-div-first-sec'>
                <img  className="background-photo-AboutUs-page-first-img" src={first_img_sec} />
                    <h1 class="AboutUs-page-first-image-txt">
                        <center>ABOUT US</center>
                    </h1>
                </div>
            </center>
    </section>
<center>        
    <section className='second-sec-size-AboutUS-pg'>
            <br></br>
              <h2 className="subtitle-AboutUS-pg-all-secs">WHO WE ARE</h2>
              <center>
                    <p className="AboutUS-pg-body-text">
                    Fluidmeet is an online marketplace and real-time platform that provides a simple and easy
                    booking experience. For instance, we offer a diverse assortment of work and event spaces
                    tailor-made for your upcoming social and corporate events. Here at fluidmeet, we aim to bridge the
                    gap between our clients within the hotel and commercial real estate industry in a cost-effective
                    and time-efficient manner.
                    </p>
              </center>
            <br></br>
    </section>
</center>

<section className='third-sec-background-hero-sec-img-AboutUS-pg'>
<div className='third-sec-background-AboutUS-pg-01'>
<br></br>
<center><h2 className="subtitle-AboutUS-pg-all-secs">HOW IT ALL STARTED</h2></center>

              <center><p className="AboutUS-pg-body-text-third-sec-01">The co-founders of fluidmeet Suleiman Salloum and Jareer Oweimrin were management
            consultants in a reputable firm working across the GCC Region.</p></center>
            <center><p className="AboutUS-pg-body-text-third-sec-02">As seniors in their firm, Suli and Jareer were responsible for meeting clients, delivering workshops,
            and training juniors in the organization. All of which required workspaces and meeting rooms.
            Consequently, this brought along multiple challenges at hand.</p></center>
              
                <div className='third-sec-background-AboutUS-pg-02'>
              <center><p className="AboutUS-pg-body-text-third-sec-03">First, Suli and Jareer had to identify the space they need, whether a <em><Link style={{color: '#174E86'}} to='/serviced-offices'>serviced office</Link></em>, coworking
                space, meeting room, or event space, then research what venues or business centers offer the
                ideal work, meeting, or event space. Next, they would reach out to these venues or business
                centers and inquire about their work or event spaces and amenities offered. Moreover, Suli and
                Jareer would wait on a proposal that included the pricing and detailing of their requirements. After
                receiving all these proposals, they would compare them and then decide. Finally, they would pass
                by the venue to inspect the workspace, negotiate the terms, and settle the due payment.</p></center>
                <center><p className="AboutUS-pg-body-text-third-sec-04">They felt this process was unnecessarily time-consuming and tiresome and thought to
                themselves: <b><i>there must be a better way! Hence, fluidmeet was born.</i></b></p>
              </center><br></br>
</div>

</div><div className='third-sec-background-AboutUS-pg-02'></div>
</section>
<section className='fourth-sec-background-AboutUS-pg'>
<div className="container-fluid contain mt-0 pr-0 pl-0">
            <div className="">
              <section className="">
                <div className='fourth-sec-header-AboutUs-pg'>
                    <img className="fourth-sec-first-img-AboutUs-pg" src={fourth_sec_first_img}/>
                    <div>&nbsp;&nbsp;</div>
                    <h2 className="mt-4 mb-4 subtitle-fourth-sec-AboutUS-pg-all-secs">OUR CORE VALUES</h2>
                    <hr className='hr-style-AboutUS-pg'></hr>
                </div>
            <div className='sec-4-pos-AboutUs-pg'>
                <div className='fourth-sec-icons-text-alignment-AboutUs-pg'>
                    <img className="fourth-sec-icons-AboutUs-pg" src={fourth_sec_icons_1}/>
                    <div>&nbsp;&nbsp;</div>
                    <h3 className='icons-subtitle-fourth-sec-AboutUS-pg-01'><b>Simplicity:</b></h3>
                </div>
                <h4 className='icons-subtitle-inner-text-fourth-sec-AboutUS-pg icons-subtitle-inner-text-fourth-sec-AboutUS-pg-01-02'>Providing a simple and smooth booking process.</h4>
                <br></br>
                <div className='fourth-sec-icons-text-alignment-AboutUs-pg'>
                    <img className="fourth-sec-icons-AboutUs-pg" src={fourth_sec_icons_2}/>
                    <div>&nbsp;&nbsp;</div>
                    <h3 className='icons-subtitle-fourth-sec-AboutUS-pg-01'><b>Professional Integrity:</b></h3>
                </div>
                <h4 className='icons-subtitle-inner-text-fourth-sec-AboutUS-pg icons-subtitle-inner-text-fourth-sec-AboutUS-pg-01-02'>Establishing a professional and trustworthy relationship with clients & partners.</h4>
                
                <br></br>
                <div className='fourth-sec-icons-text-alignment-AboutUs-pg'>
                    <img className="fourth-sec-icons-AboutUs-pg" src={fourth_sec_icons_3}/>
                    <div>&nbsp;&nbsp;</div>
                    <h3 className='icons-subtitle-fourth-sec-AboutUS-pg-01'><b>Innovation:</b></h3>
                    <div>&nbsp;&nbsp;</div>
                    <h4 className='icons-subtitle-inner-text-fourth-sec-AboutUS-pg icons-subtitle-inner-text-fourth-sec-AboutUS-pg-03'>Cultivating a culture of creativity and innovation.</h4>
                </div>
                
                <br></br>
                <div className='fourth-sec-icons-text-alignment-AboutUs-pg'>
                    <img className="fourth-sec-icons-AboutUs-pg" src={fourth_sec_icons_4}/>
                    <div>&nbsp;&nbsp;</div>
                    <h3 className='icons-subtitle-fourth-sec-AboutUS-pg-01'><b>Centricity:</b></h3>
                    <div>&nbsp;&nbsp;</div>
                    <h4 className='icons-subtitle-inner-text-fourth-sec-AboutUS-pg-04-05 icons-subtitle-inner-text-fourth-sec-AboutUS-pg-04-05'>Developing processes centered around the clients’ needs.</h4>
                </div>

                <br></br>
                <div className='fourth-sec-icons-text-alignment-AboutUs-pg'>
                    <img className="fourth-sec-icons-AboutUs-pg" src={fourth_sec_icons_5}/>
                    <div>&nbsp;&nbsp;</div>
                    <h3 className='icons-subtitle-fourth-sec-AboutUS-pg-01'><b>Efficiency:</b></h3>
                    <div>&nbsp;&nbsp;</div>
                    <h4 className='icons-subtitle-inner-text-fourth-sec-AboutUS-pg-04-05 icons-subtitle-inner-text-fourth-sec-AboutUS-pg-04-05'>Equipping clients with a time-efficient & hassle-free research and booking procedure.</h4>
                </div>
            </div>
                
                </section>
                </div>
                </div>
</section>
<section className='last-sec-background-AboutUS-pg-02'>

              <center>
                  <p className="AboutUS-pg-last-sec-body-txt">
                        <i>
                            Over the past six years of operation, we have embodied
                            these principles in our pursuit of building strong client
                            relationships and attaining a gratifying worthwhile
                            customer experience!
                        </i>
                  </p>
              </center>

</section></section>
      </>
    );
  }
}

export default withRouter(AboutUS);
