import React, {useEffect} from "react";
import {useUpdateReducer} from "src/hooks/useUpdateReducer";

export type FileImageProps = { file: File } & React.ImgHTMLAttributes<HTMLImageElement>;

export function FileImage(props: FileImageProps) {

	// const [imgProps, setImgProps, updateImgProps] = useUpdateReducer<React.ImgHTMLAttributes<HTMLImageElement>>(props);
	const [imgProps, setImgProps, updateImgProps] = useUpdateReducer<any>(props);

	useEffect(() => {


		if (props.file) {
			var reader = new FileReader();

			reader.readAsDataURL(props.file);
			reader.onload = ((e) => {
				updateImgProps({src: e.target?.result});
			})

		}

	}, [])
	return (
		<img {...imgProps}/>
	);
}
