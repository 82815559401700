import {devLog, getApiLinkV2, getToken, getUser, setUserData, setUserImage} from "src/Helpers";
import axios from "axios";
import {getAxios} from "src/Helpers";
import Bugsnag from "@bugsnag/js";
import {Message} from "src/APIs/HostAPI";

export interface UserData {
    first_name?: string | null;
    last_name?: string | null;
    phone?: string | null;
    email?: string | null;
    country?: string | null;
    city?: string | null;
    company?: string | null;
    position?: string | null;
}

export default class UserAPI {
    static uploadProfileImage = async (selectedFile: File) => {

        const token = getToken();
        const url = getApiLinkV2('user/image');
        const formData = new FormData();
        formData.append("image", selectedFile!);
        try {
            let response = await getAxios().post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + token,
                },
            })
            devLog(response);
            setUserImage(response.data.photo);
            return new Message('success', '', 'Your profile picture has been updated.', '', response);
        } catch (error: any) {
            Bugsnag.notify(error);
            devLog(error);
            return new Message('danger', (error.response && error.response.data.message) ? 'Error: ' : '', (error.response && error.response.data.message) ? error.response.data.message : error.message);
        }
    }

    static updateProfile = async (userData: UserData) => {

        try {
            const id = getUser()!.id;
            const token = getToken();
            const url = getApiLinkV2('user/' + id);
            let response = await getAxios().post(url, userData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            })
            setUserData(userData);
            return new Message('success', '', 'Your profile details have been updated.', '', response);

        } catch (error: any) {
            Bugsnag.notify(error);
            return new Message('danger', (error.response && error.response.data.error) ? 'Error: ' : '', (error.response && error.response.data.error) ? error.response.data.error : error.message, '', error);
        }
    }
    static getUser = async (id) => {

        try {
            let response = await getAxios().get(getApiLinkV2(`user/${id}`), {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            devLog(response)
            return new Message('success', '', 'Your profile details have been updated.', '', response);

        } catch (error: any) {
            Bugsnag.notify(error);
            return new Message('danger', (error.response && error.response.data.error) ? 'Error: ' : '', (error.response && error.response.data.error) ? error.response.data.error : error.message, '', error);
        }
    }

    static updatePass = async (oldPassword: string, newPassword: string) => {
        const url = getApiLinkV2('user/password');
        const token = getToken();

        try {
            let response = await getAxios().post(url, {
                oldPass: oldPassword,
                password: newPassword,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            })
            return {
                type: 'success',
                title: '',
                message: 'Your password has been updated.',
                description: response.statusText,
                attachment: response
            } as Message;
        } catch (error: any) {
            Bugsnag.notify(error);
            return {
                type: 'danger',
                title: (error.response && error.response.data.error) ? 'Error: ' : '',
                message: (error.response && error.response.data.error) ? error.response.data.error : error.message,
                description: error.message,
                attachment: error,
            } as Message;
        }

    }
}
