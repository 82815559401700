import React from "react";

export type AccordionControllerState = {
	opened: boolean
}

export class AccordionController extends React.Component<any, AccordionControllerState> {

	constructor(props) {
		super(props);

		this.state = {
			opened: Boolean(React.Children.toArray(props.children)?.[0]?.props?.defaultExpanded)
		}
	}

	open = () => this.setState({opened: true})
	close = () => this.setState({opened: false})
	toggle = () => {
		this.setState(({opened}) => ({opened: !opened}))
	}
	onChange: (event: React.ChangeEvent<{}>, expanded: boolean) => void = (_, expanded) => {
		this.setState({opened: expanded})
	}

	render() {
		let {children} = this.props
		let {opened} = this.state

		return (
			<>
				{React.Children.map(children,
					(child) => {

						return React.cloneElement(child, {expanded: opened, onChange: this.onChange})
					}
				)}
			</>
		)
	}
}

