import {ReservStyles} from "src/styles/ReservStyles";


export default class LeadUtilities {
	static LEAD_STATUSES = [
		{label: 'Did not call lead', value: 10},
		{label: 'Called - no response', value: 2},
		{label: 'Called – client visit arranged', value: 11},
		{label: 'Called – client not interested', value: 9},
		{label: 'Client visited - awaiting feedback', value: 12},
		{label: 'Client Interested – preparing contract', value: 13},
		{label: 'Contract sent - awaiting signature', value: 6},
		{label: 'Lead closed elsewhere', value: 14},
	];

	static LEAD_PAYMENT_STATUSES = [
		{label: 'Not Paid', value: 1},
		{label: 'Paid to Fluidmeet', value: 2},
		{label: 'Paid to Venue', value: 3},
		{label: 'Refunded', value: 4},
	];
	static LEAD_ACCEPT_STATUS = 1;
	static LEAD_REJECT_STATUS = 2;
	static LEAD_PENDING_STATUS = 0;
	static LEAD_VISIT_SCHEDULED_STATUS = 11;
	static LEAD_CONTRACT_SENT_STATUS = 6;
	static LEAD_LOST_OTHER_STATUS = 14;
	static LEAD_NOT_CONTACTED_STATUS = [0, 1, 2, 10];//New & Called No Response & Did not call lead
	static LEAD_DID_NOT_CALL_STATUS = 10;
	static LEAD_USER_VISIT_ARRANGED = 1;
	static LEAD_USER_DISCUSSING_CONTRACT = 2;
	static LEAD_CONTRACT_SIGNED = 7;
	static LEAD_USER_CONTRACT_SIGNED = 3;

	static LEADS_DEFAULT_CURRENCY = 'AED'

	static getLeadTypeText(lead) {
		switch (lead.lead_type) {
			case 'tour' :
				return 'Requested Tour';
			case 'call' :
				return 'Requested Call';
			case 'lead' :
				return 'Requested Call';
			case 'inquiry' :
				return 'Online Inquiry';
			case 'office' :
				return 'Requested Office';
			default:
				return "Unknown";
		}
	}


	static getHostLeadStatus(lead) {

		const accepted = lead.host_accepted + '';

		if (accepted == '1') {

			const status = (!lead.closed && !lead.chosenToOther && !lead.lost && !lead.hostClosed) ? lead.host_status_id : lead.lead_status;

			switch (status + '') {
				case '10':
				case '1':
				case 'Did not call lead':
				case 'News':
					return 'pending call';
				case '11':
				case 'Called – visit scheduled with lead':
					return 'visit scheduled';
				case '2':
				case '12':
				case 'Called no response':
				case 'Lead viewed space – awaiting feedback':
					return 'waiting client response';
				case '13':
				case 'Lead interested – preparing contract':
					return 'preparing contract';
				case '6':
				case '3':
				case 'Contract sent – awaiting signature':
				case 'Contract sent':
					return 'contract sent';
				case '7':
				case 'Contract signed':
					return 'contract signed';
				case '9':
				case '14':
				case 'Lead not interested':
				case 'Lead closed elsewhere':
					return 'client cancelled';
				default:
					return lead.lead_status;
			}
		}
		if (accepted == '2') {
			return 'rejected';
		}
		return 'pending';
	}

	static getHostLeadExtraChecks(item) {
		let closed = false;
		let lost = false;
		let chosenToOther = false;
		let hostClosed = false;

		if (item['date_closed']) {
			closed = true;
			if (item['lost'] == 1)
				lost = true;
			if (item['is_chosen'] == 0)
				chosenToOther = true;
		} else if (item['host_closed_date'] != null) {
			hostClosed = true;
		}
		return {closed, lost, chosenToOther, hostClosed,};
	}

	static printHostLeadStatus(lead) {

		const accepted = lead.host_accepted + '';

		if (accepted == '1') {

			const status = (!lead.closed && !lead.chosenToOther && !lead.lost && !lead.hostClosed) ? lead.host_status_id : lead.lead_status;

			switch (status + '') {
				case '10':
				case '1':
				case 'Did not call lead':
				case 'New':
					return (<span
						style={{...ReservStyles.reservstatus, ...ReservStyles.statusred}}>Did not call lead</span>);
				case '11':
				case 'Called – visit scheduled with lead':
					return (<span
						style={{...ReservStyles.reservstatus, ...ReservStyles.statusblack}}>Visit Scheduled</span>);
				case '2':
				case '12':
				case 'Called no response':
				case 'Lead viewed space – awaiting feedback':
					return (<span style={{...ReservStyles.reservstatus, ...ReservStyles.statusblack}}>Waiting Client Response</span>);
				case '13':
				case 'Lead interested – preparing contract':
					return (<span
						style={{...ReservStyles.reservstatus, ...ReservStyles.statusblack}}>Preparing Contract</span>);
				case '6':
				case '3':
				case 'Contract sent – awaiting signature':
				case 'Contract sent':
					return (
						<span style={{...ReservStyles.reservstatus, ...ReservStyles.statusblack}}>Contract Sent</span>);
				case '7':
				case 'Contract signed':
					return (<span
						style={{...ReservStyles.reservstatus, ...ReservStyles.statusgreen}}>Contract Signed</span>);
				case '9':
				case '14':
				case 'Lead not interested':
				case 'Lead closed elsewhere':
					return (<span
						style={{...ReservStyles.reservstatus, ...ReservStyles.statusgray}}>Client Cancelled</span>);
				default:
					return (<span
						style={{...ReservStyles.reservstatus, ...ReservStyles.statusblack}}>{lead.lead_status}</span>);
			}
		}
		if (accepted == '2') {
			return (<span style={{...ReservStyles.reservstatus, ...ReservStyles.statusred}}>Lead Declined</span>);
		}
		return (<span style={{...ReservStyles.reservstatus, ...ReservStyles.statusred}}>Pending</span>);
	}

	static getHostLeadCalledLead(lead) {

		if (lead.host_accepted + '' == '1') {

			const status = (!lead.closed && !lead.chosenToOther && !lead.lost && !lead.hostClosed) ? lead.host_status_id : lead.lead_status;

			switch (status + '') {
				case '10':
				case '1':
				case 'Did not call lead':
				case 'News':
					return false;
				case 'Lead viewed space – awaiting feedback':
				case '2':
				case '12':
				case 'Called no response':
				case '11':
				case 'Called – visit scheduled with lead':
				case '13':
				case 'Lead interested – preparing contract':
				case '6':
				case '3':
				case 'Contract sent – awaiting signature':
				case 'Contract sent':
				case '7':
				case 'Contract signed':
				case '9':
				case '14':
				case 'Lead not interested':
				case 'Lead closed elsewhere':
					return true;
				default:
					return false;
			}
		}
		return false;
	}


}
