import React, {Component} from "react";
import axios from 'axios';
import {Link} from "react-router-dom";
import {createAlert, formValidation, getApiLinkV2, setUser} from "../../Helpers";
import User from "./User";
import FloatingAlert from "../organisms/FloatingAlert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import Bugsnag from "@bugsnag/js";
import ReactGA from "react-ga-neo";
import {getAxios} from "src/Helpers";
import Helmet from "react-helmet";
class SignIn extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      reqAlert: null,
      emailError: null,
      passError: null,
      email: '',
      password: '',
    }

    this.handleSignIn = this.handleSignIn.bind(this);
  }

  handleChange(e, type) {
    e.preventDefault();

    if (type === 'email') {

      this.setState({ email: e.target.value });

    } else if (type === 'password') {

      this.setState({ password: e.target.value });
    }
  }

  handleSignIn(e) {
    e.preventDefault();

    const { email, password } = this.state;

    const emailError = formValidation('email', email);
    const passError = formValidation('password', password);

    this.setState({ reqAlert: null, emailError: emailError, passError: passError });

    if (emailError || passError) return;

    this.setState({ reqLoading: true });

    const url = getApiLinkV2('auth/signin');

    getAxios().post(url, {
      email: email,
      password: password
    }, {
      headers: { 'Content-Type': 'application/json' },
    }).then(response => {
        // if (response.data.status === 'host') {
        //   // document.getElementById('host-login').submit();
        //   // return;
        // }
        const user = response.data.userData;
        setUser(user, response.data.token);
        Bugsnag.setUser(user.user_id, user.email, user.first_name+" "+user.last_name);
        ReactGA.set({ userId: user.user_id });
      if (window.fcWidget) {
        window.fcWidget.setExternalId("user_"+user.user_id);
        window.fcWidget.user.setFirstName(user.first_name);
        window.fcWidget.user.setFirstName(user.last_name);
        window.fcWidget.user.setEmail(user.email);
      }
        this.props.signInCallback(new User(response.data.userData));
      
    }).catch(error => {
        if (error.response && error.response.status === 406) {
          if (error.response.data.status === 'wrong_pass') {
            createAlert(this, 'danger','The email address or password you entered is incorrect.', '');
          }
          if (error.response.data.status === 'wrong_login_page') {
            createAlert(this, 'danger','wrong login page, <a href="/host-login">click here</a> to login', '', false);
          }
        } else {
          Bugsnag.notify(new Error('Error Catched in axios ' + JSON.stringify(error)));
        }
        createAlert(this, 'danger',(error.response && error.response.data.error) ? 'Error: ' : '', (error.response && error.response.data.error) ? error.response.data.error : error.message);
      // devLog(error)
      }).then(() => {
        this.setState({ reqLoading: false });
    });
  }

  render() {

    const { email, password, reqLoading, reqAlert } = this.state;

    return (
      <>
          <Helmet>
            <meta charSet="utf-8"/>
            <meta name={'description'}
                  content={'Find Your Ideal Space, Whether You Want to Rent Serviced Offices, Coworking Spaces, Banquet & Event Halls and Meeting Rooms, Commission-Free Booking'}/>
            <meta name="keywords"
                  content="Banquet & Event Halls, Serviced Offices, Co-working Spaces, Meeting Rooms, Rent, Book"/>
            <link rel={"canonical"} href="https://www.fluidmeet.com/login" />
          </Helmet>
        <form autoComplete="on" onSubmit={this.handleSignIn}>

          <input type="email" id="email" name="email" className="inputEmail form-control" placeholder="Email address"
                 required value={email} onChange={(e) => this.handleChange(e, 'email')} />
          {(this.state.emailError) ? (<div className="form-error">{this.state.emailError}</div>) : (<></>)}
          <input type="password" id="password" name="password" className="inputPassword form-control" placeholder="Password"
                 required value={password} onChange={(e) => this.handleChange(e, 'password')} />
          {(this.state.passError) ? (<div className="form-error">{this.state.passError}</div>) : (<></>)}

          <button type="submit" className={"btn btn-lg btn-primary btn-block fl_btn "+((reqLoading) ? 'ld-btn' : '')}>
            {(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch} spin={true} /> loading</>) : 'Login'}
          </button>

          <h2 className="fl_a font-weight-normal">
            <Link to={"/reset"} className="a">Forgot your password?</Link>
          </h2>
          <h2 className="fl_a mt-3 mb-0 font-weight-normal">
            Don’t have an account?{" "}
            <Link to={"/signup"} className="a2">Sign up</Link>
          </h2>
          {/* <h2 className="fl_a mt-3 mb-0 font-weight-normal">
            <Link to={"/list-my-space"} className="a">Venue host sign up ? click here</Link>
          </h2> */}
          <h2 className="fl_a mt-3 mb-0 font-weight-normal">
            <Link to={"/host-login"} className="a">Host Login</Link>
          </h2>
        </form>

        <form id="host-login" name="host-login" method="POST" action="https://host.fluidmeet.com/application/index/login">
          <input type="hidden" id="host-email" name="host-email" value={email} />
          <input type="hidden" id="host-password" name="host-password" value={password} />
        </form>

        {(reqAlert && <FloatingAlert error={reqAlert} onClick={() => this.setState({reqAlert: null})} />)}

      </>
    );
  }
}

export default SignIn;
