import {ReservStyles} from "../../styles/ReservStyles";


export function getHostProposalActive(proposal) {
	// devLog("proposal.payment_status",proposal.payment_status)
	// devLog("proposal.quote_status",proposal.quote_status)
	// devLog("proposal.tot_fluidmeet_price",JSON.stringify(proposal,null,4))
	
	if (proposal.payment_status === 'paid' || proposal.payment_status === 'paid to venue') {
		return true;
	}
	if (proposal.quote_status === 'declined by seeker' || proposal.quote_status === 'declined by venue' || proposal.quote_status === 'declined by fm') {
		return false;
	}
	if (proposal.quote_status === 'expired' || (new Date()) > (new Date(proposal.start_date))) {
		// return false;
		return true;
	}
	if (proposal.quote_status === 'accepted') {
		return true;
	}
	if ((proposal.quote_status === 'submitted' || proposal.quote_status === 'pending') && (proposal.tot_fluidmeet_price)) {
		return true;
	}
	return false;
}

export function getHostProposalStatus(proposal) {
	
	if (proposal.payment_status === 'paid' || proposal.payment_status === 'paid to venue') {
		return 'paid';
	}
	if (proposal.quote_status === 'declined by seeker' || proposal.quote_status === 'declined by venue' || proposal.quote_status === 'declined by fm') {
		return 'rejected';
	}
	if (proposal.quote_status === 'expired' || (new Date()) > (new Date(proposal.start_date))) {
		return 'expired';
	}
	if (proposal.quote_status === 'accepted') {
		return 'accepted';
	}
	if ((proposal.quote_status === 'submitted' || proposal.quote_status === 'pending') && proposal.tot_fluidmeet_price) {
		return 'priced';
	}
	return 'pending';
}

export function printHostProposalStatus(proposal) {
	
	if (proposal.payment_status === 'paid' || proposal.payment_status === 'paid to venue') {
		return (<span style={{...ReservStyles.reservstatus, ...ReservStyles.statusgreen}}>Paid &amp; Confirmed</span>);
	}
	if (proposal.quote_status === 'declined by seeker') {
		return (<span style={{...ReservStyles.reservstatus, ...ReservStyles.statusgray}}>Cancelled</span>);
	}
	if (proposal.quote_status === 'declined by venue' || proposal.quote_status === 'declined by fm') {
		return (<span style={{...ReservStyles.reservstatus, ...ReservStyles.statusred}}>Inquiry Declined</span>);
	}
	if (proposal.quote_status === 'expired') {
		if (proposal.tot_fluidmeet_price && proposal.tot_fluidmeet_price > 0)
			return (<span style={{...ReservStyles.reservstatus, ...ReservStyles.statusgray}}>Expired</span>);
		return (<span style={{...ReservStyles.reservstatus, ...ReservStyles.statusred}}>Expired - Not Priced</span>);
	}
	if (proposal.quote_status === 'accepted') {
		return (<span style={{...ReservStyles.reservstatus, ...ReservStyles.statusblack}}>Client Interested</span>);
	}
	if ((proposal.quote_status === 'submitted' || proposal.quote_status === 'pending') && proposal.tot_fluidmeet_price) {
		return (<span style={{...ReservStyles.reservstatus, ...ReservStyles.statusblack}}>Priced</span>);
	}
	return (<span style={{...ReservStyles.reservstatus, ...ReservStyles.statusred}}>Pending</span>);
}


export function capitalizeFirst(str) {
	if (str) return str.charAt(0).toUpperCase() + str.slice(1);
	else return ''
}


