import React, {Component} from 'react';
import {Link} from "react-router-dom";
import coWorking from '../img/Coworking_spaces.jpg';
import office from '../img/Office_Spaces_and_serviced_offices.jpg';
import meeting from '../img/Meeting_rooms.jpg';
import events from '../img/Banquet_and_event_halls.jpg';

export default class USALaunch extends Component {
  
  componentDidMount() {
    
    //ReactGA.pageview(window.location.pathname + window.location.search);
  }
  
  render() {

    return (
      <>

        <div className="usa-banner">

          <div className="bs-header">
            <h1 className="white-text mt-3">Launching in USA</h1>
            <h5 className="white-text">We are expanding our business to the US</h5>
          </div>
        </div>


        <div className="container-fluid container bs-page">

          <section className="how-works pt-5">
            <div className="row mt-5">

              <div className="col-md-6 col-12">
                <div className="how-div">
                  <span className="cardd-title">Make the most of your space with Fluidmeet</span>
                  <span className="cardd-subtitle">Our easy to use platform enable businesses to book their spaces in business centers with a single click.</span>
                </div>
              </div>

              <div className="col-md-6 col-12">
                <div className="how-div">
                  <span className="cardd-title">Manage leads and track your earnings</span>
                  <span className="cardd-subtitle">Fluidmeet is here to help you manage leads and track your earnings with our easy to use platform.
                  </span>
                </div>
              </div>

            </div>
          </section>


          <div className="section-divider2" />


          <section className="mt-100">

            <div className="row pb-100">

              <div className="col-lg-6 col-12 order-lg-1 order-1">
                <Link to="/search/nearby/office-spaces">
                  <div className="cardd-div display-inline">
                    <img className="cardd-img" src={office} alt="card image" />
                  </div>
                </Link>
              </div>

              <div className="col-lg-6 col-12 order-lg-2 order-3">
                <Link to="/search/nearby/banquets-and-event-spaces">
                  <div className="cardd-div display-inline">
                    <img className="cardd-img" src={events} alt="card image" />
                  </div>
                </Link>
              </div>

              <div className="col-lg-6 col-12 order-lg-3 order-2">
                <div className="cardd-desc mb-lg-5 mb-4">
                  <div className="cardd-cont m-0">
                    <div className="cardd-title">Office spaces</div>
                    <div className="cardd-subtitle">Private offices that come fully furnished and equipped with wifi, DEWA, phone line and other shared services like pantry, printer/scanner, call answering, mail management and more.</div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-12 order-lg-4 order-4">
                <div className="cardd-desc mb-lg-5 mb-4">
                  <div className="cardd-cont m-0">
                    <div className="cardd-title">Banquets & event halls</div>
                    <div className="cardd-subtitle">Any workspace (Banquets, Event halls) that can be used to conduct large-sized events.</div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-12 order-lg-5 order-5">
                <Link to="/search/nearby/co-working-spaces">
                  <div className="cardd-div display-inline mt-5">
                    <img className="cardd-img" src={coWorking} alt="card image" />
                  </div>
                </Link>
              </div>

              <div className="col-lg-6 col-12 order-lg-6 order-7">
                <Link to="/search/nearby/meeting-rooms">
                  <div className="cardd-div display-inline mt-5">
                    <img className="cardd-img" src={meeting} alt="card image" />
                  </div>
                </Link>
              </div>

              <div className="col-lg-6 col-12 order-lg-7 order-6">
                <div className="cardd-desc mb-lg-5 mb-4">
                  <div className="cardd-cont m-0">
                    <div className="cardd-title">Co-working spaces</div>
                    <div className="cardd-subtitle">Shared workspaces that come fully furnished and equipped with wifi, DEWA, phone line and other shared services like pantry, printer/scanner, call answering, mail management and more.</div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-12 order-lg-8 order-8">
                <div className="cardd-desc mb-lg-5 mb-4">
                  <div className="cardd-cont m-0">
                    <div className="cardd-title">Meeting rooms</div>
                    <div className="cardd-subtitle">Any workspace (Meeting rooms, Training rooms, Boardrooms) that can be used to conduct small to mid-sized meetings.</div>
                  </div>
                </div>
              </div>

            </div>

          </section>


          <section className="host">
            <div className="row">
              <div className="about-div w100">
                <div className="cardd-title text-center">Become a Fluidmeet host</div>
                <Link to="/list-my-space">
                  <div className="btn-contain">
                    <button className="btn section-btn lg-btn center">List your space</button>
                  </div>
                </Link>
              </div>
            </div>
          </section>

        </div>
      </>
    );
  }
}
