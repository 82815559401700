import {createSlice} from '@reduxjs/toolkit';

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    defCurrency: 'USD',
  },
  reducers: {
    updateDefCurrency: (state, action) => {
      state.defCurrency = action.payload;
    },
  },
})

export const { updateDefCurrency } = settingsSlice.actions

export default settingsSlice.reducer;
