import React, {useState} from "react";

import STAR_IMAGE from "../../img/star.png";
import STAR_SELECTED_IMAGE from "../../img/star-selected.png";

const Star = props => {
    
    const [selected, setSelected] = useState(false);
    
    const {size, position, margin, index} = props;
    
    return (
        <div onClick={() => {
            const {index, starSelectedInPosition} = props;
      setSelected( !selected );
      if (starSelectedInPosition)
        starSelectedInPosition( index+1 );
    }}>
      <img src={index < position ? STAR_SELECTED_IMAGE : STAR_IMAGE}
        style={{
          cursor: position == 0 || size == 40 ? 'pointer' : 'default',
          width: size,
          height: size,
          marginRight: margin ? margin : 3,
        }} />
    </div>
  );
}

export default Star;

/*


                                  starSelected = rating => {
                                  const { reservation } = this.state;
                                  const type = reservation.reservation_type;
                                  if (reservation.reservation_rating) return;
                                  navigate('Reviews', 'Reviews', { type, reservation, rating, reviewCompleted: this.reviewCompleted});
                                };

 */
