import React, {useEffect, useRef, useState} from 'react';
import {CircularProgress, Typography} from "@material-ui/core";

import {Link, useHistory, useParams} from "react-router-dom";

import {useQuery} from "src/hooks/useQuery";
import clsx from "clsx";
import AlertSnackbar from "src/components/organisms/snackbar/AlertSnackBar";
import {HostAPI} from "src/APIs/HostAPI";
import {red} from "@material-ui/core/colors";
import {devLog} from "src/Helpers";

export type RespondToAdminHostInviteProps = {}
RespondToAdminHostInvite.key = 'host-invite'
RespondToAdminHostInvite.route = `/${RespondToAdminHostInvite.key}/:action`

// http://localhost:3000/host-invite/accept?id=1178&tkn=add47e2aee8d85822012141ad368863ee5b86e3b2ada47af191eee0fb8cd54c5953886dd1467be2adcebb0739f88215d
export type RespondToAdminHostInviteRouteParams = {
	action: string,
}
export default function RespondToAdminHostInvite({}: RespondToAdminHostInviteProps) {
	const {action} = useParams<RespondToAdminHostInviteRouteParams>();
	const [message, setMessage] = useState<any>();
	const [error, setError] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const snackbar = useRef<AlertSnackbar>(null);
	let query = useQuery();
	let history = useHistory();

	const preformAction = (action, tkn, id) => {
		HostAPI.respondToHostInvite(id, tkn, action).then((message) => {
			setLoading(false);
			setMessage(message)
			devLog(message)
			setTimeout(() => {
				history.push('/')
			}, 5000)
		})
	}

	useEffect(() => {
		let tkn = query.get('tkn');
		let id = query.get('id');
		if (!(id && tkn)) {
			setError(true)
			setLoading(false)
		} else {
			setTimeout(() => {
				preformAction(action, tkn, id)
			}, 2000)
		}


	}, [])


	return (


		<div className="container pr-0 pl-0" style={{marginTop: 120}}>
			<div className="page">


				<div className={`row page-lg-title mt-40 mb-5 `}>
					<div className={"col-11"}>
						{(error) ? (
							"Link is Malformed"
						) : (
							(action == 'accept') ? 'Accepting Invite' : "Rejecting Invite"
						)
						}


					</div>
				</div>
				{
					(loading) ? (
						<div className={clsx('d-flex col-12 justify-content-center')}>
							<CircularProgress className={clsx('mx-auto my-5')}/>
						</div>
					) : (
						<Typography>
							<div
								style={{color: (message?.type === 'danger') ? red[400] : "black"}}>{message?.title} {message?.message}</div>
							{(message?.type === 'danger') ? <div>Please, <Link to='/contact-us'>Contact
									us</Link> if this issue prevails</div>
								: <div> You can Log-in now.</div>
							}
						</Typography>
					)
				}

				<AlertSnackbar ref={snackbar}/>
			</div>
		</div>
	)
}
