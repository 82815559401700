import React from "react";
import {ReservStyles} from "src/styles/ReservStyles";
import {printPrice} from "../Helpers";

export class DdrUtils {

	static SEEKER_REQUESTED_BETTER_PRICE_KEY = "seeker-requested-better-price"
	static HOST_REJECTED_BETTER_PRICE_REQUEST = "host-rejected-better-price-request"
	static HOST_SUBMITTED_BETTER_PRICE = "host-submitted-better-price"
	static SEEKER_REJECTED_NEW_PRICE = "seeker-rejected-new-price"
	static SEEKER_ACCEPTED_NEW_PRICE = "seeker-accepted-new-price"
	static SPACE_NO_LONGER_AVAILABLE = "space-no-longer-available"
	static GENERIC_REQUEST_ALLOWED_CITIES = [
		{value: "Dubai", label: "Dubai"},
		{value: "Abu Dhabi", label: "Abu Dhabi"},
		{value: "Sharjah", label: "Sharjah"},
		{value: "Ajman", label: "Ajman"},
		{value: "Fujairah", label: "Fujairah"},
		{value: "Ras Al Khaimah", label: "Ras Al Khaimah"},
		{value: "Umm Al Quwain", label: "Umm Al Quwain"},
		{value: "Riyadh", label: "Riyadh"},
		{value: "Manama", label: "Manama"},
		{value: "Doha", label: "Doha"},
		{value: "London", label: "London"},
		{value: "Berlin", label: "Berlin"},
		{value: "Cairo", label: "Cairo"},
		{value: "Amman", label: "Amman"},
	]


	static shouldShowAcceptAndReject(req) {
		if (req.seeker_better_price_request_date) {
			let better_price_status: BetterPriceStatus = req.better_price_status
			switch (better_price_status) {
				case "seeker-rejected-new-price":
				case DdrUtils.SEEKER_REJECTED_NEW_PRICE:
				case DdrUtils.SPACE_NO_LONGER_AVAILABLE:
				case DdrUtils.SEEKER_REQUESTED_BETTER_PRICE_KEY:
					return false;
				case "host-submitted-better-price":
				case "host-rejected-better-price-request":
					return true;
			}
		} else {
			return req.quote_status === 'pending';
		}
	}

	static shouldShowVenueDetails(req) {
		return req.quote_status === 'accepted' || req.payment_status === 'paid' || req.payment_status === 'paid to venue';
	}

	static printQuoteStatus(req, isMultiple = false) {

		if (!req) return (<></>);

		if (req.better_price_status || req.requests?.[0]?.better_price_status) {
			let better_price_status: BetterPriceStatus = req.better_price_status || req.requests?.[0]?.better_price_status
			switch (better_price_status) {
				case DdrUtils.SEEKER_REQUESTED_BETTER_PRICE_KEY:
					return (<div className="reserv-status reserv-status-black">Better Price Requested</div>);
				case DdrUtils.SPACE_NO_LONGER_AVAILABLE:
					return (<div className="reserv-status reserv-status-red">Space Is No Longer Available</div>);
				case "host-rejected-better-price-request":
				case "host-submitted-better-price":
					return (<div className="reserv-status reserv-status-black">Received A Better Price</div>);
			}
		}

		if (req.payment_status === 'paid') {
			return (<div className="reserv-status reserv-status-green">Paid &amp; Confirmed</div>);
		}
		if (req.payment_status === 'paid to venue') {
			return (<div className="reserv-status reserv-status-green">Pay to Venue</div>);
		}
		if ((req.quote_status === 'accepted' || req.quote_status === 'pending') && req.payment_status !== 'paid' && ((new Date()) > (new Date(req.start_date)))) {
			return (<div className="reserv-status reserv-status-gray">Expired</div>);
		}
		if (req.quote_status === 'received' || (req.quote_status === 'pending' && req.tot_fluidmeet_price)) {
			return (<div
				className="reserv-status reserv-status-black">{(isMultiple) ? 'Received Quote(s)' : 'Received a Quote'}</div>);
		}
		if (req.quote_status === 'accepted') {
			if (req.visit_req === 'yes') return (
				<div className="reserv-status reserv-status-green">Visit Arranged</div>);
			if (req.call_req === 'yes') return (
				<div className="reserv-status reserv-status-green">Requested a Call</div>);
			return (<div className="reserv-status reserv-status-green">Interested</div>);
		}
		if (req.quote_status === 'declined by seeker') {
			return (<div className="reserv-status reserv-status-red">Declined</div>);
		}
		if (req.quote_status === 'declined by venue' || req.quote_status === 'declined by fm') {
			return (<div className="reserv-status reserv-status-red">Cancelled</div>);
		}
		if (req.quote_status === 'pending') {
			return (<div className="reserv-status reserv-status-black">Pending</div>);
		}
		return (<></>);
	}

	static printHostProposalStatus(proposal) {
		if (proposal.better_price_status || proposal.proposaluests?.[0]?.better_price_status) {
			let better_price_status: BetterPriceStatus = proposal.better_price_status || proposal.requests?.[0]?.better_price_status
			switch (better_price_status) {
				case DdrUtils.SEEKER_REQUESTED_BETTER_PRICE_KEY:
					return (<span style={{...ReservStyles.reservstatus, ...ReservStyles.statusblack}}>Client Requested A Better Price</span>);
				case DdrUtils.SPACE_NO_LONGER_AVAILABLE:
					return (<span style={{...ReservStyles.reservstatus, ...ReservStyles.statusred}}>Space Is No Longer Available</span>);
				case "host-submitted-better-price":
					return (<span style={{...ReservStyles.reservstatus, ...ReservStyles.statusblack}}>Submitted A New Price</span>);
			}
		}

		if (proposal.payment_status === 'paid' || proposal.payment_status === 'paid to venue') {
			return (
				<span style={{...ReservStyles.reservstatus, ...ReservStyles.statusgreen}}>Paid &amp; Confirmed</span>);
		}
		if (proposal.quote_status === 'declined by seeker') {
			return (<span style={{...ReservStyles.reservstatus, ...ReservStyles.statusgray}}>Cancelled</span>);
		}
		if (proposal.quote_status === 'declined by venue' || proposal.quote_status === 'declined by fm') {
			return (<span style={{...ReservStyles.reservstatus, ...ReservStyles.statusred}}>Inquiry Declined</span>);
		}
		if (proposal.quote_status === 'expired') {
			if (proposal.tot_fluidmeet_price && proposal.tot_fluidmeet_price > 0)
				return (<span style={{...ReservStyles.reservstatus, ...ReservStyles.statusgray}}>Expired</span>);
			return (
				<span style={{...ReservStyles.reservstatus, ...ReservStyles.statusred}}>Expired - Not Priced</span>);
		}
		if (proposal.quote_status === 'accepted') {
			return (<span style={{...ReservStyles.reservstatus, ...ReservStyles.statusblack}}>Client Interested</span>);
		}
		if ((proposal.quote_status === 'submitted' || proposal.quote_status === 'pending') && proposal.tot_fluidmeet_price) {
			return (<span style={{...ReservStyles.reservstatus, ...ReservStyles.statusblack}}>Priced</span>);
		}
		return (<span style={{...ReservStyles.reservstatus, ...ReservStyles.statusred}}>Pending</span>);
	}

}


export function printSummaryQuoteStatus(req) {

	if (req.better_price_status || req.requests?.[0]?.better_price_status) {
		let better_price_status: BetterPriceStatus = req.better_price_status || req.requests?.[0]?.better_price_status
		switch (better_price_status) {
			case DdrUtils.SEEKER_REQUESTED_BETTER_PRICE_KEY:
				return (<div className="status-black">Better Price Requested</div>);
			case "host-rejected-better-price-request":
			case "host-submitted-better-price":
				return (<div className="status-black">Received A Better Price</div>);
		}
	}

	if (req.payment_status === 'paid') {
		return (<span className="status-green">(Paid &amp; Confirmed)</span>);
	}
	if (req.payment_status === 'paid to venue') {
		return (<span className="status-green">(Pay to Venue)</span>);
	}
	if ((req.quote_status === 'accepted' || req.quote_status === 'pending') && req.payment_status !== 'paid' && ((new Date()) > (new Date(req.start_date)))) {
		return (<span className="status-gray">(Expired)</span>);
	}
	if (req.quote_status === 'pending' && req.tot_fluidmeet_price) {
		return (<></>);
	}
	if (req.quote_status === 'accepted') {
		if (req.visit_req === 'yes') return (<span className="status-green">(Visit Arranged)</span>);
		if (req.call_req === 'yes') return (<span className="status-green">(Requested a Call)</span>);
		return (<span className="status-green">(Interested)</span>);
	}
	if (req.quote_status === 'declined by seeker') {
		return (<span className="status-red">(Declined)</span>);
	}
	if (req.quote_status === 'declined by venue' || req.quote_status === 'declined by fm') {
		return (<span className="status-green">(Cancelled)</span>);
	}
	if (req.quote_status === 'pending') {
		return (<span>(Pending)</span>);
	}
	return (<></>);
}

export function getGenericQuoteStatus(requests) {

	const status = ['pending', 'declined by seeker', 'declined by venue', 'declined by fm'];

	const paidRequest = requests.find(x => x.payment_status === 'paid');
	if (paidRequest) return paidRequest;

	const doneRequest = requests.find(x => x.payment_status === 'paid to venue');
	if (doneRequest) return doneRequest;

	const accRequests = requests.filter(x => x.quote_status === 'accepted');
	if (accRequests.length > 0) {
		accRequests.sort((a, b) => (a.call_req) ? -1 : (a.call_req) ? 1 : 0);
		return accRequests[0];
	}

	const recievedRequests = requests.filter(x => Boolean(x.tot_fluidmeet_price)).filter(x => x.quote_status === 'pending');
	if (recievedRequests.length > 0) {
		recievedRequests.sort((a, b) => (a.tot_fluidmeet_price < b.tot_fluidmeet_price) ? -1 : (a.tot_fluidmeet_price > b.tot_fluidmeet_price) ? 1 : 0);
		return recievedRequests[0];
	}

	for (var j = 0; j <= status.length; j++) {
		const request = requests.find(x => x.quote_status === status[j]);
		if (request) return request;
	}

	return null;
}

export function checkGenericIsPaid(requests) {

	const paidRequest = requests?.find(x => x.payment_status === 'paid');
	if (paidRequest) return true;

	const doneRequest = requests?.find(x => x.payment_status === 'paid to venue');
	return Boolean(doneRequest);


}

export function getGenericQuoteMinPrice(requests) {

	const recievedRequests = requests.filter(x => x.amount_due > 0);
	if (recievedRequests.length > 0) {
		recievedRequests.sort((a, b) => (a.amount_due < b.amount_due) ? -1 : (a.amount_due > b.amount_due) ? 1 : 0);
		return recievedRequests[0];
	}
	return null;
}

export function getGenericQuotePrice(requests, fullLabel, currency) {

	const paidRequest = requests.find(x => x.payment_status === 'paid');
	if (paidRequest && paidRequest.tot_fluidmeet_price) return (
		printPrice(paidRequest.amount_due, 'USD', currency, true)
	);

	const doneRequest = requests.find(x => x.payment_status === 'paid to venue');
	if (doneRequest && doneRequest.tot_fluidmeet_price) return (
		printPrice(doneRequest.amount_due, 'USD', currency, true)
	);

	const req = getGenericQuoteMinPrice(requests);
	if (req && req.tot_fluidmeet_price) return (
		<>
			{(fullLabel && (<span className="min-price">starting at</span>))}
			{printPrice(req.amount_due, 'USD', currency, true)}
		</>
	);

	return '-';
}

export function getAvalQuotesNum(requests, venues) {

	var num = 0;
	requests.forEach(req => {
		if ((req.status === 'inactive' && !req.reject_reason) || !req.tot_fluidmeet_price || (!venues || !venues[req.generic_venue_id])) {
			return;
		}
		num++;
	});
	return num;
}

export type BetterPriceStatus =
	"seeker-requested-better-price" |
	"host-rejected-better-price-request" |
	"host-submitted-better-price" |
	"seeker-rejected-new-price" |
	"seeker-accepted-new-price" | null | "";
