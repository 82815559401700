import {FormControl, InputLabel, MenuItem, Select, SelectProps, TextField} from "@material-ui/core";
import React from "react";
import {FILTER_LIST, DATE_FILTER_LIST} from "src/components/host/host-dashboard/manage-reservations/consts";
import {SelectInputProps} from "@material-ui/core/Select/SelectInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export interface ReservationsFilterProps {
	label?: string,
	defaultValue?,
	value?: SelectProps['value'],
	onChange?: SelectInputProps['onChange'],
	onSearch?,
	search?: string,
	isSearch?: boolean
	isDate?: boolean,
	dateLabel?: string,
	dateDefaultValue?,
	dateValue?,
	dateOnChange?,
	dateStartValue?,
	dateStartOnChange?,
	dateEndValue?,
	dateEndOnChange?
}

export const ReservationsFilter = (
	{
		label = "Filter", defaultValue = FILTER_LIST[0].value, value, onChange, onSearch, search, isSearch = true, isDate = true, dateLabel = "Date", dateDefaultValue = DATE_FILTER_LIST[0].value, dateValue, dateOnChange, dateStartValue = new Date(), dateStartOnChange, dateEndValue = new Date(), dateEndOnChange
	}: ReservationsFilterProps
) => {
	let extraProps: any = {}
	if (value) extraProps.value = value
	if (onChange) extraProps.onChange = onChange

	let dateProps: any = {}
	if (value) dateProps.value = dateValue
	if (onChange) dateProps.onChange = dateOnChange

	let dateStartProps: any = {}
	if (value) dateStartProps.selected = new Date(dateStartValue)
	if (onChange) dateStartProps.onChange = dateStartOnChange

	let dateEndProps: any = {}
	if (value) dateEndProps.selected = new Date(dateEndValue)
	if (onChange) dateEndProps.onChange = dateEndOnChange

	return (
		<>
		{dateValue === 'custom' && 
			<FormControl variant="outlined" style={{marginRight: '10px'}}>
				<DatePicker
					className="form-control d-block"
					selected={dateStartValue}
					{...dateStartProps}
				/>
			</FormControl>
		}
		{dateValue === 'custom' && 
			<FormControl variant="outlined" style={{marginRight: '10px'}}>
				<DatePicker
					className="form-control d-block"
					// onChangeRaw={(e) => handleFormData('start', e)}
					// excludeDates={Object.keys(excludedDates).map(date => new Date(date))}
					// filterDate={(date) => excludedDays[date.getDay()] == null}
					selected={dateEndValue}
					{...dateEndProps}
				/>
			</FormControl>
		}
		{isDate &&
			<FormControl variant="outlined" style={{marginRight: '10px'}}>
				<InputLabel id="date-simple-select-outlined-label">{dateLabel}</InputLabel>
				<Select
					labelId="demo-simple-select-outlined-label"
					id="date-simple-select-outlined"
					defaultValue={dateDefaultValue}
					label="Date"
					{...dateProps}

				>
					<MenuItem value={'one'}>{'One Month'}</MenuItem>
					<MenuItem value={'three'}>{'Three Month'}</MenuItem>
					<MenuItem value={'six'}>{'Six Month'}</MenuItem>
					<MenuItem value={'custom'}>{'Custom Date'}</MenuItem>
				</Select>
				{/* <InputLabel id="demo-simple-select-label">Age</InputLabel>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={10}
					label="Age"
					// onChange={handleChange}
				>
					<MenuItem value={10}>Ten</MenuItem>
					<MenuItem value={20}>Twenty</MenuItem>
					<MenuItem value={30}>Thirty</MenuItem>
				</Select> */}
			</FormControl>
		}
		{isSearch &&
			<FormControl variant="outlined" style={{marginRight: '10px'}}>
				<TextField id="outlined-search" label="Search" type="search" defaultValue={search} variant="outlined" onChange={(e) => onSearch(e)}/>
			</FormControl>
		}
			<FormControl variant="outlined">
				<InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
				<Select
					labelId="demo-simple-select-outlined-label"
					id="demo-simple-select-outlined"
					defaultValue={defaultValue}
					label="Filter"
					{...extraProps}

				>
					{
						FILTER_LIST.map(({label, value}: any) => (
							<MenuItem key={value} value={value}>{label}</MenuItem>
						))
					}
				</Select>
			</FormControl>
		</>
	)
}
