import {devLog, getApiLinkV2, getToken} from "src/Helpers";
import axios from "axios";
import {getAxios} from "src/Helpers";
import Bugsnag from "@bugsnag/js";
import {Message} from "src/APIs/HostAPI";
import {DdrUtils} from "src/utils/ddr-utils";
import moment from "moment";

export type FetchReservationsResponse = {
	reservations: any[],
	bookings: any[],
	leads: any[],
	proposals: any[],
	status: 'active' | 'disabled' | 'pending',
	isAdmin: boolean,
};

export class ReservationsAPI {

	static async fetchSeekerReservations() {
		let token = await getToken()
		if (!token) return;
		try {

			const url = getApiLinkV2('user/list-reservations');

			let response = await getAxios().get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token,
				},
			})
			devLog(`Fteched Seeker reservations`, response.data)
			let allReservations: any[] = [];
			const {reservations, leads, proposals} = response.data;

			if (reservations) reservations.forEach((item: any) => allReservations.push(item));
			if (leads) leads.forEach((item: any) => allReservations.push(item));
			let finalProposals: any[] = []
			if (proposals) {
				if (proposals.non_generic) {
					Object.values(proposals.non_generic).forEach((item: any) => {
						allReservations.push(item);
						finalProposals.push(item);
					});
				}
				if (proposals.generic) {
					Object.values(proposals.generic).forEach((item: any) => {
						allReservations.push(item);
						finalProposals.push(item);
					});
				}

				finalProposals = finalProposals.filter((e) => ((typeof e) === 'object')).sort((a, b) => (a.start_date > b.start_date) ? -1 : (a.start_date < b.start_date) ? 1 : 0)
			}


			allReservations.sort((a, b) => (a.start_date > b.start_date) ? -1 : (a.start_date < b.start_date) ? 1 : 0);


			return ({
				reservations: allReservations,
				bookings: reservations ? reservations : [],
				leads: leads ? leads : [],
				proposals: finalProposals
			}) as FetchReservationsResponse;


		} catch (error: any) {
			console.error(error);
			Bugsnag.notify(error);
			throw new Error("Fetch Seeker Reservation failed")
		}
	}

	static async fetchReservations(user_type = 'host') {
		const type = (user_type === 'host') ? 'host' : 'user';


		let token = await getToken()
		if (!token) return;
		try {

			const url = getApiLinkV2(type + "/reservations");

			let response = await getAxios().get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token,
				},
			})
			let allReservations: any[] = [];
			const {reservations, leads, proposals} = response.data;

			if (reservations) reservations.forEach((item: any) => allReservations.push(item));
			if (leads) leads.forEach((item: any) => allReservations.push(item));

			let finalProposals: any[] = [];
			if (proposals) {
				if (user_type == 'host') {
					if (proposals) proposals.forEach((item: any) => {
						finalProposals.push(item);
						allReservations.push(item);
					});
				} else {
					Object.keys(proposals).forEach(day => finalProposals.push(proposals[day]));
					finalProposals.forEach(item => allReservations.push(item));
				}
			}

			if (user_type == 'host') {

				if (reservations && reservations.length > 0) {
					if (user_type == 'host') {
						reservations.sort((a, b) => {
							if (a.order < b.order) return -1;
							if (a.order > b.order) return 1;
							if (a.order_date < b.order_date) return -1;
							if (a.order_date > b.order_date) return 1;
							return 0;
						});
					} else {
						reservations.sort((a, b) => (a.start_date > b.start_date) ? -1 : (a.start_date < b.start_date) ? 1 : 0);
					}
				}
				if (leads && leads.length > 0) {
					if (user_type == 'host') {
						leads.sort((a, b) => {
							if (a.order < b.order) return -1;
							if (a.order > b.order) return 1;
							if (a.order_date < b.order_date) return -1;
							if (a.order_date > b.order_date) return 1;
							return 0;
						});
					} else {
						leads.sort((a, b) => (a.start_date > b.start_date) ? -1 : (a.start_date < b.start_date) ? 1 : 0);
					}
				}

				if (finalProposals.length > 0) {
					if (user_type == 'host') {
						finalProposals.sort((a, b) => {

							if (
								(a.seeker_better_price_request_date || b.seeker_better_price_request_date) &&
								(
									a.better_price_status === DdrUtils.SEEKER_REQUESTED_BETTER_PRICE_KEY ||
									b.better_price_status === DdrUtils.SEEKER_REQUESTED_BETTER_PRICE_KEY
								)
							) {
								let negotiation_date_a = moment(a.seeker_better_price_request_date ?? 0).toDate()
								let negotiation_date_b = moment(b.seeker_better_price_request_date ?? 0).toDate()
								// console.log({a, b})
								if (negotiation_date_a < negotiation_date_b) return 1;
								if (negotiation_date_a > negotiation_date_b) return -1;
							}
							if (a.order < b.order) return -1;
							if (a.order < b.order) return -1;
							if (a.order > b.order) return 1;
							if (a.order_date < b.order_date) return -1;
							if (a.order_date > b.order_date) return 1;
							return 0;
						});
					} else {
						finalProposals.sort((a, b) => (a.start_date > b.start_date) ? -1 : (a.start_date < b.start_date) ? 1 : 0);
					}
				}

				allReservations.sort((a, b) => {
					if (a.order < b.order) return -1;
					if (a.order > b.order) return 1;
					if (a.order_date < b.order_date) return -1;
					if (a.order_date > b.order_date) return 1;
					return 0;
				});
			} else {
				allReservations.sort((a, b) => (a.start_date > b.start_date) ? -1 : (a.start_date < b.start_date) ? 1 : 0);
			}


			return ({
				reservations: allReservations,
				bookings: reservations ? reservations : [],
				leads: leads ? leads : [],
				proposals: finalProposals ? finalProposals : []
			}) as FetchReservationsResponse;


		} catch (error: any) {
			console.error(error);
			Bugsnag.notify(error);
			throw new Error("Fetching " + user_type + " reservations failed")
		}
	}
	static async updateDDRBookingStatus(quote_id:string,value:string){
		let token = getToken()
		if (!token) return new Message('danger',"Missing Authentication Token");

		const url = getApiLinkV2("host/reservations/" + quote_id);
		
		try{
				let response  = await getAxios().put(url, {
					reservation_type: 'proposal',
					option: 'status',
					status: value,
				}, {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + token,
					},
				})
			return new Message('success', "Updated Successfully", response.data.messages, undefined, response)
		} catch (error: any) {
			Bugsnag.notify(error);
			return new Message('danger', "Failure to Update", error.message, undefined, error)
		}

	}
}
