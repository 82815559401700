import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {blue, blueGrey, red} from "@material-ui/core/colors";

export const infoSection = (icon, title, value, color?) => {
	const style = styles.callBtn
	if (color) style.backgroundColor = color
	return (
		<div className={'d-flex flex-row align-items-center my-2 col-12 col-md-6'}>
			{Boolean(icon) && (
				<div className={'mr-2'}>
					<div style={style}>
						<FontAwesomeIcon icon={icon} style={{height: 24, width: 24, color: "white"}}/>
					</div>
				</div>
			)
			}

			<div>
				<div style={{...styles.reservSubtitle, paddingBottom: 6}}>{title}</div>
				<div style={styles.reservText}>{value}</div>
			</div>


		</div>
	)
}
const styles = {

	reservSubtitle: {
		color: '#394148',
		fontWeight: 300,
		fontSize: 15,
	},
	reservText: {
		color: '#212529',
		overflow: 'hidden' as const,
		fontSize: 17,
	},
	callBtn: {
		borderRadius: '100%',
		backgroundColor: '#00a9e0',
		width: 36,
		height: 36,
		marginLeft: 8,
		justifyContent: 'center' as const,
		alignItems: 'center' as const,
		display: 'flex' as const,
	},



};
