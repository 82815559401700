import React, {Component} from 'react';
import {Link, Redirect, withRouter} from "react-router-dom";
// import TagManager from 'react-gtm-module';
import {
  createAlert,
  devLog,
  formValidation,
  getApiLinkV2,
  getCountryCode,
  getSource,
  getToken,
  getUser,
  hasTerm,
  printSpaceData,
  setSource
} from "../../Helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faCircleNotch, faStar} from "@fortawesome/free-solid-svg-icons";
import NavBar from "../atoms/NavBar";
import FloatingAlert from "../organisms/FloatingAlert";
import axios from "axios";
import {getAxios} from "src/Helpers";
import Footer from "../organisms/Footer";
import queryString from "query-string";
import Bugsnag from "@bugsnag/js";
import ReactGA from "react-ga-neo";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import libphonenumber from "google-libphonenumber";
import {Helmet} from "react-helmet";
// const tagManagerArgs = {
// 	gtmId: 'AW-935885959',
//   events: {
//     conversion: {'send_to': 'AW-935885959/0gQcCNuT6bIZEIf5ob4D'}
//   }
//   }
//   TagManager.initialize(tagManagerArgs);
let phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()
let PNF = libphonenumber.PhoneNumberFormat

class WorkspaceLTBooking extends Component {
  
  constructor(props) {
    super(props);
    
    var initState = {
      space: (props.generic) ? null : {},
      page: (props.page) ? props.page : 'contact',
      term: 'monthly',
      food: null,
      service: null,
      reqAlert: null,
      reqLoading: false,
      countryCode: null,
      request: {},
      errors: {},
      BackID: '',
    }

    if (props.location.state) {

      const { space, term, food, service, source } = props.location.state;

      initState.space = space;
      initState.term = term;
      initState.food = food;
      initState.service = service;

      if (source) setSource(source);

    } else {

      let search = queryString.parse(props.location.search);
      setSource((search && search.source) ? search.source : 'details');
    }

    this.state = initState;

    this.handleNextBtn = this.handleNextBtn.bind(this);
    this.fetchSpace = this.fetchSpace.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
      this.setState({page: this.props.match.params.page});
      //ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  componentDidMount() {

    const { generic, location } = this.props;
    const { space } = this.state;

    if (space && Object.keys(space).length === 0 && !generic) {
      this.fetchSpace();
    }

    const source = getSource();

    const spaceType = location.pathname.indexOf('lt-office') !== -1 ? 'Office Space' : 'Co-Working Space';
    var eventLabel = spaceType+' LT Attempt from '+source;
    if (!generic) eventLabel = eventLabel+' for space '+space.workspace_id;
    ReactGA.event({category: 'LT', action: 'LT_Attempt'+(generic ? '_Generic' : '_Unique'), label: eventLabel});
    if(window.hasOwnProperty('gtag')) {
      if(window.hasOwnProperty('gtag')) {
        window?.gtag('event', 'conversion', {'send_to': 'AW-935885959/bG6MCOzjgbcZEIf5ob4D'});
      }
    }
    //ReactGA.pageview(window.location.pathname + window.location.search);
  }

  fetchSpace() {

    const { match } = this.props;

    const url = getApiLinkV2('workspace/'+match.params.id);
    const token = getToken();
    const headers = {'Content-Type': 'application/json'};
    if (token) {
      headers['Authorization'] = 'Bearer '+token;
    }

    getAxios().get(url, {
      headers: headers,
    }).then(response => {
      if (response.data.workspace) {
  
        devLog(response.data.workspace);
        const space = response.data.workspace;
        const defSpaceData = printSpaceData(space.workspace_type_id);
        if (hasTerm(space, 'monthly')) {
          this.setState({space: space, similarSpaces: response.data.similar_workspace, food: defSpaceData.food, service: defSpaceData.service, });
        } else {
          this.setState({space: null});
        }
      }
    }).catch(error => {
      Bugsnag.notify(error);
      console.error((error.response && error.response.data.error) ? error.response.data.error : error.message);
      this.setState({space: null});
    });
  }

  handleNextBtn(e) {
    e.preventDefault();

    this.setState({reqLoading: true});

    const { match, location, history, generic } = this.props;
    const {space, countryCode, request, errors} = this.state;
    const data = new FormData(e.target);
  
    request.fName = data.get("fname");
    request.lName = data.get("lname");
    request.email = data.get("email");
    request.phone = data.get("phone");
    request.company = data.get("company");
  
    const user = getUser();
    // if (request.phone && request.phone.length > 0) {
    //   if (request.phone[0] === '0') {
    //     request.phone = request.phone.substring(1);
    //   }
    //   const code = countryCode ? '+'+countryCode : (user && user.country) ? ('+'+getCountryCode(user.country)[3]) : '+971';
    //   request.phone = code+request.phone;
    // }
  
  
    errors.fName = formValidation('fname', request.fName);
    errors.lName = formValidation('lname', request.lName);
    errors.email = formValidation('email', request.email);
    errors.phone = formValidation('phone', request.phone);
    errors.company = formValidation('company', request.company, false);
  
    Object.keys(errors).forEach(key => {
      if (!errors[key]) delete errors[key];
    });
  
    this.setState({errors: errors});
    if (Object.keys(errors).length > 0) {
      this.setState({reqLoading: false});
      return;
    }
    let phone = phoneUtil.parse(request.phone)
    request.phone = phoneUtil.format(phone, PNF.E164)
  
    const url = getApiLinkV2('workspace/lt-request');
    const token = getToken();
    const headers = {'Content-Type': 'application/json'};
    if (token) {
      headers['Authorization'] = 'Bearer ' + token;
    }
  
    const source = getSource();
  
    getAxios().post(url, {
      rq_wid: (generic) ? 0 : match.params.id,
      ri_first_name: request.fName,
      ri_last_name: request.lName,
      ri_email: request.email,
      ri_phone: request.phone,
      ri_company: request.company,
      source: source,
    }, {
      headers: headers,
    }).then(response => {
      this.setState({reqLoading: false});

      const spaceType = location.pathname.indexOf('lt-office') !== -1 ? 'Office Space' : 'Co-Working Space';
      var eventLabel = spaceType+' LT Success from '+source;
      if (!generic) eventLabel = eventLabel+' for space '+space.workspace_id;
      ReactGA.event({category: 'LT', action: 'LT_Success'+(generic ? '_Generic' : '_Unique'), label: eventLabel});
      if(window.hasOwnProperty('gtag')) {
        if(window.hasOwnProperty('gtag')) {
          window?.gtag('event', 'conversion', {'send_to': 'AW-935885959/bG6MCOzjgbcZEIf5ob4D'});
        }
      }
      if (spaceType == 'Office Space') {
        if(generic){
          if(window.hasOwnProperty('gtag')) {
						if(window.hasOwnProperty('gtag')) {
              window?.gtag('event', 'conversion', {'send_to': 'AW-935885959/bG6MCOzjgbcZEIf5ob4D'});
            }
          }
        } else {
          if(window.hasOwnProperty('gtag')) {
            window?.gtag('event', 'conversion', {'send_to': 'AW-935885959/ycynCNCn9PUCEIf5ob4D'});
          }
        }
      }

      if (spaceType == 'Co-Working Space') {
        if(generic){
          if(window.hasOwnProperty('gtag')) {
						if(window.hasOwnProperty('gtag')) {
              window?.gtag('event', 'conversion', {'send_to': 'AW-935885959/bG6MCOzjgbcZEIf5ob4D'});
            }
          }
        } else {
          if(window.hasOwnProperty('gtag')) {
            window?.gtag('event', 'conversion', {'send_to': 'AW-935885959/W4iFCJyU9fUCEIf5ob4D'});
          }
        }
      }

      var baseUrl = '/request/lt-office/generic/success';
      const user = getUser();
      let FirstNameUser;
      let LastNameUser;
      let PhoneNumberUser;
      let emailAddressUser;
      if(!user){
        FirstNameUser = request.fName;
        LastNameUser = request.lName;
        PhoneNumberUser = request.phone;
        emailAddressUser = request.email;
      }else{
        FirstNameUser = user?.first_name;
        LastNameUser = user?.last_name;
        PhoneNumberUser = user?.phone;
        emailAddressUser =  user?.email;
      }
      if(window.location.href.includes('lt-office')){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "Offices_LT_Submission",
          'First Name': FirstNameUser,
          'Last Name' :LastNameUser,
          'Email': emailAddressUser,
          'Phone':PhoneNumberUser
        });
      }

      if(window.location.href.includes('lt-coworking')){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "Coworking_LT_Submission",
          'First Name': FirstNameUser,
          'Last Name' :LastNameUser,
          'Email': emailAddressUser,
          'Phone':PhoneNumberUser
        });
      }
      if(window.location.href.includes('lt-coworking') && !window.location.href.includes('generic')){
         this.setState({BackID: match.params.id});
         baseUrl = '/request/lt-coworking/' + match.params.id +'/success';
      }
      if(window.location.href.includes('lt-office') && !window.location.href.includes('generic')){
        this.setState({BackID: match.params.id});
        baseUrl = '/request/lt-office/' + match.params.id + '/success';
      }
      if(window.location.href.includes('lt-coworking') && window.location.href.includes('generic')){
        this.setState({BackID: match.params.id});
        baseUrl = '/request/lt-coworking/generic/success';
      }
      if(window.location.href.includes('lt-office') && window.location.href.includes('generic')){
        this.setState({BackID: match.params.id});
        baseUrl = '/request/lt-office/generic/success';
      }
      history.push(baseUrl);
      // window.location.href = (baseUrl);
    }).catch(error => {
      Bugsnag.notify(error);
      createAlert(this, 'danger',(error.response && error.response.data.message) ? 'Error: ' : '', (error.response && error.response.data.message) ? error.response.data.message : error.message);
      this.setState({reqLoading: false});
    });
  }

  render() {

    const { match, generic } = this.props;
    const { page, space, countryCode, reqAlert, reqLoading,BackID, errors } = this.state;

    const user = getUser();

    // if (!space && !generic) {
    if (!space && !generic && !window.location.href.includes('unique')) {
      return (<Redirect to={'/space/'+match.params.id+'?t=monthly'} />);
    }
    if (space && Object.keys(space).length === 0 && !generic && !window.location.href.includes('unique')) {
      return (<></>);
    }

    return (
      <>
      {generic ? (<>
        <Helmet>
					
					{/* Event snippet for Generic LT Success (2024) conversion page */}
					<script>
					{`
						window.gtag = window.gtag || function() { window.dataLayer.push(arguments); };
						gtag('js', new Date());
						gtag('config', 'AW-935885959');
						window.gtag('event', 'conversion', {"send_to": "AW-935885959/6952CO6k9fUCEIf5ob4D"});
					`}
					</script>

				</Helmet>
      </>) : (<>
        <Helmet>
					
					{/* Event snippet for Generic LT Success (2024) conversion page */}
					<script>
					{`
						window.gtag = window.gtag || function() { window.dataLayer.push(arguments); };
						gtag('js', new Date());
						gtag('config', 'AW-935885959');
						window.gtag('event', 'conversion', {"send_to": "AW-935885959/EKffCPuQ9_UCEIf5ob4D"});
						window.gtag('event', 'conversion', {'send_to': 'AW-935885959/I6I_CJmIyfUCEIf5ob4D'});
					`}
					</script>

				</Helmet>
      </>)}

        <NavBar backLink={(generic && window.location.href.includes('unique')) ? null : '/space/'+match.params.id+'?t=monthly'} smallLogo={true} smallNav={true} hideSearch={true} hideHostBtn={true} pageTitle="Request to book space"  />

        <div id="slide-cont" className={"container-fluid slide-cont sticky top-42 " + ((generic && window.location.href.includes('unique')) ? "hide-mobile" : "")}>
          <div className="container-lg">
            <div className="booking-page">
              <div className="space-container">
                <div className="space-content" />
                {(!generic && !window.location.href.includes('unique') && (
                  <div id="space-stick" className="space-stick">
                      <div className="book-toprow" >
                        <div style={{height: 80}}>
                          <div className="space-div" style={{flexDirection: "row"}}>
                            <img className="space-img" src={"https://fluidmeet.s3.amazonaws.com/venue_logos/"+space.image} />
                            <div style={{position:'absolute',top: 0,left: 10,right: 10,height: 'calc(100% - 140px)'}} />
                            <div className="space-details pt-2" style={{height: 'auto'}}>
                              <div className="space-rate">
                                <FontAwesomeIcon icon={faStar} style={{color: (space.workspace_rating && space.workspace_rating > 0) ? '#00a9e0' : '#E0E0E0'}} />{" "}
                                {(space.workspace_rating && space.workspace_rating > 0) ? (
                                  space.workspace_rating
                                ) : (
                                  // (space.date_added) ? (
                                  //   isSpaceOld(space.date_added)
                                  // ) : (
                                  ""
                                  // )
                                )}
                                {(space.reviews > 0) ? (
                                  <span className="full">({space.reviews})</span>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <span className="space-location">{space.front_address}</span>
                              <span className="space-title">{space.name}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid bg-white h-z">
          <div className="container-lg h-z">
            <div className={"booking-page ps-187 h-z " + ((generic && window.location.href.includes('unique')) ? "pt-80" : "")}>

              {(generic) ? (
                <h1 className="full-title h-z">Long Term Request</h1>
              ) : (
                <h1 className="full-title h-z">
                  {/* <Link to={'/space/'+match.params.id+'?t=monthly'}> */}
                  {window.location.href.includes('unique') ?                   
                  <Link to={'/space/'+BackID+'?t=monthly'}>
                    <FontAwesomeIcon icon={faArrowLeft} style={{color: '#000000', fontSize: 20, marginRight: 24, marginBottom: 4}} />
                  </Link> 
                  :
                  <Link to={'/space/'+match.params.id+'?t=monthly'}>
                    <FontAwesomeIcon icon={faArrowLeft} style={{color: '#000000', fontSize: 20, marginRight: 24, marginBottom: 4}} />
                  </Link>}
                  Request to book space
                </h1>
              )}

              <div className="space-container">

                <div className="space-content h-z">

                  <div className="form-general p-0">

                      {(page === 'success') ? (
                        <>
                          <div className="space-about pt-5 pb-4 border-0">
                            <h3 className="pb-3">Request Sent</h3>
                            <h6>Your request has been sent successfully.</h6>
                          </div>
                          {!generic && (
                            // <Link to={'/space/'+match.params.id+'?t=monthly'}>
                            <Link to={'/space/'+BackID+'?t=monthly'}>
                              <button className="btn btn-lg btn-primary submit-btn mb-5">Back to space</button>
                            </Link>
                          )}
                        </>
                      ) : (
                        <form className="mt-4 form-flex" autoComplete="on" onSubmit={this.handleNextBtn}>
                          <div className="space-about pt-3 pb-0 border-0">
                            <div className="w100">
                              <input type="name" id="fname" name="fname" className="form-control" placeholder="First name"
                                     defaultValue={(user) ? user.first_name : ''} required={true} />
                              {(errors.fName) ? (<div className="form-error">{errors.fName}</div>) : (<></>)}
                            </div>
                            <div className="w100">
                              <input type="name" id="lname" name="lname" className="form-control" placeholder="Last name"
                                     defaultValue={(user) ? user.last_name : ''} required={true} />
                              {(errors.lName) ? (<div className="form-error">{errors.lName}</div>) : (<></>)}
                            </div>
                            <div className="w100">
                              <input type="email" id="email" name="email" className="form-control" placeholder="Email address"
                                     defaultValue={(user) ? user.email : ''} required={true} />
                              {(errors.email) ? (<div className="form-error">{errors.email}</div>) : (<></>)}
                            </div>
                            <div className="w100">
                              <div className="w100">
                                <PhoneInput
                                    preferredCountries={['ae']}
                                    country={(user && user.country) ? getCountryCode(user.country)[2] : 'ae'}
                                    enableAreaCodes={true}
                                    buttonStyle={{
                                      borderRadius: '16px 0 0 16px',
                                      backgroundColor: '#f9f9f9',
                                      zIndex: 10,
                                      border: '1px solid #cfd8e7',
                                    }}
                                    dropdownStyle={{
                                      borderRadius: '16px',
                                      zIndex: 10,
                                      border: '1px solid #cfd8e7',
                                    }}
                                    searchClass={"form-control-parent"}
                                    inputProps={{id: 'phone', name: 'phone', required: true}}
                                    inputStyle={{
                                      width: '100%',
                                      borderRadius: '16px',
                                    }}
                                    inputClass={'bg-grey-focus-white'}
                                    autoFormat={true}
                                    value={(user) ? user.phone : ''}
                                    enableSearch={true}
                                    onChange={(phone, data) => this.setState({countryCode: data.dialCode})}
                                />
                              </div>
                              {(errors.phone) ? (<div className="form-error">{errors.phone}</div>) : (<></>)}
                            </div>
                            <div className="w100">
                              <input type="text" id="company" name="company" className="form-control" placeholder="Company"
                                     defaultValue={(user) ? user.company : ''} />
                              {(errors.company) ? (<div className="form-error">{errors.company}</div>) : (<></>)}
                            </div>
                          </div>
                          <button type="submit" className={"btn btn-lg btn-primary btn-block fl_btn submit-btn mb-5 "+((reqLoading) ? 'ld-btn' : '')}>
                            {(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch} spin={true} /> loading</>) : 'Send request'}
                          </button>
                        </form>
                      )}
                  </div>
                </div>
              </div>

              {(reqAlert && <FloatingAlert error={reqAlert} onClick={() => this.setState({reqAlert: null})} />)}

            </div>
          </div>
        </div>

        <Footer smallNav={true} />
      </>
    );
  }
}

export default withRouter(WorkspaceLTBooking);

