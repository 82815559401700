import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import "src/components/LogIn/loginStyles.css";
import './style.css';
import 'src/styles/cursors.css';
import 'src/styles/corner-ribbons.css';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import PageNotFound from "./components/scenes/PageNotFound";


Bugsnag.start({
    apiKey: 'b1dc5da5b7e36a981e342a206520e367',
    plugins: [new BugsnagPluginReact()],
    appVersion: '1.0.0',
});

// onError: function (event) {
//   event.addMetadata('version', {
//     id: "1.0.0",
//     name: "Version 1.0.0"
//   })
// }

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={PageNotFound}>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
