import React, {createRef, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core/styles";
import {blue, blueGrey, red} from "@material-ui/core/colors";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {HostDashboardRoutePrams} from "src/components/host/host-dashboard/host-dashboard";
import clsx from 'clsx';
import {createStyles} from "@material-ui/styles";
import LinearStepper, {
	LinearStepperActionCallback,
	StepPage,
	StepperComponentGenerator
} from "src/components/host/host-dashboard/manage-listings/add-edit-venues/VerticalLinearStepper";
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";
import {useUpdateReducer} from "src/hooks/useUpdateReducer";
import {GoogleMap, Marker} from "@react-google-maps/api";
import styles from "src/GoogleMapStyles.json";
import markerIcon from "src/img/blue-marker2.png";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {geocodeByAddress, geocodeByPlaceId, getLatLng} from "react-google-places-autocomplete/build";
import {MainStyles} from "src/components/host/mobileStyles";

import {ReactComponent as AccommodationIcon} from "src/img/icons/ico-accomdation.svg";
import {ReactComponent as AccessibilityIcon} from "src/img/icons/ico-accessibility.svg";
import {ReactComponent as BarIcon} from "src/img/icons/ico-bar.svg";
import {ReactComponent as ConciergeIcon} from "src/img/icons/ico-concierge.svg";
import {ReactComponent as RestaurantsIcon} from "src/img/icons/ico-resturants.svg";
import {ReactComponent as PrinterIcon} from "src/img/icons/ico-printer.svg";
import {devLog, fetchField, formValidation, getVatPercentages, printAmenity} from "src/Helpers";
import {VenueAPI, VenueRequest} from "src/APIs/venue/VenueAPI";
import AlertSnackbar from "src/components/organisms/snackbar/AlertSnackBar";
import {CircularProgress} from "@material-ui/core";


const venues = [
	{label: 'Hotel 3-Star', value: '1'},
	{label: 'Hotel 4-Star', value: '2'},
	{label: 'Hotel 5-Star', value: '3'},
	{label: 'Events Venue', value: '4'},
	{label: 'Corporate Office', value: '5'},
	{label: 'Training Center', value: '6'},
	{label: 'Business Center/Hub', value: '7'},
	{label: 'Restaurants', value: '8'},
	{label: 'Other', value: '9'},
	{label: 'Office Spaces', value: '10'},
	{label: 'Business Setup', value: '11'},
];

const facilities = [
	{id: 'accommodation', label: 'Accommodation', image: (<AccommodationIcon style={MainStyles.detIcon}/>)},
	{id: 'accessibility', label: 'Accessibility', image: (<AccessibilityIcon style={MainStyles.detIcon}/>)},
	{id: 'bar', label: 'Bar', image: (<BarIcon style={MainStyles.detIcon}/>)},
	{id: 'concierge', label: 'Concierge', image: (<ConciergeIcon style={MainStyles.detIcon}/>)},
	{id: 'restaurant', label: 'Restaurant', image: (<RestaurantsIcon style={MainStyles.detIcon}/>)},
	{id: 'printer_scanner_fax', label: 'Printer/Scanner/Fax', image: (<PrinterIcon style={MainStyles.detIcon}/>)},
];


const useStyles = makeStyles(
	(theme: Theme) => createStyles(
		{
			root: {
				maxWidth: '80%',
			},
			media: {
				height: 0,
				paddingTop: '56.25%', // 16:9
			},
			expand: {
				transform: 'rotate(0deg)',
				marginLeft: 'auto',
				transition: theme.transitions.create('transform', {
					duration: theme.transitions.duration.shortest,
				}),
			},
			expandOpen: {
				transform: 'rotate(180deg)',
			},
			avatar: {
				backgroundColor: blueGrey[300],
			},
			count: {
				borderRadius: '40%',
				padding: '.2rem 0.5rem',
				fontSize: '.8rem',
				backgroundColor: blue[300],
				verticalAlign: 'middle',
			},
			title: {
				verticalAlign: 'middle',
			},
			iconButton: {
				marginLeft: 'auto',
				alignSelf: 'middle',

			},
			sectionTitle: {
				fontSize: 22,
				fontWeight: 600,
			},
			btn: {
				flex: 0,
				flexGrow: 0,
				textAlign: 'right' as const,
				color: 'black',
				fontSize: 17,
				fontWeight: 400,
				textDecorationLine: 'underline',
			},
		}
	)
)

export type EditVenueProps = {} & RouteComponentProps<HostDashboardRoutePrams>

EditVenue.key = 'edit-venue'
EditVenue.route = `/${EditVenue.key}/:id?`


export function EditVenue({history, match, match: {params: {id}}}: EditVenueProps) {

	const classes = useStyles();
	const [errors, setErrors, updateErrors] = useUpdateReducer<any>({});
	const [request, setRequest, updateRequest] = useUpdateReducer<VenueRequest>({});
	const [locationValue, setLocationValue] = useState<any>(null);
	const [neighbourhoods, setNeighbourhoods] = useState<any>(null);
	const [facilitiesOptions, setFacilitiesOptions] = useState<any[]>(facilities);
	const [formsRefs, setFormsRefs] = React.useState(Array(3).fill(null).map(() => createRef<HTMLFormElement>()));
	const snackbar = useRef<AlertSnackbar>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [vatPercentages, setVatPercentages] = useState("");
	const [isVatPercentageDisabled, setIsVatPercentageDisabled] = useState(true);
	const [isVatPercentageRadioYesNo, setIsVatPercentageRadioYesNo] = useState(false);

	useEffect(() => {
		fetchField('neighbourhoods', 'city', 'Dubai').then(setNeighbourhoods);
		setVatPercentages(getVatPercentages());
	}, [])

	useLayoutEffect(() => {
		if (id) {
			VenueAPI.fetchVenue(id).then(
				response => {
					if (response) {
						console.log({response})
						const {venue, facilities} = response
						// devLog("Response::",response)
						const location = {lat: venue.latitude, lng: venue.longitude}
						setRequest({...venue, facilities, location})

						const facilitiesIDs = facilitiesOptions.map((item) => item.id)
						const missingFacilitiesIDs = facilities.filter(item => !facilitiesIDs.includes(item));
						const missingFacilitiesObjects = missingFacilitiesIDs.map(
							(id) => {
								const amenity = printAmenity(id)
								if (amenity) return ({id, label: amenity.name, image: amenity.image})
								else return null;
							}
						).filter(item => Boolean(item))

						setFacilitiesOptions([...facilitiesOptions, ...missingFacilitiesObjects])


						if (location && (venue.address || venue.front_address)) {
							const address = venue.address ?? venue.front_address
							geocodeByAddress(address)
								.then(results => {
									// devLog(results)
									if (results && results[0]) setLocationValue({label: address, value: results[0]})
								})
								.catch(error => console.error(error));
						}

					}
				}
			).catch(error => {
				history.goBack();
			}).finally(() => {
				setLoading(false)
			})
		} else {
			setLoading(false)
		}

	}, [])


	// useStateLogger(errors, "Errors-State::")
	// useStateLogger(request, "Request-State::")
	// useStateLogger(neighbourhoods, "neighbourhoods-State::")

	const handleNextPage: LinearStepperActionCallback = (activeStep, setActiveStep) => {
		const fieldsIDs = [
			[
				{id: 'venue_type', type: ''},
				{id: 'venue_name', type: ''},
				{id: 'suite_floor', type: ''},
				{id: 'floor_number', type: ''},
				{id: 'building', type: ''},
				{id: 'zip_code', type: ''},
				{id: 'description', type: ''},
				{id: 'parking_instruction', type: ''},
				{id: 'website', type: ''},
			],
			[
				{id: 'director_name', type: 'name'},
				{id: 'director_email', type: 'email'},
				{id: 'director_phone', type: 'phone'},
				{id: 'booking_team_name', type: 'name'},
				{id: 'booking_team_email', type: 'email'},
				{id: 'booking_team_phone', type: 'phone'},
			],
			[
				{id: 'account_name', type: ''},
				{id: 'tax_percent', type: ''},
				{id: 'tax_percent_exception', type: ''},
				{id: 'trn', type: ''},
				{id: 'registered_address', type: ''},
			],

		]
		// devLog("ActiveStep", activeStep)
		// devLog("forms", formsRefs)
		if (formsRefs[activeStep]?.current) {
			const errorsIds: any[] = []
			const _request: any = {}
			const data = new FormData(formsRefs[activeStep].current!);
			// devLog("formData", data)

			fieldsIDs[activeStep].forEach(({id, type}) => {
				_request[id] = data.get(id)
				errorsIds.push({id, type})
			})

			if (activeStep === 0) {
				_request['facilities'] = data.getAll('facilities')
				errorsIds.push({id: 'facilities'})

				const temp = ['country', 'city', 'address', 'location']
				temp.forEach(id => _request[id] = request[id])
				errorsIds.push(...temp.map(e => ({id: e})))
				if (_request.city === 'Dubai') {
					_request['neighborhood'] = data.get('neighborhood')
					errorsIds.push({id: 'neighborhood'})
				}
			}

			if (activeStep === 2) {
				if (request.country !== 'United Arab Emirates') {
					errorsIds.splice(2, 2)
				} else {
					errorsIds.splice(2, 1)
				}
			}

			const _errors: any = {} //_ denotes local/private

			errorsIds.forEach(({id, type}) => {
				_errors[id] = formValidation(type ?? id, _request[id])
			})
			// devLog(_errors)
			Object.keys(_errors).forEach(key => {
				if (!_errors[key]) delete _errors[key];
			});
			setErrors(_errors)
			if (Object.keys(_errors).length > 0) {
				window.scrollTo(0, 0)
				snackbar.current?.setMessage("Re-Check Input Data")
				snackbar.current?.setSeverity("warning")
				snackbar.current?.open()
				return;
			}
			updateRequest(_request);
			setActiveStep(activeStep + 1)
		} else if (activeStep === pages.length - 1) {
			submitVenue()
		}

	}

	const getLatLngLocal = () => {
		if ((request.location && request.location?.lng && request.location?.lat)) {
			return {lat: parseFloat(request.location?.lat ?? 0), lng: parseFloat(request.location?.lng ?? 0)}
		} else return {lat: 25.1210779, lng: 55.2212647}
	}
	const fieldGenerator = ({id, type, placeholder, required, extraClassNames = ""}: any) => {
		return (
			<div className={clsx("col-12 pr-mobile-0 pl-mobile-0", extraClassNames)}>
				<input type={type} id={id} name={id} className="form-control"
				       placeholder={placeholder} defaultValue={request[id]}
				       required={required} />
				{(errors[id]) ? (
					<div className="form-error">{errors[id]}</div>) : (<></>)}
			</div>
		)
	}

	const createBasicInfoPage: StepperComponentGenerator = (activeStep, setActiveStep) => {
		// devLog("createBasicInfoPage")
		return (
			<div className="">

				<div className="form-general p-0">
					<form className="row mr-0 ml-0 mt-5" autoComplete="on" onSubmit={() => {
					}} ref={formsRefs[0]}>


						<div className="col-md-6 col-12 pl-mobile-0 pr-mobile-0">
							<div className="selector">
								<select className="select" name="venue_type" id="venue_type"
								        defaultValue={request['venue_type']}>
									<option value="">Venue Type</option>
									{venues.map(({label, value}) => <option value={value}
									                                        selected={request['venue_type'] === value}>{label}</option>)}
								</select>
							</div>
							{(errors['venue_type']) ? (
								<div className="form-error">{errors['venue_type']}</div>) : (<></>)}
						</div>


						<div className="col-md-6 col-12 pl-mobile-0 pr-mobile-0">
							<input type="name" id="venue_name" name="venue_name" className="form-control"
							       placeholder="Venue Name" defaultValue={request['venue_name']} required
							/>
							{(errors['venue_name']) ? (
								<div className="form-error">{errors['venue_name']}</div>) : (<></>)}
						</div>


						<div className="col-12 pr-mobile-0 pl-mobile-0 ">
							{/*<script*/}
							{/*	type="text/javascript"*/}
							{/*	src={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsApiKey}&libraries=places`}*/}
							{/*/>*/}
							<div className={clsx("h6 my-2")}>Google Maps Location</div>
							<div>
								<GooglePlacesAutocomplete
									// apiKey={GoogleMapsApiKey} Added in index.html
									selectProps={
										{
											styles: {
												control: (provided: any) => ({
													...provided,
													marginBottom: 0,
													border: "1px solid #CFD8EA !important",
													backgroundColor: "#f9f9f9",
													padding: "8px",
													borderRadius: "16px",
													fontSize: "16px",
													height: 'auto',
													boxSizing: 'border-box'
												})
											},
											value: locationValue,
											onChange: async (value: any) => {
												// devLog(value)
												setLocationValue(value)
												let results = await geocodeByPlaceId(value.value.place_id)
												devLog("First Then::", results)
												const {country, region} = getCountryAndRegion(results[0]?.address_components)
												const {lat, lng} = await getLatLng(results[0])

												updateRequest({
													address: value.label,
													location: {lat, lng},
													country,
													city: region,
												})


											},

										}
									}

									onLoadFailed={(error) => {
										// console.error("Could not inject Google script", error)
									}
									}
								/>
							</div>
							{(errors['address']) ? (
								<div className="form-error mt-2">{errors['address']}</div>) : (<></>)}
						</div>

						<div className="map-cont col-12  pr-mobile-0 pl-mobile-0 mb-4 ">
							{/*	<LoadScript googleMapsApiKey={GoogleMapsApiKey}>*/}
							<GoogleMap
								options={{
									styles: styles,
									keyboardShortcuts: false,
									scaleControl: true,
									draggable: true,
									gestureHandling: "greedy",
									mapTypeControl: true,
									mapTypeControlOptions: {position: 1},
									zoomControl: true,
									zoomControlOptions: {position: 8},
								}}
								mapContainerStyle={{height: "100%", width: "100%"}}
								zoom={Boolean(request.location) ? 12 : 1}
								center={getLatLngLocal()}>
								{Boolean(request.location) &&
								<Marker position={getLatLngLocal()}
								        icon={markerIcon}/>}
							</GoogleMap>

							{/*</LoadScript>*/}
						</div>

						<div className="col-md-6 col-12  pr-mobile-0 pl-mobile-0">
							<div className="selector">
								<CountryDropdown classes="select" value={request['country']}
								                 onChange={(val) => updateRequest({country: val})}/>
							</div>
							{(errors['country']) ? (
								<div className="form-error">{errors['country']}</div>) : (<></>)}
						</div>
						<div className="col-md-6 d-none d-md-flex pr-mobile-0 pl-mobile-0"/>

						<div className="col-md-6 col-12 pr-mobile-0 pl-mobile-0">
							<div className="selector">
								<RegionDropdown classes="select" disableWhenEmpty={true}
								                country={request['country']} value={request['city']}
								                onChange={(val) => updateRequest({city: val})}/>
							</div>
							{(errors['city']) ? (
								<div className="form-error">{errors['city']}</div>) : (<></>)}
						</div>


						{/*{request.city === 'Dubai' && (*/}
						<div
							className={clsx("col-md-6 d-md-flex pr-mobile-0 pl-mobile-0", {'d-none': request.city !== 'Dubai'})}
							style={{
								visibility: (request.city === 'Dubai') ? 'visible' : 'hidden',
							}}>
							<div className="selector">
								<select className="select" name="neighborhood" id="neighborhood"
								        aria-required={"true"}>
									<option value="">Select Neighborhood</option>
									{
										neighbourhoods &&
										neighbourhoods.map(({name, value}: any) => (
											<option value={value}
											        selected={request['neighborhood'] === value}>{name}</option>))
									}
								</select>
							</div>
							{(errors['neighborhood']) ? (
								<div className="form-error">{errors['neighborhood']}</div>) : (<></>)}
						</div>
						{/*)}*/}

						{
							[
								{id: 'suite_floor', type: 'text', placeholder: "Suite", required: true},
								{id: 'floor_number', type: 'text', placeholder: "Floor", required: true},
								{id: 'building', type: 'text', placeholder: "Building", required: true},
								{id: 'zip_code', type: 'text', placeholder: "Zip/Postal", required: true},
								{
									id: 'description',
									type: 'text/multi-line',
									placeholder: "Venue Description",
									required: true
								},
								{
									id: 'parking_instruction',
									type: 'text/multi-line',
									placeholder: "Parking",
									required: true
								},
								{id: 'website', type: 'url', placeholder: "Website", required: true},

							].map(
								({id, type, placeholder, required}) => {
									if (type === 'text/multi-line') {
										return (
											<div className="col-12 pr-mobile-0 pl-mobile-0">
												<textarea id={id} name={id} className="form-control"
												          placeholder={placeholder} defaultValue={request[id]}
												          required={required}
												/>
												{(errors[id]) ? (
													<div className="form-error">{errors[id]}</div>) : (<></>)}
											</div>
										)
									}
									return (
										<div className="col-md-6 col-12 pr-mobile-0 pl-mobile-0">
											<input type={type} id={id} name={id} className="form-control"
											       placeholder={placeholder} defaultValue={request[id]}
											       required={required}
											/>
											{(errors[id]) ? (
												<div className="form-error">{errors[id]}</div>) : (<></>)}
										</div>
									)
								}
							)
						}


						<div className={`col-12 row page-lg-title mt-4 mb-2 ${classes.title}`}>
							Facilities
						</div>
						<div className="col-12 pr-mobile-0 pl-mobile-0">
							<fieldset>
								{
									facilities.map(
										({id, label, image}: any) => (
											<div className="col-12 col-md-6 form-control d-flex align-items-center">
												<input type={'checkbox'} id={'facilities'} name={'facilities'}
												       value={id} className={clsx('mr-2')}
												       style={{width: 24, height: 24, borderRadius: '50%'}}
												       defaultChecked={request.facilities?.includes(id)}
												/>
												{image}
												<span>{label}</span>
											</div>

										)
									)
								}
							</fieldset>

							{(errors['facilities']) ? (
								<div className="form-error">{errors['facilities']}</div>) : (<></>)}
						</div>


						{/*<div className="col-12 mt-3 mb-4 pr-0 pl-0">*/}
						{/*	<button*/}
						{/*		className={"btn btn-lg btn-primary btn-block fl_btn save-btn align-start " + ((reqLoading) ? 'ld-btn' : '')}>*/}
						{/*		{(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch}*/}
						{/*		                                    spin={true}/> loading</>) : 'Save'}*/}
						{/*	</button>*/}
						{/*</div>*/}


					</form>
				</div>
			</div>

		)
	}

	const createContactsInfoPage: StepperComponentGenerator = (activeStep, setActiveStep) => {

		return (
			<>
				<form
					className={'d-flex mt-2'}
					ref={formsRefs[1]}
					key='ContactsForm'>

					<div className="col-md-6 col-12 pr-mobile-0 pl-mobile-0">
						<div className={`col-12 row h5 mt-4 mb-2 ${classes.title}`}>
							Director of Sales
						</div>
						{
							[
								{id: 'director_name', type: 'name', placeholder: "Contact Name", required: true},
								{id: 'director_email', type: 'email', placeholder: "Email Address", required: true},
								{id: 'director_phone', type: 'tel', placeholder: "Phone Number", required: true},
							].map(fieldGenerator)
						}
					</div>
					<div className="col-md-6 col-12 pr-mobile-0 pl-mobile-0">
						<div className={`col-12 row h5 mt-4 mb-2 ${classes.title}`}>
							Booking Team
						</div>
						{
							[
								{id: 'booking_team_name', type: 'name', placeholder: "Contact Name", required: true},
								{id: 'booking_team_email', type: 'email', placeholder: "Email Address", required: true},
								{id: 'booking_team_phone', type: 'tel', placeholder: "Phone Number", required: true},
							].map(fieldGenerator)
						}

					</div>


				</form>
			</>
		)
	}

	const checkVat = (country) => {
		const percentages = JSON.parse(vatPercentages)

		let vatCountry = percentages.filter(vat => {
			return vat.country === country;
		});

		return vatCountry.length >= 1 ? vatCountry[0].vat_percent : false;
	}

	const getVatValue = (country) => {
		const percentages = JSON.parse(vatPercentages)

		let vatCountry = percentages.filter(vat => {
			return vat.country === country;
		});
		if (id === undefined) {
			request.tax_percent = vatCountry.length >= 1 ? vatCountry[0].vat_percent : '';
			
		}
	}

	const taxPercentExceptionChange = (value) => {
		if (value === 'Yes') {
			request.tax_percent_exception = 'Yes';
			setIsVatPercentageDisabled(false);
		} else if(value === 'No') {
			request.tax_percent_exception = 'No';
			setIsVatPercentageDisabled(true);
		}
	}

	const setRadio = () => {
		if (request.tax_percent_exception === 'Yes') {
			setIsVatPercentageRadioYesNo(true);
		} else {
			setIsVatPercentageRadioYesNo(false);
		}
		taxPercentExceptionChange(request.tax_percent_exception);
	}

	const taxPercentChange = (e) => {
		request.tax_percent = e.target.value;
	};

	const createPaymentInfoPage: StepperComponentGenerator = (activeStep, setActiveStep) => {
		if(activeStep === 2) {
			getVatValue(request.country);
		}
		setRadio();
		
		return (
			<>
				<form
					className={"mt-5"}
					key='PaymentInfoForm'
					ref={formsRefs[2]}
				>
					<div className={clsx('h6')}>Account Name (on cheque)</div>
					{fieldGenerator({id: 'account_name', type: 'name', placeholder: 'Account Name', required: true})}


					<div className={clsx('h6')}>{request.country === 'United Arab Emirates' ? 'VAT' : 'Sales Tax'} % </div>
					<div className={clsx('pl-2')} style={{color:red[500]}}>***Important to add correct {request.country === 'United Arab Emirates' ? 'VAT' : 'Sales Tax'}, as this will impact transactions value. </div>
					
					{checkVat(request.country) ? (isVatPercentageRadioYesNo ? 
						(<div className={clsx("col-12 pr-mobile-0 pl-mobile-0")}>
							<input type={'number'} id={'tax_percent'} name={'tax_percent'} className="form-control"
							placeholder={'Sales Tax'} defaultValue={request.tax_percent}
							required={true} onChange={(e) => {
								taxPercentChange(e);
							}} />
							{(errors['tax_percent']) ? (
								<div className="form-error">{errors['tax_percent']}</div>) : (<></>)}
						</div>)
					: (<div className={clsx("col-12 pr-mobile-0 pl-mobile-0")}>
							<input type={'text'} id={'tax_percent'} name={'tax_percent'} className="form-control"
							placeholder={'Sales Tax'} value={checkVat(request.country)}
							required={true} readOnly={true} onChange={(e) => {
								taxPercentChange(e);
							}} />
							{(errors['tax_percent']) ? (
								<div className="form-error">{errors['tax_percent']}</div>) : (<></>)}
						</div>)
					)
					: fieldGenerator({id: 'tax_percent', type: 'number', placeholder: 'Sales Tax', required: true })}
					{checkVat(request.country) && (
						<>
							<div className={clsx('h6')}>Are you exempt from VAT?</div>
							<div className={clsx("col-12 pr-mobile-0 pl-mobile-0")}>
								<label className='mr-2'>
									<input type={'radio'} id={'tax_percent_exception'} name={'tax_percent_exception'} required={true} value={'Yes'} defaultChecked={isVatPercentageRadioYesNo}  onChange={() => {
										taxPercentExceptionChange('Yes');
									}}/> Yes
								</label>
								<label className='mr-2'>
									<input type={'radio'} id={'tax_percent_exception'} name={'tax_percent_exception'} required={true} value={'No'} defaultChecked={!isVatPercentageRadioYesNo} onChange={() => {
										taxPercentExceptionChange('No');
									}} /> No
								</label>
								{(errors['tax_percent_exception']) ? (
									<div className="form-error">{errors['tax_percent_exception']}</div>) : (<></>)}
							</div>
						</>
					)}

					{request.country === 'United Arab Emirates' && (
						<>
							<div className={clsx('h6')}>TRN Number</div>
							{fieldGenerator({id: 'trn', type: 'text', placeholder: 'TRN', required: true})}
						</>
					)}
					<div className={clsx('h5')}>Registered Company Address</div>
					<div className="col-12 pr-mobile-0 pl-mobile-0">
												<textarea id={'registered_address'} name={'registered_address'}
												          className="form-control"
												          placeholder={'Registered Address'}
												          defaultValue={request['registered_address']}
												          required={true}
												/>
						{(errors['registered_address']) ? (
							<div className="form-error">{errors['registered_address']}</div>) : (<></>)}
					</div>
				</form>
			</>
		)
	}

	const amenitiesTitleMapper = (array: any[]) => {
		array = array ?? []
		return array.map(
			(key) => {
				const amenity = printAmenity(key)
				return amenity?.name
			}
		).join(', ')
	}

	const createConfirmationPage: StepperComponentGenerator = (activeStep, setActiveStep) => {

		const basicInfoFields: any[] = [
			{
				label: 'Venue Type',
				id: 'venue_type',
				mapper: (value) => {
					let venueType = venues.find(x => x.value === value)
					return (venueType?.label ?? "Unknown")
				}
			},
			{label: 'Venue Name', id: 'venue_name',},
			{label: 'Address', id: 'address',},
			{label: 'Suite', id: 'suite_floor',},
			{label: 'Floor', id: 'floor_number',},
			{label: 'Building', id: 'building',},
			{label: 'Country', id: 'country',},
			{label: 'City', id: 'city',},
		]
		if (request.city === 'Dubai') basicInfoFields.push({
			label: 'Neighborhood', id: 'neighborhood', mapper: (value) => {
				let neighbourhood = neighbourhoods.find(x => x.value === value)
				return (neighbourhood?.name ?? "Unknown")
			}
		})
		basicInfoFields.push(
			{label: 'Zip/Postal', id: 'zip_code',},
			{label: 'Venue Description', id: 'description',},
			{label: 'Parking', id: 'parking_instruction',},
			{label: 'Website', id: 'website',},
			{label: 'Facilities', id: 'facilities', mapper: amenitiesTitleMapper}
		)

		const accountInfoFields = [
			{label: 'Account Name (on cheque)', id: 'account_name',},
			{label: (request.country === 'United Arab Emirates') ? 'VAT' : 'Sales Tax %', id: 'tax_percent',},
		]
		if (request.country === 'United Arab Emirates') accountInfoFields.push({label: 'TRN Number', id: 'trn',})
		accountInfoFields.push({label: 'Registered Company Address', id: 'registered_address',})

		const sectionTitle = (title: string) => (
			<div className={clsx('h4 mt-5 mb-3')}>{title}</div>
		)

		const section = (array: any[]) => (
			<div className={clsx('d-flex flex-row flex-wrap form-control')}>
				{
					array.map(
						({label, id, mapper}: any) => {
							return (
								<div className={clsx("col-12 d-flex flex-row pr-mobile-0 pl-mobile-0 my-1",)}>
									<div className={clsx('col-4 h5 text-muted ')}>{label}</div>
									<div className={clsx('col-12 h6')}>{
										(request?.[id]) && (mapper) ? mapper(request[id]) : request[id]
									}</div>
								</div>
							)
						}
					)
				}
			</div>
		)
		return (
			<div>


				<div key='ConfirmationForm'>


					{sectionTitle("Basic Info")}
					{section(basicInfoFields)}


					{sectionTitle("Director of Sales")}
					{
						section(
							[
								{label: 'Contact Name', id: 'director_name',},
								{label: 'Email Address', id: 'director_email',},
								{label: 'Phone Number', id: 'director_phone',}
							]
						)
					}

					{sectionTitle("Booking Team")}
					{
						section(
							[
								{label: 'Contact Name', id: 'booking_team_name',},
								{label: 'Email Address', id: 'booking_team_email',},
								{label: 'Phone Number', id: 'booking_team_phone',}
							]
						)
					}


					{sectionTitle("Account Info")}
					{section(accountInfoFields)}


				</div>

			</div>
		)
	}

	const pages = [
		{key: 'basic-info', label: 'Basic Info', page: createBasicInfoPage} as StepPage,
		{key: 'contact-info', label: 'Contact Info', page: createContactsInfoPage} as StepPage,
		{key: 'payment-info', label: 'Payment Info', page: createPaymentInfoPage} as StepPage,
		{key: 'confirmation', label: 'Confirmation', page: createConfirmationPage} as StepPage,
	]

	const submitVenue = () => {

		VenueAPI.updateVenue(request, id).then((response) => {
			snackbar.current?.setDataFromMessageObject(response)
			snackbar.current?.open()
			devLog(response)

		}).finally(
			() => {
				setTimeout(() => {
					history.goBack();
				}, 3000)
			}
		)
	}

	return (
		<>
			<div className="Edit Venue">
				<div className="container pr-0 pl-0" style={{marginTop: 120}}>
					<div className="page">

						<div className={`row page-lg-title mt-4 mb-5 ${classes.title}`}>
							{(Boolean(id)) ? "Edit" : "Add"} Venue
						</div>
						{(loading) ? (
							<div className={clsx('d-flex col-12 justify-content-center')}>
								<CircularProgress className={clsx('mx-auto my-5')}/>
							</div>
						) : (
							<LinearStepper
								steps={pages}
								footer={'default'}
								onDefaultFooterNext={handleNextPage}
							/>)
						}
					</div>
				</div>
			</div>
			<AlertSnackbar ref={snackbar}/>
		</>
	);
}

//@ts-ignore
export const EditVenueEnhanced = withRouter(EditVenue);

type AddressComponent = {
	short_name: string;
	long_name: string;
	types: string[];
}

function getCountryAndRegion(address_components: AddressComponent[]) {
	const countryIndex = address_components.findIndex((item) => item.types.includes('country'))
	const country = (countryIndex === -1) ? '' : address_components[countryIndex].long_name;

	const regionIndex = address_components.findIndex((item) => item.types.includes('administrative_area_level_1'))
	const region = (regionIndex === -1) ? '' : address_components[regionIndex].long_name;

	return {country, region}
}
