import React, {useLayoutEffect} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import NavBar from "src/components/atoms/NavBar";
import {devLog, getApiLinkV2, getToken} from "src/Helpers";
import axios from "axios";
import {getAxios} from "src/Helpers";
import Bugsnag from "@bugsnag/js";
import ReactGA from "react-ga-neo";
import {Link, useHistory, useParams} from "react-router-dom";
import {useQuery} from "src/hooks/useQuery";
import {Helmet} from 'react-helmet';

export type PaymentVerificationRouteParams = {
	booking_type: 'st' | 'ddr' | 'lt',
	workspace_type, //textKey of space-types ['banquets-hall','office-space','meeting-room','co-working-space']
	workspace_id,
	payment_id?,
	event: 'verify' | 'success' | 'error',
}

// '/verification/short-term/office/348/verify/OID-1642767197-35815-92518'
// '/verification/ddr/banquets-hall/348/success/OID-1642767197-35815-92518'
PaymentVerification.route = '/verification/:booking_type/:workspace_type/:workspace_id/:event/:payment_id?' // ?proposal_id

PaymentVerification.generatePath = ({booking_type, workspace_type, workspace_id, event, payment_id}: PaymentVerificationRouteParams, quote_id?) => {
	return (
		`/verification/${booking_type}/${workspace_type}/${workspace_id}/${event}` + ((payment_id) ? ('/' + payment_id) : "") + ((quote_id) ? `?proposal_id=${quote_id}` : '')
	);
}
PaymentVerification.view = (history, routeParams: PaymentVerificationRouteParams, quote_id?) => {
	history.push(PaymentVerification.generatePath(routeParams, quote_id));
}


export function PaymentVerification(props) {
	let params = useParams<PaymentVerificationRouteParams>()
	let {booking_type, workspace_type, workspace_id, event, payment_id} = params;
	let history = useHistory();
	let query = useQuery();
	let quote_id = query.get('proposal_id')
	// const space = getSpaceBooking();
	// const proposal = getProposalBooking();
	// const order_id = (space) ? space.order_id : proposal.order_id;
	console.log(history.location.pathname)
	useLayoutEffect(() => {
		console.log(booking_type, workspace_type);

		if (event === 'verify') {

			const token = getToken();
			if (!token) return;

			const url = getApiLinkV2('workspace/booking/' + params.payment_id);

			getAxios().get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token,
				},
			}).then(response => {
				devLog(response);
				if(booking_type == 'ddr' && workspace_type == 'banquets-hall') {
					ReactGA.event({
						category: 'DDR',
						action: 'DDR_Booking_Success_Unique',
						label: 'Catered Booking Success for quote ' + quote_id
					});
				}

				if (workspace_type === 'booking') {
					ReactGA.event({
						category: 'ST',
						action: 'ST_Success',
						label: workspace_type + ' ST Booking Success for space ' + workspace_id
					});

				} else if (workspace_type === 'proposal') {
					ReactGA.event({
						category: 'DDR',
						action: 'DDR_Booking_Success_Unique',
						label: 'Catered Booking Success for quote ' + quote_id
					});

				}

				PaymentVerification.view(history, {...params, event: 'success',}, quote_id)
			}).catch(error => {
				Bugsnag.notify(error);
				devLog(error);
				PaymentVerification.view(history, {...params, event: 'error'}, quote_id)
			});
		}


		//ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);


	// if (!(booking_type && workspace_type && workspace_id && order_id && event)) {
	// 	devLog("redirect-error")
	// 	return <Redirect to={'/error'}/>
	// }
	const spaceLink = `/space/${workspace_id}?t=hourly`
	return (
		<>
			{booking_type == 'ddr' && workspace_type == 'banquets-hall' && 
				<Helmet>
					
				{/* Event snippet for Banquet Halls DDR - Online Booking Success conversion page */}
					<script>
					{`
						window.gtag('event', 'conversion', {
							'send_to': 'AW-935885959/zSTDCIaxt_YCEIf5ob4D',
							'transaction_id': ${payment_id}
						});
					`}
					</script>

				</Helmet>
			}

			<NavBar backLink={spaceLink} smallLogo={true} smallNav={true} hideSearch={true} hideHostBtn={true}
			        pageTitle="Request to book space"/>

			<div className="container-fluid bg-white h-z">
				<div className="container-lg h-z">
					<div className="booking-page h-z">

						<h1 className="full-title h-z">
							<Link to={spaceLink}>
								<FontAwesomeIcon icon={faArrowLeft}
								                 style={{
									                 color: '#000000',
									                 fontSize: 20,
									                 cursor: 'pointer',
									                 marginRight: 24,
									                 marginBottom: 4
								                 }}/>
							</Link>
							Request to book space
						</h1>

						<div className="space-container">
							<div className="space-content h-z">
								<div className="form-general p-0">
									<div className="mt-4 form-flex">
										<div className="form-content">
											{(event === 'verify') ? (
												<>
													<div className="space-about pt-4 pb-2 border-0">
														<h3 className="pb-1">Verifying</h3>
														<h6>We are verifying your payment. It may take a while.</h6>
													</div>
													<button className="btn btn-lg mb-5">
														<FontAwesomeIcon icon={faCircleNotch} spin={true}/> loading
													</button>
												</>
											) : (event === 'success' || event === 'error') ? (
													<>
														{
															(event === 'success') ? (
																<div className="space-about pt-4 border-0">
																	<h3 className="pb-1">Payment received</h3>
																	<h6>Thank you. We have received your payment
																		successfully.</h6>
																</div>
															) : (event === 'error') ? (
																<div className="space-about pt-4 border-0">
																	<h3 className="pb-1">Error</h3>
																	<h6>Error while processing your payment.</h6>
																</div>

															) : (<></>)
														}
														{
															(booking_type === 'st') ?
																(
																	<Link to={spaceLink}>
																		<button
																			className="btn btn-lg btn-primary submit-btn mb-5">Back
																			to space
																		</button>
																	</Link>
																)
																:
																(booking_type === 'ddr' && Boolean(quote_id)) ? (
																		<Link to={'/reservation/proposal/' + quote_id}>
																			<button
																				className="btn btn-lg btn-primary submit-btn mb-5">Back
																				to reservation
																			</button>
																		</Link>
																	)
																	:
																	(
																		<></>
																	)
														}
													</>
												)
												:
												(
													<></>
												)
											}
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>

		</>
	);

}
