import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { HostDashboardRoutePrams } from "../host-dashboard";
import EventCalendar from "./event-calendar";
import {makeStyles, Theme} from "@material-ui/core/styles";
import clsx from 'clsx';
import { useDispatch, useSelector } from "react-redux";
import { refreshVenues } from "src/redux/VenuesSlice";
import { HostAPI } from "src/APIs/HostAPI";

const useStyles = makeStyles(
    (theme: Theme) => (
        {
            root: {
                maxWidth: '100%',
            },
            sectionTitle: {
                fontSize: 22,
                fontWeight: 600,
                marginBottom: '20px'
            }
        }
    )
)

export type ManageBookingsProps = {} & RouteComponentProps<HostDashboardRoutePrams>

ManageBookings.key = 'manage-bookings';
ManageBookings.route = `/${ManageBookings.key}`;

export function ManageBookings({})
{
    const dispatch = useDispatch();
    const venuesList = useSelector((state: any) => state.venues.data);
    const classes = useStyles();

    const [venue, setVenue] = useState<any>({});
    const [venues, setVenues] = useState<any>([]);
    const [venueId, setVenueId] = useState<any>('');
    const [wsId, setWsId] = useState<any>('');
    const [bookings, setBookings] = useState<any>([]);
    const [spaceImg, setSpaceImg] = useState<any>('');

    const handleVeneueChange = (e: any) => {
        let venueId = e.target.value;
        let venue = venuesList.filter(item => item.venue_details.venue_details_id === venueId);
        console.log(venue);
        if (venue.length > 0) {
            setVenueId(venueId);
            setVenue(venue[0]);
            setWsId('');
        } else {
            setVenueId('');
            setVenue({});
            setWsId('');
        }
    };

    const handleWorkSpaceChange = async (e: any) => {
        let wsId = e.target.value;
        if(wsId === '') {
            setSpaceImg('')
            return;
        }
        let ws = venue.workspaces.filter((item) => item.workspace_id === wsId);
        if(ws.length > 0) {
            setSpaceImg(ws[0].image);
        }
        let response = await HostAPI.fetchReservationByWorkspace(wsId);
        setBookings(response);
        setWsId(wsId);
    };

    useEffect(() => {
        HostAPI.fetchEnableVenuesForManualBookings().then((data) => setVenues(data));
        dispatch(refreshVenues())
    }, []);

    return (
        <div className="Manage-Bookings">
            <div className={clsx(classes.root)}>
                <div className={clsx(classes.sectionTitle)}>
                    Bookings
                    {venues.length === 0 && 
                        <span style={{color:'red', fontStyle:'italic', fontSize: '14px'}}>
                            &nbsp; This feature is currently unavailable to you. For assistance, please contact our support team. Thank you for understanding.
                        </span>
                    }
                </div>
                <div className="row form-general mb-2">
                    {venues && 
                        <div className="col-3">
                            <div className="selector mb-0">
                                <select name="start_time" id="start_time" className="select" onChange={(e) => handleVeneueChange(e)}>
                                    <option value="">Select Venue</option>
                                    {venues && venues.map((item, key) => (
                                        <option key={key} value={item.venue_id}>{item.venue_name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    }
                    <div className="col-3">
                        <div className="selector mb-0">
                            <select name="start_time" id="start_time" className="select" onChange={(e) => handleWorkSpaceChange(e)}>
                                <option value="">Select Workspace</option>
                                {venue.workspaces && venue.workspaces.map((item, key) => {
                                    if (item.price_category !== 'monthly') {
                                        return (
                                            <option key={key} value={item.workspace_id}>{item.ws_name}</option>
                                        )
                                    }
                                }
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="col-6"></div>
                </div>
                <EventCalendar bookings={bookings} spaceId={wsId} venueId={venueId} spaceImg={spaceImg} />
            </div>
        </div>
    );
}

export const ManageBookingsEnhanced = (ManageBookings);