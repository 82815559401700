import React, {FormEvent, FormEventHandler, useEffect, useRef, useState} from 'react';
import {ReservationsAPI} from "src/APIs/reservations/reservations-api";
import {Card, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, makeStyles} from "@material-ui/core";
import {blue, red} from "@material-ui/core/colors";
import {Pagination} from '@material-ui/lab';
import clsx from 'clsx';
import {
	devLog,
	getApiLinkV2,
	getAxios,
	getCurrency,
	getToken,
	getUserData,
	printDateTimeFromServer,
	printHostBookingStatus,
	printPrice,
	printTimeRange,
} from "src/Helpers";
import {UpcomingSVG} from "src/img/svg-exports";
import moment from "moment";
import Button from "@material-ui/core/Button";
import {Link, useHistory, useLocation} from "react-router-dom";
import Bugsnag from "@bugsnag/js";
import axios from 'axios';
import {capitalizeFirst, getHostProposalActive} from "src/components/host/host-helpers";
import {useUpdateReducer} from "src/hooks/useUpdateReducer";
import {
	FMBooleanInput,
	FMMultilineTextInput,
	FMOptionsInput,
	FMSelectInput,
	FMTextInput
} from "src/components/host/forms/fields";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import CloseIcon from '@material-ui/icons/Close';
import AlertSnackbar from "src/components/organisms/snackbar/AlertSnackBar";
import Tooltip from "@material-ui/core/Tooltip";
import ProposalDetails, {DDR_BOOKING_STATUSES} from "src/components/host/host-dashboard/manage-reservations/reservations-details/ProposalDetails";
import ShortTermDetails
	from "src/components/host/host-dashboard/manage-reservations/reservations-details/ST-booking-details";
import LongTermDetails
	from "src/components/host/host-dashboard/manage-reservations/reservations-details/LT-booking-details";
import {useQuery} from "src/hooks/useQuery";
import {FILTER_LIST, OTHER_DATES, DATE_FILTER_LIST} from "src/components/host/host-dashboard/manage-reservations/consts";
import {ReservationsFilter} from "src/components/host/host-dashboard/manage-reservations/components/reservations-filter";
import LeadUtilities from "src/utils/lead-utils";
import {DdrUtils} from "src/utils/ddr-utils";


const ReservationLink = (props: ({ reservation, children, onClick? } & any)) => {
	let {reservation, children, onClick} = props;
	let extraProps = {...props}
	delete extraProps.reservation
	delete extraProps.children
	delete extraProps.onClick

	let link: any = '#';
	switch (reservation.reservation_type) {
		case'proposal':
			link = ProposalDetails.generateViewProposalLink(reservation.quote_id)
			break;
		case'lead':
			link = LongTermDetails.generateViewLink(reservation.unique_id);
			break;
		case'booking':
			link = ShortTermDetails.generateViewLink(reservation.reservation_id);
			break;

	}


	//
	// if(reservation.reservation_type === 'lead' && reservation.host_accepted != '1'){
	// 	link = null
	// }

	if (link) {
		return <Link to={link} onClickCapture={(e) => {
			devLog("Link Clicked", link, e);
			onClick?.(e)
		}} {...extraProps}>
			{children}
		</Link>
	} else {
		return <>{children}</>
	}
}

const styles = {
	scrollView: {
		backgroundColor: 'white',
	},
	baseView: {
		display: 'none' as const,
		zIndex: -1,
		backgroundColor: 'rgba(0, 0, 0, 0.3)',
	},
	baseViewAct: {
		flex: 1,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 2,
		backgroundColor: 'rgba(0, 0, 0, 0.3)',
	},
	content: {
		flex: 1,
	},
	container: {
		backgroundColor: 'white',
		flex: 1,
	},
	header: {
		backgroundColor: 'white',
		paddingLeft: 24,
		paddingRight: 24,
	},
	divider: {
		flex: 1,
		height: 0.5,
		marginLeft: 120,
		marginRight: 20,
		backgroundColor: '#CFD8E7',
	},
	hostdivider: {
		flex: 1,
		height: 0.5,
		marginLeft: 20,
		marginRight: 20,
		backgroundColor: '#CFD8E7',
	},
	tab: {
		backgroundColor: 'white',
	},
	reservation: {
		flex: 1,
		paddingHorizontal: 20,
		paddingVertical: 12,
		flexDirection: 'row' as const,
	},
	reservimg: {
		height: 94,
		width: '30%',
		maxWidth: 100,
		flex: 0,
		resizeMode: 'cover' as const,
		borderRadius: 10,
		backgroundColor: '#E5E7E9',
	},
	generalimg: {
		resizeMode: 'contain' as const,
		marginTop: 'auto',
		marginBottom: 'auto',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	reservdetails: {
		paddingLeft: 16,
		overflow: 'hidden' as const,
	},
	reservTitle: {
		color: '#212529',
		paddingBottom: 3,
		paddingRight: 50,
		overflow: 'hidden' as const,
		fontWeight: 600,
		fontSize: 17,
	},
	reservText: {
		color: '#212529',
		paddingBottom: 3,
		overflow: 'hidden' as const,
		fontSize: 16,
	},
	reservprice: {
		right: 20,
		top: 10,
		bottom: 8,
	},
	reservTopText: {
		fontWeight: 500,
		fontSize: 17,
		marginTop: 2,
		marginLeft: 10,
		textAlign: 'right' as const,
	},
	reservPriceText: {
		fontWeight: 700,
		fontSize: 18,
		marginLeft: 10,
		textAlign: 'right' as const,
	},
	ratingContainer: {
		flexGrow: 0,
		flexShrink: 0,
		flexDirection: 'column' as const,
		alignItems: "center" as const,
		justifyContent: "center" as const,
	},
	starContainer: {
		flexDirection: "row" as const,
		alignItems: "center" as const,
		justifyContent: "center" as const,
	},
	subtitle: {
		flex: 1,
		fontSize: 16,
		fontWeight: 500,
		marginTop: 1,
		marginBottom: 2,
		color: '#4f555b',
	},
};
const useStyles = makeStyles((theme) => (
		{
			root: {
				maxWidth: '1280px',
				marginRight: "auto",
				marginLeft: "auto",
			},
			count: {
				borderRadius: '40%',
				padding: '.2rem 0.5rem',
				fontSize: '.8rem',
				backgroundColor: blue[300],
				verticalAlign: 'middle',
			},
			pagination: {
				'& ul': {
					justifyContent: 'center',
				}
			}
		}
	)
)

export type ManageReservationsProps = {}
export type ReservationAction =
	'accept-proposal'
	| 'reject-proposal'
	| 'accept-lead'
	| 'reject-lead'
	| 'accept-booking'
	| 'reject-booking'
	| null;
ManageReservations.key = 'manage-reservations'
export default function ManageReservations({}: ManageReservationsProps) {
	const cd = new Date().getFullYear()+'-'+((new Date().getMonth() + 1) < 10 ? '0'+(new Date().getMonth() + 1) : (new Date().getMonth() + 1)) + '-' + (new Date().getDate() < 10 ? '0'+new Date().getDate() : new Date().getDate());
	console.log(cd);
	const history = useHistory();
	const location = useLocation();
	const query = useQuery();
	const [data, setData, updateData] = useUpdateReducer<any>({})
	const [filter, setFilter] = useState<string>(query.get('filter') ?? FILTER_LIST[0].value)
	const [dateFilter, setDateFilter] = useState<string>(query.get('date') ?? DATE_FILTER_LIST[0].value)
	const [startDateFilter, setStartDateFilter] = useState<string>(query.get('startDate') ?? cd)
	const [endDateFilter, setEndDateFilter] = useState<string>(query.get('endDate') ?? cd)
	const [search, setSearch] = useState<string>(query.get('search') ?? '')
	const [page, setPage] = useState<number>(parseInt(query.get('page') ?? '1'))
	const [loading, setLoading] = useState<boolean>(true)
	const [requestLoading, setRequestLoading] = useState<boolean>(false)
	const snackbar = useRef<AlertSnackbar>(null);
	const [request, setRequest, updateRequest] = useUpdateReducer<any>({})

	const [currency, setCurrency] = useState<any>(getCurrency())
	const [showMore, setShowMore] = useState<string | null>(null)
	// const [showMoreReqLoading, setShowMoreReqLoading] = useState<boolean>(false)
	const [moreResrv, setMoreResrv] = useState<any>(null)

	const classes = useStyles();
	const clearActionFromQuery = () => {
		query.delete('action')
		query.delete('id')
		history.push(location.pathname + '?' + query.toString())
	}
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [page])

	useEffect(() => {
		setCurrency(getCurrency())
		window.addEventListener('storage', () => {
			setCurrency(getCurrency())
			devLog('storage', getCurrency())
		});
		let event: ReservationAction = query.get('action') as ReservationAction;
		let id = query.get('id');
		(
			async () => {
				let _data = (await fetchReservations());

				if (event && id && _data && Object.keys(_data).length > 0) {
					let [action, reservation_type, action_type] = event.split('-')
					let item: any = null;
					switch (reservation_type) {
						case "proposal":
							item = _data['proposals'].find(x => x.quote_id === id);
							// console.log(event,!(item.quote_status === 'pending' && item.amount_due <= 0 && item.edit_perm),item['amount_due'],action === 'accept',item)
							// todo very weird behaviour item.mount_due is -1 when you dispaly the object but is zero when you access the property
							if (getHostProposalActive(item)) {
								handleShowDetails(item)
								item = undefined;
							}
							if (!(item?.quote_status === 'pending' && item?.amount_due <= 0 && item?.edit_perm)) {
								item = undefined;
							} else {
								if (action === 'reject' && item?.amount_due != 0) {
									item = undefined;
								}
								if (action === 'accept') {
									clearActionFromQuery()
									ProposalDetails.priceProposal(history, item?.quote_id);
									item = undefined;
								}
							}

							break;
						case "booking":
							item = _data['bookings'].find(x => x.reservation_id === id);
							if ((item.status === 'approved' || item.status === 'completed')) {
								handleShowDetails(item)
								item = undefined;
							}
							if (!(item.status === 'pending' && item.edit_perm)) item = undefined;
							break;
						case "lead":
							if (action_type === 'lead' || action_type === 'inquiry') {
								item = _data['leads'].find(x => x.request_id === id);
								if ((LeadUtilities.getHostLeadStatus(item) !== 'pending' && LeadUtilities.getHostLeadStatus(item) !== 'rejected')) {
									handleShowDetails(item)
									item = undefined;
								}
								if (!(LeadUtilities.getHostLeadStatus(item) === 'pending' && item.edit_perm)) item = undefined;

							}
							break;
					}
					if (item) {
						devLog("Action Here***", event, item)

						if (action === 'reject')
							toggleShowMore(action, item);
						if (action === 'accept')
							handleAccept(item);
					}

				}

				setLoading(false)
			}
		)();

	}, [])

	const fetchReservations = async () => {
		try {
			let _reservations = (await ReservationsAPI.fetchReservations())
			setData(_reservations)
			console.log({_reservations})
			return _reservations;
		} catch (error: any) {
			showErrorMessage(error.message)
			setData({})
		}

	}

	const handleMoreSubmit: FormEventHandler<HTMLFormElement> = (e) => {
		e.preventDefault()
		const data = new FormData(e.target as HTMLFormElement);
		let _request = Object.fromEntries(
			[
				{key: 'dates_chk'},
				{key: 'reject_other'},
				{key: 'reject_note'},
				{key: 'available_dates', array: true}
			].map(
				({key, array}) => (
					[key, (array) ? data.getAll(key) : data.get(key)]
				)
			)
		)
		devLog("MoreSubmit", _request)
		handleSubmit(showMore, moreResrv, _request);
	}

	const handleMoreFormChanged = () => {

		const data = new FormData(document.getElementById('proposal-form') as HTMLFormElement);
		let _request = Object.fromEntries(
			[{key: 'dates_chk'}, {key: 'available_dates', array: true}].map(
				({key, array}) => (
					[key, (array) ? data.getAll(key) : data.get(key)]
				)
			)
		)

		updateRequest(_request)

	}
	const showSuccessMessage = (message,title?) => {
		snackbar.current?.setAlertData(
			{
				severity: 'success' as const,
				message: message,
				title: title,
				timeout: 7000,
			}
		)
		snackbar.current?.open()
	}
	const showErrorMessage = (message: string, title?: string) => {
		snackbar.current?.setAlertData(
			{
				severity: 'error' as const,
				message: message,
				title: title,
				timeout: 7000,
			}
		)
		snackbar.current?.open()
	}
	const handleSubmit = (showMoreType: any, moreReservation: any, _request: any) => {

		const showMoreFinal = showMoreType ? showMoreType : showMore;
		const reservation = moreReservation ? moreReservation : moreResrv;

		let token = getToken()

		if (!token) return;
		setRequestLoading(true);

		const url = getApiLinkV2("host/reservations/" + reservation.reservation_id);
		const reqData = {
			reservation_type: reservation.reservation_type,
			option: showMoreFinal,
			venue_id: reservation?.venue_id ?? '',
			token: reservation.token,
			lead_type: reservation.lead_type,
			lead_id: reservation.request_id,
			available: _request.dates_chk ? 'yes' : 'no',
			alternate_day: _request.available_dates ? _request.available_dates : [],
			reject_other_options: _request.reject_other ? _request.reject_other : '',
			reject_note: _request.reject_note ? _request.reject_note : '',
		}
		devLog({reqData})
		getAxios().put(url, reqData, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token,
			},
		}).then(response => {
			fetchReservations();
			devLog("handleSubmit ", {response, showMoreFinal, reservation})
			if (response.data.status === 'success') {
				showSuccessMessage(response.data.message);
				if (showMoreFinal === 'accept') handleShowDetails(reservation);
			}
		}).catch(error => {
			devLog("handleSubmit ", {error})
			Bugsnag.notify(error);
			devLog({error});
			showErrorMessage(error.message ?? error.response.message ?? "Unknown Error", 'Error')
		}).then(() => {

			request.dates_chk = false;
			request.available_dates = null;
			setRequestLoading(false);
			setRequest(request);

			if (showMore) toggleShowMore();
		});

	}

	const createFilter = () => {
		return <ReservationsFilter
			value={filter}
			search={search}
			dateValue = {dateFilter}
			dateStartValue = {startDateFilter}
			dateEndValue={endDateFilter}
			dateOnChange ={(e) => {
				console.log(e);
				let dateFilter = (e.target.value as string)?.toString() ?? DATE_FILTER_LIST[0].value
				setPage(1)
				setDateFilter(dateFilter)
				query.set('date', dateFilter)
				query.set('page', '1')
				history.push(location.pathname + '?' + query.toString())
			}}
			dateEndOnChange = {(e) => {
				console.log(e);
				let d = new Date(e);
				let date = d.getDate() < 10 ? '0'+d.getDate() : d.getDate();
				let month = (d.getMonth() + 1) < 10 ? '0'+(d.getMonth() + 1) : (d.getMonth() + 1);
				let year = d.getFullYear();

				let formatted_date = year+'-'+month+'-'+date;
				setPage(1);
				setEndDateFilter(formatted_date);
				query.set('endDate', formatted_date)
				query.set('page', '1')
				history.push(location.pathname + '?' + query.toString())
			}}
			dateStartOnChange={(e) => {
				console.log(e);
				let d = new Date(e);
				let date = d.getDate() < 10 ? '0'+d.getDate() : d.getDate();
				let month = (d.getMonth() + 1) < 10 ? '0'+(d.getMonth() + 1) : (d.getMonth() + 1);
				let year = d.getFullYear();
				
				let formatted_date = year+'-'+month+'-'+date;
				setPage(1);
				setStartDateFilter(formatted_date);
				query.set('startDate', formatted_date)
				query.set('page', '1')
				history.push(location.pathname + '?' + query.toString())
			}}
			onChange={
				(e) => {
					let filter = (e.target.value as string)?.toString() ?? FILTER_LIST[0].value
					setPage(1)
					setFilter(filter)
					query.set('filter', filter)
					query.set('page', '1')
					history.push(location.pathname + '?' + query.toString())
				}
			}
			onSearch={
				(e) => {
					let search = (e.target.value as string)?.toString() ?? '';
					setPage(1);
					setSearch(search);
					query.set('search', search)
					query.set('page', '1')
					history.push(location.pathname + '?' + query.toString())
				}
			}
		/>
	}

	const getFilteredList = () => {
		if (filter) return searchFromList(data?.[filter] ?? [])
		else return searchFromList(data?.reservations ?? [])
	}
	const searchFromList = (filteredList) => {
		if (search) {
			console.log(search);
			filteredList = filteredList.filter((item) => {
				return item.seeker_name?.includes(search) || item.venue_name?.includes(search) || item.ws_name?.includes(search) || item.forward_venue_name?.includes(search) || item.name?.includes(search) ;
			});
			console.log(filteredList);
		}
		return filteredList;
	}
	const getDateFilteredList = (lists) => {
		let filterList = lists;
		let currd = new Date();

		if (dateFilter === 'one') {
			currd.setMonth(currd.getMonth() - 1);
		} else if(dateFilter === 'three') {
			currd.setMonth(currd.getMonth() - 3);
		} else if(dateFilter === 'six') {
			currd.setMonth(currd.getMonth() - 6);
		} else if(dateFilter === 'custom') {
			if (startDateFilter && endDateFilter) {
				let stime = new Date(startDateFilter).getTime();
				let etime = new Date(endDateFilter).getTime();
				if(stime <= etime) {
					filterList = lists.filter((item) => {
						let idt = item.hasOwnProperty('request_date') ? item.request_date : item.created_datetime
						let d = new Date(idt);
						return d.getTime() >= stime && d.getTime() <= etime ;
					});
				}
			}
			return filterList;
		}

		filterList = lists.filter((item) => {
			let idt = item.hasOwnProperty('request_date') ? item.request_date : item.created_datetime
			let d = new Date(idt);
			return d.getTime() >= currd.getTime(); 
		});

		return filterList;
	};
	const getVisibleList = () => {
		let filteredList = [];
		console.log(filter, dateFilter);
		filteredList = getDateFilteredList(getFilteredList());
		console.log(filteredList);
		let maxPage = Math.ceil(filteredList.length / 10)
		if (page > maxPage) {
			handleSelectPage(maxPage)
			return [];
		}
		let start = 10 * (page - 1)
		return filteredList.slice(start, start + 10)
	}

	const handleSelectPage = (page?: number) => {
		setPage(page ?? 1)
		query.set('page', `${page ?? 1}`)
		history.push(location.pathname + '?' + query.toString())
	}
	const handleShowDetails = (item, isLink = false) => {
		clearActionFromQuery()
		if (!isLink)
			switch (item.reservation_type) {
				case'proposal':
					ProposalDetails.viewProposal(history, item.quote_id)
					break;
				case'lead':
					LongTermDetails.view(history, item.unique_id);
					break;
				case'booking':
					ShortTermDetails.view(history, item.reservation_id);
					break;

			}
	}

	const handleAcceptProposal = item => {

		let token = getToken()
		if (!token) return;
		setRequestLoading(true);
		const url = getApiLinkV2("host/reservations/" + item.quote_id);
		getAxios().put(url, {
			reservation_type: 'proposal',
			option: 'view',
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token,
			},
		}).then(() => {
			const {reservations, proposals} = data;
			const reservation = reservations.find(x => x.quote_id == item.quote_id);
			const proposal = proposals.find(x => x.quote_id == item.quote_id);
			const timeNow = moment(item.start_date_time).format('YYYY-MM-DD' +
				' hh:mm:ss');
			reservation.host_rfq_viewed_on = timeNow;
			proposal.host_rfq_viewed_on = timeNow;
			query.delete('action')
			query.delete('id')
			history.push(location.pathname + '?' + query.toString())
			ProposalDetails.priceProposal(history, item.quote_id);
			updateData({reservations, proposals});
		}).catch(error => {
			Bugsnag.notify(error);
			devLog(error);
			showErrorMessage('Error', error?.message ?? error?.response?.message ?? error?.response?.data?.message ?? error?.response?.data ?? 'Failed to Accept the proposal')
		}).finally(() => {
			setRequestLoading(false);
		});

	}
	const listItemHorizontalMargins = 'mx-xl-5 mx-lg-3 mx-sm-1 mx-0';

	const viewRejectReason = (item) => {
		toggleShowMore('reject-reason', item);
	}

	const handleShowLostInfo = (item) => {
		toggleShowMore('lost-info', item);
	}

	function createHostLeadItem(item: any, _: number) {
		let {closed, lost, chosenToOther, hostClosed} = LeadUtilities.getHostLeadExtraChecks(item);
		return <Card className={clsx('my-3 p-3', listItemHorizontalMargins)} variant={'outlined'}>
			<div>
				<div>

					<div style={{...styles.reservation, backgroundColor: '#FFFFFF'}}>

						<div className={clsx('d-flex flex-column')} style={{...styles.reservdetails, paddingLeft: 0}}>

							<div className={clsx('d-flex flex-row flex-wrap')}>

								<div className={clsx('col-6 col-md-4 col-xl-3')}>
									<div style={{...styles.reservTitle, fontWeight: 500}}>
										{moment(item.start_date_time).format('YYYY') !== moment().format('YYYY') ? (
											moment(item.start_date_time).format('ddd, MMM DD YYYY')
										) : (
											moment(item.start_date_time).format('ddd, MMM DD')
										)}
									</div>
									<div style={styles.reservText}>
										Workspace Inquiry
									</div>
								</div>


								<div className={clsx('col-6 col-md-4')}>
									<div style={styles.reservText}>
										<b>Client:</b> {item.seeker_name}
									</div>

									{
										Boolean(item.venue_name || item.forward_venue_name) &&
										<div style={styles.subtitle}>
											<b>Venue:</b> {item.venue_name || item.forward_venue_name}
										</div>
									}
									{
										Boolean(item.ws_name) &&
										<div style={styles.subtitle}>
											<b>Space:</b> {item.ws_name}
										</div>
									}
								</div>
								{(!chosenToOther && !lost && item['host_accepted'] == 1) &&
								(
									<div className={clsx('ml-auto mb-auto cursor-pointer')}
									>
										<ReservationLink reservation={item}
										                 onClick={() => handleShowDetails(item, true)}
										                 style={{color: 'black'}}>
											<u>View Details</u>
										</ReservationLink>

									</div>
								)
								}
								{(item.share_lost_info && chosenToOther && lost) && (
									<div className={clsx('ml-auto mb-auto cursor-pointer')}
									>
										<div
											onClick={() => handleShowLostInfo(item)}
											style={{color: red[700]}}>
											<u>View Lost Info</u>
										</div>

									</div>
								)
								}

							</div>
						</div>

					</div>

					<div className={clsx('d-flex flex-row flex-wrap justify-content-between align-items-end')}>

						<div className={clsx('ml-3 align-self-start ')}>
							<span><b
								style={{
									fontSize: 16,
									fontWeight: 500
								}}>Status: </b>{LeadUtilities.printHostLeadStatus(item)}</span>
						</div>

						{item['host_accepted'] == LeadUtilities.LEAD_PENDING_STATUS && item.edit_perm && (
							<div className={clsx('col-12 col-md-6 d-flex flex-row justify-content-end ')}>

								<div
									className={clsx('col-6 px-0 mx-1 justify-content-center d-flex align-items-center')}>
									<Button
										className={clsx('px-5')}
										style={{
											height: 44,
											marginTop: 12,
											marginBottom: 4,
											backgroundColor: red[500],
											color: 'white',
											width: '100%',
										}}
										onClickCapture={() => handleReject(item)}
									>
										Decline Lead
									</Button>
								</div>

								<div
									className={clsx('col-6 px-0 mx-1  justify-content-center d-flex align-items-center')}>
									<Button
										className={clsx('px-5')}
										style={{
											height: 44,
											marginTop: 12,
											marginBottom: 4,
											backgroundColor: blue[500],
											color: 'white',
											width: '100%'
										}}
										onClickCapture={() => handleAccept(item)}
										// isLoading={requestsLoading[item.reservation_id+'accept'] != null}
									>
										Accept Lead
									</Button>
								</div>


							</div>
						)}

						{

							(item['host_accepted'] == LeadUtilities.LEAD_REJECT_STATUS && item['reject_reason']) && (
								<div className={clsx('col-12 col-md-6 d-flex flex-row justify-content-end ')}>

									<div
										className={clsx('px-0 mx-1 justify-content-center d-flex align-items-center')}>
										<Button
											className={clsx('px-5')}
											style={{
												height: 44,
												marginTop: 12,
												marginBottom: 4,
												backgroundColor: red[500],
												color: 'white',
												width: '100%',
											}}
											onClickCapture={() => viewRejectReason(item)}
										>
											View Rejection Reason
										</Button>
									</div>
								</div>
							)
						}

						{
							(closed) && (
								(chosenToOther || lost) ?
									(
										<div className={clsx(' d-flex flex-column justify-content-start ')}>
											<div className="label error mr-auto">Lost</div>
											{/*// (share_lost_info) && <><br/><a href="javascript:viewLostDet(\'$sl_no\')" className="action-links">View lost info</a></>*/}
										</div>
									)
									:
									(
										<div className={clsx(' d-flex flex-column justify-content-start ')}>
											<div className="label success mr-auto">Closed</div>
											<div
												className="label mr-auto">{printDateTimeFromServer(item['date_closed'])}</div>
											<div
												className="label mr-auto">{printPrice(item.host_price, LeadUtilities.LEADS_DEFAULT_CURRENCY, currency.name, true)}</div>
										</div>
									)
							)

						}
					</div>

				</div>
			</div>
		</Card>
	}

	const createHostProposalItem = (item: any, idx: number) => {
		let user = getUserData();
		const createBookingStatusPicker = () => {
			return (
				<div className={'p-3 rounded'} style={{
					borderTopColor: '#CFD8E7',
					backgroundColor: '#eef1f3',
				}}>
					<div style={{
						fontWeight: 400,
						fontSize: 16,
						marginBottom: 2,
						color: '#536671',
					}}
					>Update client status
					</div>
					<FMSelectInput options={{data: DDR_BOOKING_STATUSES}}
					               defaultValue={item.booking_status}
					               placeholder={'Click here'}
					               title={'none'}
					               extraClassNames={'pb-0 px-0'}
					               onChange={//@ts-ignore
						               (e: FormEvent<HTMLSelectElement>) => {

							               ReservationsAPI.updateDDRBookingStatus(item.quote_id, (e.target as HTMLSelectElement).value).then(
								               (message) => {
									               if (message.type === "success") {
										               showSuccessMessage(message.message, message.title)
									               } else showErrorMessage(message.message ?? "", message.title)
								               }
							               )
						               }
					               }
					/>
				</div>
			)
		}
		return <Card className={clsx('my-3 p-3', listItemHorizontalMargins)} variant={'outlined'}>
			<div>
				<div>
					<div style={{...styles.reservation, backgroundColor: '#FFFFFF'}}>
						<div className={clsx('d-flex flex-column')} style={{...styles.reservdetails, paddingLeft: 0}}>
							<div className={clsx('d-flex flex-row flex-wrap')}>

								<div className={clsx('col-12 col-md-4 col-xl-3')}>
									<div style={{...styles.reservTitle, fontWeight: 500}}>
										{moment(item.start_date_time).format('YYYY') !== moment().format('YYYY') ? (
											moment(item.start_date_time).format('ddd, MMM DD YYYY')
										) : (
											moment(item.start_date_time).format('ddd, MMM DD')
										)}
										{item.meeting_days > 1 && (
											moment(item.end_date_time).format('YYYY') !== moment().format('YYYY') ? (
												' - ' + moment(item.end_date_time).format('ddd, MMM DD YYYY')
											) : (
												' - ' + moment(item.end_date_time).format('ddd, MMM DD')
											)
										)}
										{/*{' / '+item.order+' - '+item.quote_status}*/}
									</div>
									<div style={styles.reservText}>
										<b>Event
											Duration: </b>{(item.meeting_days > 1) ? item.meeting_days + ' days' : '1 day'}
										<br/>
									</div>
								</div>

								<div className={clsx('px-3')}>
									<div style={styles.reservText}>
										<b>Client:</b> {item.seeker_name}
									</div>

									<div style={styles.reservText}>
										<b>Event Type: </b> {item.event_type}
									</div>
									{
										Boolean(item.venue_name || item.forward_venue_name) &&
										<div style={styles.subtitle}>
											<b>Venue:</b> {item.venue_name || item.forward_venue_name}
										</div>
									}
									{
										Boolean(item.name) &&
										<div style={styles.subtitle}>
											<b>Space:</b> {item.name}
										</div>
									}
								</div>

								{(getHostProposalActive(item)) &&
								(
									<div className={clsx('col-12 col-lg-4 ml-auto d-flex flex-column justify-content-end')}>
										<div className={clsx('ml-auto mb-auto cursor-pointer')}
										>
											<ReservationLink reservation={item}
											                 onClick={() => handleShowDetails(item, true)}
											                 style={{color: 'black'}}>
												<u>View Details</u>
											</ReservationLink>
										</div>
										{(item.amount_due > 0) && createBookingStatusPicker()}

									</div>
								)
								}

							</div>
						</div>


						<div className={clsx('d-flex justify-content-between flex-row flex-wrap')}>
							<div className={clsx('ml-3')} style={{...styles.reservprice,}}>
								<div>
									<b>Status: </b>{DdrUtils.printHostProposalStatus(item)}
								</div>
								<div className={clsx('d-flex flex-row')}>
									{(item.amount_due > 0) && (
										<div style={{
											...styles.reservPriceText,
											marginLeft: 0,
											color: item.upcoming ? '#646464' : '#000000'
										}}>
											Price: {printPrice(item.amount_due, 'USD', currency.name, true)}
										</div>
									)}
									{ (item.upcoming) && (
										<Tooltip title={"Upcoming Reservation"}>
											<UpcomingSVG width={17} height={17} style={{marginTop: 4, marginLeft: 10}}/>
										</Tooltip>
									)
									}
								</div>


							</div>

							{(item.quote_status === 'pending' && item.amount_due <= 0 && (item.edit_perm || user?.id === user?.host_id)) && (

								<div className={clsx('col-12 col-md-6 d-flex flex-row justify-content-end')}>

									<div
										className={clsx('col-6 px-0 mx-1 justify-content-center d-flex align-items-center')}>
										{item.amount_due == 0 && (
											<Button
												className={clsx('px-5')}
												style={{
													height: 44,
													  marginTop: 12,
													  marginBottom: 4,
													  backgroundColor: red[500],
													  color: 'white',
													  width: '100%'
												  }}
												onClickCapture={() => handleReject(item)}
											>Unavailable
											</Button>
										)
										}
									</div>
									<div
										className={clsx('col-6 px-0 mx-1 justify-content-center d-flex align-items-center')}>
										<Button
											className={clsx('px-5')}
											style={{
												height: 44,
												marginTop: 12,
												marginBottom: 4,
												backgroundColor: blue[500],
												color: 'white',
												width: '100%'
											}}
											onClickCapture={() => handleAccept(item)}
											// isLoading={requestsLoading[item.reservation_id+'accept'] != null}
										>
											Price RFQ
										</Button>
									</div>
								</div>

							)}

						</div>
					</div>
				</div>
			</div>
		</Card>
	}

	function createHostBookingItem(item: any, _: number) {
		// item.status = 'pending'
		return <Card className={clsx('my-3 p-3', listItemHorizontalMargins)} variant={'outlined'}>
			<div>
				<div style={{...styles.reservation, backgroundColor: '#FFFFFF'}}>
					<div className={clsx('d-flex flex-column')} style={{...styles.reservdetails, paddingLeft: 0}}>
						<div className={clsx('d-flex flex-row flex-wrap')}>
							<div className={clsx('col-12 col-md-4 col-xl-3')}>
								<div style={{...styles.reservTitle, fontWeight: 500}}>
									{moment(item.start_date_time).format('YYYY') !== moment().format('YYYY') ? (
										moment(item.start_date_time).format('ddd, MMM DD YYYY')
									) : (
										moment(item.start_date_time).format('ddd, MMM DD')
									)}
								</div>
								<div style={styles.reservText}>
									{printTimeRange(item.start_date_time, item.end_date_time)}
								</div>
							</div>

							<div className={clsx('px-3')}>
								<div style={styles.reservText}>
									<b>Client:</b> {item.seeker_name}
								</div>

								{
									Boolean(item.venue_name || item.forward_venue_name) &&
									<div style={styles.subtitle}>
										<b>Venue:</b> {item.venue_name || item.forward_venue_name}
									</div>
								}
								{
									Boolean(item.name) &&
									<div style={styles.subtitle}>
										<b>Space:</b> {item.name}
									</div>
								}
							</div>

							{(item.status === 'approved' || item.status === 'completed') &&
							(
								<div className={clsx('ml-auto mb-auto cursor-pointer')}>
									<ReservationLink reservation={item} onClick={() => handleShowDetails(item, true)}
									                 style={{color: 'black'}}>
										<u>View Details</u>
									</ReservationLink>
								</div>
							)
							}
						</div>

					</div>

					<div className={clsx('d-flex flex-row justify-content-between')}>

						<div className={clsx('ml-3')}>
							<div>
								<span><b style={{
									fontSize: 16,
									fontWeight: 500
								}}>Status: </b>{printHostBookingStatus(item)}</span>
							</div>
							{(item.status === 'completed' || item.status === 'approved') && (
								<div className={clsx('d-flex flex-row')}>
									<div className={''}
									     style={{
										     ...styles.reservPriceText,
										     marginLeft: 0,
										     color: item.upcoming ? '#646464' : '#000000'
									     }}>
										Price: {printPrice(item.payment_amount, item.payment_currency, currency.name, true)}
									</div>
									{item.upcoming && (
										<Tooltip title={"Upcoming Reservation"}>
											<UpcomingSVG width={17} height={17} style={{marginTop: 4,marginLeft: 10}}/>
										</Tooltip>
									)
									}
								</div>
							)}
						</div>

						{item.status === 'pending' && item.edit_perm && (
							<div className={clsx('col-12 col-md-6 d-flex flex-row justify-content-end')}>

								<div
									className={clsx('col-6 px-0 mx-1 justify-content-center d-flex align-items-center')}>
									<Button
										className={clsx('')}
										style={{
											height: 44,
											marginTop: 12,
											marginBottom: 4,
											backgroundColor: red[500],
											color: 'white',
											width: '100%',
										}}
										onClickCapture={() => handleReject(item)}
									>
										Space Unavailable
									</Button>
								</div>

								<div
									className={clsx('col-6 px-0 mx-1 justify-content-center d-flex align-items-center')}>
									<Button
										className={clsx('')}
										style={{
											height: 44,
											marginTop: 12,
											marginBottom: 4,
											backgroundColor: blue[500],
											color: 'white',
											width: '100%'
										}}
										onClickCapture={() => handleAccept(item)}
										// isLoading={requestsLoading[item.reservation_id+'accept'] != null}
									>
										Accept Booking
									</Button>
								</div>

							</div>
						)}

					</div>

				</div>
				{/*<div style={styles.hostdivider} />*/}
			</div>
		</Card>
	}

	function handleAcceptBooking(item: any) {
		handleSubmit('accept', item, {});
	}

	function handleReject(item: any) {

		switch (item.reservation_type) {
			case 'lead':
				query.set(`action`, `reject-lead-inquiry`)
				query.set(`id`, item.request_id)
				history.push(location.pathname + '?' + query.toString())
				break;
			case 'booking':
				query.set(`action`, `reject-booking`)
				query.set(`id`, item.reservation_id)
				history.push(location.pathname + '?' + query.toString())
				break;
			case 'proposal':
				query.set(`action`, `reject-proposal`)
				query.set(`id`, item.quote_id)
				history.push(location.pathname + '?' + query.toString())
				break;
		}

		history.push(location.pathname + '?' + query.toString())
		toggleShowMore('reject', item);
	}

	function handleAccept(item: any) {

		switch (item.reservation_type) {
			case 'lead':
				query.set(`action`, `accept-lead-${item.lead_type}`)
				query.set(`id`, item.request_id)
				history.push(location.pathname + '?' + query.toString())
				handleAcceptLead(item);
				break;
			case 'booking':
				query.set(`action`, `accept-booking`)
				query.set(`id`, item.reservation_id)
				history.push(location.pathname + '?' + query.toString())
				handleAcceptBooking(item);
				break;
			case 'proposal':
				query.set(`action`, `accept-proposal`)
				query.set(`id`, item.quote_id)
				history.push(location.pathname + '?' + query.toString())
				handleAcceptProposal(item);
				break;
		}
	}

	function handleAcceptLead(item: any) {
		handleSubmit('accept', item, {});
	}


	const renderItem = (item: any, index: number) => {

		if (item.reservation_type === 'proposal') {
			if (item.amount_due === 0 && item.host_rfq_viewed_on) item.amount_due = -1;
			return createHostProposalItem(item, index)
		}
		if (item.reservation_type === 'booking') {
			return createHostBookingItem(item, index)
		}
		if (item.reservation_type === 'lead') {
			return createHostLeadItem(item, index)
		}

	}

	const toggleShowMore = (showMore: any = null, moreResrv: any = null) => {

		if (showMore) {
			setShowMore(showMore)
			setMoreResrv(moreResrv)
		} else {
			// , showBackView: true});
			setShowMore(showMore)
			setMoreResrv(moreResrv)

			// setTimeout(() => {
			//    setState({showBackView: false});
			// }, 200);
			//
		}
	}

	const createMoreDialog = () => {
		let title = '';
		if (showMore === 'reject') {
			switch (moreResrv?.reservation_type) {
				case 'lead':
					title = 'Decline Lead';
					break;
				default:
					title = 'Space Unavailable';
					break;
			}
		} else if (showMore === 'accept') {
			switch (moreResrv?.reservation_type) {
				case 'lead':
					title = 'Accept Lead';
					break;
				case 'booking':
					title = 'Accept Booking';
					break;
				case 'proposal':
					title = 'Price Proposal';
					break;
			}
		} else if (showMore === 'reject-reason') {
			title = "Rejection Reason"
		} else if (showMore === 'lost-info') {
			title = "Lost Info"
		}
		return (

			<Dialog
				open={Boolean(showMore)}
				onClose={() => toggleShowMore()}
				aria-labelledby="max-width-dialog-title"
			>
				<DialogTitle>
					<div className={'d-flex flex-row justifu-content-between align-items-center'}>
						{title}
						<IconButton className={'ml-auto'} aria-label="close" onClick={() => toggleShowMore()}>
							<CloseIcon/>
						</IconButton>
					</div>
				</DialogTitle>
				<DialogContent>


					{(showMore === 'reject') && (
						(moreResrv?.reservation_type === 'booking' || moreResrv?.reservation_type === 'lead') ? (
							<form
								onSubmitCapture={handleMoreSubmit}>
								<FMMultilineTextInput id='reject_note' required={true} title={'Comments'}/>
								<div className="col-12 mt-3 mb-4 pr-0 pl-0">
									<button
										className={"btn btn-lg btn-primary btn-block fl_btn save-btn mx-auto w-auto px-3 " + ((requestLoading) ? 'ld-btn' : '')}>
										{(requestLoading) ? (<><FontAwesomeIcon icon={faCircleNotch}
										                                        spin={true}/> loading</>) : moreResrv?.reservation_type === 'booking' ? 'Space Unavailable' : 'Decline Lead'}
									</button>
								</div>

							</form>
						) : (
							<form
								id={'proposal-form'}
								onChangeCapture={() => handleMoreFormChanged()}
								onSubmitCapture={handleMoreSubmit}>
								<div className={'form-control'}>Event dates:
									{' ' + moment(moreResrv?.start_date_time).format('ddd, MMM' +
										' DD YYYY')}
									{moreResrv?.meeting_days > 1 && ' - ' + moment(moreResrv?.end_date_time).format('ddd, MMM DD YYYY')}
									{moreResrv?.meeting_days > 1 ? ' (' + moreResrv?.meeting_days + ' days)' : ' (1 day)'}
								</div>

								<FMBooleanInput id='dates_chk'
								                defaultValue={false} title={'Is this space available on other dates?'}
								                extraClassNames={clsx('mt-2', request.dates_chk ? 'mb-1' : "mb-4")}

								/>
								{request.dates_chk && (
									<FMOptionsInput extraClassNames={'mb-4'} id='available_dates'
									                defaultValue={request['available_dates']}
									                options={{data: OTHER_DATES, fullwidth: true}} required={true}/>
								)}
								{request.dates_chk && request.available_dates && request.available_dates?.includes('Other') && (
									<FMTextInput id='reject_other' type='text'
									             title={'Other options'}
									             defaultValue={''} required={true}/>
								)}

								<FMMultilineTextInput id='reject_note' required={true} title={'Comments'}/>
								<div className="col-12 mt-3 mb-4 pr-0 pl-0">
									<button
										className={"btn btn-lg btn-primary btn-block fl_btn save-btn mx-auto w-auto px-3" + ((requestLoading) ? 'ld-btn' : '')}
									>
										{(requestLoading) ? (<><FontAwesomeIcon icon={faCircleNotch}
										                                        spin={true}/> loading</>) : request.dates_chk ? 'Submit' : 'Space Unavailable'}
									</button>
								</div>

							</form>
						)
					)}
					{(showMore === 'accept') && (

						<div>
							<div className="col-12 mt-3 mb-4 pr-0 pl-0 px-5"
							     style={{fontSize: '1.2rem', fontWeight: 400}}>
								Are you sure you want to accept this {(capitalizeFirst(moreResrv?.reservation_type))}?
							</div>
							<div className="d-flex flex-row col-12 mt-3 mb-4 pr-0 pl-0 justify-content-end">
								<button
									onClick={() => handleAccept(moreResrv)}
									className={"btn btn-lg btn-primary btn-block fl_btn save-btn w-auto px-3" + ((requestLoading) ? 'ld-btn' : '')}>
									{
										(requestLoading) ?
											(<><FontAwesomeIcon icon={faCircleNotch}
											                    spin={true}/> loading</>
											) : "I'm Sure"
									}
								</button>
							</div>
						</div>
					)}
					{(showMore === 'reject-reason') && (

						<div>
							<div className="col-12 mt-3 mb-4 pr-0 pl-0 px-5"
							     style={{fontSize: '1.2rem', fontWeight: 400}}>
								{moreResrv?.reject_reason}
							</div>
						</div>
					)}
					{(showMore === 'lost-info') && (

						<div>
							<div className="col-12 mt-3 mb-4 pr-0 pl-0 px-5"
							     style={{fontSize: '1.2rem', fontWeight: 400}}>
								<table className={'w-100'} style={{tableLayout: 'fixed'}}>
									<thead>
									<th>Other Venue</th>
									<th>Price</th>
									</thead>
									<tbody>
									<tr>

										<td>
											{capitalizeFirst(moreResrv['other_venue'])}
										</td>
										<td>{printPrice(moreResrv['host_price'], LeadUtilities.LEADS_DEFAULT_CURRENCY, currency.name, true)} </td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>
					)}


				</DialogContent>

			</Dialog>
		)


	}

	const isExpired = getUserData()?.status !== 'active';
	// console.log({userData: getUserData()})
	// const pageHorizontalPaddings = 'px-0 px-sm-1 px-lg-3 px-xl-5';
	return (

		<div id="Manage Listings" className={clsx(classes.root)}>
			<div className={clsx("container-fluid bg-white mt-40")}>
				<div className="page">
					<div className={`mt-4 mb-5 `}>
						<div className={"d-flex flex-wrap flex-row align-items-center justify-content-between"}>
							<div className={'page-lg-title'}>
								Manage Reservations {(!loading) &&
							<span className={classes.count}>{getDateFilteredList(getFilteredList())?.length ?? 0}</span>}
							</div>
							<div>
								{createFilter()}
							</div>
						</div>
					</div>

					{
						(loading) ? (
								<div className={clsx('d-flex col-12 justify-content-center')}>
									<CircularProgress className={clsx('mx-auto my-5')}/>
								</div>
							)
							: (isExpired) ?
							(

								<div className={clsx('page-container text-center my-5 py-5 mx-5')}
								     style={{backgroundColor: red[100], borderRadius: 8}}>
									<div className={'page-lg-title mb-1'}>
										Subscription Expired
									</div>
									<div className={'note-title text-center'}>
										In order to continue using our services, please <Link to='/contact-us'>contact
										us</Link> to renew your subscription.
									</div>
								</div>

							) : (
								<>
									{(getDateFilteredList(getFilteredList()).length === 0) && (
										<div className={clsx('page-container text-center my-5')}>
											<div className={'page-lg-title mb-1'}>
												No reservations yet
											</div>
											<div className={'note-title text-center'}>
												When you have new reservations, it will show up here.
											</div>
										</div>
									)}
									<div className={clsx('px-0 px-xl-2')}>
										{
											getVisibleList().map(renderItem)
										}
									</div>
								</>
							)
					}

					{createMoreDialog()}
					<Dialog
						open={!Boolean(showMore) && requestLoading}
						onClose={() => toggleShowMore()}
						aria-labelledby="max-width-dialog-title"
					>
						<div className={clsx('d-flex col-12 justify-content-center')}>
							<CircularProgress className={clsx('mx-auto my-5')}/>
						</div>
					</Dialog>

					<div className={`justify-content-center my-3 mx-3`}>
						<Pagination className={clsx(`mx-auto `, classes.pagination)}
						            count={Math.ceil(getDateFilteredList(getFilteredList())?.length / 10 ?? 0)}
						            defaultPage={1}
						            onChange={(e, page) => handleSelectPage(page)}
						            page={page}
						            color="primary"/>
					</div>
				</div>
				<AlertSnackbar ref={snackbar}/>
			</div>
		</div>
	);
}

ManageReservations.route = `/${ManageReservations.key}`
