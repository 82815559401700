import React, {ChangeEvent, createRef} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {FetchHostDetailsResponse, HostAPI, HostUpdateRequest} from "src/APIs/HostAPI";
import {Theme, withStyles} from "@material-ui/core/styles";
import {blue, blueGrey} from "@material-ui/core/colors";
import {Styles} from "@material-ui/styles";
import {CircularProgress, IconButton} from "@material-ui/core";
import {HostDashboardRoute} from "src/components/host/host-dashboard/consts";
import {HostDashboardRoutePrams} from "src/components/host/host-dashboard/host-dashboard";
import {
    HostPermissions,
    PermissionChangedCallback
} from "src/components/host/host-dashboard/manage-hosts/add-edit-host/host-permissions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import clsx from 'clsx';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {createAlertFromMessage, devLog, formValidation} from "src/Helpers";
import {UserData} from "src/APIs/UserAPI";
import {AlertComponent, AlertRequest} from "src/components/host/host-dashboard/Alert";
import AlertSnackbar from "src/components/organisms/snackbar/AlertSnackBar";
import HostsUtils from "src/utils/host-utils";


export interface AddNewHostProps  extends RouteComponentProps<HostDashboardRoutePrams> {
    classes?:any;
}

export type AddNewHostState = {
    reqAlert?: AlertRequest,
    reqLoading?: boolean,
    loading?: boolean,
    fNameError?: any,
    lNameError?: any,
    phoneError?: any,
    emailError?: any,
    positionError?: any,
    response?: FetchHostDetailsResponse,
}

export class AddNewHost extends React.Component<AddNewHostProps,AddNewHostState> {
    static key = 'add-host'
    static route = `${HostDashboardRoute}/${AddNewHost.key}`
    snackbar = createRef<AlertSnackbar>();
    constructor(props:AddNewHostProps){
        super(props)
        this.state = {
            // selectedPermissions: [] as Array<any>,
            // selectedVenues: [] as Array<any>,
            reqAlert: undefined,
            loading: true,
        } as AddNewHostState
    }

    componentDidMount(): void {
            HostAPI.fetchHosts()
                .then(response => {
                    devLog({response})
                    this.setState({
                        response: {
                            venues: response?.venues
                        },
                        loading: false,
                    })
                })
    }

    permissionChanged:PermissionChangedCallback = (request:FetchHostDetailsResponse)=>{
        this.setState({
            response: {
                ...this.state.response,
                selectedPermissions: request.selectedPermissions,
                selectedVenues: request.selectedVenues,
            }
        })
    }

    handleUpdateProfile = (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.target);
        const fNameError = formValidation('fname', data.get("fname"));
        const lNameError = formValidation('lname', data.get("lname"));
        const phoneError = formValidation('phone', data.get("phone"), false);
        const emailError = formValidation('email', data.get("email"));
        const positionError = formValidation('position', data.get("position"), false);

        this.setState({
            reqLoading: true,
            loading: true,
            fNameError,
            lNameError,
            phoneError,
            emailError,
            positionError
        });

        if (fNameError || lNameError || phoneError || emailError || positionError) {
            this.setState({reqLoading: false, loading: false,});
            window.scrollTo(0, 0)
            this.snackbar.current?.setMessage("Re-Check Input Data")
            this.snackbar.current?.setSeverity("warning")
            this.snackbar.current?.open()
            return;
        }
        const userData: UserData = {
            first_name: data.get("fname")?.toString(),
            last_name: data.get("lname")?.toString(),
            phone: data.get("phone")?.toString(),
            email: data.get("email")?.toString(),
            position: data.get("position")?.toString(),
        }
        let updateRequest = {
            fName: userData.first_name,
            lName: userData.last_name,
            email: userData.email,
            phone: userData.phone,
            position: userData.position,
        } as HostUpdateRequest
        if (this.state.response) {
            let {selectedPermissions, selectedVenues} = this.state.response;
            HostAPI.addNewHost(updateRequest, selectedPermissions, selectedVenues).then((message) => {
                createAlertFromMessage(this, message)
                this.setState({reqLoading: false,});
                setTimeout(() => this.props.history.goBack(), 1500);
            })
        }


    }

    render() {
        let {classes} = this.props;
        // let response = pick(this.state,...FetchHostDetailsResponseKeys)
        let {response, reqAlert, reqLoading, loading} = this.state;
        let host = response?.host
        let venues = response?.venues

        // devLog( "AddNewHost" , this.props);
        return (
            <div className="container mt-100 pr-0 pl-0" style={{position: 'relative'}}>
                <div className="page" style={{position: 'relative'}}>

                    <div className={clsx('d-flex align-items-center')}>
                        <IconButton>
                            <ArrowBackIosIcon onClickCapture={() => this.props.history.goBack()}/>
                        </IconButton>
                        {/*{this.getStatusComponent(host.status)}*/}
                    </div>
                    {loading ? (
                        <div className={clsx('d-flex col-12 justify-content-center')}>
                            <CircularProgress className={clsx('mx-auto my-5')}/>
                        </div>
                    ) : (
                        <form className="row mr-0 ml-0 mt-5" autoComplete="on" onSubmit={this.handleUpdateProfile}>

                            <div className="col-6 pl-0 pl-mobile-0 pr-mobile-1">
                                <input type="name" id="fname" name="fname" className="form-control"
                                       placeholder="First name" required/>
                                {(this.state.fNameError) ? (
                                    <div className="form-error">{this.state.fNameError}</div>) : (<></>)}
                            </div>
                            <div className="col-6 pr-0 pl-mobile-1 pr-mobile-0">
                                <input type="name" id="lname" name="lname" className="form-control"
                                       placeholder="Last name" required/>
                                {(this.state.lNameError) ? (
                                    <div className="form-error">{this.state.lNameError}</div>) : (<></>)}
                            </div>
                            <div className="col-sm-6 col-12 pl-0 pr-mobile-0 pl-mobile-0">
                                <input type="tel" id="phone" name="phone" className="form-control"
                                       placeholder="Phone number"/>
                                {(this.state.phoneError) ? (
                                    <div className="form-error">{this.state.phoneError}</div>) : (<></>)}
                            </div>
                            <div className="col-sm-6 col-12 pr-0 pr-mobile-0 pl-mobile-0">
                                <input type="email" id="email" name="email" className="inputEmail form-control"
                                       placeholder="Email address" required/>
                                {(this.state.emailError) ? (
                                    <div className="form-error">{this.state.emailError}</div>) : (<></>)}
                            </div>
                            <div className="col-sm-6 col-12 pl-0 pr-mobile-0 pl-mobile-0">
                                <div className="selector">
                                    <select id="position" name="position" className="selector form-control" required>
                                        {HostsUtils.POSITIONS.map(
                                            (position) => {
                                                return <option value={position.value}>{position.label}</option>
                                            }
                                        )}
                                    </select>
                                </div>
                            </div>

                            <div className="col-12 mt-3 mb-4 pr-0 pl-0">
                                {Boolean(venues) && <HostPermissions {...response}
                                                                     permissionChangedCallback={this.permissionChanged}/>}
                            </div>

                            <div className="col-12 mt-3 mb-4 pr-0 pl-0">
                                <button
                                    className={"btn btn-lg btn-primary btn-block fl_btn save-btn align-start " + ((reqLoading) ? 'ld-btn' : '')}>
                                    {(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch}
                                                                        spin={true}/> loading</>) : 'Add'}
                                </button>
                            </div>
                        </form>
                    )}


                </div>
                <AlertComponent reqAlert={reqAlert} onDismissClicked={() => this.setState({reqAlert: undefined})}/>
                <AlertSnackbar ref={this.snackbar}/>
            </div>
        );
    }
}

const styles = (theme: Theme)=>
    ({
        root: {
            maxWidth: '80%',
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        avatar: {
            backgroundColor: blueGrey[300],
        },
        count:{
            borderRadius:'40%',
            padding:'.2rem 0.5rem',
            fontSize:'.8rem',
            backgroundColor: blue[300],
            verticalAlign:'middle',
        },
        title: {
            verticalAlign:'middle',
        },
        iconButton:{
            marginLeft:'auto',
            alignSelf:'middle',

        }
    } as Styles<Theme,any, string> )

// @ts-ignore
export let AddNewHostEnhanced = withStyles(styles)(withRouter(AddNewHost));
