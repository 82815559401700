import React, { Component } from "react";

export default class Blog extends Component {

  componentDidMount() {
    window.open('https://host.fluidmeet.com/blog', '_top');
  }

  render() {
    return (<></>);
  }
}
