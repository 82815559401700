import {createTheme} from "@material-ui/core";
import blue from "@material-ui/core/colors/blue";

export const fluidmeetMaterialTheme = createTheme({
	palette: {
		primary: blue,
	},
	props: {
		MuiDialog: {
			fullWidth: true,
			maxWidth: "md",
		},
	}
})
