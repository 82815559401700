import React, {FormEvent, Fragment, useEffect, useMemo, useState} from 'react';
import {
	AccordionActions,
	AccordionDetails,
	AccordionSummary,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	makeStyles,
	Menu,
	MenuItem,
	useMediaQuery
} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {blue, blueGrey, green, red} from "@material-ui/core/colors";
import clsx from 'clsx';
import {
	devLog,
	formValidation,
	getApiLinkV2,
	getAxios,
	getCurrency,
	getLayoutImg,
	getLayoutName,
	getToken,
	getUser,
	getCurrencies,
	printDateFromDateTime,
	printPrice,
	printPriceOnly,
	printSpaceData,
} from "src/Helpers";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useParams} from "react-router-dom";
import Bugsnag from "@bugsnag/js";
import axios from 'axios';
import {useUpdateReducer} from "src/hooks/useUpdateReducer";
import AlertSnackbar from "src/components/organisms/snackbar/AlertSnackBar";
import multiProposal from 'src/img/gray_logo.png';
import {getHostProposalStatus} from "src/components/host/host-helpers";
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfo, faPhoneAlt} from "@fortawesome/free-solid-svg-icons";
import {faEnvelope, faUser} from "@fortawesome/free-regular-svg-icons";
import Accordion from "@material-ui/core/Accordion";
import Tooltip from "@material-ui/core/Tooltip";
import {FMButton, FMMultilineTextInput, FMSelectInput, FMTextInput} from "src/components/host/forms/fields";
import CloseIcon from "@material-ui/icons/Close";
import {useStateLogger} from "src/hooks/useStateLogger";
import {infoSection} from "src/components/host/host-dashboard/manage-reservations/reservations-details/info-section";
import {CATERED_AMENITIES} from "src/components/host/host-dashboard/manage-listings/add-edit-workspaces/consts";
import {isNotNullOrUndefined} from "src/global-functions";
import {DdrUtils} from "src/utils/ddr-utils";
import {useSnackbar} from "src/components/organisms/snackbar";
import {useAlertDialog} from "src/components/organisms/alert-dialog";
import {AccordionController} from "src/components/organisms/accordion-wrapper";
import {VenueAPI} from "src/APIs/venue/VenueAPI";

const price_types = [
	{label: 'Total (per day)', value: 'total'},
	{label: 'Per person', value: 'per-person'},
];

export const DDR_BOOKING_STATUSES = [
	{label: 'Client booked with us', value: 11},
	{label: 'Booked with another venue', value: 10},
	{label: 'Client visited - Not interested', value: 9},
	{label: 'Client visited – Interested in booking', value: 8},
	{label: 'Client visited - awaiting feedback', value: 7},
	{label: 'Called – client interested in booking', value: 6},
	{label: 'Called – client not interested', value: 5},
	{label: 'Called – client needs time to decide', value: 4},
	{label: 'Called – client visit arranged', value: 3},
	{label: 'Called – client requested more info', value: 2},
	{label: 'Called - no response', value: 1},
];

const validities = [
	{label: '3 days', value: '3'},
	{label: '5 days', value: '5'},
	{label: '1 week', value: '7'},
	{label: '10 days', value: '10'},
	{label: '2 weeks', value: '14'},
	{label: '3 weeks', value: '21'},
	{label: '1 month', value: '30'},
];

const useStyles = makeStyles((theme) => (
		{
			accordionRoot: {
				backgroundColor: '#edf5fa'
			},
			tooltip: {
				fontSize: theme.typography.pxToRem(18),
			},
		}
	)
)

export type ProposalDetailsProps = {}
ProposalDetails.key = 'proposal-details'
ProposalDetails.route = `/${ProposalDetails.key}/:id/:action?`

ProposalDetails.viewProposal = (history, quote_id) => {
	history.push(ProposalDetails.generateViewProposalLink(quote_id))
}
ProposalDetails.generateViewProposalLink = (quote_id) => {
	return (`/${ProposalDetails.key}/${quote_id}/view`)
}
ProposalDetails.generatePriceProposalLink = (quote_id) => {
	return (`/${ProposalDetails.key}/${quote_id}/price`)
}
ProposalDetails.priceProposal = (history, quote_id) => {
	history.push(ProposalDetails.generatePriceProposalLink(quote_id))
}

export type ProposalDetailsRouteParams = {
	id: string,
	type?: string,
	action?: 'view' | 'price' | 'better-price',
}

export default function ProposalDetails({}: ProposalDetailsProps) {
	const {id, type, action = 'view'} = useParams<ProposalDetailsRouteParams>()

	const [showMore, setShowMore] = useState<string | null>(null)
	const [menuAnchor, setMenuAnchor] = useState(null);
	const [isPricing, setIsPricing] = useState<boolean>(action === 'price')
	const [isBetterPricing, setIsBetterPricing] = useState<boolean>(action === 'better-price')
	const [isEditing, setIsEditing] = useState<boolean>(false)
	const [reservation, setReservation] = useState<any>({})
	const [space, setSpace] = useState<any>();
	const [selectedSpace, setSelectedSpace] = useState<any>();
	const [spaces, setSpaces] = useState<any[]>();
	const [user, setUser] = useState<any>();
	const [loading, setLoading] = useState<any>(true);
	const [submitLoading, setSubmitLoading] = useState<any>(false);
	const [showMoreLoading, setShowMoreLoading] = useState<any>(false);
	const [accordionRefs, setAccordionRefs] = React.useState<any>();
	const [request, setRequest, updateRequest] = useUpdateReducer<any>({});
	const [errors, setErrors, updateErrors] = useUpdateReducer<any>({});
	const {snackbar, showErrorMessage, showSuccessMessage} = useSnackbar();
	const {alertDialog} = useAlertDialog();
	const defaultCurrency = getCurrency();
	const [currency, setCurrency] = useState<any>(defaultCurrency);
	const [country, setCountry] = useState<any>("");
	const classes = useStyles();
	const isQuoteExpired = reservation?.quote_status === 'expired';

	useStateLogger(errors);

	const fetchReservation = () => {

		let token = getToken()
		if (!token) return;

		const url = getApiLinkV2("host/reservations/" + id);

		getAxios().get(url, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token,
			},
			params: {
				type: 'proposal',
			},
		}).then(response => {

			devLog("HostProposalDetails::FetchReservation", response.data);
			const {reservation, spaces, space} = response.data;
			if (reservation) {
				var _request: any = {};
				_request.booking_status = reservation.booking_status ? parseInt(reservation.booking_status) : null;
				reservation.booking_status = _request.booking_status;

				if (reservation.meeting_days && reservation.meeting_days.length > 0) {
					reservation.meeting_days.forEach((item, idx) => {
						_request['price_type_' + idx] = item.pricing_type ? item.pricing_type : '';
						_request['fm_price_' + idx] = item.fluidmeet_price ? printPriceOnly(item.fluidmeet_price, 'USD', currency.name, true) + '' : '';
						_request['vn_price_' + idx] = item.venue_price ? printPriceOnly(item.venue_price, 'USD', currency.name, true) + '' : '';
					});
				}
				if (reservation.accommodation && reservation.accommodation.length > 0) {
					reservation.accommodation.forEach((item, idx) => {
						_request['fm_accom_' + idx] = item.fluidmeet_price ? printPriceOnly(item.fluidmeet_price, 'USD', currency.name, true) + '' : '';
						_request['vn_accom_' + idx] = item.venue_price ? printPriceOnly(item.venue_price, 'USD', currency.name, true) + '' : '';
					});
				}
				let _accordionRefs: any = {}
				reservation.accommodation?.forEach(
					(_, i) => {
						_accordionRefs[`accomm-day-${i}`] = accordionRefs?.[`accomm-day-${i}`] ?? React.createRef()
					}
				)
				reservation.meeting_days?.forEach(
					(_, i) => {
						_accordionRefs[`event-day-${i}`] = accordionRefs?.[`event-day-${i}`] ?? React.createRef()
					}
				)
				setAccordionRefs(_accordionRefs)

				setIsPricing(reservation.tot_fluidmeet_price <= 0)
				setReservation(reservation)
				setRequest(_request)
				setSpace(space)
				setCountry(space.country)
				setSpaces(spaces)

			} else {
				setReservation(null)
				setSpace(null)
				setSpaces([])
			}
			setLoading(false)

		}).catch(error => {
			devLog(error);
			setReservation(null)
			setSpace(null)
			setSpaces([])
			setTimeout(() => {
				Bugsnag.notify(error);
			}, 500)
			setLoading(false)
		}).finally(() => {

		});

	}

	/*const getVenueCountry = async (id) => {
		const venueDetails = await VenueAPI.fetchVenue(id);
		const venue = venueDetails?.venue;
		const venueCountry = venue.country;
		setCountry(venueCountry);
	}

	useEffect(() => {
		if(space) {
			getVenueCountry(space.venue_details_id);
		}
	}, [space]);*/

	useEffect(() => {
		if(country && country !== "") {
			const currencies = getCurrencies();
			const vCurrency = currencies.find(c => c.country == country);
			setCurrency(vCurrency);
		}
	}, [country]);

	useEffect(() => {

		let _user = getUser()
		if (_user) {
			fetchReservation();
			setUser(_user);
		} else {
			setUser(null)
		}
	}, []);

	useEffect(() => {
		if (isPricing) {
			openAccordions()
			window.scrollTo(0, (document.getElementById(`event-details-title`)?.offsetTop ?? 80) - 80);
		}

	}, [isPricing]);

	const handleStatus = (type, value) => {
		handleChange(type, value);
		if (reservation[type] !== value && Boolean(value)) {

			let token = getToken()
			if (!token) return;

			const url = getApiLinkV2("host/reservations/" + reservation.quote_id);

			getAxios().put(url, {
				reservation_type: 'proposal',
				option: 'status',
				status: value,
			}, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token,
				},
			}).then(response => {
				showSuccessMessage(response.data.message);
				reservation.booking_status = value;
				setReservation({...reservation})
			}).catch(error => {
				handleChange('booking_status', reservation.booking_status);
				Bugsnag.notify(error);
				showErrorMessage(error)
			});
		}

	}


	const handleSubmit = () => {
		let form = (document.getElementById('proposal-rfq-form') as (HTMLFormElement | null))
		if (form?.reportValidity()) {

			const isRequestForSpecificSpace = (space && Object.keys(space).length > 0);
			const isRequestForMultipleSpaces = (spaces && spaces.length > 0);

			var price = 0;
			var vnPrice = 0;
			let _space = space;

			if (isRequestForMultipleSpaces) {
				_space = selectedSpace
				console.log({reservation, tax_percent: space?.tax_percent, selectedSpace})
			}

			const vat = _space?.tax_percent ? parseInt(_space.tax_percent) : 0;
			const meeting_days = reservation.meeting_days ? reservation.meeting_days.length : 0;
			const accommodation = reservation.accommodation ? reservation.accommodation.length : 0;

			var inputs: any[] = [];

			if (reservation.meeting_days && reservation.meeting_days.length > 0) {
				reservation.meeting_days.forEach((item, idx) => {
					inputs.push('event' + idx);
					const priceInt = parseInt(request['fm_price_' + idx]);
					const vnPriceInt = parseInt(request['vn_price_' + idx]);

					price += (priceInt && priceInt > 0) ? priceInt : 0;
					vnPrice += (vnPriceInt && vnPriceInt > 0) ? vnPriceInt : 0;

					errors['price_type_' + idx] = formValidation('text', request['price_type_' + idx]);
					const fm_price = (request['fm_price_' + idx] === '0') ? '-' : request['fm_price_' + idx];
					const vn_price = (request['vn_price_' + idx] === '0') ? '-' : request['vn_price_' + idx];
					errors['fm_price_' + idx] = formValidation('price', fm_price);
					errors['vn_price_' + idx] = formValidation('price', vn_price);

					if (errors['fm_price_' + idx] == null && errors['vn_price_' + idx] == null && vnPriceInt < priceInt) {
						errors['vn_price_' + idx] = 'Price must be higher than fluidmeet discounted price.';
					}

					if (errors['price_type_' + idx] || errors['fm_price_' + idx] || errors['vn_price_' + idx]) {
						errors['event' + idx] = true;
					} else {
						errors['event' + idx] = null;
					}
				});
			}

			if (reservation.accommodation && reservation.accommodation.length > 0) {
				reservation.accommodation.forEach((item, idx) => {
					inputs.push('accom' + idx);
					const priceInt = parseInt(request['fm_accom_' + idx]);
					price += (priceInt && priceInt > 0) ? priceInt : 0;
					const vnPriceInt = parseInt(request['vn_accom_' + idx]);
					vnPrice += (vnPriceInt && vnPriceInt > 0) ? vnPriceInt : 0;

					const fm_price = (request['fm_accom_' + idx] === '0') ? '-' : request['fm_accom_' + idx];
					const vn_price = (request['vn_accom_' + idx] === '0') ? '-' : request['vn_accom_' + idx];
					errors['fm_accom_' + idx] = formValidation('price', fm_price);
					errors['vn_accom_' + idx] = formValidation('price', vn_price);

					if (errors['fm_accom_' + idx] == null && errors['vn_accom_' + idx] == null && vnPriceInt < priceInt) {
						errors['vn_accom_' + idx] =
							'Price must be higher than fluidmeet discounted price.';
					}

					if (errors['fm_accom_' + idx] || errors['vn_accom_' + idx]) {
						errors['accom' + idx] = true;
					} else {
						errors['accom' + idx] = null;
					}
				});
			}
			if (isRequestForMultipleSpaces) {
				inputs.push('selectedLayout')
				errors.selectedLayout = request.selectedLayout ? null : 'Please select a' +
					' space to link this RFQ';
			}
			errors.validity = formValidation('validity', request.validity);
			inputs.push('validity');

			Object.keys(errors).forEach(key => {
				if (!errors[key]) delete errors[key];
			});
			setErrors({...errors})

			if (Object.keys(errors).length > 0) {
				openAccordions()
				setTimeout(() => {
					window.scrollBy(0, (document.getElementById(Object.keys(errors)?.[0])?.getBoundingClientRect()?.top ?? 80) - 80);
					snackbar?.current?.setMessage("Re-Check Input Data")
					snackbar?.current?.setSeverity("warning")
					snackbar?.current?.open()
				}, 750)

				return;
			}

			price = printPriceOnly((price * (1 + (vat / 100))), currency.name, 'USD');
			vnPrice = printPriceOnly((vnPrice * (1 + (vat / 100))), currency.name, 'USD');

			var data: any = {
				reservation_type: 'proposal',
				option: 'price',
				proposal_currency: currency.name,
				budget_type: reservation.budget_type,
				validity: request.validity,
				tax_percent: vat,
				hdn_tot_fluidmeet: price,
				hdn_tot_venue: vnPrice,
				meeting_days: meeting_days,
				accomodation_days: accommodation,
			};

			if (request.selectedLayout && parseInt(request.selectedLayout)) {
				data.space_linked = parseInt(request.selectedLayout);
			}

			if (reservation.meeting_days && reservation.meeting_days.length > 0) {
				reservation.meeting_days.forEach((item, idx) => {
					data['proposal_type' + (idx + 1)] = request['price_type_' + idx];
					data['participants' + (idx + 1)] = item.participants;

					const days = data.day ? data.day : [];
					var day: any = {};
					day.qmrd_id = item.qmrd_id;
					day.fluidmeet_price = printPriceOnly(request['fm_price_' + idx] / ((request['price_type_' + idx] == 'per-person') ? item.participants : 1), currency.name, 'USD');
					day.venue_price = printPriceOnly(request['vn_price_' + idx] / ((request['price_type_' + idx] == 'per-person') ? item.participants : 1), currency.name, 'USD');
					days.push(day);

					data['day'] = days;

				});
			}
			if (reservation.accommodation && reservation.accommodation.length > 0) {
				reservation.accommodation.forEach((item, idx) => {
					const vnPriceInt = parseInt(request['vn_accom_' + idx]);

					const days = data.acc_day ? data.acc_day : [];
					var day: any = {};
					day.qad_id = item.qad_id;
					day.fluidmeet_price = printPriceOnly(request['fm_accom_' + idx], currency.name, 'USD');
					day.venue_price = printPriceOnly(request['vn_accom_' + idx], currency.name, 'USD');
					days.push(day);

					data['acc_day'] = days;
				});
			}

			devLog({data});

			let token = getToken()

			if (!token) return;

			setSubmitLoading(true)

			const url = getApiLinkV2("host/reservations/" + reservation.quote_id);

			getAxios().put(url, data, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token,
				},
			}).then(response => {
				fetchReservation();
				showSuccessMessage(response.data.message);
				setIsPricing(false)
			}).catch(error => {
				Bugsnag.notify(error);
				devLog(error);
				showErrorMessage(error)

			}).finally(() => {
				setSubmitLoading(false)
				window.scrollTo(0,0);
			});
		}

	}

	const handleMoreSubmit = (e, option?) => {
		e?.preventDefault();
		let token = getToken()

		if (!token) return;
		setShowMoreLoading(true)

		const url = getApiLinkV2("host/reservations/" + reservation.quote_id);
		console.log({request})
		getAxios().put(url, {
			reservation_type: 'proposal',
			option: showMore ?? option,
			status: request.status,
			email: request.email,
			note: request.note,
			host_better_price_price: request.better_price,
			host_better_price_notes: request.better_price_comments,
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token,
			},
		}).then(response => {
			fetchReservation();
			showSuccessMessage(response.data.message);
		}).catch(error => {
			Bugsnag.notify(error);
			devLog(error);
			showErrorMessage(error)
		}).then(() => {
			setShowMoreLoading(false)
			toggleShowMore();
		});

	}
	const handleChange = (key, value) => {
		updateRequest({[key]: value})
	}
	const handleBetterPriceChange = (e: FormEvent) => {
		let target: any = e.target;
		// console.log({name : target.name, value : target.value})
		handleChange(target.name, target.value)
	}
	const handleSubmitBetterPrice = (e) => {
		e.preventDefault();
		let token = getToken()

		if (!token) return;
		setShowMoreLoading(true)

		let _space = space;
		if (reservation.is_generic == '1') {
			_space = selectedSpace
			console.log({reservation, tax_percent: space?.tax_percent, selectedSpace})

		}
		const vat = _space?.tax_percent ? parseInt(_space.tax_percent) : 0;

		let tot_better_price: number = 0
		let acc_day: any = []
		let day: any = []
		reservation.meeting_days?.forEach(
			(item, i) => {
				let better_price = parseFloat(request['event-day-' + i] || 0)
				tot_better_price += better_price
				day.push({qmrd_id: item.qmrd_id, better_price})
			}
		)
		reservation.accommodation?.forEach(
			(item, i) => {
				let better_price = parseFloat(request['accomm-day-' + i] || 0)
				tot_better_price += better_price
				acc_day.push({qad_id: item.qad_id, better_price})
			}
		)
		tot_better_price *= (1 + vat / 100)

		const url = getApiLinkV2("host/reservations/" + reservation.quote_id);
		let requestData = {
			reservation_type: 'proposal',
			option: "submit-better-price",
			acc_day,
			accomodation_days: acc_day.length,
			day,
			meeting_days: day.length,
			tot_better_price,
			host_better_price_notes: request.host_better_price_notes,
			better_price_validity: request.better_price_validity,
		}
		console.log({requestData})
		getAxios().put(url, requestData, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token,
			},
		}).then(response => {
			fetchReservation();
			showSuccessMessage(response.data.message);
		}).catch(error => {
			Bugsnag.notify(error);
			devLog(error);
			showErrorMessage(error)
		}).then(() => {
			setShowMoreLoading(false)
			setIsBetterPricing(false)
		});

	}

	const handleSpaceNoLongerAvailable = () => {
		console.log("no-longer-available-space outside")
		alertDialog?.current?.setMaxWidth({maxWidth: "xs", fullWidth: false});
		alertDialog?.current?.defaultWarningDialog(
			{
				onAgreeClicked: () => {
					let token = getToken()
					console.log("no-longer-available-space")
					if (!token) return;
					setShowMoreLoading(true)

					const url = getApiLinkV2("host/reservations/" + reservation.quote_id);
					let requestData = {
						reservation_type: "proposal",
						option: "no-longer-available-space"
					}
					getAxios().put(url, requestData, {
						headers: {
								'Content-Type': 'application/json',
								'Authorization': 'Bearer ' + token,
							},
						}).then(response => {
							fetchReservation();
							showSuccessMessage(response.data.message);
						}).catch(error => {
							Bugsnag.notify(error);
							devLog(error);
							showErrorMessage(error)
						}).then(() => {
							setShowMoreLoading(false)
						});
					}
			}
		);


	}
	const handleChangePrice = (key, value, index, section: 'price' | 'accom' = 'price') => {
		const removeErrors = () => {
			if (
				[
					'Fluidmeet Pricing Shouldn\'t be Higher Than Venue Pricing',
					'Fluidmeet Pricing Shouldn\'t be equal to Zero'
				].includes(errors[`fm_${section}_${index}`])
			) {
				// devLog('first if first else first if')
				delete errors[`fm_${section}_${index}`]
				setErrors({...errors})
			}
		}
		const _request = request
		_request[key] = value
		updateRequest({[key]: value})

		if (isNotNullOrUndefined(_request[`fm_${section}_${index}`]) && isNotNullOrUndefined(_request[`vn_${section}_${index}`])) {
			// devLog('first if')
			// devLog('second if first else if', parseFloat(_request[`fm_${section}_${index}`]), (_request[`fm_${section}_${index}`]))

			if (parseFloat(_request[`fm_${section}_${index}`]) > parseFloat(_request[`vn_${section}_${index}`])) {
				// devLog('second if')
				updateErrors({[`fm_${section}_${index}`]: 'Fluidmeet Pricing Shouldn\'t be Higher Than Venue Pricing'})
			} else if (parseFloat(_request[`fm_${section}_${index}`]) == 0) {
				// devLog('second if first else if', parseFloat(_request[`fm_${section}_${index}`]), (_request[`fm_${section}_${index}`]))
				updateErrors({[`fm_${section}_${index}`]: 'Fluidmeet Pricing Shouldn\'t be equal to Zero'})
			} else removeErrors()
			// devLog('first if first else')

		} else removeErrors()

	}

	const openAccordions = () => {
		console.log(accordionRefs)
		if (accordionRefs) Object.keys(accordionRefs).forEach((key) => accordionRefs[key]?.current?.open())
	}
	const togglePrice = () => {
		// if(!isPricing) openAccordions()
		setIsPricing((v) => (!v));
		setIsEditing((v) => (!v));
	}
	const toggleBetterPrice = () => {
		setIsBetterPricing((v) => (!v));
		setIsEditing((v) => (!v));
	}
	const handleShare = () => {
		toggleShowMore('email-share');
	}
	const toggleShowMore = (type?: string) => {
		setShowMore(type ?? null)
	}

	const createBookingStatusPicker = () => {
		return (
			<div className={'p-3'} style={{...styles.footer,}}>
				<div style={styles.footerLabel}>Update client status</div>
				<FMSelectInput options={{data: DDR_BOOKING_STATUSES}}
				               value={request.booking_status}
				               placeholder={'Click here'}
				               title={'none'}
				               extraClassNames={''}
				               onChange={//@ts-ignore
					               (e) => handleStatus('booking_status', e.target.value)}/>
			</div>
		)
	}
	const createMoreDialog = () => {
		return (

			<Dialog
				open={Boolean(showMore)}
				onClose={() => toggleShowMore()}
				aria-labelledby="max-width-dialog-title"

			>
				<DialogTitle>
					<div className={'d-flex flex-row justifu-content-between align-items-center'}>
						{(showMore === 'reject') ? 'Space Unavailable'
							: (showMore === 'add note') ? 'Add Note'
								: (showMore === 'email-share') ? 'Share'
									: (showMore === 'fluidmeet price') ? 'Fluidmeet Price'
										: (showMore === 'original price') ? 'Original Price'
											: (showMore === 'submit-better-price') ? 'Submit Better Price'
												: ''}
						<IconButton className={'ml-auto'} aria-label="close" onClick={() => toggleShowMore()}>
							<CloseIcon/>
						</IconButton>
					</div>
				</DialogTitle>
				<DialogContent className={'py-3'}>

					{(showMore === 'reject') ? (
						<form
							onSubmit={handleMoreSubmit}>
							<FMMultilineTextInput id='reject_note' title={'Comment'}
							                      onChange={
								                      (e: FormEvent<HTMLTextAreaElement>) => {
									                      handleChange('note', (e.target as HTMLTextAreaElement).value)
								                      }//todo check correct key supposed to be 'reject_note' but it's never accessed
							                      }
							/>
							<div className={'d-flex flex-row justify-content-end'}>
								<FMButton title={'Submit'} isLoading={showMoreLoading} extraClassNames={'col-sm-3'}/>
							</div>
						</form>
					) : (showMore === 'add note') ? (
						<form
							onSubmit={handleMoreSubmit}>
							<FMMultilineTextInput id='note' required={true} title={'none'} onChange={//@ts-ignore
								(e: FormEvent<HTMLTextAreaElement>) => {
									handleChange('note', (e.target as HTMLTextAreaElement).value)
								}
							}/>
							<div className={'d-flex flex-row justify-content-end'}>
								<FMButton title={'Add'} isLoading={showMoreLoading} extraClassNames={'col-sm-3'}/>
							</div>
						</form>
					) : (showMore === 'email-share') ? (
						<form
							onSubmit={handleMoreSubmit}>
							<FMTextInput id='email' type='email' title={'Email Address'}
							             defaultValue={''} required={true}
							             onChange={//@ts-ignore
								             (e: FormEvent<HTMLInputElement>) => {
									             handleChange('email', (e.target as HTMLInputElement).value)
								             }
							             }
							/>

							<div className={'d-flex flex-row justify-content-end'}>
								<FMButton title={'Add'} isLoading={showMoreLoading} extraClassNames={'col-sm-3'}/>
							</div>
						</form>
					) : (showMore === 'submit-better-price') ? (
						<>
							<form onSubmit={handleMoreSubmit}>
								<div className={'px-3'}>
									<FMTextInput
										id={"better_price"}
										type={'number'}
										title={"New Price (Inc. Tax)"}
										min={1} max={reservation.tot_fluidmeet_price}
										extraClassNames={'col-md-6'}
										onChange={//@ts-ignore
											(e: FormEvent<HTMLInputElement>) => {
												handleChange('better_price', (e.target as HTMLInputElement).value)
											}
										}
										required={true}
									/>
									<FMMultilineTextInput
										id={"better_price_comments"}
										title={"Your Comments"}
										onChange={//@ts-ignore
											(e: FormEvent<HTMLInputElement>) => {
												handleChange('better_price_comments', (e.target as HTMLInputElement).value)
											}
										}
									/>
								</div>
								<div className={'d-flex flex-row justify-content-end'}>
									<FMButton title={'Submit'} isLoading={showMoreLoading}
									          extraClassNames={'col-sm-3'}/>
								</div>

							</form>
						</>
					) : (
						<></>
					)}
				</DialogContent>
			</Dialog>

		)
	}

	const createHeader = () => {
		/*
				const layoutkeys = ['u_shape_chk',
					'boardroom_chk',
					'reception_chk',
					'banquet_chk',
					'theatre_chk',
					'classroom_chk',]

				const _spaces = Array<any>(15)
					.fill(null)
					.map(
						(item, index) => (
							{
								workspace_id: index,
								workspace_name: `test-${index}`,
								layouts: Object.fromEntries(layoutkeys.map((item, idx) => ([item, idx]))),
							}
						)
					);
		*/

		const isRequestForSpecificSpace = (space && Object.keys(space).length > 0);
		const isRequestForMultipleSpaces = (spaces && spaces.length > 0);
		return (
			<>
				<div className={clsx('d-flex flex-row align-items-start justify-content-between flex-wrap')}>
					{
						(() => {
							if (isRequestForSpecificSpace) return (
								<div className={clsx('d-flex flex-row flex-wrap align-items-start')}
								     style={styles.space}
									// onPress={() => this.spaceTapped(space)}
								>
									{(space.image) ? (
										<img className={"col-12 col-sm px-0"} style={styles.spaceimg}
										     src={"https://fluidmeet.s3.amazonaws.com/venue_logos/" + space.image}/>
									) : (
										<div className={"col-12 col-sm px-0"} style={styles.spaceimg}>
											<img style={{...styles.generalimg, height: 42, width: 43,}}
											     src={multiProposal}/>
										</div>
									)}

									<div className={"col-12 col-sm pt-1 pt-sm-0"} style={{}}>
										<div style={styles.spaceText}><b>Space:</b> {space.name}</div>
										<div style={styles.spaceText}><b>Venue:</b> {space.venue_name}</div>
										<div style={styles.spaceText}><b>Address:</b> {space.front_address}</div>
										<div style={styles.spaceText}>
											<b>Type:</b> {printSpaceData(space.workspace_type_id).name}</div>
									</div>
								</div>
							);
							else if (isPricing && isRequestForMultipleSpaces) return (
								<div className={'col-12 mb-4'}>
									<div style={styles.reservTitle}>Select Space</div>

									<div className={'d-flex flex-row overflow-auto flex-nowrap'}>
										{
											spaces?.map(
												(space, i) => (
													<div
														className={'col-12 col-sm-6 col-lg-4 p-3 m-3'}
														style={request.selectedLayout === space.workspace_id ? styles.colhfActive : styles.colhf}>
														<div
															style={styles.cardMapView}
															onClickCapture={() => {
																handleChange('selectedLayout', space.workspace_id)
																setSelectedSpace(space)
															}}
														>
															<img style={styles.cardImage}
																//@ts-ignore
																 src={space.image ? "https://fluidmeet.s3.amazonaws.com/venue_logos/" + space.image : multiProposal}
															/>
															<div style={styles.space_name}>
																{space.workspace_name}
															</div>
															<div className={'d-flex flex-row mt-3 flex-wrap'}>
																{Object.keys(space.layouts).map(key => (
																	<div
																		className={'d-flex flex-col mr-2 mb-1 align-items-center'}>
																		<img src={getLayoutImg(key)}/>
																		<div className={'ml-1'}
																		     style={styles.label}>{space.layouts[key]}</div>
																	</div>
																))}
															</div>
														</div>
													</div>
												)
											)
										}
									</div>

									{errors.selectedLayout && (<div style={{
										...styles.errorLabel,
										paddingTop: 0,
										paddingBottom: 10
									}}>{errors.selectedLayout}</div>)}
								</div>
							);
							else return (<></>);
						})()
					}


					<div className={'mt-2 ml-3'}>
						<div style={styles.idTitleContent}>
							<div style={styles.idTitle}>{'#' + reservation?.unique_id}</div>
						</div>
						<div style={styles.statusContent}>
							<b>Status:</b> {Boolean(reservation) && DdrUtils.printHostProposalStatus(reservation)}
						</div>
					</div>

				</div>
			</>
		)
	}
	const createRequestDetails = () => {
		return (
			<>
				<div className={'mt-3'}>
					<div style={styles.sectionhead}>
						Request Details
					</div>


					<div className={'ml-3'} style={{paddingBottom: 4}}>
						<div className={'d-flex flex-row flex-wrap'} style={styles.pb2}>
							<div className={'col-12 col-md-6'}>
								<div style={styles.pb2}>
									<div style={{...styles.reservSubtitle, paddingBottom: 6}}>Event Type</div>
									<div style={styles.reservText}>{reservation.event_type}</div>
								</div>
								<div style={styles.pb2}>
									<div style={{...styles.reservSubtitle, paddingBottom: 6}}>Event Dates</div>
									<div style={styles.reservText}>
										{moment(reservation.start_date_time).format('YYYY') !== moment().format('YYYY') ? (
											moment(reservation.start_date_time).format('ddd, MMM DD YYYY')
										) : (
											moment(reservation.start_date_time).format('ddd, MMM DD')
										)}
										{reservation.meeting_days?.length > 1 && (
											moment(reservation.end_date_time).format('YYYY') !== moment().format('YYYY') ? (
												' - ' + moment(reservation.end_date_time).format('ddd, MMM DD YYYY')
											) : (
												' - ' + moment(reservation.end_date_time).format('ddd, MMM DD')
											)
										)}
										{(reservation.meeting_days?.length > 1) ? ' (' + reservation.meeting_days?.length + ' days)' : ' (1 day)'}
									</div>
								</div>
							</div>
							<div className={'col-12 col-md-6'}>
								<div style={styles.pb2}>
									<div style={{...styles.reservSubtitle, paddingBottom: 6}}>Approximate Budget
										({reservation.budget_type})
									</div>
									<div style={styles.reservText}>
										{printPrice(reservation.budget, reservation.budget_curr, currency.name, true)}
									</div>
								</div>
								<div style={styles.pb2}>
									<div style={{...styles.reservSubtitle, paddingBottom: 6}}>Event Description</div>
									<div style={styles.reservText}>{reservation.event_description}</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</>
		)
	}
	const createClientDetails = () => {


		return (
			<>
				<div className={'my-3'}>
					<div className={'mt-3'} style={styles.sectionhead}>
						Client Details
					</div>


					<div className={'ml-3 d-flex flex-row flex-wrap'}>


						{infoSection(faUser, 'Client Name', reservation.first_name + ' ' + reservation.last_name)}


						{
							(reservation.tot_fluidmeet_price > 0
								&& reservation.quote_status !== 'declined by seeker'
								&& reservation.quote_status !== 'expired'
								&& ((new Date()) <= (new Date(reservation.start_date))))
							&& (
								<>
									{infoSection(faEnvelope, 'Client Email', reservation.email)}
									{infoSection(faPhoneAlt, 'Client Phone Number', reservation.phone)}
								</>
							)
						}

					</div>
				</div>

			</>
		)
	}
	const createAccommodationsDetails = () => {
		return (
			<>
				<div>
					<div style={{...styles.sectionhead, paddingBottom: 8}}>
						Accommodation Details
					</div>
				</div>


				<div style={{marginBottom: 20}}>
					{reservation.accommodation.map((item, i) => (
						<AccordionController ref={accordionRefs?.[`accomm-day-${i}`]}>
							<Accordion
								id={`accomm-day-${i}`}
								key={i}
								classes={{
									root: classes.accordionRoot
								}}
								variant={'elevation'}
								defaultExpanded={i === 0 || isPricing}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls={`accom-${i}-content`}
									id={`accom-${i}-header`}
								>
									<div style={{...styles.reservTitle, marginTop: 12,}}>
										Day {i + 1} - {printDateFromDateTime(item.acc_date)}
									</div>
								</AccordionSummary>

								<AccordionDetails className={'d-flex flex-row flex-wrap'}>

									<div className={'col-12 d-flex flex-row flex-wrap'}>
										{(item.single_room > 0) && infoSection(null, "Single Rooms", item.single_room + ' Room' + ((item.single_room > 1) ? 's' : ''))}
										{(item.double_room > 0) && infoSection(null, "Double Rooms", item.double_room + ' Room' + ((item.double_room > 1) ? 's' : ''))}
										{(item.twin_room > 0) && infoSection(null, "Double Rooms", item.twin_room + ' Room' + ((item.twin_room > 1) ? 's' : ''))}
										{infoSection(null, "Breakfast Included", (item.chk_bed_breakfast === 'yes') ? "Yes" : "No")}
									</div>

									{(isPricing && !isQuoteExpired) && (

										<div className={'col-12 mt-3 '}>
											<div className={'p-4 '}
											     style={{...styles.proposal, marginTop: 4, marginBottom: 4}}>

												<div style={styles.reservTitle}>
													Pricing (exclusive of VAT)
												</div>

												<div className={'col-12 d-flex flex-row flex-wrap align-items-start'}>
													{['fm_accom_', 'vn_accom_'].map(
														key => (
															<div className={'col-md-6 col-12 mb-4 align-items-start'}>

																<div className={'d-flex flex-row align-items-center'}>
																	<div style={styles.label}>
																		{(key === 'vn_accom_') ? 'Original Price' : 'Fluidmeet Price'} ({currency.icon})
																	</div>
																	<Tooltip
																		classes={{
																			tooltip: classes.tooltip,
																		}}
																		title={(key === 'vn_accom_') ? 'Rates offered to clients when they call venue directly.' : 'Special/ discounted rates offered to fluidmeet clients.'}>
																		<div style={{
																			...styles.callBtn,
																			height: 18,
																			width: 18
																		}}>
																			<FontAwesomeIcon style={{height: 14, width: 14}}
																			                 icon={faInfo} color={'white'}/>
																		</div>
																	</Tooltip>
																</div>

																<div className={'d-flex flex-row align-items-center'}>
																	<div>
																		<FMTextInput
																			id={key + i}
																			type={'number'}
																			placeholder={'Enter Price'}
																			defaultValue={request[key + i]}
																			onChange={(e: FormEvent<HTMLInputElement>) => {// @ts-ignore
																				handleChangePrice(key + i, e.target.value, i, "accom")
																			}}
																			min={1}
																		/>
																	</div>
																</div>

																{errors[key + i] && (
																	<div style={styles.errorLabel}>{errors[key + i]}</div>)}
															</div>
														)
													)}
												</div>
											</div>
										</div>
									)}

								</AccordionDetails>
							</Accordion>
						</AccordionController>
					))}
				</div>

			</>
		)

	}
	const createEventDetails = () => {
		let amenties = reservation.meeting_days.map(
			(meeting_day) => (
				CATERED_AMENITIES
					.filter(({key}) => meeting_day['chk_' + key] == 'yes')
					.map(({key, label}) => (meeting_day[key + '_qty'] ?? '') + ' ' + label).join(', ')
			)
		)
		return (
			<>
				<div>
					<div id={'event-details-title'} style={{...styles.sectionhead, paddingBottom: 8}}>
						Event Details
					</div>
				</div>
				{reservation.meeting_days && reservation.meeting_days.length > 0 && (
					<div style={{marginBottom: 20}}>
						{reservation.meeting_days.map((item, i) => (

							<AccordionController ref={accordionRefs?.[`event-day-${i}`]}>
								<Accordion
									id={`event-day-${i}`}
									key={i}
									classes={{
										root: classes.accordionRoot
									}}
									variant={'elevation'}
									defaultExpanded={i === 0 || isPricing}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon/>}
										aria-controls={`${i}-content`}
										id={`${i}-header`}
									>
										<div style={{...styles.reservTitle, marginTop: 12,}}>
											Event Day {i + 1} - {printDateFromDateTime(item.meeting_date)}
										</div>
									</AccordionSummary>
									<AccordionDetails className={'d-flex flex-row flex-wrap'}>


										{
											infoSection(null,
												'Event Time',
												moment("2021-01-01 " + item.start_time).format('hh:mm A')
												+ " to " + moment("2021-01-01 " + item.end_time).format('hh:mm A')
											)
										}
										{
											infoSection(null,
												'Layout & Capacity',
												<div><img
													src={getLayoutImg(item.room_layout)}/> {`${getLayoutName(item.room_layout)} (${item.participants} people)`}
												</div>
											)
										}


										{item.chk_breakfast === 'yes' && (

											infoSection(
												null,
												'Breakfast',
												(item.chk_breakfast === 'yes') ?
													((item.rdo_breakfast_other !== '') ?
														item.rdo_breakfast_other : item.rdo_breakfast) : '-'
											)
										)
										}
										{
											item.chk_tea_coffee === 'yes' && (

												infoSection(null,
													'Tea & Coffee Break',
													(item.chk_tea_coffee === 'yes') ?
														((item.rdo_tea_coffee_other !== '') ? item.rdo_tea_coffee_other : item.rdo_tea_coffee) : '-')

											)
										}
										{item.chk_lunch === 'yes' && (

											infoSection(null,
												'Lunch',
												(item.chk_lunch === 'yes') ? (
														<>
															{(item.rdo_lunch_other === '') ? item.rdo_lunch : item.rdo_lunch_other}{', '}
															{(item.rdo_lunch_drinks_other !== '') ? item.rdo_lunch_drinks_other : item.rdo_lunch_drinks}
														</>
													) :
													'-'
											)
										)
										}
										{item.chk_dinner === 'yes' && (

											infoSection(null,
												'Dinner',
												(item.chk_dinner === 'yes') ? (
													<>
														{(item.rdo_dinner_other === '') ? item.rdo_dinner : item.rdo_dinner_other}{', '}
														{(item.rdo_dinner_drinks_other !== '') ? item.rdo_dinner_drinks_other : item.rdo_dinner_drinks}
													</>
												) : '-')
										)}

										{item.chk_drinks === 'yes' && (

											infoSection(null,
												'Drinks Reception',
												(item.rdo_drinks_other !== '') ? item.rdo_drinks_other + ' for ' + item.participants + ' people' : 'For ' + item.participants + ' people')
										)}
										{
											infoSection(null,
												'Amenities',
												amenties[i]
												// '1 Projector & Screen, 1 Flipcart, Wifi'
											)
										}

										{(isPricing && !isQuoteExpired) && (
											<div className={'col-12 '}>
												<div className={'p-4 '}
												     style={{...styles.proposal, marginTop: 4, marginBottom: 4}}>
													<div style={styles.reservTitle}>
														Pricing (exclusive of VAT)
													</div>

													<div className={'col-12 d-flex flex-row flex-wrap align-items-start'}>
														<div className={'col-12 mb-4 d-flex flex-wrap align-items-start'}>

															<div style={styles.label}>Price type</div>

															<FMSelectInput
																options={{data: price_types}}
																value={request['price_type_' + i]}
																placeholder={'Select type'}
																title={'none'}
																onChange={(e) => {// @ts-ignore
																	handleChange('price_type_' + i, e.target.value)
																}}
															/>

															{errors['price_type_' + i] && (<div
																style={styles.errorLabel}>{errors['price_type_' + i]}</div>)}
														</div>
														{
															Boolean(request['price_type_' + i]) &&
															['fm_price_', 'vn_price_'].map(
																key => (
																	<div
																		className={'col-md-6 col-12 mb-4 align-items-start'}>

																		<div
																			className={'d-flex flex-row align-items-start'}>
																			<div style={styles.label}>
																				{(key === 'vn_price_') ? 'Original Price' : 'Fluidmeet Price'} ({currency.icon})
																			</div>
																			<Tooltip
																				classes={{
																					tooltip: classes.tooltip,
																				}}
																				title={(key === 'vn_price_') ? 'Rates offered to clients when they call venue directly.' : 'Special/ discounted rates offered to fluidmeet clients.'}>
																				<div style={{
																					...styles.callBtn,
																					height: 18,
																					width: 18
																				}}>
																					<FontAwesomeIcon
																						style={{height: 14, width: 14}}
																						icon={faInfo} color={'white'}/>
																				</div>
																			</Tooltip>
																		</div>

																		<div
																			className={'d-flex flex-row align-items-center mb-3'}>
																			<div>
																				<FMTextInput
																					id={key + i}
																					classes={{input: 'mb-0'}}
																					type={'number'}
																					placeholder={'Enter Price'}
																					defaultValue={(request[key + i] / ((request['price_type_' + i] === 'per-person') ? (item.participants === "" ? 1 : item.participants) : 1)).toString()}
																					onChange={(e: FormEvent<HTMLInputElement>) => {// @ts-ignore
																						handleChangePrice(key + i, e.target.value * ((request['price_type_' + i] === 'per-person') ? (item.participants === "" ? 1 : item.participants) : 1), i)
																					}}
																					min={1}
																				/>
																			</div>

																			<div style={styles.perlabel}>
																				/ {request['price_type_' + i] === 'per-person' ? 'person' : 'day'}
																			</div>
																		</div>

																		<div className={'ml-3'} style={{...styles.label}}>
																			{(request[key + i] && request[key + i] > 0) ? (
																				'Total: ' + (printPrice(request[key + i], currency.name, currency.name))
																			) : printPrice(0, currency.name, currency.name)}
																		</div>

																		{errors[key + i] && (<div
																			style={styles.errorLabel}>{errors[key + i]}</div>)}
																	</div>
																)
															)
														}

													</div>

												</div>
											</div>
										)}
									</AccordionDetails>
								</Accordion>
							</AccordionController>
						))}
					</div>
				)}
			</>
		)
	}


	const printProposal = () => {
		const getDivider = () => (
			<Divider className={'fill-available'} style={{backgroundColor: blueGrey[200], margin: ".5rem 1rem"}}/>)
		const ProposalSection = ({title, content}) => {
			return <div className={'d-flex col-12 flex-wrap'}>
				<div className={'d-flex col-sm-5 col-4 align-items-center my-1 py-1'}>
					{title}
				</div>
				<div className={'d-flex flex-row col-sm-7 col-8 justify-content-end align-items-center'}>
					{content}
				</div>
			</div>
		}
		const ProposalPricingSection = ({title, first_column, second_column}) => {
			let content = <>
				<div className={'col-6 col-lg-3'}>
					{first_column}
				</div>

				{
					(isBetterPricing || reservation.host_better_price_submit_date) &&
					<div
						className={'col-6 col-lg-3'}
						style={{...styles.reservText, fontWeight: 500, textAlign: 'left'}}>
						{second_column}
					</div>

				}
			</>
			return ProposalSection({title, content})
		}
		let _space = space;
		if (reservation.is_generic == '1') {
			_space = selectedSpace || space
			console.log({reservation, tax_percent: space?.tax_percent, selectedSpace})
		}
		const vat = _space?.tax_percent ? parseInt(_space.tax_percent) : 0;
		let tot_better_price: number = 0
		if (isBetterPricing) {
			reservation.meeting_days?.forEach(
				(_, i) => {
					tot_better_price += parseFloat(request['event-day-' + i] || 0)
				}
			)
			reservation.accommodation?.forEach(
				(_, i) => {
					tot_better_price += parseFloat(request['accomm-day-' + i] || 0)
				}
			)
			tot_better_price *= (1 + (vat / 100))
		} else if (reservation.tot_better_price) tot_better_price = reservation.tot_better_price

		return (
			<form
				onChange={handleBetterPriceChange}
				onSubmitCapture={handleSubmitBetterPrice}
			>
				<div className={'p-3'} style={styles.proposal}>

					{
						reservation.meeting_days && (

							<div className={'d-flex flex-row flex-wrap'}>

								{

									ProposalPricingSection(
										{
											title:
												<div style={{
													...styles.reservText,
													fontWeight: 500,
													textAlign: 'left',
													fontSize: '1.2rem'
												}}>
													Event Pricing
												</div>
											,
											first_column: (isBetterPricing || reservation.host_better_price_submit_date) &&
												<div style={{
													...styles.reservText,
													fontWeight: 500,
													textAlign: 'left',
													fontSize: '1rem'
												}}>Original Price</div>,
											second_column: (isBetterPricing || reservation.host_better_price_submit_date) &&
												<div style={{
													...styles.reservText,
													fontWeight: 500,
													textAlign: 'left',
													fontSize: '1rem'
												}}>Better Price</div>
										}
									)
								}
								{
									reservation.meeting_days.map(
										(item, i) =>
											ProposalPricingSection({
												title:
													<div style={{
														...styles.reservSubtitle,
														fontSize: 16,
														cursor: 'pointer',
														textDecoration: 'underline',
														color: blue[500]
													}}
													     onClick={() => {
														     window.scrollTo(0, (document.getElementById(`event-day-${i}`)?.offsetTop ?? 40) - 40);
														     console.log({accordionRefs})
														     accordionRefs?.[`event-day-${i}`]?.current?.open();
													     }}>
														Event Day {i + 1} - {printDateFromDateTime(item.meeting_date)}
													</div>,
												first_column: <div
													style={{...styles.reservText, fontSize: 16, textAlign: 'left'}}>
													{printPrice(item.fluidmeet_price || 0, 'USD', currency.name, true)}
												</div>,
												second_column: (isBetterPricing) ? <FMTextInput
														id={`event-day-${i}`}
														type={'number'}
														min={1} max={item.fluidmeet_price}
														classes={{
															input: "p-1 mb-0 rounded"
														}}
														extraClassNames={"px-0"}
														required={true}/>
													:
													(reservation.host_better_price_submit_date) ?
														<div style={{
															...styles.reservText,
															fontSize: 16,
															textAlign: 'left'
														}}>
															{printPrice(item.better_price || 0, 'USD', currency.name, true)}
														</div>
														: <></>
											})
									)
								}
								{getDivider()}
								{
									reservation.accommodation?.length > 0 && (
										<>
											{
												ProposalPricingSection(
													{
														title:
															<div style={{
																...styles.reservText,
																fontWeight: 500,
																textAlign: 'left',
																fontSize: '1.2rem'
															}}>
																Accommodation Pricing
															</div>
														,
														first_column: (isBetterPricing || reservation.host_better_price_submit_date) &&
															<div style={{
																...styles.reservText,
																fontWeight: 500,
																textAlign: 'left',
																fontSize: '1rem'
															}}>Original Price</div>,
														second_column: (isBetterPricing || reservation.host_better_price_submit_date) &&
															<div style={{
																...styles.reservText,
																fontWeight: 500,
																textAlign: 'left',
																fontSize: '1rem'
															}}>Better Price</div>
													}
												)
											}
											{
												reservation.accommodation.map(
													(item, i) => (
														ProposalPricingSection({
															title:
																<div style={{
																	...styles.reservSubtitle,
																	fontSize: 16,
																	cursor: 'pointer',
																	textDecoration: 'underline',
																	color: blue[500]
																}}
																     onClick={() => {
																	     window.scrollTo(0, (document.getElementById(`accomm-day-${i}`)?.offsetTop ?? 40) - 40);
																	     accordionRefs?.[`accomm-day-${i}`]?.current?.open();
																     }}
																>
																	Day {i + 1} - {printDateFromDateTime(item.acc_date)}
																</div>,
															first_column: <div
																style={{...styles.reservText, textAlign: 'left'}}>
																{printPrice(item.fluidmeet_price || 0, 'USD', currency.name, true)}
															</div>,
															second_column: (isBetterPricing) ? <FMTextInput
																	id={`accomm-day-${i}`}
																	type={'number'}
																	min={1} max={item.fluidmeet_price}
																	classes={{
																		input: "p-1 mb-0 rounded"
																	}}
																	extraClassNames={"px-0"}
																	required={true}
																/>
																:
																(reservation.host_better_price_submit_date) ?
																	<div style={{
																		...styles.reservText,
																		fontSize: 16,
																		textAlign: 'left'
																	}}>
																		{printPrice(item.better_price || 0, 'USD', currency.name, true)}
																	</div>
																	: <></>


														})

													)
												)
											}
											{getDivider()}
										</>
									)
								}
								{
									ProposalPricingSection({
										title: <div style={{
											...styles.reservSubtitle,
											fontSize: 16,
											fontWeight: 500
										}}>Total Price excluding VAT
										</div>,
										first_column: <div
											style={{...styles.reservText, fontWeight: 500, textAlign: 'left'}}>
											{printPrice((reservation.tot_fluidmeet_price || 0) / (1 + 0.01 * vat), 'USD', currency.name, true)}
										</div>,
										second_column: <div>
											{printPrice((tot_better_price || 0) / (1 + 0.01 * vat), 'USD', currency.name, true)}
										</div>
										,
									})
								}
								{
									ProposalPricingSection({
										title: <div style={{
											...styles.reservSubtitle,
											fontSize: 16,
											fontWeight: 500
										}}>{`VAT ${vat}%`}
										</div>,
										first_column: <div
											style={{...styles.reservText, fontWeight: 500, textAlign: 'left'}}>
											{printPrice((reservation.tot_fluidmeet_price * (vat / 100) / (1 + (vat / 100))), "USD", currency.name, true)}
										</div>,
										second_column: <div>
											{printPrice((tot_better_price * (vat / 100) / (1 + (vat / 100))), "USD", currency.name, true)}
										</div>,
									})
								}

								{getDivider()}
								{
									ProposalPricingSection({
										title: <div style={{
											...styles.reservSubtitle,
											fontSize: 16,
											fontWeight: 500
										}}>Total Price
										</div>,
										first_column: <div
											style={{...styles.reservText, fontWeight: 500, textAlign: 'left'}}>
											{printPrice(reservation.tot_fluidmeet_price || 0, 'USD', currency.name, true)}
										</div>,
										second_column: <div>
											{printPrice(tot_better_price || 0, 'USD', currency.name, true)}
										</div>
										,
									})
								}


							</div>
						)
					}
					{
						(isBetterPricing) ? (
								<>
									{ProposalSection({
										title: <div className={'my-auto'} style={{
											...styles.reservSubtitle,
											fontSize: 16,
											fontWeight: 500
										}}>Proposal validity
										</div>,
										content: <FMSelectInput
											id={'better_price_validity'}
											options={{data: validities}}
											value={request.validity}
											placeholder={'Select validity'}
											title={'none'}
											extraClassNames={'col-md-6'}
											error={errors.validity}
											classes={{
												select: "p-1 mb-0 rounded"
											}}
											required={true}
										/>,
									})}
									<FMMultilineTextInput id='host_better_price_notes' title={'Comments'} rows={'3'}/>

								</>
							)
							:
							(reservation.expired === 'yes') ? (
									<div style={styles.quoteDecline}>
										Proposal expired on {moment(reservation.expire_on).format('MMM D,' +
										' YYYY')}
									</div>
								)
								:
								(
									reservation.expire_on && (<>
										<div className={'d-flex col-12 my-2'}>
											<div className={'col-12 d-flex flex-row justify-content-start'}
											     style={{
												     ...styles.head,
												     marginTop: 0,
												     marginBottom: 0,
												     alignItems: 'center'
											     }}>

												<div style={styles.idTitleContent}>
													<div style={{...styles.idTitle, fontWeight: 500}}>Expiry:</div>
												</div>

												<div
													style={styles.quoteDecline}>{moment(reservation.expire_on, 'DD-MM-YYYY').format('MMM D, YYYY')}</div>

											</div>
										</div>
									</>)
								)
					}
					{Boolean(reservation.host_better_price_submit_date) &&

					<div className={'d-flex col-12 my-2'}>
						<div className={'col-12 d-flex flex-row justify-content-start'}
						     style={{
							     ...styles.head,
							     marginTop: 0,
							     marginBottom: 0,
							     alignItems: 'center'
						     }}>

							<div style={styles.idTitleContent}>
								<div style={{...styles.idTitle, fontWeight: 500}}>Submitted Better Pricing on:</div>
							</div>

							<div
								style={styles.quoteDecline}>{moment(reservation.host_better_price_submit_date).format('MMM D, YYYY')}</div>

						</div>
					</div>
					}
					{
						reservation.seeker_better_price_request_date && (
							(isBetterPricing) ?
								(
									<Fragment>
										<div className={'d-flex flex-row justify-content-end flex-wrap pt-3'}>
											<div className={'d-flex col-12 col-sm-3 py-1 px-1'}>
												<FMButton
													title={"Cancel"}
													extraClassNames={'px-0 py-1'}
													onClick={toggleBetterPrice}
													extraProps={
														{
															style: {
																borderColor: red[500],
																backgroundColor: red[500],
																color: 'white',
															}
														}
													}
												/>
											</div>
											<div className={'d-flex col-12 col-sm-3 py-1 px-1'}>
												<FMButton title={"Submit"} extraClassNames={'px-0 py-1'}/>
											</div>

										</div>
									</Fragment>
								)
								:
								(
									<>
										<Divider style={{
											width: '100%',
											backgroundColor: blueGrey[300],
											margin: '2rem -1rem 0.5rem',
											padding: '.01rem 1rem'
										}}/>
										{createBetterPriceSection()}
									</>
								)
						)
					}

				</div>
			</form>
		)
	}

	const printPrices = () => {

		var price = 0;
		var vn_price = 0;
		let _space = space;
		if (reservation.is_generic == '1') {
			_space = selectedSpace
			console.log({reservation, tax_percent: space?.tax_percent, selectedSpace})

		}
		const vat = _space?.tax_percent ? parseInt(_space.tax_percent) : 0;

		if (reservation.meeting_days && reservation.meeting_days.length > 0) {
			reservation.meeting_days.forEach((item, idx) => {
				const priceInt = parseInt(request['fm_price_' + idx]);
				const vnPriceInt = parseInt(request['vn_price_' + idx]);
				price += (priceInt && priceInt > 0) ? priceInt : 0;
				vn_price += (vnPriceInt && vnPriceInt > 0) ? vnPriceInt : 0;
			});
		}
		if (reservation.accommodation && reservation.accommodation.length > 0) {
			reservation.accommodation.forEach((item, idx) => {
				const priceInt = parseInt(request['fm_accom_' + idx]);
				const vnPriceInt = parseInt(request['vn_accom_' + idx]);
				price += (priceInt && priceInt > 0) ? priceInt : 0;
				vn_price += (vnPriceInt && vnPriceInt > 0) ? vnPriceInt : 0;
			});
		}

		return <div className={'d-flex flex-row flex-wrap'}>
			{
				[
					{key: 'subtotal', title: 'Subtotal', value: printPrice(price, currency.name, currency.name)},
					{
						key: 'vat',
						title: `VAT ${vat}%`,
						value: printPrice((price * (vat / 100)), currency.name, currency.name)
					},
					{
						key: 'vn_total',
						title: <span style={{fontWeight: 600}}>Original Price</span>,
						value: printPrice((vn_price + (vn_price * (vat / 100))), currency.name, currency.name)
					},
					{
						key: 'total',
						title: <span style={{fontWeight: 600}}>Total Price</span>,
						value: printPrice((price + (price * (vat / 100))), currency.name, currency.name)
					},
				].map(({key, title, value}) => (

					<div key={key} className={'d-flex flex-row col-12 justify-content-end '} style={styles.grid}>
						<div className={'col col-md-3 '}
						     style={{...styles.reservSubtitle, paddingBottom: 16}}>
							{title}
						</div>
						<div className={'col col-md-2 justify-content-end d-flex '} style={styles.reservText}>
							{value}
						</div>
					</div>
				))
			}
		</div>


	}

	const createPriceSummary = () => {
		const open = Boolean(menuAnchor);
		const handleOpenMenu = (event) => {
			setMenuAnchor(event.currentTarget);
		};
		const handleCloseMenu = () => {
			setMenuAnchor(null);
		};
		return (
			<>
				{((reservation.tot_fluidmeet_price > 0 && !(isPricing))) ? (
					<>
						<div>
							<div style={{...styles.sectionhead, paddingBottom: 8}}>
								Pricing Details
								<IconButton
									id="basic-button"
									aria-controls="basic-menu"
									aria-haspopup="true"
									aria-expanded={open ? 'true' : undefined}
									onClick={handleOpenMenu}
								>
									<MoreVertIcon/>
								</IconButton>
								<Menu
									id="basic-menu"
									anchorEl={menuAnchor}
									open={open}
									onClose={handleCloseMenu}
									MenuListProps={{
										'aria-labelledby': 'basic-button',
									}}
								>
									{
										(!['paid', 'expired', 'rejected'].includes(getHostProposalStatus(reservation)) && !reservation.seeker_better_price_request_date) &&
										<MenuItem onClick={() => {
											togglePrice()
											handleCloseMenu()
										}
										}>Edit</MenuItem>
									}
									<MenuItem onClick={() => {
										handleShare()
										handleCloseMenu()
									}}>Share</MenuItem>
								</Menu>
							</div>
							{printProposal()}
						</div>
					</>
				) : (
					(isPricing) ? (
						<div style={{marginBottom: 10}}>

							<div style={styles.sectionTitle}>Total Pricing</div>
							{printPrices()}

							<div style={styles.text}>
								Note: Use the client budget as a guide. If budget is higher, offer your regular prices
								and
								not inflated prices since client will probably be looking at multiple properties. If
								budget
								is lower than expected, try to price as close as possible to budget by customizing menu.
							</div>

							<div>
								<div style={{...styles.label, marginTop: 16}}>Proposal validity</div>
								<FMSelectInput options={{data: validities}}
								               value={request.validity}
								               placeholder={'Select validity'}
								               title={'none'}
								               extraClassNames={'col-md-6'}
								               onChange={//@ts-ignore
									               (e) => handleChange('validity', e.target.value)}/>

								{errors.validity && (<div style={styles.errorLabel}>{errors.validity}</div>)}
							</div>
							<div className={'d-flex justify-content-end'}>
								<FMButton
									title={'Cancel'}
									isLoading={submitLoading}
									onClick={togglePrice}
									extraClassNames={'col-sm-3 py-1'}
								/>
								<FMButton
									title={'Submit'}
									isLoading={submitLoading}
									onClick={handleSubmit}
									extraClassNames={'col-sm-3 py-1'}
								/>

							</div>

						</div>
					) : (
						<div className={'d-flex justify-content-end'}>
							<FMButton
								title={'Price'}
								isLoading={submitLoading}
								onClick={togglePrice}
								extraClassNames={'col-sm-3 py-1'}
							/>

						</div>
					)
				)}
			</>
		);
	}

	const createBetterPriceSection = () => {
		console.log({seeker_better_price_notes: reservation.seeker_better_price_notes})
		if (reservation.seeker_better_price_request_date)
			return (
				<div className={'pt-3 px-4'}>
					<div style={{...styles.sectionhead, paddingBottom: 0, color: green[700]}}>
						Client is interested in booking your space and has requested a better price
					</div>

					<div className={'py-1 mb-2'}>
						{(reservation.seeker_better_price_notes) &&
						<div className={"p-2 rounded"} style={{backgroundColor: blue[50]}}>
							<div style={{...styles.sectionhead, paddingBottom: 5, fontWeight: 500}}>Client's comments
							</div>
							<div className={"px-2"} style={{fontSize: 16}}>{reservation.seeker_better_price_notes}</div>
						</div>
						}
						{
							((reservation.host_better_price_submit_date || reservation.host_better_price_reject_date)) ?
								(
									(reservation.host_better_price_notes) &&
									<div className={"p-3 rounded"} style={{backgroundColor: blue[50]}}>
										<div style={{...styles.sectionhead, paddingBottom: 5, fontWeight: 500}}>
											Your comments
										</div>
										<div className={"px-2"}
										     style={{fontSize: 16}}>{reservation.host_better_price_notes}</div>
									</div>

								)
								:
								(
									<div className={'d-flex flex-row justify-content-start flex-wrap py-2'}>
										<div className={'d-flex col-12 col-sm-5 col-lg-3 py-1 px-0'}>
											<FMButton
												title={"Submit new pricing"}
												extraClassNames={'px-0 py-1'}
												onClick={toggleBetterPrice}
											/>
										</div>
										<div className={'d-flex py-1 pl-3 px-2 align-items-end'}>
											<a
												href="?action=space-nol-longer-available"
												onClick={(e) => {
													e?.preventDefault();
													handleSpaceNoLongerAvailable()
												}}
												style={{
													color: red[900],
													fontWeight: 500
												}}
											>
												<u>Is the space no longer available?</u>
											</a>
										</div>

									</div>
								)

						}
					</div>

				</div>

			);
	}

	const createNotesList = () => {

		let notes = reservation.notes?.filter((note) => (note.created_by == user.id))

		return (
			<div className={'py-3'}>
				<Accordion
					classes={{
						root: classes.accordionRoot
					}}
					defaultExpanded={true}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon/>}
						aria-controls={`notes-content`}
						id={`notes-header`}
					>

						<div className={'col-12 d-flex flex-row justify-content-between'}>
							<div style={styles.sectionhead}>Notes</div>

							{/*<div className={'cursor-pointer'} style={styles.btn}*/}
							{/*     onClick={() => toggleShowMore('add note')}>*/}
							{/*	Add Note*/}
							{/*</div>*/}

						</div>

					</AccordionSummary>


					{notes.length > 0 &&

					(
						<AccordionDetails className={'d-flex flex-column px-5'}>
							{notes.map((item, i) => (

									<div key={i} style={{marginTop: 8}}>
										<div style={{
											...styles.reservSubtitle,
											paddingBottom: 5
										}}>{item.user_name} ({printDateFromDateTime(item.created_on)}):
										</div>
										<div style={styles.reservText}>{item.note}</div>
									</div>
								)
							)
							}
						</AccordionDetails>
					)
					}
					<AccordionActions>
						<div className={'cursor-pointer'} style={styles.btn}
						     onClick={() => toggleShowMore('add note')}>
							Add Note
						</div>
					</AccordionActions>
				</Accordion>
			</div>

		);
	}
	const showStatusPicker = reservation.tot_fluidmeet_price >= 0 && !isPricing
	const sm = useMediaQuery("(max-width: 576px)")
	const navHeight = useMemo(() => document.getElementById('nav')?.clientHeight ?? 80, [sm])
	return (
		<div className="container-fluid" style={{position: 'relative', marginTop: navHeight}}>
			<div className=" d-flex flex-row-reverse flex-wrap pr-0 pl-0 align-items-top">

				{(showStatusPicker) && (
					<div className="mt-sm-10 col-12 col-sm-4 px-sm-2 px-0 mb-auto" style={{
						top: (navHeight + ((sm) ? 0 : 20)),
						position: 'sticky',
						transition: '.6s',
						zIndex: 10
					}}>

						{createBookingStatusPicker()}

					</div>
				)}

				<div className={clsx("page", (showStatusPicker) ? "col-12 col-sm-8 px-sm-5" : "container")}
				     style={{transition: '.6s'}}>

					<div className={`row page-lg-title mt-4 mb-5 `}>
						Event Inquiry
					</div>
					{
						(loading) ? (
								<div className={clsx('d-flex col-12 justify-content-center')}>
									<CircularProgress className={clsx('mx-auto my-5')}/>
								</div>
							) :
							(Boolean(reservation) && (<>
									{createHeader()}

									<Divider className={'my-1'}/>
									{createRequestDetails()}
									<Divider/>
									{createClientDetails()}
									<Divider className={'mb-3'}/>
									<form id={"proposal-rfq-form"} onSubmit={(e) => e.preventDefault()}>
										{createEventDetails()}
										<Divider className={'mb-3'}/>
										{reservation.accommodation && reservation.accommodation.length > 0 &&
										<>
											{createAccommodationsDetails()}
											<Divider className={'mb-3'}/>
										</>
										}

										{!isQuoteExpired && createPriceSummary()}
										{((reservation.tot_fluidmeet_price >= 0 && !(isPricing))) && createNotesList()}
										{createMoreDialog()}
									</form>
								</>)

							)
					}

					<AlertSnackbar ref={snackbar}/>
				</div>
			</div>
		</div>
	)
}
const styles = {
	baseView: {
		display: 'none',
		zIndex: -1,
		backgroundColor: 'rgba(0, 0, 0, 0.3)',
	},
	baseViewAct: {
		flex: 1,
		position: 'absolute' as const,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 2,
		backgroundColor: 'rgba(0, 0, 0, 0.3)',
	},
	container: {
		backgroundColor: 'white',
		flex: 1,
	},
	content: {
		flex: 1,
		paddingHorizontal: 20,
	},
	space: {
		marginTop: 6,
		paddingBottom: 16,
		flexDirection: 'row' as const,
		alignItems: 'center' as const,
		borderBottomColor: '#CFD8E7',
		borderBottomWidth: 0.5,
	},
	spaceimg: {
		height: 200,
		resizeMode: 'cover',
		borderRadius: 10,
		backgroundColor: '#E5E7E9',
	},
	generalimg: {
		resizeMode: 'contain',
		marginTop: 'auto',
		marginBottom: 'auto',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	spacedetails: {
		flex: 1,
		flexShrink: 1,
		flexDirection: 'column' as const,
		paddingLeft: 14,
		overflow: 'hidden',
	},
	spaceTitle: {
		color: '#212529',
		paddingBottom: 3,
		paddingRight: 50,
		overflow: 'hidden' as const,
		fontWeight: 600,
		fontSize: 18,
	},
	spaceText: {
		color: '#212529',
		paddingBottom: 3,
		overflow: 'hidden' as const,
		fontSize: 16,
	},
	map: {
		width: '100%',
		height: '100%',
		borderRadius: 12,
	},
	mapContainer: {
		marginTop: 12,
		marginBottom: 20,
		backgroundColor: '#cfd8e7',
		borderRadius: 12,
		borderColor: '#CFD8E7',
		borderWidth: 1,
		width: '100%',
		// height: (Dimensions.get('window').width - 50),
	},
	ratingTitle: {
		color: '#CFD8E7',
		paddingBottom: 10,
		overflow: 'hidden' as const,
		fontWeight: 700,
		textAlign: 'center' as const,
		fontSize: 16,
	},
	rowfull: {
		flexDirection: "column" as const,
		flexWrap: 'nowrap' as const,
		width: '100%',
	},
	colFull: {
		width: '100%',
		flexDirection: "row" as const,
		flexWrap: 'wrap' as const,
		marginBottom: 12,
	},
	colhf: {
		borderWidth: 1,
		borderColor: '#E0E0E0',
		borderRadius: 6,
	},
	colhfActive: {
		borderWidth: 1,
		borderColor: '#748eaa',
		backgroundColor: '#eff2f6',
		borderRadius: 6,
	},
	checkbox: {
		paddingRight: 0,
		paddingLeft: 0,
		paddingTop: 0,
		paddingBottom: 0,
		marginRight: 8,
		marginLeft: 0,
		marginTop: 0,
		marginBottom: 0,
	},
	cardMapView: {
		flexDirection: 'column' as const,
		borderRadius: 6,
		overflow: 'hidden' as const,
	},
	cardImage: {
		// width: (Dimensions.get('window').width - 150) / 2,
		// width: (Dimensions.get('window').width - 150) / 2,
		// height: (Dimensions.get('window').width - 200) / 2,
		height: 200,
		// width: 16*200/9,
		width: '100%',
		objectFit: 'cover' as const,
		borderRadius: 6,
		borderWidth: '.5px',
		borderColor: '#748eaa',
		'border-style': 'solid' as const,

	},
	space_name: {
		color: '#212529',
		fontSize: 16,
		marginTop: 8,
		// width: (Dimensions.get('window').width - 150) / 2,
	},
	optionRow: {
		flexDirection: "row" as const,
		marginTop: 16,
	},
	optionCol: {
		flexDirection: "column" as const,
		marginRight: 12,
		marginBottom: 12,
	},
	icon: {
		fontSize: 17,
		marginTop: 'auto' as const,
		marginBottom: 'auto' as const,
	},
	reservTitle: {
		color: '#212529',
		paddingBottom: 16,
		fontWeight: 600,
		fontSize: 16,
	},
	reservSubtitle: {
		color: '#394148',
		fontWeight: 300,
		fontSize: 15,
	},
	reservText: {
		color: '#212529',
		overflow: 'hidden' as const,
		fontSize: 17,
	},
	priceInput: {
		flexGrow: 1,
		backgroundColor: '#fbfcfd',
		marginTop: 8,
		paddingHorizontal: 8,
		borderRadius: 6,
		borderColor: '#CFD8E7',
		borderWidth: 0.5,
	},
	callBtn: {
		borderRadius: '100%',
		backgroundColor: '#00a9e0',
		width: 36,
		height: 36,
		marginLeft: 8,
		justifyContent: 'center' as const,
		alignItems: 'center' as const,
		display: 'flex' as const,
	},
	reservprice: {
		color: '#212529',
		paddingBottom: 1,
		overflow: 'hidden' as const,
		fontSize: 18,
	},
	quoteTitle: {
		color: '#212529',
		paddingBottom: 3,
		overflow: 'hidden' as const,
		fontSize: 17,
		fontWeight: '500',
		paddingRight: 46,
	},
	noteText: {
		textAlign: 'left' as const,
		color: '#212529',
		fontSize: 16,
		lineHeight: 24,
		marginTop: 8,
		marginBottom: 16,
	},
	noteLink: {
		textAlign: 'left' as const,
		color: '#212529',
		textDecorationLine: 'underline' as const,
		fontSize: 16,
	},
	head: {
		flexDirection: 'row' as const,
		height: 30,
		marginTop: 14,
		marginBottom: 18,
		justifyContent: 'center' as const,
	},
	quoteHead: {
		flex: 0,
		flexDirection: 'row' as const,
		height: 30,
		marginTop: 4,
		marginBottom: 6,
		justifyContent: 'center' as const,
	},
	idTitleContent: {

		paddingRight: 6,
		justifyContent: 'center' as const,
		marginTop: 'auto' as const,
		marginBottom: 'auto' as const,
	},
	idTitle: {
		justifyContent: 'center' as const,
		marginTop: 'auto' as const,
		marginBottom: 'auto' as const,
		fontSize: 17,
	},
	statusContent: {
		flexGrow: 0,
		paddingTop: 4,
		flexShrink: 0,
		justifyContent: 'center' as const,
	},
	priceContent: {
		flexGrow: 0,
		flexShrink: 0,
	},
	sectionhead: {
		fontSize: 19,
		fontWeight: 600,
		paddingBottom: 20,
	},
	ratingContainer: {
		flexDirection: 'row' as const,
		alignItems: "center" as const,
		justifyContent: "flex-start as const"
	},
	reviewText: {
		fontWeight: 700,
		alignItems: "center" as const,
		fontSize: 20,
		color: "rgba(230, 196, 46, 1)",
	},
	starContainer: {
		flexDirection: "row" as const,
		alignItems: "center" as const,
		justifyContent: "flex-start as const"
	},
	grid: {
		flexDirection: 'row' as const,
		flexWrap: 'wrap' as const,
		alignItems: 'flex-start' as const,
		marginHorizontal: -8,
	},
	btnGrid: {
		flexDirection: 'row' as const,
		marginHorizontal: -4,
	},
	col: {
		flex: 1,
		flexDirection: 'column' as const,
		paddingHorizontal: 8,
	},
	col5: {
		flex: 0,
		width: 140,
		flexDirection: 'column' as const,
		paddingHorizontal: 8,
	},
	col7: {
		flex: 1,
		flexDirection: 'column' as const,
		alignItems: 'flex-end' as const,
		paddingHorizontal: 8,
	},
	quoteDecline: {
		color: '#212529',
		fontSize: 16,
	},
	quoteDeclineNote: {
		color: '#212529',
		fontSize: 16,
	},
	pt2: {
		paddingTop: 8,
	},
	pb2: {
		paddingBottom: 10,
	},
	pb4: {
		paddingBottom: 20,
	},
	btnView: {
		flex: 1,
		height: 48,
		backgroundColor: blue[400],
		marginBottom: 10,
		borderRadius: 10,
		marginHorizontal: 8,
		paddingHorizontal: 4,
		justifyContent: 'center' as const,
	},
	btnText: {
		flex: 0,
		flexGrow: 0,
		textAlign: 'center',
		color: 'white',
		fontSize: 17,
		fontWeight: 500,
	},
	onoffBtn: {
		flex: 0,
		height: 30,
		backgroundColor: blueGrey[100],
		borderRadius: 6,
		paddingHorizontal: 8,
		justifyContent: 'center' as const,
	},
	onoffText: {
		fontSize: 16,
		fontWeight: 400,
	},
	onActive: {
		fontSize: 16,
		fontWeight: 500,
	},
	infoIcon: {
		backgroundColor: '#c1cdd7',
		marginLeft: 5,
		paddingVertical: 4,
		paddingHorizontal: 8,
		borderRadius: 12
	},
	btn: {
		textAlign: 'right' as const,
		color: 'black' as const,
		fontSize: 17,
		fontWeight: 400,
		textDecorationLine: 'underline' as const,
	},
	divider: {
		flex: 1,
		height: 1,
		marginBottom: 24,
		backgroundColor: '#CFD8E7',
	},
	emptyTitle: {
		fontSize: 17,
		fontWeight: 400,
		color: '#697486',
	},
	proposal: {
		borderWidth: 1,
		backgroundColor: '#e4e9ec',
		borderColor: '#c1cbdc',
		borderRadius: 10,
		marginBottom: 24,
	},
	backView: {
		position: 'absolute' as const,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	row: {
		flex: 1,
		flexDirection: 'column' as const,
	},
	showMore: {
		flex: 0,
		flexDirection: 'row' as const,
		marginTop: 'auto',
		marginBottom: 'auto',
		paddingVertical: 16,
		marginHorizontal: 32,
		justifyContent: "center" as const,
		alignItems: "center" as const,
		// width: (Dimensions.get('window').width - 64),
		// maxHeight: (Dimensions.get('window').height - 300),
		backgroundColor: "white",
		borderRadius: 12,
		shadowColor: "#000000",
		shadowOffset: {
			width: 0,
			height: 1
		},
		shadowOpacity: 0.125,
		shadowRadius: 2,
		elevation: 2,
	},
	moreHeader: {
		position: 'relative' as const,
		paddingHorizontal: 12,
		marginBottom: 16,
		borderBottomColor: '#CFD8E7',
		borderBottomWidth: 0.5,
	},
	moreHeaderText: {
		color: 'black',
		textAlign: 'center',
		fontSize: 19,
		fontWeight: 500,
		marginBottom: 16,
	},
	closeView: {
		padding: 2,
		position: 'absolute' as const,
		top: 0,
		right: 10,
	},
	priceView: {
		marginTop: 16,
		flexDirection: 'row' as const,
	},
	sectionTitle: {
		fontSize: 19,
		marginBottom: 16,
		fontWeight: 700,
	},
	footerLabel: {
		fontWeight: 400,
		fontSize: 16,
		marginBottom: 8,
		color: '#536671',
	},
	perlabel: {
		marginLeft: 10,
		fontWeight: 500,
		fontSize: 15,
		color: blueGrey[900],
	},
	label: {
		fontWeight: 500,
		fontSize: 15,
		color: blueGrey[900],
	},
	errorLabel: {
		fontSize: 16,
		paddingTop: 6,
		color: red[500],
	},
	textInput: {
		paddingVertical: 0,
		paddingHorizontal: 0,
		flexGrow: 1,
		height: 40,
		fontSize: 18,
	},
	errorInput: {
		paddingVertical: 0,
		paddingHorizontal: 0,
		flexGrow: 1,
		height: 40,
		fontSize: 18,
	},
	text: {
		color: '#212529',
		paddingBottom: 3,
		overflow: 'hidden' as const,
		textAlign: 'left' as const,
		fontWeight: 300,
		fontSize: 15,
	},
	footer: {
		paddingVertical: 12,
		paddingHorizontal: 20,
		borderTopWidth: 0.5,
		borderTopColor: '#CFD8E7',
		backgroundColor: '#eef1f3',
		borderRadius: '0.5rem'
	},
};
