import React, { useEffect, useState } from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./manage-booking.css";

const useStyles = makeStyles(
    (theme) => (
        {
            root: {
                maxWidth: '100%',
            },
            sectionTitle: {
                fontSize: 22,
                fontWeight: 600,
                marginBottom: '20px'
            }
        }
    )
)

function CreateBookingForm({handleFormData, startDate, endDate, bname})
{
    const classes = useStyles();
    const [errors, setErrors] = useState([]);
    const [start, setStart] = useState(startDate);
    const [end, setEnd] = useState(endDate);

    const geDefaultTime = (date) => {
        let d = new Date(date);
        let hours = d.getHours() < 10 ? ('0'+d.getHours()) : d.getHours();
        let minutes = d.getMinutes() < 10 ? ('0'+d.getMinutes()) : d.getMinutes();

        return hours + ':' + minutes;
    };

    useEffect(() => {
        handleFormData('start', start)
        handleFormData('end', end)
        handleFormData('start_time', geDefaultTime(start))
        handleFormData('end_time', geDefaultTime(end))
    }, []);
    
    return (
        <div className={clsx(classes.root)}>
            <div className="row mt-5">
                <div className="col-md-12 pl-mobile-0 pr-mobile-1 mt-2 mb-2">
                    <input type="name" id="name" name="name" className="form-control" defaultValue={bname} onChange={(e) => handleFormData('booking_name', e.target.value)} placeholder="Booking Name" required/>
                    {(errors.name) ? (
                    <div className="form-error">{errors.name}</div>) : (<></>)}
                </div>
                <div className="col-md-3 pl-mobile-0 pr-mobile-1 mt-2 mb-2">
                    <label>Start Date</label>
                    <DatePicker
                        className="form-control d-block"
                        // onChangeRaw={(e) => handleFormData('start', e)}
                        // excludeDates={Object.keys(excludedDates).map(date => new Date(date))}
                        // filterDate={(date) => excludedDays[date.getDay()] == null}
                        selected={start}
                        // minDate={new Date(start)}
                        onChange={(e) => {
                            console.log(e);
                            setStart(e)
                            let d = new Date(e);
                            let date = d.getFullYear() + '-' + ((d.getMonth() + 1) < 10 ? '0'+(d.getMonth() + 1) : (d.getMonth() + 1)) + '-' + (d.getDate() < 10 ? '0'+ d.getDate() : d.getDate());
                            handleFormData('start', e)
                        }}
                    />
                    {(errors.start) ? (
                    <div className="form-error">{errors.start}</div>) : (<></>)}
                </div>
                <div className="col-md-3 pl-mobile-0 pr-mobile-1 mt-2 mb-2">
                    <label>Start Time</label>
                    <input type="time" className="form-control" onChange={(e) => handleFormData('start_time', e.target.value)} 
                    defaultValue={geDefaultTime(start)} />
                    {(errors.start) ? (
                    <div className="form-error">{errors.start}</div>) : (<></>)}
                </div>
                <div className="col-md-3 pl-mobile-0 pr-mobile-1 mt-2 mb-2">
                <label>End Date</label>
                    <DatePicker
                        className="form-control"
                        // onChangeRaw={(e) => handleFormData('end', e)}
                        // excludeDates={Object.keys(excludedDates).map(date => new Date(date))}
                        // filterDate={(date) => excludedDays[date.getDay()] == null}
                        selected={end}
                        minDate={new Date(start)}
                        onChange={(e) => {
                            console.log(e);
                            setEnd(e)
                            let d = new Date(e);
                            let date = d.getFullYear() + '-' + ((d.getMonth() + 1) < 10 ? '0'+(d.getMonth() + 1) : (d.getMonth() + 1)) + '-' + (d.getDate() < 10 ? '0'+ d.getDate() : d.getDate());
                            handleFormData('end', date)
                        }}
                    />
                    {(errors.end) ? (
                    <div className="form-error">{errors.end}</div>) : (<></>)}
                </div>
                <div className="col-md-3 pl-mobile-0 pr-mobile-1 mt-2 mb-2">
                    <label>End Time</label>
                    <input type="time" className="form-control" onChange={(e) => handleFormData('end_time', e.target.value)} 
                    defaultValue={geDefaultTime(end)} />
                    {(errors.start) ? (
                    <div className="form-error">{errors.start}</div>) : (<></>)}
                </div>
            </div>
        </div>
    );
}

export default CreateBookingForm;