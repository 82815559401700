import {devLog, getApiLinkV2, getToken} from "src/Helpers";
import Bugsnag from "@bugsnag/js";
import axios from "axios";
import {getAxios} from "src/Helpers";
import {Message} from "src/APIs/HostAPI";

export interface VenueRequest {
    facilities?: any[];
    account_name?: any;
    building?: any;
    city?: any;
    country?: any;
    description?: any;
    director_email?: any;
    director_name?: any;
    director_phone?: any;
    booking_team_email?: any;
    booking_team_name?: any;
    booking_team_phone?: any;
    floor_number?: any;
    address?: any;
    parking_instruction?: any;
    registered_address?: any;
    tax_percent?: any;
    tax_percent_exception?: any;
    suite_floor?: any;
    venue_name?: any;
    venue_type?: any;
    website?: any;
    zip_code?: any;
    neighborhood?: any;
    trn?: any;
    location?: { lat: any, lng: any };
}

interface FetchVenueResponse {
    venue: any;
    facilities: Array<any>;
}

export class VenueAPI {
    static TAG = "VenueAPI :: "

    static fetchVenue = async (id: string) => {

        let token = getToken()
        if (!token) return null;

        const url = getApiLinkV2("host/venue/" + id);

        try {

            let response = await getAxios().get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            })

            if (response.data.venue_details) {
                let {venue_details: venue, facilities} = response.data;
                facilities = facilities ?? []
                return {venue, facilities} as FetchVenueResponse;
            }
        } catch (error: any) {
            devLog(`${VenueAPI.TAG} fetchVenue() - ${error}`);
            Bugsnag.notify(error);
            return null;
        }
    }

    static updateVenue = async (request: VenueRequest, id?: string) => {

        let token = getToken()

        const url = getApiLinkV2("host/venue");
        const facilities = (request.facilities) ?? [];
        devLog("Request.Facilities", facilities)

        const data = {
            venue_details_id: id ? id : '',
            account_name: request.account_name,
            building: request.building,
            state: request.city,
            city: request.city,
            country: request.country,
            description: request.description,
            director_email: request.director_email,
            director_name: request.director_name,
            director_phone: request.director_phone,
            booking_team_email: request.booking_team_email,
            facilities: facilities,
            amenities: [],
            floor_number: request.floor_number,
            map_address: request.address,
            front_address: request.address,
            latitude: request.location?.lat,
            longitude: request.location?.lng,
            booking_team_name: request.booking_team_name,
            neighborhood: request.neighborhood ? request.neighborhood : '',
            parking_instruction: request.parking_instruction,
            booking_team_phone: request.booking_team_phone,
            registered_address: request.registered_address,
            sales_tax: request.tax_percent,
            tax_percent_exception: request.tax_percent_exception,
            suite_floor: request.suite_floor,
            trn: request.trn ? request.trn : '',
            venue_name: request.venue_name,
            venue_type: request.venue_type,
            website: request.website,
            zip_code: request.zip_code,
        };

            try {
                await getAxios().post(url, data,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token,
                        },
                    }
                )
                return {
                    type: "success",
                    message: "Succeded Updating Venue",
                    description: ''
                } as Message
            } catch (error: any) {
                devLog(error);
                devLog(error.message);
                Bugsnag.notify(error);
                return {
                    type: "danger",
                    message: "Failed Updating Venue",
                    description: error
                } as Message
            }

    }

    static fetchListings = async() => {

        let token = await getToken()
            try{

                if (!token) throw Error('invalid-token');
                const url = getApiLinkV2("host/listings");

                let response = await getAxios().get(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    }
                })
                return (response.data) as Array<any>;
            } catch (error: any) {
                Bugsnag.notify(error);
                // devLog(error);
                return ([]) as Array<any>;
            }

    }

    static featureSpace = async(venue:any, id:string, featured:string) => {

        let token = await getToken()
        try{
            const url = getApiLinkV2("host/workspace/"+id);

            let response = await getAxios().put(url, {
                featured: (featured === 'yes') ? 'no' : 'yes',
                option: 'change-featured'
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+token,
                },
            })
            devLog(response);
            if (response.data.status === 406) {
                return new Message('danger', "Error", response.data.error)
            }
            const message = (response.data.featured === 'yes') ? 'Space has been' +
                ' marked featured successfully' : 'Space has been marked standard' +
                ' successfully';
            return new Message('success', '', message);
        } catch (error: any) {
            Bugsnag.notify(error);

            if (error.response.status === 406) {
                return new Message('danger', "Error", error.response.data.error)
            }
            return new Message('danger', '', error.message.toString, '', error)
        }

    }

    static deleteSpace = async(venue:any, id:string) => {

        let token = await getToken()
        try{
            const url = getApiLinkV2("host/workspace/" + id);

            await getAxios().delete(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            })

            return new Message(
                "success",
                '',
                'Space has been deleted successfully'
            );

        } catch (error: any) {
            Bugsnag.notify(error);
            if (error.response.status === 406) {
                return new Message('danger', "Error", error.response.data.error)
            }
            return new Message(
                "danger",
                error.code ?? "Failure",
                error.message ?? " Failed to delete"
            );
        }


    }

}
