import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import office from '../img/Setup-your-company-in-uae.png';
import dubai_img from '../img/uae-freezones-and-mainland-business-setup.png'
import icon_1 from '../img/commercial_license.png';
import icon_2 from '../img/professional_license.png';
import icon_3 from '../img/industrial_license.png';
import icon_4 from '../img/tourism_license.png';
import icon_5 from '../img/uae-freezone.png';
import icon_6 from '../img/uae-mainland.png';
import icon_last_sec_1 from '../img/business-setup-logos/ded/uae-department-of-economic-development.png';
import icon_last_sec_2 from '../img/business-setup-logos/ded/dubai-economy.png';
import icon_last_sec_3 from '../img/business-setup-logos/ded/ajman-department-of-economic-development.png';
import icon_last_sec_4 from '../img/business-setup-logos/ded/Sharja-department-of-economic-development.png';
import icon_last_sec_5 from '../img/business-setup-logos/ded/government-of-umm-alquwain.png';
import icon_last_sec_6 from '../img/business-setup-logos/ded/fujairah-DED.png';
import icon_last_sec_7 from '../img/business-setup-logos/ded/ras-al-khaimah-department-of-economic-development.png';
import icon_last_sec2_1 from '../img/business-setup-logos/freezone/meydan-free-zone.png';
import icon_last_sec2_2 from '../img/business-setup-logos/freezone/abu-dhabi-airport-free-zone.png';
import icon_last_sec2_3 from '../img/business-setup-logos/freezone/dubai-world-central.png';
import icon_last_sec2_4 from '../img/business-setup-logos/freezone/dubai-commercity.png';
import icon_last_sec2_5 from '../img/business-setup-logos/freezone/dubai-international-financial-centre.png';
import icon_last_sec2_6 from '../img/business-setup-logos/freezone/dubai-internet-city.png';
import icon_last_sec2_7 from '../img/business-setup-logos/freezone/dubai-media-city.png';
import icon_last_sec2_8 from '../img/business-setup-logos/freezone/dubai-world-trade-center.png';
import icon_last_sec2_9 from '../img/business-setup-logos/freezone/dubai-international-financial-center.png';
import icon_last_sec2_10 from '../img/business-setup-logos/freezone/dubai-silicon-oasis.png';
import icon_last_sec2_11 from '../img/business-setup-logos/freezone/rakez-ras-al-khaimah-economic-zone.png';
import icon_last_sec2_12 from '../img/business-setup-logos/freezone/dubai-multi-commodities-center.png';
import icon_last_sec2_13 from '../img/business-setup-logos/freezone/khalifa-port-free-zone-trade.png';
import icon_last_sec2_14 from '../img/business-setup-logos/freezone/creative-city.png';
import icon_last_sec2_15 from '../img/business-setup-logos/freezone/dubai-south.png';
import icon_last_sec2_16 from '../img/business-setup-logos/freezone/jebel-ali-free-zone.png';
import icon_last_sec2_17 from '../img/business-setup-logos/freezone/Masdar-Abu-Dhabi-Future-Energy.png';
import icon_last_sec2_18 from '../img/business-setup-logos/freezone/dubai-maritime-city.png';
import icon_last_sec2_19 from '../img/business-setup-logos/freezone/dubai-science-park.png';
import icon_last_sec2_20 from '../img/business-setup-logos/freezone/sharja-publishing-city.png';
import icon_last_sec2_21 from '../img/business-setup-logos/freezone/international-humanitarian-city.png';
import icon_last_sec2_22 from '../img/business-setup-logos/freezone/hamriyah-free-zone-authority.png';
import icon_last_sec2_23 from '../img/business-setup-logos/freezone/sharjah-media-city.png';
import icon_last_sec2_24 from '../img/business-setup-logos/freezone/dubai-international-financial-center.png';
import icon_last_sec2_25 from '../img/business-setup-logos/freezone/Ajman-free-zone.png';
import icon_last_sec2_26 from '../img/business-setup-logos/freezone/dubai-airport-freezone.png';
import icon_last_sec2_27 from '../img/business-setup-logos/freezone/dubai-outsource-city.png';
import icon_last_sec2_28 from '../img/business-setup-logos/freezone/abu-dhabi-global-market.png';
import icon_last_sec2_29 from '../img/business-setup-logos/freezone/sharjah-international-authority-free-zone.png';
import icon_last_sec2_30 from '../img/business-setup-logos/freezone/dubai-design-district.png';
import icon_last_sec2_31 from '../img/business-setup-logos/freezone/uaq-free-trade-zone.png';
import icon_last_sec2_32 from '../img/business-setup-logos/freezone/fujairah-free-zone.png';
import icon_last_sec2_33 from '../img/business-setup-logos/freezone/ajman-media-city-free-zone.png';
import icon_last_sec2_34 from '../img/business-setup-logos/freezone/twofour54-abu-dhabi.png';
import icon_last_sec2_35 from '../img/business-setup-logos/freezone/dubai-production-city.png';
import icon_last_sec2_36 from '../img/business-setup-logos/freezone/dubai-healthcare-city.png';
import icon_last_sec2_37 from '../img/business-setup-logos/freezone/dubai-studio-city.png';
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch, faTimes} from "@fortawesome/free-solid-svg-icons";
import {createAlert, formValidation, getApiLinkV2, getToken} from "../Helpers";
import axios from "axios";
import {getAxios} from "src/Helpers";
import Bugsnag from "@bugsnag/js";
import ReactGA from "react-ga-neo";
import { Colorize } from '@material-ui/icons';
import { WhiteboardIcon } from 'src/img/svg-exports';
import {FMPhoneInput} from "src/components/host/forms/fields";
import Helmet from "react-helmet";
class BusinessSetup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedCountry: 'United Arab Emirates',
      selectedCity: '',
      reqLoading: false,
      reqAlert: null,
      fNameError: null,
      lNameError: null,
      emailError: null,
      phoneError: null,
      countryError: null,
      cityError: null,
      width: 0,
      height: 0
    }

    this.handleSendReq = this.handleSendReq.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentDidMount() {
    ReactGA.event({category: 'Business_Setup', action: 'BS_Attempt', label: 'Business Setup Attempt'});
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    //ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
      this.setState({page: this.props.match.params.page});
      //ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  handleSendReq(e) {
    e.preventDefault();

    const data = new FormData(e.target);
    const fNameError = formValidation('fname', data.get("fname"));
    const lNameError = formValidation('lname', data.get("lname"));
    const emailError = formValidation('email', data.get("email"));
    const phoneError = formValidation('phone', data.get("phone"));
    const countryError = formValidation('country', this.state.selectedCountry);
    const cityError = formValidation('city', this.state.selectedCity);

    this.setState({
      reqLoading: true,
      fNameError: fNameError,
      lNameError: lNameError,
      phoneError: phoneError,
      emailError: emailError,
      countryError: countryError,
      cityError: cityError,
    });

    if (fNameError || lNameError || phoneError || emailError || countryError || cityError) {
      this.setState({reqLoading: false, });
      return;
    }

    const url = getApiLinkV2('business-setup');
    const token = getToken();
    var headers = {'Content-Type': 'application/json'};
    if (token) {
      headers['Authorization'] = 'Bearer '+token;
    }

    getAxios().post(url, {
      first_name: data.get("fname"),
      last_name: data.get("lname"),
      phone_code: '+',
      phone: data.get("phone"),
      email: data.get("email"),
      country: this.state.selectedCountry,
      city: this.state.selectedCity,
    }, {
      headers: headers,
    }).then(response => {
      ReactGA.event({category: 'Business_Setup', action: 'BS_Success', label: 'Business Setup by '+data.get("fname")+' '+data.get("lname") });
      this.props.history.push('/business-setup/success');
    }).catch(error => {
      Bugsnag.notify(error);
        createAlert(this, 'danger',(error.response && error.response.data.error) ? 'Error: ' : '', (error.response && error.response.data.error) ? error.response.data.error : error.message);
    }).then(() => {
        this.setState({reqLoading: false, selectedCountry: '', selectedCity: '', });
        if(window.hasOwnProperty('gtag')) {
          if(window.hasOwnProperty('gtag')) {
            window?.gtag('event', 'conversion', {'send_to': 'AW-935885959/ItLaCLW7vvsCEIf5ob4D'});
          }
        }
    });
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  render() {

    const { match } = this.props;
    const { reqLoading, reqAlert, selectedCountry, selectedCity } = this.state;

    return (
      <>
        <Helmet>
          <meta charSet="utf-8"/>
          <title>Fluidmeet |Business Setup in Dubai, Abu Dhabi, Sharjah, Ras Al Khaima, Ajman, Fujirah| Company Formation in UAE </title>
          <meta name="description"
                content="Looking to setup your business? Start Any Business with hassle-free solutions for company setup services. Get in touch with any of our business setup consultants by booking your free appointment with Fluidmeet"/>
          <meta name="keywords"
                content="business setup, company setup, business formation, freezone, mainland, office space, flexspaces, insurance, bank opening, offshore"/>
          <link rel={"canonical"} href="https://www.fluidmeet.com/business-setup" />
        </Helmet>

          <section className="bsetup mt-90 mb-5">

            <img  className="background-photo-bs-page-first-img" src={office} alt="setup your business in the UAE"/>
            <h1 class="bs1-page-image-txt">
            SET UP YOUR<br></br>
COMPANY IN<br></br>
THE UAE
            </h1>

            <div className="cardd-float shadow-lg">

              {(match.params.page === 'success') ? (
                <div className="row pb-5">
                  <div className="col-12 pb-5">
                    <div className="cardd-title">Request Sent</div>
                    <div className="cardd-subtitle mb-50">
                      Your request has been sent successfully. Please check the email you provided for updates.
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-md-6 col-12">
                    <div className="cardd-title"><h2>Starting a Business?</h2></div>
                    <div className="cardd-subtitle"><p>Lots of licensing options available. Fill in your contact details and our consultant will contact you asap to discuss options available to you.</p></div>
                    <div className="cardd-footer mb-3">
                      By Submitting your request, you agree to Fluidmeet’s <Link to='/terms'>Terms of Use</Link> and <Link to='/privacy-policy'>Privacy Policy</Link>.
                    </div>
                  </div>
                  <div className="cardd-float-cont col-md-6 col-12 pt-3 pb-3">

                    <form autoComplete="on" onSubmit={this.handleSendReq}>
                      <div className="form-general p-0">
                        <input type="text" id="fname" name="fname" className="form-control" placeholder="First Name" required />
                        {(this.state.fNameError) ? (<div className="form-error">{this.state.fNameError}</div>) : (<></>)}
                        <div className="cardd-divide" />
                        <input type="text" id="lname" name="lname" className="form-control" placeholder="Last Name" required />
                        {(this.state.lNameError) ? (<div className="form-error">{this.state.lNameError}</div>) : (<></>)}
                        <div className="cardd-divide" />
                        <input type="email" id="email" name="email" className="form-control" placeholder="Email address" required />
                        {(this.state.emailError) ? (<div className="form-error">{this.state.emailError}</div>) : (<></>)}
                        <div className="cardd-divide" />
                        <div className="selector">
                        <FMPhoneInput type={'tel'} id="phone" placeholder="Phone number"
                                      extraClassNames={"bs-white-bs-pg"}/></div>
                        {(this.state.phoneError) ? (<div className="form-error">{this.state.phoneError}</div>) : (<></>)}
                        <div className="cardd-divide" />
                        <div className="selector">
                          <CountryDropdown classes="select bg-white border-r0" defaultOptionLabel='Licensing Country' value={selectedCountry} onChange={(val) => this.setState({ selectedCountry: val })} />
                        </div>
                        {(this.state.countryError) ? (<div className="form-error">{this.state.countryError}</div>) : (<></>)}
                        <div className="cardd-divide" />
                        <div className="selector">
                          <RegionDropdown classes="select bg-white border-rtr0" disableWhenEmpty={true} defaultOptionLabel='Licensing City' country={selectedCountry} value={selectedCity} onChange={(val) => this.setState({ selectedCity: val })} />
                        </div>
                        {(this.state.cityError) ? (<div className="form-error">{this.state.cityError}</div>) : (<></>)}
                      </div>
                      <button type="submit" className={"btn btn-lg btn-primary btn-block fl_btn mt-3 "+((reqLoading) ? 'ld-btn' : '')}>
                        {(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch} spin={true} /> loading</>) : 'Submit'}
                      </button>
                    </form>

                  </div>
                </div>

              )}

            </div>

          </section>

       <section>
          <div className="container-fluid contain mt-80 pr-0 pl-0">
            <div className="sec-section-padd-bs-pg">
              <section className="page">

                <h2 className="mt-4 mb-4 subtitle-bs-pg-all-secs">TYPES OF BUSINESS LICENSES IN THE UAE</h2>
                <div className="form-general">
                  <form className="row mt-5">

                    <div className="col-6 pr-mobile-1 pl-0 business-desc-fonts">
                    <img className="business-img-icons" src={icon_1} alt='business image icon' />
                    <h3>Commercial License</h3>
                    
                    <p>
                    Or so-called trade license, allows you to start a business
                    in general trading, import and export goods or certain
                    services, and sell them inside or outside the UAE<br></br>
                    <em>Business activities include advertising, contracting & building works,
    art production and many more.</em>
                    </p>
                    <br></br>

                    </div>
                    <div className="col-6 pl-mobile-1 pr-0 business-desc-fonts">
                    <img className="business-img-icons" src={icon_2} alt='business image icon' />
                    <h3>Professional License</h3>
                    <p>
                    This type of business license grants 100% foreign
    ownership to service-related companies such as
    consultancy agency, accounting & audit firm and other
    types of professional services<br></br>
                    <em>Business activities include consultancy agencies, salons, event
    planners, and many more.</em>
                    </p>
                    <br></br>
                    </div>
                    <div className="col-6 pr-mobile-1 pl-0 business-desc-fonts">
                    <img className="business-img-icons" src={icon_3} alt='business image icon' />
                    <h3>Industrial License</h3>
                    <p>
                    Issued to companies involved in any kind of
    manufacturing or industrial activity, For manufacturing
    goods, it could be coupled with other licenses such as
    commercial license<br></br>
                    <em>Business activities include food industries, manufacturing, and
    separate industrial activities.</em>
                    </p>
                    <br></br>
                    </div>
                    <div className="col-6 pl-mobile-1 pr-0 business-desc-fonts">
                    <img className="business-img-icons" src={icon_4} alt='business image icon' />
                    <h3>Tourism License</h3>
                    <p>
                    This business license is mainly for the travel agencies &
    tourism companies, and permits its holder to trade
    airline tickets and offer visit visas<br></br>
                    <em>Business activities include travel agencies, hotels, restaurants, and car
    rental firms.</em>
                    </p>
                    <br></br>
                    </div>
                  <div className="col-12 mt-3 mb-4 pr-0 pl-0">
                      <p className="sec2-italic-font-pos-bs-pg"><em>All business licenses can be issued in freezones or mainland.
    </em></p>
                    </div>
                  </form>
                </div>
              </section>
            </div>

          </div>
      </section> <br></br><br></br><br></br>





        
        <section>
          <img className="background-photo-bs-page" src={dubai_img} alt="freezone and mainland business setup comparison"/>
          <center>
            <div className="third-sec-style-bs-pg">
            <h2 className="third-sec-bs-pg-subtitle">Differences between</h2>
            <h2 className="third-sec-bs-pg-subtitle">MAINLAND & FREEZONE setups</h2>
            <div class="column">
                            <div className="business-desc-fonts">
                            <center><img className="sec-3-other-head-txts-img-1" src={icon_5} alt="UAE Freezones business setup"/></center>
                            <h3 className="sec-3-other-head-txts-font-1">FREEZONE</h3>
                            <div class="one-row-sec-3-bs-pg mid-section-divs-bs-pg-1-left">
                                <p>Provides the opportunity of 100% foreign ownership</p>
                            </div>
                            <div class="one-row-sec-3-bs-pg mid-section-divs-bs-pg-2-left">
                                <p>Freezones offer company set up with or without office
    space. It depends on business activity & number of
    employees.</p>  
                            </div>
                            <div class="one-row-sec-3-bs-pg mid-section-divs-bs-pg-3-left">
                              <p>Company formation is easy & quick, usually takes
    up to 5 days</p>
                            </div>
                            <div class="one-row-sec-3-bs-pg mid-section-divs-bs-pg-4-left">
                              <p>Can be 100% remote</p>
                            </div>
                            <div class="one-row-sec-3-bs-pg mid-section-divs-bs-pg-5-left">
                              <p>Permission to trade or conduct business outside the UAE or between freezones</p>
                            </div>
                            <div class="one-row-sec-3-bs-pg mid-section-divs-bs-pg-6-left">
                              <p>Full exemption of all taxes & customs duties payment</p>
                            </div>
                            <div class="one-row-sec-3-bs-pg mid-section-divs-bs-pg-7-left">
                              <p>Virtual offices or so-called flexi-desks allow up to 6 visas.
    office spaces availability is directly related to your visas
    quota</p>
                            </div>
                            </div>
                    </div>
                    <div class="column">
                    <div className="pr-mobile-1 pl-0 business-desc-fonts">
                    <img className="business-img-icons mid-section-mid-img-visibilty-bs-pg" src={icon_5} alt='business image icon' />

                                  <h3 className="sec-3-criteria-txt-font">CRITERIA</h3><br></br>
                                  <div class="mid-section-bs-pg">
                                  <center><h4 className="one-row-sec-3-criteria-table-cell1-bs-pg">Ownership of Business</h4></center>
                                  <center><h4 className="one-row-sec-3-criteria-table-cell2-bs-pg">Office Space</h4></center>
                                  <center><h4 className="one-row-sec-3-criteria-table-cell3-bs-pg">Setup Time</h4></center>
                                  <center><h4 className="one-row-sec-3-criteria-table-cell4-bs-pg">Setup Procedure</h4></center>
                                  <center><h4 className="one-row-sec-3-criteria-table-cell5-bs-pg">Scope of Business</h4></center>
                                  <center><h4 className="one-row-sec-3-criteria-table-cell6-bs-pg">Taxation</h4></center>
                                  
                                  <center><h4 className="one-row-sec-3-criteria-table-cell7-bs-pg">Residence Visa</h4></center>
                                  </div>
                                  </div>
                    </div>

                    <div class="column">
                    <div className="business-desc-fonts">
                        <center><img className="sec-3-other-head-txts-img-2"  src={icon_6} alt="UAE Mainland business setup"/></center>
                        <h3 className="sec-3-other-head-txts-font-1">MAINLAND</h3>
                            <div class="one-row-sec-3-bs-pg mid-section-divs-bs-pg-1-right">
                              <p>More Than 1000 business activities can apply for full foreign ownership</p>
                            </div>
                            <div class="one-row-sec-3-bs-pg mid-section-divs-bs-pg-2-right">
                              <p><em><Link style={{color: 'white'}} to='/search/nearby/office-spaces'>Office space </Link></em>is required after one year of ownership.
    Office space size is related to the number of required visas</p>
                            </div>
                            <div class="one-row-sec-3-bs-pg mid-section-divs-bs-pg-3-right">
                              <p>It might take up to 10 days to establish a mainland company</p>
                            </div>
                            <div class="one-row-sec-3-bs-pg mid-section-divs-bs-pg-4-right">
                              <p>Requires physical presence of company’s founder OR remotely via POA</p>
                            </div>
                            <div class="one-row-sec-3-bs-pg mid-section-divs-bs-pg-5-right">
                              <p>Permits business inside and outside of the country</p>
                            </div>
                            <div class="one-row-sec-3-bs-pg mid-section-divs-bs-pg-6-right">
                              <p>Corporate tax applies to all businesses</p>
                            </div>
                            <div class="one-row-sec-3-bs-pg mid-section-divs-bs-pg-7-right">
                              <p>Unrestricted, Visa quota is calculated based on the number of office space square meters.</p>
                            </div>
                    </div>
                    </div>
            </div>
          </center>

          
                
        </section>

        <center>        <section className="fourth-sec-size-bs-pg">

<center><p className="sec-4-body-txt">UAE provides one of the most thriving business registration programs around the globe.
Fluidmeet will help you choose wisely and set up your business anywhere in UAE.
Moreover, fluidmeet offers a large selection of <Link to='/search/nearby/co-working-spaces'>co-working spaces</Link> and 
<Link to='/search/Dubai/office-spaces'> serviced offices</Link> in
most of the <Link to={{ pathname: "https://www.moec.gov.ae/en/free-zones" }} target="_blank">freezones,</Link> and across all the UAE.</p></center>
<br></br>
<h3 class="submit-text-bs-pg">Submit your request for free and let us help!</h3><br></br>
<button type="submit" className={"btn btn-lg btn-primary btn-block container-submit-section-bs-pg"}         onClick={() => {
window.scrollTo({top: 410, left: 0, behavior: 'smooth'});
}}>Submit</button>
        </section></center>
<br></br><br></br>

        <section className="last-sec-color-bs-pg">
  <br></br>
<h1 className="last-section-title-txt">NOTABLE LICENSING AUTHORITIES IN THE UAE</h1>
<br></br>
<h2 class="last-section-txts last-sec-txts-container">MAINLAND</h2><br></br><br></br><br></br>

<Link to={{ pathname: "https://added.gov.ae/" }} target="_blank">
        <img className="container-last-sec-imgs-01" src={icon_last_sec_1} alt="uae-department-of-economic-development"/>
</Link>
        <img className="container-last-sec-imgs-02" src={icon_last_sec_2} alt="dubai-economy"/>
        <img className="container-last-sec-imgs-03" src={icon_last_sec_3} alt="ajman-department-of-economic-development"/>
        <img className="container-last-sec-imgs-04" src={icon_last_sec_4} alt="Sharja-department-of-economic-development"/>
        <img className="container-last-sec-imgs-05" src={icon_last_sec_5} alt="government-of-umm-alquwain"/>
        <img className="container-last-sec-imgs-06" src={icon_last_sec_6} alt="fujairah-DED"/>
        <img className="container-last-sec-imgs-07" src={icon_last_sec_7} alt="ras-al-khaimah-department-of-economic-development"/>
       <br></br>
       <hr className="hr-bs-pg"></hr>
       <br></br>
<h2 class="last-section-txts last-sec-txts-container">FREEZONE</h2><br></br><br></br><br></br>
{this.state.width  <= 500 ?

<>
<>
<img className="container-last-sec-imgs-08" src={icon_last_sec2_1} alt="meydan-free-zone.png"/>
<img className="container-last-sec-imgs-09" src={icon_last_sec2_2} alt="abu-dhabi-airport-free-zone.png"/>
<img className="container-last-sec-imgs-10" src={icon_last_sec2_3} alt="dubai-world-central"/>
<img className="container-last-sec-imgs-16" src={icon_last_sec2_9} alt="dubai-international-financial-center"/>
<img className="container-last-sec-imgs-17" src={icon_last_sec2_10} alt="dubai-silicon-oasis"/>
<img className="container-last-sec-imgs-18" src={icon_last_sec2_11} alt="rakez-ras-al-khaimah-economic-zone"/>
<img className="container-last-sec-imgs-24" src={icon_last_sec2_17} alt="Masdar-Abu-Dhabi-Future-Energy"/>
<img className="container-last-sec-imgs-25" src={icon_last_sec2_18} alt="dubai-maritime-city"/>
<img className="container-last-sec-imgs-26" src={icon_last_sec2_19} alt="dubai-science-park"/>
<img className="container-last-sec-imgs-32" src={icon_last_sec2_25} alt="Ajman-free-zone"/>
<img className="container-last-sec-imgs-33" src={icon_last_sec2_26} alt="dubai-airport-freezone"/>
<img className="container-last-sec-imgs-34" src={icon_last_sec2_27} alt="dubai-outsource-city"/>
<img className="container-last-sec-imgs-11" src={icon_last_sec2_4} alt="dubai-commercity"/>
<img className="container-last-sec-imgs-19" src={icon_last_sec2_12} alt="dubai-multi-commodities-center"/>
<br></br>
<img className="container-last-sec-imgs-27" src={icon_last_sec2_20} alt="sharja-publishing-city"/>
<img className="container-last-sec-imgs-35" src={icon_last_sec2_28} alt="abu-dhabi-global-market"/>

<br></br>
<img className="container-last-sec-imgs-12" src={icon_last_sec2_5} alt="dubai-international-financial-centre"/>
<img className="container-last-sec-imgs-13" src={icon_last_sec2_6} alt="dubai-internet-city"/>
<img className="container-last-sec-imgs-20" src={icon_last_sec2_13} alt="khalifa-port-free-zone-trade"/>
<img className="container-last-sec-imgs-14" src={icon_last_sec2_7} alt="dubai-media-city"/>
<img className="container-last-sec-imgs-21" src={icon_last_sec2_14} alt="creative-city"/>
<img className="container-last-sec-imgs-29" src={icon_last_sec2_22} alt="hamriyah-free-zone-authority"/>
<img className="container-last-sec-imgs-28" src={icon_last_sec2_21} alt="international-humanitarian-city"/>
<img className="container-last-sec-imgs-30" src={icon_last_sec2_23} alt="sharjah-media-city"/>
<img className="container-last-sec-imgs-36" src={icon_last_sec2_29} alt="sharjah-international-authority-free-zone."/>
<img className="container-last-sec-imgs-37" src={icon_last_sec2_30} alt="dubai-design-district"/>

<img className="container-last-sec-imgs-23" src={icon_last_sec2_16} alt="jebel-ali-free-zone"/>

<img className="container-last-sec-imgs-38" src={icon_last_sec2_31} alt="uaq-free-trade-zone"/>
<img className="container-last-sec-imgs-15" src={icon_last_sec2_8} alt="dubai-world-trade-center"/>
<img className="container-last-sec-imgs-22" src={icon_last_sec2_15} alt="dubai-south"/>
<img className="container-last-sec-imgs-31" src={icon_last_sec2_24} alt="dubai-international-financial-center"/>
<img className="container-last-sec-imgs-39" src={icon_last_sec2_32} alt="fujairah-free-zone.png"/>
</>
</>
: 
<>

<img className="container-last-sec-imgs-08" src={icon_last_sec2_1} alt="meydan-free-zone.png"/>
<img className="container-last-sec-imgs-09" src={icon_last_sec2_2} alt="abu-dhabi-airport-free-zone.png"/>
<img className="container-last-sec-imgs-10" src={icon_last_sec2_3} alt="dubai-world-central"/>
<img className="container-last-sec-imgs-11" src={icon_last_sec2_4} alt="dubai-commercity"/>
<img className="container-last-sec-imgs-12" src={icon_last_sec2_5} alt="dubai-international-financial-centre"/>
<img className="container-last-sec-imgs-13" src={icon_last_sec2_6} alt="dubai-internet-city"/>
<img className="container-last-sec-imgs-14" src={icon_last_sec2_7} alt="dubai-media-city"/>
<img className="container-last-sec-imgs-15" src={icon_last_sec2_8} alt="dubai-world-trade-center"/>


<img className="container-last-sec-imgs-16" src={icon_last_sec2_9} alt="dubai-international-financial-center"/>
<img className="container-last-sec-imgs-17" src={icon_last_sec2_10} alt="dubai-silicon-oasis"/>
<img className="container-last-sec-imgs-18" src={icon_last_sec2_11} alt="rakez-ras-al-khaimah-economic-zone"/>
<img className="container-last-sec-imgs-19" src={icon_last_sec2_12} alt="dubai-multi-commodities-center"/>
<img className="container-last-sec-imgs-20" src={icon_last_sec2_13} alt="khalifa-port-free-zone-trade"/>
<img className="container-last-sec-imgs-21" src={icon_last_sec2_14} alt="creative-city"/>
<img className="container-last-sec-imgs-22" src={icon_last_sec2_15} alt="dubai-south"/>
<img className="container-last-sec-imgs-23" src={icon_last_sec2_16} alt="jebel-ali-free-zone"/>


<img className="container-last-sec-imgs-24" src={icon_last_sec2_17} alt="Masdar-Abu-Dhabi-Future-Energy"/>
<img className="container-last-sec-imgs-25" src={icon_last_sec2_18} alt="dubai-maritime-city"/>
<img className="container-last-sec-imgs-26" src={icon_last_sec2_19} alt="dubai-science-park"/>
<img className="container-last-sec-imgs-27" src={icon_last_sec2_20} alt="sharja-publishing-city"/>
<img className="container-last-sec-imgs-28" src={icon_last_sec2_21} alt="international-humanitarian-city"/>
<img className="container-last-sec-imgs-29" src={icon_last_sec2_22} alt="hamriyah-free-zone-authority"/>
<img className="container-last-sec-imgs-30" src={icon_last_sec2_23} alt="sharjah-media-city"/>
<img className="container-last-sec-imgs-31" src={icon_last_sec2_24} alt="dubai-international-financial-center"/>

<img className="container-last-sec-imgs-32" src={icon_last_sec2_25} alt="Ajman-free-zone"/>
<img className="container-last-sec-imgs-33" src={icon_last_sec2_26} alt="dubai-airport-freezone"/>
<img className="container-last-sec-imgs-34" src={icon_last_sec2_27} alt="dubai-outsource-city"/>
<img className="container-last-sec-imgs-35" src={icon_last_sec2_28} alt="abu-dhabi-global-market"/>
<img className="container-last-sec-imgs-36" src={icon_last_sec2_29} alt="sharjah-international-authority-free-zone."/>
<img className="container-last-sec-imgs-37" src={icon_last_sec2_30} alt="dubai-design-district"/>
<img className="container-last-sec-imgs-38" src={icon_last_sec2_31} alt="uaq-free-trade-zone"/>
<img className="container-last-sec-imgs-39" src={icon_last_sec2_32} alt="fujairah-free-zone.png"/>

</>
}

{this.state.width  <= 1455 || this.state.width >= 1700 ?
  <>
  <img className="container-last-sec-imgs-40" src={icon_last_sec2_33} alt="ajman-media-city-free-zone"/>
  <img className="container-last-sec-imgs-41" src={icon_last_sec2_34} alt="twofour54-abu-dhabi"/>
  <img className="container-last-sec-imgs-42" src={icon_last_sec2_35} alt="dubai-production-city"/>
  <img className="container-last-sec-imgs-43" src={icon_last_sec2_36} alt="dubai-healthcare-city"/>
  <img className="container-last-sec-imgs-44" src={icon_last_sec2_37} alt="dubai-studio-city"/>
  </>
  : 

<>
<center>
  <img className="container-last-sec-imgs-40" src={icon_last_sec2_33} alt="ajman-media-city-free-zone"/>
  <img className="container-last-sec-imgs-41" src={icon_last_sec2_34} alt="twofour54-abu-dhabi"/>
  <img className="container-last-sec-imgs-42" src={icon_last_sec2_35} alt="dubai-production-city"/>
  <img className="container-last-sec-imgs-43" src={icon_last_sec2_36} alt="dubai-healthcare-city"/>
  <img className="container-last-sec-imgs-44" src={icon_last_sec2_37} alt="dubai-studio-city"/>
</center>
  </>
}

</section><br></br>
        {(reqAlert) ? (
          <div id="alerts">
            <div className={"alert alert-"+reqAlert.type+" alert-dismissible floating-alert fade show"}>
              <strong>{reqAlert.title}</strong> {reqAlert.message}
              <button type="button" className="btn-close" onClick={() => this.setState({reqAlert: null})}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </div>
        ) : (<></>)}

      </>
    );
  }
}

export default withRouter(BusinessSetup);
