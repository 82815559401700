import React, {FormEventHandler, Fragment, useEffect, useRef, useState} from 'react';
import {
	AccordionActions,
	AccordionDetails,
	AccordionSummary,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	makeStyles
} from "@material-ui/core";
import {blue, blueGrey, red} from "@material-ui/core/colors";
import clsx from 'clsx';
import printLocal, {
	addDays,
	devLog,
	getApiLinkV2,
	getAxios,
	getCurrency,
	getToken,
	getUser,
	printDateFromDateTime,
	printDateTimeFromServer,
	printPrice,
	printPriceOnly,
	printSpaceData,
} from "src/Helpers";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useHistory, useParams} from "react-router-dom";
import Bugsnag from "@bugsnag/js";
import axios from 'axios';
import multiProposal from 'src/img/gray_logo.png';
import Accordion from "@material-ui/core/Accordion";
import {infoSection} from "src/components/host/host-dashboard/manage-reservations/reservations-details/info-section";
import AlertSnackbar from "src/components/organisms/snackbar/AlertSnackBar";
import moment from "moment";
import {faEnvelope, faUser} from "@fortawesome/free-regular-svg-icons";
import {faCheck, faPhoneAlt} from "@fortawesome/free-solid-svg-icons";
import {
	FMButton,
	FMDateInput,
	FMFileInput,
	FMMultilineTextInput,
	FMSelectInput,
	FMTextInput,
	FMTimeInput
} from "src/components/host/forms/fields";
import {useUpdateReducer} from "src/hooks/useUpdateReducer";
import CloseIcon from "@material-ui/icons/Close";
import {capitalizeFirst,} from "src/components/host/host-helpers";
import FilesAPI from "src/APIs/FilesAPI";
import AlertDialog from "src/components/organisms/alert-dialog/AlertDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LeadUtilities from "src/utils/lead-utils";


const useStyles = makeStyles((theme) => (
		{
			accordionRoot: {
				backgroundColor: '#edf5fa'
			},
			tooltip: {
				fontSize: theme.typography.pxToRem(18),
			},
			hoverListItem: {
				alignItems: 'center',
				'&:hover': {
					backgroundColor: 'rgba(230,230,230,0.35)',
					borderRadius: '0.5rem',
				}

			}
		}
	)
)

const grid = 'col-12 d-flex flex-row flex-wrap align-items-center py-2';
const col5 = 'col-5';
const col7 = 'col-7';

export type LongTermDetailsProps = {}
LongTermDetails.key = 'long-term-details'
LongTermDetails.route = `/${LongTermDetails.key}/:id/`

LongTermDetails.view = (history, reservation_id) => {
	history.push(LongTermDetails.generateViewLink(reservation_id))
}

LongTermDetails.generateViewLink = (reservation_id) => {
	return (`/${LongTermDetails.key}/${reservation_id}/`)
}


export type LongTermDetailsRouteParams = {
	id: string,
}
export default function LongTermDetails({}: LongTermDetailsProps) {

	const {id} = useParams<LongTermDetailsRouteParams>()
	const alertDialog = useRef<AlertDialog>(null);
	const [reservation, setReservation, updateReservation] = useUpdateReducer<any>({})
	const [request, setRequest,updateRequest] = useUpdateReducer<any>({})
	const [requestsLoading, setRequestsLoading,updateRequestsLoading] = useUpdateReducer<any>({})
	const [space, setSpace] = useState<any>();
	const [emailsCount, setEmailsCount] = useState<number>(1);
	const [loading, setLoading] = useState<boolean>(true);
	const history = useHistory();
	const classes = useStyles();
	const [showMore, setShowMore] = useState<string | null>(null)
	const snackbar = useRef<AlertSnackbar>(null);
	const [showMoreLoading, setShowMoreLoading] = useState<any>(false);
	const currency = getCurrency();
	const user = getUser();

	useEffect(() => {
		fetchReservation();
	}, [])


	const fetchReservation = () => {

		let token = getToken()
		if (!token) return;
		devLog("LT-Details ::: fetch reservation")
		const url = getApiLinkV2("host/reservations/" + id);
		setLoading(true)
		getAxios().get(url, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token,
			},
			params: {
				type: 'lead'
			},
		}).then(response => {
			devLog("LT-Details ::: space", {response})
			const {reservation: _reservation} = response.data;

			if (!response.data || !response.data.reservation) {
				setReservation(null)
				return;
			}

			var _request: any = {};
			_request.startDate = _reservation.host_move_date ? _reservation.host_move_date : null;
			_request.endDate = _reservation.host_end_date ? _reservation.host_end_date : null;
			_request.visitDate = addDays((new Date()), 1);
			_request.visitTime = moment((moment().format('YYYY-MM-DD') + ' 09:00:00'), 'YYYY-MM-DD hh:mm:ss').toDate();
			_request.booking_status = _reservation.host_status_id ? parseInt(_reservation.host_status_id) : 10;
			_request.payment_status = _reservation.payment_status_id ? parseInt(_reservation.payment_status_id) : null;
			_reservation.booking_status = _request.booking_status;
			_reservation.payment_status = _request.payment_status;

			setReservation(_reservation)
			console.log({_reservation})
			setRequest(_request)

			setSpace(response.data.space)

		}).catch(error => {
			devLog("LT-Details ::: space", error);
			setReservation(null)
			setTimeout(() => {
				Bugsnag.notify(error);
			}, 500)
		}).finally(()=>{setLoading(false)});
	}

	const showSuccessMessage = (message) => {
		snackbar.current?.setAlertData(
			{
				severity: 'success' as const,
				message: message,
				timeout: 7000,
			}
		)
		snackbar.current?.open()
	}
	const showErrorMessage = (message) => {
		snackbar.current?.setAlertData(
			{
				severity: 'error' as const,
				message: message,
				timeout: 7000,
			}
		)
		snackbar.current?.open()
	}

	const handleUpdateCalled =  () => {

			updateReservation(
				{
					called : true,
					called_lead_on : moment().format('YYYY-MM-DD hh:mm:ss'),
				}
			)
			handleUpdate('called')

			// statusPicker.togglePicker(true);
	}
	const handleUpdate = (type,value?) =>{
		let token = getToken()
		if (!token) return;

		const url = getApiLinkV2("host/reservations/" + reservation.reservation_id);

		getAxios().put(url, {
			reservation_type: 'lead',
			option: type,
			lead_type: reservation.lead_type,
			lead_id: reservation.reservation_id,
			venue_id: reservation.venue_id,
			status: value,
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token,
			},
		}).then(response => {
			showSuccessMessage(response.data.message);
			fetchReservation();
			// reservation.booking_status = value;
			// setReservation({...reservation})
		}).catch(error => {
			// handleChange('booking_status', reservation.booking_status);
			Bugsnag.notify(error);
			showErrorMessage(error?.message)
		});
	}

	const handleChange = (key, value) => {
		updateRequest({[key]: value})
	}

	const handleMoreSubmit: FormEventHandler<HTMLFormElement> = async(e) => {

		e?.preventDefault();
		let formData = new FormData(e.target as HTMLFormElement);
		setShowMoreLoading(true)
		let formRequest: any = Object.fromEntries(formData.entries());
		// devLog(Object.fromEntries(formData.entries()))

		var emails: any[] = [];
		for (let i = 0; i < 20; i++) {
			if (formRequest['email' + i] == null)
				break;
			if (formRequest['email' + i] != '')
				emails.push(formRequest['email' + i])
		}

		let token = getToken()
		if (!token) return;
		let fileUrl = '';
		console.log(formRequest.file)
		if (formRequest.file && formRequest.file?.name && formRequest.file?.size) {

			try {
				fileUrl = (await FilesAPI.uploadFile(formRequest.file, 'contract'))?.url ?? '';
			} catch (e: any) {
				fileUrl = ''
			}

		}
		let requestFormData = {
			status: formRequest.status,
			first_name: formRequest.fName,
			last_name: formRequest.lName,
			email: formRequest.email,
			phone: formRequest.phone,
			visit_host_phone: formRequest.phone,
		}
		if (showMore == 'visit scheduled') {
			requestFormData = {
				status: formRequest.status,
				first_name: formRequest.fName || user?.first_name,
				last_name: formRequest.lName || user?.last_name,
				email: formRequest.email || user?.email,
				phone: formRequest.phone || user?.phone,
				visit_host_phone: formRequest.phone || user?.phone,
			}
		}
		const url = getApiLinkV2("host/reservations/" + reservation.reservation_id);
		const reqData = {
			reservation_type: 'lead',
			request_id: reservation.reservation_id,
			request_type: reservation.lead_type,
			option: showMore,
			visit_datetime: moment(formRequest.visitDate).format('YYYY-MM-DD') + ' ' + formRequest.visitTime,
			visit_id: reservation.visit_id,
			venue_id: reservation.venue_id,
			contract_file: fileUrl,
			host_email: user?.email,
			lead_emails: emails,
			...requestFormData,
			price: formRequest.price ? printPriceOnly(formRequest.price, currency.name, LeadUtilities.LEADS_DEFAULT_CURRENCY) : 0,
			move_date: moment(formRequest.startDate).format('YYYY-MM-DD'),
			end_date: moment(formRequest.endDate).format('YYYY-MM-DD'),
			note: formRequest.note,
		}
		console.log({reqData, token})
		getAxios().put(url, reqData, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token,
			},
		}).then(response => {
			fetchReservation();
			if (response.data.status === 'success') {
				showSuccessMessage(response.data.message);
			}
			updateRequest(formRequest)
		}).catch(error => {
			Bugsnag.notify(error);
			devLog(error);
			showErrorMessage(error?.message);
		}).then(() => {
			setShowMoreLoading(false)
			toggleShowMore();
		});

	}

	const handleScheduleVisit = () => {
		toggleShowMore('schedule visit');
	}
	const handleViewScheduledVisit = () => {
		toggleShowMore('visit scheduled');
	}

	const handleSendProposal = () => {
		toggleShowMore('send proposal');
	}
	const handleViewProposals = () => {
		toggleShowMore('view proposals');
	}

	const handleSignedContract = () => {

		alertDialog.current?.setDialogData({
			title: "Did you sign a contract with the client ?",
			agreeText: "Yes",
			onAgreeClicked: () => {
				handleContractSigned();
			},
			cancelText: "Cancel",
			onCancelClicked:() => {
				devLog("Cancel Pressed")
			},

		});
		alertDialog.current?.open();


	}

	const handleUpdateContractDetails = () => {
		toggleShowMore('contract details');
	}

	const handleViewContract = () => {
		if (reservation.signed_contract && reservation.signed_contract !== '')
			window.open(reservation.signed_contract);
	}

	const handleContractSigned = () => {

		updateRequestsLoading({['contract signed']: true});

		let token = getToken()
		if (!token) return;

		const url = getApiLinkV2("host/reservations/" + reservation.reservation_id);

		getAxios().put(url, {
			reservation_type: 'lead',
			option: 'contract signed',
			venue_id: reservation.venue_id,
			status: 7,
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token,
			},
		}).then(response => {
			showSuccessMessage(response.data.message);
			updateReservation({
				lead_status : 'Contract signed',
				host_status_id : 7,
			})
			toggleShowMore('contract details')
		}).catch(error => {
			Bugsnag.notify(error);
			showErrorMessage(error?.message);
		}).then(() => {
			updateRequestsLoading({['contract signed']: null});
		});

	}

	const handleStatus = (type, value) => {
		handleChange(type, value);
		if (reservation[type] !== value && Boolean(value)) {
			handleUpdate(type,value)
		}

	}

	const createHeader = () => {

		return (
			<>
				<div className={clsx('d-flex flex-row align-items-start justify-content-between flex-wrap')}>
				{
					Boolean(space)? (

							<div className={clsx('d-flex flex-row align-items-start')} style={styles.space}
								// onPress={() => this.spaceTapped(space)}
							>
								{
									(space?.image) ? (
										<img style={styles.spaceimg}
										     src={"https://fluidmeet.s3.amazonaws.com/venue_logos/" + space.image}/>
									) : (
										<div style={styles.spaceimg}>
											<img style={{...styles.generalimg, height: 42, width: 43,}} src={multiProposal}/>
										</div>
									)
								}

								<div style={styles.spacedetails}>
									<div style={styles.spaceText}><b>Space:</b> {space.name}</div>
									<div style={styles.spaceText}><b>Venue:</b> {space.venue_name}</div>
									<div style={styles.spaceText}><b>Address:</b> {space.front_address}</div>
									<div style={styles.spaceText}>
										<b>Type:</b> {printSpaceData(space.workspace_type_id).name}</div>
								</div>
							</div>
					):(
						<div className={'col d-flex align-items-center align-self-end py-auto'} style={{color:"#212529",fontSize:22,fontWeight:500}}>{reservation.venue_name}</div>
					)
				}


			<div className={'mt-2 ml-3'}>
				<div style={styles.idTitleContent}>
					<div style={styles.idTitle}>{'#LT' + reservation.reservation_id}</div>
				</div>
				<div style={styles.statusContent}>
					<b>Status:</b> {LeadUtilities.printHostLeadStatus(reservation)}
				</div>
			</div>

		</div>

		</>
	)
	}

	const grid = 'col-12 d-flex flex-row flex-wrap align-items-center py-2';
	const col5 = 'col-5';
	const col8 = 'col-7';

	if (!loading && !reservation) history.goBack();

	if (!loading && reservation && Object.keys(reservation).length === 0) {
		return (

			<div>
				Workspace Inquiry
		</div>

	);
	}

	const createRequestDetails = () => {
		return (
			<>
				<div className={'mt-3'}>
					<div style={styles.sectionhead}>
						Request Details
					</div>
					<div className={'d-flex flex-row flex-wrap '}>
						{ infoSection( null,"Event Type",printLocal(reservation.lead_type) ) }
						{ infoSection( null,"Request Date",moment(reservation.request_date).format('ddd, MMM DD YYYY') )}
					</div>
				</div>
			</>
		)
	}
	const createClientDetails = () => {


		return (
			<>
				<div className={'my-3'}>
					<div className={'mt-3'} style={styles.sectionhead}>
						Client Details
					</div>


					<div className={'ml-3 d-flex flex-row flex-wrap'}>


						{infoSection(faUser, 'Client Name', reservation.first_name + ' ' + reservation.last_name)}
						{infoSection(faEnvelope, 'Client Email', reservation.email)}
						{infoSection(faPhoneAlt, 'Client Phone Number', reservation.phone)}


					</div>
				</div>

			</>
		)
	}
	const createNotesList = ()=>{
		let notes = reservation.notes?.filter((note) => (note.created_by == user?.id))
		return (
			<div className={'py-3'}>
				<Accordion
					classes={{
						root: classes.accordionRoot
					}}
					defaultExpanded={true}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon/>}
						aria-controls={`notes-content`}
						id={`notes-header`}
					>

						<div className={'col-12 d-flex flex-row justify-content-between align-items-center'}>
							<div style={{...styles.sectionhead,paddingBottom:0}}>Notes</div>
							{/*<div className={'cursor-pointer'} style={styles.btn}*/}
							{/*     onClick={() => toggleShowMore('add note')}>*/}
							{/*	Add Note*/}
							{/*</div>*/}

						</div>

					</AccordionSummary>


					{notes.length > 0 &&

					(
						<AccordionDetails className={'d-flex flex-column px-5'}>
							{notes.map((item, i) => (

									<div key={i} style={{marginTop: 8}}>
										<div style={{
											...styles.reservSubtitle,
											paddingBottom: 5
										}}>{item.created_user} ({printDateFromDateTime(item.created_on)}):
										</div>
										<div style={styles.reservText}>{item.note}</div>
									</div>
								)
							)
							}
						</AccordionDetails>
					)
					}
					<AccordionActions>
						<div className={'cursor-pointer'} style={styles.btn}
						     onClick={() => toggleShowMore('add note')}>
							Add Note
						</div>
					</AccordionActions>
				</Accordion>
			</div>

		);
	}
	const toggleShowMore = (type?: string) => {
		setShowMore(type ?? null)
	}

	const createBookingStatusPicker = () => {
		return (
			<div className={'p-3'} style={styles.footer}>
				<div style={styles.footerLabel}>Update client status</div>
				<FMSelectInput id={'booking-status-select'}
				               options={{data: LeadUtilities.LEAD_STATUSES}}
				               value={request.booking_status}
				               placeholder={'Click here'}
				               title={'none'}
				               extraClassNames={'col-md-6'}
				               disabled={!reservation.edit_perm}
				               onChange={//@ts-ignore
					               (e) => handleStatus('booking_status', e.target.value)}/>
			</div>
		)
	}
	const createPaymentStatusPicker = () => {
		let {closed, lost, chosenToOther, hostClosed} = LeadUtilities.getHostLeadExtraChecks(reservation);

		if (!closed && !chosenToOther && !lost) {
			return (
				<div className={'p-3'} style={styles.footer}>
					<div style={styles.footerLabel}>Update payment status</div>
					<FMSelectInput options={{data: LeadUtilities.LEAD_PAYMENT_STATUSES}}
					               value={request.payment_status}
					               placeholder={'Click here'}
					               title={'none'}
					               extraClassNames={'col-md-6'}
					               disabled={!reservation.edit_perm}
					               onChange={//@ts-ignore
						               (e) => handleStatus('payment_status', e.target.value)}/>
				</div>
			)
		} else if (reservation['invoice_paid'] > 0 && !chosenToOther && !lost) {

			return (
				<div className={'p-3 grid'} style={styles.footer}>
					{createHoverGridItem("Paid by", reservation['payment_method'])}
					{
						(
							() => {
								switch (reservation['payment_method']) {
									case 'Bank Transfer':
										return (
											<>
												{createHoverGridItem("Transaction Number", reservation['transaction_number'])}
												{createHoverGridItem("Bank", reservation['bank_name'])}
												{createHoverGridItem("Transfer Date", reservation['transfer_date'])}

											</>
										);

									case 'Cheque':
										return createHoverGridItem("Cheque #", reservation['cheque_number']);

									case 'Cash':
										return (createHoverGridItem("Received by", reservation['cash_receipt']));
								}
							}
						)()
					}
				</div>
			)


		}
	}
	const createCallFollowUp = () => {
		return (
			<div className={'d-flex rounded p-4'} style={{backgroundColor: blue[50]}}>
				<div style={styles.idTitleContent}>
					<div style={{...styles.reservText, fontWeight: 500}}>Did you call the client ?</div>
				</div>

				<div className={'d-flex rounded px-3 align-items-center cursor-pointer'}
				     style={{backgroundColor: blueGrey[100],}}>
					<div style={{fontSize: 16, fontWeight: 400}} onClick={handleUpdateCalled}>Yes</div>
				</div>

			</div>
		)
	}

	const createMoreDialog = () => {
		return (

			<Dialog
				open={Boolean(showMore)}
				onClose={() => toggleShowMore()}
				aria-labelledby="max-width-dialog-title"
			>
				<DialogTitle>
					<div className={'d-flex flex-row justify-content-between align-items-center'}>
						{(showMore === 'add note') ? 'Add Note'
							: (showMore === 'schedule visit') ? 'Schedule a Visit'
								: (showMore === 'visit scheduled') ? 'Visit Scheduled'
									: (showMore === 'send proposal') ? 'Send Proposal'
										: (showMore === 'view proposals') ? 'Sent Proposals'
											: (showMore === 'contract details') ? 'Update Contract Details'
												: ''}
						<IconButton className={'ml-auto'} aria-label="close" onClick={() => toggleShowMore()}>
							<CloseIcon/>
						</IconButton>
					</div>
				</DialogTitle>
				<DialogContent>
					<form
						onSubmit={handleMoreSubmit}>
					{
					(showMore === 'schedule visit') ? (
						<div className={'d-flex flex-row flex-wrap pb-2'}>

							<FMDateInput id='visitDate' defaultValue={request.visitDate} title={'Date'} required={true}
							             extraClassNames={'col-md-6'}/>
							<FMTimeInput id='visitTime' defaultValue={request.visitTime} title={'Time'} required={true}
							             extraClassNames={'col-md-6'}/>
							<FMTextInput id='fName' type='name' title={'First Name'} defaultValue={user?.first_name}
							             required={true}/>
							<FMTextInput id='lName' type='name' title={'Last Name'} defaultValue={user?.last_name}
							             required={true}/>
							<FMTextInput id='email' type='email' title={'Email Address'} defaultValue={user?.email}
							             required={true}/>
							<FMTextInput id='phone' type='phone' title={'Phone Number'} defaultValue={user?.phone}
							             required={true}/>
							<div style={{marginBottom: 10}}>Note: This is to schedule the visit with the lead after you
								have spoken to them. A reminder will be sent to the lead of scheduled date and time.
							</div>
							<div className={'col-12 d-flex flex-row justify-content-end'}>
								<FMButton title={'Submit'} isLoading={showMoreLoading} extraClassNames={'col-sm-3'}/>
							</div>
						</div>
					) : (showMore === 'send proposal') ? (
						<>

							{[...Array(emailsCount)].map((e, i) => (
								<FMTextInput key={i}
								             id={'email'+i}
								             type='email'
								             title={'Email Address' + (i > 0 ? ' ' + (i + 1) : '')}
								             defaultValue={i > 0 ? '' : reservation.email}
								             required={(i == 0)}/>
							))}
							{(emailsCount < 20) &&
							<div style={styles.addBtn}
							     onClick={() => setEmailsCount(e => (e < 20) ? ++e : e)}>
								Add another email
							</div>
							}
							<FMFileInput id='file' title={'File'} required={true} mimeType={'application/pdf'}/>
							<div className={'col-12 d-flex flex-row justify-content-end'}>
								<FMButton title={'Send'} isLoading={showMoreLoading} extraClassNames={'col-sm-3'}/>
							</div>

						</>
					) : (showMore === 'contract details') ? (
						<>
							<FMDateInput id='startDate' title={'Contract Start Date:'}
							             defaultValue={request.startDate} required={true}/>
							<FMDateInput id='endDate' title={'Contract End Date:'}
							             defaultValue={request.endDate} required={true}/>
							<FMTextInput id='price' type='number' title={'Total Contract Value (excluding taxes):'}
							             defaultValue={printPriceOnly(reservation.host_price ?? '', LeadUtilities.LEADS_DEFAULT_CURRENCY, currency.name)?.toFixed(2) ?? ''}
							             placeholder={currency.name}
							             min={0}
							             required={true}/>
							<FMFileInput id='file' title={'Upload contract'} required={false}
							             mimeType={'application/pdf'}/>

							<div className={'col-12 d-flex flex-row justify-content-end'}>
								<FMButton title={'Submit'} isLoading={showMoreLoading} extraClassNames={'col-sm-3'}/>
							</div>
						</>
					): (showMore === 'add note') ? (
						<>
							<FMMultilineTextInput id='note' required={true} title={'Note'} onChange={
								(e) => {//@ts-ignore
									handleChange('note', e.target.value)
								}
							}/>
							<div className={'col-12 d-flex flex-row justify-content-end'}>
								<FMButton title={'Add'} isLoading={showMoreLoading} extraClassNames={'col-sm-3'}/>
							</div>
						</>
					) : (showMore === 'visit scheduled') ? (
						(createRescheduleVisitDialogParts()).content
					) : (showMore === 'view proposals') ? (
						(createViewProposalsDialogParts()).content
					) : (
						<></>
					)}
					</form>
				</DialogContent>
			</Dialog>

		)
	}

	const createRescheduleVisitDialogParts = () => {
		let {request: _request, cancelled} = reservation?.visits_data
		let content = (
			<div className={'d-flex flex-row flex-wrap pb-2'}>
				<div className={'col-12 pb-3'}>
					<b>Current Scheduled Visit: </b>{printDateTimeFromServer(_request?.visit_datetime) ?? "error"}
					<span
						className={clsx('label ', (_request.visit_status == 'active') ? 'success' : 'error')}>{capitalizeFirst(_request?.visit_status ?? "")}</span>
				</div>


				<Accordion
					className={'col-12'}
					classes={{
						root: classes.accordionRoot
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon/>}
						aria-controls={`notes-content`}
						id={`notes-header`}
					>
						<div style={{...styles.sectionhead, paddingBottom: 0}}>Reschedule Visit</div>

					</AccordionSummary>

					<AccordionDetails>
						<div className={'d-flex flex-row flex-wrap pb-2 col-12'}>
							<FMDateInput id='visitDate'
							             defaultValue={moment(_request.visit_datetime).format('YYYY-MM-DD')}
							             title={'Date'} required={true}
							             extraClassNames={'col-md-6'}/>
							<FMTimeInput id='visitTime'
							             defaultValue={moment(_request.visit_datetime).format('HH:mm:ss')}
							             title={'Time'} required={true}
							             extraClassNames={'col-md-6'}/>

							<div className={'col-12 d-flex flex-row justify-content-end'}>
								<FMButton title={'Reschedule Visit'} isLoading={showMoreLoading}
								          extraClassNames={'col-12 col-sm-5 col-md-4 col-lg-3'}/>
							</div>
						</div>
					</AccordionDetails>
				</Accordion>


				{
					(cancelled && cancelled?.length > 0) && (
						<Accordion
							className={'col-12'}
							classes={{
								root: classes.accordionRoot
							}}
							defaultExpanded={true}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon/>}
								aria-controls={`notes-content`}
								id={`notes-header`}
							>
								<div style={{...styles.sectionhead, paddingBottom: 0}}>Cancelled Visits</div>

							</AccordionSummary>

							<AccordionDetails className={'flex-column d-flex'}>
								{
									cancelled.map(
										(item) => (
											<div
												className={'col-12 py-1 h6'}>{printDateTimeFromServer(item?.visit_datetime)}</div>
										)
									)
								}
							</AccordionDetails>
						</Accordion>
					)

				}


			</div>
		)
		return {title: "", content}
	}

	const createViewProposalsDialogParts = () => {
		let {proposals_data: proposals, first_name, last_name, request_date} = reservation;
		let content = (
			<div className={'d-flex flex-row flex-wrap pb-2'}>


				<div className={'p-2 mb-3'} style={{...styles.footer}}>
					<table className={'w-100'} style={{tableLayout: 'fixed'}}>
						<tr>
							<td><strong>Client</strong></td>
							<td>{first_name + ' ' + last_name}</td>
							<td><strong>Request Date</strong></td>
							<td>{printDateTimeFromServer(request_date)}</td>
						</tr>
					</table>
				</div>

				<div className="col-12">
					<table className={'w-100'}>
						<thead>
						<tr>
							<th>Date Sent</th>
							<th>Email From</th>
							<th>Sent to</th>
							<th>Status</th>
							<th>Action</th>
						</tr>
						</thead>
						<tbody>

						{(proposals && proposals?.length > 0) && (
							proposals.map((proposal) => (
									<>
										<tr style={{paddingTop: 15, borderTop: '1px solid #cdddeb'}}>
											<td>
												{printDateTimeFromServer(proposal['created_on'])}
											</td>
											<td>{proposal['from_email']}</td>
											<td className={'py-2'}>
												{JSON.parse(proposal['to_emails']).map(email => (
													<> {email} <br/></>
												))}
											</td>
											<td>
												<span
													className={(proposal['status'] === 'void') ? 'label error' : 'label success'}>{capitalizeFirst(proposal['status'])}</span>
											</td>
											<td>
												<a href={proposal['contract_file']}><span
													className="badge badge-success">Download</span>
												</a>
											</td>
										</tr>
									</>

								)
							)
						)
						}

						</tbody>
					</table>
				</div>
				<div className={'col-12 d-flex justify-content-end mt-5 mb-3'}>
					<FMButton extraClassNames={' col-sm-6 col-md-5 col-lg-4'} title={'Send a new Proposal'}
					          onClick={handleSendProposal}/>
				</div>

			</div>
		)
		return {title: "Sent Proposals", content}
	}

	const createHoverGridItem = (title, value) => {
		return (
			<div className={clsx(grid, classes.hoverListItem)}>
				<div className={col5}>
					<div style={{...styles.reservSubtitle, fontSize: 16}}>
						{title}
					</div>
				</div>
				<div className={col7}>
					<div style={{...styles.reservText, fontSize: 16, textAlign: 'right'}}>
						{value}
					</div>
				</div>
			</div>
		)
	}
	const createClosedDetails = () => {
		return (
			<Fragment>
				{/*<div className={'label green'} style={styles.sectionhead}>*/}
				{/*	Closed*/}
				{/*</div>*/}

				<div className={grid} style={styles.proposal}>
					{createHoverGridItem("Closed On", printDateTimeFromServer(reservation['date_closed']))}
					{createHoverGridItem("Closed Price", reservation.host_price ? printPrice(reservation.host_price, LeadUtilities.LEADS_DEFAULT_CURRENCY, currency.name) : '0')}
				</div>

				<Divider className={'my-2'}/>
			</Fragment>
		)
	}
	const createClosureDetails = () => {
		let {closed, lost, chosenToOther, hostClosed} = LeadUtilities.getHostLeadExtraChecks(reservation);
		return <Fragment>
			<div style={styles.sectionhead}>
				Contract Details
			</div>

			<div className={grid} style={styles.proposal}>

				<div className={clsx(grid, classes.hoverListItem)}>
					<div className={col5}>
						<div style={{...styles.reservSubtitle, fontSize: 16}}>
							Closed On
						</div>
					</div>
					<div className={col7}>
						<div style={{...styles.reservText, fontSize: 16, textAlign: 'right'}}>
							{reservation.host_closed_date ? moment(reservation.host_closed_date).format('MMM D, YYYY') : '-'}
						</div>
					</div>
				</div>


				<div className={clsx(grid, classes.hoverListItem)}>
					<div className={col5}>
						<div style={{...styles.reservSubtitle, fontSize: 16}}>
							Closed Price
						</div>
					</div>
					<div className={col7}>
						<div style={{...styles.reservText, fontSize: 16, textAlign: 'right'}}>
							{reservation.host_price ? printPrice(reservation.host_price, LeadUtilities.LEADS_DEFAULT_CURRENCY, currency.name) : '0'}
						</div>
					</div>
				</div>


				<div className={clsx(grid, classes.hoverListItem)}>
					<div className={col5}>
						<div style={{...styles.reservSubtitle, fontSize: 16}}>
							Start Date
						</div>
					</div>
					<div className={col7}>
						<div style={{...styles.reservText, fontSize: 16, textAlign: 'right'}}>
							{reservation.host_move_date ? moment(reservation.host_move_date).format('MMM D, YYYY') : '-'}
						</div>
					</div>
				</div>


				<div className={clsx(grid, classes.hoverListItem)}>
					<div className={col5}>
						<div style={{...styles.reservSubtitle, fontSize: 16}}>
							End Date
						</div>
					</div>
					<div className={col7}>
						<div style={{...styles.reservText, fontSize: 16, textAlign: 'right'}}>
							{reservation.host_end_date ? moment(reservation.host_end_date).format('MMM D, YYYY') : '-'}
						</div>
					</div>
				</div>
			</div>

			{
				(reservation.signed_contract == null || reservation.signed_contract === '') && (
					<div className={'d-flex justify-content-center'}
					     style={styles.errorLabel}>No contract is attached yet.</div>
				)
			}
			<Divider className={'my-2'}/>
		</Fragment>
	}

	const createContractDetails = () => {
		devLog("CreateContractDetails")
		let {closed, lost, chosenToOther, hostClosed} = LeadUtilities.getHostLeadExtraChecks(reservation);
		return (
			<div>
				{closed && !(chosenToOther || lost) && (
					createClosedDetails()
				)}
				{!closed && hostClosed && (
					createClosureDetails()
				)}
				<div className={'px-md-5 mx-md-5 '}>
					<div className={'d-flex flex-wrap px-2 px-lg-5 mx-md-5'}>

						{(!closed && !hostClosed && reservation.edit_perm) && (
							<div className={grid}>
								{
									(reservation?.visits_data?.request?.visit_datetime) ? (
										<FMButton extraClassNames={'col-md-6'} title={<span> <FontAwesomeIcon
											icon={faCheck}/> Visit Scheduled</span>}
										          extraProps={{
											          style: {
												          backgroundColor: '#269535',
												          borderColor: '#269535',
												          color: 'white',
											          }
										          }}
										          onClick={handleViewScheduledVisit}
										/>
									) : (
										<FMButton extraClassNames={'col-md-6'} title={'Schedule Visit'}
										          onClick={handleScheduleVisit}/>
									)
								}

								{
									(reservation?.proposals > 0 || reservation?.proposals_data?.length > 0) ? (
										<FMButton extraClassNames={'col-md-6'}
										          title={<span> <FontAwesomeIcon icon={faCheck}/> Proposal Sent </span>}
										          extraProps={{
											          style: {
												          backgroundColor: '#269535',
												          borderColor: '#269535',
												          color: 'white',
											          }
										          }}
										          onClick={handleViewProposals}
										/>
									) : (
										<FMButton extraClassNames={'col-md-6'} title={'Send Proposal'}
										          onClick={handleSendProposal}/>
									)
								}

							</div>
						)}

						<div className={grid}>

							{
								!closed && hostClosed && (
									<div className={grid}>

										<FMButton
											extraClassNames={(reservation.signed_contract != null && reservation.signed_contract !== '') ? 'col-md-6' : ''}
											title={'Update Contract'}
											onClick={handleUpdateContractDetails}/>

										{(reservation.signed_contract != null && reservation.signed_contract !== '') && (

											<FMButton
												extraClassNames={'col-md-6'}
												title={'View Contract'}
												onClick={handleViewContract}/>

										)}
									</div>
								)
							}
							{(!closed && !hostClosed && reservation.edit_perm) && (
								<FMButton
									title={'Close This Lead'}
									isLoading={requestsLoading['contract signed'] != null}
									onClick={handleSignedContract}
									extraProps={{
										style: {
											backgroundColor: '#269535',
											borderColor: '#269535',
											color: 'white',
										}
									}}/>
							)}
							<Divider className={'my-2'}/>
						</div>
					</div>
				</div>
			</div>

		)
	};

	return (
		<div className="">
			<div className="container pr-0 pl-0" style={{marginTop: 120}}>
				<div className="page">

					<div className={`row page-lg-title mt-4 mb-5 `}>
						Workspace Inquiry
					</div>
					{
						(loading) ? (
								<div className={clsx('d-flex col-12 justify-content-center')}>
									<CircularProgress className={clsx('mx-auto my-5')}/>
								</div>
							) :
							( Boolean(reservation) && (<>
									{createHeader()}
									<Divider className={'my-1'}/>
									{!reservation.called_lead_on && !LeadUtilities.getHostLeadCalledLead(reservation) && (
										<>
											{createCallFollowUp()}
											<Divider className={'my-2'}/>
										</>
									)}

									{reservation.host_accepted == 1 && (
										<>
											{
												(LeadUtilities.getHostLeadStatus(reservation) == 'contract signed') || (
													createBookingStatusPicker()
												)
											}
											<Divider className={'my-2'}/>
										</>

									)}


									{createRequestDetails()}
									<Divider className={'my-2'}/>
									{createClientDetails()}
									<Divider className={'my-2'}/>


									{createContractDetails()}
									{createPaymentStatusPicker()}


									{createNotesList()}
									{createMoreDialog()}

								</>)

							)
					}

					<AlertSnackbar ref={snackbar}/>
					<AlertDialog ref={alertDialog}/>
				</div>
			</div>
		</div>
	)
}



const styles = {
	baseView: {
		display: 'none',
		zIndex: -1,
		backgroundColor: 'rgba(0, 0, 0, 0.3)',
	},
	baseViewAct: {
		flex: 1,
		position: 'absolute' as const,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 2,
		backgroundColor: 'rgba(0, 0, 0, 0.3)',
	},
	container: {
		backgroundColor: 'white' as const,
		flex: 1,
	},
	content: {
		flex: 1,
		paddingHorizontal: 20,
	},
	space: {
		flex: 1,
		marginTop: 6,
		paddingBottom: 16,
		flexDirection: 'row' as const,
		borderBottomColor: '#CFD8E7',
		borderBottomWidth: 0.5,
	},
	spaceimg: {
		height: 200,
		resizeMode: 'cover',
		borderRadius: 10,
		backgroundColor: '#E5E7E9',
	},
	generalimg: {
		resizeMode: 'contain' as const,
		marginTop: 'auto' as const,
		marginBottom: 'auto' as const,
		marginLeft: 'auto' as const,
		marginRight: 'auto' as const,
	},
	spacedetails: {
		flex: 1,
		flexShrink: 1,
		flexDirection: 'column' as const,
		paddingLeft: 14,
		overflow: 'hidden' as const,
	},
	spaceTitle: {
		color: '#212529',
		paddingBottom: 3,
		paddingRight: 50,
		overflow: 'hidden' as const,
		fontWeight: 600,
		fontSize: 18,
	},
	spaceText: {
		color: '#212529',
		paddingBottom: 3,
		overflow: 'hidden' as const,
		fontSize: 17,
		paddingLeft: '0.5rem',
	},
	map: {
		width: '100%',
		height: '100%',
		borderRadius: 12,
	},
	mapContainer: {
		marginTop: 12,
		marginBottom: 20,
		backgroundColor: '#cfd8e7',
		borderRadius: 12,
		borderColor: '#CFD8E7',
		borderWidth: 1,
		width: '100%',
		// height: (Dimensions.get('window').width - 50),
	},
	ratingTitle: {
		color: '#CFD8E7',
		paddingBottom: 10,
		overflow: 'hidden' as const,
		fontWeight: 700,
		textAlign: 'center' as const,
		fontSize: 16,
	},
	reservTitle: {
		color: '#212529',
		paddingBottom: 16,
		overflow: 'hidden' as const,
		fontWeight: 600,
		fontSize: 16,
	},
	reservSubtitle: {
		color: '#394148',
		overflow: 'hidden' as const,
		fontWeight: 500,
		fontSize: 18,
	},
	reservText: {
		color: '#212529',
		paddingBottom: 3,
		overflow: 'hidden' as const,
		fontSize: 17,
	},
	reservprice: {
		color: '#212529',
		paddingBottom: 1,
		overflow: 'hidden' as const,
		fontSize: 18,
	},
	quoteTitle: {
		color: '#212529',
		paddingBottom: 3,
		overflow: 'hidden' as const,
		fontSize: 17,
		fontWeight: 500,
		paddingRight: 46,
	},
	noteText: {
		textAlign: 'left' as const,
		color: '#212529',
		fontSize: 16,
		lineHeight: 24,
		marginTop: 8,
		marginBottom: 16,
	},
	noteLink: {
		textAlign: 'left' as const,
		color: '#212529',
		textDecorationLine: 'underline' as const,
		fontSize: 16,
	},
	head: {
		flex: 0,
		flexDirection: 'row' as const,
		height: 30,
		marginTop: 14,
		marginBottom: 18,
		justifyContent: 'center' as const,
	},
	quoteHead: {
		flex: 0,
		flexDirection: 'row' as const,
		height: 30,
		marginTop: 4,
		marginBottom: 6,
		justifyContent: 'center' as const,
	},
	idTitleContent: {

		paddingRight: 6,
		justifyContent: 'center' as const,
		marginTop: 'auto' as const,
		marginBottom: 'auto' as const,
	},
	idTitle: {
		justifyContent: 'center' as const,
		marginTop: 'auto' as const,
		marginBottom: 'auto' as const,
		fontSize: 17,
	},
	statusContent: {
		flexGrow: 0,
		paddingTop: 4,
		flexShrink: 0,
		justifyContent: 'center' as const,
	},
	priceContent: {
		flexGrow: 0,
		flexShrink: 0,
	},
	sectionhead: {
		width: '100%',
		fontSize: 19,
		fontWeight: 600,
		paddingBottom: 20,
	},
	sectionSubhead: {
		width: '100%',
		fontSize: 18,
		color: '#212529',
		fontWeight: 500,
		paddingBottom: 8,
	},
	ratingContainer: {
		flexDirection: 'row' as const,
		alignItems: "center",
		justifyContent: "flex-start"
	},
	reviewText: {
		fontWeight: 700,
		alignItems: "center",
		fontSize: 20,
		color: "rgba(230, 196, 46, 1)",
	},
	starContainer: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-start"
	},
	btnGrid: {
		flexDirection: 'row' as const,
		marginHorizontal: -4,
	},
	col: {
		flex: 1,
		flexDirection: 'column',
		paddingHorizontal: 8,
	},

	quoteDecline: {
		color: '#212529',
		paddingTop: 20,
		paddingBottom: 4,
		fontSize: 16,
	},
	quoteDeclineNote: {
		color: '#212529',
		fontSize: 16,
	},
	pt2: {
		paddingTop: 8,
	},
	pb2: {
		paddingBottom: 10,
	},
	pb4: {
		paddingBottom: 20,
	},
	btnView: {
		flex: 1,
		height: 48,
		backgroundColor: blue[400],
		marginBottom: 10,
		borderRadius: 10,
		marginHorizontal: 8,
		paddingHorizontal: 4,
		justifyContent: 'center' as const,
	},
	btnText: {
		flex: 0,
		flexGrow: 0,
		textAlign: 'center' as const,
		color: 'white' as const,
		fontSize: 17,
		fontWeight: 500,
	},
	onoffBtn: {
		height: 30,
		backgroundColor: blueGrey[100],
		borderRadius: 6,
	},
	onoffText: {
		fontSize: 16,
		fontWeight: 400,
	},
	onActive: {
		fontSize: 16,
		fontWeight: 500,
	},
	btn: {
		textAlign: 'right' as const,
		color: 'black' as const,
		fontSize: 17,
		fontWeight: 400,
		textDecorationLine: 'underline' as const,
	},
	divider: {
		flex: 1,
		height: 1,
		marginBottom: 24,
		backgroundColor: '#CFD8E7',
	},
	emptyTitle: {
		fontSize: 17,
		fontWeight: 400,
		color: '#697486',
	},
	proposal: {
		paddingTop: 20,
		paddingHorizontal: 16,
		paddingBottom: 16,
		borderWidth: 1,
		backgroundColor: '#e1edfa',
		borderColor: '#bacce8',
		borderRadius: 10,
		marginBottom: 24,
	},
	mapIconView: {
		position: "absolute",
		right: 0,
		top: 46,
		width: 36,
		height: 36,
		zIndex: 2,
	},
	mapIcon: {
		width: 36,
		height: 36,
	},
	backView: {
		position: 'absolute' as const,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	row: {
		flex: 1,
		flexDirection: 'column',
	},
	showMore: {
		flex: 0,
		flexDirection: 'row' as const,
		marginTop: 'auto' as const,
		marginBottom: 'auto' as const,
		paddingVertical: 16,
		marginHorizontal: 32,
		justifyContent: "center",
		alignItems: "center",
		// width: (Dimensions.get('window').width - 64),
		// maxHeight: (Dimensions.get('window').height - 300),
		backgroundColor: "white",
		borderRadius: 12,
		shadowColor: "#000000",
		shadowOffset: {
			width: 0,
			height: 1
		},
		shadowOpacity: 0.125,
		shadowRadius: 2,
		elevation: 2,
	},
	moreHeader: {
		position: 'relative' as const,
		paddingHorizontal: 12,
		marginBottom: 16,
		borderBottomColor: '#CFD8E7',
		borderBottomWidth: 0.5,
	},
	moreHeaderText: {
		color: 'black',
		textAlign: 'center' as const,
		fontSize: 19,
		fontWeight: 500,
		marginBottom: 16,
	},
	closeView: {
		padding: 2,
		position: 'absolute' as const,
		top: 0,
		right: 10,
	},

	footer: {
		paddingVertical: 12,
		paddingHorizontal: 20,
		borderTopWidth: 0.5,
		borderTopColor: '#CFD8E7',
		backgroundColor: '#eef1f3',
		borderRadius: '0.5rem'
	},
	footerLabel: {
		fontWeight: 400,
		fontSize: 16,
		marginBottom: 8,
		color: '#536671',
	},
	errorLabel: {
		fontSize: 17,
		paddingBottom: 10,
		fontWeight: 500,
		color: red[500],
	},
	addBtn: {
		color: '#000000',
		marginBottom: 20,
		fontWeight: 500,
		textDecorationLine: 'underline' as const,
		fontSize: 17,
		cursor: 'pointer'
	},
}
