import React, {useRef} from 'react';
import {Theme} from "@material-ui/core/styles";
import {blueGrey} from "@material-ui/core/colors";
import {makeStyles} from "@material-ui/styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconButton, Tooltip} from '@material-ui/core';
import clsx from 'clsx';
import {ReactComponent as PenSVG} from "src/img/pen.svg";
import {ReactComponent as TrendingSVG} from "src/img/trending_dark.svg";
import {ReactComponent as TrendingOffSVG} from "src/img/trending_dark_off.svg";
import {ReactComponent as TrashSVG} from "src/img/trash.svg";
import {ReactComponent as FavIcon} from 'src/img/fav.svg';
import {ReactComponent as FavActIcon} from 'src/img/fav-act.svg';
import {ReactComponent as TrendingIcon} from 'src/img/trending.svg';
import {SpaceStyles} from "src/components/host/mobileStyles";
import AlertDialog, {AlertDialogData} from "src/components/organisms/alert-dialog/AlertDialog";
import {VenueAPI} from "src/APIs/venue/VenueAPI";
import AlertSnackbar from "src/components/organisms/snackbar/AlertSnackBar";
import {refreshVenues, updateVenues} from "src/redux/VenuesSlice";
import {Space} from "src/APIs/venue/space";
import {EditWorkspace} from "src/components/host/host-dashboard/manage-listings/add-edit-workspaces/edit-workspace";
import {useHistory} from 'react-router-dom';
import {capitalizeFirst} from "src/components/host/host-helpers";
import {connect} from "react-redux";
import {devLog} from 'src/Helpers';


export type SpaceItemProps = {
	isMaster?: boolean,
	hideFav?: boolean,
	id?: any,
	isListing?: boolean,
	space: Space,
	currency?: any,
	venue?: any,
	edit_perm?: any,
	delete_perm?: any,
	editSpace?: any,
	featureSpace?: any,
	deleteSpace?: any,
	cardClasses?: any,
}


function SpaceItem({
	                          isMaster,
	                          hideFav = true,
	                          id,
	                          isListing,
	                          space,
	                          currency,
	                   venue,
	                   edit_perm,
	                   delete_perm,
	                   editSpace,
	                   featureSpace,
	                   deleteSpace,
	                   cardClasses,
	                   venues,
	                   updateVenues,
	                   refreshVenues
                   }: SpaceItemProps & { venues, updateVenues, refreshVenues }) {

	const classes = useStyles();
	const isMapView = false;
	const isLarge = true;
	const isFav = true;
	const imageStyle = isLarge || isMaster ? SpaceStyles.spaceLgImage : SpaceStyles.spaceImage;
	const titleStyle = isLarge || isMaster ? SpaceStyles.spaceLgTitle : SpaceStyles.spaceTitle;
	const ftrStyle = isLarge || isMaster ? SpaceStyles.spaceLgfeatured : SpaceStyles.spacefeatured;
	const fav = isLarge || isMaster ? 27 : 24;
	const ftr = isLarge || isMaster ? 25 : 23;
	const history = useHistory();


	const dialog = useRef<AlertDialog>(null);
	const snackbar = useRef<AlertSnackbar>(null);

	const handleSpaceTapped = () => {
		// if (handleSpace)
		// 	handleSpace(space.workspace_id);
		// else
		// 	window.open("/space/" + space.workspace_id + (term ? "?t=" + term : ""), "_blank");
		// dialog.current?.open()
	}


	const handleFeatureSpace = () => {
		let {featured, workspace_id:id} = space

		let dialogData:AlertDialogData = {
			title: (featured === 'yes') ? "Mark space as standard" : "Mark space as featured",
			message:"",
			agreeText:"Ok",
			cancelText:"Cancel",
			onAgreeClicked:(dialog:AlertDialog)=>{
				VenueAPI.featureSpace(venue,id,featured).then(
					(message)=> {
						devLog(message)
						snackbar.current?.setDataFromMessageObject(message)
						snackbar.current?.open();
						if (message.type === 'success') refreshVenues()
					}
				)

			},
		}


		dialog.current?.setDialogData(dialogData)
		dialog.current?.open()

	}

	const handleEditSpace = () => {
		history.push(
			EditWorkspace.route
				.replace(':venueID', venue.venue_details_id)
				.replace(':id?', space.workspace_id)
		)
	}


	const handleDeleteSpace = () => {

		let {workspace_id: id} = space

		let dialogData: AlertDialogData = {
			title: "Delete Space?",
			message: "",
			agreeText: "Ok",
			cancelText: "Cancel",
			onAgreeClicked:(dialog:AlertDialog)=>{
				VenueAPI.deleteSpace(venue,id).then(
					(message)=> {
						devLog(message)
						snackbar.current?.setDataFromMessageObject(message)
						snackbar.current?.open();
						if (message.type === 'success') refreshVenues()
					}
				)

			},
		}

		dialog.current?.setDialogData(dialogData)
		dialog.current?.open()

	}
	// devLog("space", space)
	return (
		// <div>
		// 	<Card variant={'outlined'} className={clsx('',classes.space,cardClasses)} >
		// 		<Typography>
		// 			{JSON.stringify(space,null,4)}
		// 		</Typography>
		// 	</Card>
		//
		// </div>
		<div onClickCapture={handleSpaceTapped}>

		<div style={ isMapView ? SpaceStyles.cardMapView : SpaceStyles.cardView}>
			<img
				style={{...(isMapView ? SpaceStyles.cardImageMap : imageStyle),height:"15rem"}}
				src={"https://fluidmeet.s3.amazonaws.com/venue_logos/"+space.image}
			/>
			<div style={ isMapView ? SpaceStyles.contMapView : SpaceStyles.contView}>
				{!isListing && (
					<div style={{...titleStyle, fontSize: 15 }} >
						<FontAwesomeIcon  style={{color: '#00a9e0', fontSize: 16,}}  icon={[(space.workspace_rating && space.workspace_rating > 0)?'fas':'fab',"star"]}/>
						{(space.workspace_rating && space.workspace_rating > 0) ? ' '+space.workspace_rating : ''}
						{(space.reviews > 0) ? <div className="full">({space.reviews})</div> : <></>}
					</div>
				)}

				{isListing ? (
					<>
						<div style={{...titleStyle, fontWeight: 500, paddingBottom: 6}}>{space.name}</div>
						<div style={{ flex: 1, flexDirection: 'row', justifyContent: 'center'}}>

							<div style={{flex: 1, flexGrow: 1, flexShrink: 1}}>
								<div style={{...titleStyle, paddingTop: 0, marginRight: 10, color: blueGrey[800]}}>
									{space.type_title}
								</div>
								<div style={{flex: 1, flexDirection: 'row'}}>
									<div
										style={{...SpaceStyles.statusText, ...SpaceStyles[`status${space.status == 'active' ? 'green' : 'red'}`]}}>{capitalizeFirst(space.status)}</div>
								</div>
							</div>

							<div className={clsx('flex-grow-0 flex-shrink-0 d-flex flex-row align-items-center')}>
								{edit_perm && (
									<>
										<Tooltip title={"Edit"}>
											<IconButton size={'medium'}
											            style={{...SpaceStyles.iconView, width: "auto", height: 'auto'}}
											            onClickCapture={() => handleEditSpace()}>
												<PenSVG style={{width: 24, height: 24}}/>
											</IconButton>
										</Tooltip>
										<Tooltip
											title={(space.featured === 'yes') ? "Remove From Featured" : "Make Featured"}>
											<IconButton
												style={{
													...SpaceStyles.iconView,
													width: "auto",
													height: 'auto',
													marginLeft: 8
												}} // '#e7eaef'
												onClickCapture={() => handleFeatureSpace()}>
												{(space.featured === 'yes') ? (
													<TrendingOffSVG style={{width: 24, height: 24}}/>
												) : (
													<TrendingSVG style={{width: 24, height: 24}}/>
												)}
											</IconButton>
										</Tooltip>
									</>
								)}

								{delete_perm && (
									<Tooltip title={"Delete"}>
										<IconButton
											style={{
												...SpaceStyles.iconView,
												width: "auto",
												height: 'auto',
												marginLeft: 8
											}}
											onClickCapture={() => handleDeleteSpace()}>
											<TrashSVG style={{width: 24, height: 24}}/>
										</IconButton>
									</Tooltip>
								)}
							</div>
						</div>
					</>
				) : (
					<>
						<div style={{...titleStyle }} >{space.front_address}</div>
						<div style={{...titleStyle }} >{space.name}</div>
					</>
				)}

				{!isFav && !isListing && (
					<div style={{...titleStyle, marginLeft: -14, }} >
						<div style={{color:'#FFF'}} >{'P '}</div>
						<div style={SpaceStyles.spacePrice}>
							{/*{printPrice(getSpacePrice(space, service, term), ((space.currency) ? space.currency : 'USD'), currency.name, true)}*/}
						</div>
						{/*{printSpacePricePer(space, service, term)}*/}
					</div>
				)}
			</div>
		</div>

		{(!hideFav && (
			<div style={SpaceStyles.spacefavourite}>
				<div onClickCapture={() => {}}>
					{(isFav) ? (
						// <FontAwesomeIcon name='heart' style={{fontSize: 18}}/>
						// <FontAwesomeIcon icon={faHeart} style={{width: 18, height: 18, marginTop: 3, marginRight: 5,}}/>{" "}
						<FavActIcon  />) : (<FavIcon  />)}
				</div>
			</div>
		))}

		{((space.featured === 'yes' ) && (

			<TrendingIcon  style={ftrStyle}/>

		))}

		<AlertDialog ref={dialog}/>
		<AlertSnackbar ref={snackbar}/>
	</div>
	);

}


const useStyles = makeStyles(
	(theme: Theme) => (
		{
			root: {
				maxWidth: '80%',
			},
			title: {
				verticalAlign: 'middle',
			},
			iconButton: {
				marginLeft: 'auto',
				alignSelf: 'middle',

			},
			space: {
				height: '20rem',
				borderRadius: '.5rem',
			}
		}
	)
)
const VenuesConnector = connect(
	(state: any, props) => ({venues: state.venues.data,}),
	{updateVenues, refreshVenues}
);
//@ts-ignore
export const SpaceItemEnhanced = VenuesConnector(SpaceItem)

