import banquet from 'src/img/banquet.png';
import boardroom from 'src/img/boardroom.png';
import classroom from 'src/img/classroom.png';
import reception from 'src/img/reception.png';
import theatre from 'src/img/theatre.png';
import ushape from 'src/img/u-shape.png';
import {MainStyles} from "src/components/host/mobileStyles";
import {
	AvIcon,
	ElecSharedIcon,
	FlipChartIcon,
	FurniturePriIcon,
	LcdIcon,
	LecternIcon,
	MailIcon,
	MicIcon,
	PantryIcon,
	PrinterIcon,
	ProjectorIcon,
	ReceptionIcon,
	StationaryIcon,
	TelPriIcon,
	VideoConfIcon,
	WhiteboardIcon,
	WifiIcon,
	WifiSharedIcon
} from "src/img/svg-exports";

export const getTypeTextKey = (numericKey) => {
	numericKey = numericKey?.toString()
	switch (numericKey) {
		case '10':
			return 'banquets-hall';
		case '9':
			return 'office-space';
		case '5':
			return 'meeting-room';
		case '4':
			return 'co-working-space';
	}
}
export const TYPES = [
	{label: 'Banquet Halls & Event Spaces (for 20 or more)', value: '10', textKey: 'banquets-hall'},
	{label: 'Office Spaces', value: '9', textKey: 'office-space'},
	{label: 'Meeting Rooms (for less than 20)', value: '5', textKey: 'meeting-room'},
	{label: 'Co-working Spaces (for 1)', value: '4', textKey: 'co-working-space'},
];

export const LAYOUTS = [
	{id: 'u_shape', label: 'U shape', image: <img src={ushape}/>},
	{id: 'boardroom', label: 'Boardroom', image: <img src={boardroom}/>},
	{id: 'reception', label: 'Reception', image: <img src={reception}/>},
	{id: 'banquet', label: 'Banquet', image: <img src={banquet}/>},
	{id: 'theatre', label: 'Theatre', image: <img src={theatre}/>},
	{id: 'classroom', label: 'Classroom', image: <img src={classroom}/>},
];

export const DAYS = [
	{label: 'Sunday', value: 'sunday'},
	{label: 'Monday', value: 'monday'},
	{label: 'Tuesday', value: 'tuesday'},
	{label: 'Wednesday', value: 'wednesday'},
	{label: 'Thursday', value: 'thursday'},
	{label: 'Friday', value: 'friday'},
	{label: 'Saturday', value: 'saturday'},
];

export const HOURLY = [
	{value: 'hour', label: 'Hourly'},
	{value: 'half_day', label: 'Half day'},
	{value: 'day', label: 'Day'},
];

export const MONTHLY = [
	{value: 'month', label: 'Monthly'},
	{value: 'quarter', label: 'Quarterly'},
	{value: 'half_year', label: 'Semi Annually'},
	{value: 'year', label: 'Annually'},
];

export const CATERED = [
	{value: 'one_coffee_break', label: 'One coffee break'},
	{value: 'two_coffee_break', label: 'Two coffee breaks'},
	{value: 'one_coffee_break_lunch_dinner', label: 'One coffee break + Lunch/Dinner'},
	{value: 'two_coffee_break_lunch_dinner', label: 'Two coffee breaks + Lunch/Dinner'},
	{value: 'lunch_dinner', label: 'Lunch/Dinner'},
];

export const SUMMER_CATERED = [
	{value: 'summer_one_coffee_break', label: 'One coffee break (Per Person)'},
	{value: 'summer_two_coffee_break', label: 'Two coffee breaks (Per Person)'},
	{value: 'summer_one_coffee_break_lunch_dinner', label: 'One coffee break + Lunch/Dinner (Per Person)'},
	{value: 'summer_two_coffee_breaks_lunch_dinner', label: 'Two coffee breaks + Lunch/Dinner (Per Person)'},
	{value: 'summer_lunch_dinner_non_alcoholic_drinks', label: 'Lunch/Dinner + Non-alcoholic drinks (Per Person)'},
	{value: 'summer_lunch_dinner_alcoholic_drinks', label: 'Lunch/Dinner + Alcoholic drinks (Per Person)'},
	{value: 'summer_minimum_price', label: 'Minimum Price (Total)'},
];

export const WINTER_CATERED = [
	{value: 'winter_one_coffee_break', label: 'One coffee break (Per Person)'},
	{value: 'winter_two_coffee_break', label: 'Two coffee breaks (Per Person)'},
	{value: 'winter_one_coffee_break_lunch_dinner', label: 'One coffee break + Lunch/Dinner (Per Person)'},
	{value: 'winter_two_coffee_breaks_lunch_dinner', label: 'Two coffee breaks + Lunch/Dinner (Per Person)'},
	{value: 'winter_lunch_dinner_non_alcoholic_drinks', label: 'Lunch/Dinner + Non-alcoholic drinks (Per Person)'},
	{value: 'winter_lunch_dinner_alcoholic_drinks', label: 'Lunch/Dinner + Alcoholic drinks (Per Person)'},
	{value: 'winter_minimum_price', label: 'Minimum Price (Total)'},
];


export const TERMS = [
	{label: 'One month', value: 'One month'},
	{label: 'Two months', value: 'Two months'},
	{label: 'Three months', value: 'Three months'},
	{label: 'Four months', value: 'Four months'},
	{label: 'Five months', value: 'Five months'},
	{label: 'Six months', value: 'Six months'},
	{label: 'Seven months', value: 'Seven months'},
	{label: 'Eight months', value: 'Eight months'},
	{label: 'Nine months', value: 'Nine months'},
	{label: 'Ten months', value: 'Ten months'},
	{label: 'Eleven months', value: 'Eleven months'},
	{label: 'Twelve months', value: 'Twelve months'},
];

export const OFFICE_AMENITIES = [
	{id: 'Wifi (shared)', label: 'Wifi (shared)', image: (<WifiSharedIcon style={MainStyles.detIcon}/>)},
	{id: 'Furniture (private)', label: 'Furniture (private)', image: (<FurniturePriIcon style={MainStyles.detIcon}/>)},
	{
		id: 'Telephone line (private)',
		label: 'Telephone line (private)',
		image: (<TelPriIcon style={MainStyles.detIcon}/>)
	},
	{id: 'Electricity (shared)', label: 'Electricity (shared)', image: (<ElecSharedIcon style={MainStyles.detIcon}/>)},
	{
		id: 'Receptionist(s) & Call Answering Services (shared)',
		label: 'Receptionist(s) & Call Answering Services (shared)',
		image: (<ReceptionIcon style={MainStyles.detIcon}/>)
	},
	{id: 'Mail Management', label: 'Mail Management', image: (<MailIcon style={MainStyles.detIcon}/>)},
	{
		id: 'Printer/Scanner/Fax (shared)',
		label: 'Printer/Scanner/Fax (shared)',
		image: (<PrinterIcon style={MainStyles.detIcon}/>)
	},
	{
		id: 'Pantry (Coffee, Tea, Water, Snacks) (shared)',
		label: 'Pantry (Coffee, Tea, Water, Snacks) (shared)',
		image: (<PantryIcon style={MainStyles.detIcon}/>)
	},
];

export const LICENCING_TERRITORY = [
	{value: 'Freezone', label: 'Freezone'},
	{value: 'Mainland', label: 'Mainland'},
];

export const CATERED_AMENITIES = [
	{id: 'Wifi', label: 'Wifi', image: (<WifiIcon style={MainStyles.detIcon}/>), key: 'wifi'},
	{
		id: 'Projector & Screen',
		label: 'Projector & Screen',
		image: (<ProjectorIcon style={MainStyles.detIcon}/>),
		key: 'screen'
	},
	{id: 'Flipchart', label: 'Flip Chart', image: (<FlipChartIcon style={MainStyles.detIcon}/>), key: 'flipchart'},
	{id: 'Whiteboard', label: 'Whiteboard', image: (<WhiteboardIcon style={MainStyles.detIcon}/>), key: 'whiteboard'},
	{id: 'LCD Screen', label: 'LCD Screen', image: (<LcdIcon style={MainStyles.detIcon}/>), key: 'lcd'},
	{id: 'Mic', label: 'Mic', image: (<MicIcon style={MainStyles.detIcon}/>), key: 'microphone'},
	{
		id: 'AV (Sound) System',
		label: 'AV (Sound) System',
		image: (<AvIcon style={MainStyles.detIcon}/>),
		key: 'sound_system'
	},
	{
		id: 'Video Conferencing',
		label: 'Video Conferencing',
		image: (<VideoConfIcon style={MainStyles.detIcon}/>),
		key: 'video'
	},
	{
		id: 'Notepads & Stationary',
		label: 'Notepads & Stationary',
		image: (<StationaryIcon style={MainStyles.detIcon}/>),
		key: 'stat'
	},
	{
		id: 'Lecturn',
		label: 'Lectern',
		image: (<LecternIcon style={MainStyles.detIcon}/>),
		key: 'lecturn'
	},
];

export const SUITABLE = [
	{id: 'Business Meetings/ Conferences', label: 'Business Meetings/ Conferences'},
	{id: 'Workshops & Team Building Activities', label: 'Workshops & Team Building Activities'},
	{id: 'Trainings', label: 'Trainings'},
	{id: 'Company Events (Catered)', label: 'Company Events (Catered)'},
	{id: 'Birthday/ Anniversaries', label: 'Birthday/ Anniversaries'},
	{id: 'Weddings/ Engagements', label: 'Weddings/ Engagements'},
];
