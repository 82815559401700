import React from 'react';
import {Link} from "react-router-dom";
import coWorking from '../../img/Coworking_spaces.jpg';
import office from '../../img/Office_Spaces_and_serviced_offices.jpg';
import meeting from '../../img/Meeting_rooms.jpg';
import events from '../../img/Banquet_and_event_halls.jpg';
import {useMediaQuery} from "@material-ui/core";
import Helmet from "react-helmet";

export const WHY_BECOME_PARTNER = [
  {title: "Market your brand", subtitle: "Build brand awareness and equity for your property and spaces"},
  {title: "Make money", subtitle: "Generate more revenue by digitally marketing your work, meeting, and event spaces"},
  {title: "Grow your business", subtitle: "Increase footfall, leads & bookings by listing your spaces on fluidmeet"},
  {
    title: "Simple & powerful",
    subtitle: "Maximize efficiency by offering your customers an automated booking/request process"
  },
  {
    title: "Manage sales efforts",
    subtitle: "Utilize fluidmeet dashboard to manage your pipeline of leads, requests and proposal"
  },
  {
    title: "Everything in one place",
    subtitle: "From initial inquiry to confirmed booking manage every aspect of your space"
  }
]
export const SPACE_TYPES = [
  {
    id: 'office-spaces',
    name: 'Office spaces',
    image: office,
    title: 'Office spaces',
    alt: "Serviced offices rental near you",
    subtitle: "Private offices that come fully furnished and equipped with wifi, DEWA, phone line and other shared services like pantry, printer/scanner, call answering, mail management and more.",
  },
  {
    id: 'banquets-and-event-spaces',
    name: 'Banquets halls and event spaces',
    image: events,
    title: 'Banquets & event halls',
    alt: "Banquet & event halls rental near you",
    subtitle: "Any workspace (Banquets, Event halls) that can be used to conduct large-sized events.",
  },
  {
    id: 'co-working-spaces',
    name: 'Co-working spaces',
    image: coWorking,
    title: 'Co-working spaces',
    alt: "Coworking spaces booking near you",
    subtitle: "Shared workspaces that come fully furnished and equipped with wifi, DEWA, phone line and other shared services like pantry, printer/scanner, call answering, mail management and more.",
  },
  {
    id: 'meeting-rooms',
    name: 'Meeting rooms',
    image: meeting,
    title: 'Meeting rooms',
    alt: "Meeting rooms booking near you",
    subtitle: "Any workspace (Meeting rooms, Training rooms, Boardrooms) that can be used to conduct small to mid-sized meetings.",
  },
]

export default function BecomeAHost() {
  const down_sm = useMediaQuery('(max-width:576px)')
  
  return (
      
      <>
        <Helmet>
          <meta charSet="utf-8"/>
          <meta name="description"
                content="Make the most of your space with Fluidmeet management tools. Stay organized, manage leads, and track your earnings - all in one place."/>
          <meta name="keywords"
                content="Flex spaces, serviced offices, meeting rooms, corporate events venues, banquet halls, event spaces, coworking spaces, workspaces, shared offices, business setup, company setup, freezone, mainland, business centers, business hubs, booking website"/>
          <title>Fluidmeet | List Your Space | Work, Meeting & Event Spaces</title>
          <link rel={"canonical"} href="https://www.fluidmeet.com/list-my-space" />
          {/* Event snippet for Banquet Halls DDR - Online Booking Success conversion page */}
					<script>
					{`
						window.gtag('event', 'conversion', {'send_to': 'AW-935885959/MRf2CN7LiP8CEIf5ob4D'});
						window.gtag('event', 'conversion', {'send_to': 'AW-935885959/ZGraCN3Ujv8CEIf5ob4D'});
					`}
					</script>
        </Helmet>
        
        {
          (down_sm) ? (
              <div className="host-banner">
                <span role={"img"} aria-label={"List your venues and spaces on fluidmeet"}/>
                <div className="heading-medium-title" style={{top: '18.00vw', left: '4.50vw', margin: '0px 0px'}}>
    
                  <h1 className="heading-medium mb-0" style={{textAlign: 'right', fontSize: '5.5vw', color: 'white'}}>
                    Become a <br/> fluidmeet Partner
                  </h1>
                  <h4 className="heading-medium"
                      style={{textAlign: 'left', fontWeight: 400, fontSize: '1rem', color: 'white'}}>
                    List Your Venues & Spaces</h4>
    
                  <div style={{marginTop: '2.50vw', marginLeft: '-3.00vw'}}>
                    <Link to="/host-application">
                      <button className="section-btn shadow" style={{
                        backgroundColor: '#fff',
                        color: '#0e7bc7',
                        fontSize: '2.95vw',
                        fontWeight: 700,
                        paddingLeft: '1.9vw ',
                        paddingRight: '1.9vw ',
                      }}>List My Spaces
                      </button>
                    </Link>
                  </div>
                </div>
              
              </div>
          ) : (
              <div className="host-banner">
                
                <div className="heading-medium-title" style={{top: '13.00vw', left: '10.50vw', margin: '0px 0px'}}>
                  <h1 className="heading-medium mb-0"
                      style={{textAlign: 'right', fontSize: '3.0vw', color: 'white'}}>Become
                    a <br/> fluidmeet Partner</h1>
                  <h4 className=""
                      style={{textAlign: 'left', fontSize: '2.00vw', fontWeight: 400, color: 'white'}}>List Your
                    Venues & Spaces</h4>
                  
                  <div style={{marginTop: '2.20vw'}}>
                    <Link to="/host-application">
                      <button className="section-btn shadow" style={{
                        fontSize: '1.6vw',
                        fontWeight: 500,
                        borderRadius: '3vw',
                        backgroundColor: '#fff',
                        color: '#0e7bc7',
                        padding: '.75vw 1vw',
      
                      }}>List My Spaces
                      </button>
                    </Link>
                  </div>
                </div>
              
              </div>
          )
        }
        
        
        <div className="host-banner-bottom">
          <section className="how-works pt-4 pb-5">
            <div className="row mt-4">
              
              <div className="col-12">
                <div className="col-md-8 col-12 center">
                  <div className="how-div">
                    <h2 className="cardd-title text-center">Fluidmeet Marketplace</h2>
                    <span className="cardd-subtitle text-center">Fluidmeet is an online marketplace for work, meeting & event spaces. Our aim is to help our partner venues get connected to our clients asap, to interact/ transact online saving them time & generating positive ROI.
                    </span>
                  </div>
                </div>
              </div>

            </div>
          </section>
        </div>

        <div className="container-fluid container bs-page">

          <section className="how-works">
            <div className="row mt-2">
  
              <div className="col-12">
                <div className="col-md-8 col-12 center">
                  <div className="whydiv">
                    <h2 className="cardd-title mb-lg-5 mb-3 text-center">Why become a fluidmeet Partner?</h2>
                  </div>
                </div>
              </div>
              {
    
                WHY_BECOME_PARTNER.map(
                    ({title, subtitle}) =>
                        <div className="col-md-4 col-6">
                          <div className="how-div">
                            <h3 className="cardd-title">{title}</h3>
                            <p className="cardd-subtitle">{subtitle}</p>
                          </div>
                        </div>
                )
              }
  
  
              <div className="col-12">
                <div className="col-md-8 col-12 center pt-4">
                  <div className="whydiv">
                    <div className="cardd-subtitle mt-5 mb-lg-3 mb-2 text-center">
                      Let us help you maximize footfall and revenues, tap on "List My Space" and get connected to
                      fluidmeet now!
                    </div>
                    <Link to="/host-application">
                      <div className="btn-contain" style={{marginTop: 10, marginBottom: 30}}>
                        <button className="btn section-btn lg-btn center">List My Spaces</button>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

            </div>
          </section>


          <div className="section-divider2" />


          <section className="mt-100">
  
            <h2 className="cardd-title mb-lg-5 mb-3 text-center">Space Types Listed On fluidmeet</h2>
  
            <div className="row pb-50">
              {
                SPACE_TYPES.map(
                    ({image, title, subtitle, alt}) =>
                        <div className="col-lg-6 col-12 ">
                          <Link to="/search/nearby/office-spaces">
                            <div className="cardd-div height-auto display-inline">
                              <img className="cardd-img" src={image} alt={alt}/>
                            </div>
                          </Link>
                          <div className="cardd-desc mb-lg-4 mb-3">
                            <div className="cardd-cont m-0 pt-2">
                              <h2 className="cardd-title">{title}</h2>
                              <p className="cardd-subtitle">{subtitle}</p>
                            </div>
                          </div>
                        </div>
                )
              }
  
  
            </div>

          </section>


          <section className="host">
            <div className="row">
              <div className="about-div w100">
                <h2 className="cardd-title text-center">Ready To Get Your Space Listed?</h2>
                <Link to="/host-application">
                  <div className="btn-contain" style={{marginTop: 30, marginBottom: 30}}>
                    <button className="btn section-btn lg-btn center">List My Spaces</button>
                  </div>
                </Link>
              </div>
            </div>
          </section>

        </div>
      </>
    );
  
}
