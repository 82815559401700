import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import SearchApi from './SearchApi';
import Cities from './organisms/Cities';
import office from '../img/Office_Spaces_and_serviced_offices.jpg';
import coWorking from '../img/Coworking_spaces.jpg';
import meeting from '../img/Meeting_rooms.jpg';
import events from '../img/Banquet_and_event_halls.jpg';

import filterIcon from '../img/space_filter.png';
import readIcon from '../img/spaces_details.png';
import bookIcon from '../img/space_booking.png';
import enjoyIcon from '../img/booking-success.png';

import ahmedImg from '../img/ahmed.jpg';
import rafiImg from '../img/rafi.png';
import amarImg from '../img/amar.jpg';
import samerImg from '../img/samer.jpg';
import carlosImg from '../img/carlos.jpg';
import usedbyLong from '../img/usedby-long.png';
import EntrepreneurMiddleEast from '../img/featured-in-images/entrepreneur-middle-east.png';
import HotelsCateringNewsMiddleEast from '../img/featured-in-images/hotels-catering-news-middle-east.png';
import StarterStory from '../img/featured-in-images/starter-story.png';
import statrtupInfo from '../img/featured-in-images/startup-info.png';
import trainingLogo from '../img/featured-in-images/training-logo.png';
import WamdaLogo from '../img/featured-in-images/wamda-logo.png';
import featured_in from '../img/featured-in-images/featured-in.png';
import {getCurrency, sendEvent} from '../Helpers';
import Footer from "./organisms/Footer";
import {connect} from "react-redux";
import Helmet from "react-helmet";


export const HEADERS = {
  H1: [
    <>Find Your<br/>Space</>
  ],
  h2: [
    "Browse Spaces in A-list Cities",
    "What Space Are You Looking For?",
    "Book Your Space Online in 3 Fluid Steps",
    "Spaces Featured on Fluidmeet",
    "Become a Fluidmeet Partner",
    "Our Clients Trust Us",
    "Featured In",
  ],
}

export const SPACE_TYPES = [
  {
    id: 'office-spaces',
    name: 'Office spaces',
    image: office,
    title: 'Office spaces',
    alt: "Serviced offices rental near you"
  },
  {
    id: 'banquets-and-event-spaces',
    name: 'Banquets halls and event spaces',
    image: events,
    title: 'Banquets & event halls',
    alt: "Banquet & event halls rental near you"
  },
  {
    id: 'co-working-spaces',
    name: 'Co-working spaces',
    image: coWorking,
    title: 'Co-working spaces',
    alt: "Coworking spaces booking near you"
  },
  {
    id: 'meeting-rooms',
    name: 'Meeting rooms',
    image: meeting,
    title: 'Meeting rooms',
    alt: "Meeting rooms booking near you"
  },
]
let GET_STARTED_STEPS = [
  {
    image: filterIcon,
    alt: "Filter your space",
    title: "Filter down to the perfect fit",
    subtitle: "Personalize your search with filters such as price range to get exactly what you want"
  },
  {
    image: readIcon,
    alt: "Check spaces photos and details",
    title: "Read more about the details",
    subtitle: "Check out photos and read guest reviews from past reservations to learn what it is like in person"
  },
  {
    image: bookIcon,
    alt: "Book your space",
    title: "Book with peace of mind",
    subtitle: "Book your chosen space and do not worry we keep your personal info safe"
  },
  {
    image: enjoyIcon,
    alt: "Enjoy your event or work space",
    title: "Enjoy your lovely space",
    subtitle: "Enjoy your workspace! And if you need assistance or feedback, you can contact us directly at info@fluidmeet.com"
  },
]

let FEATURED_IN_IMAGES_1 = [
  {
    image: HotelsCateringNewsMiddleEast,
    alt: "Hotels Catering News Middle East",
  }
]

let FEATURED_IN_IMAGES_2 = [  {
    image: statrtupInfo,
    alt: "Statrtup Info",
  }
]

let FEATURED_IN_IMAGES_3 = [  {
    image: EntrepreneurMiddleEast,
    alt: "Entrepreneur Middle East",
  }
]

let FEATURED_IN_IMAGES_4 = [
  {
    image: WamdaLogo,
    alt: "Wamda Logo",
  }
]

let FEATURED_IN_IMAGES_5 = [  {
    image: trainingLogo,
    alt: "Training Logo",
  }  
]

let FEATURED_IN_IMAGES_6 = [
  {
    image: StarterStory,
    alt: "Starter Story",
  }
]

class Home extends Component {
  
  componentDidMount() {
    //ReactGA.pageview(window.location.pathname + window.location.search);
  }
  
  handleExplore = () => {
    console.log("Test")
    sendEvent('Home', 'Explore', 'Click - Explore button');
    // this.props.history.push('/search/nearby');
  }

  handleCity = city => {
    sendEvent('Home', 'Search City', 'Click - '+city.name);
    // this.props.history.push('/search/'+city.id);
  }

  handleSpaceType = (id, name) => {
    sendEvent('Home', 'Space Type', 'Click - '+name);
    // this.props.history.push('/search/nearby/'+id);
  }

  handleFeatured = id => {
    sendEvent('Home', 'Featured Space', 'Click - Space '+id);
    // this.props.history.push('/space/'+id);
  }

  handleBecomeHost = () => {
    sendEvent('Home', 'Become a host', 'Click - List your space button');
    // this.props.history.push('/host-application');
  }

  render() {

    const { defCurrency } = this.props;
    const currency = getCurrency(defCurrency);

    return (
        <>
          <Helmet>
            <meta charSet="utf-8"/>
            <title>Fluidmeet | Serviced Office & Coworking spaces |Banquet Halls & Events Spaces | Meeting Rooms</title>
            <meta name={'description'}
                  content={'Fluidmeet is an online marketplace and real-time platform that provides a simple and easy booking experience. Fluidmeet offers flexible solutions, whether your business needs global scale, event spaces or office spaces near you!'}/>
            <meta name="keywords"
                  content="Flex spaces, serviced offices, meeting rooms, corporate events venues, banquet halls, event spaces, coworking spaces, workspaces, shared offices, business setup, company setup, freezone, mainland, business centers, business hubs"/>
            <link rel={"canonical"} href="https://www.fluidmeet.com/" />
          </Helmet>
      
          {/* <div className="banner-img">
          <video controls autoplay>
            <source src={process.env.PUBLIC_URL + "/video/1.mp4"} type="video/mp4" />
          </video>
        </div> */}
      
          <div className="banner-img">
            <span role={"img"}
                  aria-label={"Book or rent your serviced office, coworking space, meeting room or banquet & event hall"}/>
            <div className="heading-title">
              <h1 className="heading">{HEADERS.H1[0]}</h1>
              <a href={'/search/nearby'} onClickCapture={this.handleExplore}>
                <button className="btn btn-header">Explore</button>
              </a>
            </div>

          <div className="bg-over"></div>

        </div>


        <div className="container-fluid">

          <section className="used contain">
            <div className="row">
              <img className="img-responsive used-by" alt='used-by-img' src={usedbyLong} />
            </div>
          </section>


          <section className="cities contain">
            <h2 className="section-title">{HEADERS.h2[0]}</h2>
            <div className="row mrl-5"> {/* blue-contain */}
              <Cities handleCity={this.handleCity} allowLink/>
            </div>
          </section>

          <section className="types contain">
            <h2 className="section-title">{HEADERS.h2[1]}</h2>
            <div className="row mrl-5">
    
              {
                SPACE_TYPES.map(
                    ({id, name, image, title, alt}) =>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 p-2">
                          <a href={'/search/nearby/' + id}>
                            <div className="cardd-div" onClick={() => this.handleSpaceType(id, name)}>
                              <img className="cardd-img" src={image} alt={alt}/>
                              <span className="cardd-title">{title}</span>
                            </div>
                          </a>
                        </div>
                )
              }
  
            </div>
          </section>


          <section className="contain">
            <div className="section-divider" />
          </section>


          <section className="how-works contain">
            <h2 className="section-title">{HEADERS.h2[2]}</h2>
            <div className="row mrl-5 mt-5">
              {
                GET_STARTED_STEPS.map(
                    ({title, subtitle, image, alt}) => (
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 p-2">
                          <div className="how-div">
                            <img className="how-img" src={image} alt={alt}/>
                            <span className="cardd-title">{title}</span>
                            <span className="cardd-subtitle">{subtitle}</span>
                          </div>
                        </div>
                    )
                )
              }
  
  
            </div>
          </section>

          {/*<section className="host">*/}
          {/*  <div className="row">*/}
          {/*  <div className="blue-contain" style={{backgroundColor: '#117090'}}>*/}
          {/*    <h1 className="section-title text-center white-text large-title">Starting a business?</h1>*/}
          {/*    <h4 className="section-subtitle text-center white-text">Explore a selection of creative business setup service providers.</h4>*/}
          {/*    <h4 className="section-subtitle text-center white-text">Lots of licensing options available.</h4>*/}
          {/*    <Link to="search/Dubai/business-setup">*/}
          {/*      <div className="btn-contain btn-l-m">*/}
          {/*        <button className="btn section-btn btn-light center">Explore</button>*/}
          {/*      </div>*/}
          {/*    </Link>*/}
          {/*  </div>*/}
          {/*  </div>*/}
          {/*</section>*/}

          <section className="popular contain">
            <h2 className="section-title">{HEADERS.h2[3]}</h2>

            <div className="row mrl-5 mt-5">
  
              <SearchApi key='featured' isMapOpen={false} location={this.props.location} pathname='home' search=''
                         currency={currency} params={{}} isMobileView={window.innerWidth < 768}
                         handleSpace={this.handleFeatured} allowSpaceLink={true}/>

            </div>
          </section>

          <section className="host">
            <div className="row">
              <div className="blue-contain">
                <h2 className="section-title text-center">{HEADERS.h2[4]}</h2>
                <h4 className="section-subtitle text-center">Make the most of your space with Fluidmeet management
                  tools.</h4>
                <h4 className="section-subtitle text-center">Stay organized, manage leads, and track your earnings - all
                  in one place.</h4>
                <div className="btn-contain">
                  <a className="btn section-btn center" href={'/list-my-space'} onClick={this.handleBecomeHost}>
                    List My Spaces
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section className="used contain">
          <h2 className="section-title text-center">{HEADERS.h2[6]}</h2>
            <center><div>                      
                        {FEATURED_IN_IMAGES_1.map(
                                ({image, alt}) => (
                                  <a href='https://www.hotelnewsme.com/videos/atm-2022-jareer-oweimrin-co-founder-fluidmeet/' target="_blank"><img className="featured-in-home-section-imgs" src={image} alt={alt}/></a>
                                )
                        )}                      
                        {FEATURED_IN_IMAGES_2.map(
                          ({image, alt}) => (
                            <img className="featured-in-home-section-imgs" src={image} alt={alt}/>
                          )
                        )}                      
                            {FEATURED_IN_IMAGES_3.map(
                              ({image, alt}) => (
                                  <a href='https://www.entrepreneur.com/article/338743' target="_blank"><img className="featured-in-home-section-imgs" src={image} alt={alt}/></a>
                              
                              )
                            )}  
                        {FEATURED_IN_IMAGES_4.map(
                            ({image, alt}) => (
                              <img className="featured-in-home-section-imgs" src={image} alt={alt}/>
                            )
                        )}
                        {FEATURED_IN_IMAGES_5.map(
                            ({image, alt}) => (
                              <img className="featured-in-home-section-imgs" src={image} alt={alt}/>
                            )
                        )}
                        {FEATURED_IN_IMAGES_6.map(
                            ({image, alt}) => (
                              <img className="featured-in-home-section-imgs" src={image} alt={alt}/>
                            )
                        )}
            
            </div></center>
          </section>
          <section className="how-works contain">
            <div className="container-fluid">
            <div className="row mt-5">
  
              <h2 className="section-title text-center mb-5" style={{width: '100%'}}>{HEADERS.h2[5]}</h2>

              <Carousel
                showArrows={false}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                autoPlay={true}
                interval={5000}
              >
                <div>
                  <div className="myCarousel">
                  <img className="how-img" src={ahmedImg} alt='how-img' />
                    <h3>Ahmed Al Akber</h3>
                    <h4>Consultant</h4>
                    <p>For us consultants, the last thing we need is a convoluted process for booking a training room. With fluidmeet, the process was simple. Search, select and pay. It couldn't get any easier.</p>
                  </div>
                </div>

                <div>
                  <div className="myCarousel">
                  <img className="how-img" src={carlosImg} alt='how-img' />
                    <h3>Carlos Guevara</h3>
                    <h4>Entrepreneur</h4>
                    <p>Whole experience was good and professional. Would book here again.</p>
                  </div>
                </div>

                <div>
                  <div className="myCarousel">
                  <img className="how-img" src={amarImg} alt='how-img' />
                    <h3>Ammar Zawaideh</h3>
                    <h4>Entrepreneur</h4>
                    <p>As an entrepreneur, it's important that I keep my costs low. One of the higher expenditures is on where to work. With fluidmeet, I have been able to find options that are cost effective and very suitable.</p>
                  </div>
                </div>

                <div>
                  <div className="myCarousel">
                  <img className="how-img" src={samerImg} alt='how-img' />
                    <h3>Samer Abdin</h3>
                    <h4>Entrepreneur</h4>
                    <p>Perfect meeting room, fit my requirements and easy booking process.</p>
                  </div>
                </div>

                <div>
                  <div className="myCarousel">
                  <img className="how-img" src={rafiImg} alt='how-img' />
                    <h3>Rafi Jaffari</h3>
                    <h4>Business owner</h4>
                    <p>Fluidmeet has plenty of choices for workspaces. I appreciate this level of workspace variety. This makes sense to a business owner like me, especially as I'm meeting my prospective clients or suppliers.</p>
                  </div>
                </div>

              </Carousel>

            </div>
            </div>
          </section>

        </div>

        <Footer />
      </>
    );
  }
}

export default connect((state, props) => ({
  defCurrency: state.settings.defCurrency,
}), null)(withRouter(Home));
