import React, {ChangeEvent} from 'react';
import {
    createAlert,
    createAlertFromMessage,
    E164FormatNumber,
    formValidation,
    getCurrencies,
    getCurrency,
    getUser,
    setCurrency,
    setUserData
} from "src/Helpers";
import {Link, Redirect} from "react-router-dom";
import profileImg from "src/img/profile.jpg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch, faTimes} from "@fortawesome/free-solid-svg-icons";
import {CountryDropdown, RegionDropdown} from 'react-country-region-selector';
import {Message} from 'src/APIs/HostAPI';
import UserAPI, {UserData} from "src/APIs/UserAPI";
import {connect, ConnectedProps} from "react-redux";
import {updateDefCurrency} from "src/redux/SettingsSlice";
import {FMPhoneInput} from "src/components/host/forms/fields";

const connector = connect(
    (state, props: OwnProps) => ({
        // @ts-ignore
        defCurrency: state.settings.defCurrency,
    }),
    {updateDefCurrency}
);

type PropsFromRedux = ConnectedProps<typeof connector>

export type ManageProfilePages = 'profile' | 'security'

export interface OwnProps {
    page: ManageProfilePages,
}

export type ManageProfileProps = OwnProps & PropsFromRedux


const _defaultProps = {
    page: 'profile',
};

export type ManageProfileState = {
    selectedCountry: string;
    selectedCity: string;
    uploadingFile: boolean;
    selectedFile?: any;
    reqAlert?: any;
    reqLoading: boolean;
    fNameError?: any;
    lNameError?: any;
    phoneError?: any;
    emailError?: any;
    countryError?: any;
    cityError?: any;
    companyError?: any;
    positionError?: any;
    oldPassError?: any;
    newPassError?: any;
    confirmPassError?: any;
    passError?: any;
    showCurrency: boolean;
}


export class ManageProfile extends React.Component<ManageProfileProps, ManageProfileState> {
    // static defaultProps = _defaultProps;
    static key = 'manage-profile'
    static route = `/${ManageProfile.key}`

    constructor(props: ManageProfileProps) {
        super(props);

        this.state = {
            selectedCountry: '',
            selectedCity: '',
            uploadingFile: false,
            reqLoading: false,
            showCurrency:false,
        }
    }

    componentDidMount() {
        const user = getUser();
        this.setState({selectedCountry: user?.country, selectedCity: user?.city});
        //ReactGA.pageview(window.location.pathname + window.location.search);
    }

    handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {

        this.setState({uploadingFile: true});

        const selectedFile = e.target.files?.[0];
        if (selectedFile)
            UserAPI.uploadProfileImage(selectedFile).then(message => {
                this.setState({uploadingFile: false});
                createAlertFromMessage(this, message);
            })
    }

    handleUpdateProfile = (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.target);
        const fNameError = formValidation('fname', data.get("fname"));
        const lNameError = formValidation('lname', data.get("lname"));
        const phoneError = formValidation('phone', data.get("phone"), false);
        const emailError = formValidation('email', data.get("email"));
        const countryError = formValidation('country', this.state.selectedCountry, false);
        const cityError = formValidation('city', this.state.selectedCity, false);
        const companyError = formValidation('company', data.get("company"), false);
        const positionError = formValidation('position', data.get("position"), false);

        this.setState({
            reqLoading: true,
            fNameError,
            lNameError,
            phoneError,
            emailError,
            countryError,
            cityError,
            companyError,
            positionError
        });

        if (fNameError || lNameError || phoneError || emailError || countryError || cityError || companyError || positionError) {
            this.setState({reqLoading: false,});
            return;
        }


        const userData: UserData = {
            first_name: data.get("fname")?.toString(),
            last_name: data.get("lname")?.toString(),
            phone: E164FormatNumber(data.get("phone")?.toString()),
            email: data.get("email")?.toString(),
            country: this.state.selectedCountry,
            city: this.state.selectedCity,
            company: data.get("company")?.toString(),
            position: data.get("position")?.toString(),
        }

        UserAPI.updateProfile(userData)
            .then(message => {
                setUserData(userData);
                createAlertFromMessage(this, message);
            })
            .then(() => {
                this.setState({reqLoading: false,});
            });
    }

    handleUpdatePass = (e: any) => {
        e.preventDefault();

        const data = new FormData(e.target);
        const oldPassError = formValidation('password', data.get("password"));
        const newPassError = formValidation('password', data.get("newPass"));
        const confirmPassError = formValidation('confirmpass', data.get("confirmpass"));
        const passMatchError = data.get("newPass") === data.get("confirmpass") ? null : 'Passwords do not match!';

        this.setState({
            reqLoading: true,
            reqAlert: null,
            oldPassError: oldPassError,
            newPassError: newPassError,
            confirmPassError: ((confirmPassError) ? confirmPassError : passMatchError)
        });

        if (oldPassError || newPassError || confirmPassError || passMatchError) {
            this.setState({reqLoading: false,});
            return;
        }

        UserAPI.updatePass(data.get("password") as string, data.get("newPass") as string)
            .then(
                (message: Message) => {
                    createAlert(this, message.type, message.title, message.message);
                }
            ).then(
            () => {
                this.setState({reqLoading: false});
                let passDiv = document.getElementById('password') as HTMLInputElement;
                let newPassDiv = document.getElementById('newPass') as HTMLInputElement;
                let conPassDiv = document.getElementById('confirmpass') as HTMLInputElement;
                if (passDiv) passDiv.value = '';
                if (newPassDiv) newPassDiv.value = '';
                if (conPassDiv) conPassDiv.value = '';
            }
        );
    }

    handleCurrencyUpdate = (currency:  any) => {

        const { updateDefCurrency } = this.props;

        setCurrency(currency.name);
        updateDefCurrency(currency.name);
        this.toggleShowCurrency();
    }
    toggleShowCurrency = (showCurrency = false) => {

        if (showCurrency) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }
        this.setState({ showCurrency });
    }

    render() {

        const {reqAlert} = this.state;
        const {page = 'profile'} = this.props;
        const user = getUser();

        if (!user) return (<Redirect to='/error'/>);

        return (
            <div className="container bg-white mt-40 pr-0 pl-0">
                <div className="page">

                    {this.getHeaderComponent()}

                    <div className="tab-content">
                        {this.getProfileComponent()}
                        {this.getSecurityComponent()}

                    </div>

                    {(reqAlert) ? (
                        <div id="alerts">
                            <div
                                className={"alert alert-" + reqAlert.type + " alert-dismissible floating-alert fade show"}>
                                <strong>{reqAlert.title}</strong> {reqAlert.message}
                                <button type="button" className="btn-close"
                                        onClick={() => this.setState({reqAlert: null})}>
                                    <FontAwesomeIcon icon={faTimes}/>
                                </button>
                            </div>
                        </div>
                    ) : (<></>)}

                </div>
            </div>
        );
    }

    getHeaderComponent = () => {
        const {page = 'profile'} = this.props;
        return (
            <>
                <h1 className="page-lg-title mt-4 mb-5">My Account</h1>
                <div className="nav nav-tabs fz-17 page-nav mb-3">
                    <div className="nav-item">
                        <Link id="profile-tab" className={"nav-link " + ((page === 'profile') ? "active" : "")}
                              to='/host-dashboard/manage-profile/profile'>Profile</Link>
                    </div>
                    <div className="nav-item">
                        <Link id="login-tab" className={"nav-link " + ((page === 'security') ? "active" : "")}
                              to='/host-dashboard/manage-profile/security'>Login & Security</Link>
                    </div>
                </div>
            </>
        )
    }
    getProfileComponent = () => {
        const {reqLoading, selectedCountry, selectedCity, uploadingFile, showCurrency} = this.state;
        const {page = 'profile'} = this.props;
        const user = getUser()!;
        const currency = getCurrency();
        return (
            <div id="profile" className={"tab-pane fade " + ((page == 'profile') ? "show active" : "")}>

                <div className="row m-0">

                    <div className="profile-img">
                        {/*{(uploadingFile) ? (*/}
                        {/*  <div className="loadingbtn">Uploading...</div>*/}
                        {/*) : (*/}
                        {/*  <>*/}
                        <div className="row m-0">
                            <img
                                src={(user.image) ? (user.image.startsWith('http') ? user.image : "https://fluidmeet.s3.amazonaws.com/venue_logos/" + user.image) : profileImg}/>
                        </div>
                        <div className="btn-div">
                            <input type="file" id="myFile" name="myFile" onChange={this.handleFileUpload}
                                   style={{display: 'none'}}/>
                            <button className={"custom-btn clear-btn text-center" + ((uploadingFile) ? 'ld-btn' : '')}
                                    type="button" onClick={() => document.getElementById('myFile')?.click()}>
                                {(uploadingFile) ? (<><FontAwesomeIcon icon={faCircleNotch}
                                                                       spin={true}/> loading</>) : 'Change Picture'}
                            </button>
                        </div>
                        {/*</>*/}
                        {/*)}*/}
                    </div>

                    <div className="profile-data">

                        <div className="form-general p-0">
                            <form className="row mr-0 ml-0 mt-5" autoComplete="on" onSubmit={this.handleUpdateProfile}>

                                <div className="col-6 pl-0 pl-mobile-0 pr-mobile-1">
                                    <input type="name" id="fname" name="fname" className="form-control"
                                           placeholder="First name" defaultValue={user.first_name} required/>
                                    {(this.state.fNameError) ? (
                                        <div className="form-error">{this.state.fNameError}</div>) : (<></>)}
                                </div>
                                <div className="col-6 pr-0 pl-mobile-1 pr-mobile-0">
                                    <input type="name" id="lname" name="lname" className="form-control"
                                           placeholder="Last name" defaultValue={user.last_name} required/>
                                    {(this.state.lNameError) ? (
                                        <div className="form-error">{this.state.lNameError}</div>) : (<></>)}
                                </div>
                                <FMPhoneInput type={'tel'} id="phone" placeholder="Phone number" value={user.phone}
                                              error={this.state.phoneError}
                                              extraClassNames={"col-sm-6 pl-0 pr-mobile-0 pl-mobile-0"}/>
                                <div className="col-sm-6 col-12 pr-0 pr-mobile-0 pl-mobile-0">
                                    <input type="email" id="email" name="email" className="inputEmail form-control"
                                           placeholder="Email address" defaultValue={user.email} required/>
                                    {(this.state.emailError) ? (
                                        <div className="form-error">{this.state.emailError}</div>) : (<></>)}
                                </div>
                                <div className="col-sm-6 col-12 pl-0 pr-mobile-0 pl-mobile-0">
                                    <div className="selector">
                                        <CountryDropdown classes="select" value={selectedCountry}
                                                         onChange={(val) => this.setState({selectedCountry: val})}/>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12 pr-0 pr-mobile-0 pl-mobile-0">
                                    <div className="selector">
                                        <RegionDropdown classes="select" disableWhenEmpty={true}
                                                        country={selectedCountry} value={selectedCity}
                                                        onChange={(val) => this.setState({selectedCity: val})}/>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12 pl-0 pr-mobile-0 pl-mobile-0">
                                    <input type="text" id="company" name="company" className="form-control"
                                           placeholder="Company" defaultValue={user.company}/>
                                    {(this.state.companyError) ? (
                                        <div className="form-error">{this.state.companyError}</div>) : (<></>)}
                                </div>
                                <div className="col-sm-6 col-12 pr-0 pr-mobile-0 pl-mobile-0">
                                    <input type="text" id="position" name="position" className="form-control"
                                           placeholder="Position" defaultValue={user.position}/>
                                    {(this.state.positionError) ? (
                                        <div className="form-error">{this.state.positionError}</div>) : (<></>)}
                                </div>
                                <div className="col-sm-6 col-12 pl-0 pr-mobile-0 pl-mobile-0">
                                    <input type="button" id="company" name="company" className="form-control"
                                           placeholder="Currency" defaultValue={`${currency.icon} ${currency.name}`} onClick={() => this.toggleShowCurrency(true)}/>
                                </div>
                                <div className="col-12 mt-3 mb-4 pr-0 pl-0">
                                    <button
                                        className={"btn btn-lg btn-primary btn-block fl_btn save-btn align-start " + ((reqLoading) ? 'ld-btn' : '')}>
                                        {(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch}
                                                                            spin={true}/> loading</>) : 'Save'}
                                    </button>
                                </div>


                            </form>
                        </div>
                    </div>

                </div>

                {/*region Dialog*/}
                <div className={"more-filter fz-17 justify-content-center " + ((showCurrency) ? "show-flex" : "")}>
                    <div  className="more-box align-self-center space-page-box">
                        <div className="more-header">
                            <h5 className="center pr-2 pl-2">Currency</h5>
                            <div className="dropdown-divider mt-4 mb-0" />
                        </div>
                        <div className="more-contain space-page-box-contain">
                            <div className="form-general p-0">
                                <div className="row mt-3 mb-4">
                                    {getCurrencies().map((item:any) => (
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <div className={item.name === currency.name ? "currency-item-active" : "currency-item"} onClick={() => this.handleCurrencyUpdate(item)}>
                                                <div className="currency-title">{item.country}</div>
                                                <div className="currency-subtitle">{item.name} - {item.icon}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="more-bottom">
                            <div className="dropdown-divider mb-3 mt-0" />
                            <button className="cancelBtn mr-3" type="button" onClick={() => this.toggleShowCurrency()}>Close</button>
                        </div>
                    </div>
                </div>
                {/*endregion Dialog*/}

            </div>
        )
    }
    getSecurityComponent = () => {
        const {reqLoading} = this.state;
        const {page = 'profile'} = this.props;
        const user = getUser();
        return (
            <div id="login" className={"tab-pane fade " + ((page == 'security') ? "show active" : "")}>

                <div className="row m-0">

                    <div className="login-data">
                        <div className="form-general p-0">
                            <form className="row mr-0 ml-0 mt-5" autoComplete="on" onSubmit={this.handleUpdatePass}>

                                <div className="col-sm-6 col-12 pl-0 pr-mobile-0 pl-mobile-0">
                                    <input type="password" id="password" name="password" className="form-control"
                                           placeholder="Old Password" required/>
                                    {(this.state.oldPassError) ? (
                                        <div className="form-error">{this.state.oldPassError}</div>) : (<></>)}
                                </div>
                                <div className="col-6 pr-0 mobile-hide"/>
                                <div className="col-sm-6 col-12 pl-0 pr-mobile-0 pl-mobile-0">
                                    <input type="password" id="newPass" name="newPass" className="form-control"
                                           placeholder="New Password" required/>
                                    {(this.state.passError) ? (
                                        <div className="form-error">{this.state.passError}</div>) : (<></>)}
                                </div>
                                <div className="col-sm-6 col-12 pr-0 pr-mobile-0 pl-mobile-0">
                                    <input type="password" id="confirmpass" name="confirmpass"
                                           className="inputPassword form-control" placeholder="Confirm New Password"
                                           required/>
                                    {(this.state.confirmPassError) ? (
                                        <div className="form-error">{this.state.confirmPassError}</div>) : (<></>)}
                                </div>
                                <div className="col-12 mt-3 mb-4 pr-0 pl-0">
                                    <button
                                        className={"btn btn-lg btn-primary btn-block fl_btn save-btn " + ((reqLoading) ? 'ld-btn' : '')}>
                                        {(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch}
                                                                            spin={true}/> loading</>) : 'Update'}
                                    </button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export const ManageProfileEnhanced = connector(ManageProfile);
