import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {TransitionProps} from '@material-ui/core/transitions';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement<any, any> },
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});
export type AlertDialogCallback = (dialog:AlertDialog)=>void;

export type AlertDialogExtraAction ={
	callback:AlertDialogCallback,
	text:string,
};

export type AlertDialogProps = {
	onCancelClicked: AlertDialogCallback,
	onAgreeClicked: AlertDialogCallback,
	agreeText: string, cancelText: string,title:string,  message: string,
	autoClose: boolean,
}
export type AlertDialogData = {
	onCancelClicked?: AlertDialogCallback,
	onAgreeClicked?: AlertDialogCallback,
	agreeText?: string,
	cancelText?: string,
	title?:string,
	message?: string,
	autoClose?: boolean,
}

export type AlertDialogState =
	{
		opened: boolean,
		extraAction?: AlertDialogExtraAction,
		fullWidth?: boolean,
		maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
	} & AlertDialogProps

const defaultFunction = (dialog: AlertDialog) => {
	dialog.close()
}

export default class AlertDialog extends React.Component<AlertDialogProps, AlertDialogState> {
	static defaultProps = {
		agreeText: "Agree", cancelText: "Cancel", title: "Do you Agree?", message: '',
		onAgreeClicked: defaultFunction,
		onCancelClicked: defaultFunction,
		autoClose: true
	}


	constructor(props: Readonly<AlertDialogProps> | AlertDialogProps) {
		super(props);
		this.state = {
			opened: false,
			...props,
			fullWidth: true,
			maxWidth: "md",
		}
	}
	open = ()=>{
		this.setState({opened:true})
	}
	close = ()=>{
		this.setState({opened:false})
	}
	setAutoClose = (autoClose:boolean)=>{
		this.setState({autoClose})
	}
	setOnAgreeClickedCallback = (callback: AlertDialogCallback) => {
		this.setState({onAgreeClicked: callback})
	}
	setOnCancelClickedCallback = (callback: AlertDialogCallback) => {
		this.setState({onCancelClicked: callback})
	}
	setAgreeText = (text: string) => {
		this.setState({agreeText: text})
	}

	setCancelText = (text: string) => {
		this.setState({cancelText: text})
	}
	setMessage = (text: string) => {
		this.setState({message: text})
	}
	setTitle = (text: string) => {
		this.setState({title: text})
	}
	setDialogData = (data: AlertDialogData, opened = false) => {
		this.setState({opened, ...AlertDialog.defaultProps, ...this.props, ...data})
	}

	setExtraAction = (data: AlertDialogExtraAction) => {
		this.setState({extraAction: data})
	}
	defaultWarningDialog = (callbacks?: { onAgreeClicked?: AlertDialogCallback, onCancelClicked?: AlertDialogCallback }) => {

		this.setDialogData(
			{
				title: "Are You sure?",
				agreeText: "Yes",
				cancelText: "No",
				onAgreeClicked: callbacks?.onAgreeClicked ?? defaultFunction,
				onCancelClicked: callbacks?.onCancelClicked ?? defaultFunction,
				autoClose: true,
			}, true
		)

	}

	setMaxWidth = (values: { maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false, fullWidth?: boolean }) => {
		this.setState(values)
	}


	render() {
		let {opened, onCancelClicked, onAgreeClicked, agreeText, cancelText, message, title, autoClose, extraAction, maxWidth, fullWidth} = this.state;

		return (
			<Dialog
				open={opened}
				TransitionComponent={Transition}
				keepMounted
				// onClose={onCancelClicked}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
				fullWidth={fullWidth}
				maxWidth={maxWidth}
			>
				<DialogTitle id="alert-dialog-slide-title">
					{title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						{message}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => {
						onCancelClicked(this);
						if (autoClose) this.close()
					}} color="primary">
						{cancelText}
					</Button>
					<Button onClick={() => {
						onAgreeClicked(this);
						if (autoClose) this.close()
					}} color="primary">
						{agreeText}
					</Button>
					{(Boolean(extraAction)) &&
					<Button onClick={() => {
						extraAction!.callback(this);
						if (autoClose) this.close()
					}} color="primary">
						{extraAction!.text}
					</Button>
						}
					</DialogActions>
				</Dialog>
		);
	}
}
