import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faCircleNotch, faTimes} from "@fortawesome/free-solid-svg-icons";
import {createAlert, devLog, formValidation, getApiLinkV2, getToken, getUser} from "../../Helpers";
import axios from "axios";
import {getAxios} from "src/Helpers";
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";
import Bugsnag from "@bugsnag/js";
import ReactGA from "react-ga-neo";
import {FMPhoneInput} from "../host/forms/fields";
import libphonenumber from "google-libphonenumber";

let phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()
let PNF = libphonenumber.PhoneNumberFormat

class HostYourSpace extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			title: '',
			type: '',
			name: '',
			website: '',
			selectedCountry: '',
			selectedCity: '',
			address: '',
			fName: '',
			lName: '',
			email: '',
			phone: '',
			notes: '',
			reqLoading: false,
			checkError: null,
			reqAlert: null,
			typeError: null,
			nameError: null,
			websiteError: null,
			addressError: null,
			fNameError: null,
			lNameError: null,
			emailError: null,
			phoneError: null,
			countryError: null,
			cityError: null,
		}
		
		this.nextBtn = React.createRef();
		this.handleSendReq = this.handleSendReq.bind(this);
		this.checkPendingRequests = this.checkPendingRequests.bind(this);
		this.updateTitle = this.updateTitle.bind(this);
		this.handleBackBtn = this.handleBackBtn.bind(this);
		this.handleNextBtn = this.handleNextBtn.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			this.checkPendingRequests();
			this.updateTitle();
			//ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}
	
	componentDidMount() {
		
		this.checkPendingRequests();
		this.updateTitle();
		//ReactGA.pageview(window.location.pathname + window.location.search);
		
		ReactGA.event({category: 'host', action: 'Host_Attempt', label: 'host Application Attempt'});
		
		this.interval = setInterval(() => {
			const chatFrame = document.querySelector("#fc_frame");
			if (chatFrame) {
				if (window.innerWidth < 992) {
					devLog(window.innerWidth);
					chatFrame.style.bottom = (window.innerWidth < 441) ? '90px' : '110px';
				}
				clearInterval(this.interval);
			}
		}, 1000);
		setTimeout(() => {
			clearInterval(this.interval);
		}, 10000);
		// this.dummy()
	}
	
	dummy() {
		const url = getApiLinkV2('host/host-request');
		let headers = {'Content-Type': 'application/json'};
		
		Array(10).fill(1).forEach(
			(_, i) => {
				setTimeout(() => {
					getAxios().post(url, {
							venue_type_id: i + 1,
							venue_name: `Test ${i}`,
							website: "www.fluidmeet.com",
							name: `Subscription ${i}`,
							last_name: "Test",
							number: '',
							email: `test.nov14.${i.toString().padStart(2, 0)}@fm.com`,
							phone: '01236456789',
							country: "United Arab Emirates",
							city: "Dubai",
							address: "first street",
							message: "",
						}, {
							headers: headers,
						})
						.then(response => createAlert(this, 'success', `Done ${i + 1}`))
						.catch(error => createAlert(this, 'danger', 'Error: ', error.message))
				}, 1500 * (i + 1))
				
			}
		)
	}
	
	checkPendingRequests() {
		
		if (this.props.page !== 'type') return;
		
		const user = getUser();
		const token = getToken();
		if (!token) return;
		
		const url = getApiLinkV2('host/host-request/' + user.id);
		
		getAxios().get(url, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token,
			},
		}).then(response => {
			devLog('checked pending requests');
			this.setState({checkError: null});
		}).catch(error => {
			if (error.response && error.response.status !== 406) {
				Bugsnag.notify(error);
			}
			if (error.response && error.response.data.error) {
				this.setState({checkError: error.response.data.error});
			}
		});
	}
	
	updateTitle() {
		
		const {page} = this.props;
		const {type, name, selectedCountry, selectedCity, address, fName, lName, email, phone} = this.state;
		
		this.setState({checkError: null});
		
		if (email == '' && page == 'success') {
			this.props.history.push('/host-application');
			return;
		}
		
		if (type == '' && (page == 'info' || page == 'location' || page == 'contact' || page == 'notes')) {
			this.props.history.push('/host-application');
			return;
		}
		if (name == '' && (page == 'location' || page == 'contact' || page == 'notes')) {
			this.props.history.push('/host-application/info');
			return;
		}
		if ((selectedCountry == '' || selectedCity == '' || address == '') && (page == 'contact' || page == 'notes')) {
			this.props.history.push('/host-application/location');
			return;
		}
		if ((fName == '' || lName == '' || email == '' || phone == '') && (page == 'notes')) {
			this.props.history.push('/host-application/contact-info');
			return;
		}
		
		switch (page) {
			case 'type':
				this.setState({title: 'What kind of venues are you listing?'});
				break;
			case 'info':
				this.setState({title: 'What’s your venue name?'});
				break;
			case 'location':
				this.setState({title: 'Where’s your venue located?'});
				break;
			case 'contact':
				this.setState({title: 'What’s your venue contact info?'});
				break;
			case 'notes':
				this.setState({title: 'Any notes?'});
				break;
			default:
				return;
		}
	}
	
	handleSendReq(notes) {
		
		this.setState({reqLoading: true,});
		
		const url = getApiLinkV2('host/host-request');
		const token = getToken();
		var headers = {'Content-Type': 'application/json'};
		if (token) {
			headers['Authorization'] = 'Bearer ' + token;
		}
		
		getAxios().post(url, {
			venue_type_id: this.state.type,
			venue_name: this.state.name,
			website: this.state.website,
			name: this.state.fName,
			last_name: this.state.lName,
			number: '',
			email: this.state.email,
			phone: this.state.phone,
			country: this.state.selectedCountry,
			city: this.state.selectedCity,
			address: this.state.address,
			message: notes,
		}, {
			headers: headers,
		}).then(response => {
				ReactGA.event({category: 'host', action: 'Host_Success', label: 'host Application Success'});
				this.setState({
					type: '',
					name: '',
					website: '',
					selectedCountry: '',
					selectedCity: '',
					address: '',
					fName: '',
					lName: '',
					notes: '',
					phone: '',
					reqAlert: null,
					typeError: null,
					nameError: null,
					websiteError: null,
					addressError: null,
					fNameError: null,
					lNameError: null,
					emailError: null,
					phoneError: null,
					countryError: null,
					cityError: null,
				});
				this.props.history.push('/host-application/success');
			})
			.catch(error => {
				Bugsnag.notify(error);
				if (error.response && error.response.data.error) {
					this.setState({checkError: error.response.data.error});
				} else {
					createAlert(this, 'danger', 'Error: ', error.message);
				}
			})
			.then(() => {
				this.setState({reqLoading: false,});
			});
	}
	
	handleBackBtn() {
		
		switch (this.props.page) {
			case 'info':
				this.props.history.push('/host-application');
				return;
			case 'location':
				this.props.history.push('/host-application/info');
				return;
			case 'contact':
				this.props.history.push('/host-application/location');
				return;
			case 'notes':
				this.props.history.push('/host-application/contact-info');
				return;
			default:
				return;
		}
	}
	
	handleNextBtn(e) {
		e.preventDefault();
		
		const data = new FormData(e.target);
		
		switch (this.props.page) {
			
			case 'type':
				
				const typeError = formValidation('type', data.get("type"));
				this.setState({typeError: typeError, type: data.get("type"),});
				if (typeError) return;
				this.props.history.push('/host-application/info');
				return;
			
			case 'info':
				
				const nameError = formValidation('name', data.get("name"));
				const websiteError = formValidation('website', data.get("website"), false);
				this.setState({
					nameError: nameError,
					websiteError: websiteError,
					name: data.get("name"),
					website: data.get("website"),
				});
				if (nameError || websiteError) return;
				this.props.history.push('/host-application/location');
				return;
			
			case 'location':
				
				const countryError = formValidation('country', this.state.selectedCountry);
				const cityError = formValidation('city', this.state.selectedCity);
				const addressError = formValidation('address', data.get("address"))
				this.setState({
					addressError: addressError,
					countryError: countryError,
					cityError: cityError,
					address: data.get("address"),
				});
				if (addressError || countryError || cityError) return;
				this.props.history.push('/host-application/contact-info');
				return;
			
			case 'contact':
				
				const fNameError = formValidation('fname', data.get("fname"));
				const lNameError = formValidation('lname', data.get("lname"));
				const emailError = formValidation('email', data.get("email"));
				let phone = data.get("phone")
				const phoneError = formValidation('phone', phone);
				if (!phoneError) {
					try {
						let number = phoneUtil.parse(phone)
						phone = phoneUtil.format(number, PNF.E164)
					} finally {
					}
				}
				devLog(phone)
				
				this.setState({
					fNameError: fNameError,
					lNameError: lNameError,
					emailError: emailError,
					phoneError: phoneError,
					fName: data.get("fname"),
					lName: data.get("lname"),
					email: data.get("email"),
					phone,
				});
				if (fNameError || lNameError || emailError || phoneError) return;
				this.props.history.push('/host-application/notes');
				return;
			
			case 'notes':
				this.handleSendReq(data.get("notes"));
				return;
			
			default:
				return;
		}
	}
	
	handleChange() {
		
		this.nextBtn.click();
	}
	
	render() {
		
		const {page,} = this.props;
		const {reqLoading, reqAlert, title, checkError} = this.state;
		
		if (checkError) {
			return (
				<div className="container bhost-page">
					<div className="bhost">
						<div className="bhost-cont form-general">
							<h3>Error!</h3>
							<h6 className="mt-4">{checkError}</h6>
						</div>
						<div className="bottom-fixedbar">
							<div className="container bhost-page">
								<div className="bhost">
									<div className="dropdown-divider mr-4 ml-4"/>
									<button className="custom-btn next-btn mt-1" type="button"
									        onClick={() => this.props.history.push('/')}>Done
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
		
		if (page == 'success') {
			return (
				<div className="container bhost-page">
					<div className="bhost">
						<div className="bhost-cont form-general">
							<h6 className="mt-4">Your request has been sent successfully.</h6>
						</div>
						<div className="bottom-fixedbar">
							<div className="container bhost-page">
								<div className="bhost">
									<div className="dropdown-divider mr-4 ml-4"/>
									<button className="custom-btn next-btn mt-1" type="button"
									        onClick={() => this.props.history.push('/')}>Done
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
		
		return (
			<>
				
				<div className="container bhost-page">
					
					<form className="bhost" autoComplete="on" onSubmit={this.handleNextBtn}>
						
						<div className="bhost-cont form-general">
							
							<h3>{title}</h3>
							
							{(page == 'type' && (
								<div className="pt-5 pr-0">
									<h6>Choose a venue type</h6>
									<div className="selector">
										<select name="type" id="type" className="select" defaultValue={this.state.type}
										        onChange={this.handleChange}>
											<option value="">Type</option>
											<option value="1">Hotel 3-Star</option>
											<option value="2">Hotel 4-Star</option>
											<option value="3">Hotel 5-Star</option>
											<option value="4">Events Venue</option>
											<option value="5">Corporate Office</option>
											<option value="6">Training Center</option>
											<option value="7">Business Center/Hub</option>
											<option value="8">Restaurants</option>
											<option value="9">Business Setup</option>
											<option value="10">Office Spaces</option>
										</select>
									</div>
									{(this.state.typeError) ? (
										<div className="form-error">{this.state.typeError}</div>) : (<></>)}
								</div>
							))}
							
							{(page == 'info' && (
								<>
									<div className="pt-5 pl-0">
										<h6>Name</h6>
										<input type="name" id="name" name="name" className="form-control"
										       placeholder="Name" defaultValue={this.state.name} required/>
										{(this.state.nameError) ? (
											<div className="form-error">{this.state.nameError}</div>) : (<></>)}
									</div>
									<div className="pt-3 pl-0">
										<h6>Website</h6>
										<input type="text" id="website" name="website" className="form-control"
										       placeholder="Website" defaultValue={this.state.website}/>
										{(this.state.websiteError) ? (
											<div className="form-error">{this.state.websiteError}</div>) : (<></>)}
									</div>
								</>
							))}
							
							{(page == 'location' && (
								<>
									<div className="pt-5 pr-0">
										<h6>Country</h6>
										<div className="selector">
											<CountryDropdown classes="select" value={this.state.selectedCountry}
											                 onChange={(val) => this.setState({selectedCountry: val})}/>
										</div>
										{(this.state.countryError) ? (
											<div className="form-error">{this.state.countryError}</div>) : (<></>)}
									</div>
									<div className="pt-3 pr-0">
										<h6>City</h6>
										<div className="selector">
											<RegionDropdown classes="select" disableWhenEmpty={true}
											                country={this.state.selectedCountry}
											                value={this.state.selectedCity}
											                onChange={(val) => this.setState({selectedCity: val})}/>
										</div>
										{(this.state.cityError) ? (
											<div className="form-error">{this.state.cityError}</div>) : (<></>)}
									</div>
									<div className="pt-3 pl-0">
										<h6>Address</h6>
										<input type="text" id="address" name="address" className="form-control"
										       placeholder="Address" defaultValue={this.state.address} required/>
										{(this.state.addressError) ? (
											<div className="form-error">{this.state.addressError}</div>) : (<></>)}
									</div>
								</>
							))}
							
							{(page == 'contact' && (
								<>
									<div className="pt-5 pl-0">
										<h6>First name</h6>
										<input type="name" id="fname" name="fname" className="form-control"
										       placeholder="First Name" defaultValue={this.state.fName} required/>
										{(this.state.fNameError) ? (
											<div className="form-error">{this.state.fNameError}</div>) : (<></>)}
									</div>
									<div className="pt-3 pl-0">
										<h6>Last name</h6>
										<input type="name" id="lname" name="lname" className="form-control"
										       placeholder="Last Name" defaultValue={this.state.lName} required/>
										{(this.state.lNameError) ? (
											<div className="form-error">{this.state.lNameError}</div>) : (<></>)}
									</div>
									
									<div className="pt-3 pl-0">
										<h6>Phone number</h6>
										{/*<input type="phone" id="phone" name="phone" className="form-control" placeholder="Phone Number" defaultValue={this.state.phone} required />*/}
										{/*{(this.state.phoneError) ? (<div className="form-error">{this.state.phoneError}</div>) : (<></>)}*/}
										<FMPhoneInput id={'phone'} type={'phone'} error={this.state.phoneError}
										              value={this.state.phone} required={true}
										              extraClassNames={'px-0'}/>
									</div>
									<div className="pt-3 pl-0">
										<h6>Email</h6>
										<input type="email" id="email" name="email" className="form-control"
										       placeholder="Email Address" defaultValue={this.state.email} required/>
										{(this.state.emailError) ? (
											<div className="form-error">{this.state.emailError}</div>) : (<></>)}
									</div>
								</>
							))}
							
							{(page == 'notes' && (
								<>
									<div className="pt-5 pl-0">
										<h6>Notes</h6>
										<textarea id="notes" name="notes" className="form-control" placeholder="Notes"
										          defaultValue={this.state.notes} multiline={true}/>
									</div>
								</>
							))}
						
						</div>
						
						
						<div className="bottom-fixedbar">
							<div className="container bhost-page">
								<div className="bhost">
									
									<div className="dropdown-divider mr-4 ml-4"/>
									
									<div className="mt-1">
										{(page != 'type' && (
											<button className="custom-btn clear-btn" type="button"
											        onClick={this.handleBackBtn}>
												<FontAwesomeIcon icon={faAngleLeft}
												                 style={{width: 24, height: 24, marginBottom: -4}}/>
												<span className="back-btn-span">Back</span>
											</button>
										))}
										<button ref={btn => this.nextBtn = btn}
										        className={"custom-btn next-btn " + ((reqLoading) ? 'ld-btn' : '')}
										        type="submit">
											{(page == 'notes') ? (
												(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch}
												                                   spin={true}/> loading</>) : 'Submit'
											) : (
												"Next"
											)}
										</button>
									</div>
								</div>
							</div>
						</div>
					
					</form>
				
				</div>
				
				{(reqAlert) ? (
					<div id="alerts">
						<div className={"alert alert-" + reqAlert.type + " alert-dismissible floating-alert fade show"}>
							<strong>{reqAlert.title}</strong> {reqAlert.message}
							<button type="button" className="btn-close" onClick={() => this.setState({reqAlert: null})}>
								<FontAwesomeIcon icon={faTimes}/>
							</button>
						</div>
					</div>
				) : (<></>)}
			
			</>
		);
	}
}

export default withRouter(HostYourSpace);
