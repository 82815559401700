import React, {Fragment, ReactElement} from 'react';
import {RouteComponentProps, withRouter} from "react-router-dom";
import {Theme, withStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {DrawerItem, drawerItems} from './drawer-items';
import {ManageHosts, ManageHostsEnhanced} from './manage-hosts/manage-hosts';
import ManageReservations from "./manage-reservations/manage-reservations";
import {ManageListingsEnhanced} from "./manage-listings/manage-listings";
import {ManageProfile, ManageProfileEnhanced, ManageProfilePages} from "./manage-profile/manage-profile";
import {IconButton, Tooltip} from "@material-ui/core";
import clsx from 'clsx';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {Styles} from "@material-ui/styles";
import {HostDashboardRoute} from './consts';
import {HostAPI} from "src/APIs/HostAPI";
import {devLog} from "src/Helpers";
import ContractDetails from "src/components/host/host-dashboard/subscription/contract-details";
import {LoadingComponent} from "src/components/atoms/loading-component";
import moment from "moment";
import { ManageBookingsEnhanced } from './manage-bookings/manage-bookings';

export type HostDashboardRoutePrams = {
    section?: string,
    page?: string,
    subpage?: string,
    subsubpage?: string,
    id?: string,
}

export interface HostDashboardProps extends RouteComponentProps<HostDashboardRoutePrams> {
    classes?:any;
}

export interface HostDashboardState {
    open: boolean;
    isLoading: boolean;
}


export class HostDashboard extends React.Component<HostDashboardProps, HostDashboardState> {
    static routeName = "HostDashboard"
    static route: string = HostDashboardRoute
    static subRoute = `${HostDashboardRoute}/:section?/:page?/:subpage?/:subsubpage?/:id?`
    container = React.createRef<HTMLDivElement>()
    state = {open: false, isLoading: true}

    constructor(props: HostDashboardProps, context: any) {
        super(props, context);
    }

    componentDidMount() {
        HostAPI.fetchSubscription().then((response) => {
            devLog("HostDashboard :: fetchSubscription", {
                response,
                end_date: moment(response?.subscription?.details?.end_date).toDate()
            })

            if (
                (!response?.subscription?.details)
                || (
                    response?.subscription?.details &&
                    (
                        response?.subscription?.details?.contract_status !== "accepted" ||
                        (
                            response?.subscription?.details?.end_date &&
                            moment(response?.subscription?.details?.end_date).isValid() &&
                            moment(response?.subscription?.details?.end_date).toDate() <= new Date()
                        )
                    )
                )
            ) {
                ContractDetails.view(this.props.history)
            }

        }).finally(
            () => {
                this.setState({isLoading: false})
            }
        )
    }
    navigate = (route: string) => {
        // devLog(this.props);
        this.props.history.push(route)
    }

    render() {
        let {open, isLoading} = this.state;
        let {classes, match: {params: {section}}} = this.props;

        // devLog(section);

        // @ts-ignore
        return (
            <div className={`container-fluid bg-white mt-80 pr-0 pl-0`}>

                <div className={`${classes.root} page`} style={{position: 'relative'}} ref={this.container}>
                    <CssBaseline/>

                    {
                        (isLoading) ?
                            <LoadingComponent/> :
                            <Fragment>
                                {this.getDrawer()}

                                <main className={

                                        clsx(
                                            classes.content,
                                            {[classes.contentShift]: open,},
                                            'px-0'
                                        )
                                }
                                      style={{position: 'relative'}}
                                >
                                    {this.getContent(section)}
                                </main>
                            </Fragment>
                    }
            </div>
            </div>
        );
    }
    getDrawer(){
        let {open} = this.state;
        let {classes , match :{ params : {section}}} = this.props;
        // @ts-ignore
        return(
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
                modal: classes.drawerModal
            }}
            container={this.container.current}
        >
            <div className={classes.toolbar}>
                <Tooltip title={(open)?'Collapse':'Expand'} arrow placement={'right'}>
                <IconButton onClick={()=>(this.setState({open:!open}))}>
                    {(open)?<ChevronLeftIcon />:<ChevronRightIcon />}
                </IconButton>
                </Tooltip>
            </div>

            <Divider />
            <List>
                {
                    drawerItems.map(
                        (item: DrawerItem, index) => {
                            if (item.type === 'link') {
                                return (
                                    <Tooltip key={index} title={item.name ?? ''} arrow placement={'right'}>
                                        <ListItem button key={item.key}
                                                  onClick={() => this.navigate(item.route as string)}>
                                            <ListItemIcon>{item.icon}</ListItemIcon>
                                            <ListItemText primary={item.name}/>
                                        </ListItem>
                                    </Tooltip>
                                );
                            } else if (item.type === 'action') {
                                return (
                                    <Tooltip key={index} title={item.name ?? ''} arrow placement={'right'}>
                                        <ListItem button key={item.key}
                                                  onClick={() => item.callback?.({component: this})}>
                                            <ListItemIcon>{item.icon}</ListItemIcon>
                                            <ListItemText primary={item.name}/>
                                        </ListItem>
                                    </Tooltip>
                                );
                            }else if(item.type === 'divider') {
                                return (
                                    <Divider key={index}/>
                                );
                            }else return <></>;
                        }
                    )
                }
            </List>
        </Drawer>
    )
    }
    getContent(section: any) :ReactElement {
        switch (section){
            case ManageHosts.key:
                return <ManageHostsEnhanced/>;
            case ManageProfile.key:
                return <ManageProfileEnhanced page={this.props.match.params.page as ManageProfilePages}/>;
            case 'manage-reservations':
                return <ManageReservations/>;
            case 'manage-bookings':
                return <ManageBookingsEnhanced />;
            case 'manage-listings':
            default:
                return <ManageListingsEnhanced/>;
        }
    }
}


const drawerWidth = '240px';

const styles =(theme: Theme) =>
        (
            {
            root: {
                display: 'flex',
            },
            content: {
                marginLeft: theme.spacing(7) + 1,
                [theme.breakpoints.up('sm')]: {
                    marginLeft: theme.spacing(9) + 1,
                },
                flexGrow: 1,
                padding: theme.spacing(4),
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            },
            contentShift: {
                marginLeft: drawerWidth,
                width: `calc(100% -${drawerWidth} )`,
                transition: theme.transitions.create(['width',"margin"], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
            menuButton: {
                marginRight: 36,
            },
            hide: {
                display: 'none',
            },
            drawer: {
                zIndex:0,
                width: drawerWidth,
                flexShrink: 0,
                whiteSpace: 'nowrap',
                position: "absolute",
                height:'100%'
            },
            drawerModal: {
                position: "absolute",
            },
            drawerOpen: {
                width: drawerWidth,
                position: "absolute",
                height: "100%",
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
            drawerClose: {
                position: "absolute",
                height: "100%",
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                overflowX: 'hidden',
                width: theme.spacing(7) ,
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(7) ,
                },
            },
                toolbar: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    padding: theme.spacing(0, 1),
                    // necessary for content to be below app bar
                    ...theme.mixins.toolbar,
                },
        } as Styles<Theme,any, string>
        )



// @ts-ignore
export const RoutedHostDashboard =  withStyles(styles)(withRouter(HostDashboard));

