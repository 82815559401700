import React, { Component, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { getApiLinkV2, getImageUrl, getSpacePrice, getToken, printPrice, printSpacePricePer } from "../../Helpers";
import axios from "axios";
import {getAxios} from "src/Helpers";
import Bugsnag from "@bugsnag/js";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Card, CardContent, CardMedia, Link, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(
	(theme) => (
		{
			card: {
				height: "25rem",
				overflow: "hidden",
			},
			cardRoot: {
				backgroundColor: 'transparent',
			},
			image:{
				height:"60%",
				[theme.breakpoints.down("sm")]:{
					height:'50%'
				}
			}
		}
	)
)
const onImageLoadError = (event) => {
	let image: HTMLImageElement = event.target;
	image.onerror = null;
	image.parentNode.children[0].srcset = image.parentNode.children[1].srcset = image.src
}
export const SpaceCard = ({ isFav, hideFav, space, term, handleSpace, id, isMapView, isMapOpen, food, isLarge, service, fullWidth, currency, allowLink, redirectScreen, handleRemovedFav }) => {

	const [favourited, setFavourited] = useState((isFav || (!hideFav)) ? space.favourite : undefined)
	const classes = useStyles()
	const handleFavourite = (space) => {

		const token = getToken();
		if (!token) {
			redirectScreen?.();
			return;
		}

		setFavourited((space.favourite === 'no') ? 'yes' : 'no');

		const url = (service === 'service') ? getApiLinkV2('user/favourite-services') : getApiLinkV2('user/favourite-spaces');

		getAxios().post(url, {
			workspace_id: space.workspace_id,
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token,
			},
		}).then(response => {
			if (isFav && handleRemovedFav && !response.data.favourite) {
				handleRemovedFav(space);
			}
			setFavourited((response.data.favourite) ? 'yes' : 'no');
		})
			.catch(error => {
				Bugsnag.notify(error);
				console.error((error.response && error.response.data.error) ? error.response.data.error : error.message);
			});
	}
	const handleSpaceTapped = space => {

		if (handleSpace)
			handleSpace(space.workspace_id);

	}


	return (
		<>
			<Link href={(allowLink) ? ("/space/" + space.workspace_id + (term ? "?t=" + term : "")) : null} >
				<Card className={classes.card} onClick={() => handleSpaceTapped(space)} style={{ borderRadius: '10px', border: 'none', position: 'relative', overflow: 'visible' }} variant="outlined" classes={{
					root: classes.cardRoot
				}}>
					<CardMedia
						component="picture"
						className={classes.image}
					>
						<source media="(max-width:576px)" srcSet={getImageUrl(space.image, '_384.webp')} type={'image/webp'} />
						<source media="(max-width:768px)" srcSet={getImageUrl(space.image, '_384.webp')} type={'image/webp'} />
						<img
							className={'h-100 w-100'}
							style={{ borderRadius: 10,objectFit:'cover' }}
							alt="space"
							src={getImageUrl(space.image)}
							loading={"lazy"}
							onError={onImageLoadError}
						/>
					</CardMedia>
					<CardContent>
						{space.workspace_rating <= 0 ? null : <div>
							<FontAwesomeIcon icon={faStar}
								style={{ color: (space.workspace_rating && space.workspace_rating > 0) ? '#00a9e0' : '#E0E0E0' }} />{" "}
							{(space.workspace_rating && space.workspace_rating > 0) && (
								space.workspace_rating
							)}
							{(space.reviews > 0) &&
								<span>({space.reviews})</span>
							}
						</div>}

						<Typography title={space.front_address}>{space.front_address.length > 60 ? `${space.front_address.slice(0, 60)}...` : space.front_address}</Typography>

						<Typography variant="body1" style={{ fontWeight: 400 }}>{space.name.length > 30 ? `${space.name.slice(0, 30)}...` : space.name}</Typography>

						{!isFav && (
							<span className={"space-price d-flex justify-content-between allign-item-center" + ((isMapView) ? "mb-0 pb-0" : " ")}>
								<span>
									<span className="price">
										{printPrice(getSpacePrice(space, service, term), ((space.currency) ? space.currency : 'USD'), currency.name, true)}
									</span>
									{printSpacePricePer(space, service, term)}
								</span>
								{space.rti_id && 
									<span className="btn btn-sm btn-outline-success">Instant</span>
								}
							</span>
						)}
					</CardContent>

					{(space.featured === 'yes') && (
						<div className="space-featured">
							<div className="trending-img" />
						</div>
					)}

					{(!hideFav && (
						<div className="space-favorite" onClick={(event) => { event.stopPropagation(); handleFavourite(space); }}>
							<div className={(isFav || favourited === 'yes') ? 'favact-img' : 'fav-img'} />
						</div>))}
				</Card>
			</Link>



		</>
	);

}
