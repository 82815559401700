import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	Theme,
} from "@material-ui/core";
import { useCallback, useMemo, useRef } from "react";
import * as yup from "yup";
import "yup-phone";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/styles";
import { FMPhoneInput } from "src/components/host/forms/fields";
import { PhoneNumber } from "google-libphonenumber";
import { getApiLinkV2, getAxios, getUser } from "src/Helpers";
import { useSnackbar } from "src/components/organisms/snackbar";

export type RequestDemoPopupProps = {
	option: "download" | "request-demo";
	open: Boolean;
	handleClose?: Function;
	file?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
	dialogRoot: {
		borderRadius: "1rem",
	},
	inputField: {
		margin: "0px 1rem",
		width: "calc(100% - 2rem)",
		[`& fieldset`]: {
			borderRadius: "1rem",
		},
	},
}));

export const RequestDemoPopup = ({ option, open, handleClose = () => {}, file }: RequestDemoPopupProps) => {
	const fileRef = useRef<HTMLAnchorElement>();
	const requestRef = useRef<AbortController>(new AbortController());
	const classes = useStyles();
	const { showErrorMessage, showSuccessMessage } = useSnackbar();
	const validationSchema = useMemo(
		() =>
			yup.object({
				first_name: yup.string().required().label("First Name"),
				last_name: yup.string().required().label("Last Name"),
				venue_name: yup.string().required().label("Name of Venue"),
				email_address: yup.string().email().required().label("Email"),
				phone_number: yup.string().phone().required().label("Phone Number"),
			}),
		[]
	);

	const initialValues = {
		first_name: "",
		last_name: "",
		venue_name: "",
		email_address: "",
		phone_number: "",
	};
	const onSubmit = useCallback(async (values) => {
		let user = await getUser();
		values.user_id = user?.id;
		console.log({ values });
		try {
			let res = await getAxios().post(getApiLinkV2("seo/demo-request"), {...values,option}, {
				signal: requestRef.current?.signal,
			});
			console.log({ res });
			showSuccessMessage("Submitted Successfully");
			if (option === "download" && file) {
				fileRef.current?.click();
			}
			handleClose();
		} catch (error: any) {
			console.log({ error });
			showErrorMessage("Failed to Submit, Please contact support");
		}
	}, []);
	const handleCancel = () => {
		requestRef.current.abort();
		requestRef.current = new AbortController();
		handleClose();
	};

	const { values, errors, handleBlur, handleChange, handleSubmit, submitCount, isSubmitting } = useFormik({
		initialValues,
		validationSchema,
		onSubmit,
	});

	return (
		<>
			<Dialog
				classes={{
					paper: classes.dialogRoot,
				}}
				open={open}
				onClose={handleClose}
			>
				{file && <a href={file} download className="d-none" ref={fileRef} />}
				<DialogTitle id="form-dialog-title">
					{option === "download" ? "Download Study Case" : "Request Demo"}
				</DialogTitle>
				<DialogContent>
					<TextField
						className={classes.inputField}
						variant="outlined"
						autoFocus
						id="first_name"
						label="First Name"
						type="text"
						required
						value={values["first_name"]}
						onBlur={handleBlur("first_name")}
						onChange={handleChange("first_name")}
						error={Boolean(submitCount > 0 && errors["first_name"])}
						helperText={Boolean(submitCount > 0 && errors["first_name"]) ? errors["first_name"] : " "}
					/>
					<TextField
						className={classes.inputField}
						variant="outlined"
						id="last_name"
						label="Last Name"
						type="text"
						required
						value={values["last_name"]}
						onBlur={handleBlur("last_name")}
						onChange={handleChange("last_name")}
						error={Boolean(submitCount > 0 && errors["last_name"])}
						helperText={Boolean(submitCount > 0 && errors["last_name"]) ? errors["last_name"] : " "}
					/>
					<TextField
						className={classes.inputField}
						variant="outlined"
						id="venue_name"
						label="Name of Venue"
						type="text"
						required
						value={values["venue_name"]}
						onBlur={handleBlur("venue_name")}
						onChange={handleChange("venue_name")}
						error={Boolean(submitCount > 0 && errors["venue_name"])}
						helperText={Boolean(submitCount > 0 && errors["venue_name"]) ? errors["venue_name"] : " "}
					/>
					<TextField
						className={classes.inputField}
						variant="outlined"
						id="email"
						label="Email Address"
						type="email"
						required
						value={values["email_address"]}
						onBlur={handleBlur("email_address")}
						onChange={handleChange("email_address")}
						error={Boolean(submitCount > 0 && errors["email_address"])}
						helperText={Boolean(submitCount > 0 && errors["email_address"]) ? errors["email_address"] : " "}
					/>

					<FMPhoneInput
						type={"tel"}
						id="phone"
						placeholder="Phone number"
						value={values["phone_number"]}
						error={Boolean(submitCount > 0 && errors["phone_number"]) ? errors["phone_number"] : ""}
						onPhoneChange={(value) => handleChange("phone_number")("+" + value)}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCancel} color="primary">
						Cancel
					</Button>
					<Button disabled={isSubmitting} onClick={handleSubmit} color="primary">
						{option === "download" ? "Get My Copy" : "Submit"}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
