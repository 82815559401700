import React, {Component, FormEvent} from 'react';
import {
	createAlert,
	devLog,
	doubleToPercentage,
	formValidation,
	getApiLinkV2,
	getCurrency,
	getLayoutName,
	getToken,
	getUser,
	printDateFromDateTime,
	printDateTimeFromServer,
	printLink,
	printPrice,
	printSpaceData,
	printTimeRange,
	resetBookings,
	resetUser,
	setProposalBooking,
	setUserName,
	setUserPhone,
	updateStartTime
} from "src/Helpers";
import {Link, Redirect, withRouter} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import queryString from "query-string";
import {faArrowLeft, faCircleNotch, faStar, faTimes} from "@fortawesome/free-solid-svg-icons";
import {GoogleMap, Marker} from "@react-google-maps/api";
import styles from "../../../../GoogleMapStyles.json";
import markerIcon from "../../../../img/blue-marker2.png";
import axios from "axios";
import {getAxios} from "src/Helpers";
import Footer from "../../../organisms/Footer";
import mapIcon from '../../../../img/map-pin.png';
import DatePicker from "react-datepicker";
import Bugsnag from "@bugsnag/js";
import moment from "moment";
import Star from "../../../atoms/Star";
import {connect} from "react-redux";
import {blue, green, red} from "@material-ui/core/colors"
import ReceiptDialog from "../../../organisms/ReceiptDialog";
import {PaymentVerification} from "../../../payment-verfication";
import {
	CATERED_AMENITIES,
	getTypeTextKey
} from "../../../host/host-dashboard/manage-listings/add-edit-workspaces/consts";
import {Nullable} from "src/global";
import {isNotNullOrUndefined} from "src/global-functions";
import {
	checkGenericIsPaid,
	DdrUtils,
	getAvalQuotesNum,
	getGenericQuotePrice,
	getGenericQuoteStatus,
	printSummaryQuoteStatus
} from "src/utils/ddr-utils";
import {withAlertDialog} from "src/components/organisms/alert-dialog";
import AlertDialog from "src/components/organisms/alert-dialog/AlertDialog";
import {AccordionDetails, AccordionSummary} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import {AccordionController} from "src/components/organisms/accordion-wrapper";
import clsx from "clsx";
import "src/styles/reservation-space-stick.css";
import {FMPayment} from "src/components/organisms/fm-payment";

const rightCellStyle = {
	borderLeft: '1px solid #cdddeb',
}
const leftCellStyle = {
	paddingRight: 15
}
const excludedDates = [];
// '10 Mar 2021',
// '11 Mar 2021',
// '12 May 2021',
// '13 May 2021',
// '14 May 2021',
// '15 May 2021',
// '18 Jul 2021',
// '19 Jul 2021',
// '20 Jul 2021',
// '21 Jul 2021',
// '22 Jul 2021',
// '09 Aug 2021',
// '10 Aug 2021',
// '18 Oct 2021',
// '19 Oct 2021',
// '30 Nov 2021',
// '1 Dec 2021',
// '2 Dec 2021',
// ];

const reviews = ['Terrible', 'Bad', 'Okay', 'Good', 'Great'];

const dateOptions = {year: 'numeric', month: 'short', day: 'numeric'};

const DECLINED_BY_SEEKER = 'declined by seeker'
const BOOK = 'book'
const CALL = 'call'
const HOST_PHONE = 'host-phone'
const CALL_REQUESTED = 'call requested'
const VISIT_ARRANGED = 'visit arranged'
const ADD_NOTE = 'add note'
const EMAIL_SHARE = 'email-share'
const REVIEW = 'review'
const SEEKER_BETTER_PRICE_REQUESTED = 'seeker-better-price-requested'


class DdrDetails extends Component<any, any> {
	showMoreRef = React.createRef<HTMLDivElement>();
	receiptDialog = React.createRef<ReceiptDialog>();
	visitTimeRef = React.createRef<HTMLSelectElement>();
	accordionRefs: any = {}


	constructor(props) {
		super(props);
		this.state = {
			location: {lat: 25.1210779, lng: 55.2212647},
			showDetails: true,
			showReviews: true,
			showSummary: true,
			showQuotes: true,
			showVenue: true,
			rating: 0,
			tempRating: 0,
			review: null,
			showMoreReq: null,
			showMore: '',
			showMap: '',
			space: null,
			visitDate: null,
			visitTime: '',
			reservation: null,
			venues: null,
			reqAlert: null,
			excludedDays: {},
			errors: {},
			requestsLoading: {},
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (this.props.location.search !== prevProps.location.search) {
			this.checkQuoteSelected();
		}
	}

	componentDidMount() {
		this.fetchReservation();
		document.addEventListener('mousedown', this.handleClickOut);
	}

	componentWillUnmount() {
		window.removeEventListener('mousedown', this.handleClickOut);
	}

	checkQuoteSelected = () => {
		const search = this.props.location.search;
		if (search) {
			let query = queryString.parse(search);
			if (query.quote) {
				var quoteDiv = document.getElementById("quote-" + query.quote);
				if (quoteDiv)
					window.scrollTo(0, quoteDiv.offsetTop - 100);
			}
		}
	}

	handleClickOut = (event) => {

		if (this.showMoreRef.current != null && !this.showMoreRef.current.contains(event.target)) {
			this.toggleShowMore();
		}
	}

	handleSelectQuote = (id) => {

		const {location, history} = this.props;

		if (location.search && queryString.parse(location.search).quote === id) {
			this.checkQuoteSelected();
			return;
		}

		history.push(printLink(location.pathname, location.search, 'quote', id));
	}

	toggleShowMore = (type: Nullable<string> = null, req: any = null) => {

		if (type) {
			document.body.classList.add("no-scroll");
		} else {
			document.body.classList.remove("no-scroll");
		}
		if (type === VISIT_ARRANGED) {
			this.initDate(req);
		}
		this.setState({showMore: type, showMoreReq: req});
	}

	initDate = (req) => {

		const {reservation, space, venues} = this.state;
		const ws_avail = (reservation.multiple) ? venues[req.generic_venue_id].ws_avail : space.ws_avail;

		var excluded = {}
		Object.keys(ws_avail).forEach(day => {
			const val = ws_avail[day];
			if (val === "closed") {
				if (day === 'sunday') {
					excluded[0] = true;
				} else if (day === 'monday') {
					excluded[1] = true;
				} else if (day === 'tuesday') {
					excluded[2] = true;
				} else if (day === 'wednesday') {
					excluded[3] = true;
				} else if (day === 'thursday') {
					excluded[4] = true;
				} else if (day === 'friday') {
					excluded[5] = true;
				} else if (day === 'saturday') {
					excluded[6] = true;
				}
			}
		});

		const nextAvlDate = new Date();
		const dayIndex = nextAvlDate.getDay();
		const date = this.getNextAvlDay(excluded, dayIndex, 0);

		this.setState({visitDate: date, excludedDays: excluded,});
	}

	getNextAvlDay = (excluded, dayIndex, counter) => {

		if (dayIndex > 6) {
			dayIndex = dayIndex - 6;
		}
		if (counter > 6) {
			counter = counter - 6;
		}

		if (excluded[dayIndex]) {
			return this.getNextAvlDay(excluded, dayIndex + 1, counter + 1);
		} else {
			var newDate = new Date();
			newDate.setDate(new Date().getDate() + counter);
			return newDate;
		}
	}

	printTimeOptions = (reservation, space, venues, req, visitDate) => {

		const ws_avail = (reservation.multiple) ? venues[req.generic_venue_id].ws_avail : space.ws_avail;

		const dayIndex = visitDate.getDay();
		var day = '';
		if (dayIndex === 0) {
			day = 'sunday';
		} else if (dayIndex === 1) {
			day = 'monday';
		} else if (dayIndex === 2) {
			day = 'tuesday';
		} else if (dayIndex === 3) {
			day = 'wednesday';
		} else if (dayIndex === 4) {
			day = 'thursday';
		} else if (dayIndex === 5) {
			day = 'friday';
		} else if (dayIndex === 6) {
			day = 'saturday';
		}

		return updateStartTime(ws_avail, day);
	}

	onVisitTimeChange = () => {

		const visitTime = this.visitTimeRef.current?.value;
		this.setState({visitTime: visitTime});
	}

	starSelected = rating => {
		const {history} = this.props;
		const {reservation} = this.state;
		if (reservation.reservation_rating != '0') return;
		this.setState({showMore: REVIEW, tempRating: rating, showMoreReq: null});
	};
	showReceipt = () => {
		devLog(this.receiptDialog)
		this.receiptDialog?.current?.open()
	};

	onStar = rating => {
		this.setState({tempRating: rating});
	};

	reviewCompleted = (rating, review) => {
		this.setState({rating, review});
	}

	onSubmitReview = e => {
		e.preventDefault();

		const data = new FormData(e.target);

		const {requestsLoading, reservation, tempRating, errors} = this.state;

		requestsLoading[REVIEW] = true;
		this.setState({requestsLoading: requestsLoading});

		errors.message = formValidation('message', data.get("review"));

		Object.keys(errors).forEach(key => {
			if (!errors[key]) delete errors[key];
		});

		this.setState({errors: errors});
		if (Object.keys(errors).length > 0) return;

		this.setState({reqLoading: true});

		devLog(reservation.reservation_type, reservation.workspace_id, reservation.reservation_id, tempRating, data.get("review"));

		const token = getToken();
		if (!token) {
			this.setState({showMore: '', reqLoading: false});
			return;
		}
		;
		const url = getApiLinkV2('user/review');
		getAxios().post(url, {
			reservation_type: reservation.reservation_type,
			workspace_id: reservation.workspace_id,
			reservation_id: reservation.reservation_id,
			rating: tempRating,
			comment: data.get("review"),
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token,
			},
		}).then(response => {
			const requestsLoadings = this.state.requestsLoading;
			delete requestsLoadings[REVIEW];
			this.setState({requestsLoading: requestsLoadings, rating: tempRating, review: data.get("review")});
			this.reviewCompleted(tempRating, data.get("review"));
			this.toggleShowMore();
		}).catch(error => {
			devLog(error)
			Bugsnag.notify(error);
			const requestsLoading = this.state.requestsLoading;
			delete requestsLoading[REVIEW];
			this.setState({requestsLoading: requestsLoading});
		});
	}

	handlePayOnline = (request) => {

		const {defCurrency} = this.props;
		const {requestsLoading, reservation, space, venues, apply_discount, spaces} = this.state;
		const user = getUser();
		const currency = getCurrency(defCurrency);
		let _space = (reservation.multiple !== "yes") ? space : spaces?.[request.workspace_id];
		requestsLoading[request.quote_id + BOOK] = true;
		this.setState({requestsLoading: requestsLoading});
		const url = getApiLinkV2('workspace/booking');
		const token = getToken();
		getAxios().post(url, {
				user_id: user?.id ?? "",
				submit_new: true,
				reservation_type: 'proposal',
				quote_id: request.quote_id,
				currency: 'USD',
				tax_name: 'VAT',
				title: '',
				capacity: 1,
				workspace_id: request.workspace_id,
				start_date_time: request.start_date + ' 00:00:00',
				end_date_time: request.end_date + ' 00:00:00',
				amt_due_after_disc: ((request.tot_better_price || request.tot_fluidmeet_price) * (1 - 0.01 * ((request.discount_percentage && apply_discount) ? parseFloat(request.discount_percentage) : 0))),
				final_payment: request.amount_due,
				amount_due: request.amount_due,
				total_price: request.amount_due,
				immediate_capture: true,
				redirect_url: this.getVerificationUrl(),
				space_type: _space?.workspace_type_id ?? 'unknown',
			},
			{
				headers: {
					'Cache-Control': 'no-cache',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token,
				},
			}).then(response => {

			if (response.data) devLog(response.data);

			if (response.data.intent) {

				request.order_id = response.data.booking_reference;
				request.payment_id = response.data.p_id;
				if (reservation.multiple) {
					request.venue = venues[request.generic_venue_id];
				} else {
					request.space = space;
				}
				// request.workspace_type = (request.workspace_type) ? request.workspace_type : 'banquet';
				resetBookings();
				setProposalBooking(request);

				const {requestsLoading} = this.state;
				delete requestsLoading[request.quote_id + BOOK];
				this.setState({requestsLoading: requestsLoading, paymentIntent: response.data.intent});

				// window.open(response.data.payment_url, '_top');
			} else {
				const {requestsLoading} = this.state;
				delete requestsLoading[request.quote_id + BOOK];
				this.setState({requestsLoading: requestsLoading});
			}


		}).catch(error => {

			Bugsnag.notify(error);
			devLog({error})

			if (error.response.data) devLog(error.response.data);

			createAlert(this, 'danger', (error.response && error.response.data.message) ? 'Error: ' : '', (error.response && error.response.data.message) ? error.response.data.message : error.message);

			const requestsLoading = this.state.requestsLoading;
			delete requestsLoading[request.quote_id + BOOK];
			this.setState({requestsLoading: requestsLoading});
		});
	}
	getVerificationUrl = () => (
		new URL(
			PaymentVerification.generatePath(
				{
					booking_type: "ddr",
					workspace_id: this.props.match.params.id,
					workspace_type: getTypeTextKey(this.state.space.workspace_type_id),
					event: 'verify',
					payment_id: this.state.paymentIntent?.metadata['order_id']
				}
			),
			window.location.origin
		).toString()
	)
	handleQuotePaid = (request) => {
		const {defCurrency} = this.props;
		const currency = getCurrency(defCurrency);
		let {apply_discount} = this.state;
		let alertDialog: AlertDialog | null | undefined = this.props.alertDialog?.current;
		let amount = printPrice(parseFloat(request.amount_due) * 0.01 * parseFloat(request.discount_percentage), "USD", currency.name, true);

		alertDialog?.setDialogData({
			autoClose: true,
			title: 'Book and Pay at venue',
			message: (request.is_generic != 0) ?
				'This will decline all other quotes and accept this quote'
				: (request.discount_percentage && request.discount_percentage != '0' && apply_discount) ?
					`By Paying online with Fluidmeet, you shall receive an additional ${request.discount_percentage}% (${amount}) discount on us. Are you sure you still want to pay at the venue?`
					: "Are You sure you want to pay at the venue?",
			onAgreeClicked: () => {
				this.updateQuoteStatus(request, 'paid to venue');
			}
		}, true)


	}

	updateQuoteStatus = (request, status, e?: FormEvent<HTMLFormElement>) => {

		const {defCurrency} = this.props;
		const {reservation, space, visitDate, visitTime: _visitTime, requestsLoading, errors} = this.state;
		const currency = getCurrency(defCurrency);
		const visitTime = this.visitTimeRef.current?.value ?? _visitTime
		var reqData: any = {};

		if (isNotNullOrUndefined(e)) {

			e?.preventDefault();
			const data = new FormData(e?.target as HTMLFormElement);

			switch (status) {
				case VISIT_ARRANGED:
					reqData.fName = data.get("fname");
					reqData.lName = data.get("lname");
					reqData.email = data.get("email");
					reqData.phone = data.get("phone");
					reqData.visit_date_time = moment(visitDate).format('YYYY-MM-DD') + ' ' + visitTime;
					errors.fName = formValidation('name', reqData.fName);
					errors.lName = formValidation('name', reqData.lName);
					errors.email = formValidation('email', reqData.email);
					errors.phone = formValidation('phone', reqData.phone);
					break;
				case ADD_NOTE:
					reqData.note = data.get("note");
					errors.note = formValidation('note', reqData.note);
					break;
				case EMAIL_SHARE:
					reqData.email = data.get("email");
					errors.email = formValidation('email', reqData.email);
					break;
				case DECLINED_BY_SEEKER:
					reqData.reject_reason = data.get("reject_reason");
					reqData.reject_note = data.get("reject_note");
					errors.reject_reason = formValidation('reject_reason', reqData.reject_reason);
					break;
				case CALL_REQUESTED:
					reqData.phone = data.get("phone");
					errors.phone = formValidation('phone', reqData.phone);
					break;
				case SEEKER_BETTER_PRICE_REQUESTED:
					reqData.better_price_note = data.get("better_price_note");
					break;
			}

			Object.keys(errors).forEach(key => {
				if (!errors[key]) delete errors[key];
			});
			this.setState({errors: errors});
			if (Object.keys(errors).length > 0) return;
		}

		const user = getUser();
		const token = getToken();
		if (!user || !token) return;

		requestsLoading[request.quote_id + status] = true;
		this.setState({requestsLoading: requestsLoading});

		let option = status
		const url = getApiLinkV2('user/reservations/' + request.quote_id);
		getAxios().put(url, {
			reservation_type: 'proposal',
			option,
			email: reqData.email,
			first_name: reqData.fName,
			last_name: reqData.lName,
			note: reqData.note,
			visit_date_time: reqData.visit_date_time,
			rc_seeker_phone: reqData.phone,
			reject_reason: reqData.reject_reason,
			reject_reason_other: '',
			reject_note: reqData.reject_note,
			seeker_better_price_notes: reqData.better_price_note,
			seeker_better_price_request_date: request.seeker_better_price_request_date,
			better_price_status: request.better_price_status,
			submit_new: true,
			title: 'paid to venue',
			capacity: 1,
			currency: currency.name,
			tax_name: 'VAT',
			venue_id: (reservation.multiple) ? request.generic_venue_id : space.venue_details_id,
			quote_id: request.quote_id,
			start_date: request.start_date,
			end_date: request.end_date,
			workspace_id: request.workspace_id,
			start_date_time: request.start_date + ' 00:00:00',
			end_date_time: request.end_date + ' 00:00:00',
			amt_due_after_disc: request.amount_due,
			amount_due: request.amount_due,
			total_price: request.amount_due,
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token,
			},
		}).then(response => {
			devLog({response});
			if (response.data.status === 'success') {
				devLog({status});
				const reservation = this.state.reservation;
				const req = reservation.requests.find(x => x.quote_id === request.quote_id);
				if (status === 'accepted') {
					req.quote_status = status;
					if(window.hasOwnProperty('gtag')) {
						window?.gtag('event', 'conversion', {
							'send_to': 'AW-935885959/zSTDCIaxt_YCEIf5ob4D',
							'transaction_id': ''
						});
					}
				} else if (status === ADD_NOTE) {
					req.notes.push({
						user_name: user?.first_name + " " + user?.last_name,
						created_on: (new Date().toLocaleDateString("en-US", {})),
						note: reqData.note,
					});
				} else if (status === DECLINED_BY_SEEKER) {
					req.quote_status = status;
					req.reject_reason = reqData.reject_reason
					req.reject_note = reqData.reject_note
				} else if (status === VISIT_ARRANGED) {
					req.visit_req = 'yes';
					setUserName(reqData.fName, reqData.lName);
				} else if (status === CALL_REQUESTED) {
					req.call_req = 'yes';
					setUserPhone(reqData.phone);
				} else if (status === 'venue contacted') {
					req.venue_contacted = 'yes';
				} else if (status === 'paid to venue') {
					this.fetchReservation();
					if(window.hasOwnProperty('gtag')) {
						window?.gtag('event', 'conversion', {
							'send_to': 'AW-935885959/Vs5WCLSZkPYCEIf5ob4D',
							'transaction_id': ''
						});
					}
				} else if (status === SEEKER_BETTER_PRICE_REQUESTED) {
					this.fetchReservation();
				}
				this.setState({reservation: reservation});
				this.toggleShowMore();
				createAlert(this, 'success', '', response.data.message);

			}

		}).catch(error => {
			Bugsnag.notify(error);
			console.log({error});
			devLog(`updateQuoteStatus :: ${status}`, {error});
			this.toggleShowMore();
			createAlert(this, 'danger', (error.response && error.response.data.error) ? 'Error: ' : '', (error.response && error.response.data.error) ? error.response.data.error : error.message);
		}).then(() => {
			const requestsLoading = this.state.requestsLoading;
			delete requestsLoading[request.quote_id + status];
			this.setState({requestsLoading: requestsLoading});
		});
	}

	fetchReservation = () => {

		const {match, history} = this.props;

		const token = getToken();
		if (!token) return;

		// const url = getApiLink('user/reservations/'+match.params.id);
		const url = getApiLinkV2('user/list-reservations/' + match.params.id);

		getAxios().get(url, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token,
			},
			params: {
				type: match.params.type,
			}
		}).then(response => {
			if (response.data.reservation) {
				const reservation = response.data.reservation;
				if (reservation.requests) reservation.requests.sort((a, b) => (a.created_datetime < b.created_datetime) ? -1 : (a.created_datetime > b.created_datetime) ? 1 : 0);
				const venues = response.data.venues;
				const space = response.data.space;
				const spaces = response.data.spaces;
				const location = (space) ? {
					lat: parseFloat(space.latitude),
					lng: parseFloat(space.longitude)
				} : {lat: 25.1210779, lng: 55.2212647};
				reservation.accomodation?.forEach(
					(_, i) => {
						this.accordionRefs[`accomm-day-${i}`] = this.accordionRefs[`accomm-day-${i}`] ?? React.createRef()
					}
				)
				reservation.meeting_data?.forEach(
					(_, i) => {
						this.accordionRefs[`event-day-${i}`] = this.accordionRefs[`event-day-${i}`] ?? React.createRef()
					}
				)
				this.setState({
					apply_discount: response.data.apply_discount,
					reservation,
					rating: (reservation.reservation_rating) ? reservation.reservation_rating : 0,
					review: reservation.comment ? reservation.comment : null,
					space,
					spaces,
					venues,
					location
				});
				this.checkQuoteSelected();
				let query = new URLSearchParams(this.props.location.search)
				let action = query.get('action')
				if (action === 'view-receipt') this.showReceipt()
				return;
			}
			history.push('/error');
		}).catch(error => {
			Bugsnag.notify(error);
			devLog({error});
			history.push('/error');
		});
	}

	clearActionFromQuery = () => {
		let {location, history} = this.props
		let query = new URLSearchParams(location.search)
		query.delete('action')
		history.push(location.pathname + '?' + query.toString())
	}

	createMoreDialog = () => {
		const {match, defCurrency} = this.props;
		const {reservation, space, venues, location, visitDate, visitTime, excludedDays, showReviews, showDetails, showSummary, showQuotes, showVenue, showMap, showMore, showMoreReq, errors, tempRating, rating, review, requestsLoading, reqAlert} = this.state;
		const user = getUser();
		const currency = getCurrency(defCurrency);
		const title = () => {
			switch (showMore) {
				case DECLINED_BY_SEEKER:
					return 'Decline Quote'
				case BOOK:
					return 'Book now'
				case CALL:
					return 'Call Venue'
				case HOST_PHONE:
					return 'Venue Contact Info'
				case CALL_REQUESTED:
					return 'Request a Call'
				case VISIT_ARRANGED:
					return 'Arrange Visit'
				case ADD_NOTE:
					return 'Add Note'
				case EMAIL_SHARE:
					return 'Share Quote'
				case REVIEW:
					return 'How was your reservation?'
				case SEEKER_BETTER_PRICE_REQUESTED:
					return 'Request a better price'
				default:
					return ''
			}
		}
		return (
			<>
				<div id="more-filters"
				     className={"more-filter fz-17 justify-content-center " + ((showMore) ? "show-flex" : "")}>
					<div ref={this.showMoreRef}
					     className="more-box align-self-center space-page-box reserv-box">
						<form autoComplete="on"
						      onSubmit={(e) => showMore == REVIEW ? this.onSubmitReview(e) : this.updateQuoteStatus(showMoreReq, showMore, e)}>
							<div className="more-header">
								<h5 className="center">{title()}</h5>
								<div className="dropdown-divider mt-4 mb-0"/>
							</div>
							<div className="more-contain reserv-box-box-contain">
								{(showMore === SEEKER_BETTER_PRICE_REQUESTED) ?
									(
										<>
											<div className="form-general p-0">
												<div className=" w100 mt-3 mb-2">
													If you feel that the offered price is too high you can request a
													better price by clicking on the button below
												</div>
												<textarea id="better_price_note" name="better_price_note"
												          className="form-control"
												          placeholder="Comments for the venue"/>

												<div className="more-bottom">
													<div className="dropdown-divider mb-3 mt-0"/>

													<button type="submit"
													        className={"custom-btn save " + ((requestsLoading[showMoreReq.quote_id + showMore]) ? 'ld-btn' : '')}>
														{(requestsLoading[showMoreReq.quote_id + showMore]) ? (<>
															<FontAwesomeIcon icon={faCircleNotch}
															                 spin={true}/> loading</>) : ('Request a Better Price')}
													</button>
													<button
														ref={(node) => {
															node?.style.setProperty("background-color", "#de2121", "important");
														}}
														className={"custom-btn save " + ((requestsLoading[showMoreReq.quote_id + showMore]) ? 'ld-btn' : '')}
														onClick={() => this.toggleShowMore(DECLINED_BY_SEEKER, showMoreReq)}
													>
														{(requestsLoading[showMoreReq.quote_id + showMore]) ? (<>
															<FontAwesomeIcon icon={faCircleNotch}
															                 spin={true}/> loading</>) : ('Not Interested')}
													</button>
												</div>
											</div>
										</>
									)
									:
									(showMore === DECLINED_BY_SEEKER) ? (
										<>
											<div className="form-general p-0">
												<div className="reservation-text pt-2 pb-2">Reason</div>
												<div className="selector">
													<select name="reject_reason" id="reject_reason"
													        className="select">
														<option value="">Select reason
														</option>
														<option value="Price quoted is too high">Price
															quoted is too high
														</option>
														<option
															value="Quote received too late (already found another venue)">
															Quote received too late (already found another
															venue)
														</option>
														<option
															value="Location of venue not suitable">Location
															of venue not suitable
														</option>
														<option
															value="Venue could not match F&amp;B requirements">Venue
															could not match F&amp;B requirements
														</option>
														<option
															value="Venue could not match capacity (number of people) requirements">
															Venue could not match capacity (number of
															people) requirements
														</option>
														<option value="Found better priced option">Found
															better priced option
														</option>
														<option
															value="Found venue with better location">Found
															venue with better location
														</option>
														<option value="Event postponed">Event postponed
														</option>
														<option value="Event cancelled">Event cancelled
														</option>
														<option value="Other">Other</option>
													</select>
												</div>
												{(errors.reject_reason) ? (<div
													className="form-error">{errors.reject_reason}</div>) : (<></>)}
												<div className="reservation-text pt-2 pb-2">Other Comments
												</div>
												<textarea id="reject_note" name="reject_note"
												          className="form-control" placeholder="Comments"/>
											</div>
										</>
									) : (showMore === HOST_PHONE) ? (
										<>
											<div className="reserv-text w100 mt-3 mb-2">Venue
												Name: {(reservation.multiple) ? venues[showMoreReq.generic_venue_id].venue_name : space.venue_name}</div>
											<div className="reserv-text w100 mt-3 mb-2">Phone
												Number: {(reservation.multiple) ? venues[showMoreReq.generic_venue_id].phone : space.phone}</div>
										</>
									) : (showMore === REVIEW) ? (
										<>
											<div className='ratingContainerDiv'
											     style={{width: '100%', display: "block"}}>
												<div className='starContainer pt-2' style={{
													alignContent: 'center',
													justifyContent: 'center',
													width: '100%'
												}}>
													{[...Array(5)].map((e, i) => (
														<Star
															key={i}
															index={i}
															size={40}
															margin={5}
															position={tempRating}
															starSelectedInPosition={val => this.onStar(val)}
															fill={tempRating >= i + 1}
														/>
													))}
												</div>
												<div style={{width: '100%'}}>
													<div className='review-text mt-3' style={{
														textAlign: 'center',
														fontSize: 30
													}}>{reviews[tempRating - 1]}</div>
												</div>
											</div>
											<div className="reservation-text pb-2">Review</div>
											<textarea id="review" name="review" className="form-control"
											          placeholder="Write your comments here"/>
											{(errors.message) ? (<div
												className="form-error">{errors.message}</div>) : (<></>)}
										</>
									) : (showMore === CALL_REQUESTED) ? (
										<div className="form-general p-0">
											<div className="row mt-3 mb-4">
												<div className="col-12 pl-2">
													<div className="reservation-text pt-2 pb-1">Phone
														Number
													</div>
													<input type="tel" id="phone" name="phone"
													       className="form-control"
													       placeholder="Phone number"
													       defaultValue={(user) ? user.phone : ''}
													       required={true}/>
													{(errors.phone) ? (<div
														className="form-error">{errors.phone}</div>) : (<></>)}
												</div>
											</div>
										</div>
									) : (showMore === CALL) ? (
										<div className="row mr-2 ml-2 mt-4 mb-4">
											<div className="half-width-btn pr-3">
												{/*<button type="button" className="btn btn-primary equal-btns disabled-btn w100">*/}
												{/*  Call now*/}
												{/*</button>*/}
												<button type="button"
												        className="btn btn-primary equal-btns w100"
												        onClick={() => this.setState({
													        showMore: HOST_PHONE,
													        showMoreReq: showMoreReq
												        })}>
													Contact details
												</button>
											</div>
											<div className="half-width-btn pr-2">
												<button type="button"
												        className="btn btn-primary equal-btns w100"
												        onClick={() => this.setState({
													        showMore: CALL_REQUESTED,
													        showMoreReq: showMoreReq
												        })}>
													Request a Call
												</button>
											</div>
										</div>
									) : (showMore === BOOK) ? (
										<div className="row mr-2 ml-2 mt-4 mb-4">
											<div className="half-width-btn pr-3">
												<button type="button"
												        className={"btn btn-primary equal-btns w100 " + ((requestsLoading[showMoreReq.quote_id + BOOK]) ? 'ld-btn' : '')}
												        onClick={() => this.handlePayOnline(showMoreReq)}>
													{(requestsLoading[showMoreReq.quote_id + BOOK]) ? (<>
														<FontAwesomeIcon icon={faCircleNotch}
														                 spin={true}/> loading</>) : 'Pay online'}
												</button>
											</div>
											<div className="half-width-btn pr-2">
												<button type="button"
												        className={"btn btn-primary equal-btns w100 " + ((requestsLoading[showMoreReq.quote_id + 'paid to venue']) ? 'ld-btn' : '')}
												        onClick={() => this.handleQuotePaid(showMoreReq)}>
													{(requestsLoading[showMoreReq.quote_id + 'paid to venue']) ? (<>
														<FontAwesomeIcon icon={faCircleNotch}
														                 spin={true}/> loading</>) : 'Pay at venue'}
												</button>
											</div>
										</div>
									) : (showMore === VISIT_ARRANGED) ? (
										<div className="form-general p-0">
											<div className="row mt-3 mb-4">
												<div className="col-6 pr-2">
													<div className="reservation-text pt-2 pb-1">Date</div>
													<div className="selector p-0 date-picker">
														<div
															className="dates-picked">{(visitDate) ? visitDate.toLocaleDateString("en-US", dateOptions) : ''}</div>
														<DatePicker
															filterDate={(date) => excludedDays[date.getDay()] == null}
															excludeDates={excludedDates.map(date => new Date(date))}
															onChange={(date) => this.setState({visitDate: date})}
															selected={visitDate}
															shouldCloseOnSelect={true}
															minDate={new Date()}/>
													</div>
												</div>
												<div className="col-6 pl-2">
													<div className="reservation-text pt-2 pb-1">Time</div>
													<div className="selector">
														<select ref={this.visitTimeRef} name="visit_time"
														        id="visit_time"
														        className="select" value={visitTime}
														        onChange={this.onVisitTimeChange}>
															{this.printTimeOptions(reservation, space, venues, showMoreReq, visitDate)}
														</select>
													</div>
												</div>
												<div className="col-6 pr-2">
													<div className="reservation-text pt-2 pb-1">First Name
													</div>
													<input type="name" id="fname" name="fname"
													       className="form-control" placeholder="First name"
													       defaultValue={(user) ? user.first_name : ''}
													       required={true}/>
													{(errors.fName) ? (<div
														className="form-error">{errors.fName}</div>) : (<></>)}
												</div>
												<div className="col-6 pl-2">
													<div className="reservation-text pt-2 pb-1">Last Name
													</div>
													<input type="name" id="lname" name="lname"
													       className="form-control" placeholder="Last name"
													       defaultValue={(user) ? user.last_name : ''}
													       required={true}/>
													{(errors.lName) ? (<div
														className="form-error">{errors.lName}</div>) : (<></>)}
												</div>
												<div className="col-12">
													<div className="reservation-text pt-2 pb-1">Email
														Address
													</div>
													<input type="email" id="email" name="email"
													       className="form-control"
													       placeholder="Email address"
													       defaultValue={(user) ? user.email : ''}
													       required={true}/>
													{(errors.email) ? (<div
														className="form-error">{errors.email}</div>) : (<></>)}
												</div>
												<div className="col-12">
													<div className="reservation-text pt-2 pb-1">Phone
														Number
													</div>
													<input type="tel" id="phone" name="phone"
													       className="form-control"
													       placeholder="Phone number"
													       defaultValue={(user) ? user.phone : ''}
													       required={true}/>
													{(errors.phone) ? (<div
														className="form-error">{errors.phone}</div>) : (<></>)}
												</div>
											</div>
										</div>
									) : (showMore === ADD_NOTE) ? (
										<>
														<textarea id="note" name="note" className="form-control mt-3"
														          placeholder="Note" required={true}/>
											{(errors.note) ? (
												<div className="form-error">{errors.note}</div>) : (<></>)}
										</>
									) : (showMore === EMAIL_SHARE) ? (
										<>
											{/*<div className="reservation-text bold pt-3 pb-1">Shared with</div>*/}
											{/*<div className="reserv-text">mohamedyassin360@gmail.com</div>*/}
											{/*<div className="reserv-text">yassin@fluidmeet.com</div>*/}
											<input type="email" id="email" name="email"
											       className="inputEmail form-control mt-3"
											       placeholder="Email address" required/>
										</>
									) : (
										<></>
									)}
							</div>
							{showMore == REVIEW && (
								<div className="more-bottom">
									<div className="dropdown-divider mb-3 mt-0"/>
									<button type="submit"
									        className={"custom-btn save " + ((requestsLoading[showMore]) ? 'ld-btn' : '')}>
										{(requestsLoading[showMore]) ? (<><FontAwesomeIcon
											icon={faCircleNotch} spin={true}/> loading</>) : 'Done'}
									</button>
								</div>
							)}
							{((showMoreReq && showMore !== BOOK && showMore !== CALL && showMore !== HOST_PHONE && showMore !== SEEKER_BETTER_PRICE_REQUESTED) && (
								<div className="more-bottom">
									<div className="dropdown-divider mb-3 mt-0"/>
									<button type="submit"
									        className={"custom-btn save " + ((requestsLoading[showMoreReq.quote_id + showMore]) ? 'ld-btn' : '')}>
										{(requestsLoading[showMoreReq.quote_id + showMore]) ? (<>
											<FontAwesomeIcon icon={faCircleNotch}
											                 spin={true}/> loading</>) : ((showMore === EMAIL_SHARE || showMore === ADD_NOTE) ? 'Add' : 'Submit')}
									</button>
								</div>
							))}
						</form>
					</div>
				</div>
			</>
		)
	}

	printMultipleProposal = (currency, location, reservation, venues, showSummary, isMobileView) => {

		var elements: any = [];

		if (!reservation.multiple) return elements;

		elements.push(
			<div id={(isMobileView) ? "summary-block" : ""}
			     className={(isMobileView) ? "" : "pb-3"}>
				<div className={(isMobileView) ? "m-0" : "m-0 border-0"}>

					{(isMobileView) ? (
						<h4 className="section-head" onClick={() => this.setState({showSummary: !showSummary})}>Quotes
							Summary</h4>
					) : (
						<h5 className="pb-2">Quotes Summary</h5>
					)}

					{((!isMobileView || showSummary) && (

						reservation.requests.map(item => {

							if (!item.tot_fluidmeet_price || (!venues || !venues[item.generic_venue_id])) return (<></>)

							return (
								<div className={(isMobileView) ? "pr-3 pl-3" : ""}
								     onClick={() => this.handleSelectQuote(item.quote_id)}>
									<div className="row mr-0 ml-0 pt-2">
										<div className="reserv-id-cont">
											<div className="pb-1">
												<div
													className={(isMobileView) ? "reservation-text" : "summary-text"}>{(venues[item.generic_venue_id].venue_name)} {printSummaryQuoteStatus(item)}</div>
											</div>
										</div>
										<div className="summary-price-cont">
											<div className="pb-1">
												<div className="reservation-text font-weight-bold text-right">
													{(item.tot_fluidmeet_price) ? printPrice(item.amount_due, 'USD', currency.name, true) : '-'}
												</div>
											</div>
										</div>
									</div>
								</div>
							)
						})
					))}

				</div>
			</div>
		);

		return elements;
	}

	buildPricingRow = ({header, first_column, second_column, third_column, style = {}}) => {

		return (
			<tr className="pb-2" style={style}>
				<td colSpan={7}
				    className="reserv-subtitle"
				    style={{wordBreak: "break-word", whiteSpace: 'normal',}}
				>
					{header}
				</td>
				<td colSpan={4} className="reservation-text text-right"
				    style={{
					    ...leftCellStyle,
					    wordBreak: "break-word",
					    whiteSpace: 'normal',
					    textDecoration: `line-through ${red[400]}`
				    }}>
					{first_column}
				</td>
				<td colSpan={4} className="reservation-text text-right" style={{
					...(third_column ? {
						...leftCellStyle,
						textDecoration: `line-through ${red[400]}`
					} : {}), ...rightCellStyle, wordBreak: "break-word", whiteSpace: 'normal'
				}}>
					{second_column}
				</td>
				{
					third_column &&
					<td colSpan={4} className="reservation-text text-right"
					    style={{...rightCellStyle, wordBreak: "break-word", whiteSpace: 'normal'}}>
						{third_column}
					</td>
				}

			</tr>
		)
	}

	printProposals = (currency, user, reservation, venues, requestsLoading, showMap, apply_discount) => {

		let {paymentIntent} = this.state
		if (reservation.pending || (!(reservation.multiple === 'yes') && reservation.quote_status === 'pending' && reservation.amount_due == 0)) {
			return (<h6 className="empty-title fz-18 pt-2 mt-4 mb-5">No Available quotes yet</h6>);
		}

		let elements: any = [];
		let count = 0;
		let better_price_requested = false
		// let better_price_note = ''
		if (!reservation.requests || reservation.requests.length === 0) return;

		reservation.requests.forEach(req => {
			let subtotals = {tot_venue_price: 0, tot_fluidmeet_price: 0, tot_better_price: 0,}
			// console.log(count,(({status,tot_fluidmeet_price,reject_reason,generic_venue_id})=>({status,tot_fluidmeet_price,reject_reason,generic_venue_id}))(req) )
			// console.log((req.status === 'inactive' && !req.reject_reason) ,  !req.tot_fluidmeet_price , (!venues || !venues[req.generic_venue_id])  )
			better_price_requested = better_price_requested || req.better_price_status
			// if (req.better_price_status && req.host_better_price_notes) better_price_note = req.host_better_price_notes
			if ((req.status === 'inactive' && !req.reject_reason) || (reservation.multiple && (!req.tot_fluidmeet_price || (!venues || !venues[req.generic_venue_id])))) return;
			count++;
			const vat = req?.tax_percent ? parseInt(req.tax_percent) : 0;
			let venue: any = null;
			let location = {lat: 25.1210779, lng: 55.2212647};

			if (reservation.multiple) {
				venue = venues[req.generic_venue_id];
				location = {
					lat: parseFloat(venue?.latitude ?? 25.1210779),
					lng: parseFloat(venue?.longitude ?? 55.2212647)
				}
			}
			elements.push(
				<div id={"quote-" + req.quote_id} className="proposal">

					{(reservation.multiple && (
						<>
							<div className="position-relative">
								<div className="row mr-0 ml-0">
									<div className="reserv-id-cont">
										<div className="space-text-header fz-18 pt-1 pb-1">{venue.venue_name}</div>
									</div>
									<div className="quote-status-cont pt-1 pb-3">{DdrUtils.printQuoteStatus(req)}</div>
								</div>
								{/*<div className="space-text-bold pt-2 pr-65">{(venue.venue_name)}</div>*/}
								<div className="space-text pt-1 pb-3 pr-65">{venue.registered_address}</div>
								<img src={mapIcon} style={{
									position: "absolute",
									right: 0,
									bottom: 15,
									width: 36,
									height: 36,
									cursor: "pointer"
								}}
								     onClick={() => this.setState({showMap: ((showMap === venue.venue_details_id) ? '' : venue.venue_details_id)})}/>
							</div>
							{(showMap === venue.venue_details_id && (
								<div className="map-cont mb-3">
									{/*<LoadScript googleMapsApiKey='AIzaSyBDM449V7qE4OkyoV2zP3xm_VQIYDvrHVw'>*/}
									<GoogleMap
										options={{
											styles: styles,
											keyboardShortcuts: false,
											scaleControl: true,
											draggable: true,
											gestureHandling: "greedy",
											mapTypeControl: true,
											mapTypeControlOptions: {position: 1},
											zoomControl: true,
											zoomControlOptions: {position: 8},
											maxZoom: 11,
										}}
										mapContainerStyle={{height: "100%", width: "100%"}}
										zoom={12}
										center={location}>
										<Marker key={venue.venue_details_id} position={location} icon={markerIcon}/>
									</GoogleMap>
									{/*</LoadScript>*/}
								</div>
							))}
						</>
					))}

					{(reservation.multiple && (<div className="pt-1 bb-1"/>))}
					<table className={'w-100'} style={{tableLayout: 'fixed'}}>
						<thead>
						<tr>
							<th style={{paddingTop: 10, paddingBottom: 10}} colSpan={7}>Event Pricing</th>
							<th colSpan={4} className={'text-right'}
							    style={{...leftCellStyle, fontSize: '.85rem'}}>Venue
								Price
							</th>
							<th colSpan={4} className={'text-right'}
							    style={{...rightCellStyle, fontSize: '.85rem'}}>Fluidmeet Price
							</th>
							{
								req.host_better_price_submit_date &&
								<th colSpan={4} className={'text-right'}
								    style={{...rightCellStyle, fontSize: '.85rem'}}>Improved Price
								</th>
							}
						</tr>
						</thead>
						<tbody>
						{req.meeting_data && req.meeting_data.map((item, i) => {
								['venue', 'fluidmeet', 'better'].forEach((type) => {
									subtotals[`tot_${type}_price`] += parseFloat(item[`${type}_price`])
								})
								return (
									this.buildPricingRow({
										header:
											<a onClick={() => {
												window.scrollTo(0, (document.getElementById(`event-day-${i}`)?.offsetTop ?? 80) - 80);
												this.accordionRefs[`event-day-${i}`]?.current?.open();
											}}
											   style={{
												   cursor: 'pointer',
												   textDecoration: 'underline',
												   color: blue[500]
											   }}
											>Event Day {i + 1} - {printDateFromDateTime(item.meeting_date)}</a>
										,
										first_column: '  ' + printPrice(item.venue_price, 'USD', currency.name) + '    ',
										second_column: printPrice(item.fluidmeet_price, 'USD', currency.name),
										third_column: item.better_price ? printPrice(item.better_price, 'USD', currency.name) : null,
									})

								)
							}
						)
						}
						<tr style={{borderTop: '1px solid #cdddeb'}}>
							<th style={{paddingTop: 10, paddingBottom: 10}} colSpan={7}>Accommodation Pricing</th>
							<th colSpan={4} className={'text-right'}
							    style={{...leftCellStyle, fontSize: '.85rem'}}>Venue
								Price
							</th>
							<th colSpan={4} className={'text-right'}
							    style={{...rightCellStyle, fontSize: '.85rem'}}>Fluidmeet Price
							</th>
							{
								req.host_better_price_submit_date &&
								<th colSpan={4} className={'text-right'}
								    style={{...rightCellStyle, fontSize: '.85rem'}}>Improved Price
								</th>
							}
						</tr>

						{req.accomodation && req.accomodation.map(
							(item, i) => {
								['venue', 'fluidmeet', 'better'].forEach((type) => {
									subtotals[`tot_${type}_price`] += parseFloat(item[`${type}_price`])
								})
								return (
									this.buildPricingRow({
										header: <a onClick={() => {
											window.scrollTo(0, (document.getElementById(`accomm-day-${i}`)?.offsetTop ?? 80) - 80);
											this.accordionRefs[`accomm-day-${i}`]?.current?.open();
										}}
										           style={{
											           cursor: 'pointer',
											           textDecoration: 'underline !important',
											           color: blue[500]
										           }}
										>Day {printDateFromDateTime(item.acc_date)}</a>,
										first_column: '  ' + printPrice(item.venue_price, 'USD', currency.name) + '    ',
										second_column: '  ' + printPrice(item.fluidmeet_price, 'USD', currency.name) + '    ',
										third_column: item.better_price ? printPrice(item.better_price, 'USD', currency.name) : null,
									})

								)
							}
						)
						}

						{
							this.buildPricingRow({
								header: `Total Price (excluding VAT)`,
								// first_column: '  ' + printPrice(Math.ceil(req.tot_venue_price / (1 + (req.tax_percent / 100))), 'USD', currency.name, true) + '    ',
								// second_column: '  ' + printPrice(Math.ceil(req.tot_fluidmeet_price / (1 + (req.tax_percent / 100))), 'USD', currency.name, true) + '    ',
								// third_column: req.tot_better_price ? printPrice(Math.ceil(parseFloat(req.tot_better_price) / (1 + (req.tax_percent / 100))), 'USD', currency.name, true) : null,
								first_column: '  ' + printPrice(subtotals.tot_venue_price, 'USD', currency.name, true) + '    ',
								second_column: '  ' + printPrice(subtotals.tot_fluidmeet_price, 'USD', currency.name, true) + '    ',
								third_column: req.tot_better_price ? printPrice(subtotals.tot_better_price, 'USD', currency.name, true) : null,
								style: {borderTop: '1px solid #cdddeb'}
							})
						}
						{
							this.buildPricingRow({
								header: `Sales Tax / VAT`,
								// first_column: '  ' + printPrice(Math.ceil((req.tax_percent / 100) * req.tot_venue_price / (1 + (req.tax_percent / 100))), 'USD', currency.name, true) + '    ',
								// second_column: '  ' + printPrice(Math.ceil((req.tax_percent / 100) * req.tot_fluidmeet_price / (1 + (req.tax_percent / 100))), 'USD', currency.name, true) + '    ',
								// third_column: req.tot_better_price ? printPrice(Math.ceil((req.tax_percent / 100) * parseFloat(req.tot_better_price) / (1 + (req.tax_percent / 100))), 'USD', currency.name, true) : null,
								first_column: '  ' + printPrice(req.tot_venue_price - subtotals.tot_venue_price, 'USD', currency.name, true) + '    ',
								second_column: '  ' + printPrice(req.tot_fluidmeet_price - subtotals.tot_fluidmeet_price, 'USD', currency.name, true) + '    ',
								third_column: req.tot_better_price ? printPrice(req.tot_better_price - subtotals.tot_better_price, 'USD', currency.name, true) : null,
							})
						}
						{
							this.buildPricingRow({
								header: `Total`,
								first_column: '  ' + printPrice(req.tot_venue_price, 'USD', currency.name, true) + '    ',
								second_column: '  ' + printPrice(req.tot_fluidmeet_price, 'USD', currency.name, true) + '    ',
								third_column: req.tot_better_price ? printPrice(req.tot_better_price, 'USD', currency.name, true) : null,
								style: {borderTop: '1px solid #cdddeb'}
							})
						}

						</tbody>
					</table>

					<div className="pt-2 bb-1"/>
					<div className="row pt-3 mt-1">


						{(req.tot_venue_price != req.tot_fluidmeet_price) && (
							<>
								<div className="col-8">
									<div className="pb-2">
										<div className="reserv-subtitle">Amount saved by using fluidmeet</div>
									</div>
								</div>
								<div className="col-4">
									<div className="pb-2">
										<div className="reservation-text text-right" style={{color: green[400]}}>
											{printPrice(req.tot_venue_price - (req.tot_better_price || req.tot_fluidmeet_price), 'USD', currency.name, true)}
										</div>
									</div>
								</div>

								<div className="col-8">
									<div className="pb-2">
										<div className="reserv-subtitle">Percentage saved</div>
									</div>
								</div>
								<div className="col-4">
									<div className="pb-2">
										<div className="reservation-text text-right" style={{color: green[400]}}>
											{doubleToPercentage(1 - (req.tot_better_price || req.tot_fluidmeet_price) / req.tot_venue_price)}
										</div>
									</div>
								</div>
							</>
						)
						}
						<div className="col-8">
							<div className="pb-2">
								<div className="reserv-subtitle">Total excluding VAT</div>
							</div>
						</div>
						<div className="col-4">
							<div className="pb-2">
								<div className="reservation-text text-right">
									{printPrice((req.tot_better_price || req.tot_fluidmeet_price) / (1 + 0.01 * vat), 'USD', currency.name, true)}
								</div>
							</div>
						</div>
						{(req.tot_better_price) ?
							<>
								<div className="col-8">
									<div className="pb-2">
										<div className="reserv-subtitle">Improved Fluidmeet Price</div>
									</div>
								</div>
								<div className="col-4">
									<div className="pb-2">
										<div className="reservation-text text-right">
											{printPrice(req.tot_better_price, 'USD', currency.name, true)}
										</div>
									</div>
								</div>
							</>
							: (
								<>
									<div className="col-8">
										<div className="pb-2">
											<div className="reserv-subtitle">Total Fluidmeet Price</div>
										</div>
									</div>
									<div className="col-4">
										<div className="pb-2">
											<div className="reservation-text text-right">
												{printPrice(req.amount_due, 'USD', currency.name, true)}
											</div>
										</div>
									</div>
								</>
							)
						}
						{(req.payment_status === 'paid') &&
						<>
							<div className="col-8">
								<div className="pb-2">
									<div className="reserv-subtitle">Amount Paid</div>
								</div>
							</div>
							<div className="col-4">
								<div className="pb-2">
									<div className="reservation-text text-right">
										<b>{printPrice((req.amount_due), 'USD', currency.name, true)}</b>
									</div>
								</div>
							</div>
						</>
						}


					</div>

					{
						(!req.expired || req.expired === 'no') && (

							(req.quote_status === 'accepted' && (new Date()) <= (new Date(req.start_date))) ? (
								<>
									{!req.payment_status &&
									<div className="w100 pt-3 d-flex flex-column flex-wrap align-items-start">
										{
											(paymentIntent) ? (
												<div className="col-12 mt-4">
													<FMPayment paymentIntent={paymentIntent}
													           redirectUrl={this.getVerificationUrl()}
													           allowBillingDetails={false}/>
												</div>
											) : (


												<>
													{
														Boolean(req.discount_percentage && req.discount_percentage != '0' && apply_discount) &&
														<div className="col-12 note-positive px-0">
															Pay online with fluidmeet and you'll receive an
															additional {req.discount_percentage}%({printPrice(parseFloat(req.amount_due) * 0.01 * parseFloat(req.discount_percentage), "USD", currency.name, true)})
															discount on us.
														</div>
													}
													<button type="button"
													        className={"btn btn-primary send-btn mb-2  col-12 col-sm-6 col-md-5  " + ((requestsLoading[req.quote_id + BOOK]) ? 'ld-btn' : '')}
													        onClick={() => this.handlePayOnline(req)}>
														{(requestsLoading[req.quote_id + BOOK]) ? (
															<>
																<FontAwesomeIcon icon={faCircleNotch}
																                 spin={true}/> loading
															</>
														) : (
															<span>
													{'Pay '}
																{Boolean(apply_discount && req.discount_percentage && req.discount_percentage !== '0') &&
																<strong>
																	{printPrice((req.tot_better_price || req.tot_fluidmeet_price) * (1 - 0.01 * ((req.discount_percentage && apply_discount) ? parseFloat(req.discount_percentage) : 0)), 'USD', currency.name, false) + " "}
																</strong>
																}
																Online
												</span>
														)
														}
													</button>

													<button type="button"
													        className={"btn btn-primary send-btn mb-2  col-12 col-sm-6 col-md-5 " + ((requestsLoading[req.quote_id + 'paid to venue']) ? 'ld-btn' : '')}
													        onClick={() => this.handleQuotePaid(req)}>
														{(requestsLoading[req.quote_id + 'paid to venue']) ? (
															<>
																<FontAwesomeIcon icon={faCircleNotch}
																                 spin={true}/> loading</>
														) : (
															<span>
													{'Pay '}
																{Boolean(apply_discount && req.discount_percentage && req.discount_percentage !== '0') &&
																<strong>
																	{" " + printPrice(req.tot_better_price || req.tot_fluidmeet_price, 'USD', currency.name, true) + " "}
																</strong>
																}
																at The Venue
												</span>
														)
														}
													</button>
												</>
											)
										}
										<div className="col-12 px-0 d-flex flex-row justify-content-end">
											<div className=" mb-2 mr-2 text-underline cursor-pointer"
											     onClick={() => this.toggleShowMore(CALL, req)}>
												<b>Call</b>
											</div>
											{
												(req.payment_status) || (
													<div className="mb-2 text-underline cursor-pointer "
													     onClick={() => this.toggleShowMore(VISIT_ARRANGED, req)}>
														<b>Arrange Visit</b>
													</div>
												)
											}
										</div>

									</div>
									}


									{/*<div className="w100 pt-3">*/}
									{/*	{(!req.payment_status && (*/}
									{/*		<button type="button" className="btn btn-primary send-btn mb-2 mr-2"*/}
									{/*		        onClick={() => this.toggleShowMore(BOOK, req)}>*/}
									{/*			Book*/}
									{/*		</button>*/}
									{/*	))}*/}
									{/*	<button type="button" className="btn btn-primary send-btn mb-2 mr-2"*/}
									{/*	        onClick={() => this.toggleShowMore(CALL, req)}>*/}
									{/*		Call*/}
									{/*	</button>*/}
									{/*	{*/}
									{/*		(req.payment_status) ? (<></>*/}
									{/*			// <a href={'https://host.fluidmeet.com/reservation/proposal-receipt/'+req.quote_id+'/'+user.id} rel='noreferrer' target='_blank'>*/}
									{/*			// <button type="button" className="btn btn-primary send-btn mb-2" onClick={this.showReceipt}>*/}
									{/*			//   View Receipt*/}
									{/*			// </button>*/}
									{/*			// </a>*/}
									{/*	) : (*/}
									{/*			<button type="button" className="btn btn-primary send-btn mb-2"*/}
									{/*			        onClick={() => this.toggleShowMore(VISIT_ARRANGED, req)}>*/}
									{/*				Arrange Visit*/}
									{/*			</button>*/}
									{/*		)*/}
									{/*	}*/}
									{/*</div>*/}

									{
										req.visit_req === 'yes' &&
										(
											<div className="pt-3 pb-1">
												<div className="reserv-subtitle">Visit Date & Time</div>
												<div
													className="reservation-text">{printDateTimeFromServer(req.visit_req_datetime)}</div>
											</div>
										)
									}

									{
										req.call_req === 'yes' && (
											<>
												{(!req.venue_contacted && (
													<div className="row m-0 pt-3">
														<div className="quote-status-cont">
															<h6 className="mt-2">Did the venue contact you ?</h6>
														</div>
														<div className="on-off-col display-flex">
															<div className="on-off">
                          <span className={(req.venue_contacted) ? "on-active border-r-6" : ""}
                                onClick={() => this.updateQuoteStatus(req, 'venue contacted')}>Yes</span>
															</div>
														</div>
													</div>
												))}
												{!req.payment_status && (
													<div className="row m-0 pt-3">
														<div className="quote-status-cont">
															<h6 className="mt-2">Did you reserve the space and pay at
																the venue
																?</h6>
														</div>
														<div className="on-off-col display-flex">
															<div className="on-off">
																<span
																	onClick={() => this.handleQuotePaid(req)}>Yes</span>
															</div>
														</div>
													</div>
												)
												}
											</>
										)
									}
							</>
						) : (DdrUtils.shouldShowAcceptAndReject(req)) ? (
							<div className="w100 pt-3">
								<button
									className={"btn btn-primary send-btn mb-2 mr-2 " + ((requestsLoading[req.quote_id + 'accepted']) ? 'ld-btn' : '')}
									onClick={() => this.updateQuoteStatus(req, 'accepted')}>
									{(requestsLoading[req.quote_id + 'accepted']) ? (<><FontAwesomeIcon
										icon={faCircleNotch} spin={true}/> loading</>) : 'Interested'}
								</button>
								<button className="btn btn-primary send-btn rd-btn mb-2 mr-2"
								        onClick={() => this.toggleShowMore((req.host_better_price_submit_date) ? DECLINED_BY_SEEKER : SEEKER_BETTER_PRICE_REQUESTED, req)}>
									Not Interested
								</button>
							</div>
							) : (req.seeker_better_price_request_date && req.better_price_status === DdrUtils.SEEKER_REQUESTED_BETTER_PRICE_KEY) ? (
									<div className={"note"}>Your Request for a better price has been sent to venue host. You
										can expect a response within a day.</div>
								)
								:
								(
									<></>
								)
						)
					}
					{(req.host_better_price_notes) &&
					<div className={"p-2 rounded"} style={{backgroundColor: blue[50]}}>
						<div style={{fontSize: 17, paddingBottom: 5, fontWeight: 500}}>Venue's comments
						</div>
						<div className={"px-2"}
						     style={{fontSize: 15}}>{req.host_better_price_notes}</div>
					</div>}
					{(req.seeker_better_price_notes) &&
					<div className={"p-2 rounded"} style={{backgroundColor: blue[50]}}>
						<div style={{fontSize: 17, paddingBottom: 5, fontWeight: 500}}>Your comments
						</div>
						<div className={"px-2"} style={{fontSize: 15}}>{req.seeker_better_price_notes}</div>
					</div>
					}

					{
						(req.reject_reason) ? (
							<>
								<div className="w100 pt-4">Decline reason: {req.reject_reason}</div>
								{(req.reject_note && (
									<div className="w100 pt-2">Decline note: {req.reject_note}</div>
								))}
							</>
						) : (req.expired === 'yes' && req.payment_status !== 'paid' && req.payment_status !== 'paid to venue') ? (
							<div className="form-error w100 pt-3">Proposal Expired!</div>
						) : (req.payment_status !== 'paid' && req.payment_status !== 'paid to venue') ? (
							<div className="row m-0 pt-4">
							<div className="reserv-id-cont">
								<span className='sec-header semi-bold'>Expiry:</span>
								{(req.expire_on) ? ' ' +	moment(req.expire_on, 'DD-MM-YYYY').format('MMM D, YYYY')	: ' -'}
							</div>
							<div className="quote-share-cont">
								<button className="btn space-btn m-0 p-0 float-right"
								        onClick={() => this.toggleShowMore(EMAIL_SHARE, req)}>
									Share
								</button>
							</div>
						</div>
					) : (<></>)}

					<div className="row m-0 pt-4">
						<div className="reserv-id-cont sec-header semi-bold">Notes:</div>
						<div className="quote-share-cont">
							<button className="btn space-btn m-0 p-0 float-right"
							        onClick={() => this.toggleShowMore(ADD_NOTE, req)}>
								Add Note
							</button>
						</div>
					</div>

					{
						(
							(notes) => {
								if (notes.length > 0) return (
									notes.map(item => (
										<div className="notes pt-2">
                      <span
	                      className="reserv-subtitle">{item.user_name} ({printDateFromDateTime(item.created_on)}): </span>
											{item.note}
										</div>
									))
								)
							}
						)(req.notes.filter((note) => (note.created_by == user?.id)))
					}
				</div>
			);
		});

		if (count === 0) {
			if (reservation.multiple) {
				elements.push(<h6 className="empty-title fz-18 pt-2 mt-4 mb-5">No Available quotes yet</h6>);
			} else {
				elements.push(
					<>
						<h4 className="empty-title fz-18 pt-2 mt-4 mb-1">
							{(better_price_requested) ? "Quote is no longer available" : "Venue have not submitted a quote yet"}
						</h4>
						{/*{Boolean(better_price_note) &&*/}
						{/*<h6 className="empty-title fz-18 pt-2 mt-4 mb-5">*/}
						{/*	<strong>Venue Comments:</strong><br/> {better_price_note}*/}
						{/*</h6>*/}
						{/*}*/}
					</>
				);
			}
		}

		return elements;
	}

	printSpaceDetails = (reservation, space, location, showMap) => {

		var elements: any = [];

		if (!space) return elements;

		elements.push(
			<Link to={'/space/' + space.workspace_id}>
				<div className="book-toprow mb-3">
					<div style={{height: (window.innerWidth > 440) ? 100 : 83}}>
						<div className="space-div" style={{flexDirection: "row"}}>
							<img className="space-resrv-img"
							     src={"https://fluidmeet.s3.amazonaws.com/venue_logos/" + space.image}/>
							<div className="space-details pt-0" style={{height: 'auto'}}>
								<div className="space-rate">
									<FontAwesomeIcon icon={faStar}
									                 style={{color: (space.workspace_rating && space.workspace_rating > 0) ? '#00a9e0' : '#E0E0E0'}}/>{" "}
									{
										(space.workspace_rating && space.workspace_rating > 0) ? (
											space.workspace_rating
										) : (
											// (space.date_added) ? (
											//   isSpaceOld(space.date_added)
											// ) : (
											""
											// )
										)
									}
									{(space.reviews > 0) ? (
										<span className="full">({space.reviews})</span>
									) : (
										<></>
									)}
								</div>
								<span className="space-location">{space.front_address}</span>
								<span className="space-title">{space.name}</span>
								{(showMap) ? (
									<div className="space-location">{printSpaceData(space.workspace_type_id).name}</div>
								) : (
									<div className="space-location">{space.venue_name}</div>
								)}
							</div>
						</div>
					</div>
				</div>
				{
					showMap && DdrUtils.shouldShowVenueDetails(reservation.requests?.[0]) &&
					<>
						<div className="space-text pt-1">{space.venue_type_name}</div>
						<div className="space-text">{space.venue_name}</div>
						<div className="space-text pb-3">{space.venue_address}</div>
					</>

				}
			</Link>
		);

		if (!showMap) return elements;

		elements.push(
			<div className="map-cont">
				{/*<LoadScript googleMapsApiKey='AIzaSyBDM449V7qE4OkyoV2zP3xm_VQIYDvrHVw'>*/}
				<GoogleMap
					options={{
						styles: styles,
						keyboardShortcuts: false,
						scaleControl: true,
						draggable: true,
						gestureHandling: "greedy",
						mapTypeControl: true,
						mapTypeControlOptions: {position: 1},
						zoomControl: true,
						zoomControlOptions: {position: 8},
						maxZoom: 11,
					}}
					mapContainerStyle={{height: "100%", width: "100%"}}
					zoom={12}
					center={location}>
					<Marker key={space.workspace_id} position={location} icon={markerIcon}/>
				</GoogleMap>
				{/*</LoadScript>*/}
			</div>
		);

		return elements;
	}

	infoSection = ({title, subtitle}) => (
		<div className="col-md-6 col-12">
			<div className="pb-2">
				<div className="reserv-subtitle">{title}</div>
				<div className="reservation-text">{subtitle}</div>
			</div>
		</div>
	)
	accommodationDay = (item, i) => {

		return (
			<AccordionController ref={this.accordionRefs[`accomm-day-${i}`]}>
				<Accordion

					id={`accomm-day-${i}`}
					key={i}
					classes={{
						root: 'accordionRoot'
					}}
					variant={'elevation'}
					defaultExpanded={i === 0}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon/>}
						aria-controls={`accom-${i}-content`}
						id={`accom-${i}-header`}
					>
						<div style={{
							color: '#212529',
							paddingBottom: 16,
							fontWeight: 600,
							fontSize: 16,
							marginTop: 12,
						}}>
							Day {i + 1} - {printDateFromDateTime(item.acc_date)}
						</div>
					</AccordionSummary>

					<AccordionDetails className={"d-flex flex-row flex-wrap"}>
						{
							[
								(item.single_room > 0) ? {
									title: "Single Rooms",
									subtitle: item.single_room + ' Room' + ((item.single_room > 1) ? 's' : '')
								} : null,
								(item.double_room > 0) ? {
									title: "Double Rooms",
									subtitle: item.double_room + ' Room' + ((item.double_room > 1) ? 's' : '')
								} : null,
								(item.twin_room > 0) ? {
									title: "Double Rooms",
									subtitle: item.twin_room + ' Room' + ((item.twin_room > 1) ? 's' : '')
								} : null,
								{
									title: "Breakfast Included",
									subtitle: (item.chk_bed_breakfast === 'yes') ? "Yes" : "No"
								},
							].filter(Boolean).map(this.infoSection)
						}
					</AccordionDetails>
				</Accordion>
			</AccordionController>
		)
	}
	eventDay = (item, i) => {
		let amenities = CATERED_AMENITIES
			.filter(({key}) => item['chk_' + key] == 'yes')
			.map(({key, label}) => (item[key + '_qty'] ?? '') + ' ' + label).join(', ')


		return (
			<AccordionController ref={this.accordionRefs[`event-day-${i}`]}>
				<Accordion
					id={`event-day-${i}`}
					key={i}
					classes={{
						root: 'accordionRoot'
					}}
					variant={'elevation'}
					defaultExpanded={i === 0}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon/>}
						aria-controls={`${i}-content`}
						id={`${i}-header`}
					>
						<div style={{
							color: '#212529',
							paddingBottom: 16,
							fontWeight: 600,
							fontSize: 16,
							marginTop: 12,
						}}>
							Event Day {i + 1} - {printDateFromDateTime(item.meeting_date)}
						</div>
					</AccordionSummary>
					<AccordionDetails className={'row pt-2'}>

						{
							[
								{title: 'Event Date', subtitle: printDateFromDateTime(item.meeting_date)},
								{title: 'Event Time', subtitle: printTimeRange(item.start_time, item.end_time, true)},
								{title: 'Layout', subtitle: getLayoutName(item.room_layout)},
								{title: 'Capacity', subtitle: item.participants},
								{
									title: 'Breakfast',
									subtitle:
										(item.chk_breakfast === 'yes') ?
											((item.rdo_breakfast_other === '') ? item.rdo_breakfast : item.rdo_breakfast_other) : '-'
								},
								{
									title: 'Tea & Coffee Break',
									subtitle:
										(item.chk_tea_coffee === 'yes') ?
											((item.rdo_tea_coffee_other === '') ?
												item.rdo_tea_coffee : item.rdo_tea_coffee_other)
											: '-'
								},
								{
									title: 'Lunch',
									subtitle:
										(item.chk_lunch === 'yes') ?
											[
												item.rdo_lunch_type,
												(item.rdo_lunch_other === '') ? item.rdo_lunch : item.rdo_lunch_other,
												(item.rdo_lunch_drinks_other === '') ? item.rdo_lunch_drinks : item.rdo_lunch_drinks_other
											].filter(Boolean).join(',') : '-'
								},
								{
									title: 'Dinner',
									subtitle: (item.chk_dinner === 'yes') ?
										(
											<>
												{item.rdo_dinner_type}{', '}
												{(item.rdo_dinner_other === '') ? item.rdo_dinner : item.rdo_dinner_other}{', '}
												{(item.rdo_dinner_drinks_other === '') ? item.rdo_dinner_drinks : item.rdo_dinner_drinks_other}
											</>
										) : '-'
								},
								{
									title: 'Drinks Reception',
									subtitle: (item.rdo_drinks_other !== '') ? item.rdo_drinks_other : '-'
								},
								{title: 'Amenities', subtitle: amenities},
							].map(this.infoSection)
						}


					</AccordionDetails>
				</Accordion>
			</AccordionController>
		);
	}

	render() {

		const {match, defCurrency} = this.props;
		const {
			reservation, space, venues, location, visitDate, visitTime, excludedDays,
			showReviews, showDetails, showSummary, showQuotes, showVenue, showMap, showMore,
			showMoreReq, errors, tempRating, rating, review, requestsLoading, reqAlert, apply_discount
		} = this.state;
		const user = getUser();
		const currency = getCurrency(defCurrency);

		if (!user) {
			resetUser();
			return (<Redirect to={{pathname: '/login', state: {prevLocation: this.props.location}}}/>);
		}

		if (!reservation) return null;

		if(reservation.requests[0].user_id != user.id) {
			/*resetUser();
			return (<Redirect to={{pathname: '/login', state: {prevLocation: this.props.location}}}/>);*/

			return (
				<div className="reserv-page">
					<div className="container-fluid full-height-div bg-white mt-80 h-z">
						<div>
							This Proposal Belongs to another user. Please login into your account to view it.
						</div>
					</div>
				</div>
			)
		}

		return (
			<>
				<div className="reserv-page">
					<ReceiptDialog ref={this.receiptDialog} id={user.id} receiptId={match.params.id}
					               onClose={this.clearActionFromQuery}/>

					<div className="container-fluid  bg-white mt-80 h-z">
						<div className="h-z content">
							<div className="resrv-p ps-50 pb-4 h-z">
								{/*<ReactJson src={reservation} collapsed={true}/>*/}
								<div
									className=" d-flex flex-row-reverse flex-wrap   align-items-top  justify-content-center"
									style={{position: 'relative',}}>

									{(reservation.multiple || space) && (
										<div id=""
										     className="reservation-space-stick mt-md-10 col-12 col-md-4 px-2 mb-0"
										     style={{
											     top: 100,
											     position: 'sticky',
											     transition: '0.8s',
											     zIndex: 10,
											     height: 'fit-content',
										     }}>

											{(reservation.multiple) ? this.printMultipleProposal(currency, this.props.location, reservation, venues, showSummary, false) : this.printSpaceDetails(reservation, space, location, true)}
										</div>
									)}


									<div
										className={clsx("page", (reservation.multiple || space) ? "col-12 col-md-8 px-md-5 px-0" : "container")}
										style={{transition: '0.6s'}}>
										<div className="h-z">

											<h1 className="page-lg-title pt-0 pb-4 h-z">
												<Link to='/reservations'>
													<FontAwesomeIcon icon={faArrowLeft}
													                 style={{
														                 color: '#000000',
														                 fontSize: 20,
														                 cursor: 'pointer',
														                 marginRight: 24,
														                 marginBottom: 1
													                 }}/>
												</Link>
												Catered Space Booking

											</h1>

											<div className="reservation-page mb-3">

												<div className="display-flex w100 mb-3 pb-3 bb-1">
													<div className="reserv-id-cont">
														<div
															className="reservation-text pt-2 pb-0">{'#' + (reservation.booking_number)}</div>
													</div>
													<div className="quote-status-cont">
														{
															(reservation.requests.length === 1) ? (
																DdrUtils.printQuoteStatus(reservation.requests[0], false)
															) : (
																DdrUtils.printQuoteStatus(getGenericQuoteStatus(reservation.requests), true)
															)
														}

													</div>
												</div>


												<div className="reserv-title-cont w100 pb-2 pb-2">
													<div
														className="reserv-title fz-18 pb-2">
														{
															(reservation.multiple) ? 'Multiple Quotes'
																: (reservation.pending) ? 'Online Quote'
																: (reservation.name && reservation.city) ? (
																	reservation.name + " - " + reservation.city)
																	: ('')
														}
													</div>
													<div className="reservation-text reserv-price">
														{(reservation.pending || (!reservation.multiple && reservation.quote_status === 'pending' && reservation.amount_due == 0)) ? (
															'-'
														) : (reservation.multiple) ? (
															getGenericQuotePrice(reservation.requests, true, currency.name)
														) : (
															printPrice((reservation.requests?.[0]?.payment_amount || reservation.amount_due), 'USD', currency.name, true)
														)}</div>
												</div>


												<>

													{checkGenericIsPaid(reservation.requests) && (
														<>
															<h4 className="section-head"
															    onClick={() => this.setState({showReviews: !showReviews})}>Review
																& Rating</h4>
															{showReviews && (
																<>
																	<div className='ratingContainerDiv'>
																		<div className='starContainer'>
																			{[...Array(5)].map((e, i) => (
																				<Star
																					key={i}
																					index={i}
																					size={20}
																					margin={2}
																					position={rating}
																					starSelectedInPosition={val => this.starSelected(val)}
																					fill={rating >= i + 1}
																				/>
																			))}
																		</div>
																		<div style={{flexGrow: 0, flexShrink: 1}}>
																			<div
																				className='review-text'>{reviews[rating - 1]}</div>
																		</div>
																	</div>
																	{review && (
																		<div className='reserv-text' style={{
																			paddingBottom: 10,
																			paddingLeft: 12,
																			paddingRight: 12
																		}}>{review}</div>
																	)}
																</>
															)}
														</>
													)}

													<h4 className="section-head">Event Details</h4>

													<div className="pr-3 pl-3">
														<div className="pt-3">
															<div className="row pt-2">
																<div className="col-md-6 col-12">
																	<div className="pb-2">
																		<div className="reserv-subtitle">Event Type
																		</div>
																		<div
																			className="reservation-text">{reservation.event_type}</div>
																	</div>
																</div>
																<div className="col-md-6 col-12">
																	<div className="pb-2">
																		<div className="reserv-subtitle">Approximate
																			Budget ({reservation.budget_type})
																		</div>
																		<div className="reservation-text">
																			{printPrice(reservation.budget, reservation.budget_currency, currency.name, true)}
																		</div>
																	</div>
																</div>
																<div className="col-md-6 col-12">
																	<div className="pb-2">
																		<div className="reserv-subtitle">Event Planner Required
																		</div>
																		<div className="reservation-text">
																		{reservation.event_planner_required? "Yes":"No"}
																		</div>
																	</div>
																</div>
															</div>
															<div className="w100 pb-2">
																<div className="reserv-subtitle">Event Description
																</div>
																<div
																	className="reservation-text">{reservation.event_description}</div>
															</div>
														</div>

														{
															(reservation.meeting_data && reservation.meeting_data.length > 0) &&
															reservation.meeting_data.map(this.eventDay)
														}


														{(reservation.accomodation && reservation.accomodation.length > 0 && (
															<>
																<h5 className="pt-4 pb-2">Accommodation</h5>
																{reservation.accomodation.map(this.accommodationDay)}

															</>
														))}
													</div>


													{this.printMultipleProposal(currency, this.props.location, reservation, venues, showSummary, true)}

													{(reservation.multiple) ? (
														<h4 className="section-head"
														    onClick={() => this.setState({showQuotes: !showQuotes})}>Available
															Quotes
															({getAvalQuotesNum(reservation.requests, venues)} of {reservation.requests.length} quotes)</h4>
													) : (
														<h4 className="section-head"
														    onClick={() => this.setState({showQuotes: !showQuotes})}>Quote
															Details</h4>
													)}
													{(showQuotes && reservation.reservation_type !== 'lead' && (
														<div className="pr-3 pl-3">
															{this.printProposals(currency, user, reservation, venues, requestsLoading, showMap, apply_discount)}
														</div>
													))}
												</>

											</div>

											{/*{(space && (*/}
											{/*	<div className="bottom-cont">*/}
											{/*		<div id="bottom-stick"*/}
											{/*		     className="reservation-space-stick border-0 w-100">*/}
											{/*			<h4 className="section-head"*/}
											{/*			    onClick={() => this.setState({showVenue: !showVenue})}>Venue*/}
											{/*				Details</h4>*/}
											{/*			{(showVenue && (*/}
											{/*				<div className="pr-3 pl-3 mt-4">*/}
											{/*					{this.printSpaceDetails(reservation, space, location, true)}*/}
											{/*				</div>*/}
											{/*			))}*/}
											{/*		</div>*/}
											{/*	</div>*/}
											{/*))}*/}

										</div>


									</div>
								</div>


							</div>
						</div>
					</div>
				</div>

				{this.createMoreDialog()}

				{(reqAlert) ? (
					<div id="alerts">
						<div
							className={"alert alert-" + reqAlert.type + " alert-dismissible floating-alert fade show"}>
							<strong>{reqAlert.title}</strong> {reqAlert.message}
							<button type="button" className="btn-close"
							        onClick={() => this.setState({reqAlert: null})}>
								<FontAwesomeIcon icon={faTimes}/>
							</button>
						</div>
					</div>
				) : (<></>)}
				<div id={"receipt"}/>
				<Footer key="ReservationsDFooter"/>

			</>
		);
	}


}

export default connect((state: any, props) => ({
	defCurrency: state.settings.defCurrency,
}), null)(withAlertDialog(withRouter(DdrDetails)));
