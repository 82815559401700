import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {VenueAPI} from "src/APIs/venue/VenueAPI";
import {devLog} from "../Helpers";


export const refreshVenues = createAsyncThunk(
	'venues/fetch',
	async (_, thunkAPI) => {
		return await VenueAPI.fetchListings()
	}
)

export const venuesSlice = createSlice({
	name: 'venues',
	initialState: {
		data: null,
	},
	reducers: {
		updateVenues: (state, action) => {
			devLog('Redux::Updated Venues', action.payload)
			state.data = action.payload.data
		},
	},
	extraReducers: (builder) => {
		builder.addCase(refreshVenues.fulfilled,
			(state, action) => {
				devLog('Redux::Re-fetched Venues', action.payload)
				state.data = action.payload
			}
		)
	}
})

export const {updateVenues} = venuesSlice.actions

export default venuesSlice.reducer;

//
// export const VenuesConnector =  connect<{venues:any},{updateVenues,refreshVenues},{},DefaultRootState>(
// 	(state, props) => ({venues: state.venues.data,}),
// 	{ updateVenues,refreshVenues }
// );
