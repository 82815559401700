import React, {createRef, RefObject, useCallback} from "react";
import AlertSnackBar from "./AlertSnackBar";

const SnackbarContext = React.createContext<SnackbarContextType>({
	showSuccessMessage: () => {
	},
	showErrorMessage: () => {
	},
	showInfoMessage: () => {
	},
	showWarningMessage: () => {
	},
});
export type SnackbarContextType = {
	snackbar?: RefObject<AlertSnackBar>,
	showSuccessMessage: (message, title?) => void,
	showErrorMessage: (message, title?) => void,
	showInfoMessage: (message, title?) => void,
	showWarningMessage: (message, title?) => void,
}

const SnackbarProvider = ({children}) => {
	const snackbar = createRef<AlertSnackBar>();
	const showSuccessMessage = useCallback((message, title = null) => {
		snackbar.current?.setAlertData(
			{
				severity: 'success',
				message: `${message}`,
				timeout: 7000,
				title,
			}
		)
		snackbar.current?.open()
	}, [snackbar])
	const showErrorMessage = useCallback((message, title = null) => {
		snackbar.current?.setAlertData(
			{
				severity: 'error',
				message: `${message}`,
				timeout: 7000,
				title,
			}
		)
		snackbar.current?.open()
	}, [snackbar])
	const showWarningMessage = useCallback((message, title = null) => {
		snackbar.current?.setAlertData(
			{
				severity: "warning",
				message: `${message}`,
				timeout: 7000,
				title,
			}
		)
		snackbar.current?.open()
	}, [snackbar])
	const showInfoMessage = useCallback((message, title = null) => {
		snackbar.current?.setAlertData(
			{
				severity: "info",
				message: `${message}`,
				timeout: 7000,
				title,
			}
		)
		snackbar.current?.open()
	}, [snackbar])
	return (

		<SnackbarContext.Provider value={{snackbar, showSuccessMessage, showErrorMessage,showWarningMessage,showInfoMessage}}>
			{children}
			<AlertSnackBar ref={snackbar}/>
		</SnackbarContext.Provider>
	);

}

function useSnackbar() {
	const context = React.useContext(SnackbarContext)
	if (!context) {
		throw new Error(
			`Snackbar cannot be used outside the SnackbarContext`,
		)
	}
	return context
}

export default SnackbarProvider;
export {useSnackbar, SnackbarProvider}
export const SnackbarConsumer = SnackbarContext.Consumer
