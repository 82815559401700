import { Box, Button, Theme, Typography } from "@material-ui/core";
import { Redirect, useParams } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { CASE_STUDIES } from "src/assets/case-studies";
import { blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";
// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import { RequestDemoPopup, RequestDemoPopupProps } from "./request-demo-popup";
import { useEffect, useState } from "react";
import { devLog } from "src/Helpers";

const useStyles = makeStyles((theme: Theme) => ({
	pdfBox: {
		width: "60%",
		height: props=> `calc( ${props?.pagesCount || 1} * 82.75vw)`,
		[theme.breakpoints.down("md")]: {
			width: "100%",
			height: props=> `calc( ${props?.pagesCount || 1} * 138vw)`,

		},
	},
	actionBox: {
		width: "60%",
		padding: "0px 3.5rem",
		[theme.breakpoints.down("md")]: {
			width: "100%",
			padding: "0px 1.5rem",
		},
	},
	callForActionText: {
		fontWeight: 600,
		color: "rgb(85,85,85)",
		[theme.breakpoints.down("md")]: {
			textAlign: "center",
		},
	},
	downloadButton: {
		color: "white",
		backgroundColor: "rgb(0,157,221)",
		borderRadius: "1rem",
		textTransform: "none",
		margin: "1rem 0px",
		alignSelf: "flex-end",

		[theme.breakpoints.down("md")]: {
			alignSelf: "center",
		},
	},
	CTAButton: {
		color: "white",
		backgroundColor: "rgb(0,157,221)",
		borderRadius: "1.5rem",
		fontSize: "1.5rem",
		textTransform: "none",
	},
}));
export const CaseStudiesPage = () => {
	const [option, setOption] = useState<RequestDemoPopupProps['option']>()
	const { key } = useParams();
	const { pdfBox, actionBox, callForActionText, downloadButton, CTAButton } = useStyles({pagesCount:CASE_STUDIES[key]?.pages ?? 1});
	useEffect(
		()=>{
			const timeout = setTimeout(()=>{
				setOption('request-demo')
			},120_000)
			return () => clearTimeout(timeout);
		}
		,[])
	devLog(CASE_STUDIES[key]);
	if (!CASE_STUDIES[key]) return <Redirect to={"/404"} />;
	return (
		<Box
			className={"mt-80 py-2 py-md-5 d-flex flex-column"}
			style={{ flex: "1 1 100%", justifyContent: "center", alignItems: "center" }}
		>
			{option && <RequestDemoPopup open={true} option={option} file={CASE_STUDIES[key].file} handleClose={()=>setOption(undefined)}/>}
			<Box className={pdfBox}>
				<iframe
					title={key}
					src={CASE_STUDIES[key].file + "#toolbar=0&statusbar=0&navpanes=0&scrollbar=0&view=fitH"}
					width="100%"
					height="100%"
					style={{
						overflow:'hidden !important',
						height:'100%',
						width:'100%',
					}}
				/>
			</Box>

			{/* <a href={"/case-studies/" + key + '.pdf'}>test</a> */}
			<Box className={actionBox}>
				<Typography className={callForActionText} variant="h5">
					Interested in knowing more on how fluidmeet can support your property?
					<br />
					Schedule your free 15min live demo.
				</Typography>
				<Box className="d-flex flex-column justify-content-center align-items-center py-2">
					<Button variant="contained" disableElevation className={CTAButton} 	 onClick={()=>{setOption('request-demo')}}>
						Request My Free Demo
					</Button>
					<Button 
					variant="contained"
					 disableElevation 
					 className={downloadButton}
					 onClick={()=>{setOption('download')}}
					>
						<GetAppRoundedIcon />
						Download
					</Button>
				</Box>
			</Box>
		</Box>
	);
};
CaseStudiesPage.route = "/case-studies/:key";
