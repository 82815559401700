import React, {Component, Suspense} from 'react';
import ReactLoading from 'react-loading';
import TagManager from 'react-gtm-module';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import Home from "./components/Home";
import Search, {SearchPage} from "./components/SearchPage";
import PageNotFound from "./components/scenes/PageNotFound";
import LogIn from "./components/LogIn/LogIn";
import Terms from "./components/Terms/Terms";
import Privacy from "./components/Terms/Privacy";
import Cancellation from "./components/Terms/Cancellation";
import Contact from "./components/Contact";
import AboutUs from "./components/AboutUs";
import Profile from "./components/seeker/Profile";
import Footer from "./components/organisms/Footer";
import NavBar from "./components/atoms/NavBar";
import Favourites from "./components/scenes/Favourites";
import BusinessCenters from "./components/BusinessCenters";
import BusinessSetup from "./components/BusinessSetup";
// import ServicedOffices from "./components/ServicedOffices";
// import BanquetEventHalls from "./components/BanquetEventHalls";
import HostYourSpace from "./components/scenes/HostYourSpace";
import BecomeAHost from "./components/scenes/BecomeAHost";
import Workspace from "./components/Workspace";
import WorkspaceBooking from "./components/seeker/WorkspaceBooking";
import WorkspaceLTBooking from "./components/seeker/WorkspaceLTBooking";
import WorkspaceBookingResponse from "./components/seeker/WorkspaceBookingResponse";
import Reservations from "./components/seeker/reservations/list/Reservations";
import USALaunch from "./components/USALaunch";
import ReservationDetails from "./components/seeker/reservations/details/ReservationDetails";
import {LinkedInPopUp} from "react-linkedin-login-oauth2";
import Blog from "./components/Blog";
import ReactGA from 'react-ga-neo';
import store from './redux/Store'
import {
    devLog,
    fixBookingError,
    getCurrencies,
    getOldCurrency,
    getUser,
    getUserCountry,
    setCurrency,
    updateCurrencyList,
    updateVatPercentages
} from "./Helpers";
import {connect, Provider} from "react-redux";
import {updateDefCurrency} from './redux/SettingsSlice';
import {AccessWrapper, HostDashboardRoutes} from "./components/host/host-dashboard/host-dashboard-routes";
import {PaymentVerification} from "./components/payment-verfication";
import DdrDetails from "./components/seeker/reservations/details/ddr-details";
import SnackbarProvider from "./components/organisms/snackbar";
import AlertDialogProvider from "./components/organisms/alert-dialog";
import {ThemeProvider} from "@material-ui/styles";
import {fluidmeetMaterialTheme} from "./styles/muiTheme";
import {ShortTermBooking, ShortTermBookingPage} from "src/components/seeker/booking/short-term-booking";
import {RequestForProposal} from "./components/seeker/booking/request-for-proposal";
import { CaseStudiesPage } from './components/scenes/case-studies';
import { Box } from '@material-ui/core';
const ServicedOffices = React.lazy(() => import('./components/ServicedOffices'));
const BanquetEventHalls = React.lazy(() => import('./components/BanquetEventHalls'));
ReactGA.initialize("G-822NW6QZ69");
//ReactGA.pageview(window.location.pathname + window.location.search);
// import TagManager from 'react-gtm-module';
// const tagManagerArgs = {
//   gtmId: 'GTM-WVT7W56'
// }
// TagManager.initialize(tagManagerArgs);
export default class App extends Component {
    
    componentDidMount() {
        
        const user = getUser();
        if (user) {
            ReactGA.set({userId: user.id});
        }
        fixBookingError();
        
        updateCurrencyList();
        updateVatPercentages();
    }
    
    render() {
        return (
            <ThemeProvider theme={fluidmeetMaterialTheme}>
                <AlertDialogProvider>
                    <SnackbarProvider>
                        <Provider store={store}>
                            <ConnectedRootView/>
                        </Provider>
                    </SnackbarProvider>
                </AlertDialogProvider>
            </ThemeProvider>
        );
  }
}

class RootView extends Component {

  componentDidMount() {

    const { updateDefCurrency } = this.props

    const oldCurrency = getOldCurrency();
    if (oldCurrency) {
      setCurrency(oldCurrency);
    }

    getUserCountry().then(country => {
      const currencyList = getCurrencies();
      if (country) {
        const defCurr = currencyList.find(x => x.country === country);
        if (defCurr) {
            devLog('default currency', defCurr.name);
            updateDefCurrency(defCurr.name);
          setCurrency(defCurr.name);
        } else {
            devLog('default USD');
            updateDefCurrency('USD');
          setCurrency('USD');
        }
      } else {
          devLog('default AED');
          updateDefCurrency('AED');
        setCurrency('AED');
      }
    });
  }

  render() {

    return (
      <Router>
        <Switch>
    
            <Route
                key="Search-CityType"
                path={SearchPage.route}
                render={(props) => {
                    document.title = "Fluidmeet | The Premier Market Place For Serviced Offices, Meeting Rooms, Banque.";
                    return (
                        <>
                            <NavBar key={"search" + props.location.pathname + props.location.search} fullSearch={true}
                                    match={props.match}/>
                            <Search key={props.location.pathname + props.location.search} match={props.match}
                                    location={props.location}/>
                        </>
                    );
                }} exact/>

          <Route
              key="Space"
              path="/space/:id"
              render={(props) => {
              return (
                <Workspace key={"space" + props.match.params.id} match={props.match} location={props.location}/>
              );
              }} exact/>
    
            <Route
                key="payment-verification"
                path={PaymentVerification.route}
                render={(props) => {
                    return (
                        <>
                            <PaymentVerification/>
                            <Footer smallNav={true}/>
                        </>
                    );
                }} exact/>
            <Route
                key="SpaceBooking"
                path={ShortTermBooking.route}
                render={(props) => {
                    console.log({page: 'st'})
                    return (
                        <AccessWrapper access={"seeker-only"}>
                            <ShortTermBookingPage match={props.match} location={props.location}/>
                        </AccessWrapper>
                    );
                }}/>
    
            <Route
                key="SpaceRequestBookingVerify"
                path="/booking/:type/:id/verify"
                render={(props) => {
                    return (
                        <>
                            <WorkspaceBookingResponse match={props.match} location={props.location} page='verify'
                                                      generic={props.match.params.id === 'generic'}/>
                            <Footer smallNav={true}/>
                        </>
                    );
                }} exact/>
    
            <Route
                key="SpaceRequestBookingStatus"
                path="/booking/:type/:id/:page"
                render={(props) => {
                    const params = props.match.params;
                    if (params.page === 'success' || params.page === 'error') {
                        return (
                            <>
                                <WorkspaceBookingResponse match={props.match} location={props.location}
                                                          page={props.match.params.page}
                                                          generic={params.id === 'generic'}/>
                                <Footer smallNav={true}/>
                            </>
                        );
                    }
                    return (<Redirect to='/error'/>);
                }} exact/>
    
            <Route
                key="RequestForProposal"
                path={RequestForProposal.route}
                render={(props) => {
                    const params = props.match.params;
                    return (<RequestForProposal match={props.match} location={props.location} history={props.history}
                                                generic={params.id === 'generic'} page={params.page}/>);
            
                }} exact/>
    
            <Route
                key="SpaceRequest"
                path="/request/:type/:id/:page"
                render={(props) => {
                    const params = props.match.params;
                    if (params.type === 'catered-banquet' || params.type === 'catered-meeting') {
                        return (<WorkspaceBooking match={props.match} location={props.location}
                                                  generic={params.id === 'generic'} page={params.page}/>);
                    }
                    if (params.type === 'lt-office' || params.type === 'lt-coworking') {
                        return (<WorkspaceLTBooking match={props.match} location={props.location}
                                                    generic={params.id === 'generic'} page={params.page}/>);
                    }
                    return (<Redirect to='/error'/>);
                }} exact/>
    
    
            <Route
                key="SpaceRequest"
                path="/request/:type/:id"
                render={(props) => {
                    const params = props.match.params;
                    const isGeneric = (params.id === 'generic' || params.id === 'generic?source=search' || params.id === 'generic?source=details');
                    if (params.type === 'catered-banquet' || params.type === 'catered-meeting') {
                        return (<WorkspaceBooking match={props.match} location={props.location} generic={isGeneric}
                                                  page='event'/>);
                    }
                    if (params.type === 'lt-office' || params.type === 'lt-coworking') {
                        return (<WorkspaceLTBooking match={props.match} location={props.location} generic={isGeneric}
                                                    page='contact'/>);
              }
              return (<Redirect to={'/' + params.type}/>);
            }} exact/>

          <Route
            key="BecomeAHost"
            path="/list-my-space"
            render={(props) => {
              document.title = "Fluidmeet | Become a host";
              return (
                <>
                  <NavBar hideSearch={true} hostBtn={true}/>
                  <BecomeAHost match={props.match} location={props.location}/>
                  <Footer/>
                </>
              );
            }} exact/>

          <Route
            key="HostYourSpace"
            path="/host-application"
            render={(props) => {
              document.title = "Fluidmeet | List Your Space";
              return (
                <>
                  <NavBar hideSearch={true} hideHostBtn={true} smallLogo={true} progress={1} pageTitle="List space: Venue type"/>
                  <HostYourSpace page="type" match={props.match} location={props.location}/>
                </>
              );
            }} exact/>

          <Route
            key="HostYourSpace"
            path="/host-application/info"
            render={(props) => {
              document.title = "Fluidmeet | List Your Space: Info";
              return (
                <>
                  <NavBar hideSearch={true} hideHostBtn={true} smallLogo={true} progress={25} pageTitle="List space: Venue info"/>
                  <HostYourSpace page="info" match={props.match} location={props.location}/>
                </>
              );
            }} exact/>

          <Route
            key="HostYourSpace"
            path="/host-application/location"
            render={(props) => {
              document.title = "Fluidmeet | List Your Space: Location";
              return (
                <>
                  <NavBar hideSearch={true} hideHostBtn={true} smallLogo={true} progress={50} pageTitle="List space: Venue location"/>
                  <HostYourSpace page="location" match={props.match} location={props.location}/>
                </>
              );
            }} exact/>

          <Route
            key="HostYourSpace"
            path="/host-application/contact-info"
            render={(props) => {
              document.title = "Fluidmeet | List Your Space: Contact Info";
              return (
                <>
                  <NavBar hideSearch={true} hideHostBtn={true} smallLogo={true} progress={75} pageTitle="List space: Venue contact info"/>
                  <HostYourSpace page="contact" match={props.match} location={props.location}/>
                </>
              );
            }} exact/>

          <Route
            key="HostYourSpace"
            path="/host-application/notes"
            render={(props) => {
              document.title = "Fluidmeet | List Your Space: Notes";
              return (
                <>
                  <NavBar hideSearch={true} hideHostBtn={true} smallLogo={true} progress={98} pageTitle="List space: Notes"/>
                  <HostYourSpace page="notes" match={props.match} location={props.location}/>
                </>
              );
            }} exact/>

          <Route
            key="HostYourSpace"
            path="/host-application/success"
            render={(props) => {
              document.title = "Fluidmeet | List Your Space";
              return (
                <>
                  <NavBar hideSearch={true} hideHostBtn={true} smallLogo={true} progress={100} pageTitle="List space"/>
                  <HostYourSpace page="success" match={props.match} location={props.location}/>
                </>
              );
            }} exact/>

          <Route
            key="BusinessSetup"
            path="/business-setup/:page"
            render={(props) => {
              document.title = "Fluidmeet | Business Setup";
              return (
                <>
                  <NavBar/>
                  <BusinessSetup match={props.match} location={props.location}/>
                  <Footer/>
                </>
              );
            }} exact/>

          <Route
            key="BusinessSetup"
            path="/business-setup"
            render={(props) => {
              document.title = "Fluidmeet | Business Setup";
              return (
                <>
                  <NavBar/>
                  <BusinessSetup match={props.match} location={props.location}/>
                  <Footer/>
                </>
              );
            }} exact/>



          <Route
            key="ServicedOffices"
            path="/serviced-offices"
            render={(props) => {
              document.title = "Fluidmeet | Serviced Offices";
              return (
                <>
                  <Suspense fallback={<center><ReactLoading type={'balls'} color={'#00a9e0'} height={'3%'} width={'3%'} /></center>}>
                  <ServicedOffices match={props.match} location={props.location}/>
                  </Suspense>
                </>
              );
            }} exact/>
          <Route
            key="BanquetEventHalls"
            path="/banquet-event-halls"
            render={(props) => {
              document.title = "Fluidmeet | Banquet & Event Halls";
              return (
                <>
                  <Suspense fallback={<center><ReactLoading type={'balls'} color={'#00a9e0'} height={'3%'} width={'3%'} /></center>}>
                  <BanquetEventHalls match={props.match} location={props.location}/>
                  <Footer/>
                  </Suspense>  
                </>
              );
            }} exact/>

          <Route
            key="BusinessCenters"
            path="/business-centers"
            render={(props) => {
              document.title = "Fluidmeet | Business Centers";
              return (
                <>
                  <NavBar largeSearch={true}/>
                  <BusinessCenters match={props.match} location={props.location}/>
                  <Footer/>
                </>
              );
            }} exact/>

          <Route
            key="USALaunching"
            path="/launching-in-usa"
            render={(props) => {
              document.title = "Fluidmeet | Launching in USA";
              return (
                <>
                  <NavBar largeSearch={true}/>
                  <USALaunch match={props.match} location={props.location}/>
                  <Footer/>
                </>
              );
            }} exact/>

          <Route
            key="Terms"
            path="/terms"
            render={(props) => {
              document.title = "Fluidmeet | Terms of Use";
              return (
                <>
                  <NavBar hideSearch={true}/>
                  <Terms match={props.match} location={props.location}/>
                  <Footer/>
                </>
              );
            }} exact/>

          <Route
            key="Privacy"
            path="/privacy-policy"
            render={(props) => {
              document.title = "Fluidmeet | Privacy Policy";
              return (
                <>
                  <NavBar hideSearch={true}/>
                  <Privacy match={props.match} location={props.location}/>
                  <Footer/>
                </>
              );
            }} exact/>

          <Route
            key="Refund"
            path="/refund"
            render={(props) => {
              document.title = "Fluidmeet | Cancellation Policy";
              return (
                <>
                  <NavBar hideSearch={true}/>
                  <Cancellation match={props.match} location={props.location}/>
                  <Footer/>
                </>
              );
            }} exact/>

          <Route
            key="Reservations"
            path="/reservations"
            render={(props) => {
              document.title = "Fluidmeet | Reservations";
              return (
                <>
                  <NavBar/>
                  <Reservations match={props.match} location={props.location}/>
                </>
              );
            }} exact/>

          <Route
	          key="Reservation"
	          path="/reservation/:type/:id"
	          render={(props) => {
                  document.title = "Fluidmeet | Reservation";
                  let type = props.match.params.type;
                  return (
                      <>
                          <AccessWrapper access={"seeker-only"}>
                              <NavBar/>
                              {
                                  (type === 'proposal') ?
                                      (
                                          <DdrDetails match={props.match} location={props.location}/>
                                      )
                                      :
                                      (
                                          <ReservationDetails match={props.match} location={props.location}/>
                                      )
                              }
                
                          </AccessWrapper>
                      </>
		          );
	          }} exact/>

          <Route
            key="Favourites"
            path="/favourite-spaces"
            render={(props) => {
              document.title = "Fluidmeet | Favourite Spaces";
              return (
                <>
                  <NavBar hideSearch={true}/>
                  <Favourites page="spaces" match={props.match} location={props.location}/>
                </>
              );
            }} exact/>

          <Route
            key="Favourites"
            path="/favourite-services"
            render={(props) => {
              document.title = "Fluidmeet | Favourite Services";
              return (
                <>
                  <NavBar hideSearch={true}/>
                  <Favourites page="services" match={props.match} location={props.location}/>
                </>
              );
            }} exact/>

          <Route
            key="Contact"
            path="/contact-us"
            render={(props) => {
              document.title = "Fluidmeet | Contact Us";
              return (
                <>
                  <NavBar hideSearch={true}/>
                  <Contact match={props.match} location={props.location}/>
                  <Footer/>
                </>
              );
            }} exact/>

          <Route
            key="AboutUS"
            path="/about-us"
            render={(props) => {
              document.title = "Fluidmeet | About Us";
              return (
                <>
                  <NavBar hideSearch={true}/>
                  <AboutUs match={props.match} location={props.location}/>
                  <Footer/>
                </>
              );
            }} exact/>

          <Route
            key="Profile"
            path="/profile"
            render={(props) => {
              document.title = "Fluidmeet | Profile Details";
              return (
                <>
                  <NavBar hideSearch={true} authReq={true}/>
                  <Profile key="profile" match={props.match} page="profile" location={props.location}/>
                  <Footer/>
                </>
              );
            }} exact/>

          <Route
            key="Security"
            path="/security"
            render={(props) => {
              document.title = "Fluidmeet | Login & Security";
              return (
                <>
                  <NavBar hideSearch={true} authReq={true}/>
                  <Profile key="security" match={props.match} page="security" location={props.location}/>
                  <Footer/>
                </>
              );
            }} exact/>

          <Route
            key="LogIn"
            path="/LogIn"
            render={(props) => {
              document.title = "Fluidmeet | Log In";
              return (<LogIn match={props.match} location={props.location} page="signin"/>);
            }} exact/>

          <Route
            key="LogIn"
            path="/host-login"
            render={(props) => {
              document.title = "Fluidmeet | Host Log In";
              return (<LogIn match={props.match} location={props.location} page="host-signin"/>);
            }} exact/>

          <Route
            key="SignUp"
            path="/signup"
            render={(props) => {
              document.title = "Fluidmeet | Sign Up";
              return (<LogIn match={props.match} location={props.location} page="signup"/>);
            }} exact/>

          <Route
            key="Reset"
            path="/reset"
            render={(props) => {
              document.title = "Fluidmeet | Forgot Password";
              return (<LogIn match={props.match} location={props.location} page="reset"/>);
            }} exact/>

          <Route
            key="LinkedIn"
            path="/linkedin"
            component={LinkedInPopUp}
            exact/>

          <Route
            key="LinkedIn"
            path="/linkedin"
            component={LinkedInPopUp}
            exact/>

          <Route
            key="Blog"
            path="/blog"
            component={Blog}
            exact/>

          <Route key="venue-redirect" path="/venue" exact>
            <Redirect to='/list-my-space'/>
          </Route>

          <Route key="venue-redirect" path="/application/venue" exact>
            <Redirect to='/list-my-space'/>
          </Route>

          <Route key="venue-redirect" path="/venue/host-request" exact>
            <Redirect to='/host-application'/>
          </Route>

          <Route key="venue-redirect" path="/application/venue/host-request" exact>
            <Redirect to='/host-application'/>
          </Route>

          <Route key="venue-business-redirect" path="/venue/business-centers" exact>
            <Redirect to='/business-centers'/>
          </Route>

          <Route key="venue-business-redirect" path="/application/venue/business-centers" exact>
            <Redirect to='/business-centers'/>
          </Route>

          <Route key="how-to-book" path="/application/index/howToBook" exact>
            <Redirect to='/'/>
          </Route>

          <Route key="terms-redirect" path="/application/index/terms" exact>
            <Redirect to='/terms'/>
          </Route>

          <Route key="privacy-policy-redirect" path="/application/index/privacy-policy" exact>
            <Redirect to='/privacy-policy'/>
          </Route>

          <Route key="refund-redirect" path="/application/index/refund" exact>
            <Redirect to='/refund'/>
          </Route>

          <Route key="forgetpwd-redirect" path="/application/index/forgetpwd" exact>
            <Redirect to='/reset'/>
          </Route>

          <Route key="forgetpwdreset-redirect" path="/application/index/forgetpwdreset" exact>
            <Redirect to='/reset'/>
          </Route>

          <Route key="login-redirect" path="/application/index/login" exact>
            <Redirect to='/login'/>
          </Route>

          <Route key="signup-redirect" path="/application/index/signup" exact>
            <Redirect to='/signup'/>
          </Route>

          <Route key="contact-redirect" path="/application/index/contact-us" exact>
            <Redirect to='/contact-us'/>
          </Route>

          <Route key="index-redirect" path="/application/index/index" exact>
            <Redirect to='/'/>
          </Route>

          {/*'application/index/price-agreement'*/}
          {/*'application/index/professional-agreement'*/}
          {/*'application/index/hourly-space-license-agreement'*/}
          {/*'application/index/monthly-space-license-agreement'*/}

          <Route
            key="City-Office"
            path="/:city/Serviced-Offices"
            render={(props) => {
              return (<Redirect to={'/search/' + props.match.params.city + '/office-spaces'}/>)
            }}
            exact/>

          <Route
            key="City-Banquet-And-Event-Halls"
            path="/:city/Banquet-And-Event-Halls"
            render={(props) => {
              return (<Redirect to={'/search/' + props.match.params.city + '/banquets-and-event-spaces'}/>)
            }}
            exact/>

          <Route
            key="City-Co-Working-Spaces"
            path="/:city/Co-Working-Spaces"
            render={(props) => {
              return (<Redirect to={'/search/' + props.match.params.city + '/co-working-spaces'}/>)
            }}
            exact/>

          <Route
            key="City-Meeting-Rooms"
            path="/:city/Meeting-Rooms"
            render={(props) => {
              return (<Redirect to={'/search/' + props.match.params.city + '/meeting-rooms'}/>)
            }}
            exact/>

          <Route
            key="workspace-redirect"
            path="/workspace/:type/:id"
            render={(props) => {
              return (<Redirect to={'/space/' + props.match.params.id}/>)
            }}
            exact/>

          <Route
            key="Home"
            path="/"
            render={(props) => {
              document.title = "Fluidmeet | The Premier Market Place For Serviced Offices, Meeting Rooms, Banques.";
              return (
                <>
                  <NavBar largeSearch={true}/>
                  <Home match={props.match} location={props.location}/>
                </>
              );
            }} exact/>
  
          {HostDashboardRoutes().map(route => route)}

          <Route
            key="case-studies"
            path={CaseStudiesPage.route}
            render={(props) => {
              document.title = "Fluidmeet | Case Studies";
              return (
                <Box class={'d-flex flex-column justify-content-between h-100 '}>
                  <NavBar hideSearch={true}/>
                  <CaseStudiesPage/>
                  <Footer/>
                </Box>
              );
            }} exact/>

          <Route
            key="Error"
            path="/error"
            render={(props) => {
              document.title = "Fluidmeet | Error 404";
              return (
                <>
                  <NavBar hideSearch={true}/>
                  <PageNotFound match={props.match} location={props.location}/>
                  <Footer/>
                </>
              );
            }} exact/>

          <Route key="error404-redirect" path="/error/404" exact>
            <Redirect to='/error'/>
          </Route>

          <Route key="error-redirect" path="/error/index" exact>
            <Redirect to='/error'/>
          </Route>

          <Route>
            <Redirect to='/'/>
          </Route>

        </Switch>
      </Router>
    );
  }
}

const ConnectedRootView = connect(null, { updateDefCurrency })(RootView);
