import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {getCurrencies, getCurrency, getUser, setCurrency} from "../../Helpers";
import FloatingAlert from "./FloatingAlert";
import {connect} from "react-redux";
import {updateDefCurrency} from "../../redux/SettingsSlice";
import play_store from "../../img/PlayStore.png";
import app_store from "../../img/AppStore.png";

class Footer extends Component {
  
  constructor(props) {
    super(props)

    this.state = {
      user: null,
      showCurrency: false,
      newLink: '',
      currencyList: getCurrencies(),
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (window.fcWidget) {
      const user = getUser();
      if (user) {
        window.fcWidget.setExternalId("user_"+user.user_id);
        window.fcWidget.user.setFirstName(user.first_name);
        window.fcWidget.user.setLastName(user.last_name);
        window.fcWidget.user.setEmail(user.email);
        if (user.phone) {
          window.fcWidget.user.setPhone(user.phone);
        }
      }
    }
  }

  componentDidMount() {
    this.setState({user: getUser()});
  }

  handleCurrencyUpdate = currency => {

    const { updateDefCurrency } = this.props;

    setCurrency(currency.name);
    updateDefCurrency(currency.name);
    this.toggleShowCurrency();
  }

  toggleShowCurrency = (showCurrency = false) => {

    if (showCurrency) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    this.setState({ showCurrency });
  }

  render() {

    const { smallNav, bottomPadding,defCurrency  } = this.props;
    const { reqAlert, user, currencyList, showCurrency } = this.state;
    
    const currency = getCurrency(defCurrency);
    return (
      <>

        <footer id="footer" className={"footer "+((bottomPadding) ? 'mobile-f' : '')}>
          <div className={(smallNav) ? "container-lg" : "container-fluid"}>

            {(window.innerWidth < 400) ? (
              <div className="row footer-text">
                <div className="col-2 p-0">
                  <ul>
                  {
                    (user) ? (
                      
                          (user.type === "host" ) ||
                          <>
                            <li><Link to='/profile'>Account</Link></li>
                            <li><Link to='/reservations'>Reservations</Link></li>
                            <li><Link to='/favourite-spaces'>Favourites</Link></li>
                          </>
                      
                    ) : (
                      <>
                        <li><Link to='/'>Home</Link></li>
                        <li><Link to='/signup'>Sign up</Link></li>
                        <li><Link to='/login'>Log in</Link></li>
                      </>
                    )
                  }
                  <li><Link to='/business-centers'>Business centers</Link></li>
                  </ul>
                </div>
                <div className="col-3 p-0">
                  <ul>
                    <li><Link to='/contact-us'>Contact us</Link></li>
                    <li><Link to='/about-us'>About us</Link></li>
                    <li><Link to='/list-my-space'>Become a host</Link></li>
                    <li><Link to='/business-setup'>Setup your business</Link></li>
                    <li><Link to='/serviced-offices'>Serviced Offices</Link></li>
                    <li><Link to='/banquet-event-halls'>Banquet & Event Halls</Link></li>
                    <li><a href='https://fluidmeet.com/blog'>Blog</a></li>
                  </ul>
                </div>

                <div className="col-3 p-0">
                  <ul>
                    <li><Link to='/search/Dubai/office-spaces'>Office spaces Dubai</Link></li>
                    <li><Link to='/search/Dubai/banquets-and-event-spaces'>Banquets & Event Spaces Dubai</Link></li>
                    <li><Link to='/search/Abu-Dhabi/banquets-and-event-spaces'>Banquets & Event Spaces in Abu Dhabi</Link></li>
                    <li><Link to='/search/Abu-Dhabi/office-spaces'>Office spaces Abu Dhabi</Link></li>
                  </ul>
                </div>

                <div className="col-3 p-0">
                      <center>
                        <ul>
                            <li>Download our app</li>
                            <li><Link to = {{ pathname: "https://apps.apple.com/us/app/fluidmeet/id1335336284?platform=iphone" }} target="_blank"><img alt='apps-logo'  className="apps-logo" src={app_store}/></Link></li>
                            <li><Link to = {{ pathname: "https://play.google.com/store/apps/details?id=com.fluidmeet.app1"    }} target="_blank"><img alt='apps-logo'  className="apps-logo" src={play_store}/></Link></li>
                        </ul>
                      </center>
                </div>
              </div>
            ) : (
              <div className="row footer-text">
                
                  {
                    (user) ? (
                      (user.type === "host" ) ||
                      <div className="col-1 p-0">
                      <ul>
                        <li><Link to='/profile'>Account</Link></li>
                        <li><Link to='/reservations'>Reservations</Link></li>
                        <li><Link to='/favourite-spaces'>Favourites</Link></li>
                      </ul>
                      </div>
                    ) : (
                      <div className="col-2 p-0">
                        <ul>
                          <li><Link to='/'>Home</Link></li>
                          <li><Link to='/signup'>Sign up</Link></li>
                          <li><Link to='/login'>Log in</Link></li>
                        </ul>
                      </div>
                    )
                  }
                
                {
                  (user && user.type === "host" ) ||
                  <div className="col-3 p-0">
                    <ul>
                      <li><Link to='/business-centers'>Business centers</Link></li>
                      <li><Link to='/list-my-space'>Become a host</Link></li>
                      <li><Link to='/business-setup'>Setup your business</Link></li>
                      <li><Link to='/serviced-offices'>Serviced Offices</Link></li>
                      <li><Link to='/banquet-event-halls'>Banquet & Event Halls</Link></li>
                    </ul>
                  </div>
                }
                <div className="col-3 p-0">
                  <ul>
                    <li><Link to='/search/Dubai/office-spaces'>Office spaces Dubai</Link></li>
                    <li><Link to='/search/Dubai/banquets-and-event-spaces'>Banquets & Event Spaces Dubai</Link></li>
                    <li><Link to='/search/Abu-Dhabi/banquets-and-event-spaces'>Banquets & Event Spaces in Abu Dhabi</Link></li>
                    <li><Link to='/search/Abu-Dhabi/office-spaces'>Office spaces Abu Dhabi</Link></li>
                  </ul>
                </div>
                <div className="col-2 p-0">
                  <ul>
                    <li><a href='https://fluidmeet.com/blog'>Blog</a></li>
                    {/*<li><Link to='/contact-us'>Help Center</Link></li>*/}
                    <li><Link to='/contact-us'>Contact us</Link></li>
                    <li><Link to='/about-us'>About us</Link></li>
                  </ul>
                </div>
                <div className="col-2 p-0">
                      <center>
                        <ul>
                            <li>Download our app</li>
                            <li><Link to = {{ pathname: "https://apps.apple.com/us/app/fluidmeet/id1335336284?platform=iphone" }} target="_blank"><img alt='apps-logo'  className="apps-logo" src={app_store}/></Link></li>
                            <li><Link to = {{ pathname: "https://play.google.com/store/apps/details?id=com.fluidmeet.app1"    }} target="_blank"><img alt='apps-logo' className="apps-logo" src={play_store}/></Link></li>
                        </ul>
                      </center>
                </div>
              </div>
            )}

            <div className="footer-long-divider" />

            <div className="row">
              <div className="footer-text">
                <span>© {new Date().getFullYear()} Fluidmeet, Inc. All rights reserved.</span>
                <span className="footer-divider"> </span>
              </div>
              <div className="footer-bottom">
                <Link to="/terms">Terms of Use</Link>
                <span className="footer-divider">|</span>
                <Link to="/privacy-policy">Privacy Policy</Link>
                <span className="footer-divider">|</span>
                <Link to="/refund">Cancellation Policy</Link>
              </div>
              {user && user.type === "host" ? null : <div className="currency" onClick={() => this.toggleShowCurrency(true)}>
                <div className="currency-label">{currency.icon} <span className="currency-name">{currency.name}</span></div>
              </div>}
              <div className="social">
                <a className="social-a first" href="https://www.facebook.com/fluidmeet" target="_blank">
                  <span className="facebook" />
                </a>
                <a className="social-a" href="https://twitter.com/fluidmeet" target="_blank">
                  <span className="twitter" />
                </a>
                <a className="social-a" href="https://www.instagram.com/fluidmeet" target="_blank">
                  <span className="instagram" />
                </a>
                <a className="social-a" href="https://www.linkedin.com/company/fluidmeet" target="_blank">
                  <span className="linkedin" />
                </a>
              </div>
            </div>
          </div>

        </footer>

        <div className={"more-filter fz-17 justify-content-center " + ((showCurrency) ? "show-flex" : "")}>
          <div ref={this.showCurrencyRef} className="more-box align-self-center space-page-box">
            <div className="more-header">
              <h5 className="center pr-2 pl-2">Currency</h5>
              <div className="dropdown-divider mt-4 mb-0" />
            </div>
            <div className="more-contain space-page-box-contain">
              <div className="form-general p-0">
                <div className="row mt-3 mb-4">
                  {currencyList.map(item => (
                      <div key={item.name} className="col-lg-4 col-md-6 col-12">
                        <div className={item.name == currency.name ? "currency-item-active" : "currency-item"}
                             onClick={() => this.handleCurrencyUpdate(item)}>
                          <div className="currency-title">{item.country}</div>
                          <div className="currency-subtitle">{item.name} - {item.icon}</div>
                        </div>
                      </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="more-bottom">
              <div className="dropdown-divider mb-3 mt-0" />
              <button className="cancelBtn mr-3" type="button" onClick={() => this.toggleShowCurrency()}>Close</button>
            </div>
          </div>
        </div>

        {(reqAlert && <FloatingAlert error={reqAlert} onClick={() => this.setState({reqAlert: null})} />)}
      </>
    );
  }
}

export default connect((state, props) => ({
  defCurrency: state.settings.defCurrency,
}), { updateDefCurrency })(Footer);

