import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {getSpacePrice, hasTerm, printMonth, printPrice, printSpacePricePer} from "../../Helpers";
import {RequestForProposal} from "../seeker/booking/request-for-proposal";
import {ShortTermBooking} from "../seeker/booking/short-term-booking";

export default class BottomBar extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  render() {
    
    const {id, space, service, term, food, currency, handleTermChange, toggleShowMore} = this.props;
    
    return (
        <div className="bottom-bar bg-white">
          <div className="container-lg prl-4">
          <div className="row">
            <div className="bar-dur-col">
              <div className="duration-btn">
                <select name="duration" id="duration" className="select" defaultValue={term} onChange={(e) => handleTermChange(e.target.value)}>
                  {(food === 'catered') ? (
                    (hasTerm(space, 'catered') && (
                      <option value="catered">F/B</option>
                    ))
                  ) : (
                    (hasTerm(space, 'monthly') && (
                      <option value="monthly">LT</option>
                    ))
                  )}
                  {(hasTerm(space, 'hourly') && (
                    <option value="hourly">ST</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="bar-rest-col">
              {(term !== 'catered' && (
                <div className="space-price">
                  <span className="price">
                    {printPrice(getSpacePrice(space, service, term), ((space.currency) ? space.currency : 'USD'), currency.name, true)}
                      </span>
                  {printSpacePricePer(space, service, term)}
                </div>
              ))}
              <div className={"space-review " + ((term === 'catered') ? "pt-3" : "")}>
                {(food === "catered") ? (
                  (term === 'catered') ? 'Room + F & B' : 'Room rental only'
                ) : (
                  (term === 'monthly') ? (
                    (space.term && space.term > 0) ? (
                      ((window.innerWidth < 441) ? '' : 'Long term') + ' ('+printMonth(space.term)+' min)'
                    ) : (
                      ((window.innerWidth < 441) ? '' : 'Long term') + ' (1 month min)'
                    )
                  ) : (
                    ((window.innerWidth < 441) ? '' : 'Short term') + ' (1 hour min)'
                  )
                )}
              </div>
              <button className="allprices-btn" onClick={() => toggleShowMore('allprices')}>Show all prices</button>
            </div>
            <div className="bar-btn-col">
            {(food === "catered") ? (
                (term === 'catered') ? (
                    <Link to={{
                      pathname: RequestForProposal.getRoute(id, space.workspace_type_id),
                      state: {space: space, term: term, food: food, service: service, source: 'details'}
                    }} className="link-btn">
                      {(this.state.width < 441 &&  (this.state.width > 316)) ? <button className="bar-btn ddr-request-a-quote-button-MBsized  w100">Request a Quote</button>  : (this.state.width < 316) ? <button className="bar-btn ddr-request-a-quote-button-small-MBsized w100">Request a Quote</button> : <button className="bar-btn ddr-request-a-quote-button w100">Request a Quote</button>}
                     
                    </Link>
                ) : (
                    <Link to={{
                      pathname: ShortTermBooking.getRoute(id, space.workspace_type_id),
                      state: {space: space, term: term, food: food, service: service, source: 'details'}
                    }} className="link-btn">
                      {(this.state.width < 441 &&  (this.state.width > 316)) ? <button className="bar-btn ddr-request-a-quote-button-MBsized  w100">Book now</button>  : (this.state.width < 316) ? <button className="bar-btn ddr-request-a-quote-button-small-MBsized w100">Book now</button> : <button className="bar-btn ddr-request-a-quote-button w100">Book now</button>}
                    </Link>
                )
              ) : (
                (term === 'monthly') ? (
                  <Link to={{pathname: '/request/'+(space.workspace_type_id === '4' ? 'lt-coworking' : 'lt-office')+'/'+id, state: {space: space, term: term, food: food, service: service, source: 'details'}}} className="link-btn">
                    {(this.state.width < 441 &&  (this.state.width > 316)) ? <button className="bar-btn lt-request-a-quote-button-MBsized  w100">Send Request</button>  : (this.state.width < 316) ? <button className="bar-btn lt-request-a-quote-button-small-MBsized w100">Send Request</button> : <button className="bar-btn lt-request-a-quote-button w100">Send Request</button>}
                  </Link>
                ) : (
                    <Link to={{
                      pathname: ShortTermBooking.getRoute(id, space.workspace_type_id),
                      state: {space: space, term: term, food: food, service: service, source: 'details'}
                    }} className="link-btn">
                      {(this.state.width < 441 &&  (this.state.width > 316)) ? <button className="bar-btn book-request-a-quote-button-MBsized  w100">Book now</button>  : (this.state.width < 316) ? <button className="bar-btn book-request-a-quote-button-small-MBsized w100">Book now</button> : <button className="bar-btn book-request-a-quote-button w100">Book now</button>}
                    </Link>
                )
              )}
            </div>
            {space.rti_id &&
              <div style={{padding: '18px 0px'}}>
                <i>
                  <span>This venue offers real time bookings. Space availability and bookings are instant.</span>
                </i>
              </div>
            }

          </div>
        </div>
      </div>
    );
  }
}
