import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

export default class FloatingAlert extends Component {
  
  render() {
    
    const {error, onClick} = this.props;
    
    return (
        <div id="alerts">
          <div className={"alert alert-" + error.type + " alert-dismissible floating-alert fade show"}>
          <strong dangerouslySetInnerHTML={{__html: error.title}}></strong> <span dangerouslySetInnerHTML={{__html: error.message}}></span>
          <button type="button" className="btn-close" onClick={onClick}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      </div>
    );
  }
}
