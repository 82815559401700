import MailIcon from '@material-ui/icons/Mail';
import React, {ReactElement} from "react";
import PersonIcon from '@material-ui/icons/Person';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import BookIcon from '@material-ui/icons/Book';
import RoomIcon from '@material-ui/icons/Room';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AuthAPI from "../../../APIs/AuthAPI";
import {ManageListings} from "./manage-listings/manage-listings";
import ManageReservations from "./manage-reservations/manage-reservations";
import {ManageHosts} from "./manage-hosts/manage-hosts";
import {ManageProfile} from "./manage-profile/manage-profile";
import {ReactComponent as HouseSvg} from '../../../img/house.svg';
import {ReactComponent as BookingsSvg} from '../../../img/bookings.svg';
import {ReactComponent as ProfileSvg} from '../../../img/profile.svg';
import {ReactComponent as HostsSvg} from '../../../img/hosts.svg';
import {ReactComponent as CalendarSvg} from '../../../img/calendar-days-regular.svg';
import {HostDashboardRoute} from "./consts";
import { ManageBookings } from './manage-bookings/manage-bookings';


export interface DrawerItem {
    key: string,
    type: "link"|"action"|"divider",
    icon?: React.ElementType | React.ComponentType | ReactElement ,
    name?: string  ,
    route?: string ,
    callback?:(args:any)=>any,
}


export const drawerItems:Array<DrawerItem> = [
    { key: ManageListings.key, type: "link" ,icon: <HouseSvg />,name: "Listings", route: `${HostDashboardRoute}${ManageListings.route}`},
    { key: ManageReservations.key, type: "link" ,icon: <BookingsSvg/>,name: "Reservations", route: `${HostDashboardRoute}${ManageReservations.route}`},
    { key: ManageHosts.key, type: "link" ,icon: <HostsSvg/>,name: "Hosts", route: `${HostDashboardRoute}${ManageHosts.route}`},
    { key: ManageProfile.key, type: "link" ,icon: <ProfileSvg/>,name: "Profile", route: `${HostDashboardRoute}${ManageProfile.route}`},
    { key: ManageBookings.key, type: "link" ,icon: <CalendarSvg/>,name: "Calendar", route: `${HostDashboardRoute}${ManageBookings.route}`},
    { key: "divider-1", type: "divider" } as DrawerItem,
    { key: "log-out", type: 'action',icon:<ExitToAppIcon/>,name:"Log out" , callback: ({component})=>{
        AuthAPI.signOut().then(r => {
            component.setState({user: null});
            component.props.history.push('/');
        })
        } } as DrawerItem,

];
