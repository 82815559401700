import {useEffect} from "react";
import {devLog} from "src/Helpers";

export function useStateLogger(state: any, tag: string = "") {

	useEffect(() => {
		devLog(tag, state)
	}, [state, tag])

}
