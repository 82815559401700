import clsx from "clsx";
import {CircularProgress} from "@material-ui/core";
import React from "react";

export function LoadingComponent({}) {

	return (
		<div className={clsx('d-flex col-12 justify-content-center')}>
			<CircularProgress className={clsx('mx-auto my-5')}/>
		</div>
	)
}
