import React, {Component} from 'react';
import {
	devLog,
	getCurrency,
	getUser,
	printBookingStatus,
	printDateFromDateTime,
	printPrice,
	printTimeRange
} from "../../../../Helpers";
import {Link, Redirect, withRouter} from "react-router-dom";
import multiProposal from '../../../../img/gray_logo.png';
import Footer from "../../../organisms/Footer";
import Bugsnag from "@bugsnag/js";
import Star from "../../../atoms/Star";
import {connect} from "react-redux";
import {ReservationsAPI} from "../../../../APIs/reservations/reservations-api";
import {FILTER_LIST} from "../../../host/host-dashboard/manage-reservations/consts";
import {ReservationsFilter} from "../../../host/host-dashboard/manage-reservations/components/reservations-filter";

import {checkGenericIsPaid, DdrUtils, getGenericQuotePrice, getGenericQuoteStatus} from "../../../../utils/ddr-utils";

const ReservationLink = ({reservation, children}) => {
	let link = '#';
	let id = reservation.reservation_id
	if (reservation.reservation_type === 'proposal') {
		if (reservation.quote_id) id = reservation.quote_id
		else {
			if (reservation.requests && reservation.requests.length > 0) {
				id = reservation.requests[0].quote_id
			}
		}
	}
	link = `/reservation/${reservation.reservation_type}/${id}`
	
	
	if (reservation.reservation_type === 'lead' && reservation.host_accepted != '1') {
		link = null
	}
	
	if (link) {
		return <Link to={link}>
			{children}
		</Link>
	} else {
		return <>{children}</>
	}
}

class Reservations extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			reservations: [],
			reqAlert: null,
			reqLoading: false,
		}
		
		this.fetchReservations = this.fetchReservations.bind(this);
		this.toggleMoreBtn = this.toggleMoreBtn.bind(this);
	}
	
	componentDidMount() {
		this.fetchReservations();
		//ReactGA.pageview(window.location.pathname + window.location.search);
	}
	
	toggleMoreBtn(id) {
		
		const dropdownDiv = document.getElementById('drop' + id);
		if (dropdownDiv.classList.contains('show')) {
			dropdownDiv.classList.remove('show');
		} else {
			dropdownDiv.classList.add('show');
		}
	}
	
	fetchReservations() {
		ReservationsAPI.fetchSeekerReservations()
			.then(
				(response) => {
					if (response) {
						this.setState({
							reservations: response.reservations,
							bookings: response.bookings,
							leads: response.leads,
							proposals: response.proposals
						});
					}
				}
			)
			.catch(
				(error) => {
					Bugsnag.notify(error);
					if (error.response && error.response.data.error) {
						devLog(error.response.data);
					} else {
						devLog(error);
					}
					this.setState({reservations: []});
				}
			)
		
	}
	
	printReservationDetails(item, currency) {
		
		return (
			<div className="reservation">
				
				{(item.multiple || !item.image) ? (
					<div className="reservation-img general-img">
						<img src={multiProposal}/>
					</div>
				) : (
					<img className="reservation-img"
					     src={"https://fluidmeet.s3.amazonaws.com/venue_logos/" + item.image}/>
				)}
				
				<div className="reservation-details">
					
					{(item.reservation_type === 'proposal') ? (
						<>
							{(item.pending) ? (
								<div className="reserv-text reserv-title">Online Quote</div>
							) : (item.multiple) ? (
								<div className="reserv-text reserv-title">Multiple Quotes ({item.requests.length})</div>
							) : (
								<div className="reserv-text reserv-title">{item.name} - {item.city}</div>
							)}
							<div
								className="reserv-text">{printDateFromDateTime(item.start_date)} {(item.start_date !== item.end_date && (' - ' + printDateFromDateTime(item.end_date)))}</div>
							<div
								className="reserv-text">{(item.meeting_days > 1) ? item.meeting_days + ' days' : '1 day'} - {item.event_type}</div>
						</>
					) : (item.reservation_type === 'lead') ? (
						<>
							{/*{(item.requests.length > 1) ? (*/}
							{/*  <div className="reserv-text reserv-title">Multiple Inquiries ({item.requests.length})</div>*/}
							{/*) : (*/}
							<div
								className="reserv-text reserv-title">{item.space_name} {(item.venue_city && (' - ' + item.venue_city))}</div>
							{/*)}*/}
							<div className="reserv-text">{printDateFromDateTime(item.start_date)}</div>
							<div className="reserv-text">Long Term Inquiry</div>
						</>
					) : (
						<>
							<div className="reserv-text reserv-title">{item.name} - {item.city}</div>
							<div className="reserv-text">{printDateFromDateTime(item.start_date_time)}</div>
							<div
								className="reserv-text">{printTimeRange(item.start_date_time, item.end_date_time)}</div>
						</>
					)}
					
					{(item.reservation_type === 'proposal') ? (
						<>
							{(item.requests.length === 1) ? (
								DdrUtils.printQuoteStatus(item.requests[0], false)
							) : (
								DdrUtils.printQuoteStatus(getGenericQuoteStatus(item.requests), true)
							)}
							{/*<div className="reserv-status reserv-type-ddr">Inquiry</div>*/}
						</>
					) : (item.reservation_type === 'lead') ? (
						<div
							className={"reserv-status " + ((item?.host_accepted == '1') ? 'reserv-status-green' : 'reserv-status-black')}>
							{(item?.host_accepted == '1') ? 'Approved' : 'Pending'}
						
						</div>
					) : (
						<>
							{printBookingStatus(item)}
							{/*<div className="reserv-status reserv-type-lt">Booking</div>*/}
						</>
					)}
				
				</div>
				
				<div className="reservation-price">
					{(item.reservation_type === 'proposal') ? (
						(item.requests.length > 1 || item.amount_due === 0) ? (
							<div
								className="reserv-text reserv-price pr-2">{getGenericQuotePrice(item.requests, false, currency.name)}</div>
						) : (
							<div
								className="reserv-text reserv-price pr-2">{printPrice(item.requests?.[0]?.payment_amount || item.amount_due, 'USD', currency.name, true)}</div>
						)
					) : (item.reservation_type === 'lead') ? (
						<></>
						// <div className="reserv-text reserv-price">-</div>
					) : (
						<div
							className="reserv-text reserv-price">{printPrice(item.payment_amount, item.payment_currency, currency.name, true)}</div>
					)}
				</div>
			</div>
		)
	}
	
	getFilteredList = () => {
		let filter = this.getFilter()
		devLog(filter, this.state[filter], this.state)
		// if(filter == 'bookings')devLog((this.state[filter] ?? []).map(item =>({status:item.status,reservation_status:item.reservation_status})) )
		return this.state[filter] ?? []
	}
	getFilter = () => {
		let query = new URLSearchParams(this.props.location.search)
		return query.get('filter') ?? FILTER_LIST[0].value
	}
	onFilterChange = (e) => {
		let {history, location} = this.props
		let filter = (e.target.value)?.toString() ?? FILTER_LIST[0].value
		history.push(location.pathname + `?filter=${filter}&page=${1}`)
	}
	
	
	render() {
		
		
		const {location, history, defCurrency} = this.props;
		const user = getUser();
		const currency = getCurrency(defCurrency);
		
		if (!user) return (<Redirect to={{pathname: '/login', state: {prevLocation: location}}}/>);
		
		return (
			<>
				
				<div className="container-fluid bg-white mt-80 pr-0 pl-0">
					<div className="container">
						<section className="page pt-4">
							<div className={'d-flex justify-content-between flex-row flex-wrap align-items-center'}>
								<h1 className="page-lg-title mt-4 mb-4">Reservations</h1>
									<ReservationsFilter onChange={this.onFilterChange} value={this.getFilter()} isSearch={false}/>
							</div>
							
							{/*<div className="nav nav-tabs page-nav mb-4">*/}
							{/*  <div className="nav-item">*/}
							{/*    <Link id="profile-tab" className="nav-link active" to='/profile'>All</Link>*/}
							{/*  </div>*/}
							{/*  <div className="nav-item">*/}
							{/*    <Link id="login-tab" className="nav-link" to='/security'>Bookings</Link>*/}
							{/*  </div>*/}
							{/*  <div className="nav-item">*/}
							{/*    <Link id="login-tab" className="nav-link" to='/security'>Inquiries</Link>*/}
							{/*  </div>*/}
							{/*  <div className="nav-item">*/}
							{/*    <Link id="login-tab" className="nav-link" to='/security'>Proposals</Link>*/}
							{/*  </div>*/}
							{/*</div>*/}
							
							<div className="tab-content">
								<div id="profile" className="tab-pane fade show active pb-4">
									
									{this.getFilteredList().map((item, i) => {
										
										return (
											<div key={i} className="position-relative">
												
												<ReservationLink reservation={item}>
													<div className="reserv-link">
														{this.printReservationDetails(item, currency)}
													</div>
												</ReservationLink>
												
												{((item.reservation_type === 'booking' && item.status === 'completed') || (item.reservation_type === 'proposal' && checkGenericIsPaid(item.requests))) && (
													<div className='ratingContainer'>
														<div className='starContainer'>
															{[...Array(5)].map((e, i) => (
																<Star
																	key={i}
																	index={i}
																	size={16}
																	margin={2}
																	position={item.reservation_rating ? item.reservation_rating : 0}
																	fill={(item.reservation_rating ? item.reservation_rating : 0) >= i + 1}
																/>
															))}
														</div>
													</div>
												)}
											</div>
										)
									})}
								
								</div>
							</div>
						
						</section>
					</div>
				</div>
				
				<Footer/>
			
			</>
		);
	}
}

export default connect((state, props) => ({
	defCurrency: state.settings.defCurrency,
}), null)(withRouter(Reservations));
