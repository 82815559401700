import React, {Component} from 'react';
import {
	createAlert,
	devLog,
	doubleToPercentage,
	formValidation,
	getApiLinkV2,
	getCurrency,
	getLayoutName,
	getToken,
	getUser,
	printAmenity,
	printBookingStatus,
	printDateFromDateTime,
	printDateTimeFromServer,
	printLink,
	printPrice,
	printSpaceData,
	printTimeRange,
	resetBookings,
	setProposalBooking,
	setUserName,
	setUserPhone,
	updateStartTime
} from "src/Helpers";
import {Link, Redirect, withRouter} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import queryString from "query-string";
import {faArrowLeft, faCircleNotch, faStar, faTimes} from "@fortawesome/free-solid-svg-icons";
import {GoogleMap, Marker} from "@react-google-maps/api";
import styles from "../../../../GoogleMapStyles.json";
import markerIcon from "../../../../img/blue-marker2.png";
import axios from "axios";
import {getAxios} from "src/Helpers";
import Footer from "../../../organisms/Footer";
import mapIcon from '../../../../img/map-pin.png';
import DatePicker from "react-datepicker";
import Bugsnag from "@bugsnag/js";
import moment from "moment";
import Star from "../../../atoms/Star";
import {connect} from "react-redux";
import {red} from "@material-ui/core/colors"
import ReceiptDialog from "../../../organisms/ReceiptDialog";
import {PaymentVerification} from "../../../payment-verfication";
import {getTypeTextKey} from "../../../host/host-dashboard/manage-listings/add-edit-workspaces/consts";
import {
	checkGenericIsPaid,
	DdrUtils,
	getAvalQuotesNum,
	getGenericQuotePrice,
	getGenericQuoteStatus,
	printSummaryQuoteStatus
} from "../../../../utils/ddr-utils";

const rightCellStyle = {
	borderLeft: '1px solid #cdddeb',
}
const leftCellStyle = {
	paddingRight: 15
}
const excludedDates = [];
// '10 Mar 2021',
// '11 Mar 2021',
// '12 May 2021',
// '13 May 2021',
// '14 May 2021',
// '15 May 2021',
// '18 Jul 2021',
// '19 Jul 2021',
// '20 Jul 2021',
// '21 Jul 2021',
// '22 Jul 2021',
// '09 Aug 2021',
// '10 Aug 2021',
// '18 Oct 2021',
// '19 Oct 2021',
// '30 Nov 2021',
// '1 Dec 2021',
// '2 Dec 2021',
// ];

const reviews = ['Terrible', 'Bad', 'Okay', 'Good', 'Great'];

const dateOptions = {year: 'numeric', month: 'short', day: 'numeric'};

class ReservationDetails extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			location: {lat: 25.1210779, lng: 55.2212647},
			showDetails: true,
			showReviews: true,
			showSummary: true,
			showQuotes: true,
			showVenue: true,
			rating: 0,
			tempRating: 0,
			review: null,
			showMoreReq: null,
			showMore: '',
			showMap: '',
			space: null,
			visitDate: null,
			visitTime: '',
			reservation: null,
			venues: null,
			reqAlert: null,
			excludedDays: {},
			errors: {},
			requestsLoading: {},
		}
		
		this.showMoreRef = React.createRef();
		this.receiptDialog = React.createRef();
		this.fetchReservation = this.fetchReservation.bind(this);
		this.updateQuoteStatus = this.updateQuoteStatus.bind(this);
		this.handleClickOut = this.handleClickOut.bind(this);
		this.toggleShowMore = this.toggleShowMore.bind(this);
		this.checkQuoteSelected = this.checkQuoteSelected.bind(this);
		this.handleSelectQuote = this.handleSelectQuote.bind(this);
		this.handleQuotePaid = this.handleQuotePaid.bind(this);
		this.handlePayOnline = this.handlePayOnline.bind(this);
		this.initDate = this.initDate.bind(this);
		this.onVisitTimeChange = this.onVisitTimeChange.bind(this);
		
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		
		if (this.props.location.search !== prevProps.location.search) {
			this.checkQuoteSelected();
			//ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}
	
	componentDidMount() {
		
		this.fetchReservation();
		document.addEventListener('mousedown', this.handleClickOut);
		//ReactGA.pageview(window.location.pathname + window.location.search);
	}
	
	componentWillUnmount() {
		
		window.removeEventListener('mousedown', this.handleClickOut);
	}
	
	checkQuoteSelected() {
		
		const search = this.props.location.search;
		if (search) {
			let query = queryString.parse(search);
			if (query.quote) {
				var quoteDiv = document.getElementById("quote-" + query.quote);
				if (quoteDiv)
					window.scrollTo(0, quoteDiv.offsetTop - 100);
			}
		}
	}
	
	handleClickOut(event) {
		
		if (this.showMoreRef.current != null && !this.showMoreRef.current.contains(event.target)) {
			this.toggleShowMore();
		}
	}
	
	handleSelectQuote(id) {
		
		const {location, history} = this.props;
		
		if (location.search && queryString.parse(location.search).quote === id) {
			this.checkQuoteSelected();
			return;
		}
		
		history.push(printLink(location.pathname, location.search, 'quote', id));
	}
	
	toggleShowMore(type = null, req = null) {
		
		if (type) {
			document.body.classList.add("no-scroll");
		} else {
			document.body.classList.remove("no-scroll");
		}
		if (type === 'visit arranged') {
			this.initDate(req);
		}
		this.setState({showMore: type, showMoreReq: req});
	}
	
	initDate(req) {
		
		const {reservation, space, venues} = this.state;
		const ws_avail = (reservation.multiple) ? venues[req.generic_venue_id].ws_avail : space.ws_avail;
		
		var excluded = {}
		Object.keys(ws_avail).forEach(day => {
			const val = ws_avail[day];
			if (val === "closed") {
				if (day === 'sunday') {
					excluded[0] = true;
				} else if (day === 'monday') {
					excluded[1] = true;
				} else if (day === 'tuesday') {
					excluded[2] = true;
				} else if (day === 'wednesday') {
					excluded[3] = true;
				} else if (day === 'thursday') {
					excluded[4] = true;
				} else if (day === 'friday') {
					excluded[5] = true;
				} else if (day === 'saturday') {
					excluded[6] = true;
				}
			}
		});
		
		const nextAvlDate = new Date();
		const dayIndex = nextAvlDate.getDay();
		const date = this.getNextAvlDay(excluded, dayIndex, 0);
		
		this.setState({visitDate: date, excludedDays: excluded,});
	}
	
	getNextAvlDay(excluded, dayIndex, counter) {
		
		if (dayIndex > 6) {
			dayIndex = dayIndex - 6;
		}
		if (counter > 6) {
			counter = counter - 6;
		}
		
		if (excluded[dayIndex]) {
			return this.getNextAvlDay(excluded, dayIndex + 1, counter + 1);
		} else {
			var newDate = new Date();
			newDate.setDate(new Date().getDate() + counter);
			return newDate;
		}
	}
	
	printTimeOptions(reservation, space, venues, req, visitDate) {
		
		const ws_avail = (reservation.multiple) ? venues[req.generic_venue_id].ws_avail : space.ws_avail;
		
		const dayIndex = visitDate.getDay();
		var day = '';
		if (dayIndex === 0) {
			day = 'sunday';
		} else if (dayIndex === 1) {
			day = 'monday';
		} else if (dayIndex === 2) {
			day = 'tuesday';
		} else if (dayIndex === 3) {
			day = 'wednesday';
		} else if (dayIndex === 4) {
			day = 'thursday';
		} else if (dayIndex === 5) {
			day = 'friday';
		} else if (dayIndex === 6) {
			day = 'saturday';
		}
		
		return updateStartTime(ws_avail, day);
	}
	
	onVisitTimeChange() {
		
		const visitTime = document.getElementById("visit_time").value;
		this.setState({visitTime: visitTime});
	}
	
	starSelected = rating => {
		const {history} = this.props;
		const {reservation} = this.state;
		if (reservation.reservation_rating != '0') return;
		this.setState({showMore: 'review', tempRating: rating, showMoreReq: null});
	};
	showReceipt = () => {
		devLog(this.receiptDialog)
		this.receiptDialog?.current?.open()
	};
	
	onStar = rating => {
		this.setState({tempRating: rating});
	};
	
	reviewCompleted = (rating, review) => {
		this.setState({rating, review});
	}
	
	onSubmitReview = e => {
		e.preventDefault();
		
		const data = new FormData(e.target);
		
		const {requestsLoading, reservation, tempRating, errors} = this.state;
		
		requestsLoading['review'] = true;
		this.setState({requestsLoading: requestsLoading});
		
		errors.message = formValidation('message', data.get("review"));
		
		Object.keys(errors).forEach(key => {
			if (!errors[key]) delete errors[key];
		});
		
		this.setState({errors: errors});
		if (Object.keys(errors).length > 0) return;
		
		this.setState({reqLoading: true});
		
		devLog(reservation.reservation_type, reservation.workspace_id, reservation.reservation_id, tempRating, data.get("review"));
		
		const token = getToken();
		if (!token) {
			this.setState({showMore: '', reqLoading: false});
			return;
		}
		;
		const url = getApiLinkV2('user/review');
		getAxios().post(url, {
			reservation_type: reservation.reservation_type,
			workspace_id: reservation.workspace_id,
			reservation_id: reservation.reservation_id,
			rating: tempRating,
			comment: data.get("review"),
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token,
			},
		}).then(response => {
			const requestsLoadings = this.state.requestsLoading;
			delete requestsLoadings['review'];
			this.setState({requestsLoading: requestsLoadings, rating: tempRating, review: data.get("review")});
			this.reviewCompleted(tempRating, data.get("review"));
			this.toggleShowMore();
		}).catch(error => {
			devLog(error)
			Bugsnag.notify(error);
			const requestsLoading = this.state.requestsLoading;
			delete requestsLoading['review'];
			this.setState({requestsLoading: requestsLoading});
		});
	}
	
	handlePayOnline(request) {
		
		const {defCurrency} = this.props;
		const {requestsLoading, reservation, space, venues} = this.state;
		const currency = getCurrency(defCurrency);
		
		requestsLoading[request.quote_id + 'book'] = true;
		this.setState({requestsLoading: requestsLoading});
		const url = getApiLinkV2('workspace/booking');
		const token = getToken();
		getAxios().post(url, {
				submit_new: true,
				reservation_type: 'proposal',
				quote_id: request.quote_id,
				currency: currency.name,
				tax_name: 'VAT',
				title: '',
				capacity: 1,
				workspace_id: request.workspace_id,
				start_date_time: request.start_date + ' 00:00:00',
				end_date_time: request.end_date + ' 00:00:00',
				amt_due_after_disc: request.amount_due,
				final_payment: request.amount_due,
				amount_due: request.amount_due,
				total_price: request.amount_due,
				immediate_capture: true,
				redirect_url: new URL(
					PaymentVerification.generatePath(
						{
							booking_type: "ddr",
							workspace_id: request.workspace_id,
							workspace_type: getTypeTextKey(space.workspace_type_id),
							event: 'verify',
						},
						request.quote_id
					),
					window.location.origin
				).toString(),
				space_type: space.workspace_type_id,
			},
			{
				headers: {
					'Cache-Control': 'no-cache',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token,
				},
			}).then(response => {
			
			if (response.data) devLog(response.data);
			
			if (response.data.payment_url) {
				
				request.order_id = response.data.booking_reference;
				request.payment_id = response.data.p_id;
				if (reservation.multiple) {
					request.venue = venues[request.generic_venue_id];
				} else {
					request.space = space;
				}
				// request.workspace_type = (request.workspace_type) ? request.workspace_type : 'banquet';
				resetBookings();
				setProposalBooking(request);
				
				const {requestsLoading} = this.state;
				delete requestsLoading[request.quote_id + 'book'];
				this.setState({requestsLoading: requestsLoading});
				
				window.open(response.data.payment_url, '_top');
			}
			
		}).catch(error => {
			
			Bugsnag.notify(error);
			devLog({error})
			
			if (error.response.data) devLog(error.response.data);
			
			createAlert(this, 'danger', (error.response && error.response.data.message) ? 'Error: ' : '', (error.response && error.response.data.message) ? error.response.data.message : error.message);
			
			const requestsLoading = this.state.requestsLoading;
			delete requestsLoading[request.quote_id + 'book'];
			this.setState({requestsLoading: requestsLoading});
		});
	}
	
	handleQuotePaid(request) {
		
		if (window.confirm('This will decline all other quotes and accept this quote')) {
			this.updateQuoteStatus(request, 'paid to venue');
		}
	}
	
	updateQuoteStatus(request, status, e = null) {
		
		const {defCurrency} = this.props;
		const {reservation, space, visitDate, visitTime: _visitTime, requestsLoading, errors} = this.state;
		const currency = getCurrency(defCurrency);
		const visitTime = document.getElementById("visit_time")?.value ?? _visitTime
		var reqData = {};
		
		if (e) {
			
			e.preventDefault();
			const data = new FormData(e.target);
			
			if (status === 'visit arranged') {
				
				reqData.fName = data.get("fname");
				reqData.lName = data.get("lname");
				reqData.email = data.get("email");
				reqData.phone = data.get("phone");
				reqData.visit_date_time = moment(visitDate).format('YYYY-MM-DD') + ' ' + visitTime;
				errors.fName = formValidation('name', reqData.fName);
				errors.lName = formValidation('name', reqData.lName);
				errors.email = formValidation('email', reqData.email);
				errors.phone = formValidation('phone', reqData.phone);
				
			} else if (status === 'add note') {
				
				reqData.note = data.get("note");
				errors.note = formValidation('note', reqData.note);
				
			} else if (status === 'email-share') {
				
				reqData.email = data.get("email");
				errors.email = formValidation('email', reqData.email);
				
			} else if (status === 'declined by seeker') {
				
				reqData.reject_reason = data.get("reject_reason");
				reqData.reject_note = data.get("reject_note");
				errors.reject_reason = formValidation('reject_reason', reqData.reject_reason);
				
			} else if (status === 'call requested') {
				
				reqData.phone = data.get("phone");
				errors.phone = formValidation('phone', reqData.phone);
				
			}
			
			Object.keys(errors).forEach(key => {
				if (!errors[key]) delete errors[key];
			});
			this.setState({errors: errors});
			if (Object.keys(errors).length > 0) return;
		}
		
		const user = getUser();
		const token = getToken();
		if (!user || !token) return;
		
		requestsLoading[request.quote_id + status] = true;
		this.setState({requestsLoading: requestsLoading});
		
		const url = getApiLinkV2('user/reservations/' + request.quote_id);
		
		getAxios().put(url, {
			reservation_type: 'proposal',
			option: status,
			email: reqData.email,
			first_name: reqData.fName,
			last_name: reqData.lName,
			note: reqData.note,
			visit_date_time: reqData.visit_date_time,
			rc_seeker_phone: reqData.phone,
			reject_reason: reqData.reject_reason,
			reject_reason_other: '',
			reject_note: reqData.reject_note,
			submit_new: true,
			title: 'paid to venue',
			capacity: 1,
			currency: currency.name,
			tax_name: 'VAT',
			venue_id: (reservation.multiple) ? request.generic_venue_id : space.venue_details_id,
			quote_id: request.quote_id,
			start_date: request.start_date,
			end_date: request.end_date,
			workspace_id: request.workspace_id,
			start_date_time: request.start_date + ' 00:00:00',
			end_date_time: request.end_date + ' 00:00:00',
			amt_due_after_disc: request.amount_due,
			amount_due: request.amount_due,
			total_price: request.amount_due,
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token,
			},
		}).then(response => {
			devLog({response});
			if (response.data.status === 'success') {
				devLog({status});
				const reservation = this.state.reservation;
				const req = reservation.requests.find(x => x.quote_id === request.quote_id);
				if (status === 'accepted') {
					req.quote_status = status;
				} else if (status === 'add note') {
					req.notes.push({
						user_name: user.first_name + " " + user.last_name,
						created_on: (new Date().toLocaleDateString("en-US", {})),
						note: reqData.note,
					});
				} else if (status === 'declined by seeker') {
					req.quote_status = status;
					req.reject_reason = reqData.reject_reason
					req.reject_note = reqData.reject_note
				} else if (status === 'visit arranged') {
					req.visit_req = 'yes';
					setUserName(reqData.fName, reqData.lName);
				} else if (status === 'call requested') {
					req.call_req = 'yes';
					setUserPhone(reqData.phone);
				} else if (status === 'venue contacted') {
					req.venue_contacted = 'yes';
				}
				this.setState({reservation: reservation});
				this.toggleShowMore();
				createAlert(this, 'success', '', response.data.message);
				if (status === 'paid to venue') {
					this.fetchReservation();
				}
			}
			
		}).catch(error => {
			
			Bugsnag.notify(error);
			devLog(`updateQuoteStatus :: ${status}`, {error});
			this.toggleShowMore();
			createAlert(this, 'danger', (error.response && error.response.data.error) ? 'Error: ' : '', (error.response && error.response.data.error) ? error.response.data.error : error.message);
		}).then(() => {
			const requestsLoading = this.state.requestsLoading;
			delete requestsLoading[request.quote_id + status];
			this.setState({requestsLoading: requestsLoading});
		});
	}
	
	fetchReservation() {
		
		const {match, history} = this.props;
		
		const token = getToken();
		if (!token) return;
		
		// const url = getApiLink('user/reservations/'+match.params.id);
		const url = getApiLinkV2('user/list-reservations/' + match.params.id);
		
		getAxios().get(url, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token,
			},
			params: {
				type: match.params.type,
			}
		}).then(response => {
			devLog(response.data);
			if (response.data.reservation) {
				const reservation = response.data.reservation;
				if (reservation.requests) reservation.requests.sort((a, b) => (a.created_datetime < b.created_datetime) ? -1 : (a.created_datetime > b.created_datetime) ? 1 : 0);
				const venues = response.data.venues;
				const space = response.data.space;
				const location = (space) ? {
					lat: parseFloat(space.latitude),
					lng: parseFloat(space.longitude)
				} : {lat: 25.1210779, lng: 55.2212647};
				this.setState({
					reservation: reservation,
					rating: (reservation.reservation_rating) ? reservation.reservation_rating : 0,
					review: reservation.comment ? reservation.comment : null,
					space: space,
					venues: venues,
					location: location
				});
				this.checkQuoteSelected();
				let query = new URLSearchParams(this.props.location.search)
				let action = query.get('action')
				if (action === 'view-receipt') this.showReceipt()
				return;
			}
			history.push('/error');
		}).catch(error => {
			
			Bugsnag.notify(error);
			
			devLog(error);
			history.push('/error');
		});
	}
	
	clearActionFromQuery = () => {
		let {location, history} = this.props
		let query = new URLSearchParams(location.search)
		query.delete('action')
		history.push(location.pathname + '?' + query.toString())
	}
	
	render() {
		
		const {match, defCurrency} = this.props;
		const {reservation, space, venues, location, visitDate, visitTime, excludedDays, showReviews, showDetails, showSummary, showQuotes, showVenue, showMap, showMore, showMoreReq, errors, tempRating, rating, review, requestsLoading, reqAlert} = this.state;
		const user = getUser();
		const currency = getCurrency(defCurrency);
		
		if (!user) return (<Redirect to={{pathname: '/login', state: {prevLocation: this.props.location}}}/>);
		
		if (!reservation) return (<></>);
		
		return (
			<>
				<div className="reserv-page">
					<ReceiptDialog ref={this.receiptDialog} id={user.id} receiptId={match.params.id}
					               onClose={this.clearActionFromQuery}/>
					
					<div className="container-fluid bg-white mt-80 h-z">
						<div className="container-lg h-z">
							<div className="booking-page resrv-p ps-50 pb-4 h-z">
								{/*<ReactJson src={reservation} collapsed={true}/>*/}
								<div className="space-container mt-2">
									<div className="space-content h-z">
										
										<h1 className="page-lg-title pt-0 pb-4 h-z">
											<Link to='/reservations'>
												<FontAwesomeIcon icon={faArrowLeft}
												                 style={{
													                 color: '#000000',
													                 fontSize: 20,
													                 cursor: 'pointer',
													                 marginRight: 24,
													                 marginBottom: 1
												                 }}/>
											</Link>
											{(reservation.reservation_type === 'booking') ? 'Short Term Space Booking'
												: (reservation.reservation_type === 'lead') ? 'Long Term Space Booking'
													: 'Catered Space Booking'
											}
										</h1>
										
										<div className="reservation-page mb-3">
											
											<div className="display-flex w100 mb-3 pb-3 bb-1">
												<div className="reserv-id-cont">
													<div
														className="reservation-text pt-2 pb-0">{'#' + ((reservation.reservation_type === 'lead') ? 'LT' + reservation.request_id : reservation.booking_number)}</div>
												</div>
												<div className="quote-status-cont">
													{(reservation.reservation_type === 'proposal') ? (
														(reservation.requests.length === 1) ? (
															DdrUtils.printQuoteStatus(reservation.requests[0], false)
														) : (
															DdrUtils.printQuoteStatus(getGenericQuoteStatus(reservation.requests), true)
														)
													) : (reservation.reservation_type === 'booking') ? (
														printBookingStatus(reservation)
													) : (
														<></>
													)}
													{/*{(reservation.reservation_type === 'booking') ? (*/}
													{/*  <div id="tooltip" className="reserv-status mr-0 ml-2" title="Instant short term reservation ">Booking</div>*/}
													{/*) : (reservation.reservation_type === 'lead') ? (*/}
													{/*  <div className="reserv-status reserv-type-lt mr-0 ml-2">Inquiry</div>*/}
													{/*) : (*/}
													{/*  <div className="reserv-status reserv-type-ddr mr-0 ml-2">Proposal</div>*/}
													{/*)}*/}
												</div>
											</div>
											
											{(reservation.reservation_type !== 'lead' && (
												<div className="reserv-title-cont w100 pb-2 pb-2">
													<div
														className="reserv-title fz-18 pb-2">{(reservation.multiple) ? 'Multiple' +
														' Quotes' : (reservation.pending) ? 'Online' +
														' Quote' : (reservation.name + " - " + reservation.city)}</div>
													<div className="reservation-text reserv-price">
														{(reservation.pending || (!reservation.multiple && reservation.quote_status === 'pending' && reservation.amount_due == 0)) ? (
															'-'
														) : (reservation.multiple) ? (
															getGenericQuotePrice(reservation.requests, true, currency.name)
														) : (
															printPrice(reservation.amount_due, 'USD', currency.name, true)
														)}</div>
												</div>
											))}
											
											{(reservation.reservation_type === 'booking') ? (
												<>
													
													{reservation.status === 'completed' && (
														<>
															<h4 className="section-head"
															    onClick={() => this.setState({showReviews: !showReviews})}>Review
																& Rating</h4>
															{showReviews && (
																<>
																	<div className='ratingContainerDiv'>
																		<div className='starContainer'>
																			{[...Array(5)].map((e, i) => (
																				<Star
																					key={i}
																					index={i}
																					size={20}
																					margin={2}
																					position={rating}
																					starSelectedInPosition={val => this.starSelected(val)}
																					fill={rating >= i + 1}
																				/>
																			))}
																		</div>
																		<div style={{flexGrow: 0, flexShrink: 1}}>
																			<div
																				className='review-text'>{reviews[rating - 1]}</div>
																		</div>
																	</div>
																	{review && (
																		<div className='reserv-text' style={{
																			paddingBottom: 10,
																			paddingLeft: 12,
																			paddingRight: 12
																		}}>{review}</div>
																	)}
																</>
															)}
														</>
													)}
													
													<h4 className="section-head"
													    onClick={() => this.setState({showDetails: !showDetails})}>Booking
														Details</h4>
													{(showDetails && (
														<>
															<div className="row pr-3 pl-3 pt-3">
																<div className="col-md-6 col-12">
																	<div className="pb-4">
																		<div className="reserv-subtitle">Booking date
																		</div>
																		<div
																			className="reservation-text">{printDateFromDateTime(reservation.start_date_time)}</div>
																	</div>
																	<div className="pb-4">
																		<div className="reserv-subtitle">Booking time
																		</div>
																		<div
																			className="reservation-text">{printTimeRange(reservation.start_date_time, reservation.end_date_time)}</div>
																	</div>
																	<div className="pb-4">
																		<div className="reserv-subtitle">Parking</div>
																		<div
																			className="reservation-text">{(reservation.parking_instruction) ? reservation.parking_instruction : '-'}</div>
																	</div>
																	<div className="pb-4">
																		<div className="reserv-subtitle">Created on
																		</div>
																		<div
																			className="reservation-text">{printDateFromDateTime(reservation.created_datetime)}</div>
																	</div>
																	{(reservation.layout) ? (
																		<div className="pb-4">
																			<div className="reserv-subtitle">Layout &
																				Capacity
																			</div>
																			<div
																				className="reservation-text">{getLayoutName(reservation.layout)} - {reservation.capacity}</div>
																		</div>
																	) : (
																		<div className="pb-4">
																			<div className="reserv-subtitle">Capacity
																			</div>
																			<div
																				className="reservation-text">{reservation.capacity}</div>
																		</div>
																	)}
																</div>
																<div className="col-md-6 col-12">
																	<div className="pb-4">
																		<div className="reserv-subtitle">Price</div>
																		<div className="reservation-text">
																			{printPrice(reservation.subtotal, 'USD', currency.name)}
																		</div>
																	</div>
																	<div className="pb-4">
																		<div className="reserv-subtitle">Sales Tax /
																			VAT
																		</div>
																		<div className="reservation-text">{
																			printPrice(reservation.sale_tax_amt, 'USD', currency.name)}
																		</div>
																	</div>
																	<div className="pb-4">
																		<div className="reserv-subtitle">Transaction
																			Fee
																		</div>
																		<div className="reservation-text">
																			{printPrice(reservation.user_tran_fee_amt, 'USD', currency.name)}
																		</div>
																	</div>
																	<div className="pb-4">
																		<div className="reserv-subtitle">Discount</div>
																		<div className="reservation-text">
																			{printPrice(reservation.discount_amt, 'USD', currency.name)}
																		</div>
																	</div>
																	<div className="pb-4">
																		<div className="reserv-subtitle">Total Price
																		</div>
																		<div className="reservation-text">
																			{printPrice(reservation.payment_amount, reservation.payment_currency, currency.name)}
																		</div>
																	</div>
																</div>
															</div>
															
															{(space.amenities && Object.keys(space.amenities).length > 0 && (
																<div className="pb-4">
																	<div className="reserv-subtitle">Amenities
																		Requested
																	</div>
																	<div className="reservation-text">
																		{(space.amenities.map(key => {
																			const amenityCount = space.amenities[key];
																			if (!amenityCount) return '';
																			const amenity = printAmenity(key);
																			if (!amenity) return '';
																			return amenityCount + ' ' + amenity.amenity + ' required, '
																		}))}
																	</div>
																</div>
															))}
															
															<div className="w100 pr-3 pl-3 pb-4">
																<div className="reserv-subtitle">Cancellation Policy
																</div>
																<div className="reserv-fulltext">
																	Full refund if you cancel 48 hrs before the
																	reservation start time.
																	<br/>
																	<Link to='/refund'>Read Cancellation Policy</Link>
																</div>
															</div>
															{(reservation.status === 'completed' || reservation.status === 'approved') &&
															<div className="w100 pr-3 pl-3 pt-2 pb-4"> {/* bb-1 */}
																{/*<a href={'https://host.fluidmeet.com/reservation/receipt/'+match.params.id+'/'+user.id} rel='noreferrer' target='_blank'>*/}
																
																<button type="button"
																        className="btn btn-primary send-btn"
																        onClick={this.showReceipt}>
																	View Receipt
																</button>
																
																{/*</a>*/}
															</div>
															}
														</>
													))}
												</>
											) : (reservation.reservation_type === 'lead') ? (
												<>
													<div className="pt-3">
														<div className="pb-3">
															<div className="reserv-subtitle">Created on</div>
															<div
																className="reservation-text">{printDateFromDateTime(reservation.request_date)}</div>
														</div>
														{/*<div className="pb-3">*/}
														{/*  <div className="reserv-subtitle">Visit Date & Time</div>*/}
														{/*  <div className="reservation-text">Nov 12, 2020 02:00:PM</div>*/}
														{/*</div>*/}
													</div>
													
													{(false) ? (
														<>
															{/*<h5 className="pt-4 pb-2">Inquiry updates:</h5>*/}
															
															{/*<div className="form-general p-0">*/}
															{/*  <form autoComplete="on">*/}
															{/*    <div className="row">*/}
															{/*      <div className="col-6 pr-2 pb-2">*/}
															{/*        <div className="reserv-subtitle pb-2">Contact made with venue?</div>*/}
															{/*        <div className="selector">*/}
															{/*          <select name="lead_call_status" id="lead_call_status" className="select">*/}
															{/*            <option value="1">No contact</option>*/}
															{/*            <option value="2">Venue contacted me</option>*/}
															{/*            <option value="3">I contacted venue</option>*/}
															{/*          </select>*/}
															{/*        </div>*/}
															{/*      </div>*/}
															{/*      <div className="col-6 pl-2 pb-2">*/}
															{/*        <div className="reserv-subtitle pb-2">Status</div>*/}
															{/*        <div className="selector">*/}
															{/*          <select name="lead_status" id="lead_status" className="select">*/}
															{/*            <option value="0" selected="selected">No status</option>*/}
															{/*            <option value="1">Interested – visit arranged</option>*/}
															{/*            <option value="2">Interested – discussing contract</option>*/}
															{/*            <option value="3">Interested - contract signed</option>*/}
															{/*            <option value="4">Not interested – out of budget</option>*/}
															{/*            <option value="5">Not interested – did not like venue design</option>*/}
															{/*            <option value="6">Not interested – did not like venue location</option>*/}
															{/*            <option value="7">Not interested – did not like venue staff</option>*/}
															{/*          </select>*/}
															{/*        </div>*/}
															{/*      </div>*/}
															{/*    </div>*/}
															{/*  </form>*/}
															{/*</div>*/}
															
															{/*<h5 className="pt-4 pb-2">Contract details:</h5>*/}
															
															{/*<div className="row">*/}
															{/*  <div className="col-6 pb-3">*/}
															{/*    <div className="reserv-subtitle">Start Date</div>*/}
															{/*    <div className="reservation-text">16 Nov, 2020</div>*/}
															{/*  </div>*/}
															{/*  <div className="col-6 pb-3">*/}
															{/*    <div className="reserv-subtitle">End Date</div>*/}
															{/*    <div className="reservation-text">16 Nov, 2021</div>*/}
															{/*  </div>*/}
															{/*  <div className="col-6 pb-3">*/}
															{/*    <div className="reserv-subtitle">Contract Value</div>*/}
															{/*    <div className="reservation-text">3,000 AED</div>*/}
															{/*  </div>*/}
															{/*  <div className="col-6 pb-3">*/}
															{/*    <div className="reserv-subtitle">Deposit</div>*/}
															{/*    <div className="reservation-text">2,000 AED</div>*/}
															{/*  </div>*/}
															{/*</div>*/}
															
															{/*<div className="w100 pt-3 pb-4">*/}
															{/*  <a href='https://fluidmeet.com/reservation/receipt/1452' rel='noreferrer'>*/}
															{/*    <button type="button" className="btn btn-primary send-btn mr-2">*/}
															{/*      View Contract*/}
															{/*    </button>*/}
															{/*  </a>*/}
															{/*  <a href='https://fluidmeet.com/reservation/receipt/1452' rel='noreferrer'>*/}
															{/*    <button type="button" className="btn btn-primary send-btn">*/}
															{/*      Download Proposal*/}
															{/*    </button>*/}
															{/*  </a>*/}
															{/*</div>*/}
														</>
													) : (
														<>
															{/*<div className="w100 pt-3 pb-4">*/}
															{/*  <button type="button" className="btn btn-primary send-btn mr-2">*/}
															{/*    Visit Scheduled*/}
															{/*  </button>*/}
															{/*  <button type="button" className="btn btn-primary send-btn">*/}
															{/*    Signed Contract*/}
															{/*  </button>*/}
															{/*  <div className="w100 pt-3">*/}
															{/*    Signed a contract with this venue? Inform us of your decision & contract details to help us complete our process.*/}
															{/*  </div>*/}
															{/*</div>*/}
														</>
													)}
													
													{/*<div className="pt-2 bb-1" />*/}
													
													{/*<h5 className="pt-4">Notes:</h5>*/}
													{/*<div className="pt-2">*/}
													{/*  <div className="note-title">Mohamed Yassin (24-01-2021):</div>*/}
													{/*  <div>Test</div>*/}
													{/*</div>*/}
												</>
											) : (
												<>
													
													{checkGenericIsPaid(reservation.requests) && (
														<>
															<h4 className="section-head"
															    onClick={() => this.setState({showReviews: !showReviews})}>Review
																& Rating</h4>
															{showReviews && (
																<>
																	<div className='ratingContainerDiv'>
																		<div className='starContainer'>
																			{[...Array(5)].map((e, i) => (
																				<Star
																					key={i}
																					index={i}
																					size={20}
																					margin={2}
																					position={rating}
																					starSelectedInPosition={val => this.starSelected(val)}
																					fill={rating >= i + 1}
																				/>
																			))}
																		</div>
																		<div style={{flexGrow: 0, flexShrink: 1}}>
																			<div
																				className='review-text'>{reviews[rating - 1]}</div>
																		</div>
																	</div>
																	{review && (
																		<div className='reserv-text' style={{
																			paddingBottom: 10,
																			paddingLeft: 12,
																			paddingRight: 12
																		}}>{review}</div>
																	)}
																</>
															)}
														</>
													)}
													
													<h4 className="section-head"
													    onClick={() => this.setState({showDetails: !showDetails})}>Event
														Details</h4>
													{(showDetails && (
														<div className="pr-3 pl-3">
															<div className="pt-3">
																<div className="row pt-2">
																	<div className="col-md-6 col-12">
																		<div className="pb-2">
																			<div className="reserv-subtitle">Event
																				Type
																			</div>
																			<div
																				className="reservation-text">{reservation.event_type}</div>
																		</div>
																	</div>
																	<div className="col-md-6 col-12">
																		<div className="pb-2">
																			<div className="reserv-subtitle">Approximate
																				Budget ({reservation.budget_type})
																			</div>
																			<div className="reservation-text">
																				{printPrice(reservation.budget, reservation.budget_currency, currency.name, true)}
																			</div>
																		</div>
																	</div>
																</div>
																<div className="w100 pb-2">
																	<div className="reserv-subtitle">Event Description
																	</div>
																	<div
																		className="reservation-text">{reservation.event_description}</div>
																</div>
															</div>
															
															{(reservation.meeting_data && reservation.meeting_data.length > 0 && (
																<>
																	{reservation.meeting_data.map((item, i) => (
																		<>
																			<h5 className="pt-4">Event Day {i + 1}</h5>
																			<div className="row pt-2">
																				<div className="col-md-6 col-12">
																					<div className="pb-2">
																						<div
																							className="reserv-subtitle">Event
																							Date
																						</div>
																						<div
																							className="reservation-text">{printDateFromDateTime(item.meeting_date)}</div>
																					</div>
																				</div>
																				<div className="col-md-6 col-12">
																					<div className="pb-2">
																						<div
																							className="reserv-subtitle">Event
																							Time
																						</div>
																						<div
																							className="reservation-text">{printTimeRange(item.start_time, item.end_time, true)}</div>
																					</div>
																				</div>
																				<div className="col-md-6 col-12">
																					<div className="pb-2">
																						<div
																							className="reserv-subtitle">Layout
																						</div>
																						<div
																							className="reservation-text">{getLayoutName(item.room_layout)}</div>
																					</div>
																				</div>
																				<div className="col-md-6 col-12">
																					<div className="pb-2">
																						<div
																							className="reserv-subtitle">Capacity
																						</div>
																						<div
																							className="reservation-text">{item.participants}</div>
																					</div>
																				</div>
																				<div className="col-md-6 col-12">
																					<div className="pb-2">
																						<div
																							className="reserv-subtitle">Breakfast
																						</div>
																						<div
																							className="reservation-text">
																							{(item.chk_breakfast === 'yes') ?
																								((item.rdo_breakfast_other === '') ? item.rdo_breakfast : item.rdo_breakfast_other) : '-'
																							}
																						</div>
																					</div>
																				</div>
																				<div className="col-md-6 col-12">
																					<div className="pb-2">
																						<div
																							className="reserv-subtitle">Tea
																							& Coffee Break
																						</div>
																						{(item.chk_tea_coffee === 'yes') ?
																							((item.rdo_tea_coffee_other === '') ? item.rdo_tea_coffee : item.rdo_tea_coffee_other) : '-'
																						}
																					</div>
																				</div>
																				<div className="col-md-6 col-12">
																					<div className="pb-2">
																						<div
																							className="reserv-subtitle">Lunch
																						</div>
																						{(item.chk_lunch === 'yes') ? (
																								<>
																									{item.rdo_lunch_type}{', '}
																									{(item.rdo_lunch_other === '') ? item.rdo_lunch : item.rdo_lunch_other}{', '}
																									{(item.rdo_lunch_drinks_other === '') ? item.rdo_lunch_drinks : item.rdo_lunch_drinks_other}
																								</>
																							) :
																							'-'
																						}
																					</div>
																				</div>
																				<div className="col-md-6 col-12">
																					<div className="pb-2">
																						<div
																							className="reserv-subtitle">Dinner
																						</div>
																						{(item.chk_dinner === 'yes') ? (
																								<>
																									{item.rdo_dinner_type}{', '}
																									{(item.rdo_dinner_other === '') ? item.rdo_dinner : item.rdo_dinner_other}{', '}
																									{(item.rdo_dinner_drinks_other === '') ? item.rdo_dinner_drinks : item.rdo_dinner_drinks_other}
																								</>
																							) :
																							'-'
																						}
																					</div>
																				</div>
																				<div className="col-md-6 col-12">
																					<div className="pb-2">
																						<div
																							className="reserv-subtitle">Drinks
																							Reception
																						</div>
																						{(item.rdo_drinks_other !== '') ? item.rdo_drinks_other : '-'}
																					</div>
																				</div>
																				<div className="col-md-6 col-12">
																					<div className="pb-2">
																						<div
																							className="reserv-subtitle">Amenities
																						</div>
																						<div
																							className="reservation-text">1
																							Projector & Screen, 1
																							Flipcart, Wifi
																						</div>
																					</div>
																				</div>
																			</div>
																		</>
																	))}
																</>
															))}
															
															{(reservation.accomodation && reservation.accomodation.length > 0 && (
																<>
																	<h5 className="pt-4 pb-2">Accommodation</h5>
																	{reservation.accomodation.map(item => (
																		<div className="pb-2">
																			<div
																				className="reserv-subtitle">{printDateFromDateTime(item.acc_date)}</div>
																			<div className="reservation-text">
																				{(item.single_room > 0) ? item.single_room + ' Single Rooms' : ''}
																				{(item.double_room > 0) ? (item.single_room > 0 && ', ') + item.double_room + ' Double Rooms' : ''}
																				{(item.twin_room > 0) ? ((item.single_room > 0 || item.double_room > 0) && ', ') + item.twin_room + ' Twin Rooms' : ''}
																				{(item.chk_bed_breakfast === 'yes') ? ' & Include breakfast' : ''}
																			</div>
																		</div>
																	))}
																</>
															))}
														</div>
													))}
													
													{this.printMultipleProposal(currency, this.props.location, reservation, venues, showSummary, true)}
													
													{(reservation.multiple) ? (
														<h4 className="section-head"
														    onClick={() => this.setState({showQuotes: !showQuotes})}>Available
															Quotes
															({getAvalQuotesNum(reservation.requests, venues)} of {reservation.requests.length} quotes)</h4>
													) : (
														<h4 className="section-head"
														    onClick={() => this.setState({showQuotes: !showQuotes})}>Quote
															Details</h4>
													)}
													{(showQuotes && reservation.reservation_type !== 'lead' && (
														<div className="pr-3 pl-3">
															{this.printProposals(currency, user, reservation, venues, requestsLoading, showMap)}
														</div>
													))}
												</>
											)}
										
										</div>
										
										{(space && (
											<div className="bottom-cont">
												<div id="bottom-stick" className="space-stick border-0">
													<h4 className="section-head"
													    onClick={() => this.setState({showVenue: !showVenue})}>Venue
														Details</h4>
													{(showVenue && (
														<div className="pr-3 pl-3 mt-4">
															{this.printSpaceDetails(reservation, space, location, true)}
														</div>
													))}
												</div>
											</div>
										))}
									
									</div>
									
									{this.printMultipleProposal(currency, this.props.location, reservation, venues, showSummary, false)}
									
									{(!reservation.multiple && (
										<div id="space-stick" className="space-stick">
											{this.printSpaceDetails(reservation, space, location, true)}
										</div>
									))}
								</div>
								
								
								<div id="more-filters"
								     className={"more-filter fz-17 justify-content-center " + ((showMore) ? "show-flex" : "")}>
									<div ref={this.showMoreRef}
									     className="more-box align-self-center space-page-box reserv-box">
										<form autoComplete="on"
										      onSubmit={(e) => showMore == 'review' ? this.onSubmitReview(e) : this.updateQuoteStatus(showMoreReq, showMore, e)}>
											<div className="more-header">
												<h5 className="center">
													{(showMore === 'declined by seeker') ? 'Decline Quote'
														: (showMore === 'book') ? 'Book now'
															: (showMore === 'call') ? 'Call Venue'
																: (showMore === 'host-phone') ? 'Venue Contact Info'
																	: (showMore === 'call requested') ? 'Request a Call'
																		: (showMore === 'visit arranged') ? 'Arrange Visit'
																			: (showMore === 'add note') ? 'Add Note'
																				: (showMore === 'email-share') ? 'Share Quote'
																					: (showMore === 'review') ? 'How was your reservation?'
																						: ''
													}
												</h5>
												<div className="dropdown-divider mt-4 mb-0"/>
											</div>
											<div className="more-contain reserv-box-box-contain">
												{(showMore === 'declined by seeker') ? (
													<>
														<div className="form-general p-0">
															<div className="reservation-text pt-2 pb-2">Reason</div>
															<div className="selector">
																<select name="reject_reason" id="reject_reason"
																        className="select">
																	<option value="" selected="selected">Select reason
																	</option>
																	<option value="Price quoted is too high">Price
																		quoted is too high
																	</option>
																	<option
																		value="Quote received too late (already found another venue)">
																		Quote received too late (already found another
																		venue)
																	</option>
																	<option
																		value="Location of venue not suitable">Location
																		of venue not suitable
																	</option>
																	<option
																		value="Venue could not match F&amp;B requirements">Venue
																		could not match F&amp;B requirements
																	</option>
																	<option
																		value="Venue could not match capacity (number of people) requirements">
																		Venue could not match capacity (number of
																		people) requirements
																	</option>
																	<option value="Found better priced option">Found
																		better priced option
																	</option>
																	<option
																		value="Found venue with better location">Found
																		venue with better location
																	</option>
																	<option value="Event postponed">Event postponed
																	</option>
																	<option value="Event cancelled">Event cancelled
																	</option>
																	<option value="Other">Other</option>
																</select>
															</div>
															{(errors.reject_reason) ? (<div
																className="form-error">{errors.reject_reason}</div>) : (<></>)}
															<div className="reservation-text pt-2 pb-2">Other Comments
															</div>
															<textarea id="reject_note" name="reject_note"
															          className="form-control" placeholder="Comments"/>
														</div>
													</>
												) : (showMore === 'host-phone') ? (
													<>
														<div className="reserv-text w100 mt-3 mb-2">Venue
															Name: {(reservation.multiple) ? venues[showMoreReq.generic_venue_id].venue_name : space.venue_name}</div>
														<div className="reserv-text w100 mt-3 mb-2">Phone
															Number: {(reservation.multiple) ? venues[showMoreReq.generic_venue_id].phone : space.phone}</div>
													</>
												) : (showMore === 'review') ? (
													<>
														<div className='ratingContainerDiv'
														     style={{width: '100%', display: "block"}}>
															<div className='starContainer pt-2' style={{
																alignContent: 'center',
																justifyContent: 'center',
																width: '100%'
															}}>
																{[...Array(5)].map((e, i) => (
																	<Star
																		key={i}
																		index={i}
																		size={40}
																		margin={5}
																		position={tempRating}
																		starSelectedInPosition={val => this.onStar(val)}
																		fill={tempRating >= i + 1}
																	/>
																))}
															</div>
															<div style={{width: '100%'}}>
																<div className='review-text mt-3' style={{
																	textAlign: 'center',
																	fontSize: 30
																}}>{reviews[tempRating - 1]}</div>
															</div>
														</div>
														<div className="reservation-text pb-2">Review</div>
														<textarea id="review" name="review" className="form-control"
														          placeholder="Write your comments here"/>
														{(errors.message) ? (<div
															className="form-error">{errors.message}</div>) : (<></>)}
													</>
												) : (showMore === 'call requested') ? (
													<div className="form-general p-0">
														<div className="row mt-3 mb-4">
															<div className="col-12 pl-2">
																<div className="reservation-text pt-2 pb-1">Phone
																	Number
																</div>
																<input type="tel" id="phone" name="phone"
																       className="form-control"
																       placeholder="Phone number"
																       defaultValue={(user) ? user.phone : ''}
																       required={true}/>
																{(errors.phone) ? (<div
																	className="form-error">{errors.phone}</div>) : (<></>)}
															</div>
														</div>
													</div>
												) : (showMore === 'call') ? (
													<div className="row mr-2 ml-2 mt-4 mb-4">
														<div className="half-width-btn pr-3">
															{/*<button type="button" className="btn btn-primary equal-btns disabled-btn w100">*/}
															{/*  Call now*/}
															{/*</button>*/}
															<button type="button"
															        className="btn btn-primary equal-btns w100"
															        onClick={() => this.setState({
																        showMore: 'host-phone',
																        showMoreReq: showMoreReq
															        })}>
																Contact details
															</button>
														</div>
														<div className="half-width-btn pr-2">
															<button type="button"
															        className="btn btn-primary equal-btns w100"
															        onClick={() => this.setState({
																        showMore: 'call requested',
																        showMoreReq: showMoreReq
															        })}>
																Request a Call
															</button>
														</div>
													</div>
												) : (showMore === 'book') ? (
													<div className="row mr-2 ml-2 mt-4 mb-4">
														<div className="half-width-btn pr-3">
															<button type="button"
															        className={"btn btn-primary equal-btns w100 " + ((requestsLoading[showMoreReq.quote_id + 'book']) ? 'ld-btn' : '')}
															        onClick={() => this.handlePayOnline(showMoreReq)}>
																{(requestsLoading[showMoreReq.quote_id + 'book']) ? (<>
																	<FontAwesomeIcon icon={faCircleNotch}
																	                 spin={true}/> loading</>) : 'Pay online'}
															</button>
														</div>
														<div className="half-width-btn pr-2">
															<button type="button"
															        className={"btn btn-primary equal-btns w100 " + ((requestsLoading[showMoreReq.quote_id + 'paid to venue']) ? 'ld-btn' : '')}
															        onClick={() => this.handleQuotePaid(showMoreReq)}>
																{(requestsLoading[showMoreReq.quote_id + 'paid to venue']) ? (<>
																	<FontAwesomeIcon icon={faCircleNotch}
																	                 spin={true}/> loading</>) : 'Pay at venue'}
															</button>
														</div>
													</div>
												) : (showMore === 'visit arranged') ? (
													<div className="form-general p-0">
														<div className="row mt-3 mb-4">
															<div className="col-6 pr-2">
																<div className="reservation-text pt-2 pb-1">Date</div>
																<div className="selector p-0 date-picker">
																	<div
																		className="dates-picked">{(visitDate) ? visitDate.toLocaleDateString("en-US", dateOptions) : ''}</div>
																	<DatePicker
																		filterDate={(date) => excludedDays[date.getDay()] == null}
																		excludeDates={excludedDates.map(date => new Date(date))}
																		onChange={(date) => this.setState({visitDate: date})}
																		selected={visitDate}
																		shouldCloseOnSelect={true}
																		minDate={new Date()}/>
																</div>
															</div>
															<div className="col-6 pl-2">
																<div className="reservation-text pt-2 pb-1">Time</div>
																<div className="selector">
																	<select name="visit_time" id="visit_time"
																	        className="select" value={visitTime}
																	        onChange={this.onVisitTimeChange}>
																		{this.printTimeOptions(reservation, space, venues, showMoreReq, visitDate)}
																	</select>
																</div>
															</div>
															<div className="col-6 pr-2">
																<div className="reservation-text pt-2 pb-1">First Name
																</div>
																<input type="name" id="fname" name="fname"
																       className="form-control" placeholder="First name"
																       defaultValue={(user) ? user.first_name : ''}
																       required={true}/>
																{(errors.fName) ? (<div
																	className="form-error">{errors.fName}</div>) : (<></>)}
															</div>
															<div className="col-6 pl-2">
																<div className="reservation-text pt-2 pb-1">Last Name
																</div>
																<input type="name" id="lname" name="lname"
																       className="form-control" placeholder="Last name"
																       defaultValue={(user) ? user.last_name : ''}
																       required={true}/>
																{(errors.lName) ? (<div
																	className="form-error">{errors.lName}</div>) : (<></>)}
															</div>
															<div className="col-12">
																<div className="reservation-text pt-2 pb-1">Email
																	Address
																</div>
																<input type="email" id="email" name="email"
																       className="form-control"
																       placeholder="Email address"
																       defaultValue={(user) ? user.email : ''}
																       required={true}/>
																{(errors.email) ? (<div
																	className="form-error">{errors.email}</div>) : (<></>)}
															</div>
															<div className="col-12">
																<div className="reservation-text pt-2 pb-1">Phone
																	Number
																</div>
																<input type="tel" id="phone" name="phone"
																       className="form-control"
																       placeholder="Phone number"
																       defaultValue={(user) ? user.phone : ''}
																       required={true}/>
																{(errors.phone) ? (<div
																	className="form-error">{errors.phone}</div>) : (<></>)}
															</div>
														</div>
													</div>
												) : (showMore === 'add note') ? (
													<>
														<textarea id="note" name="note" className="form-control mt-3"
														          placeholder="Note" required={true}/>
														{(errors.note) ? (
															<div className="form-error">{errors.note}</div>) : (<></>)}
													</>
												) : (showMore === 'email-share') ? (
													<>
														{/*<div className="reservation-text bold pt-3 pb-1">Shared with</div>*/}
														{/*<div className="reserv-text">mohamedyassin360@gmail.com</div>*/}
														{/*<div className="reserv-text">yassin@fluidmeet.com</div>*/}
														<input type="email" id="email" name="email"
														       className="inputEmail form-control mt-3"
														       placeholder="Email address" required/>
													</>
												) : (
													<></>
												)}
											</div>
											{showMore == 'review' && (
												<div className="more-bottom">
													<div className="dropdown-divider mb-3 mt-0"/>
													<button type="submit"
													        className={"custom-btn save " + ((requestsLoading[showMore]) ? 'ld-btn' : '')}>
														{(requestsLoading[showMore]) ? (<><FontAwesomeIcon
															icon={faCircleNotch} spin={true}/> loading</>) : 'Done'}
													</button>
												</div>
											)}
											{((showMoreReq && showMore !== 'book' && showMore !== 'call' && showMore !== 'host-phone') && (
												<div className="more-bottom">
													<div className="dropdown-divider mb-3 mt-0"/>
													<button type="submit"
													        className={"custom-btn save " + ((requestsLoading[showMoreReq.quote_id + showMore]) ? 'ld-btn' : '')}>
														{(requestsLoading[showMoreReq.quote_id + showMore]) ? (<>
															<FontAwesomeIcon icon={faCircleNotch}
															                 spin={true}/> loading</>) : ((showMore === 'email-share' || showMore === 'add note') ? 'Add' : 'Submit')}
													</button>
												</div>
											))}
										</form>
									</div>
								</div>
								
								{(reqAlert) ? (
									<div id="alerts">
										<div
											className={"alert alert-" + reqAlert.type + " alert-dismissible floating-alert fade show"}>
											<strong>{reqAlert.title}</strong> {reqAlert.message}
											<button type="button" className="btn-close"
											        onClick={() => this.setState({reqAlert: null})}>
												<FontAwesomeIcon icon={faTimes}/>
											</button>
										</div>
									</div>
								) : (<></>)}
							
							</div>
						</div>
					</div>
				</div>
				<div id={"receipt"}/>
				<Footer key="ReservationsDFooter"/>
			
			</>
		);
	}
	
	
	printMultipleProposal(currency, location, reservation, venues, showSummary, isMobileView) {
		
		var elements = [];
		
		if (!reservation.multiple) return elements;
		
		elements.push(
			<div id={(isMobileView) ? "summary-block" : "space-stick"}
			     className={(isMobileView) ? "" : "space-stick pb-3"}>
				<div className={(isMobileView) ? "" : "m-0 border-0"}>
					
					{(isMobileView) ? (
						<h4 className="section-head" onClick={() => this.setState({showSummary: !showSummary})}>Quotes
							Summary</h4>
					) : (
						<h5 className="pb-2">Quotes Summary</h5>
					)}
					
					{((!isMobileView || showSummary) && (
						
						reservation.requests.map(item => {
							
							if (!item.tot_fluidmeet_price || (!venues || !venues[item.generic_venue_id])) return (<></>)
							
							return (
								<div className={(isMobileView) ? "pr-3 pl-3" : ""}
								     onClick={() => this.handleSelectQuote(item.quote_id)}>
									<div className="row mr-0 ml-0 pt-2">
										<div className="reserv-id-cont">
											<div className="pb-1">
												<div
													className={(isMobileView) ? "reservation-text" : "summary-text"}>{(venues[item.generic_venue_id].venue_name)} {printSummaryQuoteStatus(item)}</div>
											</div>
										</div>
										<div className="summary-price-cont">
											<div className="pb-1">
												<div className="reservation-text font-weight-bold text-right">
													{(item.tot_fluidmeet_price) ? printPrice(item.amount_due, 'USD', currency.name, true) : '-'}
												</div>
											</div>
										</div>
									</div>
								</div>
							)
						})
					))}
				
				</div>
			</div>
		);
		
		return elements;
	}
	
	
	printProposals(currency, user, reservation, venues, requestsLoading, showMap) {
		
		if (reservation.pending || (!(reservation.multiple === 'yes') && reservation.quote_status === 'pending' && reservation.amount_due == 0)) {
			return (<h6 className="empty-title fz-18 pt-2 mt-4 mb-5">No Available quotes yet</h6>);
		}
		
		var elements = [];
		var count = 0;
		
		if (!reservation.requests || reservation.requests.length === 0) return;
		
		reservation.requests.forEach(req => {
			
			// console.log(count,(({status,tot_fluidmeet_price,reject_reason,generic_venue_id})=>({status,tot_fluidmeet_price,reject_reason,generic_venue_id}))(req) )
			// console.log((req.status === 'inactive' && !req.reject_reason) ,  !req.tot_fluidmeet_price , (!venues || !venues[req.generic_venue_id])  )
			if ((req.status === 'inactive' && !req.reject_reason) || (reservation.multiple && (!req.tot_fluidmeet_price || (!venues || !venues[req.generic_venue_id])))) return;
			count++;
			
			var venue = null;
			var location = {lat: 25.1210779, lng: 55.2212647};
			
			if (reservation.multiple) {
				venue = venues[req.generic_venue_id];
				location = {lat: parseFloat(venue.latitude), lng: parseFloat(venue.longitude)}
			}
			elements.push(
				<div id={"quote-" + req.quote_id} className="proposal">
					
					{(reservation.multiple && (
						<>
							<div className="position-relative">
								<div className="row mr-0 ml-0">
									<div className="reserv-id-cont">
										<div className="space-text-header fz-18 pt-1 pb-1">{venue.venue_name}</div>
									</div>
									<div
										className="quote-status-cont pt-1 pb-3">{DdrUtils.printQuoteStatus(req, false)}</div>
								</div>
								{/*<div className="space-text-bold pt-2 pr-65">{(venue.venue_name)}</div>*/}
								<div className="space-text pt-1 pb-3 pr-65">{venue.registered_address}</div>
								<img src={mapIcon} style={{
									position: "absolute",
									right: 0,
									bottom: 15,
									width: 36,
									height: 36,
									cursor: "pointer"
								}}
								     onClick={() => this.setState({showMap: ((showMap === venue.venue_details_id) ? '' : venue.venue_details_id)})}/>
							</div>
							{(showMap === venue.venue_details_id && (
								<div className="map-cont mb-3">
									{/*<LoadScript googleMapsApiKey='AIzaSyBDM449V7qE4OkyoV2zP3xm_VQIYDvrHVw'>*/}
									<GoogleMap
										options={{
											styles: styles,
											keyboardShortcuts: false,
											scaleControl: true,
											draggable: true,
											gestureHandling: "greedy",
											typeControl: true,
											mapTypeControl: true,
											mapTypeControlOptions: {position: 1},
											zoomControl: true,
											zoomControlOptions: {position: 8},
											maxZoom: 11,
										}}
										mapContainerStyle={{height: "100%", width: "100%"}}
										zoom={12}
										center={location}>
										<Marker name={venue.venue_details_id} position={location} icon={markerIcon}/>
									</GoogleMap>
									{/*</LoadScript>*/}
								</div>
							))}
						</>
					))}
					
					{(reservation.multiple && (<div className="pt-1 bb-1"/>))}
					<table className={'w-100'} style={{tableLayout: 'fixed'}}>
						<thead>
						<tr>
							<th colSpan={7}></th>
							<th colSpan={4} className={'text-right'} style={{...leftCellStyle, fontSize: '1vb'}}>Venue
								Price
							</th>
							<th colSpan={4} className={'text-right'}
							    style={{...rightCellStyle, fontSize: '1vb'}}>Fluidmeet Price
							</th>
						</tr>
						</thead>
						<tbody>
						{req.meeting_data && req.meeting_data.map((item, i) => (
								<tr className="pb-2">
									<td colSpan={7} className="reserv-subtitle">Event Day {i + 1}</td>
									<td colSpan={4} className="reservation-text text-right"
									    style={{...leftCellStyle, textDecoration: `line-through ${red[400]}`}}>
										{'  ' + printPrice(item.venue_price, 'USD', currency.name) + '    '}
									</td>
									<td colSpan={4} className="reservation-text text-right" style={rightCellStyle}>
										{printPrice(item.fluidmeet_price, 'USD', currency.name)}
									</td>
								
								</tr>
							)
						)
						}
						{req.accomodation && req.accomodation.map(
							item => (
								<tr className="pb-2">
									<td colSpan={7}
									    className="reserv-subtitle">Accommodation {printDateFromDateTime(item.acc_date)}</td>
									<td colSpan={4} className="reservation-text text-right"
									    style={{...leftCellStyle, textDecoration: `line-through ${red[400]}`}}>
										{'  ' + printPrice(item.venue_price, 'USD', currency.name) + '    '}
									</td>
									<td colSpan={4} className="reservation-text text-right" style={rightCellStyle}>
										{printPrice(item.fluidmeet_price, 'USD', currency.name)}
									</td>
								</tr>
							
							)
						)
						}
						
						
						<tr className="pb-2 " style={{borderTop: '1px solid #cdddeb'}}>
							<td colSpan={7} className="pt-2 reserv-subtitle">Sales Tax / VAT</td>
							<td colSpan={4} className=" pt-2 reservation-text text-right"
							    style={{...leftCellStyle, textDecoration: `line-through ${red[400]}`}}>
								{printPrice(Math.ceil((req.tax_percent / 100) * req.tot_venue_price / (1 + (req.tax_percent / 100))), 'USD', currency.name, true)}
							</td>
							<td colSpan={4} className="pt-2 reservation-text text-right" style={rightCellStyle}>
								{printPrice(Math.ceil((req.tax_percent / 100) * req.tot_fluidmeet_price / (1 + (req.tax_percent / 100))), 'USD', currency.name, true)}
							</td>
						</tr>
						
						<tr className="pb-2">
							<td colSpan={7} className="reserv-subtitle">Total</td>
							<td colSpan={4} className="reservation-text text-right"
							    style={{...leftCellStyle, textDecoration: `line-through ${red[400]}`}}>
								{printPrice(req.tot_venue_price, 'USD', currency.name, true)}
							</td>
							<td colSpan={4} className="reservation-text text-right" style={rightCellStyle}>
								{printPrice(req.tot_fluidmeet_price, 'USD', currency.name, true)}
							</td>
						</tr>
						
						</tbody>
					</table>
					{/*{(req.meeting_data && (*/}
					{/*  <>*/}
					{/*    <div className="row pt-3 pb-2">*/}
					{/*      {req.meeting_data.map((item, i) => (*/}
					{/*        <>*/}
					{/*          <div className="col-8">*/}
					{/*            <div className="pb-2">*/}
					{/*              <div className="reserv-subtitle">Event Day {i + 1}</div>*/}
					{/*            </div>*/}
					{/*          </div>*/}
					{/*          <div className="col-4">*/}
					{/*            <div className="pb-2">*/}
					{/*              <div className="reservation-text text-right">*/}
					{/*                {(item.venue_price != item.fluidmeet_price) &&*/}
					{/*                <span style={{textDecoration: `line-through ${red[400]}`}}>*/}
					{/*                  {'  ' + printPrice(item.venue_price, 'USD', currency.name) + '    '}*/}
					{/*                </span>}*/}
					{/*                {printPrice(item.fluidmeet_price, 'USD', currency.name)}*/}
					{/*              </div>*/}
					{/*            </div>*/}
					{/*          </div>*/}
					{/*        </>*/}
					{/*      ))}*/}
					{/*      {(req.accomodation && req.accomodation.map(item => (*/}
					{/*        <>*/}
					{/*          <div className="col-8">*/}
					{/*            <div className="pb-2">*/}
					{/*              <div className="reserv-subtitle">Accommodation {printDateFromDateTime(item.acc_date)}</div>*/}
					{/*            </div>*/}
					{/*          </div>*/}
					{/*          <div className="col-4">*/}
					{/*            <div className="pb-2">*/}
					{/*              <div className="reservation-text text-right">*/}
					{/*                {(item.venue_price != item.fluidmeet_price) &&*/}
					{/*                <span style={{textDecoration: `line-through ${red[400]}`}}>*/}
					{/*                  {'  ' + printPrice(item.venue_price, 'USD', currency.name) + '    '}*/}
					{/*                </span>}*/}
					{/*                {printPrice(item.fluidmeet_price, 'USD', currency.name)}*/}
					{/*              </div>*/}
					{/*            </div>*/}
					{/*          </div>*/}
					{/*        </>*/}
					{/*      )))}*/}
					{/*    </div>*/}
					{/*    <div className="bb-1"/>*/}
					{/*  </>*/}
					{/*))}*/}
					<div className="pt-2 bb-1"/>
					<div className="row pt-3 mt-1">
						
						{/*<div className="col-8">*/}
						{/*  <div className="pb-2">*/}
						{/*    <div className="reserv-subtitle">Sales Tax / VAT</div>*/}
						{/*  </div>*/}
						{/*</div>*/}
						{/*<div className="col-4">*/}
						{/*  <div className="pb-2">*/}
						{/*    <div*/}
						{/*      className="reservation-text text-right">{"$" + Math.ceil((req.tax_percent / 100) * req.tot_venue_price / (1 + (req.tax_percent / 100)))}</div>*/}
						{/*  </div>*/}
						{/*</div>*/}
						
						{/*<div className="col-8">*/}
						{/*  <div className="pb-2">*/}
						{/*    <div className="reserv-subtitle">Total Venue Price</div>*/}
						{/*  </div>*/}
						{/*</div>*/}
						{/*<div className="col-4">*/}
						{/*  <div className="pb-2">*/}
						{/*    <div*/}
						{/*      className="reservation-text text-right"> {printPrice(req.tot_venue_price, 'USD', currency.name, true)}</div>*/}
						{/*  </div>*/}
						{/*</div>*/}
						
						{(req.tot_venue_price != req.tot_fluidmeet_price) && (
							<>
								<div className="col-8">
									<div className="pb-2">
										<div className="reserv-subtitle">Amount Saved by Using Fluidmeet</div>
									</div>
								</div>
								<div className="col-4">
									<div className="pb-2">
										<div className="reservation-text text-right" style={{color: red[400]}}>
											{printPrice(req.tot_venue_price - req.amount_due, 'USD', currency.name, true)}
										</div>
									</div>
								</div>
								
								<div className="col-8">
									<div className="pb-2">
										<div className="reserv-subtitle">Saved(%)</div>
									</div>
								</div>
								<div className="col-4">
									<div className="pb-2">
										<div className="reservation-text text-right" style={{color: red[400]}}>
											{doubleToPercentage(1 - req.amount_due / req.tot_venue_price)}
										</div>
									</div>
								</div>
							</>
						)
						}
						
						<div className="col-8">
							<div className="pb-2">
								<div className="reserv-subtitle">Total Fluidmeet Price</div>
							</div>
						</div>
						<div className="col-4">
							<div className="pb-2">
								<div className="reservation-text text-right">
									{printPrice(req.amount_due, 'USD', currency.name, true)}
								</div>
							</div>
						</div>
					</div>
					
					{((!req.expired || req.expired === 'no') && (
						(req.quote_status === 'accepted' && (new Date()) <= (new Date(req.start_date))) ? (
							<>
								<div className="w100 pt-3">
									{(!req.payment_status && (
										<button type="button" className="btn btn-primary send-btn mb-2 mr-2"
										        onClick={() => this.toggleShowMore('book', req)}>
											Book
										</button>
									))}
									<button type="button" className="btn btn-primary send-btn mb-2 mr-2"
									        onClick={() => this.toggleShowMore('call', req)}>
										Call
									</button>
									{(req.payment_status) ? (<></>
										// <a href={'https://host.fluidmeet.com/reservation/proposal-receipt/'+req.quote_id+'/'+user.id} rel='noreferrer' target='_blank'>
										// <button type="button" className="btn btn-primary send-btn mb-2" onClick={this.showReceipt}>
										//   View Receipt
										// </button>
										/*</a>*/
									) : (
										<button type="button" className="btn btn-primary send-btn mb-2"
										        onClick={() => this.toggleShowMore('visit arranged', req)}>
											Arrange Visit
										</button>
									)}
								</div>
								
								{(req.visit_req === 'yes' && (
									<div className="pt-3 pb-1">
										<div className="reserv-subtitle">Visit Date & Time</div>
										<div
											className="reservation-text">{printDateTimeFromServer(req.visit_req_datetime)}</div>
									</div>
								))}
								
								{(req.call_req === 'yes' && (
									<>
										{(!req.venue_contacted && (
											<div className="row m-0 pt-3">
												<div className="quote-status-cont">
													<h6 className="mt-2">Did the venue contact you ?</h6>
												</div>
												<div className="on-off-col display-flex">
													<div className="on-off">
                          <span className={(req.venue_contacted) ? "on-active border-r-6" : ""}
                                onClick={() => this.updateQuoteStatus(req, 'venue contacted')}>Yes</span>
													</div>
												</div>
											</div>
										))}
										{(!req.payment_status && (
											<div className="row m-0 pt-3">
												<div className="quote-status-cont">
													<h6 className="mt-2">Did you reserve the space and pay at the venue
														?</h6>
												</div>
												<div className="on-off-col display-flex">
													<div className="on-off">
														<span onClick={() => this.handleQuotePaid(req)}>Yes</span>
													</div>
												</div>
											</div>
										))}
									</>
								))}
							</>
						) : (req.quote_status === 'pending') ? (
							<div className="w100 pt-3">
								<button
									className={"btn btn-primary send-btn mb-2 mr-2 " + ((requestsLoading[req.quote_id + 'accepted']) ? 'ld-btn' : '')}
									onClick={() => this.updateQuoteStatus(req, 'accepted')}>
									{(requestsLoading[req.quote_id + 'accepted']) ? (<><FontAwesomeIcon
										icon={faCircleNotch} spin={true}/> loading</>) : 'Interested'}
								</button>
								<button className="btn btn-primary send-btn rd-btn mb-2 mr-2"
								        onClick={() => this.toggleShowMore('declined by seeker', req)}>
									Not Interested
								</button>
							</div>
						) : (
							<></>
						)
					))}
					
					{(req.reject_reason) ? (
						<>
							<div className="w100 pt-4">Decline reason: {req.reject_reason}</div>
							{(req.reject_note && (
								<div className="w100 pt-2">Decline note: {req.reject_note}</div>
							))}
						</>
					) : (req.expired === 'yes' && req.payment_status !== 'paid' && req.payment_status !== 'paid to venue') ? (
						<div className="form-error w100 pt-3">Proposal Expired!</div>
					) : (req.payment_status !== 'paid' && req.payment_status !== 'paid to venue') ? (
						<div className="row m-0 pt-4">
							<div className="reserv-id-cont">
								<span className='sec-header semi-bold'>Expiry:</span>
								{(req.expire_on) ? ' ' + printDateFromDateTime(req.expire_on) : ' -'}
							</div>
							<div className="quote-share-cont">
								<button className="btn space-btn m-0 p-0 float-right"
								        onClick={() => this.toggleShowMore('email-share', req)}>
									Share
								</button>
							</div>
						</div>
					) : (<></>)}
					
					<div className="row m-0 pt-4">
						<div className="reserv-id-cont sec-header semi-bold">Notes:</div>
						<div className="quote-share-cont">
							<button className="btn space-btn m-0 p-0 float-right"
							        onClick={() => this.toggleShowMore('add note', req)}>
								Add Note
							</button>
						</div>
					</div>
					
					{
						(
							(notes) => {
								if (notes.length > 0) return (
									notes.map(item => (
										<div className="notes pt-2">
                      <span
	                      className="reserv-subtitle">{item.user_name} ({printDateFromDateTime(item.created_on)}): </span>
											{item.note}
										</div>
									))
								)
							}
						)(req.notes.filter((note) => (note.created_by == user?.id)))
					}
				</div>
			);
		});
		
		if (count === 0) {
			if (reservation.multiple) {
				elements.push(<h6 className="empty-title fz-18 pt-2 mt-4 mb-5">No Available quotes yet</h6>);
			} else {
				elements.push(<h6 className="empty-title fz-18 pt-2 mt-4 mb-5">Venue have not submitted a quote
					yet</h6>);
			}
		}
		
		return elements;
	}
	
	
	printSpaceDetails(reservation, space, location, showMap) {
		
		var elements = [];
		
		if (!space) return elements;
		
		elements.push(
			<Link to={'/space/' + space.workspace_id}>
				<div className="book-toprow mb-3">
					<div style={{height: (window.innerWidth > 440) ? 100 : 83}}>
						<div className="space-div" style={{flexDirection: "row"}}>
							<img className="space-resrv-img"
							     src={"https://fluidmeet.s3.amazonaws.com/venue_logos/" + space.image}/>
							<div className="space-details pt-0" style={{height: 'auto'}}>
								<div className="space-rate">
									<FontAwesomeIcon icon={faStar}
									                 style={{color: (space.workspace_rating && space.workspace_rating > 0) ? '#00a9e0' : '#E0E0E0'}}/>{" "}
									{
										(space.workspace_rating && space.workspace_rating > 0) ? (
											space.workspace_rating
										) : (
											// (space.date_added) ? (
											//   isSpaceOld(space.date_added)
											// ) : (
											""
											// )
										)
									}
									{(space.reviews > 0) ? (
										<span className="full">({space.reviews})</span>
									) : (
										<></>
									)}
								</div>
								<span className="space-location">{space.front_address}</span>
								<span className="space-title">{space.name}</span>
								{(showMap) ? (
									<div className="space-location">{printSpaceData(space.workspace_type_id).name}</div>
								) : (
									<div className="space-location">{space.venue_name}</div>
								)}
							</div>
						</div>
					</div>
				</div>
				{(showMap && (
					<>
						<div className="space-text pt-1">{space.venue_type_name}</div>
						<div className="space-text">{space.venue_name}</div>
						<div className="space-text pb-3">{space.venue_address}</div>
					</>
				))}
			</Link>
		);
		
		if (!showMap) return elements;
		
		elements.push(
			<div className="map-cont">
				{/*<LoadScript googleMapsApiKey='AIzaSyBDM449V7qE4OkyoV2zP3xm_VQIYDvrHVw'>*/}
				<GoogleMap
					options={{
						styles: styles,
						keyboardShortcuts: false,
						scaleControl: true,
						draggable: true,
						typeControl: true,
						gestureHandling: "greedy",
						mapTypeControl: true,
						mapTypeControlOptions: {position: 1},
						zoomControl: true,
						zoomControlOptions: {position: 8},
						maxZoom: 11,
					}}
					mapContainerStyle={{height: "100%", width: "100%"}}
					zoom={12}
					center={location}>
					<Marker name={space.workspace_id} position={location} icon={markerIcon}/>
				</GoogleMap>
				{/*</LoadScript>*/}
			</div>
		);
		
		return elements;
	}
	
}

export default connect((state, props) => ({
	defCurrency: state.settings.defCurrency,
}), null)(withRouter(ReservationDetails));
