import React, {Component} from "react";
import {Link, Redirect} from "react-router-dom";
import {createAlert, devLog, getApiLinkV2, getUser, setUser} from "src/Helpers";
import logo from '../../img/fullLogo.png';
import google from '../../img/google.svg';
import facebook from "../../img/fb.svg";
import linkedin from "../../img/in.svg";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import HostSignIn from "./HostSignIn";
import Reset from "./Reset";
import queryString from 'query-string';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from "react-google-login";
import LinkedinLogin from "react-linkedin-login-oauth2";
import axios from "axios";
import {getAxios} from "src/Helpers";
import User from "./User";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import Bugsnag from "@bugsnag/js";
import ReactGA from "react-ga-neo";
import clsx from "clsx";

class LogIn extends Component {

  constructor(props) {
    super(props);

    this.state = {
      user: null,
      social: true,
      reset: false,
      reqFBLoading: false,
      reqGoogleLoading: false,
      reqLinkedInLoading: false,
    }

    this.signInCallback = this.signInCallback.bind(this);
    this.toggleSocial = this.toggleSocial.bind(this);
    this.resetCallback = this.resetCallback.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
    this.responseGoogle = this.responseGoogle.bind(this);
    this.responseLinkedIn = this.responseLinkedIn.bind(this);
    this.responseSocialAuth = this.responseSocialAuth.bind(this);
  }

  componentDidMount() {
  
    const {page, location} = this.props;
    this.setState({user: getUser()});

    if (page == "signup") {
      document.title = "Fluidmeet | Sign Up";
      let query = new URLSearchParams(location.search)
      let signUpParams = {
        fname: query.get('fname'),
        lname: query.get('lname'),
        email: query.get('email'),
        user_type: query.get('user_type'),
      }
      this.setState({signUpParams, social: !(signUpParams.email && signUpParams.fname && signUpParams.lname)})
  
    } else if (page == "reset") {
      if (queryString.parse(location.search).token) {
        this.setState({social: false});
      }
      document.title = "Fluidmeet | Reset Password";
    } else {
      document.title = "Fluidmeet | Log In";
    }

    window.scrollTo(0, 0);
    //ReactGA.pageview(window.location.pathname + window.location.search);
  }

  signInCallback(user) {
    this.setState({user: user});
  }

  resetCallback() {
    this.setState({reset: true});
  }

  toggleSocial(show) {
    this.setState({social: show});
  }

  responseFacebook(response) {

    this.responseSocialAuth('facebook', response);
  }

  responseGoogle(response) {

    this.responseSocialAuth('google', response);
  }

  responseLinkedIn(response) {

    this.responseSocialAuth('linkedin', response);
  }

  responseSocialAuth(provider, response) {

    if (provider === 'linkedin' && response) {
      response.accessToken = response.code;
    }
    if (provider === 'google' && response && response.error === 'idpiframe_initialization_failed') {
      return;
    }

    if (!response || !response.accessToken) {
      createAlert(this, 'danger', 'Error: ', 'Could not connect to '+provider+' service');
      return
    }

    this.setState({reqFBLoading: true});

    const url = getApiLinkV2('auth/signup');

    getAxios().post(url, {
      authProvider: provider,
      userID: response.userID,
      redirect_uri: 'https://www.fluidmeet.com/linkedin',
      accessToken: response.accessToken,
      picture: (response.picture) ? response.picture.data.url : ''
    }, {
      headers: { 'Content-Type': 'application/json' },
    }).then(response => {
        devLog(response);
        const user = response.data.userData;
        setUser(user, response.data.token);
        Bugsnag.setUser(user.user_id, user.email, user.first_name+" "+user.last_name);
        ReactGA.set({ userId: user.user_id });
        if (window.fcWidget) {
          window.fcWidget.setExternalId("user_"+user.user_id);
          window.fcWidget.user.setFirstName(user.first_name);
          window.fcWidget.user.setFirstName(user.last_name);
          window.fcWidget.user.setEmail(user.email);
        }
        this.setState({user: new User(user)});
      })
      .catch(error => {
        Bugsnag.notify(error);
        devLog(error.response)
        createAlert(this, 'danger', (error.response && error.response.data.error) ? 'Error: ' : '', (error.response && error.response.data.error) ? error.response.data.error : error.message);
      }).then(() => {
      this.setState({reqLoading: false});
    });

  }


  render() {
  
    const {user, reset, reqAlert, signUpParams} = this.state;
    const {location} = this.props;

    const prevLink = (location.state && location.state.prevLocation ) ? (location.state.prevLocation.pathname+"?"+location.state.prevLocation.search) : '';

    if (reset) {
      return (<Redirect to='/login' />);
    }

    if (user) {
      let query = new URLSearchParams(window.location.search);
      let redirect = query.get('redirect')
      if (redirect) {
        console.log('redirect', {type: user.type, redirect})
        return (<Redirect to={decodeURIComponent(redirect)}/>);
      }
      if (user.type === 'host') {
        console.log('redirect', {type: user.type,})
        return (<Redirect to={'/host-dashboard'}/>);
      }
      if (prevLink !== '/list-my-space?')
        return (<Redirect to={(prevLink === '') ? '/' : prevLink}/>);
    }

    return (
      <>

        <div className="login-container justify-content-center">
          <div className="container display-inherit">
            <div className="form-signin">

              <div className="logo-cont center">
                <Link to={"/"}>
                  <img className="logo" src={logo} alt=""
                       width={(window.innerWidth < 441) ? "126" : "163"}
                       height={(window.innerWidth < 441) ? "70" : "90"} />
                </Link>
              </div>

              {(this.props.page === "signup") ? (
                  <div
                      className={clsx({'d-flex flex-column align-items-center justify-content-center': !this.state.social})}>
                    <h1 className="h1 mt-4 mb-3 font-weight-semi-bold">Create Account</h1>
                    <h1 className=" fl_subtitle h5 mb-3 font-weight-normal">Set up your new account</h1>
                  </div>
              ) : ((this.props.page === "reset") ? (
                <>
                  <h1 className="h1 mt-4 mb-3 font-weight-semi-bold">Reset Password</h1>
                  {(queryString.parse(location.search).token) ? (
                    <h1 className=" fl_subtitle h5 mb-3 font-weight-normal">Create a new password</h1>
                  ) : (
                    <h1 className=" fl_subtitle h5 mb-3 font-weight-normal">A reset link will be sent to your email</h1>
                  )}
                </>
              ) : ( this.props.page === "host-signin" ? (
                <>
                  <h1 className="h1 mt-4 mb-3 font-weight-semi-bold text-center">Host Dashboard</h1>
                  <h1 className=" fl_subtitle h5 mb-3 font-weight-normal text-center">Log in to your account</h1>
                </>
              ) : (
                <>
                  <h1 className="h1 mt-4 mb-3 font-weight-semi-bold">Welcome Back</h1>
                  <h1 className=" fl_subtitle h5 mb-3 font-weight-normal">Log in to your account</h1>
                </>
              )))}
  
              <div className={clsx("form-cont mt-5", {'justify-content-center': !this.state.social})}>
    
    
                {this.props.page != "host-signin" &&
                  <div className={"form-half  " + ((this.state.social) ? "bl-1 form-pr-5" : "col-md-6")}>
                    {(this.props.page === "signup") ? (
                        <SignUp signInCallback={this.signInCallback} {...signUpParams}/>
                    ) : ((this.props.page === "reset") ? (
                        <Reset location={location} toggleSocial={this.toggleSocial} resetCallback={this.resetCallback}/>
                    ) : (
                        <SignIn signInCallback={this.signInCallback}/>
                    ))}
                  </div>
                }

                {this.props.page === "host-signin" &&
                <div className="form-half col-md-6 offset-md-3 ">
                   <HostSignIn signInCallback={this.signInCallback}/>
                </div>
                }
    
    
                {(this.state.social && this.props.page != "host-signin") ? (
                    <div className="form-half form-t form-pl-5">
          
                      <div className="firebaseui-card-content">
                        <ul className="firebaseui-idp-list mt-0">
              
                          <FacebookLogin
                              appId="1885691228370129"
                              autoLoad={false}
                              disableMobileRedirect={true}
                              buttonText="Login with Facebook"
                              fields="first_name,last_name,name,email,picture"
                              scope="public_profile,email"
                              callback={this.responseFacebook}
                              render={renderProps => (
                            <li className="firebaseui-list-item" onClick={renderProps.onClick}>
                              <div id="fbBtn" className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-facebook firebaseui-id-idp-button">
                <span className="firebaseui-idp-icon-wrapper">
                  <img className="firebaseui-idp-icon fb-icon" alt="" src={facebook} />
                </span>
                                <span className="firebaseui-idp-text firebaseui-idp-text-long">Log in with Facebook</span>
                              </div>
                            </li>
                          )}
                        />

                        <GoogleLogin
                          clientId="997352301010-5ofgt72i6fl96k1rdc652lubk2vi3vha.apps.googleusercontent.com"
                          autoLoad={false}
                          isSignedIn={false}
                          buttonText="Login with Google"
                          scope="profile email"
                          onSuccess={this.responseGoogle}
                          onFailure={this.responseGoogle}
                          cookiePolicy={'single_host_origin'}
                          render={renderProps => (
                            <li className="firebaseui-list-item" onClick={renderProps.onClick}>
                              <div id="googleBtn" className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-password firebaseui-id-idp-button">
                    <span className="firebaseui-idp-icon-wrapper">
                      <img className="firebaseui-idp-icon" alt="" src={google} />
                    </span>
                                <span className="firebaseui-idp-text firebaseui-idp-text-long">Log in with Google</span>
                              </div>
                            </li>
                          )}
                        />

                        <LinkedinLogin
                          clientId="81d2ju1vhzhcen"
                          buttonText="Log in with Linked In"
                          scope="r_emailaddress,r_liteprofile"
                          onFailure={this.responseLinkedIn}
                          onSuccess={this.responseLinkedIn}
                          redirectUri="https://www.fluidmeet.com/linkedin"
                          renderElement={renderProps => (
                            <li className="firebaseui-list-item" onClick={renderProps.onClick}>
                              <div id="googleBtn" className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-linkedin firebaseui-id-idp-button">
                    <span className="firebaseui-idp-icon-wrapper">
                      <img className="firebaseui-idp-icon" alt="" src={linkedin}/>
                    </span>
                                <span
                                    className="firebaseui-idp-text firebaseui-idp-text-long">Log in with LinkedIn</span>
                              </div>
                            </li>
                          )}
                        />
            
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
  
              </div>

              <p className="text-foot mt-5 mb-2"> &copy; 2021 Fluidmeet, Inc. All rights reserved.</p>
              <p className="text-foot">By continuing, you are indicating that you accept our{" "}
                <Link to={"/terms"} className="firebaseui-link firebaseui-tos-link">Terms of Use</Link>
                {" "},{" "}
                <Link to={"/privacy-policy"} className="firebaseui-link firebaseui-pp-link">Privacy Policy</Link>
                {" "}and{" "}
                <Link to={"/refund"} className="firebaseui-link firebaseui-pp-link">Cancellation Policy</Link>
                {"."}
              </p>

            </div>
          </div>
        </div>

        {(reqAlert && (
          <div id="alerts">
            <div className={"alert alert-"+reqAlert.type+" alert-dismissible floating-alert fade show"}>
              <strong>{reqAlert.title}</strong> {reqAlert.message}
              <button type="button" className="btn-close" onClick={() => this.setState({reqAlert: null})}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </div>
        ))}

      </>
    );
  }
}

export default LogIn;
