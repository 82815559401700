import React, {Component} from 'react';

class Cancellation extends Component {

  componentDidMount() {
    //ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {

    const { match, location } = this.props;

    return (
      <div className="container-fluid contain bg-white mt-80 pr-0 pl-0">
        <div className="container page-contain">
          <section className="page">

            <h1 className="page-title mt-4 mb-5">Cancellation Policy</h1>

            <h6 className="bold mt-4">Space only bookings:</h6>
            <ul>
              <li className="page-desc">
              If a user cancels 48 hours or less after making the reservation and provided date of cancellation is more than 48 hours from date event, reservation amount will be fully refunded less 5% transaction fee; otherwise no refund is applicable and full reservation amount is charged in case of cancellation.
              </li>
              <li className="page-desc">
              If a reservation is made for a date 48 hours or less from date of event then no refund is applicable and reservation amount is fully charged in case of cancellation.
              </li>
              <li className="page-desc">
              If a user cancels after the reservation starts, no refund is applicable and reservation amount is fully charged in case of cancellation.
              Reservations may be postponed if written notice is given AND the venue agrees to postponement. Postponement request cannot be submitted and granted if booking is less than 48 hours away from time of request.
              </li>
              <li className="page-desc">
              If there is a complaint from either party, notice must be given to <strong>Fluidmeet</strong> within 48 hours of the reservation start time. <strong>Fluidmeet</strong> will mediate when necessary, and has the final say in all disputes.
              </li>
              <li className="page-desc">
              A reservation is not officially canceled until the user receives a cancellation confirmation e-mail from <strong>Fluidmeet</strong>. If the cancellation e-mail is not received, you should contact <strong>Fluidmeet</strong>.
              </li>
              <li className="page-desc">
              A reservation is not officially postponed until the user receives a postponement confirmation e-mail from <strong>Fluidmeet</strong>. If the postponement e-mail is not received, you should contact <strong>Fluidmeet</strong>.
              </li>
              <li className="page-desc">
              A reservation cannot be cancelled before a venue host has responded to booking request email (normally a response is received within a day).
              </li>
            </ul>
            <h6 className="bold mt-4">Space + Food & Beverages bookings:</h6>
            <ul>
              <p className="page-desc mt-3"><strong>Fluidmeet</strong> may support in taking requests to changes in bookings and sharing them with the venue; however:</p>
              <li className="page-desc">
                For cancellations: cancellation policy as per contract signed with the venue is applicable; <strong>Fluidmeet</strong> is not liable for any changes or disputes.
              </li>
              <li className="page-desc">
                For changes in bookings and/or postponement: date amendments as per contract signed with venue is applicable; <strong>Fluidmeet</strong> is not liable for any changes or disputes.
              </li>
              <li className="page-desc">
                For all disputes regarding space, bookings and anything else connected to the booking: <strong>Fluidmeet</strong> may act as facilitator but is not liable or responsible for the outcome. Venue has authority as per contract signed with
              </li>
            </ul>

          </section>
        </div>
      </div>
    );
  }
}

export default Cancellation;
