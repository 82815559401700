import React, {Component} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStar} from '@fortawesome/free-solid-svg-icons';
import {getApiLinkV2, getImageUrl, getSpacePrice, getToken, printPrice, printSpacePricePer} from "../Helpers";
import axios from "axios";
import {getAxios} from "src/Helpers";
import Bugsnag from "@bugsnag/js";
import 'react-lazy-load-image-component/src/effects/blur.css';

export default class Space extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      favourited: null,
    }
  }

  componentDidMount() {

    const { isFav, hideFav, space } = this.props;

    if (isFav || (!hideFav)) {
      this.setState({ favourited: space.favourite });
    }
  }

  handleFavourite(space) {

    const { redirectScreen } = this.props;
    const token = getToken();
    if (!token) {
      if (redirectScreen) {
        redirectScreen();
      }
      return;
    }

    this.setState({ favourited: ((space.favourite === 'no') ? 'yes' : 'no') });

    const { service, isFav, handleRemovedFav } = this.props;

    const url = (service === 'service') ? getApiLinkV2('user/favourite-services') : getApiLinkV2('user/favourite-spaces');

    getAxios().post(url, {
      workspace_id: space.workspace_id,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token,
      },
    }).then(response => {
      if (isFav && handleRemovedFav && !response.data.favourite) {
        handleRemovedFav(space);
      }
      this.setState({ favourited: ((response.data.favourite) ? 'yes' : 'no') });
    })
    .catch(error => {
      Bugsnag.notify(error);
      console.error((error.response && error.response.data.error) ? error.response.data.error : error.message);
    });
  }

  handleSpaceTapped = space => {
  
    const {term, handleSpace} = this.props;
    if (handleSpace)
      handleSpace(space.workspace_id);
    // else
    // window.open("/space/"+space.workspace_id+(term ? "?t="+term : ""), "_blank");
  }

  render() {
  
    const {favourited} = this.state;
    const {id, isMapView, isMapOpen, space, term, food, isLarge, isFav, hideFav, service, fullWidth, currency, allowLink} = this.props;
  
    return (
        <div key={id}
             className={(fullWidth) ? '' : (isMapView) ? ("map-space") : ((isMapOpen) ? "col-xl-4 col-6" : (isLarge) ? "col-lg-4 col-md-6" +
                 " col-6" +
                 " pr-2 pl-2" : "col-lg-15" +
                 " col-md-4 col-6")}>
          <div onClick={() => this.handleSpaceTapped(space)}
               className={((isMapOpen) ? "min-space-item " : (isLarge) ? "lg-space-item " : "space-item ") + ((isFav) ? 'mb-5 pb-0' : '')}>
  
            <a className={"space-div " + ((isMapView) ? "mb-0 pb-0" : " ")} target={"_blank"}
               href={(allowLink) ? ("/space/" + space.workspace_id + (term ? "?t=" + term : "")) : null}>
  
              <picture className="space-img">
                <source media="(max-width:576px)" srcSet={getImageUrl(space.image, '_384.webp')} type={'image/webp'}/>
                <source media="(max-width:768px)" srcSet={getImageUrl(space.image, '_384.webp')} type={'image/webp'}/>
                <img className={'h-100 w-100'} style={{borderRadius: 10}} src={getImageUrl(space.image)}
                     loading={"lazy"} onError={
                  (event) => {
                    let image: HTMLImageElement = event.target;
                    image.onerror = null;
                    image.parentNode.children[0].srcset = image.parentNode.children[1].srcset = image.src
                  }
                }/>
              </picture>
  
              {/*<LazyLoadImage*/}
              {/*    className="space-img"*/}
              {/*    effect="blur"*/}
              {/*    src={"https://fluidmeet.s3.amazonaws.com/venue_logos/" + space.image}/>*/}
              {/*<div style={{position:'absolute',top: 0,left: 10,right: 10,height: 'calc(100% - 140px)'}} />*/}
  
              <div className="space-details">
    
                <div className="space-rate">
                  <FontAwesomeIcon icon={faStar}
                                   style={{color: (space.workspace_rating && space.workspace_rating > 0) ? '#00a9e0' : '#E0E0E0'}}/>{" "}
                  {(space.workspace_rating && space.workspace_rating > 0) ? (
                      space.workspace_rating
                  ) : (
                      // (space.date_added) ? (
                      //   isSpaceOld(space.date_added)
                      // ) : (
                      ""
                      // )
                  )}
                  {(space.reviews > 0) ? (
                      <span className="full">({space.reviews})</span>
                  ) : (
                      <></>
                  )}
                </div>
              
                <span className="space-location">{space.front_address}</span>
              
                <span className="space-title">{space.name}</span>
              
                {(!isFav && (
                    <span className={"space-price " + ((isMapView) ? "mb-0 pb-0" : " ")}>
                      <span className="price">
                        {printPrice(getSpacePrice(space, service, term), ((space.currency) ? space.currency : 'USD'), currency.name, true)}
                      </span>
                      {printSpacePricePer(space, service, term)}
                  </span>
                ))}
            
              </div>
          
            </a>
        
          </div>

        {(!hideFav && (
          <div className="space-favorite" onClick={() => this.handleFavourite(space)}>
            <div className={(isFav || favourited == 'yes') ? 'favact-img': 'fav-img'} />
          </div>))}
        {(space.featured == 'yes') ? (
          <div className="space-featured">
            <div className="trending-img" />
          </div>
        ) : (
          <></>
        )}

      </div>
    );
  }
}
