import React, {Component} from 'react';

class Privacy extends Component {

  componentDidMount() {
    //ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {

    const { match, location } = this.props;

    return (
      <div className="container-fluid contain bg-white mt-80 pr-0 pl-0">
        <div className="container page-contain">
          <section className="page">

            <h1 className="page-title mt-4 mb-5">Privacy Policy</h1>

            <p className="page-desc mt-3">
              This privacy policy describes how <strong>Fluidmeet FZ LLC</strong> (<strong>Fluidmeet</strong>) collects and uses the personal information you provide on our web site www.fluidmeet.com and Mobile Software. It also describes the choices available to you regarding our use of your personal information and how you can access and update this information. By using, accessing or participating in the Service, you agree to the terms of this Privacy Policy. Capitalized terms not defined in this Privacy Policy have the meanings set forth in the Terms of Use (located at fluidmeet.com/Home/Terms-Of-Use).
            </p>
            <p className="page-desc mt-3">
            We reserve the right to change our Privacy Policy at any time. If we do this, we will post a notice that we have made material changes to this Privacy Policy on the Site for at least 7 days before the changes are posted and will indicate at the bottom of the Privacy Policy the date this policy was last revised. Any revisions to this Privacy Policy will become effective (i) on the effective date or (ii) the first time you access or use the Service after any such changes. If you do not agree to abide by this or any future Privacy Policy, do not use, access or participate in (or continue to use, access or participate in) the Service. We encourage you to periodically review this page for the latest information on our privacy practices.
            </p>

            <h6 className="bold mt-4">The Information We Collect</h6>
            <p className="page-desc mt-3">
            When you use the Service you provide us with two types of information: (i) information you submit via the Service and (ii) information regarding your use of the Service collected by us as you interact with the Service.
            </p>
            <p className="page-desc mt-3">
              When you enter the Site, we collect your browser type and IP address. This information is gathered for all <strong>Fluidmeet</strong> visitors. In addition, we store certain information from your browser using "cookies." A cookie is a piece of data stored on the user's computer tied to information about the user. We use session ID cookies to confirm that users are logged in. We also use persistent cookies to remember sign-in status, coupon availability, search preferences, and venue origination information so that you do not have to enter it again the next time you visit. You can remove persistent cookies by following directions provided in your Internet browser's "help" directory. If you do not want information collected through the use of cookies, there is a simple procedure in most browsers that allows you to deny or accept the cookie feature; however, you should note that cookies may be necessary to provide you certain features (e.g., customized delivery of information) available on the Site. The use of cookies by our partners, affiliates, tracking utility company, service providers is not covered by our privacy policy. We do not have access or control over these cookies. Our third party partners employ clear gifs (a.k.a. Web Beacons/Web Bugs), images, and scripts that help them better manage content on our site. We use Google Analytics to track application usage, performance, and availability. We do not tie the information gathered to our Customers 'or Users' personally identifiable information.
            </p>
            <p className="page-desc mt-3">
            Through the registration process you may provide us with your name, company affiliation, email address, telephone number, physical address, and other information that may be requested during the registration process on the Site. If you wish to create an account that requires payment of a fee, or use services offered via the Site that require payment of a fee, a valid credit card number or other payment information may be required. By submitting your payment information, you authorize <strong>Fluidmeet</strong> to access and use this information in order to provide you with and accept payment for the Service.
            </p>
            <p className="page-desc mt-3">
            When you use the Service you may submit information and content to your Profile, send messages, perform searches, submit reviews, and otherwise transmit information to other Users. We store this information and registration information so that we can provide you the service and offer personalized features.
            </p>
            <p className="page-desc mt-3">
            We are not responsible for circumvention of any privacy settings or security measures contained on the Site. You understand and acknowledge that, even after removal, copies of User Content may remain viewable in cached and archived pages or if other Users have copied or stored your User Content.
            </p>
            <p className="page-desc mt-3">
            If you use the Mobile Software, your location may be tracked on the mobile device on which the Mobile Software is installed. <strong>Fluidmeet</strong> will not collect, transmit or otherwise use this information without your permission. We use location data to provide you relevant search results for spaces in your area. As a Member, you can control when Venue Owners or other Members can see your location through your privacy settings. To opt out of location tracking, please change your settings at the device level.
            </p>
            <p className="page-desc mt-3">
            When you download and use our Mobile Software, we automatically collect information on the type of device you use, operating system version, and the device identifier (or "UDID").
            </p>

            <h6 className="bold mt-4">Minors</h6>
            <p className="page-desc mt-3">
            <strong>Fluidmeet</strong> does not knowingly collect or solicit information from anyone under the age of 18 or knowingly allow such persons to register. If you are under 18, please do not attempt to register for <strong>Fluidmeet</strong> or send any information about you to us, including your name, address, telephone number, or email address. No one under age 18 may provide any information to or on <strong>Fluidmeet</strong>. If you believe that we might have any information from or about a minor under 18, please contact us at privacy@fluidmeet.com.
            </p>

            <h6 className="bold mt-4">Use of Information Obtained by <strong>Fluidmeet</strong></h6>
            <p className="page-desc mt-3">
            Once you register with <strong>Fluidmeet</strong>, you may create your Profile. Your Profile information, as well as your contact information, are displayed to other Users, including Venue Owners at whose Venues you make or request reservations. We may occasionally use your contact information to send you notifications regarding new services offered by <strong>Fluidmeet</strong> and its partners that we think you may find valuable. <strong>Fluidmeet</strong> may also send you service-related announcements from time to time through the general operation of the Service. Generally, you may opt out of such emails by following the unsubscribe option included in each email, although <strong>Fluidmeet</strong> reserves the right to send you notices about your account even if you opt out of all voluntary email notifications. <strong>Fluidmeet</strong> provides push notifications for upcoming reservations. To turn off push notifications, please edit your personal settings on your device.
            </p>
            <p className="page-desc mt-3">
            Profile information and your reservation activity is used by <strong>Fluidmeet</strong> primarily to be presented back to and edited by you when you access the Service and to be presented to other Users. The amount of such information presented to other Users will depend on your relationship with those Users (e.g., whether they are following you, or you are following them, as permitted by the Service). In some cases, other Users may be able to supplement your Profile or contribute information about your Venue (if you are a Venue Owner), including by submitting comments and reviews. You consent to <strong>Fluidmeet</strong> making such information available to other Users and the public. You may change the privacy settings of your profile through your account portal. Information from your Profile may be available to our third party service provider for search engines. This is primarily so that potential users may be able to find you.
            </p>
            <p className="page-desc mt-3">
            <strong>Fluidmeet</strong> may use only non-identifiable aggregate data (based partly on IP addresses) collected through the Service to analyze Service usage and system performance. Such data may be used by <strong>Fluidmeet</strong> and shared with third parties in any manner.
            </p>
            <p className="page-desc mt-3">
            Sharing Your Information with Third Parties
            You may choose to provide information about you, your company or organization or, if you are a Venue Owner, your Venue to other Users and to the public by providing such information on public areas of the Site ("Public Information"). Public Information consists of information that is posted by you on areas of the Site accessible by other Users and/or the public, such as Venue descriptions. Public information does not include information you submit to us but do not post on areas of the Site accessible by other Users and/or the public, such as billing and payment information ("Non-Public Information").
            </p>
            <p className="page-desc mt-3">
            We will share your personal information with third parties only in the ways that are described in this privacy policy. We do not sell your personal information to third parties. We share your Non-Public Information with third parties only in limited circumstances where we believe such sharing is reasonably necessary to offer the Service, legally required or, permitted by you. For example:
            </p>
            <ul>
              <li className="page-desc">
            We may provide Non-Public Information to service providers who help us bring you the services we offer, such as hosting the service at a co-location facility, sending email updates about <strong>Fluidmeet</strong>, removing repetitive information from our User lists, processing payments, or to provide search results or links. In connection with these offerings and business operations, our service providers may have access to your Non-Public Information for use for a limited time in connection with these business activities. Where we utilize third parties for the processing of any of your Non-Public Information, we implement reasonable contractual protections limiting the use of that Non-Public Information to the provision of services to <strong>Fluidmeet</strong>.
              </li>
              <li className="page-desc">
            We may be required to disclose Non-Public Information pursuant to lawful requests, such as subpoenas or court orders, or in compliance with applicable laws. Additionally, we may share Non-Public Information when we believe it is necessary to comply with law, to protect our interests or property, to prevent fraud or other illegal activity perpetrated through the Service or using the <strong>Fluidmeet</strong> name, or to prevent imminent harm. This may include sharing Non-Public Information with other companies, lawyers, agents, government agencies or to any third party with your prior consent to do so..
              </li>
              <li className="page-desc">
            We may offer to provide services jointly with other companies on the Service, and we may share Non-Public Information with that company in connection with such offer or your use of that service. If the ownership of all or substantially all of the <strong>Fluidmeet</strong> business, or individual business units or assets owned by <strong>Fluidmeet</strong> that are related to the Site, were to change, your Non-Public Information may be transferred to the new owner. In any such transfer of information, your Non-Public Information would remain subject to this Privacy Policy. You will be notified via email and/or a prominent notice on our web site of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information..
              </li>
              <li className="page-desc">
            If we have a separate agreement with a company we believe to be your employer or company for whom we believe you work as a contractor (as determined in each case by your email domain), we may provide your User ID, email address and reservation activity to that company so that you may be included within an arrangement that we have with such company. We will notify you if we provide this information to a company and you may elect not to participate in any such arrangement by contacting us at support@fluidmeet.com and stating that you do not wish to participate in such arrangement.
              </li>
            </ul>
            <p className="page-desc mt-3">
            When you use the Service, certain information you post or share with third parties (including other Users), such as comments, reviews or other information, may be shared with other Users in accordance with the privacy settings you select. All such sharing of information is done at your own risk. Please keep in mind that if you disclose confidential information in your Profile or when posting comments, reviews or other submissions, this information may become publicly available.
            </p>
            <p className="page-desc mt-3">
            Your posting of Public Information to public areas of the Site constitutes your consent to share such Public Information publicly. Please be aware that Public Information will be accessible by both Users and the general public. Nothing in this Privacy Policy shall restrict <strong>Fluidmeet</strong>'s right to use Public Information. To request removal of your personal information from public areas of the Site, please contact us. In some cases, we may not be able to remove your personal information, in which case we will let you know if we are unable to do so and why.
            </p>

            <h6 className="bold mt-4">Links</h6>
            <p className="page-desc mt-3">
            <strong>Fluidmeet</strong> may contain links to other websites. We are not responsible for the privacy practices of other web sites. We encourage Users to be aware when they leave the Site to read the privacy statements of other web sites that collect personally identifiable information. This Privacy Policy applies solely to information collected by <strong>Fluidmeet</strong> via the Site.
            </p>

            <h6 className="bold mt-4">Single Sign-on</h6>
            <p className="page-desc mt-3">
            You can log in to our site using sign-in services such as an Open ID provider. These services will authenticate your identity and provide you the option to share certain personal information with us such as your name and email address to pre-populate our sign up form. Services like LinkedIn Open ID provider give you the option to post information about your activities on this web site to your profile page to share with others within your network.
            </p>

            <h6 className="bold mt-4">Social Media Widgets</h6>
            <p className="page-desc mt-3">
            Our web site includes social media features, such as the Facebook Like button and widgets, such as the "ShareThis" button or interactive mini-programs that run on our site. These features may collect your IP address, which page you are visiting on our site, and may set a cookie to enable the feature to function properly. Social media features and widgets are either hosted by a third party or hosted directly on our site. Your interactions with these features are governed by the privacy policy of the company providing it.
            </p>

            <h6 className="bold mt-4">Third Party Advertising</h6>
            <p className="page-desc mt-3">
            Advertisements that appear on <strong>Fluidmeet</strong> are sometimes delivered (or "served") directly to Users by third party advertisers. They automatically receive your IP address when this happens. These third party advertisers may also download cookies to your computer, or use other technologies such as JavaScript and "web beacons" (also known as "1x1 gifs") to measure the effectiveness of their ads and to personalize advertising content. Doing this allows the advertising network to recognize your computer each time they send you an advertisement in order to measure the effectiveness of their ads and to personalize advertising content. In this way, they may compile information about where individuals using your computer or browser saw their advertisements and determine which advertisements are clicked. <strong>Fluidmeet</strong> does not have access to or control of the cookies or other technologies that may be used by the third party advertisers. Third party advertisers have no access to your contact information stored on <strong>Fluidmeet</strong> unless you choose to share it with them. If you wish to not have this information used for the purpose of serving you targeted ads, you may opt-out by clicking notifying us. Please note this does not opt you out of being served advertising. You will continue to receive generic ads
            </p>
            <p className="page-desc mt-3">
            This privacy policy covers the use of cookies by <strong>Fluidmeet</strong> and does not cover the use of cookies or other tracking technologies by any of its advertisers.
            </p>

            <h6 className="bold mt-4">Changing or Removing Information</h6>
            <p className="page-desc mt-3">
            Users may modify or delete their Profile information by logging into their account. Removed information may persist in backup copies but will not be generally available to other Users. Where you make use of the communication features of the service to share information with other Users (e.g., sending a message to another User) you generally cannot remove such communications from the Site.
            </p>
            <p className="page-desc mt-3">
            Upon your request, we will close your account and remove your personal information from view as soon as reasonably possible, based on your account activity and in accordance with applicable law. We do retain personal information from closed accounts to comply with law, prevent fraud, collect any fees owed, resolve disputes, troubleshoot problems, assist with any investigations, enforce our Terms of Use and take other actions otherwise permitted by law.
            </p>

            <h6 className="bold mt-4">Security</h6>
            <p className="page-desc mt-3">
            <strong>Fluidmeet</strong> takes reasonable precautions to protect Users' Non-Public Information. Your account information is located on a secured server behind a firewall. When you enter sensitive information, such as a credit card number on our order forms or transmit location data, we encrypt the transmission of that information using secure socket layer technology (SSL). However, no method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore we cannot guarantee absolute security. If you have any questions about security please contact us.
            </p>

            <h6 className="bold mt-4">Data Transfer</h6>
            <p className="page-desc mt-3">
            The data protection laws in the United Arab Emirates differ from those applicable in other countries, including the country in which you reside. By providing <strong>Fluidmeet</strong> with your personal information, you consent to the collection, transfer and processing of your personal information to and from the UAE and agree that your personal information may be subject to access requests from governments, courts or law enforcement in the UAE according to the laws of the UAE.
            </p>

            <h6 className="bold mt-4">Contacting <strong>Fluidmeet</strong></h6>
            <p className="page-desc mt-3">
            If you have any questions about this Privacy Policy or <strong>Fluidmeet</strong>'s privacy practices in general, please contact us as provided for below. You may also contact us to require that we provide you with a copy of your Non-Public Information within our control, to have us correct any erroneous information, to discontinue receipt of certain types of communications or to ask us to delete any of your Non-Public Information we have stored that is no longer required for the purpose for which it was collected. We will respond to your request to access nonpublic information within 30 days.
            </p>

            <h6 className="bold mt-4">Email : privacy@fluidmeet.com</h6>

            <h6 className="bold mt-4">Last revised on March 2nd, 2015</h6>

          </section>
        </div>
      </div>
    );
  }
}

export default Privacy;
