import React from 'react';
import {Theme} from "@material-ui/core/styles";
import {blue, blueGrey, green, red} from "@material-ui/core/colors";
import {makeStyles} from "@material-ui/styles";
import {getCurrency} from "src/Helpers";
import {SpaceItemEnhanced} from "src/components/host/host-dashboard/manage-listings/SpaceItem";
import clsx from 'clsx';
import {MainStyles} from "src/components/host/mobileStyles";
import {Space} from "src/APIs/venue/space";
import {Venue} from "src/APIs/venue/venue";
import {VenueControl} from "src/APIs/venue/venue-control";
import {EditVenue} from "src/components/host/host-dashboard/manage-listings/add-edit-venues/edit-venue";
import {useHistory} from 'react-router-dom';

const styles = {
	rootView: {
		flex: 1,
	},
	content: {
		flex: 1,
		paddingHorizontal: 6,
	},
	hostCont: {
		borderRadius: 6,
		backgroundColor: '#eef2f5',
		borderColor: '#d4e1ee',
		borderWidth: 0.5,
		marginTop: 20,
		marginBottom: 12,
		marginHorizontal: 10,
		paddingHorizontal: 16,
		paddingVertical: 10,
		alignItems: 'center',
	},
	hostDesc: {
		flexGrow: 1,
		flexShrink: 1,
	},
	hostImg: {
		borderRadius: 40,
		backgroundColor: '#FFFFFF',
		width: 54,
		height: 54,
		marginRight: 16,
		justifyContent: 'center',
		alignItems: 'center',
	},
	textTitle: {
		fontSize: 19,
		marginTop: 3,
		color: blueGrey[700],
		fontWeight: 600,
	},
	textSubtitle: {
		fontSize: 16,
		fontWeight: 500,
		color: blueGrey[500],
		marginBottom: 6,
	},
	textTinySubtitle: {
		fontSize: 15,
		fontWeight: 500,
		color: blueGrey[500],
		marginTop: 8,
		marginBottom: 4,
	},
	textValue: {
		fontSize: 15,
		fontWeight: 500,
		color: blueGrey[500],
		textAlign: 'right' as const,
		marginTop: 8,
		marginBottom: 4,
	},
	divider: {
		height: .5,
		marginTop: 10,
		marginBottom: 20,
		width: '100%',
		backgroundColor: blueGrey[200],
	},
	text: {
		fontSize: 16,
		lineHeight: 18,
	},
	statusView: {
		flexGrow: 0,
		flexShrink: 0,
		paddingVertical: 3,
		paddingHorizontal: 6,
		borderRadius: 4,
	},
	statusText: {
		fontSize: 16,
		fontWeight: 500,
		color: 'white',
	},
	statusGreen: {
		backgroundColor: green[500],
		// backgroundColor: '#097e09',
	},
	statusRed: {
		backgroundColor: red[500],
		// backgroundColor: '#bf0f0f',
	},
	header: {
		flexDirection: 'row',
		backgroundColor: '#FFFFFF',
	},
	headerCont: {
		flex: 1,
		marginBottom: 12,
		flexDirection: 'column',
		alignContent: 'flex-start',
	},
	largeTitle: {
		fontWeight: 700,
		marginTop: 36,
		lineHeight: 34,
		fontSize: 25,
	},
	largeSubtitle: {
		color: "black",
		fontSize: 20,
		marginTop: 14,
	},
	headerText: {
		textAlign: 'left',
		color: 'black',
		fontSize: 19,
		lineHeight: 25,
	},
	submitBtn: {
		height: 54,
		backgroundColor: blue[400],
		marginBottom: 28,
		borderRadius: 10,
		justifyContent: 'center',
	},
	submitBtnView: {
		flex: 0,
		marginRight: 'auto',
		marginLeft: 'auto',
		flexDirection: 'row',
	},
	submitBtnText: {
		flex: 0,
		textAlign: 'center',
		color: 'white',
		fontSize: 17,
		fontWeight: 500,
	},
	success: {
		flex: 1,
		flexDirection: 'row',
		paddingVertical: 10,
		color: '#155724',
		backgroundColor: '#d4edda',
		borderColor: '#c3e6cb',
		borderRadius: 10,
	},
};

export type VenueItemProps = {
	venueControl: VenueControl,
	onAddSpace: (venue: Venue) => any,
}


export function VenueItem({
	                          venueControl,
	                          venueControl: {venue_details, workspaces},
	                          onAddSpace = () => {
	                          },
                          }: VenueItemProps) {

	const classes = useStyles();
	let history = useHistory();


	const handleEditVenue = (venue_details_id: string) => {
		history.push(EditVenue.route.replace(':id?', venue_details_id))
	}





	return (
		<div className={clsx(classes.root,'mx-auto')}>

			<div className={clsx('mt-4 mb-3')}>

				<div className={'d-flex flex-row mx-md-10 justify-content-end align-items-center flex-wrap'}>
					<div className={clsx('col-12 col-md pr-3 ')}>
						<div style={{fontSize: 21, fontWeight: 500}}>
							{venue_details.venue_name}
							{(venue_details.status === 'inactive') && <span className={'mx-2 p-1'} style={{
								backgroundColor: red[300],
								borderRadius: '.25rem',
								fontSize: '.75rem',
								fontWeight: 400,
								color: 'white'
							}}> This venue is deactivated and functionality is limited.  </span>}
						</div>
					</div>
					{venueControl.venue_edit && (
						<div className={clsx('cursor-pointer')} style={{marginTop: 3}}
						     onClickCapture={() => handleEditVenue(venue_details.venue_details_id)}>
							<div style={MainStyles.btn}>Edit Venue</div>
						</div>
					)}
					{venueControl.workspace_add && (
						<div className={clsx('cursor-pointer')}
						     style={{marginLeft: 12, marginTop: 3}}
						     onClickCapture={() => onAddSpace(venue_details)}>
							<div style={MainStyles.btn}>Add Space</div>
						</div>
					)}
				</div>
			</div>


			<div
				className={clsx('d-flex flex-wrap flex-row overflow-auto', (venueControl.workspaces?.length > 0) ? classes.spacesContainer : classes.spacesContainerEmpty)}>
				{
					venueControl.workspaces.map(
						(item: Space, index) => {
							return (
								<div key={item.workspace_id} className={clsx('col-12 col-lg-4 col-md-6')}>
									<SpaceItemEnhanced space={item} cardClasses={clsx(classes.space, 'my-2')}
									                   isMaster={true}
									                   hideFav={true} id={index} isListing={true}
									                   currency={getCurrency()} venue={venue_details}
									                   edit_perm={venueControl.workspace_edit}
								                   delete_perm={venueControl.workspace_delete}
								/>
								{/*<Typography>*/}

								{/*</Typography>*/}
							</div>

						)
					}
				)
			}
			</div>


		</div>
	);

}


const useStyles = makeStyles(
	(theme: Theme) => (
		{
			root: {
				maxWidth: "95%",
				width: "95%",
				borderRadius: '.5rem',
				backgroundColor: 'rgba(227,242,252,0.3)',
				padding: '1rem 1rem',
				margin: '2rem 0',
			},
			media: {
				height: 0,
				paddingTop: '56.25%', // 16:9
			},
			expand: {
				transform: 'rotate(0deg)',
				marginLeft: 'auto',
				transition: theme.transitions.create('transform', {
					duration: theme.transitions.duration.shortest,
				}),
			},
			expandOpen: {
				transform: 'rotate(180deg)',
			},
			avatar: {
				backgroundColor: blueGrey[300],
			},
			count: {
				borderRadius: '40%',
				padding: '.2rem 0.5rem',
				fontSize: '.8rem',
				backgroundColor: blue[300],
				verticalAlign: 'middle',
			},
			title: {
				verticalAlign: 'middle',
			},
			iconButton: {
				marginLeft: 'auto',
				alignSelf: 'middle',

			},
			spacesContainer: {
				height: '30rem',
				maxHeight: '50vh',
			},
			spacesContainerEmpty: {
				paddingVertical: '15',
				maxHeight: '50vh',
			},
			space: {
				height: '25rem',
				borderRadius: '.5rem',
			}

		}
	)
)


