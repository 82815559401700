import React from 'react';
import {FetchHostsResponse, HostAPI} from 'src/APIs/HostAPI';
import {IconButton,} from "@material-ui/core";
import {Theme, withStyles} from '@material-ui/core/styles';
import {blue, blueGrey} from "@material-ui/core/colors";
import {Styles} from "@material-ui/styles";
import {HostUserEnhanced} from "./host-user";
import {ReactComponent as AddCircleSVG} from "src/img/add_circle.svg";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {HostDashboardRoutePrams} from "../host-dashboard";
import {AddNewHost} from "src/components/host/host-dashboard/manage-hosts/add-edit-host/add-new-host";
import {AlertComponent, AlertRequest} from "src/components/host/host-dashboard/Alert";
import Tooltip from "@material-ui/core/Tooltip";


export type ManageHostsPages = 'add-host' | 'edit-host'
export interface ManageHostsProps  extends RouteComponentProps<HostDashboardRoutePrams> {
    classes?:any;
}
export type ManageHostsState = {
    reqAlert?: AlertRequest;
} & FetchHostsResponse & AlertRequest;


const _defaultState = {status:''}
const _defaultProps = {}

export class ManageHosts extends React.Component<ManageHostsProps,ManageHostsState> {
    state = _defaultState as ManageHostsState;
    //defaultProps = _defaultProps;

    static key = 'manage-hosts'
    static route = `/${ManageHosts.key}`

    componentDidMount() {
        this.refresh()
    }

    addNewHost = () => {
        let {history} = this.props;
        history.push(AddNewHost.route)
    }
    refresh = async () => {
        let response = await HostAPI.fetchHosts()
        this.setState(response)
    }

    render() {
        let {reqAlert, status, users} = this.state;
        let {classes, history, match} = this.props;
        return (
            <div className="container bg-white mt-40 pr-0 pl-0">
                <div className="page ">

                    <div className={`row page-lg-title mt-4 mb-5 ${classes.title}`}>
                        <div className={"col-11"}>
                            Manage Hosts <span className={classes.count}>{users?.length ?? 0}</span>
                        </div>
                        {users &&
                            <div className={"col-1 flex"}>
                                <Tooltip title={"Add New Host"}>
                                    <IconButton aria-label="edit" className={`${classes.iconButton}`}
                                                onClickCapture={this.addNewHost}>
                                        <AddCircleSVG/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        }
                        {(users?.length === 0 || users === undefined) && 
                            <p style={{fontSize: "14px", color: "red", fontStyle: 'italic', padding: '0px 15px'}}>This permission is not available to you, please contact main admin</p>
                        }
                    </div>

                    {(this.state?.status === "success") &&
                    this.state?.users?.map((user: any) => {
                            return <HostUserEnhanced user={user} parentRef={this} history={history} match={match}/>
                        }
                    )
                    }
                </div>
                <AlertComponent reqAlert={reqAlert} onDismissClicked={() => this.setState({reqAlert: undefined})}/>

            </div>
        );
    }
}

const styles = (theme: Theme)=>
    ({
        root: {
            maxWidth: '80%',
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        avatar: {
            backgroundColor: blueGrey[300],
        },
        count:{
            borderRadius:'40%',
            padding:'.2rem 0.5rem',
            fontSize:'.8rem',
            backgroundColor: blue[300],
            verticalAlign:'middle',
            },
        title: {
            verticalAlign:'middle',
        },
        iconButton:{
            marginLeft:'auto',
            alignSelf:'middle',

        }
    } as Styles<Theme,any, string> )
//@ts-ignore
export const ManageHostsEnhanced =  withRouter(withStyles(styles)(ManageHosts));
