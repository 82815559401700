export const MainStyles = {
	displayNone: {
		display: 'none',
	},
	centerText: {
		textAlign: 'center',
	},
	whiteText: {
		color: 'white',
	},
	grid: {
		marginTop: 10,
		width: '50%',
		flexDirection: 'row',
		flexWrap: 'wrap',
		alignItems: 'flex-start',
	},
	sectionTitle: {
		fontSize: 22,
		fontWeight: 600,
	},
	sectionMDTitle: {
		fontSize: 21,
		marginHorizontal: 20,
		marginBottom: 12,
		fontWeight: 500,
	},
	btn: {
		flex: 0,
		flexGrow: 0,
		textAlign: 'right' as const,
		color: 'black',
		fontSize: 17,
		fontWeight: 400,
		textDecorationLine: 'underline',
	},
	pageItem: {
		flexGrow: 0,
		flexShrink: 0,
		width: 38,
		height: 38,
		borderRadius: 20,
		marginRight: 14,
		marginBottom: 10,
		borderWidth: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#FFFFFF',
		borderColor: '#dee2e6',
	},
	pageItemActive: {
		flexGrow: 0,
		flexShrink: 0,
		width: 38,
		height: 38,
		borderRadius: 20,
		marginRight: 14,
		marginBottom: 10,
		paddingVertical: 10,
		paddingHorizontal: 14,
		borderWidth: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#00a9e0',
		borderColor: '#00a9e0',
	},
	pageItemDisabled: {
		flexGrow: 0,
		flexShrink: 0,
		width: 38,
		height: 38,
		borderRadius: 20,
		marginRight: 14,
		marginBottom: 10,
		paddingVertical: 10,
		paddingHorizontal: 12,
		borderWidth: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#FFFFFF',
		borderColor: '#e8edf1',
	},
	pageItemText: {
		fontSize: 17,
		lineHeight: 19,
		marginTop: 'auto',
		marginBottom: 'auto',
		marginRight: 'auto',
		marginLeft: 'auto',
	},
	divCol: {
		display: 'flex',
		flexDirection: 'column',
		paddingTop: 15,
		paddingLeft: 10,
	},
	divImage: {
		width: '100%',
		// height: (Dimensions.get('window').width - 80),
		borderRadius: 8,
		marginRight: 12,
		position: 'relative',
		backgroundColor: 'white',
		overflow: 'hidden',
	},
	divTitle: {
		flex: 1,
		fontWeight: 600,
		marginTop: 12,
		color: '#212529',
		fontSize: 19,
		overflow: 'hidden',
	},
	detIcon: {
		width: 36,
		height: 36,
		marginRight: 10,
	},
	icon: {
		width: 40,
		height: 40,
		marginRight: 10,
	}
};
export const SpaceStyles = {
	rootViewAct: {
		flex: 1,
		backgroundColor: 'white',
	},
	rootView: {
		flex: 1,
		backgroundColor: '#111',
	},
	header: {
		alignItems: 'center',
	},
	logo: {
		marginTop: 10,
		marginBottom: 10,
		height: 36,
	},
	fixedHeader: {
		display: 'none',
		backgroundColor: 'white',
		borderBottomWidth: 1,
		borderBottomColor: '#000',
		borderStyle: 'solid',
	},
	fixedHeaderAct: {
		position: 'absolute',
		backgroundColor: 'white',
		top: 48,
		left: 0,
		right: 0,
		zIndex: 2,
	},
	fixedSearchView: {
		borderRadius: 24,
		borderWidth: 0.5,
		borderColor: '#CFD8E7',
		textAlign: 'center',
		alignItems: 'center',
		backgroundColor: 'white',
		height: 46,
		paddingTop: 13,
		paddingLeft: 10,
		paddingRight: 10,
		marginTop: 10,
		marginBottom: 10,
		marginLeft: 28,
		marginRight: 28,
	},
	displayNone: {
		display: 'none',
	},
	searchView: {
		position: 'absolute',
		borderRadius: 24,
		borderWidth: 0.5,
		borderColor: '#CFD8E7',
		textAlign: 'center',
		alignItems: 'center',
		backgroundColor: 'white',
		height: 46,
		paddingTop: 13,
		paddingLeft: 10,
		paddingRight: 10,
		top: 100,
		left: 28,
		right: 28,
	},
	searchText: {
		fontWeight: 500,
		fontSize: 15,
	},
	searchLogo: {
		width: 18,
		height: 18,
		marginRight: 8,
	},
	dividerView: {
		height: 20,
		width: '100%',
	},
	headView: {
		width: '100%',
		// height: Dimensions.get('window').height - 400,
		marginTop: 10,
	},
	headImage: {
		borderTopRightRadius: 24,
		borderTopLeftRadius: 24,
		backgroundColor: '#777',
	},
	headTitleView: {
		position: 'absolute',
		left: 24,
		bottom: 30,
	},
	headTitle: {
		width: '70%',
		fontSize: 60,
		fontWeight: 700,
		color: 'white',
	},
	headBtn: {
		flexDirection: 'row',
		alignSelf: 'flex-start',
		backgroundColor: 'white',
		paddingTop: 10,
		paddingBottom: 10,
		paddingRight: 15,
		paddingLeft: 15,
		borderRadius: 32,
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 25,
	},
	headBtnText: {
		fontWeight: 600,
		fontSize: 16,
	},
	container: {
		backgroundColor: 'white',
	},
	contentView: {
		marginTop: 20,
	},
	lightContentView: {
		paddingTop: 36,
		paddingBottom: 30,
		marginTop: 20,
		backgroundColor: '#e4f4fd',
		alignItems: 'center',
		justifyContent: 'center',
	},
	darkContentView: {
		paddingTop: 36,
		paddingBottom: 30,
		marginTop: 20,
		backgroundColor: '#117090',
		color: 'white',
		alignItems: 'center',
		justifyContent: 'center',
	},
	centerText: {
		textAlign: 'center',
	},
	whiteText: {
		color: 'white',
	},
	grid: {
		marginTop: 10,
		flex: 1,
		flexDirection: 'row',
		flexWrap: 'wrap',
		alignItems: 'flex-start',
		paddingLeft: 6,
		paddingRight: 6,
	},
	sectionTitle: {
		fontSize: 21,
		fontWeight: 600,
		paddingLeft: 16,
		paddingRight: 16,
		marginBottom: 20,
	},
	sectionSubtitle: {
		fontSize: 16,
		width: '90%',
		fontWeight: 300,
		color: '#697486',
		paddingLeft: 16,
		paddingRight: 16,
		lineHeight: 22,
		marginBottom: 4,
	},
	sectionBtn: {
		fontSize: 16,
		marginTop: 16,
		alignSelf: 'center',
	},
	darkBtn: {
		backgroundColor: '#00a9e0',
	},
	cardCol: {
		position: 'relative',
		width: '50%',
		paddingLeft: 10,
		paddingRight: 10,
		paddingBottom: 20,
	},
	cardView: {

	},
	cardMapView: {
		flexDirection: 'row',
		backgroundColor: 'white',
		borderRadius: 16,
		flexWrap: 'nowrap',
		overflow: 'hidden',
	},
	cardImage: {
		width: '100%',
		// height: (Dimensions.get('window').width - 100) / 2,
		resizeMode: 'cover',
		borderRadius: 15,
	},
	cardImageMap: {
		width: 125,
		height: 110,
		resizeMode: 'cover',
		borderTopLeftRadius: 16,
		borderBottomLeftRadius: 16,
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
	},
	contView: {

	},
	contMapView: {
		flexShrink: 1,
		paddingVertical: 12,
		marginHorizontal: 16,
		flexDirection: 'column',
	},
	cardTitle: {
		fontWeight: 600,
		paddingTop: 10,
		paddingBottom: 10,
		overflow: 'hidden',
		color: '#212529',
		fontSize: 16,
	},
	cardSubtitle: {
		paddingTop: 10,
		color: '#212529',
		fontWeight: 400,
		paddingBottom: 20,
		fontSize: 15,
	},
	spacePrice: {
		fontWeight: 700,
	},
	spaceLgfeatured: {
		position: 'absolute' as const,
		backgroundColor: '#00a9e0',
		borderRadius: 18,
		width: 36,
		height: 36,
		right: 18,
		top: 9,
		zIndex: 10,
	},
	spacefeatured: {
		position: 'absolute' as const,
		backgroundColor: '#00a9e0',
		borderRadius: 16,
		width: 32,
		height: 32,
		right: 1,
		top: -8,
		zIndex: 10,
	},
	spacefavourite : {
		position: 'absolute' as const,
		left: 18,
		top: 12,
		width: 32,
		height: 32,
		zIndex: 10,
	},
	favimg : {
		margin: 4,
		width: 24,
		height: 24,
	},
	trendingimg : {
		marginTop: 'auto',
		marginBottom: 'auto',
		marginLeft: 'auto',
		marginRight: 'auto',
		width: 23,
		height: 23,
		zIndex: 11,
	},
	spaceLgImage: {
		width: '100%',
		// height: (Dimensions.get('window').width) * (55 / 100),
		objectFit: 'cover' as const ,
		borderRadius: 15,
		marginBottom: 10,
	},
	spaceImage: {
		// width: (Dimensions.get('window').width - 50) / 2,
		// height: (Dimensions.get('window').width - 150) / 2,
		objectFit: 'cover' as const ,
		borderRadius: 15,
		marginBottom: 10,
	},
	spaceLgTitle: {
		fontWeight: 400,
		paddingTop: 4,
		overflow: 'hidden',
		color: '#212529',
		fontSize: 18,
		textAlign: 'left' as const,
	},
	spaceTitle: {
		fontWeight: 400,
		paddingTop: 3,
		overflow: 'hidden',
		color: '#212529',
		fontSize: 15,
		textAlign: 'left' as const,
	},
	reservstatus: {
		flexGrow: 0,
		flexShrink: 0,
		height: 25,
		marginTop: 5,
		marginRight: 6,
		paddingHorizontal: 6,
		borderRadius: 6,
		fontSize: 15,
		lineHeight: 21,
		color: '#0e818d',
		borderWidth: 1,
		borderColor: '#0e818d',
	},
	statusblack: {
		color: 'black',
		borderColor: 'black',
	},
	statusgray: {
		color: '#7E7F80',
		borderColor: '#7E7F80',
	},
	statusred: {
		color: '#bf0f0f',
		borderColor: '#bf0f0f',
	},
	statusgreen: {
		color: '#097e09',
		borderColor: '#097e09',
	},
	statusText: {
		paddingTop: 4,
		fontSize: 17,
		fontWeight: 600,
		color: 'black',
	},

	iconView: {
		height: 36,
		width: 36,
		flex: 0,
		objectFit: 'cover' as const ,
		borderRadius: 6,
		backgroundColor: '#f1f3f6',
	},
	iconImg: {
		objectFit: 'contain' as const ,
		marginTop: 'auto',
		marginBottom: 'auto',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
};
