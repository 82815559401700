import React, {Component} from 'react';
import SearchApi from "../SearchApi";
import {Link} from "react-router-dom";
import {getCurrency} from "../../Helpers";
import {connect} from "react-redux";
import Footer from "../organisms/Footer";

class Favourites extends Component {

  constructor(props) {
    super(props);

    this.state = {
      spacesCount: 0,
    }

    this.updateFavResults = this.updateFavResults.bind(this);
    this.decreseFavResults = this.decreseFavResults.bind(this);
  }

  componentDidMount() {
    //ReactGA.pageview(window.location.pathname + window.location.search);
  }

  updateFavResults(count) {

    this.setState({ spacesCount: count })
  }

  decreseFavResults() {

    this.setState({ spacesCount: this.state.spacesCount-1 })
  }

  render() {

    const { page, location, defCurrency } = this.props;
    const { spacesCount } = this.state;
    const currency = getCurrency(defCurrency);
    const service = (page == 'services') ? 'service' : 'space';

    return (
      <>
      <div className="container-fluid bg-white mt-80 pr-0 pl-0">
        <div className="container">
          <section className="page pt-4">

            <h1 className="page-lg-title mt-4 mb-5">Favourites</h1>

            <div className="nav nav-tabs fz-17 page-nav ">
              <div className="nav-item">
                <Link id="profile-tab" className={"nav-link " + ((page == 'spaces') ? "active" : "")} to='/favourite-spaces'>Spaces</Link>
              </div>
              <div className="nav-item">
                <Link id="login-tab" className={"nav-link " + ((page == 'services') ? "active" : "")} to='/favourite-services'>Services</Link>
              </div>
            </div>

            <div className="tab-content mt-4">
              <div className="tab-pane fade show active">

                <h6 className="section-subtitle mb-4">{(spacesCount > 500) ? "+500" : spacesCount} {(page == 'services') ? 'services' : 'spaces'}</h6>

                <div className="favourites row pb-2">
                  <SearchApi key={page} isMapOpen={false} isLarge={true} isFav={true} location={location}
                             pathname={'fav-' + page} search={location.search} params={{}} service={service}
                             currency={currency} updateFavResults={this.updateFavResults}
                             decreseFavResults={this.decreseFavResults} isMobileView={window.innerWidth < 768}
                             allowSpaceLink={true}/>
                </div>

              </div>

            </div>

          </section>
        </div>

      </div>

      <Footer />

      </>
    );
  }
}

export default connect((state, props) => ({
  defCurrency: state.settings.defCurrency,
}), null)(Favourites);

